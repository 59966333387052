import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {styles, subHeading} from "../../Utils/styleConfig";
import {Divider} from "@mui/material";
import Box from "@mui/material/Box";

export const MarketplaceOrderDashboardRecipient = ({orderData}) => {

    return (
        <Box sx={styles?.root2}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box sx={styles?.section}>
                        <Typography sx={{fontSize: subHeading}}>
                            Recipient
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Divider/>
                </Grid>
                <Grid item xs={12}>
                    <Box sx={styles?.content}>
                        <Typography>
                            {orderData?.deliver_name}
                        </Typography>
                        <Typography>
                            {orderData?.deliver_address_line2 && `${orderData?.deliver_address_line2} - `}{orderData?.deliver_address}
                        </Typography>
                        <Typography>
                            {orderData?.deliver_city}, {orderData?.deliver_province}, {orderData?.deliver_country}, {orderData?.deliver_postal_code}
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}