import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Container,
    Divider,
    Snackbar,
    Step, StepLabel,
    Stepper
} from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@mui/material/Typography";
import intl from "react-intl-universal";
import {useDispatch, useSelector} from "react-redux";
import {isCardExpiryValid, mapCarrierLogo, mapLTLLogo, mapSpecialService} from "../../../Utils/Helper";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {Fragment, useEffect, useState} from "react";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import {useHistory} from "react-router-dom";
import {LABEL_DOWNLOAD_URL_PREFIX, PARTNER_URI} from "../../../Utils/apiUrl";
import {
    SHIPPING_LTL_GENERAL_PRIVATE_RESIDENT_DELIVERY_CODE,
    SHIPPING_LTL_GENERAL_PRIVATE_RESIDENT_PICKUP_CODE
} from "../../shared";
import axios from "axios";
import {deleteToken, getAccessToken} from "../../../Utils/doToken";
import MuiAlert from "@mui/material/Alert";
import * as React from "react";
import {
    clearShippingLTLCreateLabelComment,
    clearShippingLTLCreateLabelDateAndTime,
    clearShippingLTLCreateLabelPalletList,
    clearShippingLTLCreateLabelRate,
    clearShippingLTLCreateLabelReferenceNumber,
    saveShippingLTLQuickQuoteSpecialService,
    updateShippingLTLCreateLabelMoveFrom,
    updateShippingLTLCreateLabelMoveTo,
    updateShippingLTLCreateLabelSpecialService,
    updateShippingLTLQuickQuoteSpecialService
} from "../../../actions/shippingLTLAction";
import {ReactComponent as ShipmentOverviewIcon} from "../../../images/NewDesignIcon/ShipmentOverview.svg";
import {ReactComponent as ShippingFromAndToIcon} from "../../../images/NewDesignIcon/ShippingLocation.svg";
import {ReactComponent as PackageDetailIcon} from "../../../images/homepageIcon/Pallet.svg";
import {ReactComponent as PackageTypeIcon} from "../../../images/NewDesignIcon/AdditionalDetails.svg";
import {ReactComponent as SchedulePickupIcon} from "../../../images/NewDesignIcon/SchedulePickup.svg";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import dayjs from "dayjs";
import {ReactComponent as RateDetailsIcon} from "../../../images/NewDesignIcon/RateDetails.svg";
import {InternationalSteps, QontoConnector, QontoStepIcon, steps} from "../../../Utils/CustomComponent";
import {
    BusinessShippingOrderDetailsBoundCreditCard
} from "../../ShippingOrderDetails/Business/BusinessShippingOrderDetailsBoundCreditCard";
import {CreditCardListNewDesign} from "../../CreditCard/CreditCardListNewDesign";

const styles = {
    ShippingPackageCreateLabelOrderDetailsRoot: {
        backgroundColor: '#FFFFFF',
        paddingBottom: '20px',
        marginBottom: '40px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        gap: '20px',
        display: 'flex',
        flexDirection: 'column',
        margin: '20px 0'
    },
    ShippingPackageCreateLabelOrderDetailsHeading: {
        fontSize: '20px',
        fontWeight: '600',
        textAlign: 'left'
    },
    ShippingPackageCreateLabelOrderDetailsSubHeading: {
        fontSize: '18px',
        fontWeight: '600',
        textAlign: 'left'
    },
    ShippingPackageCreateLabelOrderDetailsTitle: {
        fontSize: '16px',
        textAlign: 'center'
    },
    ShippingPackageCreateLabelOrderDetailsTextLeft: {
        fontSize: '16px',
        textAlign: 'left'
    },
    ShippingPackageCreateLabelOrderDetailsTextRight: {
        fontSize: '16px',
        textAlign: 'right'
    },
    ShippingPackageCreateLabelOrderDetailsTitleRight: {
        fontSize: '18px',
        textAlign: 'right',
        fontWeight: '600'
    },
    ShippingPackageCreateLabelOrderDetailsSection: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '40px',
        borderRadius: '10px',
        // boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        border: '1px solid #A9A9A9',
        gap: '20px',
        display: 'flex',
        flexDirection: 'column',
    },
    ShippingPackageCreateLabelOrderDetailsSectionPrice: {
        // backgroundColor: '#FFFFFF',
        padding: '20px',
        // borderRadius: '10px 10px 0 0',
        // border: '2px solid #609966',
        gap: '20px',
        display: 'flex',
        flexDirection: 'column',
    },
    ShippingPackageCreateLabelOrderDetailsOtherBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        height: '100%'
    },
    ShippingPackageCreateLabelOrderDetailsOtherBoxRight: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        height: '100%'
    },
    ShippingPackageCreateLabelOrderDetailsOtherBoxCenter: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%'
    },
    ShippingPackageCreateLabelOrderDetailsImageBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        paddingLeft: '20px',
        gap: '10px'
    },
}

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const ShippingLTLCreateLabelOrderDetails = () => {

    const token = getAccessToken("access_token");

    let {id: userId} = useSelector(state => state?.user);

    const history = useHistory();

    const dispatch = useDispatch();

    let ftlCountRedux = useSelector(state => state?.shippingLTL?.createLabelFTLCount);
    let batteryDetails = useSelector(state => state?.shippingLTL?.createLabelBatteryDetails);
    let selectRate = useSelector(state => state?.shippingLTL?.createLabelSelectRate);
    let palletListRedux = useSelector(state => state?.shippingLTL?.createLabelPalletList);
    let shipFrom = useSelector(state => state?.shippingLTL?.createLabelShipFrom);
    let shipTo = useSelector(state => state?.shippingLTL?.createLabelShipTo);
    let metric = useSelector(state => state?.shippingLTL?.createLabelUnit);
    let specialService = useSelector(state => state?.shippingLTL?.createLabelSpecialService);
    let referenceNumberRedux = useSelector(state => state?.shippingLTL?.createLabelReferenceNumber);
    let commentRedux = useSelector(state => state?.shippingLTL?.createLabelComment);
    let dateAndTimeRedux = useSelector(state => state?.shippingLTL?.createLabelDateAndTime);
    let crossBorderFee = useSelector(state => state?.shippingLTL?.crossBorderRate);
    let crossBorderTransAndType = useSelector(state => state?.shippingLTL?.crossBorderTransAndType);
    let packingListRedux = useSelector(state => state?.shippingLTL?.crossBorderItemList);
    const userInfo = useSelector((state) => state.user);

    const [creditCardId, setCreditCardId] = useState();
    const [creditCardList, setCreditCardList] = useState([]);
    const [creditCardLoading, setCreditCardLoading] = useState(false);
    const [creditCardError, setCreditCardError] = useState(false);
    const [step, setStep] = useState(2);
    const [buyShippingStatus, setBuyShippingStatus] = useState(false);
    const [loadingCreateQuote, setLoadingCreateQuote] = useState(false);
    const [loadingPlaceOrder, setLoadingPlaceOrder] = useState(false);
    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [confirmationProceed, setConfirmationProceed] = useState(false);
    const [totalInCAD, setTotalInCAD] = useState(null);

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const handleError = (errorCode) => {
        switch (errorCode) {
            case 400:
                setErrorMessage("Bad Request: The server cannot process the request.");
                break;
            case 401:
                setErrorMessage("Unauthorized: Authentication is required to access the resource.");
                break;
            case 404:
                setErrorMessage("Not Found: The requested resource could not be found.");
                break;
            case 500:
                setErrorMessage("Internal Server Error: The server encountered an unexpected condition.");
                break;
            default:
                setErrorMessage("Unknown Error: An unknown error occurred.");
                break;
        }
    }

    const handleConvertCurrency = async (amount) => {
        let requestURL = `${PARTNER_URI}/currency/convertCurrency`;
        let data = {
            fromCurrencyCode: "USD",
            toCurrencyCode: "CAD",
            amount: amount
        }
        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                data: data
            });
            setTotalInCAD(result?.data?.value);
        } catch (e) {
            setTotalInCAD(null);
        }
    }

    const getValidCardList = async () => {
        const requestURL = `${PARTNER_URI}/stripe/validCreditCardList`;
        setCreditCardLoading(true);
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                headers: {Authorization: `Bearer ${token}`},
            })
            setCreditCardList(result?.data);
            setCreditCardError(false);
        } catch (e) {
            console.log('error', e.response);
            setCreditCardError(true);
        } finally {
            setCreditCardLoading(false);
        }
    }

    const createQuote = async () => {
        setLoadingCreateQuote(true);
        setBuyShippingStatus(true);

        let requestURL = `${PARTNER_URI}/shippingLTL/createLTLQuote`;

        // todo edit battery details
        let packageData = palletListRedux?.map(element => {
            const updatedElement = {...element};
            updatedElement.packageSpecialServices = {
                ...updatedElement.packageSpecialServices,
                batteryDetails: batteryDetails,
            };
            return updatedElement
        });

        let exchangedPackageData = packageData.map(item => {
            let {length, width} = item;
            if (parseInt(length) < parseInt(width)) {
                return {
                    ...item,
                    length: width,
                    width: length
                }
            }
            return item;
        });

        let data = {
            shipperName: shipFrom?.name,
            shipperPhone: shipFrom?.phone?.replace(/[^a-zA-Z0-9]/g, ''),
            shipperCompanyName: shipFrom?.company || shipFrom?.name,
            shipperEmail: shipFrom?.email,
            shipperAddress: shipFrom?.address,
            shipperAddressTwo: shipFrom?.address2 || "",
            shipperAddressType: specialService.some(service => service.serviceCode === SHIPPING_LTL_GENERAL_PRIVATE_RESIDENT_PICKUP_CODE) ? "RESIDENTIAL" : "WAREHOUSE",
            shipperCity: shipFrom?.city,
            shipperProvince: shipFrom?.province?.code,
            shipperPostalCode: shipFrom?.postalCode,
            shipperCountry: shipFrom?.country,
            consigneeName: shipTo?.name,
            consigneePhone: shipTo?.phone?.replace(/[^a-zA-Z0-9]/g, ''),
            consigneeCompanyName: shipTo?.company || shipTo?.name,
            consigneeEmail: shipTo?.email,
            consigneeAddress: shipTo?.address,
            consigneeAddressTwo: shipTo?.address2 || "",
            consigneeAddressType: specialService.some(service => service.serviceCode === SHIPPING_LTL_GENERAL_PRIVATE_RESIDENT_DELIVERY_CODE) ? "RESIDENTIAL" : "WAREHOUSE",
            consigneeCity: shipTo?.city,
            consigneeProvince: shipTo?.province?.code,
            consigneePostalCode: shipTo?.postalCode,
            consigneeCountry: shipTo?.country,
            serviceName: selectRate?.serviceName,
            pickupDate: dateAndTimeRedux?.pickupDate,
            packageData: exchangedPackageData,
            specialServices: specialService,
            refNumber: referenceNumberRedux,
            comments: commentRedux,
            transMode: crossBorderTransAndType?.transMode,
            shipmentType: crossBorderTransAndType?.shipmentType,
            packingList: packingListRedux,
            ftlCount: ftlCountRedux || 0
        }

        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${token}`
                },
                data: data
            })

            console.log('create quote', result);
            setErrorMessage('');
            handleSuccessMessage();
            setSuccessMessage('Successfully create quote.')
            setLoadingCreateQuote(false);
            dispatch(clearShippingLTLCreateLabelRate());
            dispatch(clearShippingLTLCreateLabelReferenceNumber());
            dispatch(clearShippingLTLCreateLabelComment());
            dispatch(clearShippingLTLCreateLabelDateAndTime());
            dispatch(updateShippingLTLCreateLabelMoveFrom(null));
            dispatch(updateShippingLTLCreateLabelMoveTo(null));
            dispatch(clearShippingLTLCreateLabelPalletList());
            dispatch(updateShippingLTLCreateLabelSpecialService([]));
            setTimeout(() => {
                history.push(`/business/myLTLQuote`);
            }, 2000);

        } catch (e) {
            if (e.response.status === 440) {
                setErrorMessage('The authentication token has expired.');
                deleteToken("access_token");
                setTimeout(() => window.location.reload(), 2000);
                handleErrorMessage();
            } else {
                const errorCode = e?.response?.data?.error?.status;
                console.log('error code', errorCode);
                handleErrorMessage();
                handleError(errorCode);
                setLoadingCreateQuote(false);
            }
        }
    }

    const initBackendOrder = async () => {

        setLoadingPlaceOrder(true);
        setBuyShippingStatus(true);

        // let requestURI = `${PARTNER_URI}/shippingLTL/initLTLBackendOrder`;
        let requestURI = `${PARTNER_URI}/shippingLTL/initLTLBackendOrderOnly`;

        // todo edit battery details
        let packageData = palletListRedux?.map(element => {
            const updatedElement = {...element};
            updatedElement.packageSpecialServices = {
                ...updatedElement.packageSpecialServices,
                batteryDetails: batteryDetails,
            };
            return updatedElement
        });

        let exchangedPackageData = packageData.map(item => {
            let {length, width} = item;
            if (parseInt(length) < parseInt(width)) {
                return {
                    ...item,
                    length: width,
                    width: length
                }
            }
            return item;
        });

        let data = {
            shipperName: shipFrom?.name,
            shipperPhone: shipFrom?.phone?.replace(/[^a-zA-Z0-9]/g, ''),
            shipperCompanyName: shipFrom?.company || shipFrom?.name,
            shipperEmail: shipFrom?.email,
            shipperAddress: shipFrom?.address,
            shipperAddressTwo: shipFrom?.address2 || "",
            shipperAddressType: specialService.some(service => service.serviceCode === SHIPPING_LTL_GENERAL_PRIVATE_RESIDENT_PICKUP_CODE) ? "RESIDENTIAL" : "WAREHOUSE",
            shipperCity: shipFrom?.parentCity,
            shipperSubCity: shipFrom?.city,
            shipperProvince: shipFrom?.province?.code,
            shipperPostalCode: shipFrom?.postalCode,
            shipperCountry: shipFrom?.country,
            consigneeName: shipTo?.name,
            consigneePhone: shipTo?.phone?.replace(/[^a-zA-Z0-9]/g, ''),
            consigneeCompanyName: shipTo?.company || shipTo?.name,
            consigneeEmail: shipTo?.email,
            consigneeAddress: shipTo?.address,
            consigneeAddressTwo: shipTo?.address2 || "",
            consigneeAddressType: specialService.some(service => service.serviceCode === SHIPPING_LTL_GENERAL_PRIVATE_RESIDENT_DELIVERY_CODE) ? "RESIDENTIAL" : "WAREHOUSE",
            consigneeCity: shipTo?.parentCity,
            consigneeSubCity: shipTo?.city,
            consigneeProvince: shipTo?.province?.code,
            consigneePostalCode: shipTo?.postalCode,
            consigneeCountry: shipTo?.country,
            shipToName: shipTo?.name,
            shipToPhone: shipTo?.phone?.replace(/[^a-zA-Z0-9]/g, ''),
            shipToCompanyName: shipTo?.company || shipTo?.name,
            shipToEmail: shipTo?.email,
            shipToAddress: shipTo?.address,
            shipToAddressType: specialService.some(service => service.serviceCode === SHIPPING_LTL_GENERAL_PRIVATE_RESIDENT_DELIVERY_CODE) ? "RESIDENTIAL" : "WAREHOUSE",
            shipToCity: shipTo?.city,
            shipToProvince: shipTo?.province?.code,
            shipToPostalCode: shipTo?.postalCode,
            shipToCountry: shipTo?.country,
            soldToName: shipTo?.name,
            soldToPhone: shipTo?.phone?.replace(/[^a-zA-Z0-9]/g, ''),
            soldToCompanyName: shipTo?.company || shipTo?.name,
            soldToEmail: shipTo?.email,
            soldToAddress: shipTo?.address,
            soldToAddressType: specialService.some(service => service.serviceCode === SHIPPING_LTL_GENERAL_PRIVATE_RESIDENT_DELIVERY_CODE) ? "RESIDENTIAL" : "WAREHOUSE",
            soldToCity: shipTo?.city,
            soldToProvince: shipTo?.province?.code,
            soldToPostalCode: shipTo?.postalCode,
            soldToCountry: shipTo?.country,
            serviceName: selectRate?.serviceName,
            shipDate: dateAndTimeRedux?.shipDate,
            pickupLocationCloseTime: `${dateAndTimeRedux?.shipDate} ${dateAndTimeRedux?.latestPickupTime}`,
            pickupLocationOpenTime: `${dateAndTimeRedux?.shipDate} ${dateAndTimeRedux?.earliestPickupTime}`,
            pickupAvailableTime: `${dateAndTimeRedux?.shipDate} ${dateAndTimeRedux?.availablePickupTime}`,
            pickupDate: dateAndTimeRedux?.pickupDate,
            transMode: crossBorderTransAndType?.transMode,
            shipmentType: crossBorderTransAndType?.shipmentType,
            pickupInstruction: "",
            packageData: exchangedPackageData,
            specialServices: specialService,
            refNumber: referenceNumberRedux,
            comments: commentRedux,
            packingList: packingListRedux,
            ftlCount: ftlCountRedux || 0,
            carrierName: selectRate?.carrierName,
            transitMode: selectRate?.transitMode,
        }

        try {
            const result = await axios({
                method: 'post',
                url: requestURI,
                headers: {
                    Authorization: `Bearer ${token}`
                },
                data: data
            })

            console.log('[initBackendOrder] result', result);
            // setErrorMessage('');
            // handleSuccessMessage();
            // setSuccessMessage('Successfully place LTL order.')
            setLoadingPlaceOrder(false);
            dispatch(clearShippingLTLCreateLabelRate());
            dispatch(clearShippingLTLCreateLabelReferenceNumber());
            dispatch(clearShippingLTLCreateLabelComment());
            dispatch(clearShippingLTLCreateLabelDateAndTime());
            dispatch(updateShippingLTLCreateLabelMoveFrom(null));
            dispatch(updateShippingLTLCreateLabelMoveTo(null));
            dispatch(clearShippingLTLCreateLabelPalletList());
            dispatch(updateShippingLTLCreateLabelSpecialService([]));
            setTimeout(() => {
                history.push(`/business/LTLShippingOrderDetails/${result?.data?.backendOrder?.id}`);
                // history.push(`/business/ltl-order-dashboard/${result?.data?.backendOrder?.id}`);
            }, 2000);

        } catch (e) {
            console.log(e.response);
            if (e.response?.status === 403) {
                setErrorMessage(`${e.response?.data?.error}. Please contact the operator at op@uucargo.ca for assistance.`);
                handleErrorMessage();
            } else if (e.response.status === 440) {
                setErrorMessage('The authentication token has expired.');
                deleteToken("access_token");
                setTimeout(() => window.location.reload(), 2000);
                handleErrorMessage();
            } else {
                const errorCode = e?.response?.data?.error?.status;
                console.log('error code', errorCode);
                handleError(errorCode);
                handleErrorMessage();
            }
        } finally {
            setLoadingPlaceOrder(false);
        }

    }

    const handleCheckOut = async () => {
        // setStep(3);
        // await handleConvertCurrency(+selectRate?.finalTotal + (+crossBorderFee?.grandTotal || 0));
        await initBackendOrder();
    }

    const handlePlaceOrder = () => {
        initBackendOrder();
    }

    const handleBackToShippingInfo = () => {
        history.push('/business/shipping-ltl/create-label', {
                savedShipFrom: shipFrom,
                savedShipTo: shipTo
            }
        );
    }

    const handleQuoteList = () => {
        history.push('/business/shipping-ltl/create-label/rate-list');
    }

    const handleCreateQuote = () => {
        createQuote();
    }

    const handleContinueProceed = () => {
        setConfirmationProceed(true);
    }

    const handleCancelProceed = () => {
        setConfirmationProceed(false);
    }

    const handleBack = () => {
        history.push('/business/shipping-ltl/create-label/rate-list');
        // if (shipFrom?.country === shipTo?.country) {
        //     history.push('/business/shipping-ltl/create-label/rate-list');
        // } else {
        //     history.push('/business/shipping-ltl/create-label/cross-border-order-details');
        // }
    }

    const handleBeforeUnload = (event) => {

        console.log('reload');
        // Customize the confirmation message
        const message = 'Are you sure you want to leave? Your unsaved changes may be lost.';

        // Set the confirmation message for modern browsers
        event.returnValue = message;

        // For older browsers
        return message;
    };

    useEffect(() => {
        (async () => {
            await getValidCardList();
        })()
        // Add the event listener when the component mounts
        window.addEventListener('beforeunload', handleBeforeUnload);

        // Remove the event listener when the component unmounts
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []); // Empty dependency array means this effect runs once when the component mounts

    console.log('[ShippingLTLCreateLabelOrderDetails] select rate', selectRate);
    // console.log('special service', specialService);
    // console.log('date and time', dateAndTimeRedux);
    console.log('ship from', shipFrom);
    // console.log('cross border fee', crossBorderFee);
    // console.log('cross border trans and type', crossBorderTransAndType);
    // console.log('cross border packing list', packingListRedux);

    if (!shipFrom?.country && !buyShippingStatus) {
        return (
            <Container maxWidth='xl'>
                <Box sx={styles.ShippingPackageCreateLabelOrderDetailsRoot}>
                    <Box sx={{
                        display: 'flex',
                        gap: '10px',
                        padding: '20px 20px 0 20px'
                    }}>
                        <ShipmentOverviewIcon width={25} height={25}/>
                        <Typography style={styles.ShippingPackageCreateLabelOrderDetailsSubHeading}>
                            Shipment Overview
                        </Typography>
                    </Box>
                    <Divider/>
                    <Box sx={{
                        backgroundColor: '#FFFFFF',
                        padding: '0 40px',
                        gap: '40px',
                        display: 'flex',
                    }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography style={{color: 'red', textAlign: 'left', fontSize: '16px'}}>
                                    You've lost all the data. Please return to the home page and create a new shipment.
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
                <Box sx={{display: 'flex', width: '100%', justifyContent: 'flex-end', gap: '20px'}}>
                    <Button
                        variant='contained'
                        size='large'
                        onClick={() => history.push('/home')}
                        sx={{
                            backgroundColor: '#F2BE22',
                            "&:hover": {
                                backgroundColor: '#F2BE22',
                                filter: 'brightness(0.9)'
                            }
                        }}
                    >
                        <Typography style={{textTransform: 'none'}}>
                            Go Back Home
                        </Typography>
                    </Button>
                </Box>
            </Container>
        )
    }

    return (
        <Container maxWidth='xl'>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Stepper alternativeLabel activeStep={step}
                     connector={<QontoConnector/>}>
                {
                    steps.map((label, index) => (
                        // (shipFrom?.country === shipTo?.country ? steps : InternationalSteps).map((label, index) => (
                        <Step key={label}>
                            {/*<StepButton onClick={handleStep(index)}>*/}
                            <StepLabel StepIconComponent={QontoStepIcon}>
                                <Typography sx={{color: index <= step ? '#1D8B45' : '#C7C7C7', fontSize: '14px'}}>
                                    {label}
                                </Typography>
                            </StepLabel>
                            {/*</StepButton>*/}
                        </Step>
                    ))
                }
            </Stepper>
            <Box sx={styles.ShippingPackageCreateLabelOrderDetailsRoot}>
                <Box sx={{
                    display: 'flex',
                    gap: '10px',
                    padding: '20px 20px 0 20px'
                }}>
                    <ShipmentOverviewIcon width={25} height={25}/>
                    <Typography style={styles.ShippingPackageCreateLabelOrderDetailsSubHeading}>
                        Shipment Overview
                    </Typography>
                </Box>
                <Divider/>
                <Box sx={{
                    backgroundColor: '#FFFFFF',
                    padding: '0 40px',
                    gap: '40px',
                    display: 'flex',
                }}>
                    <Grid container spacing={4}>
                        <Grid item xs={12} md={6}>
                            <Box sx={{display: 'flex', width: '100%', flexDirection: 'column', gap: '60px'}}>
                                <Grid container spacing={1}>
                                    <Grid item xs={1}>
                                        <ShippingFromAndToIcon width={20} height={20}/>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <Typography sx={{fontWeight: '600'}}>
                                            {intl.get('SHIPPING_INFORMATION.SHIP_FROM')}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Button onClick={handleBackToShippingInfo}
                                                sx={{padding: 0, justifyContent: 'right', width: '100%'}}>
                                            <Typography sx={{color: '#1D8B45', textTransform: 'none'}}>
                                                Edit
                                            </Typography>
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider/>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography style={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>
                                            {shipFrom?.name}
                                        </Typography>
                                        <Typography style={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>
                                            {shipFrom?.address2 && `${shipFrom?.address2} - `}{shipFrom?.address}
                                        </Typography>
                                        <Typography style={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>
                                            {shipFrom?.city}, {shipFrom?.province?.code}, {shipFrom?.country}, {shipFrom?.postalCode}
                                        </Typography>
                                        {
                                            shipFrom?.email &&
                                            <Typography style={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>
                                                {shipFrom?.email}
                                            </Typography>
                                        }
                                        <Typography style={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>
                                            {shipFrom?.phone}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={1}>
                                    <Grid item xs={1}>
                                        <ShippingFromAndToIcon width={20} height={20}/>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <Typography sx={{fontWeight: '600'}}>
                                            {intl.get('SHIPPING_INFORMATION.SHIP_TO')}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Button onClick={handleBackToShippingInfo}
                                                sx={{padding: 0, justifyContent: 'right', width: '100%'}}>
                                            <Typography sx={{color: '#1D8B45', textTransform: 'none'}}>
                                                Edit
                                            </Typography>
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider/>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography style={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>
                                            {shipTo?.name}
                                        </Typography>
                                        <Typography style={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>
                                            {shipTo?.address2 && `${shipTo?.address2} - `}{shipTo?.address}
                                        </Typography>
                                        <Typography style={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>
                                            {shipTo?.city}, {shipTo?.province?.code}, {shipTo?.country}, {shipTo?.postalCode}
                                        </Typography>
                                        {
                                            shipTo?.email &&
                                            <Typography style={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>
                                                {shipTo?.email}
                                            </Typography>
                                        }
                                        <Typography style={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>
                                            {shipTo?.phone}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={1}>
                                    <Grid item xs={1}>
                                        <PackageDetailIcon width={20} height={20}/>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <Typography sx={{fontWeight: '600'}}>
                                            Pallet Details
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Button onClick={handleBackToShippingInfo}
                                                sx={{padding: 0, justifyContent: 'right', width: '100%'}}>
                                            <Typography sx={{color: '#1D8B45', textTransform: 'none'}}>
                                                Edit
                                            </Typography>
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider/>
                                    </Grid>
                                    {
                                        palletListRedux.length > 0 && palletListRedux?.map((each, index) => (
                                            <Fragment key={index}>
                                                <Grid item xs={2}>
                                                    <Typography
                                                        style={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>
                                                        {`#${index + 1}`}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography
                                                        style={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>
                                                        {each?.length} x {each?.width} x {each?.height} {each?.lengthUnit}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <Typography
                                                        style={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>
                                                        {each?.weight} {each?.weightUnit}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <Typography
                                                        style={styles.ShippingPackageCreateLabelOrderDetailsTextRight}>
                                                        x {each?.quantity}
                                                    </Typography>
                                                </Grid>
                                            </Fragment>
                                        ))
                                    }
                                </Grid>
                                <Grid container spacing={1}>
                                    <Grid item xs={1}>
                                        <PackageTypeIcon width={20} height={20}/>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <Typography sx={{fontWeight: '600'}}>
                                            Additional Details
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Button onClick={handleBackToShippingInfo}
                                                sx={{padding: 0, justifyContent: 'right', width: '100%'}}>
                                            <Typography sx={{color: '#1D8B45', textTransform: 'none'}}>
                                                Edit
                                            </Typography>
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider/>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography>
                                            Reference Order Number: {referenceNumberRedux || 'N/A'}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography>
                                            Special Services: {specialService?.length === 0 && 'N/A'}
                                        </Typography>
                                    </Grid>
                                    {
                                        specialService?.length > 0 && specialService?.map((each, index) => (
                                            <Grid item xs={6} key={index}>
                                                <Typography
                                                    style={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>
                                                    {mapSpecialService(each?.serviceCode)}
                                                </Typography>
                                            </Grid>
                                        ))
                                    }
                                    <Grid item xs={12}>
                                        <Typography>
                                            Remark Notes: {commentRedux || 'N/A'}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Box sx={{display: 'flex', width: '100%', flexDirection: 'column', gap: '60px'}}>
                                <Grid container spacing={1}>
                                    <Grid item xs={1}>
                                        <SchedulePickupIcon width={20} height={20}/>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <Typography sx={{fontWeight: '600'}}>
                                            Schedule Pickup
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Button onClick={handleQuoteList}
                                                sx={{padding: 0, justifyContent: 'right', width: '100%'}}>
                                            <Typography sx={{color: '#1D8B45', textTransform: 'none'}}>
                                                Edit
                                            </Typography>
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider/>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography>
                                            <CheckCircleIcon sx={{color: '#1D8B45'}}/> Pickup Schedule
                                            on {dayjs(dateAndTimeRedux?.pickupDate)?.format('MMMM D, YYYY')}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography>
                                            From {dayjs(dateAndTimeRedux?.earliestPickupTime, 'HH:mm')?.format('h:mm A')} to {dayjs(dateAndTimeRedux?.latestPickupTime, 'HH:mm')?.format('h:mm A')}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={1}>
                                    <Grid item xs={1}>
                                        <RateDetailsIcon width={20} height={20}/>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <Typography sx={{fontWeight: '600'}}>
                                            Rate Details
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Button onClick={handleQuoteList}
                                                sx={{padding: 0, justifyContent: 'right', width: '100%'}}>
                                            <Typography sx={{color: '#1D8B45', textTransform: 'none'}}>
                                                Edit
                                            </Typography>
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider/>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <img
                                            src={`${LABEL_DOWNLOAD_URL_PREFIX}${selectRate?.icon_uri}`}
                                            alt="logo"
                                            width='60px'/>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={6}>
                                                <Typography>
                                                    {
                                                        selectRate?.serviceName.toUpperCase() === "DAYANDROSS" ? "DAY && ROSS" : selectRate?.serviceName.toUpperCase() === "TFORCE" ? "TForce" : selectRate?.serviceName.toUpperCase()
                                                    }
                                                </Typography>
                                                <Typography>
                                                    2-10 business day(s)
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography
                                                    style={styles.ShippingPackageCreateLabelOrderDetailsTitleRight}>
                                                    ${(+selectRate?.finalTotal + (+crossBorderFee?.grandTotal || 0))?.toFixed(2)} {selectRate.currency}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Divider/>
                                            </Grid>
                                            {
                                                selectRate?.shipmentCharges?.map((each, index) => {
                                                    const cost = +each?.value
                                                    if (cost !== 0) {
                                                        return (
                                                            <Fragment key={index}>
                                                                <Grid item xs={6}>
                                                                    <Typography
                                                                        style={styles.ShippingPackageCreateLabelOrderDetailsTextRight}>
                                                                        {each?.name}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item xs={6}>
                                                                    <Typography
                                                                        style={styles.ShippingPackageCreateLabelOrderDetailsTextRight}>
                                                                        ${(+each?.value)?.toFixed(2)} {selectRate.currency}
                                                                    </Typography>
                                                                </Grid>
                                                            </Fragment>
                                                        )
                                                    }
                                                })
                                            }
                                            <Grid item xs={12}>
                                                <Divider/>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography
                                                    style={styles.ShippingPackageCreateLabelOrderDetailsTextRight}>
                                                    Subtotal
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography
                                                    style={styles.ShippingPackageCreateLabelOrderDetailsTextRight}>
                                                    ${((+selectRate?.shippingFee).toFixed(2))} {selectRate.currency}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography
                                                    style={styles.ShippingPackageCreateLabelOrderDetailsTextRight}>
                                                    Tax
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography
                                                    style={styles.ShippingPackageCreateLabelOrderDetailsTextRight}>
                                                    ${(+selectRate?.taxCharge)?.toFixed(2)} {selectRate.currency}
                                                </Typography>
                                            </Grid>
                                            {
                                                crossBorderFee?.totalBrokerageFees > 0 &&
                                                <>
                                                    <Grid item xs={6}>
                                                        <Typography
                                                            style={styles.ShippingPackageCreateLabelOrderDetailsTextRight}>
                                                            Custom Brokerage Fee
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Typography
                                                            style={styles.ShippingPackageCreateLabelOrderDetailsTextRight}>
                                                            ${(+crossBorderFee?.totalBrokerageFees)?.toFixed(2)} {selectRate.currency}
                                                        </Typography>
                                                    </Grid>
                                                </>
                                            }
                                            {
                                                crossBorderFee?.totalDutyandTax > 0 &&
                                                <>
                                                    <Grid item xs={6}>
                                                        <Typography
                                                            style={styles.ShippingPackageCreateLabelOrderDetailsTextRight}>
                                                            Duty And Tax
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Typography
                                                            style={styles.ShippingPackageCreateLabelOrderDetailsTextRight}>
                                                            ${(+crossBorderFee?.totalDutyandTax)?.toFixed(2)} {selectRate.currency}
                                                        </Typography>
                                                    </Grid>
                                                </>
                                            }
                                            <Grid item xs={12}>
                                                <Divider/>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography
                                                    style={styles.ShippingPackageCreateLabelOrderDetailsTitleRight}>
                                                    Total
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography
                                                    style={styles.ShippingPackageCreateLabelOrderDetailsTitleRight}>
                                                    ${(+selectRate?.finalTotal + (+crossBorderFee?.grandTotal || 0))?.toFixed(2)} {selectRate.currency}
                                                </Typography>
                                            </Grid>
                                            {
                                                (totalInCAD && selectRate?.currency === 'USD') &&
                                                <>
                                                    <Grid item xs={6}>
                                                        <Typography
                                                            style={styles.ShippingPackageCreateLabelOrderDetailsTitleRight}>
                                                            Total
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Typography
                                                            style={styles.ShippingPackageCreateLabelOrderDetailsTitleRight}>
                                                            ${(+totalInCAD)?.toFixed(2)} CAD
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Typography>
                                                            This amount has been converted to CAD for your reference.
                                                            The total is ${(+totalInCAD)?.toFixed(2)}. You will be
                                                            charged and billed in CAD moving forward.
                                                        </Typography>
                                                    </Grid>
                                                </>
                                            }
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <Box sx={{marginBottom: '40px'}}>
                <Grid container spacing={2}>
                    {
                        confirmationProceed ?
                            <>
                                <Grid item xs={12}>
                                    <Typography>
                                        Would you like to start over? Please select 'Confirm' to begin again or
                                        'Cancel' to continue with your current session.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box sx={{
                                        display: 'flex',
                                        width: '100%',
                                        justifyContent: 'flex-end',
                                        gap: '20px'
                                    }}>
                                        <Button
                                            size='large'
                                            variant='contained'
                                            onClick={handleCancelProceed}
                                            sx={{
                                                backgroundColor: '#F2BE22',
                                                "&:hover": {
                                                    backgroundColor: '#F2BE22',
                                                    filter: 'brightness(0.9)'
                                                }
                                            }}
                                        >
                                            <Typography sx={{textTransform: 'none'}}>
                                                Cancel
                                            </Typography>
                                        </Button>
                                        <LoadingButton
                                            size='large'
                                            variant='contained'
                                            color='success'
                                            loading={loadingPlaceOrder}
                                            onClick={handleBackToShippingInfo}
                                        >
                                            <Typography sx={{textTransform: 'none'}}>
                                                Confirm
                                            </Typography>
                                        </LoadingButton>
                                    </Box>
                                </Grid>
                            </>
                            :
                            <>
                                <Grid item md={3} xs={12}>
                                    <Box sx={{
                                        display: 'flex',
                                        width: '100%',
                                        justifyContent: 'flex-start',
                                        gap: '20px'
                                    }}>
                                        <Button
                                            size='large'
                                            variant='contained'
                                            onClick={handleContinueProceed}
                                            sx={{
                                                backgroundColor: '#454545',
                                                "&:hover": {
                                                    backgroundColor: '#454545',
                                                    filter: 'brightness(0.9)'
                                                }
                                            }}
                                        >
                                            <Typography sx={{textTransform: 'none'}}>
                                                Start Over
                                            </Typography>
                                        </Button>
                                    </Box>
                                </Grid>
                                <Grid item md={9} xs={12}>
                                    <Box sx={{
                                        display: 'flex',
                                        width: '100%',
                                        justifyContent: 'flex-end',
                                        gap: '20px'
                                    }}>
                                        <Button
                                            size='large'
                                            variant='contained'
                                            onClick={handleBack}
                                            sx={{
                                                backgroundColor: '#F2BE22',
                                                "&:hover": {
                                                    backgroundColor: '#F2BE22',
                                                    filter: 'brightness(0.9)'
                                                }
                                            }}
                                        >
                                            <Typography sx={{textTransform: 'none'}}>
                                                Back
                                            </Typography>
                                        </Button>
                                        {
                                            (selectRate?.serviceName === 'dayandross' || selectRate?.serviceName?.toLowerCase() === 'csa' || selectRate?.serviceName?.toLowerCase() === 'tforce') &&
                                            <LoadingButton
                                                size='large'
                                                variant='contained'
                                                color='success'
                                                loading={loadingCreateQuote}
                                                onClick={handleCreateQuote}
                                            >
                                                <Typography sx={{textTransform: 'none'}}>
                                                    {intl.get('SHIPPING_LTL_QUICK_QUOTE.CREATE_QUOTE')}
                                                </Typography>
                                            </LoadingButton>
                                        }
                                        <LoadingButton
                                            size='large'
                                            variant='contained'
                                            color='success'
                                            loading={loadingPlaceOrder}
                                            // onClick={handlePlaceOrder}
                                            onClick={handleCheckOut}
                                        >
                                            <Typography sx={{textTransform: 'none'}}>
                                                Check Out
                                            </Typography>
                                        </LoadingButton>
                                    </Box>
                                </Grid>
                            </>
                    }
                </Grid>
                {/*{*/}
                {/*    step === 2 ?*/}
                {/*        <Grid container spacing={2}>*/}
                {/*            {*/}
                {/*                confirmationProceed ?*/}
                {/*                    <>*/}
                {/*                        <Grid item xs={12}>*/}
                {/*                            <Typography>*/}
                {/*                                Would you like to start over? Please select 'Confirm' to begin again or*/}
                {/*                                'Cancel'*/}
                {/*                                to*/}
                {/*                                continue with your current session.*/}
                {/*                            </Typography>*/}
                {/*                        </Grid>*/}
                {/*                        <Grid item xs={12}>*/}
                {/*                            <Box sx={{*/}
                {/*                                display: 'flex',*/}
                {/*                                width: '100%',*/}
                {/*                                justifyContent: 'flex-end',*/}
                {/*                                gap: '20px'*/}
                {/*                            }}>*/}
                {/*                                <Button*/}
                {/*                                    size='large'*/}
                {/*                                    variant='contained'*/}
                {/*                                    onClick={handleCancelProceed}*/}
                {/*                                    sx={{*/}
                {/*                                        backgroundColor: '#F2BE22',*/}
                {/*                                        "&:hover": {*/}
                {/*                                            backgroundColor: '#F2BE22',*/}
                {/*                                            filter: 'brightness(0.9)'*/}
                {/*                                        }*/}
                {/*                                    }}*/}
                {/*                                >*/}
                {/*                                    <Typography sx={{textTransform: 'none'}}>*/}
                {/*                                        Cancel*/}
                {/*                                    </Typography>*/}
                {/*                                </Button>*/}
                {/*                                <LoadingButton*/}
                {/*                                    size='large'*/}
                {/*                                    variant='contained'*/}
                {/*                                    color='success'*/}
                {/*                                    loading={loadingPlaceOrder}*/}
                {/*                                    onClick={handleBackToShippingInfo}*/}
                {/*                                >*/}
                {/*                                    <Typography sx={{textTransform: 'none'}}>*/}
                {/*                                        Confirm*/}
                {/*                                    </Typography>*/}
                {/*                                </LoadingButton>*/}
                {/*                            </Box>*/}
                {/*                        </Grid>*/}
                {/*                    </>*/}
                {/*                    :*/}
                {/*                    <>*/}
                {/*                        <Grid item md={3} xs={12}>*/}
                {/*                            <Box sx={{*/}
                {/*                                display: 'flex',*/}
                {/*                                width: '100%',*/}
                {/*                                justifyContent: 'flex-start',*/}
                {/*                                gap: '20px'*/}
                {/*                            }}>*/}
                {/*                                <Button*/}
                {/*                                    size='large'*/}
                {/*                                    variant='contained'*/}
                {/*                                    onClick={handleContinueProceed}*/}
                {/*                                    sx={{*/}
                {/*                                        backgroundColor: '#454545',*/}
                {/*                                        "&:hover": {*/}
                {/*                                            backgroundColor: '#454545',*/}
                {/*                                            filter: 'brightness(0.9)'*/}
                {/*                                        }*/}
                {/*                                    }}*/}
                {/*                                >*/}
                {/*                                    <Typography sx={{textTransform: 'none'}}>*/}
                {/*                                        Start Over*/}
                {/*                                    </Typography>*/}
                {/*                                </Button>*/}
                {/*                            </Box>*/}
                {/*                        </Grid>*/}
                {/*                        <Grid item md={9} xs={12}>*/}
                {/*                            <Box sx={{*/}
                {/*                                display: 'flex',*/}
                {/*                                width: '100%',*/}
                {/*                                justifyContent: 'flex-end',*/}
                {/*                                gap: '20px'*/}
                {/*                            }}>*/}
                {/*                                <Button*/}
                {/*                                    size='large'*/}
                {/*                                    variant='contained'*/}
                {/*                                    onClick={handleBack}*/}
                {/*                                    sx={{*/}
                {/*                                        backgroundColor: '#F2BE22',*/}
                {/*                                        "&:hover": {*/}
                {/*                                            backgroundColor: '#F2BE22',*/}
                {/*                                            filter: 'brightness(0.9)'*/}
                {/*                                        }*/}
                {/*                                    }}*/}
                {/*                                >*/}
                {/*                                    <Typography sx={{textTransform: 'none'}}>*/}
                {/*                                        Back*/}
                {/*                                    </Typography>*/}
                {/*                                </Button>*/}
                {/*                                {*/}
                {/*                                    (selectRate?.serviceName === 'dayandross' || selectRate?.serviceName?.toLowerCase() === 'csa' || selectRate?.serviceName?.toLowerCase() === 'tforce') &&*/}
                {/*                                    <LoadingButton*/}
                {/*                                        size='large'*/}
                {/*                                        variant='contained'*/}
                {/*                                        color='success'*/}
                {/*                                        loading={loadingCreateQuote}*/}
                {/*                                        onClick={handleCreateQuote}*/}
                {/*                                    >*/}
                {/*                                        <Typography sx={{textTransform: 'none'}}>*/}
                {/*                                            {intl.get('SHIPPING_LTL_QUICK_QUOTE.CREATE_QUOTE')}*/}
                {/*                                        </Typography>*/}
                {/*                                    </LoadingButton>*/}
                {/*                                }*/}
                {/*                                <LoadingButton*/}
                {/*                                    size='large'*/}
                {/*                                    variant='contained'*/}
                {/*                                    color='success'*/}
                {/*                                    loading={loadingPlaceOrder}*/}
                {/*                                    // onClick={handlePlaceOrder}*/}
                {/*                                    onClick={handleCheckOut}*/}
                {/*                                >*/}
                {/*                                    <Typography sx={{textTransform: 'none'}}>*/}
                {/*                                        Check Out*/}
                {/*                                    </Typography>*/}
                {/*                                </LoadingButton>*/}
                {/*                            </Box>*/}
                {/*                        </Grid>*/}
                {/*                    </>*/}
                {/*            }*/}
                {/*        </Grid>*/}
                {/*        :*/}
                {/*        // Not need bind credit card if user id is 1 or 245*/}
                {/*        ((+userId === 1 || +userId === 245)) ?*/}
                {/*            <Box sx={{display: 'flex', justifyContent: 'flex-end', gap: '10px'}}>*/}
                {/*                <LoadingButton*/}
                {/*                    variant='contained'*/}
                {/*                    sx={{*/}
                {/*                        backgroundColor: '#1D8B45',*/}
                {/*                        "&:hover": {*/}
                {/*                            backgroundColor: '#1D8B45',*/}
                {/*                            filter: 'brightness(0.9)'*/}
                {/*                        }*/}
                {/*                    }}*/}
                {/*                    loading={loadingPlaceOrder}*/}
                {/*                    onClick={handlePlaceOrder}*/}
                {/*                >*/}
                {/*                    <Typography style={{textTransform: 'none', fontSize: '16px'}}>*/}
                {/*                        Place Order*/}
                {/*                    </Typography>*/}
                {/*                </LoadingButton>*/}
                {/*            </Box>*/}
                {/*            :*/}
                {/*            // card list is 0, and user id is not 1 nor 245, then bind credit card*/}
                {/*            (creditCardList?.length === 0) ?*/}
                {/*                <BusinessShippingOrderDetailsBoundCreditCard getValidCardList={getValidCardList}*/}
                {/*                                                             creditCardList={creditCardList}/> :*/}
                {/*                // has a valid credit card*/}
                {/*                isCardExpiryValid(creditCardList?.[0]?.card_expiry) ?*/}
                {/*                    <Box sx={{display: 'flex', justifyContent: 'flex-end', gap: '10px'}}>*/}
                {/*                        <LoadingButton*/}
                {/*                            variant='contained'*/}
                {/*                            sx={{*/}
                {/*                                backgroundColor: '#1D8B45',*/}
                {/*                                "&:hover": {*/}
                {/*                                    backgroundColor: '#1D8B45',*/}
                {/*                                    filter: 'brightness(0.9)'*/}
                {/*                                }*/}
                {/*                            }}*/}
                {/*                            loading={loadingPlaceOrder}*/}
                {/*                            onClick={handlePlaceOrder}*/}
                {/*                        >*/}
                {/*                            <Typography style={{textTransform: 'none', fontSize: '16px'}}>*/}
                {/*                                Place Order*/}
                {/*                            </Typography>*/}
                {/*                        </LoadingButton>*/}
                {/*                    </Box> :*/}
                {/*                    // has invalid credit card*/}
                {/*                    <BusinessShippingOrderDetailsBoundCreditCard getValidCardList={getValidCardList}*/}
                {/*                                                                 creditCardList={creditCardList}/>*/}
                {/*}*/}
            </Box>
        </Container>
    )
}
