import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {Divider, Link, Tooltip, useMediaQuery} from "@mui/material";
import dayjs from "dayjs";
import {blue, green, LightTooltip, noteContent, red, yellow} from "../../Utils/styleConfig";
import {Fragment, useEffect, useState} from "react";
import Box from "@mui/material/Box";

export const MovingVehicleOrderEach = ({order, startsWithBusiness}) => {

    const isMobile = useMediaQuery('(max-width: 480px)');

    const [quantity, setQuantity] = useState(0);

    useEffect(() => {
        const count = order?.backendPackages?.reduce((total, vehicle) => +total + +vehicle?.number, 0);
        setQuantity(count);
    }, [order])

    return (
        <Grid container spacing={1}>
            <Grid item md={1.5} xs={4}>
                <Link
                    href={`${startsWithBusiness ? '/business/' : '/'}transportation/movingVehicle/orderDashboard/${order?.id}`}
                    style={{textDecoration: 'none'}}
                >
                    <Typography sx={{
                        fontSize: '14px',
                        textAlign: 'left',
                        fontWeight: '600',
                        color: (+order?.status_id) === 0 ? yellow : (+order?.status_id) === 1 ? green : (+order?.status_id) === 2 ? red : blue,
                    }}>
                        {order?.id}
                    </Typography>
                </Link>
                <Typography sx={{fontSize: '12px', textAlign: 'left'}}>
                    {dayjs(order?.created_at).format('YYYY-MM-DD')}
                </Typography>
            </Grid>
            {!isMobile &&
                <Grid item md={2.25}>
                    <Typography sx={{fontSize: '14px', textAlign: 'left'}}>
                        {order?.pickup_address}
                    </Typography>
                    <Typography sx={{fontSize: '14px', textAlign: 'left'}}>
                        {order?.pickup_area}
                    </Typography>
                </Grid>
            }
            {!isMobile &&
                <Grid item md={2.25}>
                    <Typography sx={{fontSize: '14px', textAlign: 'left'}}>
                        {order?.deliver_address}
                    </Typography>
                    <Typography sx={{fontSize: '14px', textAlign: 'left'}}>
                        {order?.deliver_area}
                    </Typography>
                </Grid>
            }
            {!isMobile &&
                <Grid item md={2}>
                    <Typography sx={{fontSize: '14px', textAlign: 'left'}}>
                        {order?.sender}
                    </Typography>
                    <Typography sx={{fontSize: '14px', textAlign: 'left'}}>
                        {order?.pickup_phone}
                    </Typography>
                    <Typography sx={{fontSize: '14px', textAlign: 'left'}}>
                        {order?.pickup_email}
                    </Typography>
                </Grid>
            }
            {!isMobile &&
                <Grid item md={1}>
                    <LightTooltip
                        placement="bottom"
                        sx={{
                            padding: '0',
                            margin: '0'
                        }}
                        title={
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                width: '300px',
                                padding: '10px',
                            }}>
                                <Grid container spacing={1}>
                                    {
                                        order?.backendPackages?.map((each, index) => (
                                            <Fragment key={index}>
                                                <Grid items xs={3}>
                                                    <Typography sx={{fontSize: noteContent}}>
                                                        {each?.max_year_of_production}
                                                    </Typography>
                                                </Grid>
                                                <Grid items xs={4}>
                                                    <Typography sx={{fontSize: noteContent}}>
                                                        {each?.make}
                                                    </Typography>
                                                </Grid>
                                                <Grid items xs={4}>
                                                    <Typography sx={{fontSize: noteContent}}>
                                                        {each?.model}
                                                    </Typography>
                                                </Grid>
                                                <Grid items xs={1}>
                                                    <Typography sx={{fontSize: noteContent}}>
                                                        {each?.number}
                                                    </Typography>
                                                </Grid>
                                            </Fragment>
                                        ))
                                    }
                                </Grid>
                            </Box>
                        }
                    >
                        <Typography sx={{fontSize: '14px', textAlign: 'center'}}>
                            {quantity}
                        </Typography>
                    </LightTooltip>
                </Grid>
            }
            <Grid item md={1.5} xs={4}>
                <Typography sx={{fontSize: '14px', textAlign: 'center'}}>
                    {(+order?.final_total === 0) ? 'Quote Required' : (+order?.final_total)?.toFixed(2)}
                </Typography>
            </Grid>
            <Grid item md={1.5} xs={4}>
                <Typography sx={{
                    fontSize: '14px',
                    fontWeight: '600',
                    textAlign: 'center',
                    color: (+order?.status_id) === 0 ? yellow : (+order?.status_id) === 1 ? green : (+order?.status_id) < 0 ? red : blue,
                }}>
                    {
                        (+order?.status_id) === 0 ? 'Initial' :
                            (+order?.status_id) === 1 ? 'Confirm' :
                                (+order?.status_id) === 100 ? 'Processing' :
                                    (+order?.status_id) === 200 ? 'Paid Deposit' :
                                        (+order?.status_id) === -1 ? 'Cancel' :
                                            (+order?.status_id) === -100 ? 'Refund Request' :
                                                'Refund'
                    }
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Divider/>
            </Grid>
        </Grid>
    )
}