import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React from "react";
import {Divider} from "@mui/material";

const style = {
    OrderDashboard: {
        border: '1px solid #B2B2B2',
        borderRadius: '10px',
        padding: '10px 0',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
    }
}

export const MovingVehicleOrderDashboardPrice = ({orderData}) => {

    return (
        <Box sx={style.OrderDashboard}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        padding: '10px 20px',
                        borderBottom: '1px solid #B2B2B2'
                    }}>
                        <Typography sx={{fontSize: '16px', fontWeight: '600'}}>
                            Order Price
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{padding: '20px'}}>
                        {
                            orderData?.orderPrice?.final_total === 0 ?
                                <Typography>
                                    Based on the selections you've made, a quote cannot be provided to you immediately.
                                    The Operations team will process your quote request and get back to you as soon as
                                    possible with further details.
                                </Typography>
                                :
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <Typography sx={{fontSize: '16px', textAlign: 'left'}}>
                                            Subtotal
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography sx={{fontSize: '16px', textAlign: 'right'}}>
                                            $ {(+orderData?.orderPrice?.total).toFixed(2)}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography sx={{fontSize: '16px', textAlign: 'left'}}>
                                            Tax
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography sx={{fontSize: '16px', textAlign: 'right'}}>
                                            $ {(+orderData?.orderPrice?.taxes).toFixed(2)}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider/>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography sx={{fontSize: '16px', textAlign: 'left'}}>
                                            Total
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography sx={{fontSize: '16px', textAlign: 'right'}}>
                                            $ {(+orderData?.orderPrice?.final_total).toFixed(2)}
                                        </Typography>
                                    </Grid>
                                </Grid>
                        }
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}