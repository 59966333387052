import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {greenButtonContained, textContent} from "../../Utils/styleConfig";
import {Button, Divider} from "@mui/material";
import {useHistory} from "react-router-dom";
import dayjs from "dayjs";
import {LABEL_DOWNLOAD_URL_PREFIX_ALTERNATE} from "../../Utils/apiUrl";

export const MarketplaceOrderEach = ({order, startsWithBusiness}) => {

    const history = useHistory();

    const handleOrderDashboard = () => {
        history?.push(`${startsWithBusiness ? '/business/' : '/'}e-commerce/order/${order?.marketplace_order_id}`)
    }

    return (
        <>
            <Grid item xs={2}>
                <Typography sx={{fontSize: textContent}}>
                    {order?.order_name}
                </Typography>
                <Typography sx={{fontSize: textContent}}>
                    {dayjs?.(order?.created_at)?.format('YYYY-MM-DD')}
                </Typography>
            </Grid>
            <Grid item xs={1.5}>
                <img src={`${LABEL_DOWNLOAD_URL_PREFIX_ALTERNATE}${order?.icon_uri}`} alt="logo" width='30px'/>
            </Grid>
            <Grid item xs={3}>
                <Typography sx={{fontSize: textContent}}>
                    {order?.deliver_name}
                </Typography>
                <Typography sx={{fontSize: textContent}}>
                    {order?.deliver_city}, {order?.deliver_province}, {order?.deliver_country}, {order?.deliver_postal_code}
                </Typography>
            </Grid>
            <Grid item xs={1.5}>
                <Typography sx={{fontSize: textContent}}>
                    {order?.packages} {order?.packages === 1 ? 'item' : 'items'}
                </Typography>
            </Grid>
            <Grid item xs={2.5}>
                <Typography sx={{fontSize: textContent, wordBreak: 'break-word'}}>
                    {order?.status_name}
                </Typography>
            </Grid>
            <Grid item xs={1.5}>
                <Button
                    variant='contained'
                    sx={greenButtonContained}
                    onClick={handleOrderDashboard}
                >
                    <Typography sx={{fontSize: textContent, textTransform: 'none'}}>
                        View
                    </Typography>
                </Button>
            </Grid>
            <Grid item xs={12}>
                <Divider/>
            </Grid>
        </>
    )
}