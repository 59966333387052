import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React, {Fragment} from "react";
import {Divider} from "@mui/material";
import dayjs from "dayjs";

const style = {
    OrderDashboard: {
        border: '1px solid #B2B2B2',
        borderRadius: '10px',
        padding: '10px 0',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
    }
}

export const MovingVehicleOrderDashboardContact = ({orderData}) => {

    return (
        <Box sx={style.OrderDashboard}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        padding: '10px 20px',
                        borderBottom: '1px solid #B2B2B2'
                    }}>
                        <Typography sx={{fontSize: '16px', fontWeight: '600'}}>
                            Contact Details
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{padding: '20px'}}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Typography sx={{fontWeight: '600', textAlign: 'left'}}>
                                            Pickup Contact
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box sx={{
                                            display: 'flex',
                                            flexDirection: 'column'
                                        }}>
                                            <Typography sx={{textAlign: 'left'}}>
                                                {orderData?.sender}
                                            </Typography>
                                            <Typography sx={{textAlign: 'left'}}>
                                                {orderData?.pickup_phone}
                                            </Typography>
                                            <Typography sx={{textAlign: 'left'}}>
                                                {orderData?.pickup_email}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Typography sx={{fontWeight: '600', textAlign: 'left'}}>
                                            Deliver Contact
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box sx={{
                                            display: 'flex',
                                            flexDirection: 'column'
                                        }}>
                                            <Typography sx={{textAlign: 'left'}}>
                                                {orderData?.deliver_name}
                                            </Typography>
                                            <Typography sx={{textAlign: 'left'}}>
                                                {orderData?.deliver_phone}
                                            </Typography>
                                            <Typography sx={{textAlign: 'left'}}>
                                                {orderData?.deliver_email}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}