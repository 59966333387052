import Box from "@mui/material/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@mui/material/Typography";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {extractFullAddress, loadAsyncScript, provinces_states} from "../../../Utils/Helper";
import {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    updateCrossBorderQuickQuoteLTL,
    updateShippingLTLQuickQuoteMoveFrom,
    updateShippingLTLQuickQuoteMoveTo
} from "../../../actions/shippingLTLAction";
import Paper from "@mui/material/Paper";
import {PARTNER_URI} from "../../../Utils/apiUrl";
import axios from "axios";
import {value} from "lodash/seq";
import {internationalShipmentTypeList, transportationListToCodeMap} from "../../shared";
import {Accordion, AccordionDetails, AccordionSummary, MenuItem, Select} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import IconButton from "@mui/material/IconButton";
import HelpIcon from "@mui/icons-material/Help";
import FormControl from "@mui/material/FormControl";
import {makeStyles} from "@material-ui/core/styles";
import intl from "react-intl-universal";
import FormHelperText from "@mui/material/FormHelperText";
import FormLabel from "@mui/material/FormLabel";

const styles = {
    ShippingLTLQuickQuoteAddressTitle: {
        fontSize: '20px',
        fontWeight: '600',
        textAlign: 'left'
    },
    ShippingLTLQuickQuoteAddressHeading: {
        fontSize: '20px',
        // fontWeight: '600',
        textAlign: 'left'
    },
    ShippingLTLQuickQuoteAddressSection: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start'
    },
    ShippingLTLQuickQuoteAddressErrorText: {
        fontSize: '12px',
        color: "#FF0303"
    },
    ShippingLTLQuickQuoteAddressSectionRow: {
        display: 'flex',
        alignItems: 'center'
        // justifyContent: 'flex-end',
    }
}

const addressType = [
    {value: 'warehouse', name: 'WAREHOUSE'},
    {value: 'residential', name: 'RESIDENTIAL'}
]

const useStyles = makeStyles({
    smallInput: {
        "& .MuiInputBase-input": {
            height: "4px", // Adjust the height as per your preference
            padding: "2px", // Adjust the padding as per your preference
            fontSize: "16px", // Adjust the font size as per your preference
        }
    }
});

export const ShippingLTLQuickQuoteAddress = ({isGetRate, postalCodeValid}) => {

    const classes = useStyles();

    const dispatch = useDispatch();

    const [crossBorder, setCrossBorder] = useState({
        tranMode: "INT_TRUCK",
        shipmentType: "COMMERCIAL",
    })
    const [movingFromPostalCodePredictions, setMovingFromPostalCodePredictions] = useState([]);
    const [movingToPostalCodePredictions, setMovingToPostalCodePredictions] = useState([]);
    const [shipFrom, setShipFrom] = useState({
        city: null,
        parentCity: null,
        province: null,
        region: null,
        country: null,
        addressType: {value: 'warehouse', name: 'WAREHOUSE'},
        postalCode: null
    });
    const [shipTo, setShipTo] = useState({
        city: null,
        parentCity: null,
        province: null,
        region: null,
        country: null,
        addressType: {value: 'warehouse', name: 'WAREHOUSE'},
        postalCode: null
    });

    const googleMapsPlaceAutocomplete = async (inputValue, type) => {
        let requestURL = `${PARTNER_URI}/googleMaps/placeAutocomplete`;

        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    input: inputValue,
                    types: type
                }
            })
            const {data: {data: {predictions}}} = result;
            const updatedPredictions = predictions?.map(each => {
                return {
                    description: each?.description,
                    text: each?.structured_formatting?.main_text,
                    placeId: each?.place_id
                }
            })
            return updatedPredictions;
        } catch (e) {
            console.log(e.response)
        }
    }

    const googleMapsPlaceDetails = async (placeId) => {
        let requestURL = `${PARTNER_URI}/googleMaps/placeDetails`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    placeId: placeId
                }
            });
            const place = result?.data?.data?.result;
            return place;

        } catch (e) {
            console.log(e.response)
        }
    }

    const handleInputShipFromPostalCode = async (event, value) => {
        console.log('[ShippingLTLQuickQuoteAddress] handleInputShipFromPostalCode - value', value);
        setShipFrom(prevState => ({
            ...prevState,
            postalCode: value
        }))
        const predictions = await googleMapsPlaceAutocomplete(value, 'postal_code');
        console.log('[ShippingLTLQuickQuoteAddress] handleInputShipFromPostalCode - predictions', predictions);
        setMovingFromPostalCodePredictions(predictions);
    }

    const handleInputShipToPostalCode = async (event, value) => {
        console.log('[ShippingLTLQuickQuoteAddress] handleInputShipToPostalCode - value', value);
        setShipTo(prevState => ({
            ...prevState,
            postalCode: value
        }))
        const predictions = await googleMapsPlaceAutocomplete(value, 'postal_code');
        console.log('[ShippingLTLQuickQuoteAddress] handleInputShipToPostalCode - predictions', predictions);
        setMovingToPostalCodePredictions(predictions);
    }

    const handleChangeShipFromPostalCode = async (event, value) => {
        const place = await googleMapsPlaceDetails(value?.placeId);
        if (place) {
            const address = extractFullAddress(place);
            console.log('address from postal code ship to', address);
            setShipFrom(prevState => ({
                ...prevState,
                city: address?.sublocality || address?.city || address?.administrative_area_level_3,
                parentCity: address?.city || address?.administrative_area_level_3 || address?.region,
                province: address?.state,
                region: address?.region,
                country: address?.country?.code,
                postalCode: address?.zip,
                displayPostalCode: value
            }))
        } else {
            setShipFrom(prevState => ({
                ...prevState,
                postalCode: '',
                displayPostalCode: {
                    description: '',
                    placeId: '',
                    text: ''
                },
            }))
        }
    }

    const handleChangeShipToPostalCode = async (event, value) => {
        const place = await googleMapsPlaceDetails(value?.placeId);
        if (place) {
            const address = extractFullAddress(place);
            console.log('address from postal code ship to', address);
            setShipTo(prevState => ({
                ...prevState,
                city: address?.sublocality || address?.city || address?.administrative_area_level_3,
                parentCity: address?.city || address?.administrative_area_level_3 || address?.region,
                province: address?.state,
                region: address?.region,
                country: address?.country?.code,
                postalCode: address?.zip,
                displayPostalCode: value
            }))
        } else {
            setShipTo(prevState => ({
                ...prevState,
                postalCode: '',
                displayPostalCode: {
                    description: '',
                    placeId: '',
                    text: ''
                },
            }))
        }
    }

    useEffect(() => {
        dispatch(updateShippingLTLQuickQuoteMoveFrom(shipFrom));
    }, [shipFrom]);

    useEffect(() => {
        dispatch(updateShippingLTLQuickQuoteMoveTo(shipTo));
    }, [shipTo]);

    useEffect(() => {
        dispatch(updateCrossBorderQuickQuoteLTL(crossBorder));
    }, [crossBorder])

    // console.log('cross border', crossBorder);

    console.log('[ShippingLTLQuickQuoteAddress] ship from', shipFrom)
    console.log('[ShippingLTLQuickQuoteAddress] ship to', shipTo)
    console.log('[ShippingLTLQuickQuoteAddress] postalCodeValid', postalCodeValid)

    return (
        <Grid container spacing={4}>
            <Grid item xs={12}>
                <Typography style={styles.ShippingLTLQuickQuoteAddressTitle}>
                    {intl.get('SHIPPING_LTL_QUICK_QUOTE.ADDRESS_DETAILS')}
                </Typography>
            </Grid>
            <Grid item md={6} xs={12}>
                <Box sx={{boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)', padding: '10px', borderRadius: '10px'}}>
                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            <Typography sx={styles.ShippingLTLQuickQuoteAddressHeading}>
                                {intl.get('SHIPPING_INFORMATION.SHIP_FROM')}
                            </Typography>
                        </Grid>
                        <Grid item md={12} xs={12}>
                            <Box sx={styles.ShippingLTLQuickQuoteAddressSection}>
                                <FormControl fullWidth>
                                    <FormLabel required>
                                        {intl.get('SHIPPING_INFORMATION.POSTAL_CODE')}
                                    </FormLabel>
                                    <Autocomplete
                                        value={shipFrom?.displayPostalCode}
                                        disabled={isGetRate}
                                        filterOptions={(options, state) => options}
                                        options={movingFromPostalCodePredictions}
                                        getOptionLabel={option => option?.description}
                                        onInputChange={handleInputShipFromPostalCode}
                                        onChange={handleChangeShipFromPostalCode}
                                        fullWidth
                                        PaperComponent={props => <Paper {...props} sx={{width: 400}}/>}
                                        renderInput={params => <TextField
                                            {...params}
                                            variant='outlined'
                                            className={classes.smallInput}
                                            error={postalCodeValid !== null && (!shipFrom?.postalCode || !postalCodeValid?.shipFromPostalCodeValid)}
                                            helperText={(postalCodeValid !== null && (!shipFrom?.postalCode || !postalCodeValid?.shipFromPostalCodeValid) && intl.get('SHIPPING_PACKAGE_QUICK_QUOTE.ADDRESS.MISSING_INVALID'))}
                                        />}
                                        renderOption={(option) => (
                                            <Typography
                                                style={{fontSize: '12px'}}
                                            >
                                                {/*<Typography>*/}
                                                {option.description}
                                                {/*</Typography>*/}
                                            </Typography>
                                        )}
                                    />
                                </FormControl>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
            <Grid item md={6} xs={12}>
                <Box sx={{boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)', padding: '10px', borderRadius: '10px'}}>
                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            <Typography sx={styles.ShippingLTLQuickQuoteAddressHeading}>
                                {intl.get('SHIPPING_INFORMATION.SHIP_TO')}
                            </Typography>
                        </Grid>
                        <Grid item md={12} xs={12}>
                            <Box sx={styles.ShippingLTLQuickQuoteAddressSection}>
                                <FormControl fullWidth>
                                    <FormLabel required>
                                        {intl.get('SHIPPING_INFORMATION.POSTAL_CODE')}
                                    </FormLabel>
                                    <Autocomplete
                                        value={shipTo?.displayPostalCode}
                                        disabled={isGetRate}
                                        filterOptions={(options, state) => options}
                                        options={movingToPostalCodePredictions}
                                        getOptionLabel={option => option?.description}
                                        onInputChange={handleInputShipToPostalCode}
                                        onChange={handleChangeShipToPostalCode}
                                        fullWidth
                                        PaperComponent={props => <Paper {...props} sx={{width: 400}}/>}
                                        renderInput={params => <TextField
                                            {...params}
                                            variant='outlined'
                                            className={classes.smallInput}
                                            error={postalCodeValid !== null && (!shipTo?.postalCode || !postalCodeValid?.shipToPostalCodeValid)}
                                            helperText={(postalCodeValid !== null && (!shipTo?.postalCode || !postalCodeValid?.shipToPostalCodeValid) && intl.get('SHIPPING_PACKAGE_QUICK_QUOTE.ADDRESS.MISSING_INVALID'))}
                                        />}
                                        renderOption={(option) => (
                                            <Typography
                                                style={{fontSize: '12px'}}
                                            >
                                                {option.description}
                                            </Typography>
                                        )}
                                    />
                                </FormControl>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
        </Grid>
    )
}