import React, {Fragment, useEffect, useState} from "react";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import TextField from "@mui/material/TextField";
import {MuiTelInput} from "mui-tel-input";
import intl from "react-intl-universal";

export const WordPressQuoteContact = ({tab, cbHandleContact, validatedResult, validatedEmail, lng}) => {

    const [contact, setContact] = useState({
        email: null,
        phone: null,
    })

    const handleEmail = event => {
        const {value} = event.target;
        setContact(prevState => ({
            ...prevState,
            email: value
        }))
    }

    const handleCompanyPhone = value => {
        setContact(prevState => ({
            ...prevState,
            phone: value
        }))
    }

    useEffect(() => {
        cbHandleContact(contact);
    }, [contact])

    return (
        <Fragment>
            <Grid item md={6} xs={12}>
                <FormControl fullWidth>
                    <FormLabel required>
                        {lng === 'cn' ? '邮件' : "Email"}
                    </FormLabel>
                    <TextField
                        value={contact?.email}
                        onInput={handleEmail}
                        fullWidth
                        variant='outlined'
                        size='small'
                        InputProps={{
                            inputProps: {
                                min: 1
                            },
                            style: {fontSize: 14} // font size of input text
                        }}
                        error={validatedResult !== null && (!contact?.email || !validatedEmail?.valid)}
                        helperText={validatedResult !== null && (!contact?.email ? (lng === 'cn' ? "必填" : "Required") : !validatedEmail?.valid && (
                            validatedEmail?.validators?.mx?.reason || validatedEmail?.validators?.regex?.reason || "Invalid"))}
                    />
                </FormControl>
            </Grid>
            <Grid item md={6} xs={12}>
                <FormControl fullWidth>
                    <FormLabel>
                        {lng === 'cn' ? '电话' : "Phone"}
                    </FormLabel>
                    <MuiTelInput
                        value={contact?.phone}
                        required
                        fullWidth
                        defaultCountry="CA"
                        onChange={handleCompanyPhone}
                        size="small"
                        inputProps={{style: {fontSize: 14}}}
                        InputLabelProps={{style: {fontSize: 14}}}
                    />
                </FormControl>
            </Grid>
        </Fragment>
    )
}