import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import intl from "react-intl-universal";
import * as React from "react";
import {MenuItem, Select} from "@mui/material";
import {useEffect, useState} from "react";
import InputLabel from "@mui/material/InputLabel";
import FormLabel from "@mui/material/FormLabel";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Paper from "@mui/material/Paper";
import {makeStyles} from "@material-ui/core";
import {extractFullAddress} from "../../Utils/Helper";
import {NODE_ROUTE_URI} from "../../Utils/apiUrl";
import axios from "axios";
import {useDispatch, useSelector} from "react-redux";
import {updateLandingEstimateMoving} from "../../actions/landingEstimateAction";
import FormHelperText from "@mui/material/FormHelperText";

const useStyles = makeStyles({
    smallInput: {
        "& .MuiInputBase-input": {
            height: "4px", // Adjust the height as per your preference
            padding: "2px", // Adjust the padding as per your preference
            fontSize: "16px", // Adjust the font size as per your preference
        }
    }
});

const movingSize = [
    {description: 'One Bedroom Apartment', boxSize: 25},
    {description: 'Two Bedroom Apartment', boxSize: 38},
    {description: 'Three Bedroom Apartment', boxSize: 48},
    {description: 'Two Bedroom House', boxSize: 30},
    {description: 'Three Bedroom House', boxSize: 42},
    {description: 'Four Bedroom House', boxSize: 58},
    {description: 'Five Bedroom House', boxSize: 60},
    {description: 'Studio', boxSize: 20},
]

export const EstimateMoving = () => {

    const classes = useStyles();

    const dispatch = useDispatch();

    let missingKeys = useSelector(state => state?.landingEstimate?.landingEstimateMovingMissing);

    const [movingData, setMovingData] = useState({
        movingSize: null,
        pickupOption: 'schedule',
        pickupWarehouse: null,
        pickupCity: null,
        pickupProvince: null,
        pickupCountry: null,
        pickupPostalCode: {
            description: null,
            placeId: null,
            text: null
        },
        pickupLatitude: null,
        pickupLongitude: null,
        deliverOption: 'schedule',
        deliverWarehouse: null,
        deliverCity: null,
        deliverProvince: null,
        deliverCountry: null,
        deliverPostalCode: {
            description: null,
            placeId: null,
            text: null
        },
        deliverLatitude: null,
        deliverLongitude: null
    });

    const [predictions, setPredictions] = useState([]);

    const googleMapsPlaceAutocomplete = async (inputValue, type) => {
        let requestURL = `${NODE_ROUTE_URI}/maps_apis/placeAutocomplete`;

        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    input: inputValue,
                    types: type
                }
            })
            const {data: {data: {predictions}}} = result;
            console.log('prediction', predictions);
            const updatedPredictions = predictions?.map(each => {
                return {
                    description: each?.description,
                    text: each?.structured_formatting?.main_text,
                    placeId: each?.place_id
                }
            })
            console.log('updated prediction', updatedPredictions);

            return updatedPredictions;

        } catch (e) {
            console.log(e.response)
        }
    }

    const googleMapsPlaceDetails = async (placeId) => {
        let requestURL = `${NODE_ROUTE_URI}/maps_apis/placeDetails`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    placeId: placeId
                }
            });
            const place = result?.data?.data?.result;
            return place;

        } catch (e) {
            console.log(e.response)
        }
    }

    const handleInputChangePickupPostalCode = async (event, value) => {
        setPredictions([]);
        const result = await googleMapsPlaceAutocomplete(value, 'postal_code');
        setPredictions(result);
    }

    const handleChangePickupPostalCode = async (event, value) => {
        const place = await googleMapsPlaceDetails(value?.placeId);
        if (place) {
            const address = extractFullAddress(place);
            setMovingData(prevState => ({
                ...prevState,
                pickupCity: address?.city,
                pickupProvince: address?.state?.code,
                pickupCountry: address?.country?.code,
                pickupPostalCode: value,
                pickupLatitude: place?.geometry?.location?.lat,
                pickupLongitude: place?.geometry?.location?.lng
            }))
        } else {
            setMovingData(prevState => ({
                ...prevState,
                pickupCity: '',
                pickupProvince: '',
                pickupCountry: '',
                pickupPostalCode: {
                    ...prevState.deliverPostalCode,
                    description: '',
                    placeId: '',
                    text: ''
                },
                pickupLatitude: '',
                pickupLongitude: ''
            }))
        }
    }

    const handleInputChangeDeliverPostalCode = async (event, value) => {
        setPredictions([]);
        const result = await googleMapsPlaceAutocomplete(value, 'postal_code');
        setPredictions(result);
    }

    const handleChangeDeliverPostalCode = async (event, value) => {
        const place = await googleMapsPlaceDetails(value?.placeId);
        if (place) {
            const address = extractFullAddress(place);
            setMovingData(prevState => ({
                ...prevState,
                deliverCity: address?.city,
                deliverProvince: address?.state?.code,
                deliverCountry: address?.country?.code,
                deliverPostalCode: value,
                deliverLatitude: place?.geometry?.location?.lat,
                deliverLongitude: place?.geometry?.location?.lng
            }))
        } else {
            setMovingData(prevState => ({
                ...prevState,
                deliverCity: '',
                deliverProvince: '',
                deliverCountry: '',
                deliverPostalCode: {
                    ...prevState.deliverPostalCode,
                    description: '',
                    placeId: '',
                    text: ''
                },
                deliverLatitude: '',
                deliverLongitude: ''
            }))
        }
    }

    const handleSelectMovingSize = (event) => {
        const value = event?.target?.value;
        setMovingData(prevState => ({
            ...prevState,
            movingSize: value
        }))
    }

    useEffect(() => {
        dispatch(updateLandingEstimateMoving(movingData));
    }, [movingData])

    console.log('[EstimateMoving] moving data', movingData);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <FormControl fullWidth>
                    <FormLabel sx={{textAlign: 'left'}}
                               required>
                        Moving Size
                    </FormLabel>
                    <Select
                        value={movingData?.movingSize}
                        label='Moving Size'
                        fullWidth
                        variant="standard"
                        onChange={handleSelectMovingSize}
                        size='small'
                        error={missingKeys?.includes('movingSize')}
                    >
                        {
                            movingSize?.map((data, index) => (
                                <MenuItem key={index} value={data}>
                                    {data?.description}
                                </MenuItem>
                            ))
                        }
                    </Select>
                    {
                        missingKeys?.includes('movingSize') &&
                        <FormHelperText sx={{color: 'error.main'}}>
                            {intl.get('LANDING_PAGE.CALCULATOR.REQUIRED')}
                        </FormHelperText>
                    }
                </FormControl>
            </Grid>
            <Grid item xs={6}>
                <FormControl fullWidth>
                    <FormLabel sx={{textAlign: 'left'}}
                               required>
                        Pickup Postal Code
                    </FormLabel>
                    <Autocomplete
                        value={movingData?.pickupPostalCode}
                        fullWidth
                        options={predictions}
                        filterOptions={(options, state) => options}
                        getOptionLabel={option => option?.text}
                        onInputChange={handleInputChangePickupPostalCode}
                        onChange={handleChangePickupPostalCode}
                        PaperComponent={props => <Paper {...props} sx={{width: 400}}/>}
                        renderInput={params => <TextField
                            {...params}
                            variant='standard'
                            error={missingKeys.includes('movingFrom')}
                            helperText={missingKeys?.includes('movingFrom') && intl.get('LANDING_PAGE.CALCULATOR.REQUIRED')}
                        />}
                        renderOption={option => (
                            <Typography style={{fontSize: '12px'}}>
                                {option.description}
                            </Typography>
                        )}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={6}>
                <FormControl fullWidth>
                    <FormLabel sx={{textAlign: 'left'}}
                               required>
                        Deliver Postal Code
                    </FormLabel>
                    <Autocomplete
                        value={movingData?.deliverPostalCode}
                        fullWidth
                        options={predictions}
                        filterOptions={(options, state) => options}
                        getOptionLabel={option => option?.text}
                        onInputChange={handleInputChangeDeliverPostalCode}
                        onChange={handleChangeDeliverPostalCode}
                        PaperComponent={props => <Paper {...props} sx={{width: 400}}/>}
                        renderInput={params => <TextField
                            {...params}
                            variant='standard'
                            error={missingKeys.includes('movingTo')}
                            helperText={missingKeys?.includes('movingTo') && intl.get('LANDING_PAGE.CALCULATOR.REQUIRED')}
                        />}
                        renderOption={option => (
                            <Typography style={{fontSize: '12px'}}>
                                {option.description}
                            </Typography>
                        )}
                    />
                </FormControl>
            </Grid>
        </Grid>
    )
}