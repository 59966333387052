import {useSelector} from "react-redux";
import React, {Fragment, useEffect, useState} from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {Collapse, Divider, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import Paper from "@mui/material/Paper";
import {MovingServiceCarRateRow} from "./MovingServiceCarRateRow";
import CircularProgress from "@material-ui/core/CircularProgress";

export const MovingServiceCarRate = () => {

    let basicRateInfo = useSelector(state => state?.movingService?.movingServiceBasicRateInfo);
    let carRate = useSelector(state => state?.movingService?.movingServiceBasicRateInfoFromAI);

    console.log('[MovingServiceRate] basic rate info', basicRateInfo);
    console.log('[MovingServiceRate] car rate', carRate);

    return (
        <Grid item xs={12}>
            <Grid container spacing={2}>
                {
                    (+basicRateInfo?.finalTotal || +carRate?.total) > 0 ?
                        <>
                            <Grid item xs={6}>
                                <Typography sx={{fontSize: '16px', textAlign: 'left'}}>
                                    Subtotal
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography sx={{fontSize: '16px', textAlign: 'right'}}>
                                    $ {(+basicRateInfo?.totalCharge || +carRate?.subtotal).toFixed(2)}
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography sx={{fontSize: '16px', textAlign: 'left'}}>
                                    Tax
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography sx={{fontSize: '16px', textAlign: 'right'}}>
                                    $ {(+basicRateInfo?.taxCharge || +carRate?.tax || 0).toFixed(2)}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider/>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography sx={{fontSize: '16px', textAlign: 'left'}}>
                                    Total
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography sx={{fontSize: '16px', textAlign: 'right'}}>
                                    $ {(+basicRateInfo?.finalTotal || +carRate?.total)?.toFixed(2)}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography sx={{fontSize: '16px', textAlign: 'left'}}>
                                    Estimated shipping time
                                    is {basicRateInfo?.shippingDaysRange || carRate?.days ? `${basicRateInfo?.shippingDaysRange || carRate?.days} days` : 'N/A'}.
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography>
                                    The estimated cost is for reference only. Once you book the shipment, an
                                    operator will process your request and confirm the final total with you.
                                </Typography>
                            </Grid>
                        </>
                        :
                        <Grid item xs={12}>
                            <Typography>
                                Based on the selections you've made, a quote cannot be provided to you immediately.
                                Please select "Book" to send this to our operation team.
                            </Typography>
                        </Grid>
                }
            </Grid>
        </Grid>
    )
}