import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import * as React from "react";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import LoadingButton from "@mui/lab/LoadingButton";
import {useEffect, useState} from "react";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import EmailIcon from "../../../images/emailTransferIcon.png";
import CreditIcon from "../../../images/creditCardIcon.png";
import {ShippingOrderDetailsETransfer} from "./ShippingOrderDetailsETransfer";
import {ShippingOrderDetailsCreditCard} from "./ShippingOrderDetailsCreditCard";
import {ShippingOrderDetailsOther} from "./ShippingOrderDetailsOther";
import wechatPay from "../../../images/wechatPay.svg";
import aliPay from "../../../images/alipay.svg";
import {ShippingOrderDetailsWeChat} from "./ShippingOrderDetailsWeChat";
import {ShippingOrderDetailsAliPay} from "./ShippingOrderDetailsAliPay";
import {ShippingOrderDetailsPayPal} from "./ShippingOrderDetailsPayPal";
import {ReactComponent as ShipmentOverviewIcon} from "../../../images/NewDesignIcon/ShipmentOverview.svg";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import {MenuItem, Select} from "@mui/material";
import {ShippingOrderDetailsAvailableCredit} from "./ShippingOrderDetailsAvailableCredit";
import axios from "axios";
import {GET_PARTNER_INFO_API, NODE_ROUTE_URI, PARTNER_URI} from "../../../Utils/apiUrl";
import {getAccessToken} from "../../../Utils/doToken";
import FormLabel from "@mui/material/FormLabel";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import {costWithCanadianCurrencyCode, isCardExpiryValid} from "../../../Utils/Helper";
import intl from "react-intl-universal";
import {useSelector} from "react-redux";
import {greyButtonContained} from "../../../Utils/styleConfig";
import {Coupon} from "../../Coupon/Coupon";

const styles = {
    ShippingPackageCreateLabelOrderDetailsRoot: {
        backgroundColor: '#FFFFFF',
        paddingBottom: '20px',
        marginBottom: '40px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        gap: '20px',
        display: 'flex',
        flexDirection: 'column',
        margin: '20px 0'
    },
    ShippingPackageCreateLabelOrderDetailsHeading: {
        fontSize: '18px',
        fontWeight: '600',
        textAlign: 'left'
    },
    ShippingPackageCreateLabelOrderDetailsSubHeading: {
        fontSize: '18px',
        fontWeight: '600',
        textAlign: 'left'
    },
    ShippingPackageCreateLabelOrderDetailsTitle: {
        fontSize: '16px',
        textAlign: 'center'
    },
    ShippingPackageCreateLabelOrderDetailsTextLeft: {
        fontSize: '16px',
        textAlign: 'left'
    },
    ShippingPackageCreateLabelOrderDetailsTextRight: {
        fontSize: '16px',
        textAlign: 'right'
    },
    ShippingPackageCreateLabelOrderDetailsTextRightColor: {
        fontSize: '16px',
        textAlign: 'right',
        color: '#1D8B45'
    },
    ShippingPackageCreateLabelOrderDetailsTitleRight: {
        fontSize: '18px',
        textAlign: 'right',
        fontWeight: '600'
    },
    ShippingPackageCreateLabelOrderDetailsSection: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '40px',
        borderRadius: '10px',
        // boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        border: '1px solid #A9A9A9',
        gap: '20px',
        display: 'flex',
        flexDirection: 'column',
    },
    ShippingPackageCreateLabelOrderDetailsSectionPrice: {
        // backgroundColor: '#FFFFFF',
        padding: '20px',
        // borderRadius: '10px 10px 0 0',
        // border: '2px solid #609966',
        gap: '20px',
        display: 'flex',
        flexDirection: 'column',
    },
    ShippingPackageCreateLabelOrderDetailsOtherBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        height: '100%'
    },
    ShippingPackageCreateLabelOrderDetailsOtherBoxRight: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        height: '100%'
    },
    ShippingPackageCreateLabelOrderDetailsOtherBoxCenter: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%'
    },
    ShippingPackageCreateLabelOrderDetailsImageBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        paddingLeft: '20px',
        gap: '10px'
    },
    ShippingOrderDetailsPayment: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        flexDirection: 'column',
        gap: '20px'
    },
}

export const ShippingOrderDetailsPayment = ({
                                                orderDetails,
                                                orderId,
                                                pickupRate,
                                                creditCardList,
                                                getValidCardList,
                                                getBackendOrderInfoWithBackendOrderId
                                            }) => {

    const storedToken = getAccessToken("access_token");

    let {id: userId} = useSelector(state => state?.user);

    const [selectPayment, setSelectPayment] = useState('card');
    const [availableCredit, setAvailableCredit] = useState(0);
    const [useCredit, setUseCredit] = useState(false);
    const [unpaidAmount, setUnpaidAmount] = useState(0);
    const [orderPrice, setOrderPrice] = useState(0);
    const [loading, setLoading] = useState(false);

    const handleSelectPayment = (event) => {
        setSelectPayment(event?.target?.value);
    }

    const getAvailableCredit = async () => {
        let requestURL = `${NODE_ROUTE_URI}/billingCenter/accountDetail`;

        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${storedToken}`,
                }
            });
            console.log('[ShippingOrderDetailsPayment] - getAvailableCredit result', result);
            const {data: {absolute_creditnote, current_bill_balance, not_billed_amount, not_paid_amount}} = result;
            setAvailableCredit(current_bill_balance);
            // setAvailableCredit(20); // test
        } catch (e) {
            console.log('[ShippingOrderDetailsPayment] - getAvailableCredit error', e.response);
        }
    }

    const handleCancelOrder = async () => {
        try {
            setLoading(true);
            const requestURL = `${NODE_ROUTE_URI}/looseItem/cancelBackendOrderByBackendOrderId`;
            const result = await axios({
                method: 'delete',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${storedToken}`,
                },
                params: {
                    backendOrderId: orderId
                }
            });
        } catch (e) {
            console.log('[ShippingOrderDetailsPayment] - handleCancelOrder error', e.response);
        } finally {
            setLoading(false)
        }
    }

    const handleUseCredit = (event) => {
        setUseCredit(event?.target?.checked);
    }

    useEffect(() => {
        (async () => await getAvailableCredit()
        )()
    }, []);

    useEffect(() => {
        const pickupFee = +pickupRate?.totalChargeWithTaxes || 0;
        const orderFee = +pickupFee + +orderDetails?.prices?.final_total;
        const amount = +pickupFee + +orderDetails?.prices?.unpaidAmount;
        setOrderPrice(orderFee);
        setUnpaidAmount(amount);
    }, [orderDetails, pickupRate]);

    useEffect(() => {
        const pickupFee = +pickupRate?.totalChargeWithTaxes || 0;
        const amount = +pickupFee + +orderDetails?.prices?.unpaidAmount;
        if (creditCardList?.length > 0 && +availableCredit > +amount && isCardExpiryValid(creditCardList?.[0]?.card_expiry)) {
            setSelectPayment('credit');
        }
    }, [availableCredit]);

    useEffect(() => {
        const pickupFee = +pickupRate?.totalChargeWithTaxes || 0;
        const amount = +pickupFee + +orderDetails?.prices?.unpaidAmount;
        if (useCredit) {
            const remainAmount = +amount - +availableCredit;
            setUnpaidAmount(remainAmount);
        } else {
            setUnpaidAmount(+amount);
        }
    }, [useCredit]);

    console.log('[ShippingOrderDetailsPayment] pickupRate', pickupRate);
    console.log('[ShippingOrderDetailsPayment] userId', userId);

    return (
        <Box sx={styles.ShippingPackageCreateLabelOrderDetailsRoot}>
            <Box sx={{
                display: 'flex',
                gap: '10px',
                padding: '20px 20px 0 20px'
            }}>
                <ShipmentOverviewIcon width={25} height={25}/>
                <Typography style={styles.ShippingPackageCreateLabelOrderDetailsHeading}>
                    Payment Methods
                </Typography>
            </Box>
            <Divider/>
            <Box sx={{
                backgroundColor: '#FFFFFF',
                padding: '0 40px',
                gap: '40px',
                display: 'flex',
            }}>
                <Grid container spacing={1}>
                    <Grid item md={6} xs={12}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Coupon orderId={orderId} refreshBackendOrder={getBackendOrderInfoWithBackendOrderId}/>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <FormLabel>
                                        <Typography sx={{fontWeight: '600'}}>
                                            {intl.get('BILLING_CENTER.PAYMENT.SELECT_PAYMENT')}
                                        </Typography>
                                    </FormLabel>
                                    <Select
                                        value={selectPayment}
                                        size='small'
                                        onChange={handleSelectPayment}
                                    >
                                        <MenuItem value='card'>
                                            {intl.get('BILLING_CENTER.PAYMENT.CREDIT_CARD.CREDIT_CARD')}
                                        </MenuItem>
                                        <MenuItem value='wechatPay'
                                                  disabled={(creditCardList?.length === 0 || !isCardExpiryValid(creditCardList?.[0]?.card_expiry)) && +userId !== 1 && +userId !== 245}>
                                            {intl.get('BILLING_CENTER.PAYMENT.WECHAT.WECHAT_PAY')}
                                        </MenuItem>
                                        <MenuItem value='aliPay'
                                                  disabled={(creditCardList?.length === 0 || !isCardExpiryValid(creditCardList?.[0]?.card_expiry)) && +userId !== 1 && +userId !== 245}>
                                            {intl.get('BILLING_CENTER.PAYMENT.ALI.ALI_PAY')}
                                        </MenuItem>
                                        <MenuItem value="credit"
                                                  disabled={(creditCardList?.length === 0 || !isCardExpiryValid(creditCardList?.[0]?.card_expiry)) && +userId !== 1 && +userId !== 245}>
                                            UUCargo Credit (available balance&nbsp;<span
                                            style={{fontWeight: '600'}}>${availableCredit}</span>)
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            {
                                (!pickupRate && +availableCredit > 0 && +availableCredit < (+orderDetails?.prices?.unpaidAmount + (+pickupRate?.totalChargeWithTaxes || 0)) && selectPayment !== 'credit') &&
                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <FormLabel>
                                            <Typography sx={{fontWeight: '600'}}>
                                                UUCargo Credit
                                            </Typography>
                                        </FormLabel>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={useCredit}
                                                    onChange={handleUseCredit}
                                                    sx={{
                                                        color: 'green',
                                                        '&.Mui-checked': {
                                                            color: 'green',
                                                        },
                                                    }}
                                                    style={{
                                                        color: 'green',
                                                        '&.Mui-checked': {
                                                            color: 'green',
                                                        }
                                                    }}
                                                    disabled={creditCardList?.length === 0}
                                                />
                                            }
                                            label={
                                                <>
                                                    Use Available Credit:&nbsp;
                                                    <span
                                                        style={{fontWeight: '600'}}>{costWithCanadianCurrencyCode(orderDetails?.send_from_country, orderDetails?.send_to_country, +availableCredit)}
                                                    </span>
                                                </>
                                            }
                                        />
                                    </FormControl>
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                    <Grid item xs={6}>
                        <Grid container spacing={1}>
                            <Grid item xs={6}>
                                <Typography
                                    style={styles.ShippingPackageCreateLabelOrderDetailsTextRight}>
                                    Order Price
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography
                                    style={styles.ShippingPackageCreateLabelOrderDetailsTextRight}>
                                    {costWithCanadianCurrencyCode(orderDetails?.send_from_country, orderDetails?.send_to_country, +orderPrice)}
                                </Typography>
                            </Grid>
                            {
                                useCredit &&
                                <>
                                    <Grid item xs={6}>
                                        <Typography
                                            style={styles.ShippingPackageCreateLabelOrderDetailsTextRightColor}>
                                            UUCargo Credit
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography
                                            style={styles.ShippingPackageCreateLabelOrderDetailsTextRightColor}>
                                            -{costWithCanadianCurrencyCode(orderDetails?.send_from_country, orderDetails?.send_to_country, +availableCredit)}
                                        </Typography>
                                    </Grid>
                                </>
                            }
                            <Grid item xs={12}>
                                <Divider/>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography
                                    style={styles.ShippingPackageCreateLabelOrderDetailsTitleRight}>
                                    Total Amount Due
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography
                                    style={styles.ShippingPackageCreateLabelOrderDetailsTitleRight}>
                                    {costWithCanadianCurrencyCode(orderDetails?.send_from_country, orderDetails?.send_to_country, +unpaidAmount)}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={styles.ShippingOrderDetailsPayment}>
                            {
                                selectPayment === 'card' &&
                                <ShippingOrderDetailsCreditCard orderDetails={orderDetails}
                                                                orderId={orderId}
                                                                useCredit={useCredit}
                                                                refreshGetValidCardList={getValidCardList}/>
                            }
                            {
                                selectPayment === 'wechatPay' &&
                                <ShippingOrderDetailsWeChat orderDetails={orderDetails} orderId={orderId}
                                                            useCredit={useCredit}/>
                            }
                            {
                                selectPayment === 'aliPay' &&
                                <ShippingOrderDetailsAliPay orderDetails={orderDetails} orderId={orderId}
                                                            useCredit={useCredit}/>
                            }
                            {
                                selectPayment === 'credit' &&
                                <ShippingOrderDetailsAvailableCredit orderDetails={orderDetails}
                                                                     orderId={orderId}
                                                                     availableCredit={availableCredit}
                                                                     pickupRate={pickupRate}/>
                            }
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'flex-end'
                        }}>
                            <LoadingButton
                                variant='contained'
                                sx={greyButtonContained}
                                loading={loading}
                                onClick={handleCancelOrder}
                            >
                                <Typography sx={{textTransform: 'none'}}>
                                    Cancel Order
                                </Typography>
                            </LoadingButton>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}