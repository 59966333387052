import React, {Fragment, useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@mui/material/TextField";
import {InputAdornment} from "@material-ui/core";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

const useStyles = makeStyles({
    smallInput: {
        "& .MuiInputBase-input": {
            height: "0px", // Adjust the height as per your preference
            padding: "2px", // Adjust the padding as per your preference
            fontSize: "14px", // Adjust the font size as per your preference
        }
    }
});

const unitType = [
    {name: 'Metric', lengthUnit: 'cm', weightUnit: 'kg'},
    {name: 'Imperial', lengthUnit: 'in', weightUnit: 'lb'}
]

export const WordPressQuotePackageList = ({cbHandleShippingDetails, validatedResult, lng}) => {

    const classes = useStyles();

    const [packageDetail, setPackageDetail] = useState({
        unitType: {
            name: "Imperial",
            lengthUnit: "in",
            weightUnit: "lb"
        },
        length: "10",
        width: "10",
        height: "10",
        weight: "10",
        lengthUnit: 'in',
        weightUnit: 'lb',
        quantity: "1"
    });

    const handleUnitType = (event, value) => {
        setPackageDetail(prevState => ({
            ...prevState,
            unitType: value,
            lengthUnit: value?.lengthUnit,
            weightUnit: value?.weightUnit,
        }))
    }

    const handleLength = event => {
        const {value} = event.target;
        const maximum = packageDetail?.lengthUnit === 'in' ? 108 : 274;
        if (/^\d*\.?\d*$/.test(value)) {
            setPackageDetail(prevState => ({
                ...prevState,
                length: value > (+maximum) ? maximum : value
            }))
        }
    }

    const handleWidth = event => {
        const {value} = event.target;
        const maximum = packageDetail?.lengthUnit === 'in' ? 108 : 274;
        if (/^\d*\.?\d*$/.test(value)) {
            setPackageDetail(prevState => ({
                ...prevState,
                width: value > (+maximum) ? maximum : value
            }))
        }
    }

    const handleHeight = event => {
        const {value} = event.target;
        const maximum = packageDetail?.lengthUnit === 'in' ? 108 : 274;
        if (/^\d*\.?\d*$/.test(value)) {
            setPackageDetail(prevState => ({
                ...prevState,
                height: value > (+maximum) ? maximum : value
            }))
        }
    }

    const handleWeight = event => {
        const {value} = event.target;
        if (/^\d*\.?\d*$/.test(value)) {
            const maximum = packageDetail?.weightUnit === 'lb' ? 150 : 68;
            setPackageDetail(prevState => ({
                ...prevState,
                weight: value > (+maximum) ? maximum : value
            }))
        }
    }

    const handleQuantity = event => {
        const {value} = event.target;
        if (/^\d*\.?\d*$/.test(value)) {
            const maximum = 10;
            setPackageDetail(prevState => ({
                ...prevState,
                quantity: value > (+maximum) ? maximum : value
            }))
        }
    }

    useEffect(() => {
        cbHandleShippingDetails(packageDetail);
    }, [packageDetail]);

    return (
        <Fragment>
            <Grid item md={4} xs={12}>
                <FormControl fullWidth>
                    <FormLabel required>
                        {lng === 'cn' ? '单位类型' : "Unit Type"}
                    </FormLabel>
                    <Autocomplete
                        value={packageDetail?.unitType}
                        fullWidth
                        options={unitType}
                        getOptionLabel={option => `${option?.name} | ${option?.lengthUnit} ${option?.weightUnit}`}
                        onChange={handleUnitType}
                        renderInput={(params) => <TextField
                            variant="outlined"
                            {...params}
                            className={classes.smallInput}
                            error={validatedResult !== null && !packageDetail?.unitType?.name}
                            helperText={(validatedResult !== null && !packageDetail?.unitType?.name) && (lng === 'cn' ? "必填" : "Required")}
                        />}
                        renderOption={option => (
                            <Typography style={{fontSize: '14px'}}>
                                {option?.name} | {option?.lengthUnit} {option?.weightUnit}
                            </Typography>
                        )}
                    />
                </FormControl>
            </Grid>
            <Grid item md={4} xs={12}>
                <FormControl fullWidth>
                    <FormLabel required>
                        {lng === 'cn' ? '数量' : "Quantity"}
                    </FormLabel>
                    <TextField
                        value={packageDetail?.quantity}
                        onInput={handleQuantity}
                        fullWidth
                        variant='outlined'
                        type='number'
                        size='small'
                        InputProps={{
                            inputProps: {
                                min: 1
                            },
                            style: {fontSize: 14}
                        }}
                        error={validatedResult !== null && (+packageDetail?.quantity <= 0 || !packageDetail?.quantity)}
                        helperText={validatedResult !== null && (!packageDetail?.quantity ? (lng === 'cn' ? "必填" : "Required") : +packageDetail?.quantity <= 0 && (lng === 'cn' ? "无效" : "Invalid"))}
                    />
                </FormControl>
            </Grid>
            <Grid item md={4} xs={12}>
                <FormControl fullWidth>
                    <FormLabel required>
                        {lng === 'cn' ? '重量' : "Weight"}
                    </FormLabel>
                    <TextField
                        value={packageDetail?.weight}
                        onInput={handleWeight}
                        fullWidth
                        variant='outlined'
                        type='number'
                        size='small'
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    {packageDetail?.weightUnit}
                                </InputAdornment>
                            ),
                            inputProps: {
                                min: 1
                            },
                            style: {fontSize: 14}
                        }}
                        error={validatedResult !== null && (+packageDetail?.weight <= 0 || !packageDetail?.weight)}
                        helperText={validatedResult !== null && (!packageDetail?.weight ? (lng === 'cn' ? "必填" : "Required") : +packageDetail?.weight <= 0 && (lng === 'cn' ? "无效" : "Invalid"))}
                    />
                </FormControl>
            </Grid>
            <Grid item md={4} xs={12}>
                <FormControl fullWidth>
                    <FormLabel required>
                        {lng === 'cn' ? '长' : "Length"}
                    </FormLabel>
                    <TextField
                        value={packageDetail?.length}
                        onInput={handleLength}
                        fullWidth
                        variant='outlined'
                        type='number'
                        size='small'
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    {packageDetail?.lengthUnit}
                                </InputAdornment>
                            ),
                            inputProps: {
                                min: 1
                            },
                            style: {fontSize: 14}
                        }}
                        error={validatedResult !== null && (+packageDetail?.length <= 0 || !packageDetail?.length)}
                        helperText={validatedResult !== null && (!packageDetail?.length ? (lng === 'cn' ? "必填" : "Required") : +packageDetail?.length <= 0 && (lng === 'cn' ? "无效" : "Invalid"))}
                    />
                </FormControl>
            </Grid>
            <Grid item md={4} xs={12}>
                <FormControl fullWidth>
                    <FormLabel required>
                        {lng === 'cn' ? '宽' : "Width"}
                    </FormLabel>
                    <TextField
                        value={packageDetail?.width}
                        onInput={handleWidth}
                        fullWidth
                        variant='outlined'
                        type='number'
                        size='small'
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    {packageDetail?.lengthUnit}
                                </InputAdornment>
                            ),
                            inputProps: {
                                min: 1
                            },
                            style: {fontSize: 14}
                        }}
                        error={validatedResult !== null && (+packageDetail?.width <= 0 || !packageDetail?.width)}
                        helperText={validatedResult !== null && (!packageDetail?.width ? (lng === 'cn' ? "必填" : "Required") : +packageDetail?.width <= 0 && (lng === 'cn' ? "无效" : "Invalid"))}
                    />
                </FormControl>
            </Grid>
            <Grid item md={4} xs={12}>
                <FormControl fullWidth>
                    <FormLabel required>
                        {lng === 'cn' ? '高' : "Height"}
                    </FormLabel>
                    <TextField
                        value={packageDetail?.height}
                        onInput={handleHeight}
                        fullWidth
                        variant='outlined'
                        type='number'
                        size='small'
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    {packageDetail?.lengthUnit}
                                </InputAdornment>
                            ),
                            inputProps: {
                                min: 1
                            },
                            style: {fontSize: 14}
                        }}
                        error={validatedResult !== null && (+packageDetail?.height <= 0 || !packageDetail?.height)}
                        helperText={validatedResult !== null && (!packageDetail?.height ? (lng === 'cn' ? "必填" : "Required") : +packageDetail?.height <= 0 && (lng === 'cn' ? "无效" : "Invalid"))}
                    />
                </FormControl>
            </Grid>
        </Fragment>
    )
}