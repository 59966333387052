import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import {Divider, InputAdornment, MenuItem, Select} from "@mui/material";
import SearchIcon from "@material-ui/icons/Search";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Typography from "@mui/material/Typography";
import {Fragment, useEffect, useState} from "react";
import {greenButtonContained, textContent} from "../../Utils/styleConfig";
import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import {MarketplaceOrderEach} from "./MarketplaceOrderEach";
import {NODE_ROUTE_URI, PARTNER_URI} from "../../Utils/apiUrl";
import axios from "axios";
import {getAccessToken} from "../../Utils/doToken";
import CircularProgress from "@material-ui/core/CircularProgress";
import {useDispatch, useSelector} from "react-redux";
import {updateECommerceProfileId} from "../../actions/eCommerceAction";
import {Pagination} from "@mui/lab";

export const MarketplaceOrderList = ({
                                         startsWithBusiness,
                                         isSyncOrder,
                                         clearSyncOrder,
                                         cbHandlePlatform,
                                         storeList,
                                         platform
                                     }) => {

    const dispatch = useDispatch();
    const storedToken = getAccessToken("access_token");

    let storeId = useSelector(state => state?.eCommerce?.profileId);

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [fulfillment, setFulfillment] = useState('all');
    const [orderData, setOrderData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [profileId, setProfileId] = useState('');
    // const [platform, setPlatform] = useState('shopify');
    const [searchString, setSearchString] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [lastPage, setLastPage] = useState(0);

    const handleSearchString = (event) => {
        setSearchString(event.target.value);
    }

    const getMarketplaceOrderList = async () => {
        try {
            setLoading(true)
            let requestURL = startsWithBusiness ? `${PARTNER_URI}/marketplace/getMarketplaceOrderList` : `${NODE_ROUTE_URI}/marketplace/getMarketplaceOrderList`;

            const result = await axios({
                method: 'get',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${storedToken}`,
                },
                params: {
                    profileId: storeId
                }
            })
            setOrderData(result?.data);
        } catch (e) {
            console.log('[getMarketplaceOrderList] error', e?.response);
        } finally {
            setLoading(false);
            clearSyncOrder();
        }
    }

    const searchMarketplaceOrderList = async () => {
        try {
            setLoading(true);
            let requestURL = startsWithBusiness ? `${PARTNER_URI}/elasticsearch/marketplaceOrder/searchMarketplaceOrders` : `${NODE_ROUTE_URI}/elasticsearch/marketplaceOrder/searchMarketplaceOrders`;

            const result = await axios({
                method: 'get',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${storedToken}`,
                },
                params: {
                    queryString: searchString,
                    profileId: storeId,
                    page: 0,
                    pageSize: 20,
                    createDateAfter: startDate,
                    createDateBefore: endDate
                }
            })
            setOrderData(result?.data?.result);
            setCurrentPage(result?.data?.pagination?.currentPage + 1);
            setLastPage(result?.data?.pagination?.lastPage + 1);
        } catch (e) {

        } finally {
            setLoading(false);
        }
    }

    const searchMarketplaceOrderListByPage = async (page) => {
        try {
            setLoading(true);
            let requestURL = startsWithBusiness ? `${PARTNER_URI}/elasticsearch/marketplaceOrder/searchMarketplaceOrders` : `${NODE_ROUTE_URI}/elasticsearch/marketplaceOrder/searchMarketplaceOrders`;

            const result = await axios({
                method: 'get',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${storedToken}`,
                },
                params: {
                    queryString: searchString,
                    profileId: storeId,
                    page: page,
                    pageSize: 20,
                    createDateAfter: startDate,
                    createDateBefore: endDate
                }
            })
            setOrderData(result?.data?.result);
            setCurrentPage(result?.data?.pagination?.currentPage + 1);
            setLastPage(result?.data?.pagination?.lastPage + 1);
        } catch (e) {

        } finally {
            setLoading(false);
        }
    }

    const handleFulfillment = (event) => {
        const inputValue = event.target.value;
        setFulfillment(inputValue);
    }

    const handleProfileId = (event) => {
        const inputValue = event.target.value;
        setProfileId(inputValue);
        dispatch(updateECommerceProfileId(inputValue));
    }

    const handlePlatform = (event) => {
        const inputValue = event.target.value;
        // setPlatform(inputValue);
        cbHandlePlatform(inputValue);
    }

    const handleStartDate = (event) => {
        const inputDate = event.target.value;
        if (endDate) {
            if (inputDate <= endDate) {
                setStartDate(inputDate);
            } else {
                setStartDate(endDate);
            }
        } else {
            setStartDate(inputDate);
        }
    }

    const handleEndDate = (event) => {
        const inputDate = event.target.value;
        if (startDate) {

            if (inputDate >= startDate) {
                setEndDate(inputDate);
            } else {
                setEndDate(startDate);
            }
        } else {
            setEndDate(inputDate);
        }
    }

    const handleSearch = async () => {
        await searchMarketplaceOrderList();
    }

    const handlePageChange = async (event, value) => {
        console.log('page change', value);
        setCurrentPage(value);
        const page = value - 1;
        await searchMarketplaceOrderListByPage(page);
    };

    useEffect(() => {
        (async () => {
            if (isSyncOrder) {
                await getMarketplaceOrderList();
            }
        })()
    }, [isSyncOrder])

    useEffect(() => {
        (async () => {
            if (storeId) {
                setProfileId(storeId);
                await getMarketplaceOrderList();
            }
        })()
    }, [storeId]);

    console.log('[MarketplaceOrderList] profile id', profileId);
    console.log('[MarketplaceOrderList] orderData', orderData);
    console.log('[MarketplaceOrderList] startDate', startDate);
    console.log('[MarketplaceOrderList] endDate', endDate);
    console.log('[MarketplaceOrderList] searchString', searchString);

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '20px'
        }}>
            <Grid container spacing={2}>
                <Grid item xs={2}>
                    <FormControl fullWidth>
                        <FormLabel>
                            <Typography sx={{textAlign: 'left', fontSize: textContent}}>
                                Fulfillment
                            </Typography>
                        </FormLabel>
                        <Select
                            value={fulfillment}
                            size='small'
                            style={{textAlign: 'left', fontSize: '14px'}}
                            onChange={handleFulfillment}
                        >
                            <MenuItem value='all'>
                                <Typography sx={{textAlign: 'left', fontSize: textContent}}>
                                    All Orders
                                </Typography>
                            </MenuItem>
                            <MenuItem value='fulfilled'>
                                <Typography sx={{textAlign: 'left', fontSize: textContent}}>
                                    Fulfilled Orders
                                </Typography>
                            </MenuItem>
                            <MenuItem value='unfulfilled'>
                                <Typography sx={{textAlign: 'left', fontSize: textContent}}>
                                    Unfulfilled Orders
                                </Typography>
                            </MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={2}>
                    <FormControl fullWidth>
                        <FormLabel>
                            <Typography sx={{textAlign: 'left', fontSize: textContent}}>
                                Platform
                            </Typography>
                        </FormLabel>
                        <Select
                            value={platform}
                            size='small'
                            style={{textAlign: 'left', fontSize: '14px'}}
                            onChange={handlePlatform}
                        >
                            <MenuItem value='shopify'>
                                <Typography sx={{textAlign: 'left', fontSize: textContent}}>
                                    Shopify
                                </Typography>
                            </MenuItem>
                            <MenuItem value='wix'>
                                <Typography sx={{textAlign: 'left', fontSize: textContent}}>
                                    Wix
                                </Typography>
                            </MenuItem>
                            <MenuItem value='woocommerce'>
                                <Typography sx={{textAlign: 'left', fontSize: textContent}}>
                                    WooCommerce
                                </Typography>
                            </MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={2}>
                    <FormControl fullWidth>
                        <FormLabel>
                            <Typography sx={{textAlign: 'left', fontSize: textContent}}>
                                Store
                            </Typography>
                        </FormLabel>
                        <Select
                            value={profileId}
                            size='small'
                            style={{textAlign: 'left', fontSize: '14px'}}
                            onChange={handleProfileId}
                        >
                            {
                                storeList?.map((store, index) => (
                                    <MenuItem key={index} value={store?.profile_id}>
                                        <Typography sx={{textAlign: 'left', fontSize: textContent}}>
                                            {store?.store_name}
                                        </Typography>
                                    </MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <FormControl fullWidth>
                        <FormLabel>
                            <Typography sx={{textAlign: 'left', fontSize: textContent}}>
                                Start Date
                            </Typography>
                        </FormLabel>
                        <TextField
                            value={startDate}
                            type='date'
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                style: {fontSize: '14px'}, // Adjust the font size here
                            }}
                            onChange={handleStartDate}
                            size='small'
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <FormControl fullWidth>
                        <FormLabel>
                            <Typography sx={{textAlign: 'left', fontSize: textContent}}>
                                End Date
                            </Typography>
                        </FormLabel>
                        <TextField
                            value={endDate}
                            type='date'
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                style: {fontSize: '14px'}, // Adjust the font size here
                            }}
                            onChange={handleEndDate}
                            size='small'
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={9}>
                    <FormControl fullWidth>
                        <TextField
                            onInput={handleSearchString}
                            size='small'
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon/>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'flex-end'
                    }}>
                        <LoadingButton
                            variant='contained'
                            sx={greenButtonContained}
                            // size='small'
                            onClick={handleSearch}
                            disabled={!profileId}
                        >
                            <Typography sx={{
                                textTransform: 'none',
                            }}>
                                Search
                            </Typography>
                        </LoadingButton>
                    </Box>
                </Grid>
            </Grid>
            {
                loading ?
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'center'
                            }}>
                                <CircularProgress/>
                            </Box>
                        </Grid>
                    </Grid>
                    :
                    orderData?.length === 0 ?
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'center'
                                }}>
                                    <Typography>
                                        No E-Commerce Orders
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                        :
                        <Grid container spacing={2}>
                            <Grid item xs={2}>
                                <Typography sx={{fontWeight: '600', fontSize: textContent}}>
                                    Order
                                </Typography>
                            </Grid>
                            <Grid item xs={1.5}>
                                <Typography sx={{fontWeight: '600', fontSize: textContent}}>
                                    Platform
                                </Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography sx={{fontWeight: '600', fontSize: textContent}}>
                                    Customer
                                </Typography>
                            </Grid>
                            <Grid item xs={1.5}>
                                <Typography sx={{fontWeight: '600', fontSize: textContent}}>
                                    Items
                                </Typography>
                            </Grid>
                            <Grid item xs={2.5}>
                                <Typography sx={{fontWeight: '600', fontSize: textContent}}>
                                    Fulfillment
                                </Typography>
                            </Grid>
                            <Grid item xs={1.5}>
                                <Typography sx={{fontWeight: '600', fontSize: textContent}}>
                                    Action
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider/>
                            </Grid>
                            {
                                orderData?.map((order, index) =>
                                    <Fragment key={index}>
                                        <MarketplaceOrderEach order={order} startsWithBusiness={startsWithBusiness}/>
                                    </Fragment>
                                )
                            }
                            <Grid item xs={12}>
                                <Pagination
                                    count={lastPage}
                                    page={+currentPage}
                                    size='small'
                                    onChange={handlePageChange}
                                />
                            </Grid>
                        </Grid>
            }
        </Box>
    )
}