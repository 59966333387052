import UPSLogo from "../images/UPS.png";
import CanadaPostLogo from "../images/CanadaPost.png";
import CanparLogo from "../images/Canpar.png";
import FedExLogo from "../images/FedEx.png";
import UPSLogoRound from "../images/UPSLogo.png";
import FedexLogoRound from "../images/FedExLogo.png";
import CanadaPostLogoRound from "../images/CanadaPostLogo.png";
import CanparLogoRound from "../images/CanparLogo.png";
import Cookies from "js-cookie";
import {ReactComponent as CanadaIcon} from "../images/homepageIcon/CA.svg";
import {ReactComponent as USIcon} from "../images/homepageIcon/USA.svg";
import dayjs from "dayjs";
import {useEffect, useState} from "react";
import {ReactComponent as BathroomIcon} from "../images/roomPlace/Bathroom.svg";
import {ReactComponent as BedroomIcon} from "../images/roomPlace/Bedroom.svg";
import {ReactComponent as BoxesIcon} from "../images/roomPlace/Boxes.svg";
import {ReactComponent as DinningRoomIcon} from "../images/roomPlace/DinningRoom.svg";
import {ReactComponent as GarageIcon} from "../images/roomPlace/Garage.svg";
import {ReactComponent as GardenIcon} from "../images/roomPlace/Garden.svg";
import {ReactComponent as HomeOfficeIcon} from "../images/roomPlace/HomeOffice.svg";
import {ReactComponent as KitchenIcon} from "../images/roomPlace/Kitchen.svg";
import {ReactComponent as LivingRoomIcon} from "../images/roomPlace/LivingRoom.svg";
import {ReactComponent as OthersIcon} from "../images/roomPlace/Others.svg";
import {
    USER_ACCOUNT_STATUS_INVALID,
    USER_LOGIN_EMAIL_NOT_EXIST,
    USER_LOGIN_EMAIL_PASSWORD_NOT_MATCH,
    USER_LOGIN_NEED_TO_VERIFY_EMAIL
} from "./config";

const provinceToCode = {
    "Alberta": "AB",
    "British Columbia": "BC",
    "Manitoba": "MB",
    "New Brunswick": "NB",
    "Newfoundland and Labrador": "NL",
    "Nova Scotia": "NS",
    "Ontario": "ON",
    "Prince Edward Island": "PE",
    "Quebec": "QC",
    "Saskatchewan": "SK",
    "Northwest Territories": "NT",
    "Nunavut": "NU",
    "Yukon": "YT",
    "Alabama": "AL",
    "Alaska": "AK",
    "Arizona": "AZ",
    "Arkansas": "AR",
    "California": "CA",
    "Colorado": "CO",
    "Connecticut": "CT",
    "Delaware": "DE",
    "Florida": "FL",
    "Georgia": "GA",
    "Hawaii": "HI",
    "Idaho": "ID",
    "Illinois": "IL",
    "Indiana": "IN",
    "Iowa": "IA",
    "Kansas": "KS",
    "Kentucky": "KY",
    "Louisiana": "LA",
    "Maine": "ME",
    "Maryland": "MD",
    "Massachusetts": "MA",
    "Michigan": "MI",
    "Minnesota": "MN",
    "Mississippi": "MS",
    "Missouri": "MO",
    "Montana": "MT",
    "Nebraska": "NE",
    "Nevada": "NV",
    "New Hampshire": "NH",
    "New Jersey": "NJ",
    "New Mexico": "NM",
    "New York": "NY",
    "North Carolina": "NC",
    "North Dakota": "ND",
    "Ohio": "OH",
    "Oklahoma": "OK",
    "Oregon": "OR",
    "Pennsylvania": "PA",
    "Rhode Island": "RI",
    "South Carolina": "SC",
    "South Dakota": "SD",
    "Tennessee": "TN",
    "Texas": "TX",
    "Utah": "UT",
    "Vermont": "VT",
    "Virginia": "VA",
    "Washington": "WA",
    "West Virginia": "WV",
    "Wisconsin": "WI",
    "Wyoming": "WY",
    "District of Columbia": "DC"
};

const getProvinceCodeOrName = (input) => {
    if (provinceToCode[input]) {
        return provinceToCode[input];
    } else {
        return input;
    }
};

// valid Canadian Postal Code
export const isValidPostalCode = (postalCode) => {
    const regex = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/i;
    return regex.test(postalCode);
}

// valid US Postal Code
export const isValidZipCode = (zipCode) => {
    const regex = /^\d{5}(?:[-\s]\d{4})?$/;
    return regex.test(zipCode);
}

// validate email
export const isValidateEmail = (value) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(value);
}

// validate phone number
export const isValidatePhone = (value) => {
    // Clean input string by removing non-alphanumeric characters
    const cleanedString = value?.replace(/[^a-zA-Z0-9]/g, '');

    // Regex for general phone number validation (10-15 digits)
    const generalPhoneNumberRegex = /^\d{10,15}$/;

    // Regex for US/CA specific phone number validation (exactly 10 digits, with optional country code 1 or +1)
    const usCaPhoneNumberRegex = /^(1|\+1)?\d{10}$/;

    // Validate based on the conditions
    if (cleanedString?.startsWith('1') || cleanedString?.startsWith('+1')) {
        // Check for US/CA format
        return usCaPhoneNumberRegex.test(cleanedString);
    }

    // Validate against general phone number format
    return generalPhoneNumberRegex.test(cleanedString);
}

export const isValidatePassword = (value) => {
    const passwordRegex = /^.{6,20}$/;
    return passwordRegex.test(value);
}

export const getLocation = async () => {
    try {
        const position = await new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(resolve, reject);
        });
        // console.log("Latitude: " + position.coords.latitude);
        // console.log("Longitude: " + position.coords.longitude);
        return position
    } catch (error) {
        console.log("Error retrieving location: " + error.message);
    }
}

// validate the string if contain char other than english
export const isAlphaString = (str) => {
    const regexString = /[^a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/? àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]/;
    console.log(str, str.match(regexString));
    return str.match(regexString) === null;
}

export const getMissingKeysForBecomePartner = (obj) => {
    const requiredKeys = ['contactEmail', 'companyPhone', 'password', 'companyName', 'firstName', 'lastName', 'companyEmail', 'companyAddress'];
    const missingKeys = requiredKeys.filter(key => {
        return !obj.hasOwnProperty(key) || obj[key] === "" || obj[key] === undefined || obj[key] === null;
    });
    return missingKeys;
}

export const getMissingKeysForProfileCompanyInfo = (obj) => {
    const requiredKeys = ['companyName', 'companyPhone', 'companyType', 'address', 'city', 'province', 'postalCode'];
    const missingKeys = requiredKeys.filter(key => {
        return !obj.hasOwnProperty(key) || obj[key] === "" || obj[key] === undefined || obj[key] === null;
    });
    return missingKeys;
}

// check missing key for pickup info
export const getMissingKeysForPickupInformation = (obj) => {
    const requiredKeys = ['name', 'phone', 'email', 'address', 'city', 'province', 'country', 'postalCode'];
    const missingKeys = requiredKeys.filter(key => {
        return !obj.hasOwnProperty(key) || obj[key] === "" || obj[key] === undefined || obj[key] === null;
    });
    return missingKeys;
}

// check missing key for update user information
export const getMissingKeysForProfileUserInfo = (obj) => {
    const requiredKeys = ['firstName', 'lastName', 'phone'];
    const missingKeys = requiredKeys.filter(key => {
        return !obj.hasOwnProperty(key) || obj[key] === "" || obj[key] === undefined || obj[key] === null;
    });
    return missingKeys;
}

// check missing key for quick quote contact info
export const getMissingKeysForQuickQuotePalletContact = (obj) => {
    const requiredKeys = ['name', 'phone'];
    const missingKeys = requiredKeys.filter(key => {
        return !obj.hasOwnProperty(key) || obj[key] === "" || obj[key] === undefined || obj[key] === null;
    });
    return missingKeys;
}

// check missing key for landing package
export const getMissingKeysForBecomeEndUser = (obj) => {
    const requiredKeys = ['contactEmail', 'password', 'firstName', 'lastName', 'phone'];
    const missingKeys = requiredKeys.filter(key => {
        return !obj.hasOwnProperty(key) || obj[key] === "" || obj[key] === undefined || obj[key] === null;
    });
    return missingKeys;
}

// check missing keys for address book
export const getMissingKeysForMovingContact = (obj) => {
    const requiredKeys = ['pickupName', 'pickupPhone', 'pickupEmail', 'deliverName', 'deliverPhone', 'deliverEmail'];
    const missingKeys = requiredKeys.filter(key => {
        return !obj.hasOwnProperty(key) || obj[key] === "" || obj[key] === undefined || obj[key] === null;
    });
    return missingKeys;
}

// check missing keys for address book
export const getMissingKeys = (obj) => {
    const requiredKeys = ['name', 'phone', 'address', 'city', 'province', 'country', 'postalCode'];
    const missingKeys = requiredKeys.filter(key => {
        return !obj.hasOwnProperty(key) || obj[key] === "" || obj[key] === undefined || obj[key] === null;
    });
    return missingKeys;
}

// check missing keys for moving address
export const getMissingKeysForMovingAddress = (obj) => {
    const requiredKeys = ['streetAddress', 'city', 'province', 'country', 'postalCode', 'propertyType', 'lift', 'storey'];
    const missingKeys = requiredKeys.filter(key => {
        return !obj.hasOwnProperty(key) || obj[key] === "" || obj[key] === undefined || obj[key] === null;
    });
    return missingKeys;
}

// check missing keys for moving address from moving item
export const getMissingKeysForMovingAddressFromItem = (obj) => {
    const requiredKeys = [
        'streetAddress',
        'city',
        'province',
        'country',
        'postalCode',
        'propertyType',
        'lift',
        'floorLevel',
        'distanceFromTruckToDoor',
        'drivewaySteepLevel'
    ];
    const missingKeys = requiredKeys.filter(key => {
        return !obj.hasOwnProperty(key) || obj[key] === "" || obj[key] === undefined || obj[key] === null;
    });
    return missingKeys;
}

// check missing keys for moving address
export const getMissingKeysForMovingLocation = (obj) => {
    const requiredKeys = ['streetAddress', 'city', 'province', 'country', 'postalCode'];
    const missingKeys = requiredKeys.filter(key => {
        return !obj.hasOwnProperty(key) || obj[key] === "" || obj[key] === undefined || obj[key] === null;
    });
    return missingKeys;
}

// check missing key for schedule pickup information
export const getMissingKeysForSchedulePickupInformation = (obj) => {
    const requiredKeys = ['name', 'phone', 'email', 'address', 'city', 'province', 'country', 'postalCode'];
    const missingKeys = requiredKeys.filter(key => {
        return !obj.hasOwnProperty(key) || obj[key] === "" || obj[key] === undefined || obj[key] === null;
    });
    return missingKeys;
}

// check missing key for create label contact info
export const getMissingKeysForCreateLabelLocation = (obj) => {
    const requiredKeys = ['name', 'phone', 'address', 'city', 'province', 'country', 'postalCode'];
    const missingKeys = requiredKeys.filter(key => {
        return !obj.hasOwnProperty(key) || obj[key] === "" || obj[key] === undefined || obj[key] === null;
    });
    return missingKeys;
}

// check missing key for create label contact info
export const getMissingKeysForCreateLTLLabelLocation = (obj) => {
    const requiredKeys = ['name', 'phone', 'email', 'address', 'city', 'province', 'country', 'postalCode'];
    const missingKeys = requiredKeys.filter(key => {
        return !obj.hasOwnProperty(key) || obj[key] === "" || obj[key] === undefined || obj[key] === null;
    });
    return missingKeys;
}

// check missing keys for moving address
export const getMissingKeysForMovingCarAddress = (obj) => {
    const requiredKeys = ['city', 'province'];
    const missingKeys = requiredKeys.filter(key => {
        return !obj.hasOwnProperty(key) || obj[key] === "" || obj[key] === undefined || obj[key] === null;
    });
    return missingKeys;
}

// check missing key for boxes
export const getMissingKeysForMyBoxes = (obj) => {
    const requiredKeys = ['boxName'];
    const missingKeys = requiredKeys.filter(key => {
        return !obj.hasOwnProperty(key) || obj[key] === "" || obj[key] === undefined || obj[key] === null;
    });
    return missingKeys;
}

// check missing key for landing package
export const getMissingKeysForLandingPackage = (obj) => {
    const requiredKeys = ['quantity', 'weight', 'weightUnit', 'length', 'width', 'height', 'dimensionUnit', 'pickupCountry', 'pickupProvince', 'pickupPostalCode', 'deliverCountry', 'deliverProvince', 'deliverPostalCode'];
    const missingKeys = requiredKeys.filter(key => {
        return !obj.hasOwnProperty(key) || obj[key] === "" || obj[key] === undefined || obj[key] === null;
    });
    return missingKeys;
}

// check missing key for landing package
export const getMissingKeysForLandingPallet = (obj) => {
    const requiredKeys = ['quantity', 'packagingType', 'pickupCity', 'pickupProvince', 'deliverCity', 'deliverProvince'];
    const requiredKeysAdditional = ['quantity', 'packagingType', 'pickupCity', 'pickupProvince', 'pickupPostalCode', 'deliverCity', 'deliverProvince', 'deliverPostalCode'];
    if (obj?.deliverProvince?.countryCode?.toLowerCase() === 'us' || obj?.pickupProvince?.countryCode?.toLowerCase() === 'us') {
        const missingKeys = requiredKeysAdditional.filter(key => {
            return !obj.hasOwnProperty(key) || obj[key] === "" || obj[key] === undefined || obj[key] === null;
        });
        return missingKeys;
    } else {
        const missingKeys = requiredKeys.filter(key => {
            return !obj.hasOwnProperty(key) || obj[key] === "" || obj[key] === undefined || obj[key] === null;
        });
        return missingKeys;
    }
}

// check missing key for landing package
export const getMissingKeysForLandingBox = (obj) => {
    const requiredKeys = ['quantity', 'weight', 'weightUnit', 'length', 'width', 'height', 'dimensionUnit', 'pickupPostalCode', 'deliverPostalCode'];
    const missingKeys = requiredKeys.filter(key => {
        return !obj.hasOwnProperty(key) || obj[key] === "" || obj[key] === undefined || obj[key] === null;
    });
    return missingKeys;
}

// check missing key for quick quote contact info
export const getMissingKeysForQuickQuoteLocation = (obj) => {
    const requiredKeys = ['city', 'province', 'country', 'postalCode'];
    const missingKeys = requiredKeys.filter(key => {
        return !obj.hasOwnProperty(key) || obj[key] === "" || obj[key] === undefined || obj[key] === null;
    });
    return missingKeys;
}

// check missing key for moving item
export const getMissingKeysForMovingItem = (obj) => {
    const requiredKeys = ['quantity', 'category', 'length', 'width', 'height', 'dimensionUnit', 'weight', 'weightUnit'];
    const missingKeys = requiredKeys.filter(key => {
        return !obj.hasOwnProperty(key) || obj[key] === "" || obj[key] === undefined || obj[key] === null;
    });
    return missingKeys;
}

// check missing key for moving item
export const getMissingKeysForMovingCar = (obj) => {
    const requiredKeys = ['make', 'model', 'year'];
    const missingKeys = requiredKeys.filter(key => {
        return !obj.hasOwnProperty(key) || obj[key] === "" || obj[key] === undefined || obj[key] === null;
    });
    return missingKeys;
}

// check missing key for quick quote pallet address
export const getMissingKeysForQuickQuotePalletAddress = (obj) => {
    const requiredKeys = ['city', 'province'];
    const missingKeys = requiredKeys.filter(key => {
        return !obj.hasOwnProperty(key) || obj[key] === "" || obj[key] === undefined || obj[key] === null;
    });
    return missingKeys;
}

// check missing key for quick quote pallet address
export const getMissingKeysForBoxInformation = (obj) => {
    const requiredKeys = ["boxName", "itemName", "length", "width", "height", "weight", "lengthUnit", "weightUnit"];
    const missingKeys = requiredKeys.filter(key => {
        return !obj.hasOwnProperty(key) || obj[key] === "" || obj[key] === undefined || obj[key] === null;
    });
    return missingKeys;
}

// check missing key from the object
export const getMissingKeysFromObject = (obj) => {
    console.log('[getMissingKeysFromObject] obj', obj);
    const missingKeys = Object.keys(obj).filter(key => {
        const value = obj[key];
        return (typeof value === "string" && value.trim() === "") || value === undefined || value === null;
    });

    return missingKeys;
}

// check invalid key from the object
export const getInvalidKeysFromObject = (obj) => {
    console.log('[getInvalidKeysFromObject] obj', obj);
    const invalidKeys = Object.keys(obj).filter(key => {
        const value = obj[key];
        return +value <= 0;
    });

    return invalidKeys;
}

// check valid key for quick quote pallet address
export const getInvalidKeysForBoxInformation = (obj) => {
    const requiredKeys = ["length", "width", "height", "weight"];
    const invalidKeys = requiredKeys.filter(key => {
        return +obj[key] <= 0;
    });
    return invalidKeys;
}

// map logos
export const mapServiceLogo = (serviceName) => {
    const service = serviceLogo.filter(item => item.serviceName === serviceName);
    // console.log(service);
    return service[0]?.url
}

// get valid country from given province/state
export const getCountryFromProvince = (province) => {
    const validStates = {
        "CA": ["AB", "BC", "MB", "NB", "NL", "NS", "NT", "NU", "ON", "PE", "QC", "SK", "YT"],
        "US": ["AL", "AK", "AZ", "AR", "CA", "CO", "CT", "DE", "FL", "GA", "HI", "ID", "IL", "IN", "IA", "KS", "KY", "LA", "ME", "MD", "MA", "MI", "MN", "MS", "MO", "MT", "NE", "NV", "NH", "NJ", "NM", "NY", "NC", "ND", "OH", "OK", "OR", "PA", "RI", "SC", "SD", "TN", "TX", "UT", "VT", "VA", "WA", "WV", "WI", "WY"]
    };
    const country = Object.keys(validStates).find((key) => {
        return validStates[key].indexOf(province) > -1;
    });
    return country || null;
}

// is valid province and country in Canada and US
export const isValidAddress = (country, state) => {
    // console.log('helper', country, state)
    if (country === "CA" || country === "US") {
        const validStates = {
            "CA": ["AB", "BC", "MB", "NB", "NL", "NS", "NT", "NU", "ON", "PE", "QC", "SK", "YT"],
            "US": ["AL", "AK", "AZ", "AR", "CA", "CO", "CT", "DE", "FL", "GA", "HI", "ID", "IL", "IN", "IA", "KS", "KY", "LA", "ME", "MD", "MA", "MI", "MN", "MS", "MO", "MT", "NE", "NV", "NH", "NJ", "NM", "NY", "NC", "ND", "OH", "OK", "OR", "PA", "RI", "SC", "SD", "TN", "TX", "UT", "VT", "VA", "WA", "WV", "WI", "WY"]
        };
        if (validStates[country].includes(state)) {
            // console.log('true')
            return true;
        }
    }
    // console.log('false')
    return false;
}

// all properties have value in object
export const checkAllPropertiesHaveValueFalse = (obj) => {
    for (let prop in obj) {
        if (obj[prop] === true) {
            return false;
        }
    }
    return true;
}

// positive integer
export const isPositiveInteger = (value) => {
    return /^[1-9]\d*$/.test(value);
}

export const getDeliverTime = (date) => {

    if (date === null || date === "") {
        return -2;
    } else {
        const now = new Date();
        const nowDateOnly = new Date(now.getFullYear(), now.getMonth(), now.getDate());
        const end = new Date(date);
        const endDateOnly = new Date(end.getFullYear(), end.getMonth(), end.getDate());

        if (endDateOnly < nowDateOnly) {
            return -1;
        } else {
            const diff = Math.abs(endDateOnly - nowDateOnly);
            const diffDays = Math.ceil(diff / (1000 * 60 * 60 * 24));
            return diffDays;
        }
    }
}

export const movingOrderStatus = (orderStatus) => {
    switch (orderStatus) {
        case 0:
            return 'Initial';
            break;
        case 100:
            return 'Paid Deposit';
            break;
        case 1:
            return 'Payment Received';
            break;
        case 2:
            return 'Paused';
            break;
        case 3:
            return 'Refunded';
            break;
        case 4:
            return 'Refunded';
            break;
        case -1:
            return 'Cancelled';
            break;
        default:
            return 'Invalid';
    }
}

export const movingShippingStatus = (shippingStatus) => {
    switch (shippingStatus) {
        case 1:
            return 'Initial';
            break;
        case 2:
            return 'Processing';
            break;
        case 3:
            return 'Pickup Confirmed';
            break;
        case 4:
            return 'Deliver Confirmed';
            break;
        case 20:
            return 'Waiting Approved';
            break;
        case 25:
            return 'Confirmed';
            break;
        case 30:
            return 'Pickup Unconfirmed';
            break;
        case 40:
            return 'Deliver Unconfirmed';
            break;
        default:
            return 'Invalid';
    }
}

export const movingOrderPriceDisplay = (orderStatus, shippingStatus) => {
    if (orderStatus === 100 && (shippingStatus === 1 || shippingStatus === 2)) {
        return false
    } else if (orderStatus === 2) {
        return false
    } else if (orderStatus === -1) {
        return false
    } else {
        return true
    }
}

export const movingOrderToPay = (orderStatus, shippingStatus) => {
    if (orderStatus === 100 && (shippingStatus === 1 || shippingStatus === 2)) {
        return false
    } else if (orderStatus === 2 || orderStatus === -1 || orderStatus === 3 || orderStatus === 4) {
        return false
    } else {
        return true
    }
}

export const movingOrderToCancel = (orderStatus, shippingStatus, orderSubType) => {
    if ((orderStatus === 100 || orderStatus === 0 || (orderStatus === 1 && orderSubType === 'EXPRESS')) && (shippingStatus === 1 || shippingStatus === 2)) {
        return true
    } else {
        return false
    }
}

export const movingOrderToEdit = (orderStatus, shippingStatus) => {
    if ((orderStatus === 100 || orderStatus === 0 || orderStatus === 1) && (shippingStatus === 1 || shippingStatus === 2)) {
        return true
    } else {
        return false
    }
}

// load google map api
export const loadAsyncScript = src => {
    return new Promise(resolve => {
        const script = document.createElement("script");
        Object.assign(script, {
            type: "text/javascript",
            async: true,
            src
        })
        script.addEventListener("load", () => resolve(script));
        document.head.appendChild(script);
    })
}

export const extractAddress = place => {
    console.log(place)
    const address = {
        streetNumber: "",
        streetName: "",
        city: "",
        state: "",
        zip: "",
        country: "",
    }

    if (!Array.isArray(place?.address_components)) {
        return address;
    }

    place.address_components.forEach(component => {
        const types = component.types;
        const value = component.long_name;

        if (types.includes("street_number")) {
            address.streetNumber = value;
        }

        if (types.includes("route")) {
            address.streetName = value;
        }

        if (types.includes("locality")) {
            address.city = value;
        }

        if (types.includes("administrative_area_level_1")) {
            address.state = value;
        }

        if (types.includes("postal_code")) {
            address.zip = value;
        }

        if (types.includes("country")) {
            address.country = value;
        }
    });
    return address;
}

export const extractFullAddress = place => {
    console.log(place)
    const address = {
        streetNumber: "",
        streetName: "",
        city: "",
        state: {},
        zip: "",
        country: {},
        neighborhood: "",
        sublocality: "",
        administrative_area_level_3: "",
        region: ""
    }

    if (!Array.isArray(place?.address_components)) {
        return address;
    }

    place.address_components.forEach(component => {
        const types = component.types;
        const value = component.long_name;
        const code = component.short_name;

        if (types.includes("street_number")) {
            address.streetNumber = value;
        }

        if (types.includes("route")) {
            address.streetName = value;
        }

        if (types.includes("locality")) {
            address.city = value;
        }

        if (types.includes("neighborhood")) {
            address.neighborhood = value;
        }

        if (types.includes("sublocality")) {
            address.sublocality = value;
        }

        if (types.includes("administrative_area_level_1")) {
            address.state = {
                name: value,
                code: getProvinceCodeOrName(code)
            }
        }

        if (types.includes("administrative_area_level_2")) {
            address.region = value;
        }

        if (types.includes("administrative_area_level_3")) {
            address.administrative_area_level_3 = value;
        }

        if (types.includes("postal_code")) {
            address.zip = value;
        }

        if (types.includes("country")) {
            address.country = {
                name: value,
                code
            }
        }
    });
    return address;
}

export const generateRandomString = (length) => {
    let result = '';
    let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
}

export const formatCurrency = (value, currency) => {
    return value.toLocaleString('en-US', {
        style: 'currency',
        currency: currency,
    });
}

export const calTransactionFee = (amount) => {
    const processingFee = (amount * 0.029 + 0.3) / 0.971;
    return processingFee.toFixed(2);
}

export const UOMList = [
    "BOX",
    "BAG",
    "CASE",
    "PALLET",
    "PACKAGE",
    "PAIRS",
    "EACH",
    "ARS",
    "BARREL",
    "BOLT",
    "BUNCH",
    "BUNDLE",
    "BUTT",
    "CANISTER",
    "CARTON",
    "CENTIMETER",
    "CONTAINER",
    "CRATE",
    "CYLINDER",
    "DOZEN",
    "ENVELOPE",
    "FEET",
    "KILOGRAM",
    "LITER",
    "METER",
    "NUMBER",
    "PACKET",
    "PIECES",
    "POUNDS",
    "PROOFLITER"
]

export const getCountryCodeByName = (name) => {
    switch (name) {
        case "Afghanistan":
            return "AF";
        case "Albania":
            return "AL";
        case "Algeria":
            return "DZ";
        case "Andorra":
            return "AD";
        case "Angola":
            return "AO";
        case "Antigua and Barbuda":
            return "AG";
        case "Argentina":
            return "AR";
        case "Armenia":
            return "AM";
        case "Australia":
            return "AU";
        case "Austria":
            return "AT";
        case "Azerbaijan":
            return "AZ";
        case "Bahamas":
            return "BS";
        case "Bahrain":
            return "BH";
        case "Bangladesh":
            return "BD";
        case "Barbados":
            return "BB";
        case "Belarus":
            return "BY";
        case "Belgium":
            return "BE";
        case "Belize":
            return "BZ";
        case "Benin":
            return "BJ";
        case "Bhutan":
            return "BT";
        case "Bolivia":
            return "BO";
        case "Bosnia and Herzegovina":
            return "BA";
        case "Botswana":
            return "BW";
        case "Brazil":
            return "BR";
        case "Brunei Darussalam":
            return "BN";
        case "Bulgaria":
            return "BG";
        case "Burkina Faso":
            return "BF";
        case "Burundi":
            return "BI";
        case "Cabo Verde":
            return "CV";
        case "Cambodia":
            return "KH";
        case "Cameroon":
            return "CM";
        case "Canada":
            return "CA";
        case "Central African Republic":
            return "CF";
        case "Chad":
            return "TD";
        case "Chile":
            return "CL";
        case "China":
            return "CN";
        case "Colombia":
            return "CO";
        case "Comoros":
            return "KM";
        case "Congo":
            return "CG";
        case "Costa Rica":
            return "CR";
        case "Côte d'Ivoire":
            return "CI";
        case "Croatia":
            return "HR";
        case "Cuba":
            return "CU";
        case "Cyprus":
            return "CY";
        case "Czech Republic":
            return "CZ";
        case "Denmark":
            return "DK";
        case "Djibouti":
            return "DJ";
        case "Dominica":
            return "DM";
        case "Dominican Republic":
            return "DO";
        case "Ecuador":
            return "EC";
        case "Egypt":
            return "EG";
        case "El Salvador":
            return "SV";
        case "Equatorial Guinea":
            return "GQ";
        case "Eritrea":
            return "ER";
        case "Estonia":
            return "EE";
        case "Eswatini":
            return "SZ";
        case "Ethiopia":
            return "ET";
        case "Fiji":
            return "FJ";
        case "Finland":
            return "FI";
        case "France":
            return "FR";
        case "Gabon":
            return "GA";
        case "Gambia":
            return "GM";
        case "Georgia":
            return "GE";
        case "Germany":
            return "DE";
        case "Ghana":
            return "GH";
        case "Greece":
            return "GR";
        case "Grenada":
            return "GD";
        case "Guatemala":
            return "GT";
        case "Guinea":
            return "GN";
        case "Guinea-Bissau":
            return "GW";
        case "Guyana":
            return "GY";
        case "Haiti":
            return "HT";
        case "Honduras":
            return "HN";
        case "Hungary":
            return "HU";
        case "Iceland":
            return "IS";
        case "India":
            return "IN";
        case "Indonesia":
            return "ID";
        case "Iran":
            return "IR";
        case "Iraq":
            return "IQ";
        case "Ireland":
            return "IE";
        case "Israel":
            return "IL";
        case "Italy":
            return "IT";
        case "Jamaica":
            return "JM";
        case "Japan":
            return "JP";
        case "Jordan":
            return "JO";
        case "Kazakhstan":
            return "KZ";
        case "Kenya":
            return "KE";
        case "Kiribati":
            return "KI";
        case "Korea, North":
            return "KP";
        case "Korea, South":
            return "KR";
        case "Kosovo":
            return "XK";
        case "Kuwait":
            return "KW";
        case "Kyrgyzstan":
            return "KG";
        case "Laos":
            return "LA";
        case "Latvia":
            return "LV";
        case "Lebanon":
            return "LB";
        case "Lesotho":
            return "LS";
        case "Liberia":
            return "LR";
        case "Libya":
            return "LY";
        case "Liechtenstein":
            return "LI";
        case "Lithuania":
            return "LT";
        case "Luxembourg":
            return "LU";
        case "Macao":
            return "MO";
        case "Macedonia (the former Yugoslav Republic of)":
            return "MK";
        case "Madagascar":
            return "MG";
        case "Malawi":
            return "MW";
        case "Malaysia":
            return "MY";
        case "Maldives":
            return "MV";
        case "Mali":
            return "ML";
        case "Malta":
            return "MT";
        case "Marshall Islands":
            return "MH";
        case "Martinique":
            return "MQ";
        case "Mauritania":
            return "MR";
        case "Mauritius":
            return "MU";
        case "Mayotte":
            return "YT";
        case "Mexico":
            return "MX";
        case "Micronesia (Federated States of)":
            return "FM";
        case "Moldova (Republic of)":
            return "MD";
        case "Monaco":
            return "MC";
        case "Mongolia":
            return "MN";
        case "Montenegro":
            return "ME";
        case "Montserrat":
            return "MS";
        case "Morocco":
            return "MA";
        case "Mozambique":
            return "MZ";
        case "Myanmar":
            return "MM";
        case "Namibia":
            return "NA";
        case "Nauru":
            return "NR";
        case "Nepal":
            return "NP";
        case "Netherlands":
            return "NL";
        case "New Caledonia":
            return "NC";
        case "New Zealand":
            return "NZ";
        case "Nicaragua":
            return "NI";
        case "Niger":
            return "NE";
        case "Nigeria":
            return "NG";
        case "Niue":
            return "NU";
        case "Norfolk Island":
            return "NF";
        case "North Korea":
            return "KP";
        case "Northern Mariana Islands":
            return "MP";
        case "Norway":
            return "NO";
        case "Oman":
            return "OM";
        case "Pakistan":
            return "PK";
        case "Palau":
            return "PW";
        case "Palestine, State of":
            return "PS";
        case "Panama":
            return "PA";
        case "Papua New Guinea":
            return "PG";
        case "Paraguay":
            return "PY";
        case "Peru":
            return "PE";
        case "Philippines":
            return "PH";
        case "Pitcairn":
            return "PN";
        case "Poland":
            return "PL";
        case "Portugal":
            return "PT";
        case "Puerto Rico":
            return "PR";
        case "Qatar":
            return "QA";
        case "Russia":
            return "RU";
        case "Rwanda":
            return "RW";
        case "Saint Kitts and Nevis":
            return "KN";
        case "Saint Lucia":
            return "LC";
        case "Saint Vincent and the Grenadines":
            return "VC";
        case "Samoa":
            return "WS";
        case "San Marino":
            return "SM";
        case "Sao Tome and Principe":
            return "ST";
        case "Saudi Arabia":
            return "SA";
        case "Senegal":
            return "SN";
        case "Serbia":
            return "RS";
        case "Seychelles":
            return "SC";
        case "Sierra Leone":
            return "SL";
        case "Singapore":
            return "SG";
        case "Slovakia":
            return "SK";
        case "Slovenia":
            return "SI";
        case "Solomon Islands":
            return "SB";
        case "Somalia":
            return "SO";
        case "South Africa":
            return "ZA";
        case "South Sudan":
            return "SS";
        case "Spain":
            return "ES";
        case "Sri Lanka":
            return "LK";
        case "Sudan":
            return "SD";
        case "Suriname":
            return "SR";
        case "Sweden":
            return "SE";
        case "Switzerland":
            return "CH";
        case "Syria":
            return "SY";
        case "Taiwan":
            return "TW";
        case "Tajikistan":
            return "TJ";
        case "Tanzania":
            return "TZ";
        case "Thailand":
            return "TH";
        case "Timor-Leste":
            return "TL";
        case "Togo":
            return "TG";
        case "Tonga":
            return "TO";
        case "Trinidad and Tobago":
            return "TT";
        case "Tunisia":
            return "TN";
        case "Turkey":
            return "TR";
        case "Turkmenistan":
            return "TM";
        case "Tuvalu":
            return "TV";
        case "Uganda":
            return "UG";
        case "Ukraine":
            return "UA";
        case "United Arab Emirates":
            return "AE";
        case "United Kingdom":
            return "GB";
        case "United States":
            return "US";
        case "Uruguay":
            return "UY";
        case "Uzbekistan":
            return "UZ";
        case "Vanuatu":
            return "VU";
        case "Vatican City":
            return "VA";
        case "Venezuela":
            return "VE";
        case "Vietnam":
            return "VN";
        case "Yemen":
            return "YE";
        case "Zambia":
            return "ZM";
        case "Zimbabwe":
            return "ZW";
        default:
            return name;
    }
}

const getProvinceCodeByName = (name) => {
    switch (name) {
        case "Newfoundland and Labrador":
            return "NL";
        case "Prince Edward Island":
            return "PE";
        case "Nova Scotia":
            return "NS";
        case "New Brunswick":
            return "NB";
        case "Quebec":
            return "QC";
        case "Québec":
            return "QC";
        case "Ontario":
            return "ON";
        case "Manitoba":
            return "MB";
        case "Saskatchewan":
            return "SK";
        case "Alberta":
            return "AB";
        case "British Columbia":
            return "BC";
        case "Yukon":
            return "YT";
        case "Northwest Territories":
            return "NT";
        case "Nunavut":
            return "NU";
        case "Alabama":
            return "AL";
        case "Alaska":
            return "AK";
        case "Arizona":
            return "AZ";
        case "Arkansas":
            return "AR";
        case "California":
            return "CA";
        case "Colorado":
            return "CO";
        case "Connecticut":
            return "CT";
        case "Delaware":
            return "DE";
        case "Florida":
            return "FL";
        case "Georgia":
            return "GA";
        case "Hawaii":
            return "HI";
        case "Idaho":
            return "ID";
        case "Illinois":
            return "IL";
        case "Indiana":
            return "IN";
        case "Iowa":
            return "IA";
        case "Kansas":
            return "KS";
        case "Kentucky":
            return "KY";
        case "Louisiana":
            return "LA";
        case "Maine":
            return "ME";
        case "Maryland":
            return "MD";
        case "Massachusetts":
            return "MA";
        case "Michigan":
            return "MI";
        case "Minnesota":
            return "MN";
        case "Mississippi":
            return "MS";
        case "Missouri":
            return "MO";
        case "Montana":
            return "MT";
        case "Nebraska":
            return "NE";
        case "Nevada":
            return "NV";
        case "New Hampshire":
            return "NH";
        case "New Jersey":
            return "NJ";
        case "New Mexico":
            return "NM";
        case "New York":
            return "NY";
        case "North Carolina":
            return "NC";
        case "North Dakota":
            return "ND";
        case "Ohio":
            return "OH";
        case "Oklahoma":
            return "OK";
        case "Oregon":
            return "OR";
        case "Pennsylvania":
            return "PA";
        case "Rhode Island":
            return "RI";
        case "South Carolina":
            return "SC";
        case "South Dakota":
            return "SD";
        case "Tennessee":
            return "TN";
        case "Texas":
            return "TX";
        case "Utah":
            return "UT";
        case "Vermont":
            return "VT";
        case "Virginia":
            return "VA";
        case "Washington":
            return "WA";
        case "West Virginia":
            return "WV";
        case "Wisconsin":
            return "WI";
        case "Wyoming":
            return "WY";
        case "NL":
            return "NL";
        case "PE":
            return "PE";
        case "NS":
            return "NS";
        case "NB":
            return "NB";
        case "QC":
            return "QC";
        case "ON":
            return "ON";
        case "MB":
            return "MB";
        case "SK":
            return "SK";
        case "AB":
            return "AB";
        case "BC":
            return "BC";
        case "YT":
            return "YT";
        case "NT":
            return "NT";
        case "NU":
            return "NU";
        default:
            return name;
    }
};

export const countryListAllIsoData = [
    {"code": "AF", "code3": "AFG", "name": "Afghanistan", "number": "004"},
    {"code": "AL", "code3": "ALB", "name": "Albania", "number": "008"},
    {"code": "DZ", "code3": "DZA", "name": "Algeria", "number": "012"},
    {"code": "AS", "code3": "ASM", "name": "American Samoa", "number": "016"},
    {"code": "AD", "code3": "AND", "name": "Andorra", "number": "020"},
    {"code": "AO", "code3": "AGO", "name": "Angola", "number": "024"},
    {"code": "AI", "code3": "AIA", "name": "Anguilla", "number": "660"},
    {"code": "AQ", "code3": "ATA", "name": "Antarctica", "number": "010"},
    {"code": "AG", "code3": "ATG", "name": "Antigua and Barbuda", "number": "028"},
    {"code": "AR", "code3": "ARG", "name": "Argentina", "number": "032"},
    {"code": "AM", "code3": "ARM", "name": "Armenia", "number": "051"},
    {"code": "AW", "code3": "ABW", "name": "Aruba", "number": "533"},
    {"code": "AU", "code3": "AUS", "name": "Australia", "number": "036"},
    {"code": "AT", "code3": "AUT", "name": "Austria", "number": "040"},
    {"code": "AZ", "code3": "AZE", "name": "Azerbaijan", "number": "031"},
    {"code": "BS", "code3": "BHS", "name": "Bahamas (the)", "number": "044"},
    {"code": "BH", "code3": "BHR", "name": "Bahrain", "number": "048"},
    {"code": "BD", "code3": "BGD", "name": "Bangladesh", "number": "050"},
    {"code": "BB", "code3": "BRB", "name": "Barbados", "number": "052"},
    {"code": "BY", "code3": "BLR", "name": "Belarus", "number": "112"},
    {"code": "BE", "code3": "BEL", "name": "Belgium", "number": "056"},
    {"code": "BZ", "code3": "BLZ", "name": "Belize", "number": "084"},
    {"code": "BJ", "code3": "BEN", "name": "Benin", "number": "204"},
    {"code": "BM", "code3": "BMU", "name": "Bermuda", "number": "060"},
    {"code": "BT", "code3": "BTN", "name": "Bhutan", "number": "064"},
    {"code": "BO", "code3": "BOL", "name": "Bolivia (Plurinational State of)", "number": "068"},
    {"code": "BQ", "code3": "BES", "name": "Bonaire, Sint Eustatius and Saba", "number": "535"},
    {"code": "BA", "code3": "BIH", "name": "Bosnia and Herzegovina", "number": "070"},
    {"code": "BW", "code3": "BWA", "name": "Botswana", "number": "072"},
    {"code": "BV", "code3": "BVT", "name": "Bouvet Island", "number": "074"},
    {"code": "BR", "code3": "BRA", "name": "Brazil", "number": "076"},
    {"code": "IO", "code3": "IOT", "name": "British Indian Ocean Territory (the)", "number": "086"},
    {"code": "BN", "code3": "BRN", "name": "Brunei Darussalam", "number": "096"},
    {"code": "BG", "code3": "BGR", "name": "Bulgaria", "number": "100"},
    {"code": "BF", "code3": "BFA", "name": "Burkina Faso", "number": "854"},
    {"code": "BI", "code3": "BDI", "name": "Burundi", "number": "108"},
    {"code": "CV", "code3": "CPV", "name": "Cabo Verde", "number": "132"},
    {"code": "KH", "code3": "KHM", "name": "Cambodia", "number": "116"},
    {"code": "CM", "code3": "CMR", "name": "Cameroon", "number": "120"},
    {"code": "CA", "code3": "CAN", "name": "Canada", "number": "124"},
    {"code": "KY", "code3": "CYM", "name": "Cayman Islands (the)", "number": "136"},
    {"code": "CF", "code3": "CAF", "name": "Central African Republic (the)", "number": "140"},
    {"code": "TD", "code3": "TCD", "name": "Chad", "number": "148"},
    {"code": "CL", "code3": "CHL", "name": "Chile", "number": "152"},
    {"code": "CN", "code3": "CHN", "name": "China", "number": "156"},
    {"code": "CX", "code3": "CXR", "name": "Christmas Island", "number": "162"},
    {"code": "CC", "code3": "CCK", "name": "Cocos (Keeling) Islands (the)", "number": "166"},
    {"code": "CO", "code3": "COL", "name": "Colombia", "number": "170"},
    {"code": "KM", "code3": "COM", "name": "Comoros (the)", "number": "174"},
    {"code": "CD", "code3": "COD", "name": "Congo (the Democratic Republic of the)", "number": "180"},
    {"code": "CG", "code3": "COG", "name": "Congo (the)", "number": "178"},
    {"code": "CK", "code3": "COK", "name": "Cook Islands (the)", "number": "184"},
    {"code": "CR", "code3": "CRI", "name": "Costa Rica", "number": "188"},
    {"code": "HR", "code3": "HRV", "name": "Croatia", "number": "191"},
    {"code": "CU", "code3": "CUB", "name": "Cuba", "number": "192"},
    {"code": "CW", "code3": "CUW", "name": "Curaçao", "number": "531"},
    {"code": "CY", "code3": "CYP", "name": "Cyprus", "number": "196"},
    {"code": "CZ", "code3": "CZE", "name": "Czechia", "number": "203"},
    {"code": "CI", "code3": "CIV", "name": "Côte d'Ivoire", "number": "384"},
    {"code": "DK", "code3": "DNK", "name": "Denmark", "number": "208"},
    {"code": "DJ", "code3": "DJI", "name": "Djibouti", "number": "262"},
    {"code": "DM", "code3": "DMA", "name": "Dominica", "number": "212"},
    {"code": "DO", "code3": "DOM", "name": "Dominican Republic (the)", "number": "214"},
    {"code": "EC", "code3": "ECU", "name": "Ecuador", "number": "218"},
    {"code": "EG", "code3": "EGY", "name": "Egypt", "number": "818"},
    {"code": "SV", "code3": "SLV", "name": "El Salvador", "number": "222"},
    {"code": "GQ", "code3": "GNQ", "name": "Equatorial Guinea", "number": "226"},
    {"code": "ER", "code3": "ERI", "name": "Eritrea", "number": "232"},
    {"code": "EE", "code3": "EST", "name": "Estonia", "number": "233"},
    {"code": "SZ", "code3": "SWZ", "name": "Eswatini", "number": "748"},
    {"code": "ET", "code3": "ETH", "name": "Ethiopia", "number": "231"},
    {"code": "FK", "code3": "FLK", "name": "Falkland Islands (the) [Malvinas]", "number": "238"},
    {"code": "FO", "code3": "FRO", "name": "Faroe Islands (the)", "number": "234"},
    {"code": "FJ", "code3": "FJI", "name": "Fiji", "number": "242"},
    {"code": "FI", "code3": "FIN", "name": "Finland", "number": "246"},
    {"code": "FR", "code3": "FRA", "name": "France", "number": "250"},
    {"code": "GF", "code3": "GUF", "name": "French Guiana", "number": "254"},
    {"code": "PF", "code3": "PYF", "name": "French Polynesia", "number": "258"},
    {"code": "TF", "code3": "ATF", "name": "French Southern Territories (the)", "number": "260"},
    {"code": "GA", "code3": "GAB", "name": "Gabon", "number": "266"},
    {"code": "GM", "code3": "GMB", "name": "Gambia (the)", "number": "270"},
    {"code": "GE", "code3": "GEO", "name": "Georgia", "number": "268"},
    {"code": "DE", "code3": "DEU", "name": "Germany", "number": "276"},
    {"code": "GH", "code3": "GHA", "name": "Ghana", "number": "288"},
    {"code": "GI", "code3": "GIB", "name": "Gibraltar", "number": "292"},
    {"code": "GR", "code3": "GRC", "name": "Greece", "number": "300"},
    {"code": "GL", "code3": "GRL", "name": "Greenland", "number": "304"},
    {"code": "GD", "code3": "GRD", "name": "Grenada", "number": "308"},
    {"code": "GP", "code3": "GLP", "name": "Guadeloupe", "number": "312"},
    {"code": "GU", "code3": "GUM", "name": "Guam", "number": "316"},
    {"code": "GT", "code3": "GTM", "name": "Guatemala", "number": "320"},
    {"code": "GG", "code3": "GGY", "name": "Guernsey", "number": "831"},
    {"code": "GN", "code3": "GIN", "name": "Guinea", "number": "324"},
    {"code": "GW", "code3": "GNB", "name": "Guinea-Bissau", "number": "624"},
    {"code": "GY", "code3": "GUY", "name": "Guyana", "number": "328"},
    {"code": "HT", "code3": "HTI", "name": "Haiti", "number": "332"},
    {"code": "HM", "code3": "HMD", "name": "Heard Island and McDonald Islands", "number": "334"},
    {"code": "VA", "code3": "VAT", "name": "Holy See (the)", "number": "336"},
    {"code": "HN", "code3": "HND", "name": "Honduras", "number": "340"},
    {"code": "HK", "code3": "HKG", "name": "Hong Kong", "number": "344"},
    {"code": "HU", "code3": "HUN", "name": "Hungary", "number": "348"},
    {"code": "IS", "code3": "ISL", "name": "Iceland", "number": "352"},
    {"code": "IN", "code3": "IND", "name": "India", "number": "356"},
    {"code": "ID", "code3": "IDN", "name": "Indonesia", "number": "360"},
    {"code": "IR", "code3": "IRN", "name": "Iran (Islamic Republic of)", "number": "364"},
    {"code": "IQ", "code3": "IRQ", "name": "Iraq", "number": "368"},
    {"code": "IE", "code3": "IRL", "name": "Ireland", "number": "372"},
    {"code": "IM", "code3": "IMN", "name": "Isle of Man", "number": "833"},
    {"code": "IL", "code3": "ISR", "name": "Israel", "number": "376"},
    {"code": "IT", "code3": "ITA", "name": "Italy", "number": "380"},
    {"code": "JM", "code3": "JAM", "name": "Jamaica", "number": "388"},
    {"code": "JP", "code3": "JPN", "name": "Japan", "number": "392"},
    {"code": "JE", "code3": "JEY", "name": "Jersey", "number": "832"},
    {"code": "JO", "code3": "JOR", "name": "Jordan", "number": "400"},
    {"code": "KZ", "code3": "KAZ", "name": "Kazakhstan", "number": "398"},
    {"code": "KE", "code3": "KEN", "name": "Kenya", "number": "404"},
    {"code": "KI", "code3": "KIR", "name": "Kiribati", "number": "296"},
    {"code": "KP", "code3": "PRK", "name": "Korea (the Democratic People's Republic of)", "number": "408"},
    {"code": "KR", "code3": "KOR", "name": "Korea (the Republic of)", "number": "410"},
    {"code": "KW", "code3": "KWT", "name": "Kuwait", "number": "414"},
    {"code": "KG", "code3": "KGZ", "name": "Kyrgyzstan", "number": "417"},
    {"code": "LA", "code3": "LAO", "name": "Lao People's Democratic Republic (the)", "number": "418"},
    {"code": "LV", "code3": "LVA", "name": "Latvia", "number": "428"},
    {"code": "LB", "code3": "LBN", "name": "Lebanon", "number": "422"},
    {"code": "LS", "code3": "LSO", "name": "Lesotho", "number": "426"},
    {"code": "LR", "code3": "LBR", "name": "Liberia", "number": "430"},
    {"code": "LY", "code3": "LBY", "name": "Libya", "number": "434"},
    {"code": "LI", "code3": "LIE", "name": "Liechtenstein", "number": "438"},
    {"code": "LT", "code3": "LTU", "name": "Lithuania", "number": "440"},
    {"code": "LU", "code3": "LUX", "name": "Luxembourg", "number": "442"},
    {"code": "MO", "code3": "MAC", "name": "Macao", "number": "446"},
    {"code": "MG", "code3": "MDG", "name": "Madagascar", "number": "450"},
    {"code": "MW", "code3": "MWI", "name": "Malawi", "number": "454"},
    {"code": "MY", "code3": "MYS", "name": "Malaysia", "number": "458"},
    {"code": "MV", "code3": "MDV", "name": "Maldives", "number": "462"},
    {"code": "ML", "code3": "MLI", "name": "Mali", "number": "466"},
    {"code": "MT", "code3": "MLT", "name": "Malta", "number": "470"},
    {"code": "MH", "code3": "MHL", "name": "Marshall Islands (the)", "number": "584"},
    {"code": "MQ", "code3": "MTQ", "name": "Martinique", "number": "474"},
    {"code": "MR", "code3": "MRT", "name": "Mauritania", "number": "478"},
    {"code": "MU", "code3": "MUS", "name": "Mauritius", "number": "480"},
    {"code": "YT", "code3": "MYT", "name": "Mayotte", "number": "175"},
    {"code": "MX", "code3": "MEX", "name": "Mexico", "number": "484"},
    {"code": "FM", "code3": "FSM", "name": "Micronesia (Federated States of)", "number": "583"},
    {"code": "MD", "code3": "MDA", "name": "Moldova (the Republic of)", "number": "498"},
    {"code": "MC", "code3": "MCO", "name": "Monaco", "number": "492"},
    {"code": "MN", "code3": "MNG", "name": "Mongolia", "number": "496"},
    {"code": "ME", "code3": "MNE", "name": "Montenegro", "number": "499"},
    {"code": "MS", "code3": "MSR", "name": "Montserrat", "number": "500"},
    {"code": "MA", "code3": "MAR", "name": "Morocco", "number": "504"},
    {"code": "MZ", "code3": "MOZ", "name": "Mozambique", "number": "508"},
    {"code": "MM", "code3": "MMR", "name": "Myanmar", "number": "104"},
    {"code": "NA", "code3": "NAM", "name": "Namibia", "number": "516"},
    {"code": "NR", "code3": "NRU", "name": "Nauru", "number": "520"},
    {"code": "NP", "code3": "NPL", "name": "Nepal", "number": "524"},
    {"code": "NL", "code3": "NLD", "name": "Netherlands (the)", "number": "528"},
    {"code": "NC", "code3": "NCL", "name": "New Caledonia", "number": "540"},
    {"code": "NZ", "code3": "NZL", "name": "New Zealand", "number": "554"},
    {"code": "NI", "code3": "NIC", "name": "Nicaragua", "number": "558"},
    {"code": "NE", "code3": "NER", "name": "Niger (the)", "number": "562"},
    {"code": "NG", "code3": "NGA", "name": "Nigeria", "number": "566"},
    {"code": "NU", "code3": "NIU", "name": "Niue", "number": "570"},
    {"code": "NF", "code3": "NFK", "name": "Norfolk Island", "number": "574"},
    {"code": "MP", "code3": "MNP", "name": "Northern Mariana Islands (the)", "number": "580"},
    {"code": "NO", "code3": "NOR", "name": "Norway", "number": "578"},
    {"code": "OM", "code3": "OMN", "name": "Oman", "number": "512"},
    {"code": "PK", "code3": "PAK", "name": "Pakistan", "number": "586"},
    {"code": "PW", "code3": "PLW", "name": "Palau", "number": "585"},
    {"code": "PS", "code3": "PSE", "name": "Palestine, State of", "number": "275"},
    {"code": "PA", "code3": "PAN", "name": "Panama", "number": "591"},
    {"code": "PG", "code3": "PNG", "name": "Papua New Guinea", "number": "598"},
    {"code": "PY", "code3": "PRY", "name": "Paraguay", "number": "600"},
    {"code": "PE", "code3": "PER", "name": "Peru", "number": "604"},
    {"code": "PH", "code3": "PHL", "name": "Philippines (the)", "number": "608"},
    {"code": "PN", "code3": "PCN", "name": "Pitcairn", "number": "612"},
    {"code": "PL", "code3": "POL", "name": "Poland", "number": "616"},
    {"code": "PT", "code3": "PRT", "name": "Portugal", "number": "620"},
    {"code": "PR", "code3": "PRI", "name": "Puerto Rico", "number": "630"},
    {"code": "QA", "code3": "QAT", "name": "Qatar", "number": "634"},
    {"code": "MK", "code3": "MKD", "name": "Republic of North Macedonia", "number": "807"},
    {"code": "RO", "code3": "ROU", "name": "Romania", "number": "642"},
    {"code": "RU", "code3": "RUS", "name": "Russian Federation (the)", "number": "643"},
    {"code": "RW", "code3": "RWA", "name": "Rwanda", "number": "646"},
    {"code": "RE", "code3": "REU", "name": "Réunion", "number": "638"},
    {"code": "BL", "code3": "BLM", "name": "Saint Barthélemy", "number": "652"},
    {"code": "SH", "code3": "SHN", "name": "Saint Helena, Ascension and Tristan da Cunha", "number": "654"},
    {"code": "KN", "code3": "KNA", "name": "Saint Kitts and Nevis", "number": "659"},
    {"code": "LC", "code3": "LCA", "name": "Saint Lucia", "number": "662"},
    {"code": "MF", "code3": "MAF", "name": "Saint Martin (French part)", "number": "663"},
    {"code": "PM", "code3": "SPM", "name": "Saint Pierre and Miquelon", "number": "666"},
    {"code": "VC", "code3": "VCT", "name": "Saint Vincent and the Grenadines", "number": "670"},
    {"code": "WS", "code3": "WSM", "name": "Samoa", "number": "882"},
    {"code": "SM", "code3": "SMR", "name": "San Marino", "number": "674"},
    {"code": "ST", "code3": "STP", "name": "Sao Tome and Principe", "number": "678"},
    {"code": "SA", "code3": "SAU", "name": "Saudi Arabia", "number": "682"},
    {"code": "SN", "code3": "SEN", "name": "Senegal", "number": "686"},
    {"code": "RS", "code3": "SRB", "name": "Serbia", "number": "688"},
    {"code": "SC", "code3": "SYC", "name": "Seychelles", "number": "690"},
    {"code": "SL", "code3": "SLE", "name": "Sierra Leone", "number": "694"},
    {"code": "SG", "code3": "SGP", "name": "Singapore", "number": "702"},
    {"code": "SX", "code3": "SXM", "name": "Sint Maarten (Dutch part)", "number": "534"},
    {"code": "SK", "code3": "SVK", "name": "Slovakia", "number": "703"},
    {"code": "SI", "code3": "SVN", "name": "Slovenia", "number": "705"},
    {"code": "SB", "code3": "SLB", "name": "Solomon Islands", "number": "090"},
    {"code": "SO", "code3": "SOM", "name": "Somalia", "number": "706"},
    {"code": "ZA", "code3": "ZAF", "name": "South Africa", "number": "710"},
    {"code": "GS", "code3": "SGS", "name": "South Georgia and the South Sandwich Islands", "number": "239"},
    {"code": "SS", "code3": "SSD", "name": "South Sudan", "number": "728"},
    {"code": "ES", "code3": "ESP", "name": "Spain", "number": "724"},
    {"code": "LK", "code3": "LKA", "name": "Sri Lanka", "number": "144"},
    {"code": "SD", "code3": "SDN", "name": "Sudan (the)", "number": "729"},
    {"code": "SR", "code3": "SUR", "name": "Suriname", "number": "740"},
    {"code": "SJ", "code3": "SJM", "name": "Svalbard and Jan Mayen", "number": "744"},
    {"code": "SE", "code3": "SWE", "name": "Sweden", "number": "752"},
    {"code": "CH", "code3": "CHE", "name": "Switzerland", "number": "756"},
    {"code": "SY", "code3": "SYR", "name": "Syrian Arab Republic", "number": "760"},
    {"code": "TW", "code3": "TWN", "name": "Taiwan", "number": "158"},
    {"code": "TJ", "code3": "TJK", "name": "Tajikistan", "number": "762"},
    {"code": "TZ", "code3": "TZA", "name": "Tanzania, United Republic of", "number": "834"},
    {"code": "TH", "code3": "THA", "name": "Thailand", "number": "764"},
    {"code": "TL", "code3": "TLS", "name": "Timor-Leste", "number": "626"},
    {"code": "TG", "code3": "TGO", "name": "Togo", "number": "768"},
    {"code": "TK", "code3": "TKL", "name": "Tokelau", "number": "772"},
    {"code": "TO", "code3": "TON", "name": "Tonga", "number": "776"},
    {"code": "TT", "code3": "TTO", "name": "Trinidad and Tobago", "number": "780"},
    {"code": "TN", "code3": "TUN", "name": "Tunisia", "number": "788"},
    {"code": "TR", "code3": "TUR", "name": "Turkey", "number": "792"},
    {"code": "TM", "code3": "TKM", "name": "Turkmenistan", "number": "795"},
    {"code": "TC", "code3": "TCA", "name": "Turks and Caicos Islands (the)", "number": "796"},
    {"code": "TV", "code3": "TUV", "name": "Tuvalu", "number": "798"},
    {"code": "UG", "code3": "UGA", "name": "Uganda", "number": "800"},
    {"code": "UA", "code3": "UKR", "name": "Ukraine", "number": "804"},
    {"code": "AE", "code3": "ARE", "name": "United Arab Emirates (the)", "number": "784"},
    {
        "code": "GB",
        "code3": "GBR",
        "name": "United Kingdom of Great Britain and Northern Ireland (the)",
        "number": "826"
    },
    {"code": "UM", "code3": "UMI", "name": "United States Minor Outlying Islands (the)", "number": "581"},
    {"code": "US", "code3": "USA", "name": "United States of America (the)", "number": "840"},
    {"code": "UY", "code3": "URY", "name": "Uruguay", "number": "858"},
    {"code": "UZ", "code3": "UZB", "name": "Uzbekistan", "number": "860"},
    {"code": "VU", "code3": "VUT", "name": "Vanuatu", "number": "548"},
    {"code": "VE", "code3": "VEN", "name": "Venezuela (Bolivarian Republic of)", "number": "862"},
    {"code": "VN", "code3": "VNM", "name": "Viet Nam", "number": "704"},
    {"code": "VG", "code3": "VGB", "name": "Virgin Islands (British)", "number": "092"},
    {"code": "VI", "code3": "VIR", "name": "Virgin Islands (U.S.)", "number": "850"},
    {"code": "WF", "code3": "WLF", "name": "Wallis and Futuna", "number": "876"},
    {"code": "EH", "code3": "ESH", "name": "Western Sahara", "number": "732"},
    {"code": "YE", "code3": "YEM", "name": "Yemen", "number": "887"},
    {"code": "ZM", "code3": "ZMB", "name": "Zambia", "number": "894"},
    {"code": "ZW", "code3": "ZWE", "name": "Zimbabwe", "number": "716"},
    {"code": "AX", "code3": "ALA", "name": "Åland Islands", "number": "248"}
];

export const transportationList = [
    {"code": "INT_TRUCK", "name": "International Truck"},
    {"code": "INT_AIR", "name": "International Air"},
    {"code": "INT_OCEAN", "name": "International Ocean"},
    {"code": "INT_RAIL", "name": "International Rail"}
]

export const internationalShipmentTypeList = [
    "GIFT",
    "COMMERCIAL",
    "SALE",
    "SAMPLE",
    "REPAIR",
    "RETURN",
    "REPLACEMENT",
    "PERSONAL USE",
    "EXHIBITION",
    "WARRANTY CLAIM",
    "DONATION",
    "RELOCATION"
]

export const serviceLogo = [
    {
        url: "https://storage.googleapis.com/uucargo-resource/image/icon/ups-ground-icon.png",
        serviceName: "UPS Standard"
    },
    {
        url: "https://storage.googleapis.com/uucargo-resource/image/icon/ups-express-icon.png",
        serviceName: "UPS Super Express"
    },
    {
        url: "https://storage.googleapis.com/uucargo-resource/image/icon/ups-express-icon.png",
        serviceName: "UPS Express Saver"
    },
    {
        url: "https://storage.googleapis.com/uucargo-resource/image/icon/canadapost.png",
        serviceName: "Canada Post Regular Parcel"
    },
    {
        url: "https://storage.googleapis.com/uucargo-resource/image/icon/canadapost.png",
        serviceName: "Canada Post Expedited Parcel"
    },
    {
        url: "https://storage.googleapis.com/uucargo-resource/image/icon/canpar-ground.png",
        serviceName: "Canpar Ground"
    },
    {
        url: "https://storage.googleapis.com/uucargo-resource/image/icon/canpar-express.png",
        serviceName: "Canpar Select"
    },
    {
        url: "https://storage.googleapis.com/uucargo-resource/image/icon/fedex-ground-icon.png",
        serviceName: "Fedex Ground"
    },
    {
        url: "https://storage.googleapis.com/uucargo-resource/image/icon/fedex-express-icon.png",
        serviceName: "Fedex Express Saver"
    }
]

export const countries = [{code: 'CA', name: 'Canada'}, {code: 'US', name: 'United States'},];

export const provinces = {
    CA: [
        {code: 'AB', name: 'Alberta'},
        {code: 'BC', name: 'British Columbia'},
        {code: 'MB', name: 'Manitoba'},
        {code: 'NB', name: 'New Brunswick'},
        {code: 'NL', name: 'Newfoundland and Labrador'},
        {code: 'NS', name: 'Nova Scotia'},
        {code: 'NT', name: 'Northwest Territories'},
        {code: 'NU', name: 'Nunavut'},
        {code: 'ON', name: 'Ontario'},
        {code: 'PE', name: 'Prince Edward Island'},
        {code: 'QC', name: 'Quebec'},
        {code: 'SK', name: 'Saskatchewan'},
        {code: 'YT', name: 'Yukon'},
    ],
    US: [
        {name: 'Alabama', code: 'AL'},
        {name: 'Alaska', code: 'AK'},
        {name: 'Arizona', code: 'AZ'},
        {name: 'Arkansas', code: 'AR'},
        {name: 'California', code: 'CA'},
        {name: 'Colorado', code: 'CO'},
        {name: 'Connecticut', code: 'CT'},
        {name: 'Delaware', code: 'DE'},
        {name: 'District of Columbia', code: 'DC'},
        {name: 'Florida', code: 'FL'},
        {name: 'Georgia', code: 'GA'},
        {name: 'Hawaii', code: 'HI'},
        {name: 'Idaho', code: 'ID'},
        {name: 'Illinois', code: 'IL'},
        {name: 'Indiana', code: 'IN'},
        {name: 'Iowa', code: 'IA'},
        {name: 'Kansas', code: 'KS'},
        {name: 'Kentucky', code: 'KY'},
        {name: 'Louisiana', code: 'LA'},
        {name: 'Maine', code: 'ME'},
        {name: 'Maryland', code: 'MD'},
        {name: 'Massachusetts', code: 'MA'},
        {name: 'Michigan', code: 'MI'},
        {name: 'Minnesota', code: 'MN'},
        {name: 'Mississippi', code: 'MS'},
        {name: 'Missouri', code: 'MO'},
        {name: 'Montana', code: 'MT'},
        {name: 'Nebraska', code: 'NE'},
        {name: 'Nevada', code: 'NV'},
        {name: 'New Hampshire', code: 'NH'},
        {name: 'New Jersey', code: 'NJ'},
        {name: 'New Mexico', code: 'NM'},
        {name: 'New York', code: 'NY'},
        {name: 'North Carolina', code: 'NC'},
        {name: 'North Dakota', code: 'ND'},
        {name: 'Ohio', code: 'OH'},
        {name: 'Oklahoma', code: 'OK'},
        {name: 'Oregon', code: 'OR'},
        {name: 'Pennsylvania', code: 'PA'},
        {name: 'Rhode Island', code: 'RI'},
        {name: 'South Carolina', code: 'SC'},
        {name: 'South Dakota', code: 'SD'},
        {name: 'Tennessee', code: 'TN'},
        {name: 'Texas', code: 'TX'},
        {name: 'Utah', code: 'UT'},
        {name: 'Vermont', code: 'VT'},
        {name: 'Virginia', code: 'VA'},
        {name: 'Washington', code: 'WA'},
        {name: 'West Virginia', code: 'WV'},
        {name: 'Wisconsin', code: 'WI'},
        {name: 'Wyoming', code: 'WY'}
    ]
}

export const provinces_states = [
    {countryCode: 'CA', country: 'Canada', code: 'AB', name: 'Alberta'},
    {countryCode: 'CA', country: 'Canada', code: 'BC', name: 'British Columbia'},
    {countryCode: 'CA', country: 'Canada', code: 'MB', name: 'Manitoba'},
    {countryCode: 'CA', country: 'Canada', code: 'NB', name: 'New Brunswick'},
    {countryCode: 'CA', country: 'Canada', code: 'NL', name: 'Newfoundland and Labrador'},
    {countryCode: 'CA', country: 'Canada', code: 'NS', name: 'Nova Scotia'},
    {countryCode: 'CA', country: 'Canada', code: 'NT', name: 'Northwest Territories'},
    {countryCode: 'CA', country: 'Canada', code: 'NU', name: 'Nunavut'},
    {countryCode: 'CA', country: 'Canada', code: 'ON', name: 'Ontario'},
    {countryCode: 'CA', country: 'Canada', code: 'PE', name: 'Prince Edward Island'},
    {countryCode: 'CA', country: 'Canada', code: 'QC', name: 'Quebec'},
    {countryCode: 'CA', country: 'Canada', code: 'SK', name: 'Saskatchewan'},
    {countryCode: 'CA', country: 'Canada', code: 'YT', name: 'Yukon'},
    {countryCode: 'US', country: 'United States', name: 'Alabama', code: 'AL'},
    {countryCode: 'US', country: 'United States', name: 'Alaska', code: 'AK'},
    {countryCode: 'US', country: 'United States', name: 'Arizona', code: 'AZ'},
    {countryCode: 'US', country: 'United States', name: 'Arkansas', code: 'AR'},
    {countryCode: 'US', country: 'United States', name: 'California', code: 'CA'},
    {countryCode: 'US', country: 'United States', name: 'Colorado', code: 'CO'},
    {countryCode: 'US', country: 'United States', name: 'Connecticut', code: 'CT'},
    {countryCode: 'US', country: 'United States', name: 'Delaware', code: 'DE'},
    {countryCode: 'US', country: 'United States', name: 'District of Columbia', code: 'DC'},
    {countryCode: 'US', country: 'United States', name: 'Florida', code: 'FL'},
    {countryCode: 'US', country: 'United States', name: 'Georgia', code: 'GA'},
    {countryCode: 'US', country: 'United States', name: 'Hawaii', code: 'HI'},
    {countryCode: 'US', country: 'United States', name: 'Idaho', code: 'ID'},
    {countryCode: 'US', country: 'United States', name: 'Illinois', code: 'IL'},
    {countryCode: 'US', country: 'United States', name: 'Indiana', code: 'IN'},
    {countryCode: 'US', country: 'United States', name: 'Iowa', code: 'IA'},
    {countryCode: 'US', country: 'United States', name: 'Kansas', code: 'KS'},
    {countryCode: 'US', country: 'United States', name: 'Kentucky', code: 'KY'},
    {countryCode: 'US', country: 'United States', name: 'Louisiana', code: 'LA'},
    {countryCode: 'US', country: 'United States', name: 'Maine', code: 'ME'},
    {countryCode: 'US', country: 'United States', name: 'Maryland', code: 'MD'},
    {countryCode: 'US', country: 'United States', name: 'Massachusetts', code: 'MA'},
    {countryCode: 'US', country: 'United States', name: 'Michigan', code: 'MI'},
    {countryCode: 'US', country: 'United States', name: 'Minnesota', code: 'MN'},
    {countryCode: 'US', country: 'United States', name: 'Mississippi', code: 'MS'},
    {countryCode: 'US', country: 'United States', name: 'Missouri', code: 'MO'},
    {countryCode: 'US', country: 'United States', name: 'Montana', code: 'MT'},
    {countryCode: 'US', country: 'United States', name: 'Nebraska', code: 'NE'},
    {countryCode: 'US', country: 'United States', name: 'Nevada', code: 'NV'},
    {countryCode: 'US', country: 'United States', name: 'New Hampshire', code: 'NH'},
    {countryCode: 'US', country: 'United States', name: 'New Jersey', code: 'NJ'},
    {countryCode: 'US', country: 'United States', name: 'New Mexico', code: 'NM'},
    {countryCode: 'US', country: 'United States', name: 'New York', code: 'NY'},
    {countryCode: 'US', country: 'United States', name: 'North Carolina', code: 'NC'},
    {countryCode: 'US', country: 'United States', name: 'North Dakota', code: 'ND'},
    {countryCode: 'US', country: 'United States', name: 'Ohio', code: 'OH'},
    {countryCode: 'US', country: 'United States', name: 'Oklahoma', code: 'OK'},
    {countryCode: 'US', country: 'United States', name: 'Oregon', code: 'OR'},
    {countryCode: 'US', country: 'United States', name: 'Pennsylvania', code: 'PA'},
    {countryCode: 'US', country: 'United States', name: 'Rhode Island', code: 'RI'},
    {countryCode: 'US', country: 'United States', name: 'South Carolina', code: 'SC'},
    {countryCode: 'US', country: 'United States', name: 'South Dakota', code: 'SD'},
    {countryCode: 'US', country: 'United States', name: 'Tennessee', code: 'TN'},
    {countryCode: 'US', country: 'United States', name: 'Texas', code: 'TX'},
    {countryCode: 'US', country: 'United States', name: 'Utah', code: 'UT'},
    {countryCode: 'US', country: 'United States', name: 'Vermont', code: 'VT'},
    {countryCode: 'US', country: 'United States', name: 'Virginia', code: 'VA'},
    {countryCode: 'US', country: 'United States', name: 'Washington', code: 'WA'},
    {countryCode: 'US', country: 'United States', name: 'West Virginia', code: 'WV'},
    {countryCode: 'US', country: 'United States', name: 'Wisconsin', code: 'WI'},
    {countryCode: 'US', country: 'United States', name: 'Wyoming', code: 'WY'}
]

export const getBackendOrderStatusCode = (value) => {
    switch (value) {
        case 'notPaid':
            return [0]
        case 'active':
            return [1]
        case 'cancelled':
            return [-1]
        case 'refunded':
            return [3, 4]
        default:
            break
    }
}

export const getLogo = (logoName) => {
    switch (logoName) {
        case 'canpar':
            return CanparLogo
        case 'ups':
            return UPSLogo
        case 'fedex':
            return FedExLogo
        case 'canadapost':
            return CanadaPostLogo
        default:
            break

    }
}

export const property_type = [
    {propertyType: 'Residential', houseType: '1 Bedroom Apartment'},
    {propertyType: 'Residential', houseType: '2 Bedroom Apartment'},
    {propertyType: 'Residential', houseType: '3 Bedroom Apartment'},
    {propertyType: 'Residential', houseType: '4+ Bedroom Apartment'},
    {propertyType: 'Residential', houseType: '1 Bedroom House'},
    {propertyType: 'Residential', houseType: '1 Bedroom House'},
    {propertyType: 'Residential', houseType: '2 Bedroom House'},
    {propertyType: 'Residential', houseType: '3 Bedroom House'},
    {propertyType: 'Residential', houseType: '4 Bedroom House'},
    {propertyType: 'Residential', houseType: 'Assisted Living'},
    {propertyType: 'Residential', houseType: 'Townhouse'},
    {propertyType: 'Residential', houseType: 'Condo'},
    {propertyType: 'Commercial', houseType: 'Office'},
    {propertyType: 'Warehouse', houseType: 'Warehouse'},
    {propertyType: 'Warehouse', houseType: 'Storage Unit (5 * 5)'},
    {propertyType: 'Warehouse', houseType: 'Storage Unit (5 * 10)'},
    {propertyType: 'Warehouse', houseType: 'Storage Unit (10 * 20)'},
]

export const getCountryCode = (accountBase = "ups", nafta = false) => {

    if (nafta) {
        return [
            {
                name: "Canada",
                code: "CA"
            },
            {
                name: "United States",
                code: "US"
            },
            {
                name: "Mexico",
                code: "MX"
            }
        ]
    }

    return [
        {
            name: "Argentina",
            code: "AR"
        },
        {
            name: "Australia",
            code: "AU"
        },
        {
            name: "Brazil",
            code: "BR"
        },
        {
            name: "Cambodia",
            code: "KH"
        },
        {
            name: "Canada",
            code: "CA"
        },
        {
            name: "China",
            code: "CN"
        },
        {
            name: "France",
            code: "FR"
        },
        {
            name: "Germany",
            code: "DE"
        },
        {
            name: "Hong Kong",
            code: "HK"
        },
        {
            name: "India",
            code: "IN"
        },
        {
            name: "Indonesia",
            code: "ID"
        },
        {
            name: "Italy",
            code: "IT"
        },
        {
            name: "Japan",
            code: "JP"
        },
        {
            name: "Laos People's Democratic LA Republic",
            code: "LA"
        },
        {
            name: "Malaysia",
            code: "MY"
        },
        {
            name: "Mexico",
            code: "MX"
        },
        {
            name: "Portugal",
            code: "PT"
        },
        {
            name: "Russia",
            code: "RU"
        },
        {
            name: "Saudi Arabia",
            code: "SA"
        },
        {
            name: "South Africa",
            code: "ZA"
        },
        {
            name: "South Korea",
            code: "KR"
        },
        {
            name: "Spain",
            code: "ES"
        },
        {
            name: "Taiwan",
            code: "TW"
        },
        {
            name: "Thailand",
            code: "TH"
        },
        {
            name: "Turkey",
            code: "TR"
        },
        {
            name: "United Kingdom",
            code: "GB"
        },
        {
            name: "United States",
            code: "US"
        },
        {
            name: "VietNam",
            code: "VN"
        },
    ]
}

export const mapCarrierLogo = (serviceName) => {
    const service = carrierLogo.filter(item => item.serviceName === serviceName?.toUpperCase());
    // console.log(service);
    return service[0]?.url
}

export const mapCarrierServiceName = (serviceName) => {
    const service = carrierLogo.filter(item => item?.serviceName?.toUpperCase() === serviceName?.toUpperCase());
    // console.log(service);
    return service.length > 0 ? service[0]?.displayName : serviceName;
}

export const carrierLogo = [
    {
        url: "https://storage.googleapis.com/uucargo-resource/image/icon/UPSLogo.png",
        serviceName: "UPS",
        displayName: "UPS"
    },
    {
        url: "https://storage.googleapis.com/uucargo-resource/image/icon/CanadaPostLogo.png",
        serviceName: "CANADAPOST",
        displayName: "Canada Post"
    },
    {
        url: "https://storage.googleapis.com/uucargo-resource/image/icon/FedExLogo.png",
        serviceName: "FEDEX",
        displayName: "FedEx"
    },
    {
        url: "https://storage.googleapis.com/uucargo-resource/image/icon/CanparLogo.png",
        serviceName: "CANPAR",
        displayName: "Canpar"
    },
    {
        url: "https://storage.googleapis.com/uucargo-resource/image/icon/GLSLogoNew.png",
        serviceName: "GLS",
        displayName: "GLS"
    },
]

export const getWelcome = () => {
    const isWelcome = Cookies.get('welcome');
    if (isWelcome) {
        return false;
    } else {
        return true;
    }
}

export const convertCMToIN = (value) => {
    const convertValue = (value / 2.54).toFixed(3);
    return convertValue;
}

export const convertINToCM = (value) => {
    const convertValue = (value * 2.54).toFixed(3);
    return convertValue;
}

export const convertKGToLB = (value) => {
    const convertValue = (value * 2.20462).toFixed(3);
    return convertValue;
}

export const convertLBToKG = (value) => {
    const convertValue = (value / 2.20462).toFixed(3);
    return convertValue;
}

export const convertCubicInToCubicMeter = (value) => {
    const convertValue = (value / 61023.744095).toFixed(2);
    return convertValue;
}

export const convertCubicMeterToCubicIn = (value) => {
    const convertValue = (value * 61023.744095).toFixed(2);
    return convertValue;
}

export const mapCountryIcon = (code) => {
    const countryIcon = CountryIcon.filter(item => item.code === code);
    // console.log(countryIcon);
    return countryIcon[0]?.component
}

export const CountryIcon = [
    {
        code: 'CA',
        component: <CanadaIcon width='20' height='20'/>
    },
    {
        code: 'US',
        component: <USIcon width='20' height='20'/>
    }
]

export const mapLTLLogo = (serviceName) => {
    const service = LTLLogo.filter(item => item?.serviceName?.toUpperCase() === serviceName?.toUpperCase());
    // console.log(service);
    return service[0]?.url
}

export const mapLTLServiceName = (serviceName) => {
    const service = LTLLogo.filter(item => item?.serviceName?.toUpperCase() === serviceName?.toUpperCase());
    // console.log(service);
    return service.length > 0 ? service[0]?.officialName : serviceName;
}

export const LTLLogo = [
    {
        url: "https://storage.googleapis.com/uucargo-resource/image/icon/J-Link.png",
        serviceName: "j-link",
        officialName: "J-Link"
    },
    {
        url: "https://storage.googleapis.com/uucargo-resource/image/icon/CSA.png",
        serviceName: "csa",
        officialName: "CSA"
    },
    {
        url: "https://storage.googleapis.com/uucargo-resource/image/icon/PurolatorLogo.png",
        serviceName: "purolator",
        officialName: "Purolator"
    },
    {
        url: "https://storage.googleapis.com/uucargo-resource/image/icon/Day%26Ross.png",
        serviceName: "dayandross",
        officialName: "Day & Ross"
    },
    {
        url: "https://storage.googleapis.com/uucargo-resource/image/icon/Vitran.png",
        serviceName: "vitran",
        officialName: "Vitran"
    },
    {
        url: "https://storage.googleapis.com/uucargo-resource/image/icon/TForce.png",
        serviceName: "tforce",
        officialName: "TForce"
    },
    {
        url: "https://storage.googleapis.com/uucargo-resource/image/icon/bison_logo.png",
        serviceName: "bison",
        officialName: "Bison"
    },
    {
        url: "https://storage.googleapis.com/uucargo-resource/image/icon/Kindersley.png",
        serviceName: "kindersley",
        officialName: "Kindersley"
    },
    {
        url: "https://storage.googleapis.com/uucargo-resource/image/icon/CVCLogo.png",
        serviceName: "cvc",
        officialName: "CVC"
    },
    {
        url: "https://storage.googleapis.com/uucargo-resource/image/icon/tfww.png",
        serviceName: "tforce-worldwide",
        officialName: "TForce Worldwide"
    },
    {
        url: "https://storage.googleapis.com/uucargo-resource/image/icon/meadallionIcon.jpg",
        serviceName: "medallion",
        officialName: "Medallion"
    },
    {
        url: "https://storage.googleapis.com/uucargo-resource/image/icon/UUCargoLogo.png",
        serviceName: "uucargo",
        officialName: "UUCargo"
    }
]

export const mapSpecialService = (code) => {
    switch (code) {
        case 'APTFGT':
            return 'Appointment Delivery';
            break
        case 'PRESDL':
            return 'Private Resident Delivery';
            break
        case 'PRESPU':
            return 'Private Resident Pickup';
            break
        case 'PROTEC':
            return 'Protected Service';
            break
        case 'TLGDEL':
            return 'Taligate Delivery';
            break
        case 'TLGPU':
            return 'Taligate Pickup';
            break
        default:
            return code
    }
}

export const mapLTLAdditionalService = (serviceCode) => {
    const service = LTLAdditionalService.filter(item => item.serviceCode === serviceCode);
    // console.log(service);
    return service[0]?.serviceName;
}

export const LTLAdditionalService = [
    {serviceCode: 'APTFGT', serviceName: 'Appointment Delivery'},
    {serviceCode: 'DANGEROUS', serviceName: 'Dangerous Goods'},
    {serviceCode: 'INSDLY', serviceName: 'Inside Delivery'},
    {serviceCode: 'PRESDL', serviceName: 'Private Resident Delivery'},
    {serviceCode: 'PRESPU', serviceName: 'Private Resident Pickup'},
    {serviceCode: 'PROTEC', serviceName: 'Protected Service'},
    {serviceCode: 'TLGDEL', serviceName: 'Tailgate Delivery'},
    {serviceCode: 'TLGGP', serviceName: 'Tailgate Pickup'},
    {serviceCode: 'INSFEE', serviceName: 'Insurance/Declared Value'},
]

export const getDateFromDaysLater = (day) => {
    const today = new Date();
    const hours = day * 24;
    const futureDate = new Date(today.getTime() + hours * 60 * 60 * 1000); // Adding two day

    return dayjs(futureDate);
};

export const getWorkingDateAfterDays = (days) => {
    let futureDate = dayjs().add(days, 'day');

    // Adjust if the future date falls on a weekend
    if (futureDate.day() === 6) {
        // If Saturday, move to Monday
        futureDate = futureDate.add(2, 'day');
    } else if (futureDate.day() === 0) {
        // If Sunday, move to Monday
        futureDate = futureDate.add(1, 'day');
    }

    return futureDate;
};

export const getAmountOfFTLCount = (packageData) => {
    console.log('package data', packageData);
    let result = 0;
    let totalQuantity = 0;
    for (const onePackage of packageData) {
        const {quantity} = onePackage;
        totalQuantity += +quantity;
    }
    if (totalQuantity > 19 && totalQuantity < 24) {
        return 1
    } else {
        result = Math.floor(totalQuantity / 24);
        return result;
    }
};

export const incoterms = [
    {code: 'CFR', name: 'Cost & Freight'},
    {code: 'CIF', name: 'Cost, Insurance & Freight'},
    {code: 'CIP', name: 'Carriage and Insurance Paid-To'},
    {code: 'CPT', name: 'Carriage Paid-To'},
    {code: 'DAP', name: 'Delivered At Place'},
    {code: 'DAT', name: 'Delivered At Terminal'},
    {code: 'DDP', name: 'Delivered Duty Paid'},
    {code: 'DDU', name: 'Delivered Duty Unpaid'},
    {code: 'DPU', name: 'Delivered at Place Unloaded'},
    {code: 'EXW', name: 'Ex Works'},
    {code: 'FAS', name: 'Free Alongside Ship'},
    {code: 'FCA', name: 'Free Carrier'},
    {code: 'FOB', name: 'Free On Board'}
]

export const mapRoomPlaceIcon = (roomPlaceName, color, size) => {
    const service = roomPlaceIcon.find(item => item?.name?.toUpperCase() === roomPlaceName?.toUpperCase());
    return service ? <service.icon fill={color} height={size} width={size}/> : null;
}

export const roomPlaceIcon = [
    {
        icon: BathroomIcon,
        name: "Bathroom"
    },
    {
        icon: BedroomIcon,
        name: "Bedroom"
    },
    {
        icon: BoxesIcon,
        name: "Boxes"
    },
    {
        icon: DinningRoomIcon,
        name: "Dinning Room"
    },
    {
        icon: GarageIcon,
        name: "Garage"
    },
    {
        icon: GardenIcon,
        name: "Garden/Patio"
    },
    {
        icon: HomeOfficeIcon,
        name: "Home Office"
    },
    {
        icon: KitchenIcon,
        name: "Kitchen"
    },
    {
        icon: LivingRoomIcon,
        name: "Living Room"
    },
    {
        icon: OthersIcon,
        name: "Others"
    },
]

export const timeSlot = [
    {text: '9 am - 10 am', from: '9:00', to: '10:00'},
    {text: '10 am - 11 am', from: '10:00', to: '11:00'},
    {text: '11 am - 12 pm', from: '11:00', to: '12:00'},
    {text: '1 pm - 2 pm', from: '13:00', to: '14:00'},
    {text: '2 pm - 3 pm', from: '14:00', to: '15:00'},
    {text: '3 pm - 4 pm', from: '15:00', to: '16:00'},
    {text: '4 pm - 5 pm', from: '16:00', to: '17:00'},
    {text: '5 pm - 6 pm', from: '17:00', to: '18:00'}
]

export const generateUniqueName = (baseName, nameList) => {

    if (baseName) {

        // Extract names from the array of objects
        const existingNames = nameList.map(item => item.description);

        // Check if baseName already has a numeric suffix like "car(1)"
        const regex = /^(.*?)(\(\d+\))?$/;
        const match = baseName.match(regex);
        let nameWithoutSuffix = match[1]; // "car"
        let suffixNumber = match[3] ? parseInt(match[3], 10) : 0; // existing number or 0

        let newName = baseName;
        let counter = suffixNumber + 1;

        while (existingNames.includes(newName)) {
            newName = `${nameWithoutSuffix}(${counter})`;
            counter++;
        }

        return newName;
    } else {
        return "";
    }

}

export const areAllResellerAccounts = (selectOrder) => {
    // Iterate through each subarray
    for (const subArray of selectOrder) {
        // Check each object in the subarray
        for (const obj of subArray) {
            if (obj.isResellerAccount !== true) {
                return false; // Return false if any object has isResellerAccount not true
            }
        }
    }
    return true; // Return true if all objects have isResellerAccount as true
}

export const checkAppointmentListFromSelectOrder = (selectOrder) => {
    if (selectOrder?.length === 0) {
        return []
    } else {
        const currentDate = dayjs(new Date());
        const appointList = [];
        const updateSelectOrder = selectOrder?.map(selectOrder => {
            const order = selectOrder?.[0];
            // console.log('[checkAppointmentListFromSelectOrder] selectOrder', selectOrder?.[0]);

            const filteredAppointmentList = order?.appointmentList?.filter(appointment => {
                const appointmentDate = dayjs(appointment?.appoint_date);
                const status = appointment?.status;
                // console.log('[checkAppointmentListFromSelectOrder] currentDate', currentDate);
                // console.log('[checkAppointmentListFromSelectOrder] appointmentDate', appointmentDate);
                return (!(appointmentDate?.isBefore(currentDate, 'day')) && +status !== -1);
            })?.map(appointment => ({
                ...appointment,
                shippingOrderId: order?.order_id
            }));
            if (filteredAppointmentList?.length > 0) {
                appointList.push(filteredAppointmentList?.[0]);
            }
            return {
                ...selectOrder,
                appointments: filteredAppointmentList
            }
        });
        // console.log('[checkAppointmentList] appointList', appointList);
        //
        // console.log('[checkAppointmentList] updateSelectOrder', updateSelectOrder);
        return appointList;
    }
}

export const checkScheduledPickup = (selectOrder) => {
    const appointmentList = selectOrder?.appointmentList;
    // console.log('[checkScheduledPickup] appointmentList', appointmentList);

    if (appointmentList?.length === 0) {
        return false
    } else {
        const currentDate = dayjs(new Date());
        const isScheduled = selectOrder?.appointmentList?.some(appointment => {
            const appointmentDate = dayjs(appointment?.appoint_date);
            const status = appointment?.status;
            return (!(appointmentDate?.isBefore(currentDate, 'day')) && +status !== -1);
        })
        return isScheduled;
    }
}

export const costWithCanadianCurrencyCode = (fromCountry, toCountry, cost) => {
    let amount;
    if (fromCountry?.toUpperCase() !== 'CA' || toCountry?.toUpperCase() !== 'CA') {
        amount = `C$ ${(+cost)?.toFixed(2)}`
    } else {
        amount = `$ ${(+cost)?.toFixed(2)}`
    }
    return amount;
}

export const checkHSCodeInValid = (errorList, hsCode) => {
    console.log('[checkHSCodeInValid] errorList', errorList);
    console.log('[checkHSCodeInValid] hsCode', hsCode);

    return errorList?.some(item => +item?.hsCode === +hsCode)
}

export const checkIsPOBoxAddress = (address) => {
    console.log('[checkIsPOBoxAddress] address', address);
    if (address) {
        const trimmedAddress = address.trim();
        const poBoxPattern = /(?:^|\s)P\.?\s?O\.?\s?Box\s?\d*\b/i;
        return poBoxPattern.test(trimmedAddress);
    }
    return false;
}

export const logInErrorMessage = (errorCode) => {
    console.log('[logInErrorMessage] error code', errorCode);
    switch (+errorCode) {
        case USER_LOGIN_EMAIL_PASSWORD_NOT_MATCH:
            return 'Incorrect password. Please try again.'
        case USER_ACCOUNT_STATUS_INVALID:
            return 'Invalid account status. Please contact support for assistance.'
        case USER_LOGIN_EMAIL_NOT_EXIST:
            return 'This email is not registered. Please check the email or sign up for a new account.'
        case USER_LOGIN_NEED_TO_VERIFY_EMAIL:
            return 'Your account is not activated yet. Please check your email for the activation link.'
        default:
            return 'An unknown error occurred. Please try again later.'; // Or any message you prefer
    }
}

export const isCardExpiryValid = (expiryDate) => {
    // Extract the month and year from the expiry date (assumes format "MM/YY")
    const [expMonth, expYear] = expiryDate.split('/').map(Number);

    // Get the current month and year
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1; // JavaScript months are 0-based
    const currentYear = currentDate.getFullYear() % 100; // Get last two digits of the year

    // Check if the card is still valid
    if (expYear > currentYear || (expYear === currentYear && expMonth >= currentMonth)) {
        return true; // Valid expiration date
    } else {
        return false; // Expired card
    }
}

export const filterRateList = (rateList) => {
    const result = [];

    rateList.forEach(service => {
        // Check for a service with the same `serviceName` and `coOperated`
        const sameServiceAndCoOperated = result.find(
            item => item?.serviceName === service?.serviceName &&
                item?.coOperated === service?.coOperated &&
                service?.coOperated != null
        );

        if (sameServiceAndCoOperated) {
            // If `serviceName` and `coOperated` are the same, keep both
            result.push(service);
            return;
        }

        // Check for a service with the same `serviceName` and an `errorMessage`
        const sameServiceWithErrorIndex = result.findIndex(
            item => item?.serviceName === service?.serviceName && item?.errorMessage
        );

        if (sameServiceWithErrorIndex !== -1) {
            // If both have `errorMessage`, keep only one
            return; // Skip adding this service
        }

        // Check for a service with the same `serviceName` but different `coOperated`
        const sameServiceDifferentCoOperatedIndex = result.findIndex(
            item => item?.serviceName === service?.serviceName && item?.coOperated !== service?.coOperated
        );

        if (sameServiceDifferentCoOperatedIndex !== -1) {
            const existingService = result[sameServiceDifferentCoOperatedIndex];

            // Compare totalCharge and keep the lower one
            if (service?.totalCharge?.value < existingService?.totalCharge?.value) {
                result[sameServiceDifferentCoOperatedIndex] = service;
            }
        } else {
            // If no matching `serviceName` exists, add the service to the result
            result.push(service);
        }
    });

    return result;
};

export const getRemovedRateList = (rateList) => {
    const result = [];
    const removedList = [];

    rateList.forEach(service => {
        // Check for a service with the same `serviceName` and `coOperated`
        const sameServiceAndCoOperated = result.find(
            item => item?.serviceName === service?.serviceName &&
                item?.coOperated === service?.coOperated &&
                service?.coOperated != null
        );

        if (sameServiceAndCoOperated) {
            // If `serviceName` and `coOperated` are the same, keep both
            result.push(service);
            return;
        }

        // Check for a service with the same `serviceName` and an `errorMessage`
        const sameServiceWithErrorIndex = result.findIndex(
            item => item?.serviceName === service?.serviceName && item?.errorMessage
        );

        if (sameServiceWithErrorIndex !== -1) {
            removedList.push(service);
            return;
        }

        // Check for a service with the same `serviceName` but different `coOperated`
        const sameServiceDifferentCoOperatedIndex = result.findIndex(
            item => item?.serviceName === service?.serviceName && item?.coOperated !== service?.coOperated
        );

        if (sameServiceDifferentCoOperatedIndex !== -1) {
            const existingService = result[sameServiceDifferentCoOperatedIndex];

            // Compare totalCharge and keep the lower one
            if (service?.totalCharge?.value < existingService?.totalCharge?.value) {
                removedList.push(existingService);
                result[sameServiceDifferentCoOperatedIndex] = service;
            } else {
                removedList.push(service);
            }
        } else {
            result.push(service);
        }
    });

    return removedList;
};

export const mappingBackendOrderStatus = (statusCode, invoiceNumber) => {
    switch (statusCode) {
        case 0:
            return "Unpaid";
        case 1:
            return "Paid";
        case 2:
            return "To-Be Refunded";
        case 3:
            return "Refunded";
        case 200:
            return invoiceNumber ? "Billed" : "Not Billed";
        default:
            return "unknown";
    }
}

export const splitMessage = (message) => {
    const [purpose, ...formParts] = message.split(" ");
    const type = formParts.join(" ");
    return {purpose, type}
}

export const isValidStringInput = (value) => {
    if (typeof value !== "string") return false; // Reject non-string values
    return !/^(n\/a|na)$/i.test(value.trim()) && value.trim() !== "";
}

export const isNotApplicableString = (value) => {
    return !/^(n\/a|na)$/i.test(value?.trim());
}

export const isValidURL = (url) => {
    const regex = /^(https?:\/\/)(\[[0-9a-fA-F:]+\]|(?:\d{1,3}\.){3}\d{1,3}|[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})(:\d{1,5})?(\/[^\s]*)?$/;
    return regex.test(url) && !url.endsWith("/");
}

export const carManufactures = [
    "Abarth",
    "Alfa Romeo",
    "Aston Martin",
    "Audi",
    "Bentley",
    "BMW",
    "Bugatti",
    "Cadillac",
    "Chevrolet",
    "Chrysler",
    "Citroën",
    "Dacia",
    "Daewoo",
    "Daihatsu",
    "Dodge",
    "Donkervoort",
    "DS",
    "Ferrari",
    "Fiat",
    "Fisker",
    "Ford",
    "Honda",
    "Hummer",
    "Hyundai",
    "Infiniti",
    "Iveco",
    "Jaguar",
    "Jeep",
    "Kia",
    "KTM",
    "Lada",
    "Lamborghini",
    "Lancia",
    "Land Rover",
    "Landwind",
    "Lexus",
    "Lincoln",
    "Lotus",
    "Maserati",
    "Maybach",
    "Mazda",
    "McLaren",
    "Mercedes-Benz",
    "MG",
    "Mini",
    "Mitsubishi",
    "Morgan",
    "Nissan",
    "Opel",
    "Peugeot",
    "Porsche",
    "Renault",
    "Rolls-Royce",
    "Rover",
    "Saab",
    "Seat",
    "Skoda",
    "Smart",
    "SsangYong",
    "Subaru",
    "Suzuki",
    "Tesla",
    "Toyota",
    "Volkswagen",
    "Volvo"
]

export const carSizeList = [
    "SEDAN",
    "COMPACT-SUV",
    "MIDDLE-SUV",
    "LARGE-SUV",
    "PICKUP-TRUCK",
]

export const isValidTariffCode = (input) => {
    const pattern = /^\d{4}(\.\d{2}(\.\d{2}(\.\d{2})?)?)?$/;
    return pattern.test(input);
}

export const compareCustomsTotalWeightToPackageWeight = (customsTotalWeight, customsWeightUnit, packageWeight, packageWeightUnit) => {
    const convertedCustomsTotalWeight = customsWeightUnit?.toLowerCase() === 'lb' ? convertLBToKG(customsTotalWeight) : customsTotalWeight;
    const convertedPackageWeight = packageWeightUnit?.toLowerCase() === 'lb' ? convertLBToKG(packageWeight) : packageWeight;

    return +convertedCustomsTotalWeight <= convertedPackageWeight;
}

export const packageCount = (packageList) => {
    const totalQuantity = packageList?.reduce((sum, each) => sum + +each?.quantity, 0);
    return +totalQuantity;
}

export const generateCarShippingQuoteRequest = (data) => {
    const fromLocation = `${data.shipFromCity}, ${data.shipFromProvince}, ${data.shipFromCountry} (${data.shipFromPostalCode})`;
    const toLocation = `${data.shipToCity}, ${data.shipToProvince}, ${data.shipToCountry} (${data.shipToPostalCode})`;

    const carList = data.packageData
        .map(car => `${car.quantity}x ${car.yearOfProduction} ${car.make} ${car.model} (${car.categoryValue.replace("CAR-SIZE-", "").replace("-", " ")})`)
        .join(", ");

    return `Could you provide a detailed and conservative quote for transporting the following cars from ${fromLocation} to ${toLocation}: ${carList}? Please include estimated transit time and any relevant details.`;
}

export const numArray = (start, end, order = 'asc') => {
    const array = Array.from({length: end - start + 1}, (_, i) => start + i);
    if (order === 'desc') {
        array.reverse();
    }
    return array.map(year => year.toString());
}

// Set Cookie (modified to store both count and timestamp)
export const setAPICallTimesInCookie = (key, value, expireMin) => {
    const date = new Date();
    date.setTime(date.getTime() + (expireMin * 60 * 1000));
    document.cookie = `${key}=${value}; expires=${date.toUTCString()}; path=/; ${
        process.env.NODE_ENV === 'production' ? 'domain=.uucargo.ca;' : ''
    }`;
};

export const getAPICallTimesFromCookie = (key) => {
    const cookie = document.cookie
        .split(';')
        .map(c => c.trim())
        .find(c => c.startsWith(`${key}=`));

    return cookie ? cookie.split('=')[1] : null;
};

export const resetAPICallLimit = (apiKey) => {
    const cookieName = `apiLimit_${apiKey}`;
    document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; ${
        process.env.NODE_ENV === 'production' ? 'domain=.uucargo.ca;' : ''
    }`;
};

export const checkAPICallLimit = (apiKey) => {
    const MAX_CALLS = 10;
    const TIME_WINDOW_MINUTES = 10;
    const cookieName = `apiLimit_${apiKey}`;

    // Get current call count & last call time
    const cookieData = getAPICallTimesFromCookie(cookieName);
    let callCount = 0;
    let lastCallTime = 0;

    if (cookieData) {
        const [storedCount, storedTime] = cookieData.split('|');
        callCount = parseInt(storedCount, 10);
        lastCallTime = parseInt(storedTime, 10);
    }

    // Check if time window has expired
    const currentTime = Date.now();
    const timeWindowMs = TIME_WINDOW_MINUTES * 60 * 1000;
    const timeElapsed = currentTime - lastCallTime;

    if (timeElapsed > timeWindowMs) {
        // Reset counter if time window expired
        callCount = 0;
    }

    // Check if limit exceeded
    if (callCount >= MAX_CALLS) {
        const nextAvailableTime = new Date(lastCallTime + timeWindowMs);
        return {
            allowed: false,
            remaining: 0,
            nextAvailable: nextAvailableTime
        };
    }

    // Update call count and timestamp
    const newCount = callCount + 1;
    setAPICallTimesInCookie(cookieName, `${newCount}|${currentTime}`, TIME_WINDOW_MINUTES);

    return {
        allowed: true,
        remaining: MAX_CALLS - newCount,
        nextAvailable: null
    };
};




