import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import {
    Button,
    Container,
    Divider,
    IconButton, Link, ListItem,
    MenuItem, Modal,
    Select,
    Snackbar,
    Stack,
    Step,
    StepLabel,
    Stepper
} from "@mui/material";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@mui/material/FormControl";
import {
    compareCustomsTotalWeightToPackageWeight,
    generateRandomString,
    isValidTariffCode,
    packageCount,
    transportationList
} from "../../Utils/Helper";
import * as React from "react";
import MuiAlert from "@mui/material/Alert";
import Switch from "@mui/material/Switch";
import {styled} from "@mui/material/styles";
import addPackage from "../../images/Create Label.png";
import {
    addShippingPackageCrossBorderItemList,
    updateCreateLabelShippingPackageCrossBorder,
    updateDeclarationDutyFreeEntry,
    updateShipmentPurpose,
    updateShippingPackageCrossBorderRate
} from "../../actions/shippingPackageAction";
import {ShippingPackageCrossBorderItem} from "./ShippingPackageCrossBorderItem";
import LoadingButton from "@mui/lab/LoadingButton";
import {FORM_3299, FORM_434, FORM_B232, NODE_ROUTE_URI} from "../../Utils/apiUrl";
import axios from "axios";
import {InternationalSteps, QontoConnector, QontoStepIcon, steps} from "../../Utils/CustomComponent";
import {ReactComponent as CrossBorderIcon} from "../../images/NewDesignIcon/CustomsDeclaration.svg";
import FormLabel from "@mui/material/FormLabel";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import {green, grey} from "../../Utils/styleConfig";
import FormHelperText from "@mui/material/FormHelperText";
import {internationalShipmentTypeList, nonDeliveryHandingListToCodeMap} from "../shared";
import RadioGroup from "@mui/material/RadioGroup";
import {invoiceTypeCommercial, invoiceTypePersonal} from "../../Utils/config";
import Radio from "@mui/material/Radio";
import {ReactComponent as ItemDescriptionIcon} from "../../images/icons/itemDescription.svg";
import {CrossBorderModal} from "../CrossBorder/CrossBorderModal";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const styles = {
    ShippingPackageCrossBorderRoot: {
        backgroundColor: '#FFFFFF',
        // padding: '20px',
        marginBottom: '40px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        gap: '20px',
        display: 'flex',
        flexDirection: 'column',
        margin: '20px 0'
    },

    ShippingPackageCrossBorderHeading: {
        fontSize: '18px',
        fontWeight: '600',
        textAlign: 'left'
    },

    ShippingPackageCrossBorderSubHeading: {
        fontSize: '16px',
        fontWeight: '600',
        textAlign: 'left'
    },
    ShippingPackageCreateLabelRateListContent: {
        backgroundColor: '#FFFFFF',
        padding: '0 40px',
        marginBottom: '40px',
        borderRadius: '10px',
        gap: '20px',
        display: 'flex',
        flexDirection: 'column',
    },
    ShippingPackageCreateLabelPackageSection: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '20px 20px 0 20px',
        alignItems: 'center'
    },
    ShippingPackageCreateLabelPackageSubSection: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
}

const AntSwitch = styled(Switch)(({theme}) => ({
    width: 32,
    height: 20,
    padding: 0,
    display: 'flex',
    '&:active': {
        '& .MuiSwitch-thumb': {
            width: 20,
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(9px)',
        },
    },
    '& .MuiSwitch-switchBase': {
        padding: 2,
        '&.Mui-checked': {
            transform: 'translateX(12px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.mode === 'dark' ? '#609966' : '#609966',
            },
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
        width: 16,
        height: 16,
        borderRadius: 8,
        transition: theme.transitions.create(['width'], {
            duration: 200,
        }),
    },
    '& .MuiSwitch-track': {
        borderRadius: 20 / 2,
        opacity: 1,
        backgroundColor:
            theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
        boxSizing: 'border-box',
    },
}));

const requiredProperties = ["description", "quantity", "weight", "weightUnit", "UOM", "currency", "priceEach", "hscode", "originCountryCode"];
const validateRequiredProperties = ["quantity", "weight", "priceEach"];

export const ShippingPackageCrossBorder = () => {

    const dispatch = useDispatch();
    const history = useHistory();

    let itemListRedux = useSelector(state => state?.shippingPackage?.crossBorderItemList);
    let shipFrom = useSelector(state => state?.shippingPackage?.createLabelShipFrom);
    let shipTo = useSelector(state => state?.shippingPackage?.createLabelShipTo);
    let selectRate = useSelector(state => state?.shippingPackage?.createLabelSelectedRate);
    let packageListRedux = useSelector(state => state?.shippingPackage?.createLabelPackageList);

    const [validation, setValidation] = useState(true);
    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [crossBorderFee, setCrossBorderFee] = useState(null);
    const [loading, setLoading] = useState(false);
    const [itemId, setItemId] = useState(1);
    const [metric, setMetric] = useState(false);
    const [crossBorder, setCrossBorder] = useState({
        tranMode: "INT_TRUCK",
        shipmentType: "COMMERCIAL",
        option: "RASE"
    });
    const [errorList, setErrorList] = useState([]);
    const [declarationDutyFreeEntry, setDeclarationDutyFreeEntry] = useState(false);
    const [shipmentPurpose, setShipmentPurpose] = useState(null);
    const [totalQuantity, setTotalQuantity] = useState(0);
    const [totalWeight, setTotalWeight] = useState(0);
    const [totalValue, setTotalValue] = useState(0);
    const [openItemDescription, setOpenItemDescription] = useState(false);
    const [minPackage, setMinPackage] = useState(null);

    const handleOpenItemDescription = () => {
        setOpenItemDescription(true);
    }

    const handleCloseItemDescription = () => {
        setOpenItemDescription(false);
    }

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const handleShipmentPurpose = (event) => {
        const purpose = event.target.value;
        setShipmentPurpose(purpose);
        if (+purpose === +invoiceTypePersonal) {
            setCrossBorder(prevState => ({
                ...prevState,
                shipmentType: 'PERSONAL USE'
            }))
        } else {
            setCrossBorder(prevState => ({
                ...prevState,
                shipmentType: 'COMMERCIAL'
            }))
            setDeclarationDutyFreeEntry(false);
        }
    }

    const handleFormCheck = (event) => {
        const checkValue = event?.target?.checked
        setDeclarationDutyFreeEntry(checkValue);
    }

    const handleTranMode = (event) => {
        setCrossBorder(prevState => ({
            ...prevState,
            tranMode: event.target.value
        }))
    }

    const handleShipmentType = (event) => {
        setCrossBorder(prevState => ({
            ...prevState,
            shipmentType: event.target.value
        }))
    }

    const handleShipmentOptions = (event) => {
        setCrossBorder(prevState => ({
            ...prevState,
            option: event.target.value
        }))
    }

    const handleMetric = (event) => {
        setMetric(event.target.checked);
    }

    const handleAddItem = () => {

        const itemId = generateRandomString(8);
        const newItem = {
            description: '',
            sku: '',
            weight: '',
            weightUnit: metric ? 'kg' : 'lb',
            UOM: 'BOX',
            hscode: '',
            originCountryCode: 'CA',
            originProvinceCode: 'BC',
            currency: 'CAD',
            priceEach: '',
            quantity: '1',
            itemId: itemId
        }
        dispatch(addShippingPackageCrossBorderItemList(newItem));
        setItemId(prevState => prevState + 1);
    }

    const northAmericaShipment = () => {
        if ((shipFrom?.country?.toLowerCase() === 'ca' || shipFrom?.country?.toLowerCase() === 'us') && (shipTo?.country?.toLowerCase() === 'ca' || shipTo?.country?.toLowerCase() === 'us')) {
            return true
        } else {
            return false
        }
    }

    const handleBack = () => {
        // const backToRateList = northAmericaShipment();
        // if (backToRateList) {
        history.push('/shipping-package/create-label/rate-list');
        // } else {
        //     history.push('/shipping-package/international-shipment/rate-list');
        // }
    }

    const handleOrderDetails = () => {
        history.push('/shipping-package/create-label/order-details')
    }

    const validate = () => {
        setErrorMessage('');
        setToastOpen(false);
        const hasMissingValues = itemListRedux.some(object => requiredProperties.some((property) => !object[property]));
        const hasValidateValues = itemListRedux.some(object => validateRequiredProperties.some((property) => (object[property]) <= 0));
        const hasMissingProvince = itemListRedux.some(object => object.originCountryCode === "CA" && (!object.originProvinceCode));
        const hasInvalidHSCode = itemListRedux.some(object => !isValidTariffCode(object?.hscode));
        const customsTotalWeightValid = selectRate?.accountBase?.toUpperCase() === 'CANADAPOST' ? compareCustomsTotalWeightToPackageWeight(+totalWeight, itemListRedux?.[0]?.weightUnit, +minPackage?.weight, minPackage?.weightUnit) : true;

        itemListRedux?.length === 0 && setErrorMessage(prevState => prevState + "Your item list is empty.");
        hasMissingValues && setErrorMessage(prevState => prevState + "Your item list has missing one or more required fields.");
        hasValidateValues && setErrorMessage(prevState => prevState + "Your item list has one or more invalid fields.");
        !shipmentPurpose && setErrorMessage(prevState => prevState + "Shipment purpose is missing.");
        hasMissingProvince && setErrorMessage(prevState => prevState + "Origin province is missing.");
        hasInvalidHSCode && setErrorMessage(prevState => prevState + "HS Code is invalid.");
        !customsTotalWeightValid && setErrorMessage(prevState => prevState + "Total Customs Weight must not be greater than the item weight.")
        // setShipmentPurpose(!!shipmentPurpose);

        if (!hasMissingValues &&
            !hasValidateValues &&
            itemListRedux.length !== 0 &&
            shipmentPurpose &&
            !hasMissingProvince &&
            !hasInvalidHSCode &&
            customsTotalWeightValid) {
            return true
        } else {
            handleErrorMessage();
            return false
        }
    }

    const getCrossBorderFee = async () => {
        setErrorList([]);
        setLoading(true);
        let requestURL = `${NODE_ROUTE_URI}/brokerage/package/landedCostByServiceName`;

        let data = {
            serviceName: "ups",
            importCountryCode: shipTo?.country,
            importProvince: shipTo?.province?.code,
            exportCountryCode: shipFrom?.country,
            transModes: crossBorder?.tranMode,
            shipmentType: crossBorder?.shipmentType,
            shipmentItems: itemListRedux,
            declarationDutyFreeEntry: declarationDutyFreeEntry
        }

        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                data: data
            })
            console.log('result', result);
            if (result?.data?.error?.errors?.length > 0) {
                setErrorMessage(`Fail to get cross border fees. ${result?.data?.error?.errors[0]?.message}`);
                handleErrorMessage();
                setErrorList(result?.data?.error?.errors);
                return false;
            } else {
                setErrorMessage('');
                // setSuccessMessage('Successfully get cross border fees.');
                // handleSuccessMessage();
                setCrossBorderFee(result?.data);
                return true
            }
        } catch (e) {
            console.log('error', e.response);
            setErrorMessage('Fail to get cross border fees.');
            handleErrorMessage();
            return false
        } finally {
            setLoading(false);
        }
    }

    const handleGetCrossBorderRate = async () => {
        const validationResult = validate();
        setValidation(validationResult);
        if (validationResult) {
            setCrossBorderFee(null);
            const crossBorderRateResult = await getCrossBorderFee();
            crossBorderRateResult && handleOrderDetails();
        }
    }

    useEffect(() => {
        dispatch(updateShippingPackageCrossBorderRate(crossBorderFee));
    }, [crossBorderFee]);

    useEffect(() => {
        dispatch(updateCreateLabelShippingPackageCrossBorder(crossBorder))
    }, [crossBorder]);

    useEffect(() => {
        dispatch(updateDeclarationDutyFreeEntry(declarationDutyFreeEntry))
    }, [declarationDutyFreeEntry]);

    useEffect(() => {
        dispatch(updateShipmentPurpose(shipmentPurpose))
    }, [shipmentPurpose]);

    useEffect(() => {
        if (itemListRedux?.length > 0) {
            const totalQuantity = itemListRedux.reduce((total, item) => total + +item?.quantity, 0);
            const totalWeight = itemListRedux.reduce((total, item) => total + +item?.weight * +item?.quantity, 0);
            const totalValue = itemListRedux.reduce((total, item) => total + +item?.priceEach * +item?.quantity, 0);
            setTotalQuantity(totalQuantity);
            setTotalWeight(totalWeight);
            setTotalValue(totalValue);
        } else {
            setTotalQuantity(0);
            setTotalWeight(0);
            setTotalValue(0);
        }
    }, [itemListRedux]);

    useEffect(() => {
        if (packageListRedux?.length > 0) {
            const packageData = packageListRedux?.reduce((lightest, current) => +current?.weight < +lightest?.weight ? current : lightest);
            setMinPackage(packageData);
        }
    }, [packageListRedux]);

    console.log('[ShippingPackageCrossBorder] shipment purpose', shipmentPurpose);
    console.log('[ShippingPackageCrossBorder] item list redux', itemListRedux);
    console.log('[ShippingPackageCrossBorder] total quantity', totalQuantity);
    console.log('[ShippingPackageCrossBorder] total weight', totalWeight);
    console.log('[ShippingPackageCrossBorder] total value', totalValue);

    return (
        <Container maxWidth='xl'>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Stepper alternativeLabel activeStep={2} connector={<QontoConnector/>}>
                {
                    (shipFrom?.country === shipTo?.country ? steps : InternationalSteps).map((label, index) => (
                        <Step key={label}>
                            {/*<StepButton onClick={handleStep(index)}>*/}
                            <StepLabel StepIconComponent={QontoStepIcon}>
                                <Typography sx={{color: index <= 1 ? '#1D8B45' : '#C7C7C7', fontSize: '14px'}}>
                                    {label}
                                </Typography>
                            </StepLabel>
                            {/*</StepButton>*/}
                        </Step>
                    ))
                }
            </Stepper>
            <Box style={styles.ShippingPackageCrossBorderRoot}>
                <Box sx={styles.ShippingPackageCreateLabelPackageSection}>
                    <Box sx={{
                        display: 'flex',
                        gap: '10px',
                    }}>
                        <CrossBorderIcon width={25} height={25}/>
                        <Typography style={styles.ShippingPackageCrossBorderHeading}>
                            Shipping Mode & From Check
                        </Typography>
                    </Box>
                </Box>
                <Divider/>
                <Box sx={styles.ShippingPackageCreateLabelRateListContent}>
                    <Grid container spacing={2}>
                        <Grid item md={12} xs={12}>
                            <FormControl fullWidth>
                                <FormLabel required>Shipment Purpose</FormLabel>
                                <RadioGroup
                                    row
                                    value={shipmentPurpose}
                                    onChange={handleShipmentPurpose}
                                >
                                    <FormControlLabel
                                        value={invoiceTypeCommercial}
                                        control={<Radio
                                            sx={{
                                                color: green,
                                                '&.Mui-checked': {
                                                    color: green,
                                                },
                                            }}/>}
                                        label={
                                            <Typography sx={{fontSize: '14px'}}>Commercial</Typography>
                                        }/>
                                    <FormControlLabel
                                        value={invoiceTypePersonal}
                                        control={<Radio
                                            sx={{
                                                color: green,
                                                '&.Mui-checked': {
                                                    color: green,
                                                },
                                            }}/>}
                                        label={
                                            <Typography sx={{fontSize: '14px'}}>Personal</Typography>
                                        }/>
                                </RadioGroup>
                                {
                                    (shipmentPurpose !== null && !shipmentPurpose) &&
                                    <FormHelperText sx={{color: "error.main"}}>
                                        Missing
                                    </FormHelperText>
                                }
                            </FormControl>
                        </Grid>
                        <Grid item md={12} xs={12}>
                            <FormControl variant="outlined" fullWidth>
                                <FormLabel>
                                    Form Check
                                </FormLabel>
                                {
                                    +shipmentPurpose === +invoiceTypePersonal ?
                                        <>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        sx={{
                                                            color: green,
                                                            '&.Mui-checked': {
                                                                color: green,
                                                            },
                                                        }}
                                                        checked={declarationDutyFreeEntry}
                                                        onChange={handleFormCheck}
                                                        disabled={+shipmentPurpose === +invoiceTypeCommercial || shipTo?.country?.toUpperCase() !== 'US'}
                                                    />
                                                }
                                                label="The CBP Form 3299 - Declaration of Free Entry of Unaccompanied Articles"
                                            />
                                            <FormHelperText>
                                                The <span><Link href={FORM_3299}
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                                sx={{
                                                                    color: green,
                                                                    textDecoration: 'none',
                                                                    '&:hover': {
                                                                        textDecoration: 'underline',
                                                                        color: green
                                                                    }
                                                                }}> CBP Form 3299</Link></span> is used by individuals
                                                or entities to declare articles being imported into the United States.
                                                This form allows for the exemption of certain articles from duties and
                                                taxes, facilitating the entry of personal effects or gifts that do not
                                                exceed specified value limits.
                                            </FormHelperText>
                                        </>
                                        :
                                        <>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        sx={{
                                                            color: green,
                                                            '&.Mui-checked': {
                                                                color: green,
                                                            },
                                                        }}
                                                        checked={declarationDutyFreeEntry}
                                                        onChange={handleFormCheck}
                                                        disabled={+shipmentPurpose === +invoiceTypePersonal}
                                                    />
                                                }
                                                label="NAFTA - North American Free Trade Agreement"
                                            />
                                            <FormHelperText>
                                                If your shipment falls under the provisions of the North American Free
                                                Trade Agreement (NAFTA), be aware that it may qualify for certain
                                                exemptions from duties or taxes. Make sure to review NAFTA guidelines
                                                for eligibility and ensure compliance with <span> <Link
                                                href={FORM_B232}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                sx={{
                                                    color: green,
                                                    textDecoration: 'none',
                                                    '&:hover': {textDecoration: 'underline', color: green}
                                                }}> Canada</Link></span> and <span> <Link href={FORM_434}
                                                                                          target="_blank"
                                                                                          rel="noopener noreferrer"
                                                                                          sx={{
                                                                                              color: green,
                                                                                              textDecoration: 'none',
                                                                                              '&:hover': {
                                                                                                  textDecoration: 'underline',
                                                                                                  color: green
                                                                                              }
                                                                                          }}> US.</Link></span>
                                            </FormHelperText>
                                        </>
                                }
                            </FormControl>
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <Box sx={styles.ShippingPackageQuickQuoteLocationSection}>
                                <InputLabel required>
                                    Transportation Mode
                                </InputLabel>
                                <FormControl variant="outlined" fullWidth>
                                    <Select
                                        value={crossBorder?.tranMode}
                                        onChange={handleTranMode}
                                        size='small'
                                    >
                                        {
                                            transportationList.map(value => {
                                                return <MenuItem value={value.code}>{value?.name}</MenuItem>
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </Box>
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <Box sx={styles.ShippingPackageQuickQuoteLocationSection}>
                                <InputLabel required>
                                    Shipment Type
                                </InputLabel>
                                <FormControl variant="outlined" fullWidth>
                                    <Select
                                        value={crossBorder?.shipmentType}
                                        onChange={handleShipmentType}
                                        size='small'
                                    >
                                        {internationalShipmentTypeList.map(value => (
                                            <MenuItem
                                                key={value}
                                                value={value}
                                                disabled={
                                                    (+shipmentPurpose === +invoiceTypeCommercial && (value === "PERSONAL USE" || value === "DONATION" || value === "RELOCATION")) ||
                                                    (+shipmentPurpose === +invoiceTypePersonal && (value === "COMMERCIAL" || value === "SALE" || value === "REPAIR" || value === "SAMPLE" || value === "REPAIR" || value === "WARRANTY CLAIM" || value === "EXHIBITION"))
                                                }
                                            >
                                                {value}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>
                        </Grid>
                        {
                            selectRate?.accountBase?.toUpperCase() === 'CANADAPOST' &&
                            <Grid item md={4} xs={12}>
                                <FormControl variant="outlined" fullWidth>
                                    <FormLabel required>
                                        Non-delivery handling
                                    </FormLabel>
                                    <Select
                                        value={crossBorder?.option}
                                        onChange={handleShipmentOptions}
                                        size='small'
                                    >
                                        {nonDeliveryHandingListToCodeMap.map((each, index) => (
                                            <MenuItem
                                                key={index}
                                                value={each?.code}
                                            >
                                                {each?.value}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        }
                    </Grid>
                </Box>
            </Box>
            <Box style={styles.ShippingPackageCrossBorderRoot}>
                <Box sx={styles.ShippingPackageCreateLabelPackageSection}>
                    <Box sx={{
                        display: 'flex',
                        gap: '10px',
                    }}>
                        <CrossBorderIcon width={25} height={25}/>
                        <Typography style={styles.ShippingPackageCrossBorderHeading}>
                            Custom Declaration
                        </Typography>
                    </Box>
                    <Box sx={styles.ShippingPackageCreateLabelPackageSubSection}>
                        <Stack direction="row" spacing={1} alignItems="center">
                            <AntSwitch
                                checked={metric}
                                onChange={handleMetric}
                            />
                            <Typography>
                                Metric (kg)
                            </Typography>
                        </Stack>
                        <Stack direction="row" spacing={1} alignItems="center">
                            <IconButton
                                sx={{color: '#609966'}}
                                onClick={handleAddItem}
                            >
                                <img src={addPackage} width='35px'/>
                            </IconButton>
                            <Typography>
                                Add New Item
                            </Typography>
                        </Stack>
                    </Box>
                </Box>
                <Divider/>
                <Box sx={styles.ShippingPackageCreateLabelRateListContent}>
                    <Grid container spacing={2}>
                        {
                            (+shipmentPurpose === +invoiceTypeCommercial && declarationDutyFreeEntry) &&
                            <Grid item xs={12}>
                                <Typography>
                                    Under NAFTA, the country of origin must be the US, Canada, or Mexico.
                                </Typography>
                            </Grid>
                        }
                        {
                            selectRate?.accountBase?.toUpperCase() === 'CANADAPOST' &&
                            shipTo?.country?.toUpperCase() !== 'CA' &&
                            <Grid item xs={12}>
                                <Typography>
                                    Ensure that the Total Customs Weight does not exceed the weight of each package to
                                    avoid processing issues. Note that the minimum package weight
                                    is {(+minPackage?.weight)?.toFixed(2)} {minPackage?.weightUnit}.
                                    {
                                        packageCount(packageListRedux) > 1 &&
                                        <span> For cross-border shipments with Canada Post, it is recommended to include only one
                                        package per order to prevent customs delays. Please consider splitting your shipment
                                        into separate orders.
                                        </span>
                                    }
                                </Typography>
                            </Grid>
                        }
                        {
                            itemListRedux?.length > 0 ?
                                <>
                                    {
                                        itemListRedux?.map((eachItem, index) =>
                                            <Grid item xs={12} key={index}>
                                                <ShippingPackageCrossBorderItem
                                                    itemInfo={eachItem}
                                                    index={index}
                                                    metric={metric}
                                                    validation={validation}
                                                    errorList={errorList}
                                                    handleOpenItemDescription={handleOpenItemDescription}
                                                    declarationDutyFreeEntry={declarationDutyFreeEntry}
                                                    shipmentPurpose={shipmentPurpose}
                                                    // cbHandleOpen={handleOpen}
                                                    // cbHandleUpdatePackage={handleUpdatePackage}
                                                    // updatedPackage={updatedPackage}
                                                />
                                            </Grid>
                                        )
                                    }
                                    <Grid item xs={4}>
                                        <Typography sx={{fontWeight: '600'}}>
                                            Total Quantity: {totalQuantity}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography sx={{fontWeight: '600'}}>
                                            Total Weight: {totalWeight} {metric ? 'kg' : 'lb'}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography sx={{fontWeight: '600'}}>
                                            Total Value: ${(+totalValue)?.toFixed(2)}
                                        </Typography>
                                    </Grid>
                                </>
                                :
                                <Grid item xs={12}>
                                    <Box sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        border: '1px solid #B7B7B7',
                                        padding: '20px',
                                        borderRadius: '10px'
                                    }}>
                                        <Typography
                                            style={{
                                                fontSize: '20px',
                                                fontWeight: '600',
                                                color: '#7F8487',
                                                marginBottom: '10px'
                                            }}>
                                            Your item list is empty
                                        </Typography>
                                        <IconButton
                                            sx={{color: '#609966'}}
                                            onClick={handleAddItem}
                                        >
                                            <img src={addPackage} width='35px'/>
                                        </IconButton>
                                        <Typography style={{fontSize: '12px', fontWeight: '600', color: '#609966'}}>
                                            Add new item
                                        </Typography>
                                    </Box>
                                </Grid>
                        }
                    </Grid>
                </Box>
            </Box>
            <Box sx={{
                marginBottom: '40px'
            }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Box sx={{
                            display: 'flex',
                            width: '100%',
                            justifyContent: 'flex-end',
                            gap: '20px'
                        }}>
                            <Button
                                size='large'
                                variant='contained'
                                onClick={handleBack}
                                sx={{
                                    backgroundColor: '#F2BE22',
                                    "&:hover": {
                                        backgroundColor: '#F2BE22',
                                        filter: 'brightness(0.9)'
                                    }
                                }}
                            >
                                <Typography style={{textTransform: 'none'}}>
                                    Back
                                </Typography>
                            </Button>
                            <LoadingButton
                                size='large'
                                variant='contained'
                                sx={{
                                    backgroundColor: '#1D8B45',
                                    "&:hover": {
                                        backgroundColor: '#1D8B45',
                                        filter: 'brightness(0.9)'
                                    }
                                }}
                                loading={loading}
                                onClick={handleGetCrossBorderRate}
                            >
                                <Typography style={{textTransform: 'none'}}>
                                    Next
                                </Typography>
                            </LoadingButton>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <CrossBorderModal handleCloseItemDescription={handleCloseItemDescription}
                              openItemDescription={openItemDescription}/>
        </Container>
    )
}