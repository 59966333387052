// src/MovingServiceMapOverview.js
import React, { useState, useEffect } from 'react';
import GoogleMapReact from 'google-map-react';
import {Box} from "@material-ui/core";

const Marker = ({ text }) => <div>{text}</div>;

const MovingServiceMapOverview = ({ apiKey, shipFrom, shipTo }) => {
  const [mapApiLoaded, setMapApiLoaded] = useState(false);
  const [mapInstance, setMapInstance] = useState(null);
  const [mapApi, setMapApi] = useState(null);
  const [directions, setDirections] = useState(null);

  // useEffect(() => {
  //   // console.log("MovingServiceMapOverview shipFrom: ", shipFrom);
  //   console.log("MovingServiceMapOverview shipTo: ", shipTo);
  // }, [])

  const handleApiLoaded = (map, maps) => {
    setMapInstance(map);
    setMapApi(maps);
    setMapApiLoaded(true);
  };

  useEffect(() => {
    if (mapApiLoaded && mapInstance && mapApi) {
      const directionsService = new mapApi.DirectionsService();
      const directionsRenderer = new mapApi.DirectionsRenderer();

      directionsRenderer.setMap(mapInstance);

      directionsService.route(
          {
            origin: {lat: shipFrom?.latitude, lng: shipFrom?.longitude},
            destination: {lat: shipTo?.latitude, lng: shipTo?.longitude},
            travelMode: mapApi.TravelMode.DRIVING,
          },
          (result, status) => {
            if (status === mapApi.DirectionsStatus.OK) {
              directionsRenderer.setDirections(result);
              setDirections(result);
            } else {
              console.error(`error fetching directions ${result}`);
            }
          }
      );
    }
  }, [mapApiLoaded, mapInstance, mapApi, shipFrom, shipTo]);

  return (
      <Box sx={{height: "50vh", width: "100%"}}>
        <GoogleMapReact
            bootstrapURLKeys={{ key: apiKey, libraries: ['places', 'directions'] }}
            defaultCenter={{ lat: 0, lng: 0 }}
            defaultZoom={10}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
        >
          {directions && (
              <>
                <Marker
                    lat={directions.routes[0].legs[0].start_location.lat()}
                    lng={directions.routes[0].legs[0].start_location.lng()}
                    // text="Ship From"
                />
                <Marker
                    lat={directions.routes[0].legs[0].end_location.lat()}
                    lng={directions.routes[0].legs[0].end_location.lng()}
                    // text="Ship To"
                />
              </>
          )}
        </GoogleMapReact>
      </Box>
  );
};

export default MovingServiceMapOverview;