import {useLocation} from "react-router-dom";
import {Container} from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {greenButtonContained, mainHeading} from "../../Utils/styleConfig";
import * as React from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import {MarketplaceOrderList} from "./MarketplaceOrderList";
import {useEffect, useState} from "react";
import {NODE_ROUTE_URI, PARTNER_URI} from "../../Utils/apiUrl";
import axios from "axios";
import {getAccessToken} from "../../Utils/doToken";
import CircularProgress from "@material-ui/core/CircularProgress";
import {useDispatch, useSelector} from "react-redux";
import {updateECommerceProfileId} from "../../actions/eCommerceAction";

export const MarketplaceOrder = () => {

    const storedToken = getAccessToken("access_token");

    let storeId = useSelector(state => state?.eCommerce?.profileId);
    const dispatch = useDispatch();

    const {pathname} = useLocation();
    const startsWithBusiness = pathname.startsWith('/business');
    const [platform, setPlatform] = useState('shopify');
    const [storeList, setStoreList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isSyncOrder, setIsSyncOrder] = useState(false);

    const cbHandlePlatform = (data) => {
        setPlatform(data)
    }

    const syncOrder = () => {
        setIsSyncOrder(true);
    }

    const clearSyncOrder = () => {
        setIsSyncOrder(false);
    }

    const handleSyncOrderList = async () => {
        try {
            setLoading(true)
            let requestURL = startsWithBusiness ? `${PARTNER_URI}/marketplace/syncMarketplaceOrderList` : `${NODE_ROUTE_URI}/marketplace/syncMarketplaceOrderList`;
            const result = await axios({
                method: 'get',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${storedToken}`,
                },
                params: {
                    profileId: storeId
                }
            })
            console.log('[handleSyncOrderList] result', result);
        } catch (e) {
            console.log('[handleSyncOrderList] error', e?.response);
        } finally {
            setLoading(false);
            syncOrder();
        }
    }

    const getStoreList = async () => {
        try {
            setLoading(true)
            let requestURL = startsWithBusiness ? `${PARTNER_URI}/marketplace/getMarketplaceProfileList` : `${NODE_ROUTE_URI}/marketplace/getMarketplaceProfileList`;
            const result = await axios({
                method: 'get',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${storedToken}`,
                },
                params: {
                    marketplace: platform
                }
            })

            setStoreList(result?.data);
            dispatch(updateECommerceProfileId(result?.data?.[0]?.profile_id));
        } catch (e) {
            console.log('[getStoreList] error', e?.response);
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        (async () => {
            if (platform) {
                await getStoreList();
            }
        })()
    }, [platform]);

    return (
        <Container maxWidth='xl'>
            <Box sx={{display: 'flex', flexDirection: 'column', gap: '20px', marginBottom: '40px'}}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Typography sx={{fontSize: mainHeading, fontWeight: '600'}}>
                            Orders
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'flex-end'
                        }}>
                            <LoadingButton
                                variant='contained'
                                sx={greenButtonContained}
                                onClick={handleSyncOrderList}
                                disabled={!storeId}
                                loading={loading}
                            >
                                <Typography sx={{
                                    textTransform: 'none'
                                }}>
                                    Sync Order List
                                </Typography>
                            </LoadingButton>
                        </Box>
                    </Grid>
                </Grid>
                {
                    loading ?
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'center'
                        }}>
                            <CircularProgress/>
                        </Box>
                        :
                        <MarketplaceOrderList startsWithBusiness={startsWithBusiness}
                                              cbHandlePlatform={cbHandlePlatform}
                                              clearSyncOrder={clearSyncOrder}
                                              isSyncOrder={isSyncOrder}
                                              storeList={storeList}
                                              platform={platform}
                        />
                }
            </Box>
        </Container>
    )
}