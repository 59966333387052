import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {Divider} from "@mui/material";
import React, {Fragment} from "react";

export const WordPressQuoteRatingMoving = ({
                                               APIRate,
                                               AIRate,
                                               redirectToApp,
                                               movingFrom,
                                               movingTo,
                                               shippingDetails,
                                               lng
                                           }) => {

    if (!APIRate?.totalCharge || +APIRate?.totalCharge === 0) {
        return (
            <Fragment>
                <Grid item xs={12}>
                    <Typography>
                        {lng === 'cn' ? '根据您当前的选择，系统无法实时生成报价。' : "Based on the selections you've made, a quote cannot be provided immediately."}
                    </Typography>
                </Grid>
                {redirectToApp()}
            </Fragment>
        )
    }

    return (
        <Fragment>
            <Grid item md={6} xs={12}>
                <Typography>
                    {lng === 'cn' ? '始发地' : "Departure"}: {movingFrom?.city}, {movingFrom?.province}, {movingFrom?.postalCode}, {movingFrom?.country}
                </Typography>
            </Grid>
            <Grid item md={6} xs={12}>
                <Typography>
                    {lng === 'cn' ? '目的地' : "Destination"}: {movingTo?.city}, {movingTo?.province}, {movingTo?.postalCode}, {movingTo?.country}
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography sx={{fontSize: '16px', textAlign: 'left'}}>
                    {lng === 'cn' ? '小计' : "Subtotal"}
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography sx={{fontSize: '16px', textAlign: 'right'}}>
                    $ {(+APIRate?.totalCharge).toFixed(2)}
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography sx={{fontSize: '16px', textAlign: 'left'}}>
                    {lng === 'cn' ? '税费' : "Tax"}
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography sx={{fontSize: '16px', textAlign: 'right'}}>
                    $ {(+APIRate?.taxCharge || 0).toFixed(2)}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Divider/>
            </Grid>
            <Grid item xs={6}>
                <Typography sx={{fontSize: '16px', textAlign: 'left'}}>
                    {lng === 'cn' ? '总计' : "Total"}
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography sx={{fontSize: '16px', textAlign: 'right'}}>
                    $ {(+APIRate?.totalChargeWithTaxes)?.toFixed(2)}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography>
                    {lng === 'cn' ? '预估费用仅供参考。' : "The estimated cost is for reference only."}
                </Typography>
            </Grid>
            {
                redirectToApp()
            }
        </Fragment>
    )
}