import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import Grid from "@mui/material/Grid";
import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {updateMovingServiceSummaryContactInfo} from "../../actions/movingServiceAction";
import {isValidateEmail} from "../../Utils/Helper";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import intl from "react-intl-universal";
import CurrencyFormat from 'react-currency-format';
import {Divider} from "@material-ui/core";
import {ReactComponent as ContactIcon} from "../../images/NewDesignIcon/AddressBook.svg";
import {NODE_ROUTE_URI} from "../../Utils/apiUrl";
import axios from "axios";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Paper from "@mui/material/Paper";
import {makeStyles} from "@material-ui/core/styles";

const styles = {
    MovingServiceContactRoot: {
        backgroundColor: '#FFFFFF',
        borderRadius: '10px',
        // boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        gap: '10px',
        display: 'flex',
        flexDirection: 'column',
    },
    MovingServiceContactSection: {
        backgroundColor: '#FFFFFF',
        paddingBottom: '20px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        gap: '20px',
        display: 'flex',
        flexDirection: 'column',
    },
    MovingServiceContactHeading: {
        fontSize: '20px',
        fontWeight: '600'
    },
    MovingServiceContactErrorText: {
        fontSize: '12px',
        color: "#FF0303"
    }
}

const useStyles = makeStyles({
    smallInput: {
        "& .MuiInputBase-input": {
            height: "4px", // Adjust the height as per your preference
            padding: "2px", // Adjust the padding as per your preference
            fontSize: "16px", // Adjust the font size as per your preference
        }
    }
});

export const MovingServiceContact = ({validatedResult}) => {

    const dispatch = useDispatch();
    const classes = useStyles();

    let userInfo = useSelector(state => state?.user);
    let contactInfoReducer = useSelector(state => state?.movingService?.movingServiceSummaryContactInfo);
    let contactInfoMissing = useSelector(state => state?.movingService?.movingServiceSummaryContactInfoMissing);

    const [movingFromAddressPredictions, setMovingFromAddressPredictions] = useState([]);
    const [movingToAddressPredictions, setMovingToAddressPredictions] = useState([])
    const [contactInfo, setContactInfo] = useState({
        pickupAddress: {
            description: null,
            placeId: null,
            text: null
        },
        pickupAddress2: null,
        pickupName: '',
        pickupPhone: '',
        pickupEmail: '',
        deliverAddress: {
            description: null,
            placeId: null,
            text: null
        },
        deliverAddress2: null,
        deliverName: '',
        deliverPhone: '',
        deliverEmail: ''
    });

    const googleMapsPlaceAutocomplete = async (inputValue, type) => {
        let requestURL = `${NODE_ROUTE_URI}/maps_apis/placeAutocomplete`;

        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    input: inputValue,
                    types: type
                }
            })
            const {data: {data: {predictions}}} = result;
            console.log('prediction', predictions);
            const updatedPredictions = predictions?.map(each => {
                return {
                    description: each?.description,
                    text: each?.structured_formatting?.main_text,
                    placeId: each?.place_id
                }
            })
            console.log('updated prediction', updatedPredictions);

            return updatedPredictions;

        } catch (e) {
            console.log(e.response)
        }
    }

    const googleMapsPlaceDetails = async (placeId) => {
        let requestURL = `${NODE_ROUTE_URI}/maps_apis/placeDetails`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    placeId: placeId
                }
            });
            const place = result?.data?.data?.result;
            return place;

        } catch (e) {
            console.log(e.response)
        }
    }

    const handlePickupName = event => {
        setContactInfo(prevState => ({
            ...prevState,
            pickupName: event.target.value
        }))
    };

    const handlePickupPhone = event => {
        setContactInfo(prevState => ({
            ...prevState,
            pickupPhone: event.target.value
        }))
    };

    const handlePickupEmail = event => {
        setContactInfo(prevState => ({
            ...prevState,
            pickupEmail: event.target.value
        }))
    };

    const handleDeliverName = event => {
        setContactInfo(prevState => ({
            ...prevState,
            deliverName: event.target.value
        }))
    };

    const handleDeliverPhone = event => {
        setContactInfo(prevState => ({
            ...prevState,
            deliverPhone: event.target.value
        }))
    };

    const handleDeliverEmail = event => {
        setContactInfo(prevState => ({
            ...prevState,
            deliverEmail: event.target.value
        }))
    };

    const handleInputChangeMovingFromAddress = async (event, value) => {
        setContactInfo(prevState => ({
            ...prevState,
            pickupAddress: {
                ...prevState?.pickupAddress,
                description: '',
                placeId: '',
                text: value
            }
        }));
        const predictionsAddress = await googleMapsPlaceAutocomplete(value, 'address');
        setMovingFromAddressPredictions(predictionsAddress);
    }

    const handleInputChangeMovingToAddress = async (event, value) => {
        setContactInfo(prevState => ({
            ...prevState,
            deliverAddress: {
                ...prevState?.pickupAddress,
                description: '',
                placeId: '',
                text: value
            }
        }));
        const predictionsAddress = await googleMapsPlaceAutocomplete(value, 'address');
        setMovingToAddressPredictions(predictionsAddress);
    }

    const handleMovingFromApt = (event) => {
        setContactInfo(prevState => ({
            ...prevState, pickupAddress2: event.target.value
        }))
    }

    const handleMovingToApt = (event) => {
        setContactInfo(prevState => ({
            ...prevState, deliverAddress2: event.target.value
        }))
    }

    useEffect(() => {

        setContactInfo(prevState => ({
            ...prevState,
            pickupAddress: contactInfoReducer?.pickupAddress,
            pickupAddress2: contactInfoReducer?.pickupAddress2,
            pickupName: contactInfoReducer?.pickupName || userInfo?.legalName,
            pickupPhone: contactInfoReducer?.pickupPhone || userInfo?.phone?.replace(/\D/g, ""),
            pickupEmail: contactInfoReducer?.pickupEmail || userInfo?.email,
            deliverAddress: contactInfoReducer?.deliverAddress,
            deliverAddress2: contactInfoReducer?.deliverAddress2,
            deliverName: contactInfoReducer?.deliverName || userInfo?.legalName,
            deliverPhone: contactInfoReducer?.deliverPhone || userInfo?.phone?.replace(/\D/g, ""),
            deliverEmail: contactInfoReducer?.deliverEmail || userInfo?.email
        }))
    }, []);

    useEffect(() => {
        dispatch(updateMovingServiceSummaryContactInfo(contactInfo));
    }, [contactInfo])

    // console.log(userInfo);

    console.log('[MovingServiceContact] contactInfo', contactInfo);
    console.log('[MovingServiceContact] contactInfo reducer', contactInfoReducer);

    return (
        <Box sx={styles.MovingServiceContactRoot}>
            <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography sx={{fontWeight: '600'}}>
                                Moving From Information
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <FormControl fullWidth>
                                <FormLabel required>
                                    {intl.get('MOVING.STREET_ADDRESS')}
                                </FormLabel>
                                <Autocomplete
                                    freeSolo
                                    value={contactInfo?.pickupAddress}
                                    fullWidth
                                    options={movingFromAddressPredictions}
                                    filterOptions={(options, state) => options}
                                    getOptionLabel={option => option?.text}
                                    onInputChange={handleInputChangeMovingFromAddress}
                                    // onChange={handleChangeMovingFromAddress}
                                    PaperComponent={props => <Paper {...props}
                                                                    sx={{width: 400}}/>}
                                    renderInput={params => <TextField
                                        {...params}
                                        className={classes.smallInput}
                                        variant="outlined"
                                        error={validatedResult !== null && !contactInfo?.pickupAddress?.text}
                                        helperText={(validatedResult !== null && !contactInfo?.pickupAddress?.text) && 'Required'}
                                    />}
                                    renderOption={option => (
                                        <Typography style={{fontSize: '12px'}}>
                                            {option.description}
                                        </Typography>
                                    )}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <FormControl fullWidth>
                                <FormLabel>
                                    {intl.get('MOVING.APT')}
                                </FormLabel>
                                <TextField
                                    value={contactInfo.pickupAddress2}
                                    onInput={handleMovingFromApt}
                                    fullWidth
                                    variant="outlined"
                                    size='small'
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <FormControl fullWidth>
                                <FormLabel required>
                                    {intl.get('CONTACT.NAME')}
                                </FormLabel>
                                <TextField
                                    value={contactInfo?.pickupName}
                                    onInput={handlePickupName}
                                    fullWidth
                                    variant="outlined"
                                    size='small'
                                    error={validatedResult !== null && !contactInfo?.pickupName}
                                    helperText={(validatedResult !== null && !contactInfo?.pickupName) && 'Required'}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <FormControl fullWidth>
                                <FormLabel required>
                                    {intl.get('CONTACT.PHONE')}
                                </FormLabel>
                                <CurrencyFormat
                                    value={contactInfo?.pickupPhone}
                                    customInput={TextField}
                                    onInput={handlePickupPhone}
                                    format="+1 (###) ###-####"
                                    mask="_"
                                    fullWidth
                                    variant="outlined"
                                    size='small'
                                    error={validatedResult !== null && !contactInfo?.pickupPhone}
                                    helperText={(validatedResult !== null && !contactInfo?.pickupPhone) && 'Required'}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={12} xs={12}>
                            <FormControl fullWidth>
                                <FormLabel required>
                                    {intl.get('CONTACT.EMAIL')}
                                </FormLabel>
                                <TextField
                                    value={contactInfo?.pickupEmail}
                                    onInput={handlePickupEmail}
                                    fullWidth
                                    variant="outlined"
                                    size='small'
                                    error={validatedResult !== null && !contactInfo?.pickupEmail}
                                    helperText={(validatedResult !== null && !contactInfo?.pickupEmail) && 'Required'}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={6} xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography sx={{fontWeight: '600'}}>
                                Moving From Information
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <FormControl fullWidth>
                                <FormLabel required>
                                    {intl.get('MOVING.STREET_ADDRESS')}
                                </FormLabel>
                                <Autocomplete
                                    freeSolo
                                    value={contactInfo?.deliverAddress}
                                    fullWidth
                                    options={movingToAddressPredictions}
                                    filterOptions={(options, state) => options}
                                    getOptionLabel={option => option?.text}
                                    onInputChange={handleInputChangeMovingToAddress}
                                    // onChange={handleChangeMovingFromAddress}
                                    PaperComponent={props => <Paper {...props}
                                                                    sx={{width: 400}}/>}
                                    renderInput={params => <TextField
                                        {...params}
                                        className={classes.smallInput}
                                        variant="outlined"
                                        error={validatedResult !== null && !contactInfo?.deliverAddress?.text}
                                        helperText={(validatedResult !== null && !contactInfo?.deliverAddress?.text) && 'Required'}
                                    />}
                                    renderOption={option => (
                                        <Typography style={{fontSize: '12px'}}>
                                            {option.description}
                                        </Typography>
                                    )}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <FormControl fullWidth>
                                <FormLabel>
                                    {intl.get('MOVING.APT')}
                                </FormLabel>
                                <TextField
                                    value={contactInfo.deliverAddress2}
                                    onInput={handleMovingToApt}
                                    fullWidth
                                    variant="outlined"
                                    size='small'
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <FormLabel required>
                                {intl.get('CONTACT.NAME')}
                            </FormLabel>
                            <TextField
                                value={contactInfo?.deliverName}
                                onInput={handleDeliverName}
                                fullWidth
                                variant="outlined"
                                size='small'
                                error={validatedResult !== null && !contactInfo?.deliverName}
                                helperText={(validatedResult !== null && !contactInfo?.deliverName) && 'Required'}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <FormLabel required>
                                {intl.get('CONTACT.PHONE')}
                            </FormLabel>
                            <CurrencyFormat
                                value={contactInfo?.deliverPhone}
                                customInput={TextField}
                                onInput={handleDeliverPhone}
                                format="+1 (###) ###-####"
                                mask="_"
                                fullWidth
                                variant="outlined"
                                size='small'
                                error={validatedResult !== null && !contactInfo?.deliverPhone}
                                helperText={(validatedResult !== null && !contactInfo?.deliverPhone) && 'Required'}
                            />
                        </Grid>
                        <Grid item md={12} xs={12}>
                            <FormLabel required>
                                {intl.get('CONTACT.EMAIL')}
                            </FormLabel>
                            <TextField
                                value={contactInfo?.deliverEmail}
                                onInput={handleDeliverEmail}
                                fullWidth
                                variant="outlined"
                                size='small'
                                error={validatedResult !== null && !contactInfo?.deliverEmail}
                                helperText={(validatedResult !== null && !contactInfo?.deliverEmail) && 'Required'}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    )
}