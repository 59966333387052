import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {greenButtonContained, mainHeading} from "../../Utils/styleConfig";
import * as React from "react";
import {Button, Container} from "@mui/material";
import Box from "@mui/material/Box";
import {useState} from "react";
import {MarketplaceConnectStore} from "./MarketplaceConnectStore";
import {MarketplaceStoreList} from "./MarketplaceStoreList";
import {useHistory, useLocation} from "react-router-dom";

export const MarketplaceStore = () => {

    const history = useHistory();
    const {pathname} = useLocation();
    const startsWithBusiness = pathname.startsWith('/business');

    const [connectStore, setConnectStore] = useState(false);

    const handleConnectStore = () => {
        setConnectStore(true)
    }

    const handleOrderList = () => {
        history?.push(`${startsWithBusiness ? '/business/' : '/'}e-commerce/order`);
    }

    const handleCloseConnectStore = () => {
        setConnectStore(false)
    }

    return (
        <Container maxWidth='xl'>
            <Box sx={{display: 'flex', flexDirection: 'column', gap: '20px', marginBottom: '40px'}}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Typography sx={{fontSize: mainHeading, fontWeight: '600'}}>
                            Stores
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            gap: '10px'
                        }}>
                            {
                                connectStore ?
                                    <Button
                                        variant='contained'
                                        sx={greenButtonContained}
                                        onClick={handleCloseConnectStore}
                                    >
                                        <Typography sx={{
                                            textTransform: 'none'
                                        }}>
                                            Back to store list
                                        </Typography>
                                    </Button>
                                    :
                                    <>
                                        <Button
                                            variant='contained'
                                            sx={greenButtonContained}
                                            onClick={handleOrderList}
                                        >
                                            <Typography sx={{
                                                textTransform: 'none'
                                            }}>
                                                E-Commerce Order List
                                            </Typography>
                                        </Button>
                                        <Button
                                            variant='contained'
                                            sx={greenButtonContained}
                                            onClick={handleConnectStore}
                                        >
                                            <Typography sx={{
                                                textTransform: 'none'
                                            }}>
                                                Connect Store
                                            </Typography>
                                        </Button>
                                    </>
                            }
                        </Box>
                    </Grid>
                </Grid>
                {
                    connectStore ?
                        <MarketplaceConnectStore/>
                        :
                        <MarketplaceStoreList startsWithBusiness={startsWithBusiness}/>
                }
            </Box>
        </Container>
    )
}