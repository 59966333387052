import Grid from "@material-ui/core/Grid";
import Typography from "@mui/material/Typography";
import intl from "react-intl-universal";
import {Checkbox, FormControlLabel, Link} from "@mui/material";
import {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import {useDispatch, useSelector} from "react-redux";
import {
    updateShippingPackageQuickQuoteBatteryDetails, updateShippingPackageQuickQuoteBatteryOptions,
    updateShippingPackageQuickQuoteSignatureOptions
} from "../../../actions/BusinessShippingPackageAction";
import {useMediaQuery} from "@material-ui/core";
import * as React from "react";

const styles = {
    ShippingPackageQuickQuoteLocationTitle: {
        fontSize: '20px',
        fontWeight: '600',
        textAlign: 'left'
    },
    ShippingPackageQuickQuoteLocationHeading: {
        fontSize: '20px',
        textAlign: 'left'
    },
    ShippingPackageQuickQuoteLocationSection: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start'
    },
    ShippingPackageQuickQuoteLocationBox: {
        padding: '20px',
        // border: '1px solid #000000',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
    },
    ShippingPackageQuickQuoteLocationErrorText: {
        fontSize: '12px',
        color: "#FF0303"
    },
    ShippingPackageQuickQuoteLocationRow: {
        display: 'flex',
        alignItems: 'center'
        // justifyContent: 'flex-end',
    }
}

export const BusinessShippingPackageQuickQuoteShippingOptions = ({isGetRate}) => {

    const dispatch = useDispatch();
    const isMobile = useMediaQuery('(max-width: 480px)');

    // let batteryDetailsRedux = useSelector(state => state?.businessShippingPackage?.quickQuoteBatteryDetails);

    let shipFrom = useSelector(state => state?.businessShippingPackage?.quickQuoteShipFrom);
    let shipTo = useSelector(state => state?.businessShippingPackage?.quickQuoteShipTo);

    const [isBattery, setIsBattery] = useState(false);
    const [batteryDetails, setBatteryDetails] = useState({
        material: null,
        packing: null
    })
    const [isSignatureRequired, setIsSignatureRequired] = useState(false);
    const [signatureRequired, setSignatureRequired] = useState(null);
    const [isShippingUS, setIsShippingUS] = useState(false);
    const [isUN3481, setIsUN3481] = useState(true);

    const handleIsBattery = event => {
        setIsBattery(event?.target?.checked);
        if (!event?.target?.checked) {
            setBatteryDetails(prevState => ({
                ...prevState,
                material: null,
                packing: null
            }))
            setIsUN3481(true);
        }
    }

    const handleSignatureRequired = event => {
        setIsSignatureRequired(event?.target?.checked);
        if (event?.target?.checked) {
            setSignatureRequired('DEFAULT')
        } else {
            setSignatureRequired(null)
        }
    }

    const handleBatteryType = (type) => {
        setBatteryDetails(prevState => ({
            ...prevState,
            material: type
        }))
    }

    const handleBatteryPackaged = (packaged) => {
        setBatteryDetails(prevState => ({
            ...prevState,
            packing: packaged
        }))
    }

    const handleUN3481 = (value) => {
        setIsUN3481(value);
        if (!value) {
            setBatteryDetails(prevState => ({
                ...prevState,
                material: 'LITHIUM_ION',
                packing: 'CONTAINED_IN_EQUIPMENT'
            }))
        } else {
            setBatteryDetails(prevState => ({
                ...prevState,
                material: null,
                packing: null
            }))
        }
    }

    useEffect(() => {
        dispatch(updateShippingPackageQuickQuoteBatteryDetails(batteryDetails));
    }, [batteryDetails]);

    useEffect(() => {
        dispatch(updateShippingPackageQuickQuoteBatteryOptions(isBattery));
    }, [isBattery])

    useEffect(() => {
        dispatch(updateShippingPackageQuickQuoteSignatureOptions(signatureRequired));
    }, [signatureRequired]);

    useEffect(() => {
        if (shipFrom?.country && shipTo?.country) {
            if (shipFrom?.country?.toUpperCase() !== 'CA' || shipTo?.country?.toUpperCase() !== 'CA') {
                setIsShippingUS(true);
                setIsBattery(false);
                setIsSignatureRequired(false);
                setSignatureRequired(null);
                setBatteryDetails(prevState => ({
                    ...prevState,
                    material: null,
                    packing: null
                }))
            } else {
                setIsShippingUS(false);
            }
        }
    }, [shipFrom, shipTo]);

    console.log('[BusinessShippingPackageQuickQuoteShippingOptions] shipFrom', shipFrom);
    console.log('[BusinessShippingPackageQuickQuoteShippingOptions] IsShippingUS', isShippingUS);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography style={styles.ShippingPackageQuickQuoteLocationTitle}>
                    {intl.get('PACKAGE_SHIPPING_OPTIONS.SHIPPING_OPTIONS')}
                </Typography>
            </Grid>
            {
                isShippingUS &&
                <Grid item xs={12}>
                    <Typography style={{textAlign: 'left'}}>
                        The shipping option is exclusively for Canadian domestic shipping services.
                    </Typography>
                </Grid>
            }
            <Grid item xs={12}>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    flexDirection: 'column',
                }}>
                    <FormControlLabel
                        value="start"
                        control={<Checkbox
                            checked={isSignatureRequired}
                            onChange={handleSignatureRequired}
                            color="success"
                            disabled={isGetRate || isShippingUS}
                        />}
                        label={
                            <Typography style={{textAlign: 'left'}}>
                                {intl.get('PACKAGE_SHIPPING_OPTIONS.SIGNATURE_OPTIONS')}
                            </Typography>
                        }
                        labelPlacement="end"
                    />
                    <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                        {intl.get('PACKAGE_SHIPPING_OPTIONS.SIGNATURE_OPTIONS_TEXT')}
                    </Typography>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    flexDirection: 'column',
                }}>
                    <FormControlLabel
                        value="start"
                        control={<Checkbox
                            checked={isBattery}
                            onChange={handleIsBattery}
                            color="success"
                            disabled={isGetRate || isShippingUS}
                        />}
                        label={
                            <Typography style={{textAlign: 'left'}}>
                                {intl.get('PACKAGE_SHIPPING_OPTIONS.INCLUDE_BATTERY')}
                            </Typography>
                        }
                        labelPlacement="end"
                    />
                </Box>
            </Grid>
            {
                isBattery &&
                <Grid item xs={12}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                        flexDirection: 'column',
                    }}>
                        <Box sx={{display: 'flex', gap: '5px'}}>
                            <Typography>
                                {intl.get('PACKAGE_SHIPPING_OPTIONS.UN3481')}
                            </Typography>
                            <Typography sx={{
                                textAlign: 'left',
                                fontWeight: '600',
                            }}>
                                <Link
                                    href='https://www.iata.org/contentassets/05e6d8742b0047259bf3a700bc9d42b9/lithium-battery-guidance-document.pdf'
                                    underline='none'
                                    target='_blank'
                                    color='#1D8B45'
                                >
                                    {intl.get('PACKAGE_SHIPPING_OPTIONS.MORE')}
                                </Link>
                            </Typography>
                        </Box>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            gap: '10px',
                            alignItems: 'flex-start'
                        }}>
                            <FormControlLabel
                                value="start"
                                control={<Checkbox
                                    checked={isUN3481}
                                    onChange={() => handleUN3481(true)}
                                    color="success"
                                    disabled={isGetRate}
                                    icon={<RadioButtonUncheckedIcon sx={{width: '20px'}}/>}
                                    checkedIcon={<CheckCircleIcon sx={{width: '20px'}}/>}
                                />}
                                label={
                                    <Typography>
                                        Yes
                                    </Typography>
                                }
                                labelPlacement="end"
                            />
                            <FormControlLabel
                                value="start"
                                control={<Checkbox
                                    checked={!isUN3481}
                                    onChange={() => handleUN3481(false)}
                                    disabled={isGetRate || isShippingUS}
                                    color="success"
                                    icon={<RadioButtonUncheckedIcon sx={{width: '20px'}}/>}
                                    checkedIcon={<CheckCircleIcon sx={{width: '20px'}}/>}
                                />}
                                label={
                                    <Typography>
                                        No
                                    </Typography>
                                }
                                labelPlacement="end"
                            />
                        </Box>
                    </Box>
                </Grid>
            }
            {
                (isBattery && !isUN3481) &&
                <>
                    <Grid item xs={12}>
                        <Box sx={{display: 'flex', gap: '5px'}}>
                            <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                                {intl.get('PACKAGE_SHIPPING_OPTIONS.BATTERY_TEXT')}
                            </Typography>
                            <Typography style={{
                                fontSize: '14px',
                                textAlign: 'left',
                                fontWeight: '600',
                            }}>
                                <Link
                                    href='https://uucargo.ca/support/shipment-of-items-containing-lithium-batteries-with-uucargo/'
                                    underline='none'
                                    target='_blank'
                                    color='#1D8B45'
                                >
                                    {intl.get('PACKAGE_SHIPPING_OPTIONS.MORE')}
                                </Link>
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'flex-start',
                            flexDirection: 'column',
                        }}>
                            <Typography>
                                {intl.get('PACKAGE_SHIPPING_OPTIONS.BATTERY_TYPE')}
                            </Typography>
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                gap: '10px',
                                flexDirection: isMobile && 'column',
                                alignItems: 'flex-start'
                            }}>
                                <FormControlLabel
                                    value="start"
                                    control={<Checkbox
                                        checked={batteryDetails?.material === 'LITHIUM_ION'}
                                        onChange={() => handleBatteryType('LITHIUM_ION')}
                                        color="success"
                                        icon={<RadioButtonUncheckedIcon sx={{width: '20px'}}/>}
                                        checkedIcon={<CheckCircleIcon sx={{width: '20px'}}/>}
                                        disabled={isGetRate}
                                    />}
                                    label={
                                        <Typography>
                                            {intl.get('PACKAGE_SHIPPING_OPTIONS.LITHIUM_ION')}
                                        </Typography>
                                    }
                                    labelPlacement="end"
                                />
                                <FormControlLabel
                                    value="start"
                                    control={<Checkbox
                                        checked={batteryDetails?.material === 'LITHIUM_METAL'}
                                        onChange={() => handleBatteryType('LITHIUM_METAL')}
                                        color="success"
                                        icon={<RadioButtonUncheckedIcon sx={{width: '20px'}}/>}
                                        checkedIcon={<CheckCircleIcon sx={{width: '20px'}}/>}
                                        disabled={isGetRate}
                                    />}
                                    label={
                                        <Typography>
                                            {intl.get('PACKAGE_SHIPPING_OPTIONS.LITHIUM_METAL')}
                                        </Typography>
                                    }
                                    labelPlacement="end"
                                />
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'flex-start',
                            flexDirection: 'column',
                        }}>
                            <Typography>
                                {intl.get('PACKAGE_SHIPPING_OPTIONS.PACKING_TYPE')}
                            </Typography>
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                gap: '10px',
                                flexDirection: isMobile && 'column',
                                alignItems: 'flex-start'
                            }}>
                                <FormControlLabel
                                    value="start"
                                    control={<Checkbox
                                        checked={batteryDetails?.packing === 'CONTAINED_IN_EQUIPMENT'}
                                        onChange={() => handleBatteryPackaged('CONTAINED_IN_EQUIPMENT')}
                                        color="success"
                                        icon={<RadioButtonUncheckedIcon sx={{width: '20px'}}/>}
                                        checkedIcon={<CheckCircleIcon sx={{width: '20px'}}/>}
                                        disabled={isGetRate}
                                    />}
                                    label={
                                        <Typography style={{textAlign: 'left'}}>
                                            {intl.get('PACKAGE_SHIPPING_OPTIONS.CONTAINED_IN_EQUIPMENT')}
                                        </Typography>
                                    }
                                    labelPlacement="end"
                                />
                                <FormControlLabel
                                    value="start"
                                    control={<Checkbox
                                        checked={batteryDetails?.packing === 'PACKED_WITH_EQUIPMENT'}
                                        onChange={() => handleBatteryPackaged('PACKED_WITH_EQUIPMENT')}
                                        color="success"
                                        icon={<RadioButtonUncheckedIcon sx={{width: '20px'}}/>}
                                        checkedIcon={<CheckCircleIcon sx={{width: '20px'}}/>}
                                        disabled={isGetRate}
                                    />}
                                    label={
                                        <Typography style={{textAlign: 'left'}}>
                                            {intl.get('PACKAGE_SHIPPING_OPTIONS.PACKED_WITH_EQUIPMENT')}
                                        </Typography>
                                    }
                                    labelPlacement="end"
                                />
                            </Box>
                        </Box>
                    </Grid>
                </>
            }
        </Grid>
    )
}
