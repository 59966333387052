import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import dayjs from "dayjs";
import React, {Fragment} from "react";
import {Divider} from "@mui/material";
import MovingServiceMapOverview from "../Moving/MovingServiceMapOverview";

const style = {
    OrderDashboard: {
        border: '1px solid #B2B2B2',
        borderRadius: '10px',
        padding: '10px 0',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
    }
}

export const MovingVehicleOrderDashboardTransportation = ({orderData}) => {

    return (
        <Box sx={style.OrderDashboard}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        padding: '10px 20px',
                        borderBottom: '1px solid #B2B2B2'
                    }}>
                        <Typography sx={{fontSize: '16px', fontWeight: '600'}}>
                            Transportation Details
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{padding: '20px'}}>
                        <Grid container spacing={1}>
                            <Grid item md={6} xs={12}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12}>
                                                <Typography sx={{fontWeight: '600', textAlign: 'left'}}>
                                                    Moving From
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Box sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column'
                                                }}>
                                                    <Typography sx={{textAlign: 'left'}}>
                                                        {orderData?.pickup_addr_type?.toUpperCase() === 'SCHEDULE' ? 'Scheduled Pickup' : 'Self Drop-Off'}
                                                    </Typography>
                                                    <Typography sx={{textAlign: 'left'}}>
                                                        {orderData?.pickup_address}
                                                    </Typography>
                                                    <Typography sx={{textAlign: 'left'}}>
                                                        {orderData?.pickup_area}
                                                    </Typography>
                                                    <Typography sx={{textAlign: 'left'}}>
                                                        {orderData?.pickup_postal_code}, {orderData?.send_from_country}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12}>
                                                <Typography sx={{fontWeight: '600', textAlign: 'left'}}>
                                                    Moving To
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Box sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column'
                                                }}>
                                                    <Typography sx={{textAlign: 'left'}}>
                                                        {orderData?.deliver_addr_type?.toUpperCase() === 'SCHEDULE' ? 'Scheduled Deliver' : 'Self Pickup'}
                                                    </Typography>
                                                    <Typography sx={{textAlign: 'left'}}>
                                                        {orderData?.deliver_address}
                                                    </Typography>
                                                    <Typography sx={{textAlign: 'left'}}>
                                                        {orderData?.deliver_area}
                                                    </Typography>
                                                    <Typography sx={{textAlign: 'left'}}>
                                                        {orderData?.deliver_postal_code}, {orderData?.send_to_country}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Typography sx={{fontWeight: '600', textAlign: 'left'}}>
                                            Map Overview
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <MovingServiceMapOverview
                                            apiKey={"AIzaSyDxbjdueUQWI1WFfiEviIkvspt8S95L-bQ"}
                                            shipFrom={`${orderData?.pickup_address}, ${orderData?.pickup_area}, ${orderData?.send_from_country}, ${orderData?.pickup_postal_code}`}
                                            shipTo={`${orderData?.deliver_address}, ${orderData?.deliver_area}, ${orderData?.send_to_country}, ${orderData?.deliver_postal_code}`}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Typography sx={{fontWeight: '600', textAlign: 'left'}}>
                                            Vehicle List
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography sx={{textAlign: 'left'}}>
                                            Make
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography sx={{textAlign: 'left'}}>
                                            Model
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Typography sx={{textAlign: 'center'}}>
                                            Year
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Typography sx={{textAlign: 'center'}}>
                                            Quantity
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider/>
                                    </Grid>
                                    {
                                        orderData?.backendPackages?.map((vehicle, index) => (
                                            <Fragment key={index}>
                                                <Grid item xs={4}>
                                                    <Typography sx={{textAlign: 'left'}}>
                                                        {vehicle?.make}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Typography sx={{textAlign: 'left'}}>
                                                        {vehicle?.model}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <Typography sx={{textAlign: 'center'}}>
                                                        {vehicle?.max_year_of_production}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <Typography sx={{textAlign: 'center'}}>
                                                        {vehicle?.number}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Divider/>
                                                </Grid>
                                            </Fragment>
                                        ))
                                    }
                                </Grid>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Typography sx={{fontWeight: '600', textAlign: 'left'}}>
                                            Moving Date and Time
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box sx={{
                                            display: 'flex',
                                            flexDirection: 'column'
                                        }}>
                                            <Typography sx={{textAlign: 'left'}}>
                                                Moving
                                                Date: {dayjs(orderData?.scheduled_pickup_date)?.format('MMM DD, YYYY')}
                                            </Typography>
                                            <Typography sx={{textAlign: 'left'}}>
                                                Moving Time: {dayjs(orderData?.scheduled_pickup_date)?.format('h:mm a')}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}