import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {makeStyles} from "@material-ui/core/styles";
import axios from "axios";
import {Box, Button, Card, Container} from "@material-ui/core";
import Typography from "@mui/material/Typography";
import {Divider, useMediaQuery} from "@mui/material";
import {ReactComponent as QuickQuoteLogo} from "../../../images/homepageIcon/QuickQuote.svg";
import {ReactComponent as CreateShipmentLogo} from "../../../images/homepageIcon/CreateShipment.svg";
import {ReactComponent as ECommerceLogo} from "../../../images/homepageIcon/ECommerce.svg";
import {ReactComponent as ImportShipmentLogo} from "../../../images/homepageIcon/ImportShipments.svg";
import {ReactComponent as SchedulePickupLogo} from "../../../images/homepageIcon/SchedulePickup.svg";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import intl from "react-intl-universal";
import Modal from "@mui/material/Modal";
import {getAccessToken} from "../../../Utils/doToken";
import {GET_PARTNER_INFO_API, PARTNER_URI} from "../../../Utils/apiUrl";
import {getWelcome} from "../../../Utils/Helper";
import {Welcome} from "../../Welcome/Business/Welcome";
import {ShippingOrder} from "../../ShippingOrderList/Business/ShippingOrder";
import {BusinessLandingScheduledPickup} from "../../SchedulePickup/Business/BusinessLandingScheduledPickup";
import {Tracking} from "../../Tracking/Business/Tracking";
import {BusinessLandingAdjustOrder} from "../../AdjustOrderDashboard/BusinessLandingAdjustOrder";

const styles = {
    landingPageCard: {
        display: 'flex',
        gap: '15px',
        flexWrap: 'wrap',
        justifyContent: 'flex-start',

    },
    landingPageCardEach: {
        display: "flex",
        justifyContent: "center",
        gap: '20px',
        width: "100%",
        height: '100%',
        alignItems: "center",
    },
    landingPageCardEachText: {
        '@media (max-width: 480px)': {
            fontSize: '14px'
        }
    },
    landingPageLatestShippingOrder: {
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap'
    },
    landingPageLatestShippingOrderText: {
        border: '1px solid #000000',
        fontSize: '14px',
        padding: '0 5px'
    },
    landingPageLatestShippingOrderTableText: {
        fontSize: '14px',
        fontWeight: '600'
    },
}

const BusinessLandingPage = () => {

    const dispatch = useDispatch();

    const isMobile = useMediaQuery('(max-width: 480px)');

    const {firstname, id, isLoading} = useSelector((state) => state.user);
    const history = useHistory();
    const classes = useStyles();
    const ORDER_NUMBER = 10;

    const [partnerInfo, setPartnerInfo] = useState(null);

    const [gettingPartner, setGettingPartnerStatus] = useState(true);

    const [partnerData, setPartnerData] = useState(null);

    const [message, setMessage] = useState("");

    const [shippingOrderData, setShippingOrderData] = useState([]);
    const [invoiceData, setInvoiceData] = useState([]);
    const [bolOrderData, setBolOrderData] = useState([]);
    const [palletRequestData, setPalletRequestData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [directURL, setDirectURL] = useState("/business/billing-center");
    const [availableCredit, setAvailableCredit] = useState(0);

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    console.log('[BusinessLandingPage] partner info', partnerInfo);

    useEffect(() => {
            if (id) {
                const storedToken = getAccessToken("access_token");
                axios({
                    method: "get",
                    url: GET_PARTNER_INFO_API,
                    headers: {
                        Authorization: `Bearer ${storedToken}`,
                    },
                })
                    .then((res) => {
                        setGettingPartnerStatus(false);
                        if (res.data) {
                            setPartnerData(res.data);
                        } else {
                            setMessage(
                                "Please contact us to access the buy shipping page \n Phone: 1-604-998-1888, email: info@uucargo.ca"
                            );
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        setMessage("Error when accessing the page");
                    });

                // Load Latest Order Item
                (async () => {
                    try {
                        const {data} = await axios.get(
                            `${PARTNER_URI}/shipping/list/order?partner_id=${id}&limit=${ORDER_NUMBER}`
                        );
                        setShippingOrderData(data.result);
                        console.log(data);
                    } catch (e) {
                        console.log(e);
                    }
                })();

                // Get Latest Invoice List
                (async () => {
                    try {
                        const {data} = await axios.get(`${PARTNER_URI}/invoices/getInvoicesList?partnerId=${id}&page=0&pageSize=5`);
                        const result = data.data.filter((v, index) => index < 5);
                        console.log("Invoices: ", result);
                        setInvoiceData(result);
                    } catch (e) {
                        console.log(e);
                    }
                })();

                (async () => {
                        setLoading(true);
                        try {
                            const result = await axios({
                                method: 'get',
                                url: GET_PARTNER_INFO_API,
                                headers: {
                                    Authorization: `Bearer ${storedToken}`,
                                }
                            });
                            console.log('partner info result', result);
                            const {data: {overdraft_limit, current_bill_balance}} = result;
                            setPartnerInfo(result?.data);
                            // console.log('limit', overdraft_limit);
                            // console.log('balance', current_bill_balance);
                            const credit = overdraft_limit + current_bill_balance
                            setAvailableCredit(credit);
                            setLoading(false);
                        } catch (e) {
                            console.log(e);
                            setLoading(false);
                            return false;
                        }
                    }
                )();

                (async () => {
                    try {
                        const {data} = await axios.get(`${PARTNER_URI}/partner/bol/getLatestBolList?partnerId=${id}&limit=${ORDER_NUMBER}`);
                        setBolOrderData(data.result);
                        console.log(data);
                    } catch (e) {
                        console.log(e);
                    }
                })();

                // Pallets API
                (async () => {
                    try {
                        const {data} = await axios.post(`${PARTNER_URI}/partnerPallets/getMyShippingQuoteRequestList`, {
                            partnerId: id,
                            requestStatusArray: [0, 1],
                            page: 0,
                            pageSize: 5
                        });
                        setPalletRequestData(data.results);
                    } catch (e) {
                        console.log(e);
                    }
                })();

                // (async () => {
                //         const storedToken = getAccessToken("access_token");
                //         try {
                //             const result = await axios({
                //                 method: 'get',
                //                 url: GET_PARTNER_INFO_API,
                //                 headers: {
                //                     Authorization: `Bearer ${storedToken}`,
                //                 }
                //             });
                //             setPartnerInfo(result?.data);
                //         } catch (e) {
                //             console.log(e);
                //         }
                //     }
                // )();

            } else if (!isLoading && !id && id !== 0) {
                setMessage("Can't get user information");
                history.push("/");
            }
        }
        ,
        [id, isLoading]
    );

    useEffect(() => {
        const isWelcome = getWelcome();
        setOpen(isWelcome);
    }, [])

    return (
        <Container maxWidth='xl'>
            <Box sx={{
                display: 'flex',
                gap: '10px',
                justifyContent: 'flex-start',
                marginBottom: '30px'
            }}>
                <Divider orientation="vertical" flexItem sx={{backgroundColor: '#1D8B45', width: '5px'}}/>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start'
                }}>
                    <Typography style={{
                        fontSize: '20px',
                        fontWeight: '600',
                    }}>
                        {intl.get('HOME_PAGE.WELCOME', {name: firstname})}
                    </Typography>
                    <Typography style={{
                        fontSize: '14px',
                        // fontWeight: '600'
                    }}>
                        {intl.get('HOME_PAGE.SLOGAN')}
                    </Typography>
                </Box>
            </Box>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '10px',
                alignItems: 'flex-start',
                marginBottom: '20px'
            }}>
                <Typography style={{fontSize: '16px', fontWeight: 600}}>
                    {intl.get('HOME_PAGE.QUICK_ACTION.QUICK_ACTION')}
                </Typography>
                <Grid container spacing={1}>
                    <Grid item md={9} xs={12}>
                        <Grid container spacing={1}>
                            <Grid item md={3} xs={6}>
                                <Button
                                    className={classes.ButtonBase}
                                    variant='outlined'
                                    onClick={() => history.push("/business/shipping-package/quick-quote")}
                                >
                                    <Box className={classes.section}>
                                        <Box sx={{
                                            display: 'flex',
                                            alignItems: 'center'
                                        }}>
                                            <QuickQuoteLogo width="40" height="40"/>
                                        </Box>
                                        <Box sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'flex-start',
                                            gap: '5px',
                                        }}>
                                            <Typography
                                                style={{
                                                    textTransform: 'none',
                                                    fontSize: '14px',
                                                    fontWeight: '600',
                                                    textAlign: 'left'
                                                }}>
                                                {intl.get('HOME_PAGE.QUICK_ACTION.QUICK_QUOTE')}
                                            </Typography>
                                            {!isMobile &&
                                                <Typography
                                                    style={{
                                                        textTransform: 'none',
                                                        fontSize: '12px',
                                                        textAlign: 'left'
                                                    }}>
                                                    {intl.get('HOME_PAGE.QUICK_ACTION.QUICK_QUOTE_SLOGAN')}
                                                </Typography>
                                            }
                                        </Box>
                                    </Box>
                                </Button>
                            </Grid>
                            <Grid item md={3} xs={6}>
                                <Button
                                    className={classes.ButtonBase}
                                    variant='outlined'
                                    onClick={() => history.push("/business/e-commerce/store")}
                                >
                                    <Box className={classes.section}>
                                        <Box sx={{
                                            display: 'flex',
                                            alignItems: 'center'
                                        }}>
                                            <CreateShipmentLogo width="40" height="40"/>
                                        </Box>
                                        <Box sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'flex-start',
                                            gap: '5px',
                                            justifyContent: 'flex-start',
                                            height: '100%'
                                        }}>
                                            <Typography
                                                style={{
                                                    textTransform: 'none',
                                                    fontSize: '14px',
                                                    fontWeight: '600',
                                                    textAlign: 'left'
                                                }}>
                                                {/*{intl.get('HOME_PAGE.QUICK_ACTION.CREATE_SHIPMENT')}*/}
                                                {intl.get('HOME_PAGE.QUICK_ACTION.E_COMMERCE')}
                                            </Typography>
                                            {!isMobile &&
                                                <Typography
                                                    style={{
                                                        textTransform: 'none',
                                                        fontSize: '12px',
                                                        textAlign: 'left'
                                                    }}>
                                                    {intl.get('HOME_PAGE.QUICK_ACTION.E_COMMERCE_SLOGAN')}
                                                </Typography>
                                            }
                                        </Box>
                                    </Box>
                                </Button>
                            </Grid>
                            <Grid item md={3} xs={6}>
                                {
                                    partnerInfo?.ltl_rating_status === 1 ?
                                        <Button
                                            className={classes.ButtonBase}
                                            variant='outlined'
                                            onClick={() => history.push('/business/shipping-ltl/quick-quote')}
                                        >
                                            <Box className={classes.section}>
                                                <Box sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                }}>
                                                    <ImportShipmentLogo width="40" height="40"/>
                                                </Box>
                                                <Box sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'flex-start',
                                                    gap: '5px',
                                                    justifyContent: 'flex-start',
                                                    height: '100%',
                                                }}>
                                                    <Typography
                                                        style={{
                                                            textTransform: 'none',
                                                            fontSize: '14px',
                                                            fontWeight: '600',
                                                            textAlign: 'left'
                                                        }}>
                                                        {intl.get('HOME_PAGE.QUICK_ACTION.LTL_FREIGHT')}
                                                    </Typography>
                                                    {!isMobile &&
                                                        <Typography
                                                            style={{
                                                                textTransform: 'none',
                                                                fontSize: '12px',
                                                                textAlign: 'left'
                                                            }}>
                                                            {intl.get('HOME_PAGE.QUICK_ACTION.LTL_FREIGHT_SLOGAN')}
                                                        </Typography>
                                                    }
                                                </Box>
                                            </Box>
                                        </Button>
                                        :
                                        <Button
                                            className={classes.ButtonBase}
                                            variant='outlined'
                                            onClick={() => history.push('/business/shipping-package/international-shipment')}
                                        >
                                            <Box className={classes.section}>
                                                <Box sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                }}>
                                                    <ImportShipmentLogo width="40" height="40"/>
                                                </Box>
                                                <Box sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'flex-start',
                                                    gap: '5px',
                                                    justifyContent: 'flex-start',
                                                    height: '100%',
                                                }}>
                                                    <Typography
                                                        style={{
                                                            textTransform: 'none',
                                                            fontSize: '14px',
                                                            fontWeight: '600',
                                                            textAlign: 'left'
                                                        }}>
                                                        {intl.get('HOME_PAGE.QUICK_ACTION.INTERNATIONAL_SHIPMENT')}
                                                    </Typography>
                                                    {!isMobile &&
                                                        <Typography
                                                            style={{
                                                                textTransform: 'none',
                                                                fontSize: '12px',
                                                                textAlign: 'left'
                                                            }}>
                                                            {intl.get('HOME_PAGE.QUICK_ACTION.INTERNATIONAL_SHIPMENT_SLOGAN')}
                                                        </Typography>
                                                    }
                                                </Box>
                                            </Box>
                                        </Button>
                                }
                            </Grid>
                            <Grid item md={3} xs={6}>
                                <Button
                                    className={classes.ButtonBase}
                                    variant='outlined'
                                    onClick={() => history.push("/business/schedulepickup")}
                                >
                                    <Box className={classes.section}>
                                        <Box sx={{
                                            display: 'flex',
                                            alignItems: 'center'
                                        }}>
                                            <SchedulePickupLogo width="40" height="40"/>
                                        </Box>
                                        <Box sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'flex-start',
                                            gap: '5px',
                                        }}>
                                            <Typography
                                                style={{
                                                    textTransform: 'none',
                                                    fontSize: '14px',
                                                    fontWeight: '600',
                                                    textAlign: 'left'
                                                }}>
                                                {intl.get('HOME_PAGE.QUICK_ACTION.SCHEDULE_PICKUP')}
                                            </Typography>
                                            {
                                                !isMobile &&
                                                <Typography
                                                    style={{
                                                        textTransform: 'none',
                                                        fontSize: '12px',
                                                        textAlign: 'left'
                                                    }}>
                                                    {intl.get('HOME_PAGE.QUICK_ACTION.SCHEDULE_PICKUP_SLOGAN')}
                                                </Typography>
                                            }
                                        </Box>
                                    </Box>
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={3} xs={12}>
                        {loading ? <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    width: '100%',
                                    height: '100%'
                                }}
                            >
                                <CircularProgress/>
                            </Box> :
                            <Button
                                className={classes.BillingButtonBase}
                                variant='outlined'
                                onClick={() => history.push(directURL)}
                            >
                                <Grid container spacing={0}>
                                    <Grid item xs={12}>
                                        <Typography style={{
                                            fontSize: '14px',
                                            fontWeight: '600',
                                            textAlign: 'left',
                                            textTransform: 'none',
                                            marginBottom: '5px'
                                        }}>
                                            {intl.get('HOME_PAGE.QUICK_ACTION.BILLING_CENTER')}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12}>
                                                {
                                                    availableCredit < 0 ?
                                                        <Typography style={{
                                                            color: '#FE0000',
                                                            textAlign: 'left',
                                                            fontSize: '14px',
                                                            fontWeight: '600'
                                                        }}>
                                                            ${availableCredit.toFixed(2)}
                                                        </Typography> :
                                                        <Typography style={{
                                                            color: '#1D8B45',
                                                            textAlign: 'left',
                                                            fontSize: '14px',
                                                            fontWeight: '600'
                                                        }}>
                                                            ${availableCredit.toFixed(2)}
                                                        </Typography>
                                                }
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography style={{
                                                    fontSize: '12px',
                                                    fontWeight: '600',
                                                    textAlign: 'left',
                                                    textTransform: 'none'
                                                }}>
                                                    {intl.get('HOME_PAGE.QUICK_ACTION.AVAILABLE_CREDITS')}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button
                                            variant='contained'
                                            className={classes.button}
                                            onMouseMove={() => setDirectURL('/business/billing-center/top-up')}
                                            onMouseLeave={() => setDirectURL('/business/billing-center')}
                                        >
                                            <Typography style={{textTransform: 'none', fontSize: '12px'}}>
                                                {intl.get('HOME_PAGE.QUICK_ACTION.ADD_CREDITS')}
                                            </Typography>
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Button>
                        }
                    </Grid>
                </Grid>
            </Box>
            <Grid container spacing={1}>
                <Grid item xl={9} md={12} xs={12}>
                    <ShippingOrder shippingOrderData={shippingOrderData}/>
                </Grid>
                <Grid item xl={3} md={12} xs={12}>
                    <Grid container spacing={1}>
                        <Grid item xl={12} md={4} xs={12}>
                            <BusinessLandingAdjustOrder/>
                        </Grid>
                        <Grid item xl={12} md={4} xs={12}>
                            <BusinessLandingScheduledPickup/>
                        </Grid>
                        <Grid item xl={12} md={4} xs={12}>
                            <Tracking/>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Modal
                open={open}
                sx={{
                    overflow: 'scroll',
                    height: '100%',
                    display: 'block'
                }}
                // onClose={handleClose}
            >
                <Welcome/>
            </Modal>
        </Container>
    );
};
const useStyles = makeStyles((theme) => ({
    title: {
        fontFamily: "Montserrat",
        fontSize: "2rem",
        letterSpacing: "2px",
        color: "green",
        marginTop: "2vh",
        textAlign: "left",
    },
    root: {
        width: 150,
        height: "6rem",
        backgroundColor: "white",
        borderRadius: 5,
        cursor: "pointer",
        alignItems: "center",
        "&:hover": {
            backgroundColor: "orange",
            color: "white",
        },
        '@media (max-width: 480px)': {
            width: 130,
        }

    },
    lgRoot: {
        minHeight: 200,
        margin: 10,
        marginLeft: 0,
        marginTop: 10,
        // width: '90%',
        width: "100%",
        '@media (max-width: 480px)': {
            margin: 0,
        }
    },
    table: {
        width: "100%",
    },
    tbody: {
        "& tr:nth-child(odd)": {
            backgroundColor: "rgba(222, 222, 222, 0.4)",
        },
        textAlign: "left",
    },
    nameTH: {
        width: "100px",
    },
    idTRSmall: {
        display: 'none',
        '@media (max-width: 480px)': {
            display: 'table-row'
        }
    },

    idTRLarge: {
        '@media (max-width: 480px)': {
            display: 'none'
        }
    },

    idTH: {
        fontSize: '14px',
        fontWeight: '600'
    },
    button: {
        backgroundColor: '#1D8B45',
        color: '#FFFFFF',
        "&:hover": {
            filter: 'brightness(0.9)',
            backgroundColor: '#1D8B45',
        },
    },
    verticalLine: {
        width: '2px',
        height: '100%',
        background: '#1D8B45'
    },
    container: {
        display: 'grid',
        gridTemplateColumns: '5fr 5fr 5fr 5fr 6fr', // Divide into 5 equal parts
        maxHeight: '300px', // Set the desired height for the container
        gap: '5px', // You can adjust the gap between items if needed
    },
    item: {
        border: '1px solid #000000',
        borderRadius: '5px',
        display: 'flex',
        alignItems: 'center'
        // backgroundColor: theme.palette.primary.main, // You can customize the color here
    },
    section: {
        display: 'grid',
        gridTemplateColumns: '1fr 3fr',
        gap: '10px', // You can adjust the gap between items if needed
        height: '100%',
        width: '100%'
    },
    BillingSection: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gap: '10px', // You can adjust the gap between items if needed
        height: '100%',
        width: '100%'
    },
    ButtonBase: {
        maxHeight: 300,
        height: 130,
        backgroundColor: '#FFFFFF',
        width: '100%',
        '&:hover': {
            transform: 'scale(1.1)',
            transition: 'transform 0.3s linear',
            backgroundColor: '#F5F5F5',
            zIndex: '1000',
            boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)',
        },
    },
    BillingButtonBase: {
        maxHeight: 300,
        height: 130,
        backgroundColor: '#FFFFFF',
        width: '100%',
        '&:hover': {
            transform: 'scale(1.05)',
            transition: 'transform 0.3s linear',
            backgroundColor: '#F5F5F5',
            zIndex: '1000',
            boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)',
        },
    }
}));
export default BusinessLandingPage;
