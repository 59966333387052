import Box from "@mui/material/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@mui/material/Typography";
import {PARTNER_URI} from "../../../Utils/apiUrl";
import axios from "axios";
import {useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import Button from "@mui/material/Button";
import {ReactComponent as SuccessSubmitLogo} from "../../../images/crossborderIcon/SuccessSubmit.svg";
import {Divider} from "@mui/material";
import WarningIcon from '@mui/icons-material/Warning';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {getAccessToken} from "../../../Utils/doToken";
import intl from "react-intl-universal";
import {green, yellow} from "../../../Utils/styleConfig";

const style = {
    PackageOrderDashboardCrossBorderRoot: {
        border: '1px solid #B2B2B2',
        borderRadius: '10px',
        padding: '10px 0',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
    }
}

export const BusinessPackageOrderDashboardCrossBorder = ({orderData}) => {

    console.log('order data', orderData);

    const history = useHistory();

    const {order_id: orderId} = useParams();
    const {firstname, id} = useSelector((state) => state.user);
    const token = getAccessToken("access_token");

    const [invoiceStatus, setInvoiceStatus] = useState(false);
    const [trackingList, setTrackingList] = useState([]);
    const [itemList, setItemList] = useState([]);
    const [documentList, setDocumentList] = useState([]);
    const [loadingGetDocumentList, setLoadingGetDocumentList] = useState(false);
    const [errorGetDocumentList, setErrorGetDocumentList] = useState(false);

    const getDocumentList = async () => {
        try {
            setLoadingGetDocumentList(true);
            let requestURI = `${PARTNER_URI}/shipping/list/getTrackingViewByShippingOrderId/${orderId}`;
            const result = await axios({
                method: 'get',
                url: requestURI,
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            })
            setErrorGetDocumentList(false);
            setDocumentList(result?.data);
        } catch (e) {
            setErrorGetDocumentList(true);
        } finally {
            setLoadingGetDocumentList(false);
        }
    }

    const getTrackingList = async () => {
        let requestURI = `${PARTNER_URI}/shipping/list/getTrackingViewByOrderId`;
        try {
            const results = await axios({
                method: 'get',
                url: requestURI,
                params: {
                    order_id: orderId,
                    partner_id: id
                }
            })

            const {data} = results;

            console.log('get tracking list', data);

            const allInvoiceStatus = data.every(element => element?.doc_status === 2);

            console.log('all invoice status', allInvoiceStatus);

            setInvoiceStatus(allInvoiceStatus);

            const updatedTrackingList = [];

            data.forEach(element => {
                let object = {
                    trackingNumber: element?.tracking_no,
                    tranId: element?.tran_id,
                    shippingIdNumber: element?.shipping_id_no,
                    description: element?.item_name,
                    unitPrice: element?.unit_price,
                    quantity: 1,
                    UOM: element?.uom,
                    weight: element?.weight,
                    hsCode: element?.hs_code,
                    originalCountry: element?.origi_country,
                    currency: element?.currency_code,
                    weightUnit: element?.weight_unit,
                    length: element?.length,
                    height: element?.height,
                    width: element?.width,
                    dimensionUnit: element?.dimension_unit,
                    documentStatus: element?.doc_status
                }
                updatedTrackingList.push(object);
            })
            setTrackingList(updatedTrackingList);

        } catch (e) {
            console.log(e)
        }
    }

    const getItemList = async () => {
        let requestURI = `${PARTNER_URI}/packingList/getPackingListAndPackingListDetailByShippingOrderIdAndPartnerId`;
        const token = getAccessToken("access_token");
        try {
            const results = await axios({
                method: 'get',
                url: requestURI,
                headers: {
                    Authorization: `Bearer ${token}`
                },
                params: {
                    shippingOrderId: orderId,
                    currency: 'CAD'
                }
            })

            const {data: {packingListDetails}} = results;
            console.log('get packing list', packingListDetails);

            const updatedPackingList = [];

            packingListDetails.forEach(element => {
                let object = {
                    packingId: element?.id,
                    isChecked: true,
                    description: element?.item_name,
                    unitPrice: element?.unit_price,
                    quantity: element?.quantity,
                    UOM: element?.uom,
                    weight: element?.weight,
                    hsCode: element?.hs_code,
                    originalCountry: element?.origi_country,
                    currency: element?.currency_code,
                    weightUnit: element?.weight_unit,
                }
                updatedPackingList.push(object);
            })
            setItemList(updatedPackingList);
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        if (orderData?.cross_border_shipment_type) {
            console.log('call api')
            getTrackingList();
            getItemList();
            getDocumentList();
        }
    }, [orderData])

    if (orderData?.cross_border_shipment_type === null) {
        return (
            <Box sx={style.PackageOrderDashboardCrossBorderRoot}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            padding: '10px',
                            borderBottom: '1px solid #B2B2B2'
                        }}>
                            <Typography style={{fontSize: '20px', fontWeight: '600'}}>
                                {intl.get('ORDER_DASHBOARD.CROSS_BORDER_INFORMATION.CROSS_BORDER_INFORMATION')}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={{
                            display: 'flex',
                            height: '100%',
                            alignItems: 'center',
                            justifyContent: 'center',
                            minHeight: '150px',
                            padding: '10px',
                            flexDirection: 'column'
                        }}>
                            <Typography style={{fontSize: '14px'}}>
                                Only Available For Cross Border Order
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        )
    }

    console.log('tracking list', trackingList);
    console.log('item list', itemList);
    console.log('document list', documentList);

    return (
        <Box sx={style.PackageOrderDashboardCrossBorderRoot}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        padding: '10px',
                        borderBottom: '1px solid #B2B2B2'
                    }}>
                        <Typography style={{fontSize: '20px', fontWeight: '600'}}>
                            {intl.get('ORDER_DASHBOARD.CROSS_BORDER_INFORMATION.CROSS_BORDER_INFORMATION')}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        padding: '0 10px',
                    }}>
                        <Typography style={{fontSize: '16px', fontWeight: '600', textAlign: 'left'}}>
                            {intl.get('ORDER_DASHBOARD.CROSS_BORDER_INFORMATION.COMMERCIAL_INVOICE')}
                        </Typography>
                    </Box>
                    {/*{*/}
                    {/*    trackingList?.map((element, index) => (*/}
                    {/*        <Box sx={{padding: '5px 10px'}} key={index}>*/}
                    {/*            <Typography style={{fontSize: '14px', textAlign: 'left'}}>*/}
                    {/*                {intl.get('ORDER_DASHBOARD.CROSS_BORDER_INFORMATION.PACKAGE_NAME')}: {element?.description}*/}
                    {/*            </Typography>*/}
                    {/*            <Typography style={{fontSize: '14px', textAlign: 'left'}}>*/}
                    {/*                {intl.get('ORDER_DASHBOARD.CROSS_BORDER_INFORMATION.PACKAGE_DIMENSION')}: {element?.length} x {element?.width} x {element?.height} {element?.dimensionUnit}*/}
                    {/*            </Typography>*/}
                    {/*            <Typography style={{fontSize: '14px', textAlign: 'left'}}>*/}
                    {/*                {intl.get('ORDER_DASHBOARD.CROSS_BORDER_INFORMATION.PACKAGE_WEIGHT')}: {element?.weight} {element?.weightUnit}*/}
                    {/*            </Typography>*/}
                    {/*            <Typography style={{fontSize: '14px', textAlign: 'left'}}>*/}
                    {/*                {intl.get('ORDER_DASHBOARD.CROSS_BORDER_INFORMATION.TRACKING_NO')}: {element?.trackingNumber}*/}
                    {/*            </Typography>*/}
                    {/*            <Box sx={{*/}
                    {/*                display: 'flex',*/}
                    {/*                alignItems: 'center',*/}
                    {/*                gap: '5px'*/}
                    {/*            }}>*/}
                    {/*                <Typography style={{*/}
                    {/*                    fontSize: '14px',*/}
                    {/*                    textAlign: 'left',*/}
                    {/*                }}>*/}
                    {/*                    {intl.get('ORDER_DASHBOARD.CROSS_BORDER_INFORMATION.INVOICE_STATUS')}:*/}
                    {/*                </Typography>*/}
                    {/*                {*/}
                    {/*                    element?.documentStatus === 2 ?*/}
                    {/*                        <Box sx={{display: 'flex', alignItems: 'center'}}>*/}
                    {/*                            <CheckCircleIcon style={{color: '#1D8B45', fontSize: '18px'}}/>*/}
                    {/*                            <Typography style={{*/}
                    {/*                                fontSize: '14px',*/}
                    {/*                                textAlign: 'left',*/}
                    {/*                            }}>*/}
                    {/*                                {intl.get('ORDER_DASHBOARD.CROSS_BORDER_INFORMATION.INVOICE_SUBMIT')}*/}
                    {/*                            </Typography>*/}
                    {/*                        </Box> : element?.documentStatus === 1 ?*/}
                    {/*                            <Box sx={{display: 'flex', alignItems: 'center'}}>*/}
                    {/*                                <WarningIcon style={{color: '#F2BE22', fontSize: '18px'}}/>*/}
                    {/*                                <Typography style={{*/}
                    {/*                                    fontSize: '14px',*/}
                    {/*                                    textAlign: 'left',*/}
                    {/*                                }}>*/}
                    {/*                                    {intl.get('ORDER_DASHBOARD.CROSS_BORDER_INFORMATION.INVOICE_UPLOAD')}*/}
                    {/*                                </Typography>*/}
                    {/*                            </Box> :*/}
                    {/*                            <Box sx={{display: 'flex', alignItems: 'center'}}>*/}
                    {/*                                <CheckCircleIcon style={{color: '#454545', fontSize: '18px'}}/>*/}
                    {/*                                <Typography style={{*/}
                    {/*                                    fontSize: '14px',*/}
                    {/*                                    textAlign: 'left',*/}
                    {/*                                }}>*/}
                    {/*                                    {intl.get('ORDER_DASHBOARD.CROSS_BORDER_INFORMATION.INVOICE_NEED')}*/}
                    {/*                                </Typography>*/}
                    {/*                            </Box>*/}
                    {/*                }*/}
                    {/*            </Box>*/}
                    {/*            <Divider/>*/}
                    {/*        </Box>*/}
                    {/*    ))*/}
                    {/*}*/}
                </Grid>
                <Grid item xs={12}>
                    {
                        invoiceStatus ?
                            <Box sx={{
                                display: 'flex',
                                height: '100%',
                                alignItems: 'center',
                                justifyContent: 'center',
                                minHeight: '150px',
                                padding: '10px',
                                flexDirection: 'column',
                                gap: '20px'
                            }}>
                                <SuccessSubmitLogo width="75px" height="75px"/>
                                <Typography style={{fontSize: '14px'}}>
                                    {intl.get('ORDER_DASHBOARD.CROSS_BORDER_INFORMATION.INVOICE_SUBMITTED_MESSAGE')}
                                </Typography>
                                <Button
                                    variant='outlined'
                                    sx={{
                                        border: '2px solid #1D8B45',
                                        "&:hover": {
                                            border: '2px solid #1D8B45',
                                            filter: 'brightness(0.9)'
                                        }
                                    }}
                                    onClick={() => history.push(`/business/cross-border-dashboard/${orderId}`)}
                                >
                                    <Typography style={{
                                        textTransform: 'none',
                                        fontSize: '14px',
                                        color: '#1D8B45',
                                    }}>
                                        {intl.get('ORDER_DASHBOARD.CROSS_BORDER_INFORMATION.VIEW_DETAILS')}
                                    </Typography>
                                </Button>
                            </Box>
                            :
                            <Box sx={{
                                display: 'flex',
                                height: '100%',
                                alignItems: 'center',
                                justifyContent: 'center',
                                minHeight: '150px',
                                padding: '10px',
                                flexDirection: 'column',
                                gap: '20px'
                            }}>
                                <Typography style={{fontSize: '14px'}}>
                                    {intl.get('ORDER_DASHBOARD.CROSS_BORDER_INFORMATION.INVOICE_PROVIDED_MESSAGE')}
                                </Typography>
                                <Button
                                    variant='outlined'
                                    sx={{
                                        border: '2px solid #1D8B45',
                                        "&:hover": {
                                            border: '2px solid #1D8B45',
                                            filter: 'brightness(0.9)'
                                        }
                                    }}
                                    onClick={() => history.push(`/business/cross-border-dashboard/${orderId}`)}
                                >
                                    <Typography style={{
                                        textTransform: 'none',
                                        fontSize: '14px',
                                        color: '#1D8B45',
                                    }}>
                                        {intl.get('ORDER_DASHBOARD.CROSS_BORDER_INFORMATION.EDIT')}
                                    </Typography>
                                </Button>
                            </Box>
                    }
                </Grid>
                {
                    documentList?.length > 0 &&
                    <Grid item xs={12}>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            padding: '0 10px',
                        }}>
                            <Typography style={{fontSize: '16px', fontWeight: '600', textAlign: 'left'}}>
                                Document List
                            </Typography>
                        </Box>
                        {
                            documentList?.sort((a, b) => a?.doc_status - b?.doc_status)?.map((element, index) => (
                                <Box sx={{padding: '5px 10px'}} key={index}>
                                    <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                                        Document Type: {element?.doc_type}
                                    </Typography>
                                    <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                                        Tracking #: {element?.tracking_no}
                                    </Typography>
                                    <Typography style={{
                                        fontSize: '14px',
                                        textAlign: 'left',
                                    }}>
                                        Status: {element?.doc_status === 2 ? 'Submitted Successfully' : 'Awaiting Submission'}
                                    </Typography>
                                    <Divider/>
                                </Box>
                            ))
                        }
                    </Grid>
                }
                {/*<Grid item xs={12}>*/}
                {/*    <Box sx={{*/}
                {/*        display: 'flex',*/}
                {/*        justifyContent: 'flex-start',*/}
                {/*        padding: '0 10px',*/}
                {/*    }}>*/}
                {/*        <Typography style={{fontSize: '16px', fontWeight: '600', textAlign: 'left'}}>*/}
                {/*            {intl.get('ORDER_DASHBOARD.CROSS_BORDER_INFORMATION.PRODUCT_LIST')}*/}
                {/*        </Typography>*/}
                {/*    </Box>*/}
                {/*    {*/}
                {/*        itemList?.map((element, index) => (*/}
                {/*            <Box sx={{padding: '5px 10px'}} key={index}>*/}
                {/*                <Typography style={{fontSize: '14px', textAlign: 'left'}}>*/}
                {/*                    {intl.get('ORDER_DASHBOARD.CROSS_BORDER_INFORMATION.NAME')}: {element?.description}*/}
                {/*                </Typography>*/}
                {/*                <Typography style={{fontSize: '14px', textAlign: 'left'}}>*/}
                {/*                    {intl.get('ORDER_DASHBOARD.CROSS_BORDER_INFORMATION.UNIT_WEIGHT')}: {element?.weight} {element?.weightUnit}*/}
                {/*                </Typography>*/}
                {/*                <Typography style={{fontSize: '14px', textAlign: 'left'}}>*/}
                {/*                    {intl.get('ORDER_DASHBOARD.CROSS_BORDER_INFORMATION.UNIT_PRICE')}: $ {element?.unitPrice} {element?.currency}*/}
                {/*                </Typography>*/}
                {/*                <Typography style={{fontSize: '14px', textAlign: 'left'}}>*/}
                {/*                    {intl.get('ORDER_DASHBOARD.CROSS_BORDER_INFORMATION.HS_CODE')}: {element?.hsCode}*/}
                {/*                </Typography>*/}
                {/*                <Typography style={{fontSize: '14px', textAlign: 'left'}}>*/}
                {/*                    {intl.get('ORDER_DASHBOARD.CROSS_BORDER_INFORMATION.QUANTITY')}: {element?.quantity}*/}
                {/*                </Typography>*/}
                {/*                <Divider/>*/}
                {/*            </Box>*/}
                {/*        ))*/}
                {/*    }*/}
                {/*</Grid>*/}
            </Grid>
        </Box>
    )
}