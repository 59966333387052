import React, {useEffect, useState} from "react";
import {Elements} from "@stripe/react-stripe-js";
import {makeStyles} from "@material-ui/core/styles";
import {Container} from "@material-ui/core";
import {loadStripe} from "@stripe/stripe-js";
import axios from "axios";
import {useLocation} from "react-router-dom";
import {Box} from "@material-ui/core";
import {Alert} from "@material-ui/lab";
import {useSelector} from "react-redux";
import CardSetupForm from "./cardSetupForm.js";
import {getHeaders, errHandling} from "../../Utils/fetchUtil";
import {
    GET_SETUP_SECRET_API,
    BACKEND_CREATE_PAYMENT_INTENT,
} from "../../Utils/apiUrl";
import LoadingContainer from "../shared/loadingContainer";

const AddCreditCardPage = ({addCard = true, popUp = false}) => {
    const [loading, setLoading] = useState(true);
    const [clientSecret, setClientSecret] = useState("");
    const [setupIntentId, setSetupIntentId] = useState("");
    const [stripePromise, setStripePromise] = useState(null);

    const [err, setErr] = useState("");

    const location = useLocation();
    const userEmail = useSelector((state) => state.user.email);
    //Fetches data from backend/api/me to display userprofile data

    const loadStripeLibrary = async () => {
        const stripe = await loadStripe(
            process.env.NODE_ENV === "development" ? "pk_test_51IK8FMH0JPRrjEMmbN0wv8VwP6yvr8SPUd1Kqgsgw930pSRYVrPQxpwRl2XU9VBHcsJjh8uj0CFV7dq3asN9H8w500ihTaHCIB" : "pk_live_51IK8FMH0JPRrjEMm7eHYO7OrcX5T7ddazAQysXyyD4iYnQrTAFY7NWJoH0L28yv48g1KOBUnMH9reHqu1ofPlTbw00YwMFKWVH"
        );
        setStripePromise(stripe);
    };

    useEffect(() => {
        setErr("");
        if (addCard)
            axios
                .get(GET_SETUP_SECRET_API, {
                    headers: getHeaders(),
                })
                .then((response) => {
                    setLoading(false);
                    // setClientSecret(response.data.requirement);
                    setClientSecret(response.data.client_secret);
                    setSetupIntentId(response.data.id);
                })
                .catch((err) => {
                    console.log(err.response);
                    if (
                        err.response?.data?.code === "2000" &&
                        err.response?.data?.client_secret
                    ) {
                        setLoading(false);
                        setClientSecret(err.response?.data?.client_secret);
                        return;
                    }
                    errHandling(err, setErr);
                    setLoading(false);
                });
        else
            axios({
                method: "post",
                url: BACKEND_CREATE_PAYMENT_INTENT,
                data: JSON.stringify({
                    order_number: location.state?.orderNum,
                    email: location.state?.email,
                }),
                headers: {
                    "Content-Type": "application/json",
                    "X-Requested-With": "XMLHttpRequest",
                },
            })
                .then((response) => {
                    setLoading(false);
                    setClientSecret(response.data.secret);
                    setSetupIntentId(response.data.id);
                })
                .catch((err) => {
                    errHandling(err, setErr);
                    setLoading(false);
                });
    }, []);

    useEffect(() => {
        if (clientSecret) {
            loadStripeLibrary();
        }
    }, [clientSecret])

    const classes = useStyles();

    console.log(setupIntentId);

    let cardComponent = (
        <Elements stripe={stripePromise}>
            <CardSetupForm
                setupIntentId={setupIntentId}
                clientSecret={clientSecret}
                paymentProcess={!addCard}
                orderNum={location.state && location.state.orderNum}
                email={addCard ? userEmail : location.state && location.state.email}
                popUp={popUp}
            />
        </Elements>
    );

    if (err)
        cardComponent = (
            <Box pt="1vh" width="100%">
                <Alert severity="error" classes={{root: classes.alertRoot}}>
                    {err}
                </Alert>
            </Box>
        );
    return (
        <Container
            maxWidth="sm"
            className={popUp ? classes.windowContainer : classes.container}
        >
            {loading ? <LoadingContainer/> : cardComponent}
        </Container>
    );
};

const useStyles = makeStyles((theme) => ({
    container: {
        position: "relative",
        marginTop: "95px",
        marginBottom: "45px",
    },
    windowContainer: {
        position: "relative",
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(4),
    },
}));

export default AddCreditCardPage;
