import {Button, Container, InputAdornment, MenuItem, Select} from "@mui/material";
import {makeStyles} from "@material-ui/core/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {useEffect, useState} from "react";
import {NODE_ROUTE_URI, PARTNER_URI} from "../../Utils/apiUrl";
import axios from "axios";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import {extractFullAddress} from "../../Utils/Helper";
import SearchIcon from "@mui/icons-material/Search";
import intl from "react-intl-universal";
import LoadingButton from "@mui/lab/LoadingButton";
import {Pagination} from "@mui/lab";
import {useLocation} from "react-router-dom";
import {getAccessToken} from "../../Utils/doToken";
import {PAGE_SIZE} from "../../Utils/consInfo";
import {MovingVehicleOrderTable} from "./MovingVehicleOrderTable";
import CircularProgress from "@material-ui/core/CircularProgress";

const styles = {
    ShippingOrderRoot: {
        gap: '15px',
        display: 'flex',
        flexDirection: 'column',
    },
    ShippingOrderContent: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        border: '1px solid #D1D1D1',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px'
    },
}

const useStyles = makeStyles({
    customTextField: {
        "& input::placeholder": {
            fontSize: "14px"
        }
    },
    smallInput: {
        "& .MuiInputBase-input": {
            height: "2px", // Adjust the height as per your preference
            padding: "2px", // Adjust the padding as per your preference
            fontSize: "14px", // Adjust the font size as per your preference
        },
    }
});

export const MovingVehicleOrderList = () => {

    const classes = useStyles();

    const {pathname} = useLocation();
    const startsWithBusiness = pathname.startsWith('/business');
    const token = getAccessToken("access_token");

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [orderData, setOrderData] = useState([]);
    const [searchString, setSearchString] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [lastPage, setLastPage] = useState(0);
    const [movingFromAddressPredictions, setMovingFromAddressPredictions] = useState([]);
    const [movingToAddressPredictions, setMovingToAddressPredictions] = useState([]);
    const [shipFrom, setShipFrom] = useState({
        city: null,
        province: null,
        postalCode: null,
        country: null
    });
    const [shipTo, setShipTo] = useState({
        city: null,
        province: null,
        postalCode: null,
        country: null
    });
    const [status, setStatus] = useState([]);

    const googleMapsPlaceAutocomplete = async (inputValue, type) => {
        let requestURL = `${PARTNER_URI}/googleMaps/placeAutocomplete`;

        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    input: inputValue,
                    types: type
                }
            })
            const {data: {data: {predictions}}} = result;

            return predictions;
        } catch (e) {
            console.log(e.response)
        }
    }

    const googleMapsPlaceDetails = async (placeId) => {
        let requestURL = `${PARTNER_URI}/googleMaps/placeDetails`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    placeId: placeId
                }
            });
            const place = result?.data?.data?.result;
            return place;

        } catch (e) {
            console.log(e.response)
        }
    }

    const handleInputShipFromCity = async (event, value) => {
        setShipFrom(prevState => ({
            ...prevState,
            city: value
        }))
        const predictions = await googleMapsPlaceAutocomplete(value, 'locality|sublocality');
        console.log('[handleInputShipFromCity] predictions', predictions);
        setMovingFromAddressPredictions(predictions);
    }

    const handleInputShipToCity = async (event, value) => {
        setShipTo(prevState => ({
            ...prevState,
            city: value
        }))
        const predictions = await googleMapsPlaceAutocomplete(value, 'locality|sublocality');
        setMovingToAddressPredictions(predictions);
    }

    const handleChangeShipFromCity = async (event, value) => {
        console.log('select', value);
        const place = await googleMapsPlaceDetails(value?.place_id);
        if (place) {
            const address = extractFullAddress(place);
            console.log('address from city ship from', address);
            setShipFrom(prevState => ({
                ...prevState,
                city: address?.sublocality || address?.city || address?.administrative_area_level_3,
                province: {
                    name: address?.state?.name,
                    code: address?.state?.code,
                },
                country: {
                    code: address?.country?.code,
                    name: address?.country?.name,

                }
            }))
        }
    }

    const handleChangeShipToCity = async (event, value) => {
        console.log('select', value);
        const place = await googleMapsPlaceDetails(value?.place_id);
        if (place) {
            const address = extractFullAddress(place);
            console.log('address from city ship to', address);
            setShipTo(prevState => ({
                ...prevState,
                city: address?.sublocality || address?.city || address?.administrative_area_level_3,
                province: {
                    name: address?.state?.name,
                    code: address?.state?.code,
                },
                country: {
                    code: address?.country?.code,
                    name: address?.country?.name,
                }
            }))
        }
    }

    const handleChangeStatus = event => {
        const {
            target: {value},
        } = event;
        setStatus(
            typeof value === 'string' ? value.split(',') : value,
        );
    }

    const handleSearchString = event => {
        const {
            target: {value},
        } = event;
        setSearchString(value);
    }

    const getMovingVehicleOrderList = async () => {
        try {
            setLoading(true);
            let requestURL = startsWithBusiness ? `${PARTNER_URI}/movingCar/getMovingCarOrderList` : `${NODE_ROUTE_URI}/movingCar/getMovingCarOrderList`;

            const result = await axios({
                method: 'get',
                url: requestURL,
                headers: {Authorization: `Bearer ${token}`},
                params: {
                    // queryString: searchString || "",
                    page: 0,
                    pageSize: PAGE_SIZE
                }
            })
            console.log('[getMovingVehicleOrderList] - result', result);
            setOrderData(result?.data?.data);
            setError(false);
            setCurrentPage(result?.data?.pagination?.currentPage);
            setLastPage(result?.data?.pagination?.lastPage);
        } catch (e) {
            setError(true);
        } finally {
            setLoading(false);
        }
    }

    const getMovingVehicleOrderListByPage = async (page) => {
        try {
            setLoading(true);
            let requestURL = startsWithBusiness ? `${PARTNER_URI}/movingCar/getMovingCarOrderList` : `${NODE_ROUTE_URI}/movingCar/getMovingCarOrderList`;

            const result = await axios({
                method: 'get',
                url: requestURL,
                headers: {Authorization: `Bearer ${token}`},
                params: {
                    // queryString: searchString || "",
                    page: page,
                    pageSize: PAGE_SIZE
                }
            })
            console.log('[getMovingVehicleOrderList] - result', result);
            setOrderData(result?.data?.data);
            setError(false);
        } catch (e) {
            setError(true);
        } finally {
            setLoading(false);
        }
    }

    const handleSearchShippingOrderByKeyDown = (event) => {
        if (event.key === 'Enter') {
            getMovingVehicleOrderList();
        }
    }

    const handleSearchOrder = () => {
        getMovingVehicleOrderList();
    }

    const handlePageChange = (event, value) => {
        console.log('page change', value);
        setCurrentPage(value);
        const page = +value - 1;
        getMovingVehicleOrderListByPage(page);
    };

    useEffect(() => {
        (async () => {
            await getMovingVehicleOrderList();
        })()
    }, [])

    console.log('[MovingVehicleOrderList] status', status);

    return (
        <Container maxWidth='xl'>
            <Box sx={styles.ShippingOrderRoot}>
                <Typography sx={{fontSize: '24px', fontWeight: 600, textAlign: 'left'}}>
                    Car Order List
                </Typography>
                {/*<Box sx={styles.ShippingOrderContent}>*/}
                {/*    <Grid container spacing={2}>*/}
                {/*        <Grid item xs={4}>*/}
                {/*            <FormControl fullWidth>*/}
                {/*                <FormLabel sx={{textAlign: 'left'}}>*/}
                {/*                    Moving From City*/}
                {/*                </FormLabel>*/}
                {/*                <Autocomplete*/}
                {/*                    options={movingFromAddressPredictions}*/}
                {/*                    getOptionLabel={option => option?.description}*/}
                {/*                    onInputChange={handleInputShipFromCity}*/}
                {/*                    onChange={handleChangeShipFromCity}*/}
                {/*                    fullWidth*/}
                {/*                    PaperComponent={props => <Paper {...props} sx={{width: '100%'}}/>}*/}
                {/*                    renderInput={params => <TextField*/}
                {/*                        {...params}*/}
                {/*                        variant='outlined'*/}
                {/*                        className={classes.smallInput}*/}
                {/*                    />}*/}
                {/*                    renderOption={(option) => (*/}
                {/*                        <Typography*/}
                {/*                            style={{fontSize: '14px'}}*/}
                {/*                        >*/}
                {/*                            {option.description}*/}
                {/*                        </Typography>*/}
                {/*                    )}*/}
                {/*                />*/}
                {/*            </FormControl>*/}
                {/*        </Grid>*/}
                {/*        <Grid item xs={4}>*/}
                {/*            <FormControl fullWidth>*/}
                {/*                <FormLabel sx={{textAlign: 'left'}}>*/}
                {/*                    Moving To City*/}
                {/*                </FormLabel>*/}
                {/*                <Autocomplete*/}
                {/*                    options={movingToAddressPredictions}*/}
                {/*                    getOptionLabel={option => option?.description}*/}
                {/*                    onInputChange={handleInputShipToCity}*/}
                {/*                    onChange={handleChangeShipToCity}*/}
                {/*                    fullWidth*/}
                {/*                    PaperComponent={props => <Paper {...props} sx={{width: '100%'}}/>}*/}
                {/*                    renderInput={params => <TextField*/}
                {/*                        {...params}*/}
                {/*                        variant='outlined'*/}
                {/*                        className={classes.smallInput}*/}
                {/*                    />}*/}
                {/*                    renderOption={(option) => (*/}
                {/*                        <Typography*/}
                {/*                            style={{fontSize: '14px'}}*/}
                {/*                        >*/}
                {/*                            {option.description}*/}
                {/*                        </Typography>*/}
                {/*                    )}*/}
                {/*                />*/}
                {/*            </FormControl>*/}
                {/*        </Grid>*/}
                {/*        <Grid item xs={4}>*/}
                {/*            <FormControl fullWidth>*/}
                {/*                <FormLabel sx={{textAlign: 'left'}}>*/}
                {/*                    Status*/}
                {/*                </FormLabel>*/}
                {/*                <Select*/}
                {/*                    value={status}*/}
                {/*                    multiple*/}
                {/*                    size='small'*/}
                {/*                    onChange={handleChangeStatus}*/}
                {/*                >*/}
                {/*                    <MenuItem disabled value='carrier' sx={{fontSize: '14px'}}>*/}
                {/*                        <em>Status</em>*/}
                {/*                    </MenuItem>*/}
                {/*                    <MenuItem value='0' sx={{fontSize: '14px'}}>*/}
                {/*                        Initial*/}
                {/*                    </MenuItem>*/}
                {/*                    <MenuItem value='1' sx={{fontSize: '14px'}}>*/}
                {/*                        Processing*/}
                {/*                    </MenuItem>*/}
                {/*                    <MenuItem value='2' sx={{fontSize: '14px'}}>*/}
                {/*                        Confirmed*/}
                {/*                    </MenuItem>*/}
                {/*                </Select>*/}
                {/*            </FormControl>*/}
                {/*        </Grid>*/}
                {/*        <Grid item xs={12}>*/}
                {/*            <TextField*/}
                {/*                value={searchString}*/}
                {/*                fullWidth*/}
                {/*                size='small'*/}
                {/*                onInput={handleSearchString}*/}
                {/*                // onKeyDown={handleSearchShippingOrderByKeyDown}*/}
                {/*                InputProps={{*/}
                {/*                    style: {*/}
                {/*                        paddingRight: '0px'*/}
                {/*                    },*/}
                {/*                    endAdornment: (*/}
                {/*                        <InputAdornment position="end">*/}
                {/*                            <LoadingButton*/}
                {/*                                variant='contained'*/}
                {/*                                sx={{*/}
                {/*                                    backgroundColor: '#1D8B45',*/}
                {/*                                    "&:hover": {*/}
                {/*                                        backgroundColor: '#1D8B45',*/}
                {/*                                        filter: 'brightness(0.9)'*/}
                {/*                                    },*/}
                {/*                                }}*/}
                {/*                                onClick={handleSearchOrder}*/}
                {/*                            >*/}
                {/*                                <SearchIcon/>*/}
                {/*                                <Typography sx={{fontSize: '14px', textTransform: 'none'}}>*/}
                {/*                                    Search*/}
                {/*                                </Typography>*/}
                {/*                            </LoadingButton>*/}
                {/*                        </InputAdornment>*/}
                {/*                    )*/}
                {/*                }}*/}
                {/*                classes={{root: classes.customTextField}}*/}
                {/*                placeholder='Car Size / Car Name / Car Model / City'*/}
                {/*            />*/}
                {/*        </Grid>*/}
                {/*    </Grid>*/}
                {/*</Box>*/}
                {
                    loading ?
                        <Box sx={{display: 'flex', justifyContent: 'center'}}>
                            <CircularProgress/>
                        </Box>
                        :
                        error ?
                            <Box sx={styles.ShippingOrderContent}>
                                <Typography sx={{fontSize: '16px', fontWeight: '600'}}>
                                    {intl.get('HOME_PAGE.ERROR')}
                                </Typography>
                            </Box>
                            :
                            orderData?.length === 0 ?
                                <Box sx={styles.ShippingOrderContent}>
                                    <Typography sx={{fontSize: '16px', fontWeight: '600'}}>
                                        Your Vehicle Order List is Empty
                                    </Typography>
                                </Box>
                                :
                                <Box sx={styles.ShippingOrderContent}>
                                    <MovingVehicleOrderTable orderData={orderData} startsWithBusiness={startsWithBusiness}/>
                                    <Pagination
                                        count={lastPage}
                                        page={+currentPage}
                                        size='small'
                                        onChange={handlePageChange}
                                    />
                                </Box>

                }
            </Box>
        </Container>
    )
}