import * as React from "react";
import MuiAlert from "@mui/material/Alert";
import {getAccessToken} from "../../Utils/doToken";
import {Fragment, useState} from "react";
import {LABEL_DOWNLOAD_URL_PREFIX, NODE_ROUTE_URI, PARTNER_URI} from "../../Utils/apiUrl";
import axios from "axios";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Link, Snackbar} from "@mui/material";
import Typography from "@mui/material/Typography";
import {green, greenButtonContained, greyButtonContained, sectionHeading, yellow} from "../../Utils/styleConfig";
import Box from "@mui/material/Box";
import LoadingButton from "@mui/lab/LoadingButton";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const CrossBorderDashboardDocumentEach = ({key, documentData, getDocumentList}) => {

    const accessToken = getAccessToken("access_token");

    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [submitDialogOpen, setSubmitDialogOpen] = useState(false);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [removeDialogOpen, setRemoveDialogOpen] = useState(false);
    const [removeLoading, setRemoveLoading] = useState(false);

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const handleOpenSubmit = () => {
        setSubmitDialogOpen(true);
    }

    const handleCloseSubmit = () => {
        setSubmitDialogOpen(false);
    }

    const handleOpenRemove = () => {
        setRemoveDialogOpen(true);
    }

    const handleCloseRemove = () => {
        setRemoveDialogOpen(false);
    }

    const handleConfirmSubmission = async () => {
        try {
            setSubmitLoading(true);
            let requestURL = `${NODE_ROUTE_URI}/commercialInvoice/upsPaperlessPushImageToRepo`;

            const data = {
                documentId: documentData?.ref_doc_id,
                shipmentIdentifier: documentData?.shipping_id_no,
                trackingNumber: documentData?.tracking_no,
                docId: documentData?.doc_id
            }

            const result = await axios({
                method: 'post',
                url: requestURL,
                data: data
            })
            setErrorMessage('');
            setSuccessMessage('Submit document successfully');
            handleSuccessMessage();
            setTimeout(() => {
                handleCloseSubmit();
                getDocumentList();
            }, 2000);
        } catch (e) {
            setErrorMessage('Fail to upload document');
            handleErrorMessage();
        } finally {
            setSubmitLoading(false)
        }
    }

    const handleConfirmDeletion = async () => {
        try {
            setRemoveLoading(true);
            let requestURL = `${NODE_ROUTE_URI}/commercialInvoice/removePaperlessDocumentByDocId`;

            const result = await axios({
                method: 'delete',
                url: requestURL,
                headers: {
                    authorization: `Bearer ${accessToken}`,
                },
                params: {
                    docId: documentData?.doc_id
                }
            })
            setErrorMessage('');
            setSuccessMessage('Delete document successfully');
            handleSuccessMessage();
            setTimeout(() => {
                handleCloseRemove();
                getDocumentList();
            }, 2000);
        } catch (e) {
            setErrorMessage('Fail to delete document');
            handleErrorMessage();
        } finally {
            setRemoveLoading(false);
        }
    }

    console.log('[BusinessCrossBorderDashboardDocumentEach] document data', documentData);

    return (
        <Fragment key={key}>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Dialog
                maxWidth="sm"
                fullWidth
                open={submitDialogOpen}
                onClose={handleCloseSubmit}
            >
                <DialogTitle>
                    <Typography sx={{fontSize: sectionHeading, fontWeight: '600'}}>
                        Submit Document
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Typography>
                        You are about to submit the document. Please note that this action is final
                        and cannot be undone.
                    </Typography>
                    <Typography>
                        Would you like to proceed with the submission?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        gap: '10px'
                    }}>
                        <Button
                            variant='contained'
                            sx={greyButtonContained}
                            size='small'
                            onClick={handleCloseSubmit}
                        >
                            <Typography sx={{textTransform: 'none'}}>
                                No, cancel submission
                            </Typography>
                        </Button>
                        <LoadingButton
                            variant='contained'
                            sx={greenButtonContained}
                            size='small'
                            onClick={handleConfirmSubmission}
                            loading={submitLoading}
                        >
                            <Typography sx={{textTransform: 'none'}}>
                                Yes, confirm submission
                            </Typography>
                        </LoadingButton>
                    </Box>
                </DialogActions>
            </Dialog>
            <Dialog
                maxWidth="sm"
                fullWidth
                open={removeDialogOpen}
                onClose={handleCloseRemove}
            >
                <DialogTitle>
                    <Typography sx={{fontSize: sectionHeading, fontWeight: '600'}}>
                        Delete Document
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Typography>
                        You are about to delete the document.
                    </Typography>
                    <Typography>
                        Would you like to proceed with the deletion?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        gap: '10px'
                    }}>
                        <Button
                            variant='contained'
                            sx={greyButtonContained}
                            size='small'
                            onClick={handleCloseRemove}
                        >
                            <Typography sx={{textTransform: 'none'}}>
                                No, cancel deletion
                            </Typography>
                        </Button>
                        <LoadingButton
                            variant='contained'
                            sx={greenButtonContained}
                            size='small'
                            onClick={handleConfirmDeletion}
                            loading={removeLoading}
                        >
                            <Typography sx={{textTransform: 'none'}}>
                                Yes, confirm deletion
                            </Typography>
                        </LoadingButton>
                    </Box>
                </DialogActions>
            </Dialog>
            <Grid item xs={3}>
                <Typography sx={{fontSize: '14px', textAlign: 'left'}}>
                    {documentData?.doc_type || 'N/A'}
                </Typography>
            </Grid>
            <Grid item xs={3}>
                <Typography sx={{fontSize: '14px', textAlign: 'left'}}>
                    {documentData?.tracking_no}
                </Typography>
            </Grid>
            <Grid item xs={3}>
                <Typography
                    sx={{
                        fontSize: '14px',
                        fontWeight: '600',
                        textAlign: 'left',
                        color: documentData?.doc_status === 2 ? green : yellow
                    }}>
                    {documentData?.doc_status === 2 ? 'Submitted Successfully' : 'Awaiting Submission'}
                </Typography>
            </Grid>
            <Grid item xs={3}>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between'
                }}>
                    {
                        documentData?.doc_status !== 2 &&
                        <>
                            <Button
                                variant='contained'
                                sx={greenButtonContained}
                                onClick={handleOpenSubmit}
                            >
                                <Typography sx={{textTransform: 'none', fontSize: '14px'}}>
                                    Submit
                                </Typography>
                            </Button>
                            <IconButton
                                sx={{padding: 0}}
                                onClick={handleOpenRemove}
                            >
                                <DeleteIcon/>
                            </IconButton>
                        </>
                    }
                    {
                        documentData?.file_uri &&
                        <Box sx={{display: 'flex', alignItems: 'center'}}>
                            <Link
                                href={documentData?.file_uri && `${LABEL_DOWNLOAD_URL_PREFIX}${documentData?.file_uri}`}
                                target="_blank" rel="noopener noreferrer"
                            >
                                <IconButton
                                    sx={{padding: 0}}
                                >
                                    <DownloadIcon/>
                                </IconButton>
                            </Link>
                        </Box>
                    }
                </Box>
            </Grid>
        </Fragment>
    )
}