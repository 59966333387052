import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {useHistory} from "react-router-dom";
import {useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {NODE_ROUTE_URI, PARTNER_URI} from "../../../Utils/apiUrl";
import axios from "axios";
import {getAccessToken} from "../../../Utils/doToken";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import WarningIcon from '@mui/icons-material/Warning';
import {Button, Divider} from "@mui/material";
import {ReactComponent as SuccessSubmitLogo} from "../../../images/crossborderIcon/SuccessSubmit.svg";


const style = {
    PackageOrderDashboardCrossBorderRoot: {
        border: '1px solid #B2B2B2',
        borderRadius: '10px',
        padding: '10px 0',
    }
}

export const PackageOrderDashboardCrossBorder = ({orderId, orderInfo}) => {

    const history = useHistory();
    const token = getAccessToken("access_token");

    const {firstname, id} = useSelector((state) => state.user);

    const [invoiceStatus, setInvoiceStatus] = useState(false);
    const [trackingList, setTrackingList] = useState([]);
    const [itemList, setItemList] = useState([]);
    const [documentList, setDocumentList] = useState([]);
    const [loadingGetDocumentList, setLoadingGetDocumentList] = useState(false);
    const [errorGetDocumentList, setErrorGetDocumentList] = useState(false);

    const getDocumentList = async () => {
        try {
            setLoadingGetDocumentList(true);
            let requestURI = `${NODE_ROUTE_URI}/shippingOrderDetailsDashboard/getTrackingViewByShippingOrderId/${orderId}`;
            const result = await axios({
                method: 'get',
                url: requestURI,
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            })
            setErrorGetDocumentList(false);
            setDocumentList(result?.data);
        } catch (e) {
            setErrorGetDocumentList(true);
        } finally {
            setLoadingGetDocumentList(false);
        }
    }

    const getTrackingList = async () => {
        let requestURI = `${NODE_ROUTE_URI}/commercialInvoice/getCrossBorderTrackingViewListByShippingOrderId`;
        try {
            const results = await axios({
                method: 'get',
                url: requestURI,
                headers: {
                    Authorization: `Bearer ${token}`
                },
                params: {
                    shippingOrderId: orderId
                }
            })
            const {data} = results;

            console.log('get tracking list', data);

            const allInvoiceStatus = data.every(element => element?.doc_status === 2);

            console.log('all invoice status', allInvoiceStatus);

            setInvoiceStatus(allInvoiceStatus);

            const updatedTrackingList = [];

            data.forEach(element => {
                let object = {
                    trackingNumber: element?.tracking_no,
                    tranId: element?.tran_id,
                    shippingIdNumber: element?.shipping_id_no,
                    description: element?.item_name,
                    unitPrice: element?.unit_price,
                    quantity: 1,
                    UOM: element?.uom,
                    weight: element?.weight,
                    hsCode: element?.hs_code,
                    originalCountry: element?.origi_country,
                    currency: element?.currency_code,
                    weightUnit: element?.weight_unit,
                    length: element?.length,
                    height: element?.height,
                    width: element?.width,
                    dimensionUnit: element?.dimension_unit,
                    documentStatus: element?.doc_status
                }
                updatedTrackingList.push(object);
            })
            setTrackingList(updatedTrackingList);

        } catch (e) {
            console.log(e)
        }
    }

    const getItemList = async () => {
        let requestURI = `${NODE_ROUTE_URI}/packingList/getPackingListAndPackingListDetailByShippingOrderIdAndUserId`;
        try {
            const results = await axios({
                method: 'get',
                url: requestURI,
                headers: {
                    Authorization: `Bearer ${token}`
                },
                params: {
                    shippingOrderId: orderId,
                    currency: 'CAD'
                }
            })

            const {data: {packingListDetails}} = results;
            console.log('get packing list', packingListDetails);

            const updatedPackingList = [];

            packingListDetails.forEach(element => {
                let object = {
                    packingId: element?.id,
                    isChecked: true,
                    description: element?.item_name,
                    unitPrice: element?.unit_price,
                    quantity: element?.quantity,
                    UOM: element?.uom,
                    weight: element?.weight,
                    hsCode: element?.hs_code,
                    originalCountry: element?.origi_country,
                    currency: element?.currency_code,
                    weightUnit: element?.weight_unit,
                }
                updatedPackingList.push(object);
            })
            setItemList(updatedPackingList);
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        if (orderInfo?.cross_border_shipment_type) {
            getTrackingList();
            getItemList();
            getDocumentList();
        }
    },[orderInfo]);

    if (orderInfo?.cross_border_shipment_type === null) {
        return (
            <Box sx={style.PackageOrderDashboardCrossBorderRoot}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            padding: '10px',
                            borderBottom: '1px solid #B2B2B2'
                        }}>
                            <Typography style={{fontSize: '20px', fontWeight: '600'}}>
                                Cross Border Information
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={{
                            display: 'flex',
                            height: '100%',
                            alignItems: 'center',
                            justifyContent: 'center',
                            minHeight: '150px',
                            padding: '10px',
                            flexDirection: 'column'
                        }}>
                            <Typography style={{fontSize: '14px'}}>
                                Only Available For Cross Border Order
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        )
    }

    console.log('order info', orderInfo);
    console.log('tracking list', trackingList);


    return (
        <Box sx={style.PackageOrderDashboardCrossBorderRoot}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        padding: '10px',
                        borderBottom: '1px solid #B2B2B2'
                    }}>
                        <Typography style={{fontSize: '20px', fontWeight: '600'}}>
                            Cross Border Information
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        padding: '0 10px',
                    }}>
                        <Typography style={{fontSize: '16px', fontWeight: '600', textAlign: 'left'}}>
                            Commercial Invoices
                        </Typography>
                    </Box>
                    {/*{*/}
                    {/*    trackingList?.map((element, index) => (*/}
                    {/*        <Box sx={{padding: '5px 10px'}} key={index}>*/}
                    {/*            <Typography style={{fontSize: '14px', textAlign: 'left'}}>*/}
                    {/*                Package Name: {element?.description}*/}
                    {/*            </Typography>*/}
                    {/*            <Typography style={{fontSize: '14px', textAlign: 'left'}}>*/}
                    {/*                Package*/}
                    {/*                Dimension: {element?.length} x {element?.width} x {element?.height} {element?.dimensionUnit}*/}
                    {/*            </Typography>*/}
                    {/*            <Typography style={{fontSize: '14px', textAlign: 'left'}}>*/}
                    {/*                Package Weight: {element?.weight} {element?.weightUnit}*/}
                    {/*            </Typography>*/}
                    {/*            <Typography style={{fontSize: '14px', textAlign: 'left'}}>*/}
                    {/*                Tracking No: {element?.trackingNumber}*/}
                    {/*            </Typography>*/}
                    {/*            <Box sx={{*/}
                    {/*                display: 'flex',*/}
                    {/*                alignItems: 'center',*/}
                    {/*                gap: '5px'*/}
                    {/*            }}>*/}
                    {/*                <Typography style={{*/}
                    {/*                    fontSize: '14px',*/}
                    {/*                    textAlign: 'left',*/}
                    {/*                }}>*/}
                    {/*                    Invoice Status:*/}
                    {/*                </Typography>*/}
                    {/*                {*/}
                    {/*                    element?.documentStatus === 2 ?*/}
                    {/*                        <Box sx={{display: 'flex', alignItems: 'center'}}>*/}
                    {/*                            <CheckCircleIcon style={{color: '#1D8B45', fontSize: '18px'}}/>*/}
                    {/*                            <Typography style={{*/}
                    {/*                                fontSize: '14px',*/}
                    {/*                                textAlign: 'left',*/}
                    {/*                            }}>*/}
                    {/*                                Invoice Submitted*/}
                    {/*                            </Typography>*/}
                    {/*                        </Box> : element?.documentStatus === 1 ?*/}
                    {/*                            <Box sx={{display: 'flex', alignItems: 'center'}}>*/}
                    {/*                                <WarningIcon style={{color: '#F2BE22', fontSize: '18px'}}/>*/}
                    {/*                                <Typography style={{*/}
                    {/*                                    fontSize: '14px',*/}
                    {/*                                    textAlign: 'left',*/}
                    {/*                                }}>*/}
                    {/*                                    Invoice uploaded*/}
                    {/*                                </Typography>*/}
                    {/*                            </Box> :*/}
                    {/*                            <Box sx={{display: 'flex', alignItems: 'center'}}>*/}
                    {/*                                <CheckCircleIcon style={{color: '#454545', fontSize: '18px'}}/>*/}
                    {/*                                <Typography style={{*/}
                    {/*                                    fontSize: '14px',*/}
                    {/*                                    textAlign: 'left',*/}
                    {/*                                }}>*/}
                    {/*                                    Invoice Need To Submit*/}
                    {/*                                </Typography>*/}
                    {/*                            </Box>*/}
                    {/*                }*/}
                    {/*            </Box>*/}
                    {/*            <Divider/>*/}
                    {/*        </Box>*/}
                    {/*    ))*/}
                    {/*}*/}
                </Grid>
                <Grid item xs={12}>
                    {
                        invoiceStatus ?
                            <Box sx={{
                                display: 'flex',
                                height: '100%',
                                alignItems: 'center',
                                justifyContent: 'center',
                                minHeight: '150px',
                                padding: '10px',
                                flexDirection: 'column',
                                gap: '20px'
                            }}>
                                <SuccessSubmitLogo width="75px" height="75px"/>
                                <Typography style={{fontSize: '14px'}}>
                                    Invoices Submitted Successfully
                                </Typography>
                                <Button
                                    variant='outlined'
                                    sx={{
                                        border: '2px solid #1D8B45',
                                        "&:hover": {
                                            border: '2px solid #1D8B45',
                                            filter: 'brightness(0.9)'
                                        }
                                    }}
                                    onClick={() => history.push(`/cross-border-dashboard/${orderId}`)}
                                >
                                    <Typography style={{
                                        textTransform: 'none',
                                        fontSize: '14px',
                                        color: '#1D8B45',
                                    }}>
                                        View Details
                                    </Typography>
                                </Button>
                            </Box>
                            :
                            <Box sx={{
                                display: 'flex',
                                height: '100%',
                                alignItems: 'center',
                                justifyContent: 'center',
                                minHeight: '150px',
                                padding: '10px',
                                flexDirection: 'column',
                                gap: '20px'
                            }}>
                                <Typography style={{fontSize: '14px'}}>
                                    This is a cross-border order, please provide the
                                    commercial invoices you are going to ship.
                                </Typography>
                                <Button
                                    variant='outlined'
                                    sx={{
                                        border: '2px solid #1D8B45',
                                        "&:hover": {
                                            border: '2px solid #1D8B45',
                                            filter: 'brightness(0.9)'
                                        }
                                    }}
                                    onClick={() => history.push(`/cross-border-dashboard/${orderId}`)}
                                >
                                    <Typography style={{
                                        textTransform: 'none',
                                        fontSize: '14px',
                                        color: '#1D8B45',
                                    }}>
                                        Edit
                                    </Typography>
                                </Button>
                            </Box>
                    }
                </Grid>
                {
                    documentList?.length > 0 &&
                    <Grid item xs={12}>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            padding: '0 10px',
                        }}>
                            <Typography style={{fontSize: '16px', fontWeight: '600', textAlign: 'left'}}>
                                Document List
                            </Typography>
                        </Box>
                        {
                            documentList?.sort((a, b) => a?.doc_status - b?.doc_status)?.map((element, index) => (
                                <Box sx={{padding: '5px 10px'}} key={index}>
                                    <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                                        Document Type: {element?.doc_type}
                                    </Typography>
                                    <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                                        Tracking #: {element?.tracking_no}
                                    </Typography>
                                    <Typography style={{
                                        fontSize: '14px',
                                        textAlign: 'left',
                                    }}>
                                        Status: {element?.doc_status === 2 ? 'Submitted Successfully' : 'Awaiting Submission'}
                                    </Typography>
                                    <Divider/>
                                </Box>
                            ))
                        }
                    </Grid>
                }
                {/*<Grid item xs={12}>*/}
                {/*    <Box sx={{*/}
                {/*        display: 'flex',*/}
                {/*        justifyContent: 'flex-start',*/}
                {/*        padding: '0 10px',*/}
                {/*    }}>*/}
                {/*        <Typography style={{fontSize: '16px', fontWeight: '600', textAlign: 'left'}}>*/}
                {/*            Product List*/}
                {/*        </Typography>*/}
                {/*    </Box>*/}
                {/*    {*/}
                {/*        itemList?.map((element, index) => (*/}
                {/*            <Box sx={{padding: '5px 10px'}} key={index}>*/}
                {/*                <Typography style={{fontSize: '14px', textAlign: 'left'}}>*/}
                {/*                    Name: {element?.description}*/}
                {/*                </Typography>*/}
                {/*                <Typography style={{fontSize: '14px', textAlign: 'left'}}>*/}
                {/*                    Unit Weight: {element?.weight} {element?.weightUnit}*/}
                {/*                </Typography>*/}
                {/*                <Typography style={{fontSize: '14px', textAlign: 'left'}}>*/}
                {/*                    Unit Price: $ {element?.unitPrice} {element?.currency}*/}
                {/*                </Typography>*/}
                {/*                <Typography style={{fontSize: '14px', textAlign: 'left'}}>*/}
                {/*                    HS Code: {element?.hsCode}*/}
                {/*                </Typography>*/}
                {/*                <Typography style={{fontSize: '14px', textAlign: 'left'}}>*/}
                {/*                    Quantity: {element?.quantity}*/}
                {/*                </Typography>*/}
                {/*                <Divider/>*/}
                {/*            </Box>*/}
                {/*        ))*/}
                {/*    }*/}
                {/*</Grid>*/}
            </Grid>
        </Box>

    )

}