import {useState} from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {Button, Divider} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import * as React from "react";
import Grid from "@mui/material/Grid";
import {greenButtonContained, greyButtonContained} from "../../Utils/styleConfig";
import {CrossBorderDashboardDocumentEach} from "./CrossBorderDashboardDocumentEach";
import {CrossBorderDashboardDocumentUpload} from "./CrossBorderDashboardDocumentUpload";

export const CrossBorderDashboardDocumentList = ({
                                                     documentList,
                                                     errorGetDocumentList,
                                                     loadingGetDocumentList,
                                                     getDocumentList
                                                 }) => {

    const [isUpload, setIsUpload] = useState(false);

    const openIsUpload = () => {
        setIsUpload(true);
    }

    const closeIsUpload = () => {
        setIsUpload(false);
    }

    if (loadingGetDocumentList) {
        return (
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '20px',
                border: '1px solid #B2B2B2',
                borderRadius: '5px',
                padding: '15px',
            }}>
                <Typography sx={{fontSize: '18px', fontWeight: '600', textAlign: 'left'}}>
                    Document List
                </Typography>
                <Divider/>
                <Box>
                    <CircularProgress/>
                </Box>
            </Box>
        )
    }

    if (!loadingGetDocumentList && errorGetDocumentList) {
        return (
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '20px',
                border: '1px solid #B2B2B2',
                borderRadius: '5px',
                padding: '15px',
            }}>
                <Typography sx={{fontSize: '18px', fontWeight: '600', textAlign: 'left'}}>
                    Document List
                </Typography>
                <Divider/>
                <Typography style={{fontSize: '16px'}}>
                    Sorry We currently encounter some issues, please try again later.
                </Typography>
            </Box>
        )
    }

    return (
        <Box sx={{
            border: '1px solid #B2B2B2',
            borderRadius: '10px',
            padding: '20px'
        }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'space-between'
                    }}>
                        <Typography sx={{fontSize: '18px', fontWeight: '600', textAlign: 'left'}}>
                            Document List
                        </Typography>
                        <Button
                            variant='contained'
                            sx={isUpload ? greyButtonContained : greenButtonContained}
                            size='small'
                            onClick={isUpload ? closeIsUpload : openIsUpload}
                        >
                            <Typography sx={{textTransform: 'none'}}>
                                {isUpload ? 'Cancel' : 'Upload'}
                            </Typography>
                        </Button>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Divider/>
                </Grid>
                {
                    isUpload ?
                        <Grid item xs={12}>
                            <CrossBorderDashboardDocumentUpload
                                closeIsUpload={closeIsUpload}
                                getDocumentList={getDocumentList}
                            />
                        </Grid>
                        :
                        documentList?.length === 0 ?
                            <Grid item xs={12}>
                                <Typography>
                                    Your document list is empty
                                </Typography>
                            </Grid>
                            :
                            <>
                                <Grid item xs={3}>
                                    <Typography sx={{fontSize: '14px', fontWeight: '600', textAlign: 'left'}}>
                                        Document
                                    </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography sx={{fontSize: '14px', fontWeight: '600', textAlign: 'left'}}>
                                        Tracking #
                                    </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography sx={{fontSize: '14px', fontWeight: '600', textAlign: 'left'}}>
                                        Status
                                    </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                </Grid>
                                {
                                    documentList?.sort((a, b) => a?.doc_status - b?.doc_status).map((document, index) =>
                                        <CrossBorderDashboardDocumentEach
                                            key={index}
                                            documentData={document}
                                            getDocumentList={getDocumentList}
                                        />
                                    )
                                }
                            </>

                }
            </Grid>
        </Box>
    )

}