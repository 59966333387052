import Box from "@mui/material/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@mui/material/Typography";
import intl from "react-intl-universal";
import {useEffect, useState} from "react";
import {Checkbox, Divider, FormControlLabel, Link} from "@mui/material";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {useDispatch, useSelector} from "react-redux";
import {
    updateShippingPackageCreateLabelBatteryDetails, updateShippingPackageCreateLabelBatteryOptions,
    updateShippingPackageCreateLabelSignatureOptions
} from "../../../actions/BusinessShippingPackageAction";
import {useMediaQuery} from "@material-ui/core";
import {ReactComponent as PackageTypeIcon} from "../../../images/NewDesignIcon/AdditionalDetails.svg";
import * as React from "react";

const styles = {
    ShippingPackageCreateLabelPackageDetailsRoot: {
        backgroundColor: '#FFFFFF',
        // padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        // border: '1px solid #A9A9A9',
        gap: '20px',
        display: 'flex',
        flexDirection: 'column',
    },

    ShippingPackageCreateLabelPackageDetailsHeading: {
        fontSize: '18px',
        fontWeight: '600',
        textAlign: 'left'
    },

    ShippingPackageCreateLabelPackageDetailsSection: {
        display: 'flex',
        justifyContent: 'space-between',
    },

    ShippingPackageCreateLabelPackageDetailsSectionColumn: {
        display: 'flex',
        flexDirection: 'column',
    },

    ShippingPackageCreateLabelPackageDetailsModalBox: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: 500,
        maxHeight: 400,
        backgroundColor: '#FFFFFF',
        border: '2px solid #000',
        borderRadius: '10px',
        boxShadow: 24,
        padding: '15px 15px',
        overflow: 'auto'
    },
    ShippingPackageCreateLabelPackageDetailsSelectBox: {
        "&:hover": {
            cursor: "pointer",
            background: "aliceblue"
        },
    },
    ShippingPackageCreateLabelPackageDetailsSelectCommonBox: {
        display: 'flex',
        justifyContent: 'flex-start',
        "&:hover": {
            cursor: "pointer",
            background: "aliceblue"
        },
    },
    ShippingPackageCreateLabelRateListContent: {
        backgroundColor: '#FFFFFF',
        padding: '0 40px',
        marginBottom: '40px',
        borderRadius: '10px',
        gap: '20px',
        display: 'flex',
        flexDirection: 'column',
    },
}

export const BusinessShippingPackageCreateLabelShippingOptions = ({batteryDetailsHistory, signatureOptionsHistory}) => {

    const dispatch = useDispatch();
    const isMobile = useMediaQuery('(max-width: 480px)');

    let batteryDetailsRedux = useSelector(state => state?.businessShippingPackage?.createLabelBatteryDetails);
    let batteryOptionsRedux = useSelector(state => state?.businessShippingPackage?.createLabelBatteryOptions);
    let signatureRequiredRedux = useSelector(state => state?.businessShippingPackage?.createLabelSignatureOptions);
    let shipFrom = useSelector(state => state?.businessShippingPackage?.createLabelShipFrom);
    let shipTo = useSelector(state => state?.businessShippingPackage?.createLabelShipTo);
    let returnLabel = useSelector(state => state?.businessShippingPackage?.returnLabel);

    const [isBattery, setIsBattery] = useState(false);
    const [batteryDetails, setBatteryDetails] = useState({
        material: null,
        packing: null
    });

    const [isSignatureRequired, setIsSignatureRequired] = useState(false);
    const [signatureRequired, setSignatureRequired] = useState(null);
    const [isShippingUS, setIsShippingUS] = useState(false);
    const [isUN3481, setIsUN3481] = useState(true);

    const handleSignatureRequired = event => {
        setIsSignatureRequired(event?.target?.checked);
        if (event?.target?.checked) {
            setSignatureRequired('DEFAULT')
        } else {
            setSignatureRequired(null)
        }
    }

    const handleIsBattery = event => {
        setIsBattery(event?.target?.checked);
        if (!event?.target?.checked) {
            setBatteryDetails(prevState => ({
                ...prevState,
                material: null,
                packing: null
            }))
            setIsUN3481(true);
        }
    }

    const handleBatteryType = (type) => {
        setBatteryDetails(prevState => ({
            ...prevState,
            material: type
        }))
    }

    const handleBatteryPackaged = (packaged) => {
        setBatteryDetails(prevState => ({
            ...prevState,
            packing: packaged
        }))
    }

    const handleUN3481 = (value) => {
        setIsUN3481(value);
        if (!value) {
            setBatteryDetails(prevState => ({
                ...prevState,
                material: 'LITHIUM_ION',
                packing: 'CONTAINED_IN_EQUIPMENT'
            }))
        } else {
            setBatteryDetails(prevState => ({
                ...prevState,
                material: null,
                packing: null
            }))
        }
    }

    useEffect(() => {
        if (batteryDetailsRedux?.material) {
            setIsUN3481(false);
            setBatteryDetails(batteryDetailsRedux);
        }
        if (batteryOptionsRedux) {
            setIsBattery(true);
        }
        if (signatureRequiredRedux) {
            setIsSignatureRequired(true);
            setSignatureRequired(signatureRequiredRedux);
        }
    }, [])

    useEffect(() => {
        dispatch(updateShippingPackageCreateLabelBatteryDetails(batteryDetails));
    }, [batteryDetails])

    useEffect(() => {
        dispatch(updateShippingPackageCreateLabelBatteryOptions(isBattery));
    },[isBattery])

    useEffect(() => {
        dispatch(updateShippingPackageCreateLabelSignatureOptions(signatureRequired));
    }, [signatureRequired])

    useEffect(() => {
        if (shipFrom?.country && shipTo?.country) {
            if (shipFrom?.country?.toUpperCase() !== 'CA' || shipTo?.country?.toUpperCase() !== 'CA') {
                setIsShippingUS(true);
                setIsBattery(false);
                setIsSignatureRequired(false);
                setSignatureRequired(null);
                setBatteryDetails(prevState => ({
                    ...prevState,
                    material: null,
                    packing: null
                }))
            } else {
                setIsShippingUS(false);
            }
        }
    }, [shipFrom, shipTo]);

    useEffect(() => {
        if (returnLabel) {
            setIsBattery(false);
            setIsSignatureRequired(false);
            setSignatureRequired(null);
            setBatteryDetails(prevState => ({
                ...prevState,
                material: null,
                packing: null
            }))
        }
    }, [returnLabel]);

    console.log('[BusinessShippingPackageCreateLabelShippingOptions] signatureRequired', signatureRequired);
    console.log('[BusinessShippingPackageCreateLabelShippingOptions] signatureRequiredRedux', signatureRequiredRedux);
    console.log('[BusinessShippingPackageCreateLabelShippingOptions] batteryOptionsRedux', batteryOptionsRedux);
    console.log('[BusinessShippingPackageCreateLabelShippingOptions] batteryDetailsRedux', batteryDetailsRedux);

    return (
        <Box sx={styles.ShippingPackageCreateLabelPackageDetailsRoot}>
            <Box sx={{
                display: 'flex',
                gap: '10px',
                padding: '20px 20px 0 20px'
            }}>
                <PackageTypeIcon width={25} height={25}/>
                <Typography style={styles.ShippingPackageCreateLabelPackageDetailsHeading}>
                    {intl.get('PACKAGE_SHIPPING_OPTIONS.SHIPPING_OPTIONS')}
                </Typography>
            </Box>
            <Divider/>
            <Box sx={styles.ShippingPackageCreateLabelRateListContent}>
                <Grid container spacing={2}>
                    {
                        isShippingUS &&
                        <Grid item xs={12}>
                            <Typography style={{textAlign: 'left'}}>
                                The shipping option is exclusively for Canadian domestic shipping services.
                            </Typography>
                        </Grid>
                    }
                    {
                        returnLabel &&
                        <Grid item xs={12}>
                            <Typography style={{textAlign: 'left'}}>
                                The shipping option does not cater to return labels.
                            </Typography>
                        </Grid>
                    }
                    <Grid item xs={12}>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'flex-start',
                            flexDirection: 'column',
                        }}>
                            <FormControlLabel
                                value="start"
                                control={<Checkbox
                                    checked={isSignatureRequired}
                                    onChange={handleSignatureRequired}
                                    color="success"
                                    disabled={isShippingUS || returnLabel}
                                />}
                                label={
                                    <Typography style={{textAlign: 'left'}}>
                                        {intl.get('PACKAGE_SHIPPING_OPTIONS.SIGNATURE_OPTIONS')}
                                    </Typography>
                                }
                                labelPlacement="end"
                            />
                            <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                                {intl.get('PACKAGE_SHIPPING_OPTIONS.SIGNATURE_OPTIONS_TEXT')}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'flex-start',
                            flexDirection: 'column',
                        }}>
                            <FormControlLabel
                                value="start"
                                control={<Checkbox
                                    checked={isBattery}
                                    onChange={handleIsBattery}
                                    color="success"
                                    disabled={returnLabel}
                                />}
                                label={
                                    <Typography style={{textAlign: 'left'}}>
                                        {intl.get('PACKAGE_SHIPPING_OPTIONS.INCLUDE_BATTERY')}
                                    </Typography>
                                }
                                labelPlacement="end"
                            />
                        </Box>
                    </Grid>
                    {
                        isBattery &&
                        <Grid item xs={12}>
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'flex-start',
                                flexDirection: 'column',
                            }}>
                                <Box sx={{display: 'flex', gap: '5px'}}>
                                    <Typography>
                                        {intl.get('PACKAGE_SHIPPING_OPTIONS.UN3481')}
                                    </Typography>
                                    <Typography sx={{
                                        textAlign: 'left',
                                        fontWeight: '600',
                                    }}>
                                        <Link
                                            href='https://www.iata.org/contentassets/05e6d8742b0047259bf3a700bc9d42b9/lithium-battery-guidance-document.pdf'
                                            underline='none'
                                            target='_blank'
                                            color='#1D8B45'
                                        >
                                            {intl.get('PACKAGE_SHIPPING_OPTIONS.MORE')}
                                        </Link>
                                    </Typography>
                                </Box>
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    gap: '10px',
                                    flexDirection: isMobile && 'column',
                                    alignItems: 'flex-start'
                                }}>
                                    <FormControlLabel
                                        value="start"
                                        control={<Checkbox
                                            checked={isUN3481}
                                            onChange={() => handleUN3481(true)}
                                            color="success"
                                            icon={<RadioButtonUncheckedIcon sx={{width: '20px'}}/>}
                                            checkedIcon={<CheckCircleIcon sx={{width: '20px'}}/>}
                                        />}
                                        label={
                                            <Typography>
                                                Yes
                                            </Typography>
                                        }
                                        labelPlacement="end"
                                    />
                                    <FormControlLabel
                                        value="start"
                                        control={<Checkbox
                                            checked={!isUN3481}
                                            onChange={() => handleUN3481(false)}
                                            disabled={isShippingUS}
                                            color="success"
                                            icon={<RadioButtonUncheckedIcon sx={{width: '20px'}}/>}
                                            checkedIcon={<CheckCircleIcon sx={{width: '20px'}}/>}
                                        />}
                                        label={
                                            <Typography>
                                                No
                                            </Typography>
                                        }
                                        labelPlacement="end"
                                    />
                                </Box>
                            </Box>
                        </Grid>
                    }
                    {
                        (isBattery && !isUN3481) &&
                        <>
                            <Grid item xs={12}>
                                <Box sx={{display: 'flex', gap: '5px'}}>
                                    <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                                        {intl.get('PACKAGE_SHIPPING_OPTIONS.BATTERY_TEXT')}
                                    </Typography>
                                    <Typography style={{
                                        fontSize: '14px',
                                        textAlign: 'left',
                                        fontWeight: '600',
                                    }}>
                                        <Link
                                            href='https://uucargo.ca/support/shipment-of-items-containing-lithium-batteries-with-uucargo/'
                                            underline='none'
                                            target='_blank'
                                            color='#1D8B45'
                                        >
                                            {intl.get('PACKAGE_SHIPPING_OPTIONS.MORE')}
                                        </Link>
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    alignItems: 'flex-start',
                                    flexDirection: 'column',
                                }}>
                                    <Typography>
                                        {intl.get('PACKAGE_SHIPPING_OPTIONS.BATTERY_TYPE')}
                                    </Typography>
                                    <Box sx={{
                                        display: 'flex',
                                        justifyContent: 'flex-start',
                                        gap: '10px',
                                        flexDirection: isMobile && 'column',
                                        alignItems: 'flex-start'
                                    }}>
                                        <FormControlLabel
                                            value="start"
                                            control={<Checkbox
                                                checked={batteryDetails?.material === 'LITHIUM_ION'}
                                                onChange={() => handleBatteryType('LITHIUM_ION')}
                                                color="success"
                                                icon={<RadioButtonUncheckedIcon sx={{width: '20px'}}/>}
                                                checkedIcon={<CheckCircleIcon sx={{width: '20px'}}/>}
                                            />}
                                            label={
                                                <Typography>
                                                    {intl.get('PACKAGE_SHIPPING_OPTIONS.LITHIUM_ION')}
                                                </Typography>
                                            }
                                            labelPlacement="end"
                                        />
                                        <FormControlLabel
                                            value="start"
                                            control={<Checkbox
                                                checked={batteryDetails?.material === 'LITHIUM_METAL'}
                                                onChange={() => handleBatteryType('LITHIUM_METAL')}
                                                color="success"
                                                icon={<RadioButtonUncheckedIcon sx={{width: '20px'}}/>}
                                                checkedIcon={<CheckCircleIcon sx={{width: '20px'}}/>}
                                            />}
                                            label={
                                                <Typography>
                                                    {intl.get('PACKAGE_SHIPPING_OPTIONS.LITHIUM_METAL')}
                                                </Typography>
                                            }
                                            labelPlacement="end"
                                        />
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    alignItems: 'flex-start',
                                    flexDirection: 'column',
                                }}>
                                    <Typography>
                                        {intl.get('PACKAGE_SHIPPING_OPTIONS.PACKING_TYPE')}
                                    </Typography>
                                    <Box sx={{
                                        display: 'flex',
                                        justifyContent: 'flex-start',
                                        gap: '10px',
                                        flexDirection: isMobile && 'column',
                                        alignItems: 'flex-start'
                                    }}>
                                        <FormControlLabel
                                            value="start"
                                            control={<Checkbox
                                                checked={batteryDetails?.packing === 'CONTAINED_IN_EQUIPMENT'}
                                                onChange={() => handleBatteryPackaged('CONTAINED_IN_EQUIPMENT')}
                                                color="success"
                                                icon={<RadioButtonUncheckedIcon sx={{width: '20px'}}/>}
                                                checkedIcon={<CheckCircleIcon sx={{width: '20px'}}/>}
                                            />}
                                            label={
                                                <Typography style={{textAlign: 'left'}}>
                                                    {intl.get('PACKAGE_SHIPPING_OPTIONS.CONTAINED_IN_EQUIPMENT')}
                                                </Typography>
                                            }
                                            labelPlacement="end"
                                        />
                                        <FormControlLabel
                                            value="start"
                                            control={<Checkbox
                                                checked={batteryDetails?.packing === 'PACKED_WITH_EQUIPMENT'}
                                                onChange={() => handleBatteryPackaged('PACKED_WITH_EQUIPMENT')}
                                                color="success"
                                                icon={<RadioButtonUncheckedIcon sx={{width: '20px'}}/>}
                                                checkedIcon={<CheckCircleIcon sx={{width: '20px'}}/>}
                                            />}
                                            label={
                                                <Typography style={{textAlign: 'left'}}>
                                                    {intl.get('PACKAGE_SHIPPING_OPTIONS.PACKED_WITH_EQUIPMENT')}
                                                </Typography>
                                            }
                                            labelPlacement="end"
                                        />
                                    </Box>
                                </Box>
                            </Grid>
                        </>
                    }
                </Grid>
            </Box>
        </Box>
    )
}