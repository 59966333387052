import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import intl from "react-intl-universal";
import React from "react";
import dayjs from "dayjs";

const style = {
    OrderDashboard: {
        border: '1px solid #B2B2B2',
        borderRadius: '10px',
        padding: '10px 0',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
    }
}

export const MovingVehicleOrderDashboardSummary = ({orderData}) => {

    console.log('[MovingVehicleOrderDashboardSummary] order data', orderData);

    return (
        <Box sx={style.OrderDashboard}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        padding: '10px 20px',
                        borderBottom: '1px solid #B2B2B2'
                    }}>
                        <Typography sx={{fontSize: '16px', fontWeight: '600'}}>
                            Order Summary
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{padding: '20px'}}>
                        <Grid container spacing={1}>
                            <Grid item md={6} xs={12}>
                                <Typography>
                                    <span style={{fontWeight: '600'}}>Order Id:</span> {orderData?.id}
                                </Typography>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Typography>
                                    <span style={{fontWeight: '600'}}>Order #:</span> {orderData?.order_number}
                                </Typography>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Typography>
                                    <span style={{fontWeight: '600'}}>Order Status:</span> {
                                    (+orderData?.status_id) === 0 ? 'Initial' :
                                        (+orderData?.status_id) === 1 ? 'Confirm' :
                                            (+orderData?.status_id) === 100 ? 'Processing' :
                                                (+orderData?.status_id) === 200 ? 'Paid Deposit' :
                                                    (+orderData?.status_id) === -1 ? 'Cancel' :
                                                        (+orderData?.status_id) === -100 ? 'Refund Request' :
                                                            'Refund'
                                }
                                </Typography>
                            </Grid>
                            {/*<Grid item md={6} xs={12}>*/}
                            {/*    <Typography>*/}
                            {/*        <span style={{fontWeight: '600'}}>Invoice Number:</span> {orderData?.crm_invoice_no || "N/A"}*/}
                            {/*    </Typography>*/}
                            {/*</Grid>*/}
                            <Grid item md={6} xs={12}>
                                <Typography>
                                    <span
                                        style={{fontWeight: '600'}}>{orderData?.status_id === 0 ? 'Estimate Price:' : 'Order Price'}</span> {orderData?.orderPrice?.final_total === 0 ? 'Quote Required' : `$ ${orderData?.orderPrice?.final_total?.toFixed(2)}`}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}