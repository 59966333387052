import React, {Fragment, useEffect, useState} from "react";
import {WordPressQuoteCarList} from "./WordPressQuoteCarList";
import {WordPressQuoteMovingItems} from "./WordPressQuoteMovingItems";
import {WordPressQuotePalletList} from "./WordPressQuotePalletList";
import {WordPressQuotePackageList} from "./WordPressQuotePackageList";

export const WordPressQuoteShippingDetails = ({tab, cbHandleShippingDetails, validatedResult, lng}) => {
    return (
        <Fragment>
            {
                tab === 'car' ?
                    <WordPressQuoteCarList cbHandleShippingDetails={cbHandleShippingDetails}
                                           validatedResult={validatedResult}
                                           lng={lng}/>
                    :
                    tab === 'moving' ?
                        <WordPressQuoteMovingItems cbHandleShippingDetails={cbHandleShippingDetails}
                                                   validatedResult={validatedResult}
                                                   lng={lng}/>
                        :
                        tab === 'ltl' ?
                            <WordPressQuotePalletList cbHandleShippingDetails={cbHandleShippingDetails}
                                                      validatedResult={validatedResult}
                                                      lng={lng}/>
                            : <WordPressQuotePackageList cbHandleShippingDetails={cbHandleShippingDetails}
                                                         validatedResult={validatedResult}
                                                         lng={lng}/>

            }
        </Fragment>
    )
}