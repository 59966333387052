import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {Button, IconButton, Stack} from "@mui/material";
import Divider from "@mui/material/Divider";
import {useDispatch, useSelector} from "react-redux";
import {addMovingServiceCarList, toggleMovingServiceAddCar} from "../../actions/movingServiceAction";
import {MovingServiceCarAdd} from "./MovingServiceCarAdd";
import {MovingServiceCarEach} from "./MovingServiceCarEach";
import {ReactComponent as PackageDetailsIcon} from "../../images/NewDesignIcon/Package.svg";
import React, {useEffect, useState} from "react";
import Grid from "@mui/material/Grid";
import {MovingServiceItemList} from "./MovingServiceItemList";
import intl from "react-intl-universal";
import addPackage from "../../images/Create Label.png";
import {generateRandomString} from "../../Utils/Helper";

const styles = {
    MovingServiceItemRoot: {
        backgroundColor: '#FFFFFF',
        paddingBottom: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        gap: '20px',
        display: 'flex',
        flexDirection: 'column',
    },
    MovingServiceAddressHeading: {
        fontSize: '20px',
        fontWeight: '600',
        lineHeight: '20px'
    },
    MovingServiceItemRootRow: {
        display: 'flex',
        gap: '10px',
        justifyContent: 'flex-end',
    },

    MovingServiceItemContent: {
        display: 'flex',
        flexDirection: 'column',
    },

    MovingServiceItemHeading: {
        fontSize: '20px',
        fontWeight: '600'
    },
}

export const MovingServiceCar = ({validatedResult}) => {

    const dispatch = useDispatch();
    let vehicleListRedux = useSelector(state => state?.movingService?.carList);

    const handleAddVehicle = () => {
        const vehicleId = generateRandomString(8);

        const newVehicle = {
            make: null,
            model: null,
            year: null,
            size: null,
            quantity: "1",
            vehicleId: vehicleId
        }

        dispatch(addMovingServiceCarList(newVehicle));
    }

    const [vehicleList, setVehicleList] = useState([]);

    useEffect(() => {
        if (vehicleListRedux?.length > 0) {
            setVehicleList(vehicleListRedux);
        } else {
            setVehicleList([])
        }

    }, [vehicleListRedux])

    console.log('vehicleList', vehicleList);
    console.log('vehicleListRedux', vehicleListRedux);

    return (
        <Box sx={styles.MovingServiceItemRoot}>
            <Box sx={{
                display: 'flex',
                gap: '10px',
                padding: '20px 20px 0 20px',
                justifyContent: 'space-between',
                alignItems: 'center'
            }}>
                <Box sx={{
                    display: 'flex',
                    gap: '10px',
                    alignItems: 'center'
                }}>
                    <PackageDetailsIcon width={25} height={25}/>
                    <Typography style={styles.MovingServiceAddressHeading}>
                        Vehicle List
                    </Typography>
                </Box>
            </Box>
            <Divider/>
            <Box sx={{
                backgroundColor: '#FFFFFF',
                padding: '0 40px',
                gap: '40px',
                display: 'flex',
            }}>
                <Grid container spacing={2}>
                    <Grid item md={12}>
                        <Box sx={styles.MovingServiceItemRootRow}>
                            <Stack direction="row" spacing={2} alignItems="center">
                                <IconButton
                                    sx={{color: '#609966'}}
                                    onClick={handleAddVehicle}
                                >
                                    <img src={addPackage} width='30px'/>
                                </IconButton>
                                <Typography>
                                    New Vehicle
                                </Typography>
                            </Stack>
                        </Box>
                    </Grid>
                    {
                        vehicleList?.length > 0 ? vehicleList?.map((eachItem, index) =>
                            <Grid item xs={12} key={index}>
                                <MovingServiceCarEach
                                    vehicleInfo={eachItem}
                                    index={index}
                                    validatedResult={validatedResult}
                                />
                            </Grid>
                        ) : <Grid item xs={12}>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                border: '1px solid #B7B7B7',
                                padding: '20px',
                                borderRadius: '10px'
                            }}>
                                <Typography sx={{fontSize: '20px', fontWeight: '600', color: '#7F8487'}}>
                                    Your vehicle list is empty
                                </Typography>
                                <IconButton
                                    sx={{color: '#1D8B45'}}
                                    onClick={handleAddVehicle}
                                >
                                    {/*<AddBoxIcon/>*/}
                                    <img src={addPackage} width='30px'/>
                                </IconButton>
                                <Typography sx={{fontSize: '12px', fontWeight: '600', color: '#1D8B45'}}>
                                    Add new vehicle
                                </Typography>
                            </Box>
                        </Grid>
                    }
                </Grid>
            </Box>
        </Box>
    )
}