import {Checkbox, Container, Link, Snackbar} from "@mui/material";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Grid from "@material-ui/core/Grid";
import Typography from "@mui/material/Typography";
import {useEffect, useRef, useState} from "react";
import {
    getMissingKeysForQuickQuotePalletAddress,
    isValidateEmail,
    isValidPostalCode, isValidZipCode,
} from "../../../Utils/Helper";
import {ShippingLTLQuickQuoteAddress} from "./ShippingLTLQuickQuoteAddress";
import {ShippingLTLQuickQuotePallet} from "./ShippingLTLQuickQuotePallet";
import {useDispatch, useSelector} from "react-redux";
import LoadingButton from "@mui/lab/LoadingButton";
import {deleteToken, getAccessToken} from "../../../Utils/doToken";
import {PARTNER_URI} from "../../../Utils/apiUrl";
import axios from "axios";
import MuiAlert from "@mui/material/Alert";
import * as React from "react";
import {
    clearShippingLTLQuickQuotePalletList,
    saveShippingLTLQuickQuoteSpecialService,
    selectShippingLTLQuickQuoteRate,
    updateShippingLTLQuickQuoteConsigneeInfo,
    updateShippingLTLQuickQuoteMoveFrom,
    updateShippingLTLQuickQuoteMoveFromAddition,
    updateShippingLTLQuickQuoteMoveFromMissing,
    updateShippingLTLQuickQuoteMoveToAddition,
    updateShippingLTLQuickQuoteMoveToMissing,
    updateShippingLTLQuickQuotePlaceOrderValidate,
    updateShippingLTLQuickQuoteRate,
    updateShippingLTLQuickQuoteShipperInfo,
    updateShippingLTLQuickQuoteShipPickupDateTime,
    updateShippingLTLQuickQuoteSpecialService
} from "../../../actions/shippingLTLAction";
import {ShippingLTLQuickQuoteRate} from "./ShippingLTLQuickQuoteRate";
import Button from "@mui/material/Button";
import {useMediaQuery} from "@material-ui/core";
import {styled} from "@mui/material/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {useHistory} from "react-router-dom";
import {ShippingLTLQuickQuoteAdditionalService} from "./ShippingLTLQuickQuoteAdditionalService";
import {
    SHIPPING_LTL_GENERAL_PRIVATE_RESIDENT_DELIVERY_CODE,
    SHIPPING_LTL_GENERAL_PRIVATE_RESIDENT_PICKUP_CODE
} from "../../shared";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import packageIcon from "../../../images/homepageIcon/Package.svg";
import ltlIcon from "../../../images/homepageIcon/Pallet.svg";
import brokerageIcon from "../../../images/homepageIcon/CustomBrokerage.svg";
import intl from "react-intl-universal";
import {ShippingLTLQuickQuoteEmailRate} from "./ShippingLTLQuickQuoteEmailRate";
import PublicIcon from "@mui/icons-material/Public";
import {ShippingLTLQuickQuoteOtherRate} from "./ShippingLTLQuickQuoteOtherRate";
import {ShippingLTLQuickQuoteShippingOptions} from "./ShippingLTLQuickQuoteShippingOptions";
import {TIME_OUT} from "../../../Utils/consInfo";
import {ShippingLTLQuickQuoteAIRate} from "./ShippingLTLQuickQuoteAIRate";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const styles = {
    ShippingLTLQuickQuoteSection: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '40px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
    },
    ShippingLTLQuickQuoteSection2nd: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '40px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
    },
    ShippingPackageQuickQuoteHeading: {
        fontSize: '20px',
        fontWeight: '600',
        textAlign: 'left'
    },
}

const StyledTabs = styled((props) => (
    <Tabs
        {...props}
        TabIndicatorProps={{children: <span className="MuiTabs-indicatorSpan"/>}}
    />
))({
    border: '1px solid #609966',
    borderRadius: '25px',
    '& .MuiTabs-indicator': {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'transparent',
    },
    '& .MuiTabs-indicatorSpan': {
        // maxWidth: 40,
        width: '100%',
        backgroundColor: 'transparent',
    },
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
    ({theme}) => ({
        textTransform: 'none',
        fontWeight: theme.typography.fontWeightRegular,
        fontSize: theme.typography.pxToRem(15),
        color: '#000000',
        // border: '1px solid #609966',
        // borderRadius: '25px',
        '&.Mui-selected': {
            color: '#ffffff',
            backgroundColor: '#609966',
            borderRadius: '25px'
        },
        '&.Mui-focusVisible': {
            backgroundColor: 'rgba(100, 95, 228, 0.32)',
        },
    }),
);

const requiredProperties = ["length", "width", "height", "quantity", "weight", "lengthUnit", "weightUnit"];
const validateRequiredProperties = ["length", "width", "height", "weight", "quantity"];
const crossRequiredProperties = ["UOM", "currency", "priceEach", "hscode", "originCountryCode"];

export const ShippingLTLQuickQuote = () => {

    const dispatch = useDispatch();

    const rateSection = useRef(null);

    const userInfo = useSelector((state) => state.user);
    let palletListRedux = useSelector(state => state?.shippingLTL?.quickQuotePalletList);
    let moveFrom = useSelector(state => state?.shippingLTL?.quickQuoteMoveFrom);
    let moveTo = useSelector(state => state?.shippingLTL?.quickQuoteMoveTo);
    let selectRate = useSelector(state => state?.shippingLTL?.quickQuoteRateSelect);
    let moveFromAddition = useSelector(state => state?.shippingLTL?.quickQuoteMoveFromAddition);
    let moveToAddition = useSelector(state => state?.shippingLTL?.quickQuoteMoveToAddition);
    let shipperInfo = useSelector(state => state?.shippingLTL?.quickQuoteShipperInfo);
    let consigneeInfo = useSelector(state => state?.shippingLTL?.quickQuoteConsigneeInfo);
    let shipPickupDateTime = useSelector(state => state?.shippingLTL?.quickQuoteShipPickupDateTime);
    let crossBorder = useSelector(state => state?.shippingLTL?.quickQuoteCrossBorder);
    let specialService = useSelector(state => state?.shippingLTL?.quickQuoteSpecialService);
    let referenceNumber = useSelector(state => state?.shippingLTL?.quickQuoteReferenceNumber);
    let metric = useSelector(state => state?.shippingLTL?.quickQuoteUnit);
    let comments = useSelector(state => state?.shippingLTL?.quickQuoteRemark);
    let batteryDetails = useSelector(state => state?.shippingLTL?.quickQuoteBatteryDetails);
    let ftlCount = useSelector(state => state?.shippingLTL.quickQuoteFTLCount);

    const isMobile = useMediaQuery('(max-width: 600px)');

    // console.log('selected rate', selectRate);

    const history = useHistory();

    const [isGetRate, setIsGetRate] = useState(false);
    const [confirm, setConfirm] = useState(false);
    const [isCallAllRate, setIsCallAllRate] = useState(0);
    const [rate, setRate] = useState([]);
    const [otherRate, setOtherRate] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingPlaceOrder, setLoadingPlaceOrder] = useState(false);
    const [loadingCustomQuote, setLoadingCustomQuote] = useState(false);
    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [error, setError] = useState([]);
    const [tabValue, setTabValue] = useState('ltl');
    const [LTLDialog, setLTLDialog] = useState(false);
    const [overSizeDialog, setOverSizeDialog] = useState(false);
    const [overWeightDialog, setOverWeightDialog] = useState(false);
    const [noLTLRateDialog, setNoLTLRateDialog] = useState(false);
    const [requestRateEmail, setRequestRateEmail] = useState(false);
    const [placeOrderDialog, setPlaceOrderDialog] = useState(false);
    const [commodity, setCommodity] = useState('');
    const [contactEmail, setContactEmail] = useState(userInfo?.email);
    const [contactEmailValidate, setContactEmailValidate] = useState(true);
    const [availableService, setAvailableService] = useState([]);
    const [allService, setAllService] = useState([]);
    const [postalCodeValid, setPostalCodeValid] = useState(null);
    const [showOtherRate, setShowOtherRate] = useState(true);
    const [hasMissingValue, setHasMissingValue] = useState(null);

    const handleOpenRequestEmail = () => {
        setRequestRateEmail(true);
    }

    const handleCloseRequestEmail = () => {
        setRequestRateEmail(false);
    }

    const cbHandleCommodity = (data) => {
        setCommodity(data);
    }

    const handleShowOtherRate = (value) => {
        setShowOtherRate(value);
    }

    const handleOverSizeDialogClose = () => {
        setOverSizeDialog(false);
        // setContactEmail('');
    };

    const handleOverWeightDialogClose = () => {
        setOverWeightDialog(false);
        // setContactEmail('');
    };

    const handleNoLTLRateDialogClose = () => {
        setNoLTLRateDialog(false);
    }

    const handlePlaceOrderDialogClose = () => {
        setPlaceOrderDialog(false);
    }

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const handleContactEmail = (event) => {
        const emailAddress = event.target.value;
        const validateEmail = isValidateEmail(emailAddress);
        setContactEmail(event.target.value);
        setContactEmailValidate(validateEmail);
    }

    const handleError = (errorCode) => {
        switch (errorCode) {
            case 400:
                setErrorMessage("Bad Request: The server cannot process the request.");
                break;
            case 401:
                setErrorMessage("Unauthorized: Authentication is required to access the resource.");
                break;
            case 404:
                setErrorMessage("Not Found: The requested resource could not be found.");
                break;
            case 500:
                setErrorMessage("Internal Server Error: The server encountered an unexpected condition.");
                break;
            default:
                setErrorMessage("Unknown Error: An unknown error occurred.");
                break;
        }
    }

    const token = getAccessToken("access_token");

    const getAllServiceNames = async () => {

        let requestURL = `${PARTNER_URI}/shippingLTL/getAllAvailableServiceNames`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${token}`
                },
                params: {
                    count: 1
                }
            })
            console.log('result', result);
            setAllService(result?.data);
            const updateRate = result?.data?.map(item => ({
                ...item,
                serviceName: item?.account_base?.toLowerCase(),
                loading: false,
                finalTotalBeforeTax: 0
            }));
            setOtherRate(updateRate);
        } catch (e) {
            console.log('error', e);
        }
    }

    const getAllAvailableServiceNames = async () => {

        const totalQty = palletListRedux?.reduce((acc, item) => acc + (+item?.quantity), 0);

        if (totalQty > 25) {
            setAvailableService([]);
            setRate([]);
        } else {
            let requestURL = `${PARTNER_URI}/shippingLTL/getAllAvailableServiceNames`;
            try {
                const result = await axios({
                    method: 'get',
                    url: requestURL,
                    headers: {
                        Authorization: `Bearer ${token}`
                    },
                    params: {
                        count: 1
                    }
                })
                console.log('result', result);
                setAvailableService(result?.data);
                const updateRate = result?.data?.map(item => ({
                    ...item,
                    serviceName: item?.account_base?.toLowerCase(),
                    loading: false,
                    finalTotalBeforeTax: 0
                }));

                setRate(updateRate);

            } catch (e) {
                console.log('error', e);
            }
        }
    }

    const getLTLRatingByOneService = async (serviceName) => {
        setLoading(true);
        let requestURL = `${PARTNER_URI}/shippingLTL/LTLShippingRatingByServiceName`;

        // todo edit battery details
        let packageData = palletListRedux?.map(element => {
            const updatedElement = {...element};
            updatedElement.packageSpecialServices = {
                ...updatedElement.packageSpecialServices,
                batteryDetails: batteryDetails,
            };
            return updatedElement
        });

        let exchangedPackageData = packageData.map(item => {
            let {length, width} = item;
            if (parseInt(length) < parseInt(width)) {
                return {
                    ...item,
                    length: width,
                    width: length
                }
            }
            return item;
        })

        let data = {
            shipperAddress: "",
            shipperAddressType: specialService.some(service => service.serviceCode === SHIPPING_LTL_GENERAL_PRIVATE_RESIDENT_PICKUP_CODE) ? "RESIDENTIAL" : "WAREHOUSE",
            shipperCity: moveFrom?.parentCity || moveFrom?.region,
            shipperSubCity: moveFrom?.city,
            shipperProvince: moveFrom?.province?.code,
            shipperPostalCode: moveFrom?.postalCode,
            shipperCountry: moveFrom?.country,
            consigneeAddress: "",
            consigneeAddressType: specialService.some(service => service.serviceCode === SHIPPING_LTL_GENERAL_PRIVATE_RESIDENT_DELIVERY_CODE) ? "RESIDENTIAL" : "WAREHOUSE",
            consigneeCity: moveTo?.parentCity || moveFrom?.region,
            consigneeSubCity: moveTo?.city,
            consigneeProvince: moveTo?.province?.code,
            consigneePostalCode: moveTo?.postalCode,
            consigneeCountry: moveTo?.country,
            packageData: exchangedPackageData,
            serviceName: serviceName,
            specialServices: specialService,
            ftlCount: ftlCount || 0
        }

        console.log('[getLTLRatingByOneService] api data', data);

        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${token}`
                },
                data: data,
                timeout: TIME_OUT
                // timeout: 200
            })
            const LTLRate = result?.data;
            console.log('ltl rating', LTLRate);
            // dispatch(updateShippingLTLQuickQuoteSpecialService([]));
            setRate(prevState => {
                return prevState?.map(rateItem => {
                    if (rateItem?.serviceName === LTLRate?.serviceName) {
                        return LTLRate;
                    }
                    return rateItem;
                })
            })
            // setRate(prevState => [...prevState, LTLRate]);
            setLoading(false);
            setIsGetRate(true);
            return true;
        } catch (e) {
            setLoading(false);
            if (e.code === 'ECONNABORTED') {
                console.log('Request timed out');
            } else if (e.response.status === 440) {
                setErrorMessage('The authentication token has expired.');
                deleteToken("access_token");
                setTimeout(() => window.location.reload(), 2000);
                handleErrorMessage();
            } else {
                setError(prevState => [...prevState, serviceName]);
                console.log('service name', serviceName);
                return false;
            }
        } finally {
            setIsCallAllRate(prevState => prevState + 1);
            setRate(prevRate => {
                return prevRate.map(rateItem => {
                    if (rateItem?.serviceName.toUpperCase() === serviceName.toUpperCase()) {
                        return {...rateItem, loading: false};
                    }
                    return rateItem;
                });
            });
        }
    }

    const handleConvertCurrency = async (amount) => {
        let requestURL = `${PARTNER_URI}/currency/convertCurrency`;
        let data = {
            fromCurrencyCode: "USD",
            toCurrencyCode: "CAD",
            amount: amount
        }
        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                data: data
            });
            return result?.data?.value;
        } catch (e) {
            return amount;
        }
    }

    // Function to add `amountInCad` to each item
    const addAmountInCAD = async (rateList) => {
        // Create a new array with updated costs
        const updatedRateList = await Promise.all(rateList.map(async (item) => {
            if (item.currency === 'USD') {
                // Convert the cost from USD to CAD if the currency is USD
                const convertedCost = await handleConvertCurrency(item.finalTotalBeforeTax); // Call the API to convert to CAD
                return {
                    ...item,
                    costInCAD: convertedCost, // Update the cost with the converted value
                };
            }
            // If the currency is already CAD, keep the original item
            return {
                ...item,
                costInCAD: item.finalTotalBeforeTax
            }
        }));

        return updatedRateList; // Return the new list with updated rates
    }

    const getLTLRatingByOneServiceNew = async (serviceName) => {
        setLoading(true);
        let requestURL = `${PARTNER_URI}/shippingLTL/LTLShippingRatingListByServiceName`;

        // todo edit battery details
        let packageData = palletListRedux?.map(element => {
            const updatedElement = {...element};
            updatedElement.packageSpecialServices = {
                ...updatedElement.packageSpecialServices,
                batteryDetails: batteryDetails,
            };
            return updatedElement
        });

        let exchangedPackageData = packageData.map(item => {
            let {length, width} = item;
            if (parseInt(length) < parseInt(width)) {
                return {
                    ...item,
                    length: width,
                    width: length
                }
            }
            return item;
        })

        let data = {
            shipperAddress: "",
            shipperAddressType: specialService.some(service => service.serviceCode === SHIPPING_LTL_GENERAL_PRIVATE_RESIDENT_PICKUP_CODE) ? "RESIDENTIAL" : "WAREHOUSE",
            shipperCity: moveFrom?.parentCity || moveFrom?.region,
            shipperSubCity: moveFrom?.city,
            shipperProvince: moveFrom?.province?.code,
            shipperPostalCode: moveFrom?.postalCode,
            shipperCountry: moveFrom?.country,
            consigneeAddress: "",
            consigneeAddressType: specialService.some(service => service.serviceCode === SHIPPING_LTL_GENERAL_PRIVATE_RESIDENT_DELIVERY_CODE) ? "RESIDENTIAL" : "WAREHOUSE",
            consigneeCity: moveTo?.parentCity || moveFrom?.region,
            consigneeSubCity: moveTo?.city,
            consigneeProvince: moveTo?.province?.code,
            consigneePostalCode: moveTo?.postalCode,
            consigneeCountry: moveTo?.country,
            packageData: exchangedPackageData,
            serviceName: serviceName,
            specialServices: specialService,
            ftlCount: ftlCount || 0
        }

        console.log('[getLTLRatingByOneService] api data', data);

        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${token}`
                },
                data: data,
                timeout: TIME_OUT
                // timeout: 200
            })
            const LTLRateList = result?.data;
            console.log('[getLTLRatingByOneServiceNew] ltl rating list', LTLRateList);

            // update ltl rate list, having field cost in CAD for sort
            const updatedLTLRateList = await addAmountInCAD(LTLRateList);

            console.log('[getLTLRatingByOneServiceNew] updatedLTLRateList', updatedLTLRateList);

            setRate(prevState => [...prevState, ...updatedLTLRateList]);

            setLoading(false);
            setIsGetRate(true);
            return true;
        } catch (e) {
            setLoading(false);
            if (e.code === 'ECONNABORTED') {
                console.log('Request timed out');
            } else if (e.response.status === 440) {
                setErrorMessage('The authentication token has expired.');
                deleteToken("access_token");
                setTimeout(() => window.location.reload(), 2000);
                handleErrorMessage();
            } else {
                setError(prevState => [...prevState, serviceName]);
                console.log('service name', serviceName);
                return false;
            }
        } finally {
            setIsCallAllRate(prevState => prevState + 1);
            // setRate(prevRate => {
            //     return prevRate.map(rateItem => {
            //         if (rateItem?.serviceName.toUpperCase() === serviceName.toUpperCase()) {
            //             return {...rateItem, loading: false};
            //         }
            //         return rateItem;
            //     });
            // });

            setRate(prevRate => {
                return prevRate.filter(rateItem =>
                    !(rateItem?.serviceName.toUpperCase() === serviceName.toUpperCase() &&
                        rateItem.finalTotalBeforeTax === 0)
                );
            });
        }
    }

    const placeLTLOrder = async () => {
        setLoadingPlaceOrder(true);
        let requestURI = `${PARTNER_URI}/shippingLTL/initLTLBackendOrder`;
        let data = {
            shipperName: shipperInfo?.name,
            shipperPhone: shipperInfo?.phone?.replace(/[^a-zA-Z0-9]/g, ''),
            shipperCompanyName: shipperInfo?.company || shipperInfo?.name,
            shipperEmail: shipperInfo?.email,
            shipperAddress: moveFromAddition?.address,
            shipperAddressTwo: moveFromAddition?.address2 || "",
            shipperAddressType: specialService.some(service => service.serviceCode === SHIPPING_LTL_GENERAL_PRIVATE_RESIDENT_PICKUP_CODE) ? "RESIDENTIAL" : "WAREHOUSE",
            shipperCity: moveFrom?.city,
            shipperProvince: moveFrom?.province?.code,
            shipperPostalCode: moveFromAddition?.postalCode,
            shipperCountry: moveFrom?.province?.countryCode,
            consigneeName: consigneeInfo?.name,
            consigneePhone: consigneeInfo?.phone?.replace(/[^a-zA-Z0-9]/g, ''),
            consigneeCompanyName: consigneeInfo?.company || consigneeInfo?.name,
            consigneeEmail: consigneeInfo?.email,
            consigneeAddress: moveToAddition?.address,
            consigneeAddressTwo: moveToAddition?.address2 || "",
            consigneeAddressType: specialService.some(service => service.serviceCode === SHIPPING_LTL_GENERAL_PRIVATE_RESIDENT_DELIVERY_CODE) ? "RESIDENTIAL" : "WAREHOUSE",
            consigneeCity: moveTo?.city,
            consigneeProvince: moveTo?.province?.code,
            consigneePostalCode: moveToAddition?.postalCode,
            consigneeCountry: moveTo?.province?.countryCode,
            shipToName: consigneeInfo?.name,
            shipToPhone: consigneeInfo?.phone?.replace(/[^a-zA-Z0-9]/g, ''),
            shipToCompanyName: consigneeInfo?.company || consigneeInfo?.name,
            shipToEmail: consigneeInfo?.email,
            shipToAddress: moveToAddition?.address,
            shipToAddressType: specialService.some(service => service.serviceCode === SHIPPING_LTL_GENERAL_PRIVATE_RESIDENT_DELIVERY_CODE) ? "RESIDENTIAL" : "WAREHOUSE",
            shipToCity: moveTo?.city,
            shipToProvince: moveTo?.province?.code,
            shipToPostalCode: moveToAddition?.postalCode,
            shipToCountry: moveTo?.province?.countryCode,
            soldToName: consigneeInfo?.name,
            soldToPhone: consigneeInfo?.phone?.replace(/[^a-zA-Z0-9]/g, ''),
            soldToCompanyName: consigneeInfo?.company || consigneeInfo?.name,
            soldToEmail: consigneeInfo?.email,
            soldToAddress: moveToAddition?.address,
            soldToAddressType: specialService.some(service => service.serviceCode === SHIPPING_LTL_GENERAL_PRIVATE_RESIDENT_DELIVERY_CODE) ? "RESIDENTIAL" : "WAREHOUSE",
            soldToCity: moveTo?.city,
            soldToProvince: moveTo?.province?.code,
            soldToPostalCode: moveToAddition?.postalCode,
            soldToCountry: moveTo?.province?.countryCode,
            serviceName: selectRate?.serviceName,
            shipDate: shipPickupDateTime?.shipDate,
            pickupLocationCloseTime: `${shipPickupDateTime?.shipDate} ${shipPickupDateTime?.latestPickupTime}`,
            pickupLocationOpenTime: `${shipPickupDateTime?.shipDate} ${shipPickupDateTime?.earliestPickupTime}`,
            pickupAvailableTime: `${shipPickupDateTime?.shipDate} ${shipPickupDateTime?.availablePickupTime}`,
            pickupDate: shipPickupDateTime?.pickupDate,
            transMode: crossBorder?.tranMode,
            shipmentType: crossBorder?.shipmentType,
            pickupInstruction: "",
            packageData: palletListRedux,
            specialServices: specialService,
            refNumber: referenceNumber,
            comments: comments
        }

        try {
            const result = await axios({
                method: 'post',
                url: requestURI,
                headers: {
                    Authorization: `Bearer ${token}`
                },
                data: data
            })
            console.log('place order', result);
            setErrorMessage('');
            handleSuccessMessage();
            setSuccessMessage('Successfully place LTL order.')
            setLoadingPlaceOrder(false);
            // setDisabledPlaceOrder(true);
            dispatch(updateShippingLTLQuickQuoteSpecialService([]));
            setTimeout(() => {
                history.push(`/myLTLs`);

            }, 2000);

        } catch (e) {
            console.log(e.response)
            const errorCode = e?.response?.data?.error?.status;
            console.log('error code', errorCode);
            handleErrorMessage();
            handleError(errorCode);
            setLoadingPlaceOrder(false);

        }
    }

    const customQuote = async () => {
        setLoadingCustomQuote(true);

        const updatedPackageData = [...palletListRedux]?.map((item) => ({
            ...item,
            description: commodity
        }))

        let requestURI = `${PARTNER_URI}/shippingLTL/LTLRequestQuote`;
        let data = {
            shipperName: shipperInfo?.name,
            shipperPhone: shipperInfo?.phone?.replace(/[^a-zA-Z0-9]/g, ''),
            shipperCompanyName: shipperInfo?.company || shipperInfo?.name,
            shipperEmail: shipperInfo?.email,
            shipperAddress: moveFromAddition?.address,
            shipperAddressTwo: moveFromAddition?.address2 || "",
            shipperAddressType: specialService.some(service => service.serviceCode === SHIPPING_LTL_GENERAL_PRIVATE_RESIDENT_PICKUP_CODE) ? "RESIDENTIAL" : "WAREHOUSE",
            shipperCity: moveFrom?.city,
            shipperProvince: moveFrom?.province?.code,
            shipperPostalCode: moveFromAddition?.postalCode,
            shipperCountry: moveFrom?.province?.countryCode,
            consigneeName: consigneeInfo?.name,
            consigneePhone: consigneeInfo?.phone?.replace(/[^a-zA-Z0-9]/g, ''),
            consigneeCompanyName: consigneeInfo?.company || consigneeInfo?.name,
            consigneeEmail: consigneeInfo?.email,
            consigneeAddress: moveToAddition?.address,
            consigneeAddressTwo: moveToAddition?.address2 || "",
            consigneeAddressType: specialService.some(service => service.serviceCode === SHIPPING_LTL_GENERAL_PRIVATE_RESIDENT_DELIVERY_CODE) ? "RESIDENTIAL" : "WAREHOUSE",
            consigneeCity: moveTo?.city,
            consigneeProvince: moveTo?.province?.code,
            consigneePostalCode: moveToAddition?.postalCode,
            consigneeCountry: moveTo?.province?.countryCode,
            shipToName: consigneeInfo?.name,
            shipToPhone: consigneeInfo?.phone?.replace(/[^a-zA-Z0-9]/g, ''),
            shipToCompanyName: consigneeInfo?.company || consigneeInfo?.name,
            shipToEmail: consigneeInfo?.email,
            shipToAddress: moveToAddition?.address,
            shipToAddressType: specialService.some(service => service.serviceCode === SHIPPING_LTL_GENERAL_PRIVATE_RESIDENT_DELIVERY_CODE) ? "RESIDENTIAL" : "WAREHOUSE",
            shipToCity: moveTo?.city,
            shipToProvince: moveTo?.province?.code,
            shipToPostalCode: moveToAddition?.postalCode,
            shipToCountry: moveTo?.province?.countryCode,
            soldToName: consigneeInfo?.name,
            soldToPhone: consigneeInfo?.phone?.replace(/[^a-zA-Z0-9]/g, ''),
            soldToCompanyName: consigneeInfo?.company || consigneeInfo?.name,
            soldToEmail: consigneeInfo?.email,
            soldToAddress: moveToAddition?.address,
            soldToAddressType: specialService.some(service => service.serviceCode === SHIPPING_LTL_GENERAL_PRIVATE_RESIDENT_DELIVERY_CODE) ? "RESIDENTIAL" : "WAREHOUSE",
            soldToCity: moveTo?.city,
            soldToProvince: moveTo?.province?.code,
            soldToPostalCode: moveToAddition?.postalCode,
            soldToCountry: moveTo?.province?.countryCode,
            serviceName: "",
            shipDate: shipPickupDateTime?.shipDate,
            pickupLocationCloseTime: `${shipPickupDateTime?.shipDate} ${shipPickupDateTime?.latestPickupTime}`,
            pickupLocationOpenTime: `${shipPickupDateTime?.shipDate} ${shipPickupDateTime?.earliestPickupTime}`,
            pickupAvailableTime: `${shipPickupDateTime?.shipDate} ${shipPickupDateTime?.availablePickupTime}`,
            pickupDate: shipPickupDateTime?.pickupDate,
            transMode: crossBorder?.tranMode,
            shipmentType: crossBorder?.shipmentType,
            pickupInstruction: "",
            packageData: updatedPackageData,
            specialServices: specialService,
            email: contactEmail,
            refNumber: referenceNumber,
            comments: comments,
        }

        try {
            const result = await axios({
                method: 'post',
                url: requestURI,
                headers: {
                    Authorization: `Bearer ${token}`
                },
                data: data
            })
            // console.log('place order', result);
            setErrorMessage('');
            handleSuccessMessage();
            setSuccessMessage('Successfully send quote request.')
            setLoadingCustomQuote(false);
            // setDisabledPlaceOrder(true);
            handleOverWeightDialogClose();
            handleOverSizeDialogClose();
            handleCloseRequestEmail();

        } catch (e) {
            console.log(e.response)
            const errorCode = e?.response?.data?.error?.status;
            console.log('error code', errorCode);
            handleErrorMessage();
            handleError(errorCode);
            setLoadingCustomQuote(false);
        }
    }

    const validateCityProvinceCountry = async (city, province, country) => {
        let requestURI = `${PARTNER_URI}/googleMaps/validateCityProvinceCountry`;
        let data = {city, province, country};
        try {
            const result = await axios({
                method: 'post',
                url: requestURI,
                data: data
            })
            console.log('validateCityProvinceCountry result', result);
            return result?.data?.validation;
        } catch (e) {
            console.log(e.response)
            const errorCode = e?.response?.data?.error?.status;
            console.log('error code', errorCode);
        }
    }

    const validate = async () => {
        setErrorMessage('');
        setToastOpen(false);
        const moveFromMissing = getMissingKeysForQuickQuotePalletAddress({
            city: moveFrom?.city || moveFrom?.region,
            province: moveFrom?.province?.code,
            country: moveFrom?.country,
            postalCode: moveFrom?.postalCode
        });
        const moveToMissing = getMissingKeysForQuickQuotePalletAddress({
            city: moveTo?.city || moveTo?.region,
            province: moveTo?.province?.code,
            country: moveTo?.country,
            postalCode: moveTo?.postalCode
        });
        const hasMissingValues = palletListRedux.some(object => requiredProperties.some((property) => !object[property]));
        const hasValidateValues = palletListRedux.some(object => validateRequiredProperties.some((property) => object[property] <= 0));
        const shipFromPostalCodeValid = moveFrom?.country?.toLowerCase() === 'ca' ? isValidPostalCode(moveFrom?.postalCode) : isValidZipCode(moveFrom?.postalCode);
        const shipToPostalCodeValid = moveTo?.country?.toLowerCase() === 'ca' ? isValidPostalCode(moveTo?.postalCode) : isValidZipCode(moveTo?.postalCode);

        setHasMissingValue(hasMissingValues);
        dispatch(updateShippingLTLQuickQuoteMoveFromMissing(moveFromMissing));
        dispatch(updateShippingLTLQuickQuoteMoveToMissing(moveToMissing));

        palletListRedux?.length === 0 && setErrorMessage(prevState => prevState + intl.get('ADD_PALLET.VALIDATION.PALLET_LIST_EMPTY'));
        moveFromMissing?.length !== 0 && setErrorMessage(prevState => prevState + intl.get('ADD_PALLET.VALIDATION.SHIP_FROM_MISSING'));
        moveToMissing?.length !== 0 && setErrorMessage(prevState => prevState + intl.get('ADD_PALLET.VALIDATION.SHIP_TO_MISSING'));
        hasMissingValues && setErrorMessage(prevState => prevState + intl.get('ADD_PALLET.VALIDATION.PALLET_LIST_MISSING'));
        hasValidateValues && setErrorMessage(prevState => prevState + intl.get('ADD_PALLET.VALIDATION.PALLET_LIST_ERROR'));
        !shipFromPostalCodeValid && setErrorMessage(prevState => prevState + 'Ship From postal code is invalid.');
        !shipToPostalCodeValid && setErrorMessage(prevState => prevState + 'Ship To postal code is invalid.');

        setPostalCodeValid({
            shipFromPostalCodeValid,
            shipToPostalCodeValid,
        })

        if (moveFromMissing.length === 0 &&
            moveToMissing.length === 0 &&
            palletListRedux.length !== 0 &&
            !hasMissingValues &&
            !hasValidateValues &&
            shipFromPostalCodeValid &&
            shipToPostalCodeValid
        ) {
            return true
        } else {
            handleErrorMessage();
            return false
        }
    }

    const handleCallLTLCarrier = () => {

        for (const service of availableService) {
            // getLTLRatingByOneService(service?.account_base);
            getLTLRatingByOneServiceNew(service?.account_base);
        }
    }

    const handleGetRate = async () => {
        // clear redux state
        await getAllAvailableServiceNames();

        handleCloseRequestEmail();
        dispatch(selectShippingLTLQuickQuoteRate(null));
        dispatch(updateShippingLTLQuickQuotePlaceOrderValidate(null));
        // dispatch(updateShippingLTLQuickQuoteSpecialService([]));
        dispatch(updateShippingLTLQuickQuoteMoveFromAddition({}));
        dispatch(updateShippingLTLQuickQuoteMoveToAddition({}));
        dispatch(updateShippingLTLQuickQuoteShipperInfo({}));
        dispatch(updateShippingLTLQuickQuoteConsigneeInfo({}));
        dispatch(updateShippingLTLQuickQuoteShipPickupDateTime({}));
        const validationResult = await validate();
        if (validationResult) {
            // setRate([]);
            setError([]);

            console.log('pallet list', palletListRedux);
            const hasCustom = palletListRedux.some(each => each?.packagingType?.name === 'Custom');
            console.log('has custom', hasCustom);

            const maxWeight = metric ? 680 : 1500;
            const maxLength = metric ? 122 : 48;
            const maxWidth = metric ? 122 : 48;
            const maxHeight = metric ? 203 : 80;

            const overWeight = palletListRedux?.some(({weight}) => weight > maxWeight);
            const overSize = palletListRedux?.some(({length, width, height}) =>
                length > maxLength ||
                width > maxWidth ||
                height > maxHeight
            )

            console.log('over weight', overWeight);
            console.log('over size', overSize);

            // if (overWeight) {
            //     setIsGetRate(true);
            //     handleOverWeightDialogOpen();
            // } else {
            console.log('no over weight or size');
            setRate(prevRate => {
                return prevRate.map(rateItem => {
                    return {...rateItem, loading: true};
                });
            });
            try {
                await handleCallLTLCarrier();
            } catch (e) {
                console.log('error', e.response);
            } finally {
                setIsGetRate(true);
            }
        }
    }

    const handleEdit = () => {
        setIsGetRate(false);
        setIsCallAllRate(0);
        handleOverSizeDialogClose();
        handleOverWeightDialogClose();
        dispatch(selectShippingLTLQuickQuoteRate(null));
        dispatch(updateShippingLTLQuickQuotePlaceOrderValidate(null));
        // dispatch(updateShippingLTLQuickQuoteSpecialService([]));
    }

    const handlePlaceOrderFromEmail = () => {
        const validateEmail = isValidateEmail(contactEmail);
        if (validateEmail) {
            placeLTLOrder();
        }
    }

    const handleGetRateFromEmail = () => {
        const validateEmail = isValidateEmail(contactEmail);
        if (validateEmail) {
            customQuote();
        }
    }

    useEffect(() => {
        getAllServiceNames();
    }, [])

    useEffect(() => {
        const sortedRate = [...rate].sort((a, b) => (a?.costInCAD || Infinity) - (b?.costInCAD || Infinity));
        dispatch(updateShippingLTLQuickQuoteRate(sortedRate));
        sortedRate[0]?.costInCAD && dispatch(selectShippingLTLQuickQuoteRate(sortedRate[0]));
    }, [rate]);

    useEffect(() => {
        if (tabValue === 'package') {
            history.push('/shipping-package/quick-quote')
        } else if (tabValue === 'brokerage') {
            history.push('/brokerage/estimate')
        } else {
            return;
        }
    }, [tabValue])

    useEffect(() => {
        dispatch(clearShippingLTLQuickQuotePalletList());
        dispatch(updateShippingLTLQuickQuotePlaceOrderValidate(null));
        dispatch(selectShippingLTLQuickQuoteRate(null));
        dispatch(saveShippingLTLQuickQuoteSpecialService([]));
    }, []);

    useEffect(() => {
        getAllAvailableServiceNames();
    }, [palletListRedux]);

    useEffect(() => {
        if ((isCallAllRate === rate?.length) && (rate?.length === 0 || rate?.every(obj => obj?.finalTotalBeforeTax === 0))) {
            handleOpenRequestEmail();
        }
    }, [isCallAllRate, rate]);

    const scrollDown = (ref) => {
        console.log(ref.current.offsetTop)
        window.scrollTo({
            top: ref.current.offsetTop,
            behavior: 'smooth',
        });
    };

    useEffect(() => {
        if (isGetRate) {
            scrollDown(rateSection);
        }
    }, [isGetRate])

    // console.log('[ShippingLTLQuickQuote] pallet', palletListRedux);
    console.log('[ShippingLTLQuickQuote] move from', moveFrom);

    return (
        <Container maxWidth='xl'>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Dialog
                // open={overWeightDialog}
                onClose={handleOverWeightDialogClose}
            >
                <DialogTitle>
                    {intl.get('SHIPPING_LTL_QUICK_QUOTE.DIALOG.GET_LTL_RATE')}
                </DialogTitle>
                <DialogContent>
                    <Box sx={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
                        <Typography>
                            {intl.get('SHIPPING_LTL_QUICK_QUOTE.DIALOG.OVER_WEIGHT_MESSAGE')}
                        </Typography>
                        <TextField
                            // defaultValue={contactEmail}
                            value={contactEmail}
                            onInput={handleContactEmail}
                            fullWidth
                        />
                        <Typography style={{fontSize: '12px', color: '#FF0303'}}>
                            {contactEmailValidate ? "" : intl.get('SHIPPING_LTL_QUICK_QUOTE.DIALOG.VALID_EMAIL')}
                        </Typography>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleOverWeightDialogClose}>
                        {intl.get('SHIPPING_LTL_QUICK_QUOTE.DIALOG.NO')}
                    </Button>
                    <LoadingButton
                        onClick={handleGetRateFromEmail}
                        loading={loadingCustomQuote}
                        disabled={!contactEmail || contactEmail.replace(/\s+/g, '') === ""}
                    >
                        {intl.get('SHIPPING_LTL_QUICK_QUOTE.DIALOG.CONFIRM')}
                    </LoadingButton>
                </DialogActions>
            </Dialog>
            <Dialog
                open={overSizeDialog}
                onClose={handleOverSizeDialogClose}
            >
                <DialogTitle>
                    {intl.get('SHIPPING_LTL_QUICK_QUOTE.DIALOG.GET_LTL_RATE')}
                </DialogTitle>
                <DialogContent>
                    <Box sx={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
                        <Typography>
                            {intl.get('SHIPPING_LTL_QUICK_QUOTE.DIALOG.OVER_SIZE_MESSAGE')}
                        </Typography>
                        <TextField
                            // defaultValue={contactEmail}
                            value={contactEmail}
                            onInput={handleContactEmail}
                            fullWidth
                        />
                        <Typography style={{fontSize: '12px', color: '#FF0303'}}>
                            {contactEmailValidate ? "" : intl.get('SHIPPING_LTL_QUICK_QUOTE.DIALOG.VALID_EMAIL')}
                        </Typography>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleOverSizeDialogClose}>
                        {intl.get('SHIPPING_LTL_QUICK_QUOTE.DIALOG.NO')}
                    </Button>
                    <LoadingButton
                        onClick={handleGetRateFromEmail}
                        loading={loadingCustomQuote}
                        disabled={!contactEmail || contactEmail.replace(/\s+/g, '') === ""}
                    >
                        {intl.get('SHIPPING_LTL_QUICK_QUOTE.DIALOG.CONFIRM')}
                    </LoadingButton>
                </DialogActions>
            </Dialog>
            <Dialog
                open={noLTLRateDialog}
                onClose={handleNoLTLRateDialogClose}
            >
                <DialogTitle>
                    {intl.get('SHIPPING_LTL_QUICK_QUOTE.DIALOG.GET_LTL_RATE')}
                </DialogTitle>
                <DialogContent>
                    <Box sx={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
                        <Typography>
                            {intl.get('SHIPPING_LTL_QUICK_QUOTE.DIALOG.NO_LTL_MESSAGE')}
                        </Typography>
                        <TextField
                            // defaultValue={contactEmail}
                            value={contactEmail}
                            onInput={handleContactEmail}
                            fullWidth
                        />
                        <Typography style={{fontSize: '12px', color: '#FF0303'}}>
                            {contactEmailValidate ? "" : intl.get('SHIPPING_LTL_QUICK_QUOTE.DIALOG.VALID_EMAIL')}
                        </Typography>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleNoLTLRateDialogClose}>
                        {intl.get('SHIPPING_LTL_QUICK_QUOTE.DIALOG.NO')}
                    </Button>
                    <LoadingButton
                        onClick={handleGetRateFromEmail}
                        loading={loadingCustomQuote}
                        disabled={!contactEmail || contactEmail.replace(/\s+/g, '') === ""}
                    >
                        {intl.get('SHIPPING_LTL_QUICK_QUOTE.DIALOG.CONFIRM')}
                    </LoadingButton>
                </DialogActions>
            </Dialog>
            <Dialog
                open={placeOrderDialog}
                onClose={handlePlaceOrderDialogClose}
            >
                <DialogTitle>
                    {intl.get('SHIPPING_LTL_QUICK_QUOTE.PLACE_ORDER')}
                </DialogTitle>
                <DialogContent>
                    <Box sx={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
                        <Typography>
                            {intl.get('SHIPPING_LTL_QUICK_QUOTE.DIALOG.PLACE_ORDER_MESSAGE')}
                        </Typography>
                        <TextField
                            // defaultValue={contactEmail}
                            disabled={true}
                            value={contactEmail}
                            onInput={handleContactEmail}
                            fullWidth
                        />
                        <Typography style={{fontSize: '12px', color: '#FF0303'}}>
                            {contactEmailValidate ? "" : intl.get('SHIPPING_LTL_QUICK_QUOTE.DIALOG.VALID_EMAIL')}
                        </Typography>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleNoLTLRateDialogClose}>
                        {intl.get('SHIPPING_LTL_QUICK_QUOTE.DIALOG.NO')}
                    </Button>
                    <LoadingButton
                        onClick={handlePlaceOrderFromEmail}
                        loading={loadingPlaceOrder}
                        disabled={!contactEmail || contactEmail.replace(/\s+/g, '') === ""}
                    >
                        {intl.get('SHIPPING_LTL_QUICK_QUOTE.DIALOG.CONFIRM')}
                    </LoadingButton>
                </DialogActions>
            </Dialog>
            <Box sx={styles.ShippingLTLQuickQuoteSection}>
                <Typography style={styles.ShippingPackageQuickQuoteHeading}>
                    {intl.get('QUICK_QUOTE_NAV.SELECT_TYPE')}
                </Typography>
                <Box
                    sx={{
                        width: isMobile ? '300px' : '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexWrap: 'wrap',
                        gap: '10px'
                    }}>
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        <Checkbox
                            icon={<RadioButtonUncheckedIcon/>}
                            checkedIcon={<CheckCircleIcon/>}
                            color="success"
                            onClick={() => history.push('/business/shipping-package/quick-quote')}
                        />
                        <img src={packageIcon} alt="packageIcon" width='40px'/>
                        <Typography style={{color: '#545454', padding: '0 5px'}}>
                            {intl.get('QUICK_QUOTE_NAV.PACKAGE')}
                        </Typography>
                    </Box>
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        <Checkbox
                            icon={<RadioButtonUncheckedIcon/>}
                            checkedIcon={<CheckCircleIcon/>}
                            color="success"
                            onClick={() => history.push('/business/shipping-package/international-shipment')}
                        />
                        <PublicIcon sx={{
                            fontSize: '40px'
                        }}/>
                        <Typography style={{color: '#545454', padding: '0 5px'}}>
                            {intl.get('QUICK_QUOTE_NAV.INTERNATION')}
                        </Typography>
                    </Box>
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        <Checkbox
                            icon={<RadioButtonUncheckedIcon/>}
                            checkedIcon={<CheckCircleIcon/>}
                            color="success"
                            checked
                        />
                        <img src={ltlIcon} alt="ltlIcon" width='40px'/>
                        <Typography style={{color: '#545454', padding: '0 5px'}}>
                            {intl.get('QUICK_QUOTE_NAV.LTL')}
                        </Typography>
                    </Box>
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        <Checkbox
                            icon={<RadioButtonUncheckedIcon/>}
                            checkedIcon={<CheckCircleIcon/>}
                            color="success"
                            onClick={() => history.push('/business/brokerage/estimate')}
                        />
                        <img src={brokerageIcon} alt="brokerageIcon" width='40px'/>
                        <Typography style={{color: '#545454', padding: '0 5px'}}>
                            {intl.get('QUICK_QUOTE_NAV.BROKERAGE')}
                        </Typography>
                    </Box>
                </Box>
            </Box>
            <Box sx={styles.ShippingLTLQuickQuoteSection2nd}>
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <ShippingLTLQuickQuoteAddress isGetRate={isGetRate} postalCodeValid={postalCodeValid}/>
                    </Grid>
                </Grid>
            </Box>
            <Box sx={styles.ShippingLTLQuickQuoteSection2nd}>
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <ShippingLTLQuickQuotePallet palletListRedux={palletListRedux} isGetRate={isGetRate}
                                                     hasMissingValue={hasMissingValue}/>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box sx={{display: 'flex', alignItems: 'center'}}>
                            <Typography style={{color: 'red'}}>
                                *
                            </Typography>
                            <Typography style={{textAlign: 'left', marginTop: '15px', fontSize: '12px'}}>
                                {intl.get('SHIPPING_LTL_QUICK_QUOTE.RATE_MESSAGE')}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Box sx={styles.ShippingLTLQuickQuoteSection2nd}>
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <ShippingLTLQuickQuoteShippingOptions isGetRate={isGetRate}/>
                    </Grid>
                </Grid>
            </Box>
            <Box sx={styles.ShippingLTLQuickQuoteSection2nd}>
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <ShippingLTLQuickQuoteAdditionalService isGetRate={isGetRate}/>
                    </Grid>
                </Grid>
            </Box>
            <Box sx={{
                display: 'flex',
                width: '100%',
                justifyContent: 'flex-end',
                marginBottom: '40px',
            }}
                 ref={rateSection}>
                {isGetRate ?
                    <Button
                        variant='contained'
                        sx={{
                            backgroundColor: '#F2BE22',
                            "&:hover": {
                                backgroundColor: '#F2BE22',
                                filter: 'brightness(0.9)'
                            }
                        }}
                        onClick={handleEdit}
                        disabled={confirm}
                    >
                        <Typography style={{textTransform: 'none', fontSize: '16px'}}>
                            {intl.get('SHIPPING_LTL_QUICK_QUOTE.START_OVER')}
                        </Typography>
                    </Button>
                    :
                    <LoadingButton
                        variant='contained'
                        color='success'
                        loading={loading}
                        onClick={handleGetRate}
                        disabled={palletListRedux.length === 0 ? true : false}
                    >
                        <Typography style={{textTransform: 'none', fontSize: '16px'}}>
                            {intl.get('SHIPPING_LTL_QUICK_QUOTE.GET_RATES')}
                        </Typography>
                    </LoadingButton>
                }
            </Box>
            {
                isGetRate && <>
                    <ShippingLTLQuickQuoteRate confirm={confirm}
                                               availableService={availableService}
                                               isCallAllRate={isCallAllRate}
                    />
                    {
                        (isCallAllRate === availableService?.length || rate.length === 0) &&
                        <Box sx={styles.ShippingLTLQuickQuoteSection2nd}>
                            <Grid container spacing={4}>
                                <Grid item xs={12} md={12}>
                                    <Typography style={{textAlign: 'left'}}>
                                        To receive a quote via email, kindly click on the <Link
                                        color='#1D8B45'
                                        onClick={handleOpenRequestEmail}><span
                                        style={{color: '#1D8B45'}}>Request Rate via Email</span></Link>, and
                                        the
                                        operator will promptly assist you with the information you need. To
                                        receive an accurate rate and expedite the processing time, it is
                                        recommended to provide the following information. Afterward, click
                                        'Confirm' to proceed.
                                    </Typography>
                                </Grid>
                                {
                                    (isCallAllRate === availableService?.length && rate.length === 0) &&
                                    <ShippingLTLQuickQuoteAIRate/>
                                }
                            </Grid>
                            {
                                requestRateEmail &&
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        flexDirection: 'column',
                                        gap: '20px',
                                        marginBottom: '50px',
                                        backgroundColor: '#FFFFFF',
                                        padding: '20px',
                                        borderRadius: '10px',
                                        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
                                    }}
                                >
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Typography style={{
                                                fontSize: '20px',
                                                fontWeight: '600',
                                                textAlign: 'left'
                                            }}>
                                                {intl.get('SHIPPING_LTL_QUICK_QUOTE.CONTACT_EMAIL')}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                // defaultValue={userInfo?.email}
                                                value={contactEmail}
                                                onInput={handleContactEmail}
                                                fullWidth
                                                size='small'
                                            />
                                            <Typography style={{fontSize: '12px', color: '#FF0303'}}>
                                                {contactEmailValidate ? "" : intl.get('SHIPPING_LTL_QUICK_QUOTE.DIALOG.VALID_EMAIL')}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <ShippingLTLQuickQuoteEmailRate cbHandleCommodity={cbHandleCommodity}/>
                                    <ShippingLTLQuickQuoteAdditionalService confirm={false}/>
                                    <Box sx={{
                                        display: 'flex',
                                        justifyContent: 'flex-end'
                                    }}>
                                        <LoadingButton
                                            variant='contained'
                                            onClick={handleGetRateFromEmail}
                                            loading={loadingCustomQuote}
                                            disabled={!contactEmail || contactEmail.replace(/\s+/g, '') === ""}
                                            sx={{
                                                backgroundColor: '#1D8B45',
                                                "&:hover": {
                                                    backgroundColor: '#1D8B45',
                                                    filter: 'brightness(0.9)'
                                                }
                                            }}
                                        >
                                            <Typography style={{textTransform: 'none', fontSize: '16px'}}>
                                                {intl.get('SHIPPING_LTL_QUICK_QUOTE.DIALOG.CONFIRM')}
                                            </Typography>
                                        </LoadingButton>
                                    </Box>
                                </Box>
                            }
                        </Box>
                    }
                    <ShippingLTLQuickQuoteOtherRate isCallAllRate={isCallAllRate}
                                                    allService={allService}
                                                    availableService={availableService}
                                                    handleShowOtherRate={handleShowOtherRate}/>
                </>
            }
        </Container>
    )
}