import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import * as React from "react";
import {defaultText, greenButtonContained, textContent} from "../../Utils/styleConfig";
import {Button} from "@mui/material";
import Box from "@mui/material/Box";
import {useHistory} from "react-router-dom";
import {LABEL_DOWNLOAD_URL_PREFIX_ALTERNATE} from "../../Utils/apiUrl";

export const MarketplaceStoreEach = ({store, startsWithBusiness}) => {

    const history = useHistory();

    const handleStoreManagement = () => {
        history.push(`${startsWithBusiness ? `/business/` : `/`}e-commerce/store/${store?.profile_id}`)
    }

    return (
        <>
            <Grid item xs={2}>
                <img src={`${LABEL_DOWNLOAD_URL_PREFIX_ALTERNATE}${store?.icon_uri}`}
                     alt="logo"
                    width='30px'
                     // height='20px'
                />
            </Grid>
            {/*<Grid item xs={1}>*/}
            {/*    <Typography sx={{textAlign: 'left'}}>*/}
            {/*        {store?.marketplace}*/}
            {/*    </Typography>*/}
            {/*</Grid>*/}
            <Grid item xs={4}>
                <Typography sx={{textAlign: 'left'}}>
                    {store?.store_name}
                </Typography>
            </Grid>
            <Grid item xs={4}>
                <Typography sx={{textAlign: 'left'}}>
                    {store?.send_from_address_line2 && `${store?.send_from_address_line2} - `}{store?.send_from_address}, {store?.send_from_city}, {store?.send_from_postal_code}, {store?.send_from_province}, {store?.send_from_country}
                </Typography>
            </Grid>
            <Grid item xs={2}>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'flex-start'
                }}>
                    <Button
                        variant='contained'
                        size='small'
                        onClick={handleStoreManagement}
                        sx={greenButtonContained}>
                        <Typography sx={{textTransform: 'none', fontSize: defaultText}}>
                            Manage
                        </Typography>
                    </Button>
                </Box>
            </Grid>
        </>
    )
}