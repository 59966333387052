import {Button, Container, Snackbar} from "@mui/material";
import Box from "@mui/material/Box";
import {MovingServiceAddress} from "./MovingServiceAddress";
import {MovingServiceItem} from "./MovingServiceItem";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import * as React from "react";
import MuiAlert from "@mui/material/Alert";
import {
    checkAllPropertiesHaveValueFalse, generateCarShippingQuoteRequest,
    getLocation,
    getMissingKeysForMovingAddress, getMissingKeysForMovingAddressFromItem,
    getMissingKeysForMovingCarAddress,
    getMissingKeysForMovingLocation, getMissingKeysFromObject,
    isValidPostalCode,
    isValidZipCode,
    loadAsyncScript
} from "../../Utils/Helper";
import {
    updateMovingServiceAIAssisted,
    updateMovingServiceBasicRate,
    updateMovingServiceBasicRateFromAI,
    updateMovingServiceExpressProperty,
    updateMovingServiceItemListFromAI,
    updateMovingServiceMoveFromAddressMissing,
    updateMovingServiceMoveFromCarAddressMissing,
    updateMovingServiceMoveToAddressMissing,
    updateMovingServiceMoveToCarAddressMissing
} from "../../actions/movingServiceAction";
import '@fontsource/roboto';
import {NODE_ROUTE_URI, PARTNER_URI} from "../../Utils/apiUrl";
import axios from "axios";
import {useHistory, useLocation, useParams} from "react-router-dom";
import {
    updateLandingEstimateDeliverPostalCodeValid,
    updateLandingEstimatePickupPostalCodeValid
} from "../../actions/landingEstimateAction";
import {MovingServiceAddressCar} from "./MovingServiceAddressCar";
import {MovingServiceCar} from "./MovingServiceCar";
import Checkbox from "@mui/material/Checkbox";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import {ReactComponent as USAIcon} from "../../images/homepageIcon/USA.svg";
import {ReactComponent as CanadaIcon} from "../../images/homepageIcon/CA.svg";
import PublicIcon from "@mui/icons-material/Public";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import EmojiTransportationIcon from '@mui/icons-material/EmojiTransportation';
import {MovingServiceExpress} from "./MovingServiceExpress";
import {getAccessToken} from "../../Utils/doToken";
import intl from "react-intl-universal";
import {MovingServiceRequestQuote} from "./MovingServiceRequestQuote";
import {MovingServiceRoomPlaceItemList} from "./MovingServiceRoomPlaceItemList";
import {MovingServiceList} from "./MovingServiceList";
import dayjs from "dayjs";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const styles = {
    MovingServiceRoot: {
        display: 'flex',
        flexDirection: 'column',
        gap: '30px',
        marginBottom: '40px',
    },
    MovingServiceButtonSection: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',

    },
    MovingServiceButton: {
        // width: '100%',
        // height: '100%',
        color: '#FFFFFF',
        fontSize: '16px',
        fontWeight: '600',
        padding: '10px 30px',
        borderRadius: '10px'
    },
}

const requiredItemProperties = ["length", "width", "height", "quantity", "weight", "lengthUnit", "weightUnit", "type"];
const validateRequiredItemProperties = ["length", "width", "height", "weight", "quantity"];

export const MovingService = () => {

    const {tab} = useParams();
    const {pathname} = useLocation();
    const startsWithBusiness = pathname.startsWith('/business');

    const ownerRole = useSelector(state => state?.user?.ownerRole);
    const storedToken = getAccessToken("access_token");

    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [validatedResult, setValidatedResult] = useState(null);
    const [loading, setLoading] = useState(false);
    const [AIAssisted, setAIAssisted] = useState(true);

    let movingFromAddress = useSelector(state => state?.movingService?.movingFromAddress);
    let movingToAddress = useSelector(state => state?.movingService?.movingToAddress);
    let itemList = useSelector(state => state?.movingService?.itemList);
    let movingFurnitureList = useSelector(state => state?.movingService?.movingFurnitureList);
    let vehicleList = useSelector(state => state?.movingService?.carList);
    let itemInput = useSelector(state => state?.movingService?.movingItemInput);
    let itemListFromAI = useSelector(state => state?.movingService?.movingItemListFromAI);
    let additionalService = useSelector(state => state?.movingService?.additionalService);
    let AIAssistedReducer = useSelector(state => state?.movingService?.movingAIAssisted);

    const dispatch = useDispatch();

    const history = useHistory();

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const validateMovingFromPostalCode = () => {
        if (movingFromAddress?.country === 'CA') {
            const result = isValidPostalCode(movingFromAddress?.postalCode);
            return result;
        } else {
            const result = isValidZipCode(movingFromAddress?.postalCode);
            console.log(result);
            return result;
        }
    }

    const validateMovingToPostalCode = () => {
        if (movingToAddress?.country === 'CA') {
            const result = isValidPostalCode(movingToAddress?.postalCode);
            return result;
        } else {
            const result = isValidZipCode(movingToAddress?.postalCode);
            return result;
        }
    }

    const validatePostalCode = () => {
        const movingFromPostalCode = validateMovingFromPostalCode();
        const movingToPostalCode = validateMovingToPostalCode();
        return (movingFromPostalCode && movingToPostalCode);
    }

    const validateItemList = () => {
        if (itemList.length === 0 && movingFurnitureList?.length === 0) {
            return true
        } else {
            return false
        }
    }

    const validationAddress = () => {

        const validateMovingFromMissing =
            // movingFromAddress?.option === 'self' ?
            //     getMissingKeysFromObject({
            //         warehouse: movingFromAddress?.warehouse
            //     })
            //     :
            tab === 'vehicle' ?
                getMissingKeysFromObject({
                    // streetAddress: movingFromAddress?.streetAddress,
                    city: movingFromAddress?.city,
                    province: movingFromAddress?.province,
                    country: movingFromAddress?.country,
                    postalCode: movingFromAddress?.postalCode,
                })
                :
                getMissingKeysFromObject({
                    city: movingFromAddress?.city,
                    province: movingFromAddress?.province,
                    country: movingFromAddress?.country,
                    postalCode: movingFromAddress?.postalCode,
                    propertyType: movingFromAddress?.propertyType,
                    lift: movingFromAddress?.lift,
                    floorLevel: movingFromAddress?.floorLevel,
                });

        const validateMovingToMissing =
            // movingToAddress?.option === 'self' ?
            //     getMissingKeysFromObject({
            //         warehouse: movingToAddress?.warehouse
            //     })
            //     :
            tab === 'vehicle' ?
                getMissingKeysFromObject({
                    // streetAddress: movingToAddress?.streetAddress,
                    city: movingToAddress?.city,
                    province: movingToAddress?.province,
                    country: movingToAddress?.country,
                    postalCode: movingToAddress?.postalCode,
                })
                :
                getMissingKeysFromObject({
                    city: movingToAddress?.city,
                    province: movingToAddress?.province,
                    country: movingToAddress?.country,
                    postalCode: movingToAddress?.postalCode,
                    propertyType: movingToAddress?.propertyType,
                    lift: movingToAddress?.lift,
                    floorLevel: movingToAddress?.floorLevel,
                });

        !validatePostalCode() && setErrorMessage(prevState => prevState + ' Postal code is invalid.');
        validateMovingFromMissing.length > 0 && setErrorMessage(prevState => prevState + ' Move from is missing one or more required fields.');
        validateMovingToMissing.length > 0 && setErrorMessage(prevState => prevState + ' Move to is missing one or more required fields.');

        if (!validatePostalCode() ||
            validateMovingFromMissing.length > 0 ||
            validateMovingToMissing.length > 0
        ) {
            return false
        } else {
            return true
        }

    }

    const validationItem = () => {
        console.log('[validationItem] itemInput', itemInput);

        !itemInput?.content?.trim() && setErrorMessage(prevState => prevState + ' Your item list field is empty.');
        // itemListFromAI?.length === 0 && setErrorMessage(prevState => prevState + ' Your converted item list is empty.');
        if (!itemInput?.content?.trim()) {
            return false
        } else {
            return true
        }
    }

    const validationVehicle = () => {
        console.log('[validationVehicle]');

        const hasMissingValues = vehicleList.some(object => ["make", "model", "year", "quantity"].some((property) => !object[property]));
        const hasValidateValues = vehicleList.some(object => ["quantity"].some((property) => (object[property]) <= 0));

        vehicleList?.length === 0 && setErrorMessage(prevState => prevState + 'Your vehicle list is empty.');
        hasMissingValues && setErrorMessage(prevState => prevState + ' Your vehicle list has missing one or more required fields.');
        hasValidateValues && setErrorMessage(prevState => prevState + ' Your vehicle list has one or more invalid fields.');

        if (vehicleList?.length === 0 ||
            hasMissingValues ||
            hasValidateValues
        ) {
            return false
        } else {
            return true
        }
    }

    const validationExpress = () => {
        console.log('[validationExpress]');

        const hasMissingValues = itemList.some(object => ["length", "width", "height", "quantity", "weight", "lengthUnit", "weightUnit", "type"].some((property) => !object[property]));
        const hasValidateValues = itemList.some(object => ["length", "width", "height", "weight", "quantity"].some((property) => (object[property]) <= 0));
        const hasMissingDescription = itemList.some(item => {
            // Check if cate_id is 0 and description is missing or empty
            return item.cate_id === 0 && (item.description === undefined || item?.description?.trim() === "");
        });

        validateItemList() && setErrorMessage(prevState => prevState + 'Your moving list is empty.');
        (hasMissingValues || hasMissingDescription) && setErrorMessage(prevState => prevState + ' Your item list has missing one or more required fields.');
        hasValidateValues && setErrorMessage(prevState => prevState + ' Your item list has one or more invalid fields.');

        if (validateItemList() ||
            hasMissingValues ||
            hasValidateValues ||
            hasMissingDescription
        ) {
            return false
        } else {
            return true
        }
    }

    const validation = () => {
        setErrorMessage('');
        setToastOpen(false);

        const validationAddressResult = validationAddress();

        const validationListResult = tab === 'vehicle' ?
            validationVehicle() : tab === 'item' ?
                validationItem() : validationExpress()

        console.log('[validation] - validationListResult', validationListResult);
        console.log('[validation] - validationAddressResult', validationAddressResult);

        if (validationListResult && validationAddressResult) {
            return true
        } else {
            handleErrorMessage();
            return false
        }
    }

    const addMovingCarQuotes = async () => {
        try {
            let requestURL = `${NODE_ROUTE_URI}/movingCar/addMovingCarQuotes`;

            const data = {
                accountBase: "j-link",
                shipFromCity: movingFromAddress?.city,
                shipFromProvince: movingFromAddress?.province?.code,
                shipFromCountry: movingFromAddress?.country,
                shipFromPostalCode: movingFromAddress?.postalCode,
                shipToAddress: "",
                shipToCity: movingToAddress?.city,
                shipToProvince: movingToAddress?.province?.code,
                shipToCountry: movingToAddress?.country,
                shipToPostalCode: movingToAddress?.postalCode,
                cateId: null,
                make: "TOYOTA",
                model: "COROLLA",
                productionYear: "2024",
                shippingMode: "Open Truck & Train",
                currency: "CAD",
                includeInsurance: false,
                tariffCharge: 500,
                fuelCharge: 300,
                crossBorderFee: 0,
                insuranceCharge: 0,
                taxes: 100,
                remarkNotes: "",
                expiredTime: "2024-01-20 12:00:00",
                quoteNumber: "",
                insuranceDescription: "Insurance Description",
                itemsAllowedInsideCar: "APPLE",
                shippingDaysRange: "7 - 14"
            }

            const result = await axios({
                method: 'post',
                url: requestURL,
                headers: {Authorization: `Bearer ${storedToken}`},
                data: data
            })

        } catch (e) {
            console.log('error', e?.response);
        }
    }

    const getCarQuoteByDeepSeek = async () => {
        try {
            setLoading(true);
            let requestURL = `${NODE_ROUTE_URI}/deepseek/endUser/getCarQuoteByDeepSeek`;

            const updatedCarList = vehicleList?.map(car => ({
                make: car.make,
                model: car.model,
                yearOfProduction: dayjs(car.year)?.format('YYYY'),
                categoryValue: `CAR-SIZE-${car?.size}`,
                quantity: car.quantity
            }));

            console.log('[handleGetVehicleRating] updatedCarList', updatedCarList);

            const content = generateCarShippingQuoteRequest({
                shipFromAddress: "",
                shipFromCity: movingFromAddress?.city,
                shipFromProvince: movingFromAddress?.province?.code,
                shipFromCountry: movingFromAddress?.country,
                shipFromPostalCode: movingFromAddress?.postalCode,
                shipToAddress: "",
                shipToCity: movingToAddress?.city,
                shipToProvince: movingToAddress?.province?.code,
                shipToCountry: movingToAddress?.country,
                shipToPostalCode: movingToAddress?.postalCode,
                packageData: updatedCarList
            })

            const data = {
                messages: [
                    {
                        role: "user",
                        content: content
                    }
                ],
                systemRole: "CAR_QUOTE",
                shipFromCity: movingFromAddress?.city,
                shipFromProvince: movingFromAddress?.province?.code,
                shipFromCountry: movingFromAddress?.country,
                shipToCity: movingToAddress?.city,
                shipToProvince: movingToAddress?.province?.code,
                shipToCountry: movingToAddress?.country,
                packageData: updatedCarList
            }

            const result = await axios({
                method: 'post',
                url: requestURL,
                headers: {Authorization: `Bearer ${storedToken}`},
                data: data
            })

            console.log('[getCarQuoteByDeepSeek] result', result);
            dispatch(updateMovingServiceBasicRateFromAI(result?.data));

        } catch (e) {
            console.log('error', e?.response)

        } finally {
            setLoading(false);
            history.push(`${ownerRole === 1 ? '/' : '/business/'}transportation/${tab}/review`);
            window.scrollTo(0, 0); // Scroll to the top
        }
    }

    const handleGetVehicleRating = async () => {
        try {
            setLoading(true);

            let requestURL = startsWithBusiness ? `${PARTNER_URI}/movingCar/getMovingCarRatingList` : `${NODE_ROUTE_URI}/movingCar/getMovingCarRatingList`;

            console.log('[handleGetVehicleRating] carList', vehicleList);

            const updatedCarList = vehicleList?.map(car => ({
                make: car.make,
                model: car.model,
                yearOfProduction: dayjs(car.year)?.format('YYYY'),
                categoryValue: `CAR-SIZE-${car?.size}`,
                quantity: car.quantity
            }));

            console.log('[handleGetVehicleRating] updatedCarList', updatedCarList);

            const data = {
                shipFromAddress: "",
                shipFromCity: movingFromAddress?.city,
                shipFromProvince: movingFromAddress?.province?.code,
                shipFromCountry: movingFromAddress?.country,
                shipFromPostalCode: movingFromAddress?.postalCode,
                shipToAddress: "",
                shipToCity: movingToAddress?.city,
                shipToProvince: movingToAddress?.province?.code,
                shipToCountry: movingToAddress?.country,
                shipToPostalCode: movingToAddress?.postalCode,
                packageData: updatedCarList
            }

            // console.log('[handleGetVehicleRating] data', data);

            const result = await axios({
                method: 'post',
                url: requestURL,
                headers: {Authorization: `Bearer ${storedToken}`},
                data: data
            })

            console.log('[handleGetVehicleRating] rating result', result);
            // setErrorMessage('');

            if (+result?.data?.finalTotal > 0) {
                dispatch(updateMovingServiceBasicRate(result?.data));
                setLoading(false);
                history.push(`${ownerRole === 1 ? '/' : '/business/'}transportation/${tab}/review`);
                window.scrollTo(0, 0); // Scroll to the top
            } else {
                await getCarQuoteByDeepSeek();
            }
        } catch (e) {
            console.log(e.response);
            await getCarQuoteByDeepSeek();
        }
    }

    const handleConvertToItemList = async () => {
        try {
            setLoading(true);
            let requestURL = `${NODE_ROUTE_URI}/deepSeek/admin/getMovingInformationByDeepSeek`;

            const messages = [];
            messages.push(itemInput);

            const result = await axios({
                method: 'post',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${storedToken}`
                },
                data: {
                    messages: messages,
                    systemRole: "MOVING",
                    shipFromCity: movingFromAddress?.city,
                    shipFromProvince: movingFromAddress?.province?.code,
                    shipFromPostalCode: movingFromAddress?.postalCode,
                    shipFromCountry: movingFromAddress?.country,
                    shipFromHouseType: movingFromAddress?.propertyType?.houseType,
                    shipFromHasLift: movingFromAddress?.lift !== 0,
                    shipFromFloorLevel: movingFromAddress?.floorLevel,
                    shipToCity: movingToAddress?.city,
                    shipToProvince: movingToAddress?.province?.code,
                    shipToPostalCode: movingToAddress?.postalCode,
                    shipToCountry: movingToAddress?.country,
                    shipToHouseType: movingToAddress?.propertyType?.houseType,
                    shipToHasLift: movingToAddress?.lift !== 0,
                    shipToFloorLevel: movingToAddress?.floorLevel,
                    items: itemInput?.content,
                    workers: "2",
                }
            })

            console.log('[handleConvertToItemList] result', result);
            // setItemList(result?.data?.items);
            // setError(false);
            const itemList = result?.data?.items;

            if (itemList?.length === 0) {
                history.push(`${ownerRole === 1 ? '/' : '/business/'}transportation/${tab}/review`);
                window.scrollTo(0, 0); // Scroll to the top
            } else {
                dispatch(updateMovingServiceItemListFromAI(itemList));
                await handleGetItemRating(result?.data);
            }

        } catch (e) {
            history.push(`${ownerRole === 1 ? '/' : '/business/'}transportation/${tab}/review`);
            window.scrollTo(0, 0); // Scroll to the top
        }
    }

    const handleGetItemRating = async (deepSeekData) => {
        try {
            setLoading(true);
            // let requestURL = `${NODE_ROUTE_URI}/movingService/movingItemGetGeneralRatingWithChatCompletion`;

            let requestURL = `${NODE_ROUTE_URI}/movingService/movingItemGetGeneralRatingFromAI`;

            const updatedItemList = deepSeekData?.items?.map(item => ({
                ...item,
                lengthUnit: "in",
                weightUnit: "lb",
                packageSpecialServices: {
                    wrappingService: false,
                    packingService: false,
                    assemblyService: false,
                    disassemblyService: false
                }
            }))

            const specialServices = Object.keys(additionalService)
                .filter(key => typeof additionalService[key] === 'boolean' && additionalService[key])
                .map(serviceName => ({serviceName}));

            const data = {
                items: updatedItemList,
                pickupWarehouseId: null,
                selfDropOff: movingFromAddress?.option === 'self',
                pickupAddress: "",
                pickupCity: movingFromAddress?.city,
                pickupProvince: movingFromAddress?.province?.code,
                pickupCountry: movingFromAddress?.country,
                pickupPostalCode: movingFromAddress?.postalCode,
                deliverWarehouseId: null,
                selfPickup: movingToAddress?.option === 'self',
                deliverAddress: "",
                deliverCity: movingToAddress?.city,
                deliverProvince: movingToAddress?.province?.code,
                deliverCountry: movingToAddress?.country,
                deliverPostalCode: movingToAddress?.postalCode,
                specialServices: specialServices,
                pickupHouseFeatures: {
                    houseType: movingFromAddress?.propertyType?.houseType === 'Apartment' ? 'Condo' : movingFromAddress?.propertyType?.houseType,
                    floorLevel: movingFromAddress?.floorLevel,
                    staircaseNumber: "0",
                    isNarrowStreet: movingFromAddress?.isNarrowStreet,
                    drivewaySteepLevel: movingFromAddress?.drivewaySteepLevel,
                    hasLift: !!movingFromAddress?.lift
                },
                deliverHouseFeatures: {
                    houseType: movingToAddress?.propertyType?.houseType === 'Apartment' ? 'Condo' : movingToAddress?.propertyType?.houseType,
                    floorLevel: movingToAddress?.floorLevel,
                    staircaseNumber: "0",
                    isNarrowStreet: movingToAddress?.isNarrowStreet,
                    drivewaySteepLevel: movingToAddress?.drivewaySteepLevel,
                    hasLift: !!movingToAddress?.lift
                },
                loadingTime: deepSeekData?.loadingTime,
                unloadingTime: deepSeekData?.unloadingTime,
                labourNumber: 2
            }

            const result = await axios({
                method: 'post',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${storedToken}`
                },
                data: data
            })
            // console.log(result.data);
            // setCategoryList(result.data);
            console.log('[handleGetRating] rating result', result);
            setErrorMessage('');
            // handleSuccessMessage();
            // setTimeout(() => {
            //     history.push(`/transportation/${tab}/rate`);
            // }, 1000);
            dispatch(updateMovingServiceBasicRate(result?.data));

        } catch (e) {
            console.log(e.response);
        } finally {
            setLoading(false);
            history.push(`${ownerRole === 1 ? '/' : '/business/'}transportation/${tab}/review`);
            window.scrollTo(0, 0); // Scroll to the top
        }
    }

    const handleGetBoxRating = async () => {
        try {
            setLoading(true);

            let requestURL = `${NODE_ROUTE_URI}/movingService/movingItemGetRatingForLocalMoving`;

            const updatedItemList = itemList?.map(item => ({
                description: item.category,
                cateId: item.cate_id,
                length: item.length,
                width: item.width,
                height: item.height,
                weight: item.weight,
                volume: item.volume,
                lengthUnit: item.lengthUnit,
                weightUnit: item.weightUnit,
                quantity: item.quantity,
                packageSpecialServices: item.packageSpecialServices
            }));

            const updatedFurnitureList = movingFurnitureList?.map(item => ({
                description: item.category,
                cateId: item.cate_id,
                length: item.length,
                width: item.width,
                height: item.height,
                weight: item.weight,
                volume: item.volume,
                lengthUnit: item?.lengthUnit,
                weightUnit: item?.weightUnit,
                quantity: item.quantity,
                packageSpecialServices: item.packageSpecialServices
            }));

            // console.log(updatedItemList);
            // const items = [...updatedItemList, ...updatedFurnitureList];

            const specialServices = Object.keys(additionalService)
                .filter(key => typeof additionalService[key] === 'boolean' && additionalService[key])
                .map(serviceName => ({serviceName}));

            const data = {
                items: updatedFurnitureList,
                boxList: updatedItemList,
                pickupWarehouseId: movingFromAddress?.warehouse?.id || 0,
                selfDropOff: movingFromAddress?.option === 'self',
                pickupAddress: movingFromAddress?.streetAddress,
                pickupCity: movingFromAddress?.city,
                pickupProvince: movingFromAddress?.province?.code,
                pickupCountry: movingFromAddress?.country,
                pickupPostalCode: movingFromAddress?.postalCode,
                deliverWarehouseId: movingToAddress?.warehouse?.id || 0,
                selfPickup: movingToAddress?.option === 'self',
                deliverAddress: movingToAddress?.streetAddress,
                deliverCity: movingToAddress?.city,
                deliverProvince: movingToAddress?.province?.code,
                deliverCountry: movingToAddress?.country,
                deliverPostalCode: movingToAddress?.postalCode,
                specialServices: specialServices,
                pickupHouseFeatures: {
                    houseType: movingFromAddress?.propertyType?.houseType === 'Apartment' ? 'Condo' : movingFromAddress?.propertyType?.houseType,
                    floorLevel: movingFromAddress?.floorLevel,
                    staircaseNumber: "0",
                    isNarrowStreet: movingFromAddress?.isNarrowStreet,
                    drivewaySteepLevel: movingFromAddress?.drivewaySteepLevel,
                    hasLift: !!movingFromAddress?.lift
                },
                deliverHouseFeatures: {
                    houseType: movingToAddress?.propertyType?.houseType === 'Apartment' ? 'Condo' : movingToAddress?.propertyType?.houseType,
                    floorLevel: movingToAddress?.floorLevel,
                    staircaseNumber: "0",
                    isNarrowStreet: movingToAddress?.isNarrowStreet,
                    drivewaySteepLevel: movingToAddress?.drivewaySteepLevel,
                    hasLift: !!movingToAddress?.lift
                }
            }
            const result = await axios({
                method: 'post',
                url: requestURL,
                data: data
            })
            // console.log(result.data);
            // setCategoryList(result.data);
            console.log('[handleGetRating] rating result', result);
            setErrorMessage('');
            // handleSuccessMessage();
            // setTimeout(() => {
            //     history.push(`/transportation/${tab}/rate`);
            // }, 1000);
            dispatch(updateMovingServiceBasicRate(result?.data))
        } catch (e) {
            console.log(e.response);
        } finally {
            setLoading(false);
            history.push(`${ownerRole === 1 ? '/' : '/business/'}transportation/${tab}/review`);
            window.scrollTo(0, 0); // Scroll to the top
        }
    }

    const handleNext = async () => {
        dispatch(updateMovingServiceBasicRate(null));
        dispatch(updateMovingServiceBasicRateFromAI(null));
        const validationResult = validation();
        setValidatedResult(validationResult);
        if (validationResult) {
            if (tab === 'vehicle') {
                await handleGetVehicleRating();
            } else if (tab === 'item' && AIAssisted) {
                await handleConvertToItemList();
            } else {
                await handleGetBoxRating();
            }
        }
    }

    useEffect(() => {
        setAIAssisted(AIAssistedReducer);
    }, []);

    useEffect(() => {
        dispatch(updateMovingServiceAIAssisted(AIAssisted));
    }, [AIAssisted])

    return (
        <Container maxWidth='xl'>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            {(tab === 'item' || tab === 'vehicle' || tab === 'express') &&
                <Box sx={styles.MovingServiceRoot}>
                    <MovingServiceAddress tab={tab} validatedResult={validatedResult}/>
                    {
                        tab === 'item' &&
                        <Box sx={{
                            display: 'flex',
                            gap: '20px',
                            justifyContent: 'flex-start'
                        }}>
                            <FormControl>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={AIAssisted}
                                            onChange={() => setAIAssisted(true)}
                                            style={{
                                                color: 'green',
                                                '&.Mui-checked': {
                                                    color: 'green',
                                                }
                                            }}
                                        />
                                    }
                                    label={
                                        <Typography sx={{fontSize: '18px'}}>
                                            Input-Based Completion (AI-Assisted)
                                        </Typography>
                                    }
                                />
                            </FormControl>
                            <FormControl>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={!AIAssisted}
                                            onChange={() => setAIAssisted(false)}
                                            style={{
                                                color: 'green',
                                                '&.Mui-checked': {
                                                    color: 'green',
                                                }
                                            }}
                                        />
                                    }
                                    label={
                                        <Typography sx={{fontSize: '18px'}}>
                                            Traditional Selection (Manual)
                                        </Typography>
                                    }
                                />
                            </FormControl>
                        </Box>
                    }
                    <MovingServiceList tab={tab} validatedResult={validatedResult} AIAssisted={AIAssisted}/>
                    <Box sx={styles.MovingServiceButtonSection}>
                        <LoadingButton
                            variant="contained"
                            sx={{
                                backgroundColor: '#1D8B45',
                                "&:hover": {
                                    backgroundColor: '#1D8B45',
                                    filter: 'brightness(0.9)'
                                }
                            }}
                            onClick={handleNext}
                            loading={loading}
                        >
                            <Typography style={{textTransform: 'none'}}>
                                {intl.get('BUTTON.GET_RATE')}
                            </Typography>
                        </LoadingButton>
                    </Box>
                    {
                        errorMessage &&
                        <MovingServiceRequestQuote errorMessage={errorMessage}/>
                    }
                </Box>
            }
        </Container>
    )
}