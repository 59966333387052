import {
    Button,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    MenuItem,
    Select,
    Snackbar
} from "@mui/material";
import {useHistory, useLocation, useParams} from "react-router-dom";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {
    greenButtonContained,
    greyButtonContained,
    mainHeading, sectionHeading,
    subHeading,
    textContent,
    yellowButtonContained
} from "../../Utils/styleConfig";
import * as React from "react";
import Box from "@mui/material/Box";
import LoadingButton from "@mui/lab/LoadingButton";
import TextField from "@mui/material/TextField";
import {useEffect, useState} from "react";
import {getAccessToken} from "../../Utils/doToken";
import {NODE_ROUTE_URI, PARTNER_URI} from "../../Utils/apiUrl";
import axios from "axios";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import {MuiTelInput} from "mui-tel-input";
import {extractFullAddress, getCountryCode, getMissingKeysFromObject, isValidatePhone} from "../../Utils/Helper";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {makeStyles} from "@material-ui/core/styles";
import MuiAlert from "@mui/material/Alert";
import FormHelperText from "@mui/material/FormHelperText";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const useStyles = makeStyles({
    smallInput: {
        "& .MuiInputBase-input": {
            height: "4px", // Adjust the height as per your preference
            padding: "2px", // Adjust the padding as per your preference
            fontSize: "16px", // Adjust the font size as per your preference
        }
    }
});

export const MarketplaceStoreManagement = () => {

    const classes = useStyles();
    const storedToken = getAccessToken("access_token");

    const history = useHistory();
    const {pathname} = useLocation();
    const startsWithBusiness = pathname.startsWith('/business');
    const {storeId} = useParams();

    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const [storeInfo, setStoreInfo] = useState(null);
    const [error, setError] = useState(false);
    const [sendFromAddressPredictions, setSendFromAddressPredictions] = useState([]);
    const [validation, setValidation] = useState(true);
    const [loadingUpdateStore, setLoadingUpdateStore] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [loadingDisconnectStore, setLoadingDisconnectStore] = useState(false);
    const [isDisconnect, setIsDisconnect] = useState(false);

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const handleDialogOpen = () => {
        setDialogOpen(true);
    }

    const handleDialogClose = () => {
        setDialogOpen(false);
    }

    const getMarketplaceStoreByStoreId = async () => {
        try {
            setLoading(true);
            const storedToken = getAccessToken("access_token");

            let requestURL = startsWithBusiness ? `${PARTNER_URI}/marketplace/getMarketplaceProfileByProfileId` : `${NODE_ROUTE_URI}/marketplace/getMarketplaceProfileByProfileId`;

            const result = await axios({
                method: 'get',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${storedToken}`
                },
                params: {
                    profileId: +storeId
                }
            })
            console.log('[getMarketplaceStoreByStoreId] result', result);

            setStoreInfo(preState => ({
                ...preState,
                ...result?.data,
                displayAddress: {
                    ...preState?.displayAddress,
                    description: null,
                    placeId: null,
                    text: result?.data?.send_from_address
                }
            }));
            setError(false)

        } catch (e) {
            console.log('[getMarketplaceStoreByStoreId] error', e?.response);

            setError(true);
        } finally {
            setLoading(false)
        }
    }

    const googleMapsPlaceAutocomplete = async (inputValue, type, country) => {
        console.log('[googleMapsPlaceAutocomplete] country', country);
        let requestURL = `${NODE_ROUTE_URI}/maps_apis/placeAutocompleteFromCountry`;

        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    input: inputValue,
                    types: type,
                    country: country || 'ca'
                }
            })
            const {data: {data: {predictions}}} = result;
            console.log('prediction', predictions);
            const updatedPredictions = predictions?.map(each => {
                return {
                    description: each?.description,
                    text: each?.structured_formatting?.main_text,
                    placeId: each?.place_id
                }
            })
            console.log('updated prediction', updatedPredictions);

            return updatedPredictions;

        } catch (e) {
            console.log(e.response)
        }
    }

    const googleMapsPlaceDetails = async (placeId) => {
        let requestURL = `${NODE_ROUTE_URI}/maps_apis/placeDetails`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    placeId: placeId
                }
            });
            const place = result?.data?.data?.result;
            return place;

        } catch (e) {
            console.log(e.response)
        }
    }

    const handleSendFromName = (event) => {
        setStoreInfo(prevState => ({
            ...prevState,
            send_from_name: event?.target?.value
        }))
    }

    const handleSendFromPhone = value => {
        setStoreInfo(prevState => ({
            ...prevState,
            send_from_phone: value
        }))
    }

    const handleSendFromEmail = (event) => {
        setStoreInfo(prevState => ({
            ...prevState,
            send_from_email: event?.target?.value
        }))
    }

    const handleSendFromCompany = (event) => {
        setStoreInfo(prevState => ({
            ...prevState,
            send_from_company: event?.target?.value
        }))
    }

    const handleInputChangeAddress = async (event, value) => {
        setStoreInfo(prevState => ({
            ...prevState,
            send_from_address: value,
            displayAddress: {
                description: '',
                placeId: '',
                text: value
            }
        }));
        const predictionsAddress = await googleMapsPlaceAutocomplete(value, 'address', storeInfo?.send_from_country);
        setSendFromAddressPredictions(predictionsAddress);
    }

    const handleChangeAddress = async (event, value) => {
        const place = await googleMapsPlaceDetails(value?.placeId);
        if (place) {
            const address = extractFullAddress(place);
            setStoreInfo(prevState => ({
                ...prevState,
                send_from_postal_code: address?.zip,
                send_from_city: address?.sublocality || address?.city || address?.administrative_area_level_3 || address.neighborhood || address?.region,
                send_from_province: address?.state?.code,
                send_from_country: address?.country?.code,
                displayAddress: value
            }))
        } else {
            setStoreInfo(prevState => ({
                ...prevState,
                send_from_address: '',
                displayAddress: {
                    description: '',
                    placeId: '',
                    text: ''
                },
            }))
        }
    }

    const handleSendFromAddress2 = (event) => {
        setStoreInfo(prevState => ({
            ...prevState,
            send_from_address_line2: event?.target?.value
        }))
    }

    const handleSendFromCity = (event) => {
        setStoreInfo(prevState => ({
            ...prevState,
            send_from_city: event?.target?.value
        }))
    }

    const handleSendFromProvince = (event) => {
        setStoreInfo(prevState => ({
            ...prevState,
            send_from_province: event?.target?.value
        }))
    }

    const handleSendFromCountry = (event) => {
        setStoreInfo(prevState => ({
            ...prevState,
            send_from_country: event?.target?.value
        }))
    }

    const handleSendFromPostalCode = (event) => {
        setStoreInfo(prevState => ({
            ...prevState,
            send_from_postal_code: event?.target?.value
        }))
    }

    const handleBackToStoreList = () => {
        history.push(`${startsWithBusiness ? '/business/' : '/'}e-commerce/store`)
    }

    const validate = async () => {

        setErrorMessage('');
        setToastOpen(false);

        const sendFromMissing = getMissingKeysFromObject({
            name: storeInfo?.send_from_name,
            phone: storeInfo?.send_from_phone,
            address: storeInfo?.send_from_address,
            city: storeInfo?.send_from_city,
            province: storeInfo?.send_from_province,
            country: storeInfo?.send_from_country,
            postalCode: storeInfo?.send_from_postal_code
        })

        const sendFromPhoneValidate = isValidatePhone(storeInfo?.send_from_phone);

        sendFromMissing?.length !== 0 && setErrorMessage(prevState => prevState + ' Send from is missing one or more required fields.');
        !sendFromPhoneValidate && setErrorMessage(prevState => prevState + ' Send from phone number is invalid.');

        if (sendFromMissing?.length === 0 &&
            sendFromPhoneValidate) {
            return true;
        } else {
            handleErrorMessage();
            return false;
        }
    }

    const handleUpdateStoreInformation = async () => {
        try {
            setLoadingUpdateStore(true);

            let requestURL = startsWithBusiness ? `${PARTNER_URI}/marketplace/updateMarketplaceProfileByProfileIdForUserByUserId` : `${NODE_ROUTE_URI}/marketplace/updateMarketplaceProfileByProfileIdForUserByUserId`;

            const data = {
                profileId: +storeId,
                name: storeInfo?.send_from_name,
                companyName: storeInfo?.send_from_company,
                email: storeInfo?.send_from_email,
                phone: storeInfo?.send_from_phone,
                address: storeInfo?.send_from_address,
                addressTwo: storeInfo?.send_from_address_line2,
                city: storeInfo?.send_from_city,
                province: storeInfo?.send_from_province,
                postalCode: storeInfo?.send_from_postal_code,
                country: storeInfo?.send_from_country
            }

            const result = await axios({
                method: 'post',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${storedToken}`
                },
                data: data
            })
            setErrorMessage('');
            setSuccessMessage('Store information was updated successfully');
            handleSuccessMessage();
        } catch (e) {
            setErrorMessage('Unable to update store information');
            handleErrorMessage();
        } finally {
            setLoadingUpdateStore(false)
        }
    }

    const handleUpdateShippingInformation = async () => {
        const validationResult = await validate();
        setValidation(validationResult);
        await handleUpdateStoreInformation();
    }

    const disconnectStore = async () => {
        try {
            setLoadingDisconnectStore(true);

            let requestURL = startsWithBusiness ? `${PARTNER_URI}/marketplace/deleteMarketplaceProfileByProfileId` : `${NODE_ROUTE_URI}/marketplace/deleteMarketplaceProfileByProfileId`;

            const data = {
                profileId: storeId
            }

            const result = await axios({
                method: 'post',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${storedToken}`
                },
                data: data
            })

            setErrorMessage('');
            setSuccessMessage('The store has been successfully disconnected.');
            handleSuccessMessage();
            setIsDisconnect(true);
            return true

        } catch (e) {
            setErrorMessage('Failed to disconnect the store. Please try again later.');
            handleErrorMessage();
            return false;
        } finally {
            setLoadingDisconnectStore(false)
        }
    }

    const handleDisconnectStore = async () => {
        const disconnectStoreResult = await disconnectStore();
        setIsDisconnect(disconnectStoreResult);
        if (disconnectStoreResult) {
            handleDialogClose();
        }
    }

    useEffect(() => {
        if (storeId) {
            (async () => {
                await getMarketplaceStoreByStoreId();
            })()
        }
    }, [storeId]);

    if (loading) {
        return (
            <Box sx={{display: 'flex', justifyContent: 'center', padding: '30px'}}>
                <CircularProgress/>
            </Box>
        )
    }

    if (error && !loading) {
        return <Box sx={{maxWidth: '450px', padding: '15px 30px 15px',}}>
            <Typography sx={{fontWeight: '600'}}>
                Sorry We currently encounter some issues, please try again later.
            </Typography>
        </Box>
    }

    console.log('[MarketplaceStoreManagement] storeInfo', storeInfo);

    return (
        <Container maxWidth='xl'>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Dialog
                maxWidth="sm"
                fullWidth
                open={dialogOpen}
                onClose={handleDialogClose}
            >
                <DialogTitle>
                    <Typography sx={{fontSize: sectionHeading, fontWeight: '600'}}>
                        Disconnect Store
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Typography>
                        You are about to disconnect the store. Please note that this action is final and cannot be
                        undone.
                    </Typography>
                    <Typography>
                        Would you like to proceed with the disconnection?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        gap: '10px'
                    }}>
                        <Button
                            variant='contained'
                            sx={greyButtonContained}
                            size='small'
                            onClick={handleDialogClose}
                        >
                            <Typography sx={{textTransform: 'none'}}>
                                Retain Store
                            </Typography>
                        </Button>
                        <LoadingButton
                            variant='contained'
                            sx={greenButtonContained}
                            size='small'
                            onClick={handleDisconnectStore}
                            loading={loadingDisconnectStore}
                        >
                            <Typography sx={{textTransform: 'none'}}>
                                Disconnect Store
                            </Typography>
                        </LoadingButton>
                    </Box>
                </DialogActions>
            </Dialog>
            <Box sx={{display: 'flex', flexDirection: 'column', gap: '20px', marginBottom: '40px'}}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography sx={{fontSize: mainHeading, fontWeight: '600'}}>
                            Store Management
                        </Typography>
                    </Grid>
                    {/*<Grid item xs={6}>*/}
                    {/*    <Box sx={{*/}
                    {/*        display: 'flex',*/}
                    {/*        justifyContent: 'flex-end'*/}
                    {/*    }}>*/}
                    {/*        <LoadingButton*/}
                    {/*            variant='contained'*/}
                    {/*            sx={greenButtonContained}*/}
                    {/*            // onClick={handleCloseConnectStore}*/}
                    {/*        >*/}
                    {/*            <Typography sx={{*/}
                    {/*                textTransform: 'none'*/}
                    {/*            }}>*/}
                    {/*                Sync Store Information*/}
                    {/*            </Typography>*/}
                    {/*        </LoadingButton>*/}
                    {/*    </Box>*/}
                    {/*</Grid>*/}
                </Grid>
                <Grid container spacing={2}>
                    {
                        isDisconnect ?
                            <Grid item xs={12}>
                                <Typography sx={{fontSize: subHeading}}>
                                    The store has been successfully disconnected.
                                </Typography>
                            </Grid>
                            :
                            <>
                                <Grid item xs={12}>
                                    <Typography sx={{fontWeight: '600', fontSize: subHeading}}>
                                        Store Information
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl fullWidth>
                                        <FormLabel>
                                            Store Name
                                        </FormLabel>
                                        <TextField
                                            value={storeInfo?.store_name}
                                            size='small'
                                            disabled={true}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl fullWidth>
                                        <FormLabel>
                                            Platform
                                        </FormLabel>
                                        <TextField
                                            value={storeInfo?.marketplace}
                                            size='small'
                                            disabled={true}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography sx={{fontWeight: '600', fontSize: subHeading}}>
                                        Shipping Information
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl fullWidth>
                                        <FormLabel>
                                            Name
                                        </FormLabel>
                                        <TextField
                                            value={storeInfo?.send_from_name}
                                            fullWidth
                                            size='small'
                                            onInput={handleSendFromName}
                                            // disabled={true}
                                            error={!validation && !storeInfo?.send_from_name}
                                            helperText={(!validation && !storeInfo?.send_from_name) && "Required"}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl fullWidth>
                                        <FormLabel>
                                            Phone
                                        </FormLabel>
                                        <MuiTelInput
                                            value={storeInfo?.send_from_phone}
                                            fullWidth
                                            variant="outlined"
                                            defaultCountry="CA"
                                            onChange={handleSendFromPhone}
                                            size='small'
                                            // disabled={true}
                                            error={!validation && (!storeInfo?.send_from_phone || !isValidatePhone(storeInfo?.send_from_phone))}
                                            helperText={!validation && ((!storeInfo?.send_from_phone) ? "Required" : (!isValidatePhone(storeInfo?.send_from_phone) && 'Invalid'))}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl fullWidth>
                                        <FormLabel>
                                            Email
                                        </FormLabel>
                                        <TextField
                                            value={storeInfo?.send_from_email}
                                            fullWidth
                                            size='small'
                                            onInput={handleSendFromEmail}
                                            // disabled={true}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl fullWidth>
                                        <FormLabel>
                                            Company
                                        </FormLabel>
                                        <TextField
                                            value={storeInfo?.send_from_company}
                                            fullWidth
                                            size='small'
                                            onInput={handleSendFromCompany}
                                            // disabled={true}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl fullWidth>
                                        <FormLabel>
                                            Address
                                        </FormLabel>
                                        <Autocomplete
                                            freeSolo
                                            value={storeInfo?.displayAddress}
                                            fullWidth
                                            options={sendFromAddressPredictions}
                                            filterOptions={(options, state) => options}
                                            getOptionLabel={option => option?.text}
                                            onInputChange={handleInputChangeAddress}
                                            onChange={handleChangeAddress}
                                            renderInput={params => <TextField
                                                {...params}
                                                className={classes.smallInput}
                                                variant="outlined"
                                                error={!validation && !storeInfo?.send_from_address}
                                                helperText={(!validation && !storeInfo?.send_from_address) && "Required"}
                                            />}
                                            renderOption={option => (
                                                <Typography sx={{fontSize: textContent}}>
                                                    {option.description}
                                                </Typography>
                                            )}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl fullWidth>
                                        <FormLabel>
                                            Address Two
                                        </FormLabel>
                                        <TextField
                                            value={storeInfo?.send_from_address_line2}
                                            fullWidth
                                            size='small'
                                            onInput={handleSendFromAddress2}
                                            // disabled={true}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControl fullWidth>
                                        <FormLabel>
                                            City
                                        </FormLabel>
                                        <TextField
                                            value={storeInfo?.send_from_city}
                                            fullWidth
                                            size='small'
                                            onInput={handleSendFromCity}
                                            // disabled={true}
                                            error={!validation && !storeInfo?.send_from_city}
                                            helperText={(!validation && !storeInfo?.send_from_city) && "Required"}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControl fullWidth>
                                        <FormLabel>
                                            Province
                                        </FormLabel>
                                        <TextField
                                            value={storeInfo?.send_from_province}
                                            fullWidth
                                            size='small'
                                            onInput={handleSendFromProvince}
                                            // disabled={true}
                                            error={!validation && !storeInfo?.send_from_province}
                                            helperText={(!validation && !storeInfo?.send_from_province) && "Required"}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControl fullWidth>
                                        <FormLabel>
                                            Country
                                        </FormLabel>
                                        <Select
                                            value={storeInfo?.send_from_country}
                                            size='small'
                                            onChange={handleSendFromCountry}
                                            // disabled={true}
                                            error={!validation && !storeInfo?.send_from_country}
                                            MenuProps={{
                                                PaperProps: {
                                                    style: {
                                                        maxHeight: 48 * 4.5 + 8,
                                                        // width: 250,
                                                    },
                                                },
                                            }}
                                        >
                                            {
                                                getCountryCode().map(v => {
                                                    return <MenuItem value={v.code}>{v.name}</MenuItem>
                                                })
                                            }
                                        </Select>
                                        <FormHelperText
                                            sx={{
                                                color: "error.main",
                                            }}
                                        >
                                            {(!validation && !storeInfo?.send_from_country) && 'Required'}
                                        </FormHelperText>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControl fullWidth>
                                        <FormLabel>
                                            Postal Code
                                        </FormLabel>
                                        <TextField
                                            value={storeInfo?.send_from_postal_code}
                                            fullWidth
                                            size='small'
                                            onInput={handleSendFromPostalCode}
                                            // disabled={true}
                                            error={!validation && !storeInfo?.send_from_postal_code}
                                            helperText={(!validation && !storeInfo?.send_from_postal_code) && "Required"}
                                        />
                                    </FormControl>
                                </Grid>
                            </>
                    }
                    <Grid item xs={12}>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'space-between'
                        }}>
                            <Button
                                variant='contained'
                                sx={greyButtonContained}
                                onClick={handleBackToStoreList}
                            >
                                <Typography sx={{textTransform: 'none'}}>
                                    Back to Store List
                                </Typography>
                            </Button>
                            {
                                !isDisconnect &&
                                <Box sx={{
                                    display: 'flex',
                                    gap: '10px',
                                    justifyContent: 'flex-end'
                                }}>
                                    <Button
                                        variant='contained'
                                        sx={yellowButtonContained}
                                        onClick={handleDialogOpen}
                                    >
                                        <Typography sx={{textTransform: 'none'}}>
                                            Disconnect Store
                                        </Typography>
                                    </Button>
                                    <LoadingButton
                                        variant='contained'
                                        sx={greenButtonContained}
                                        onClick={handleUpdateShippingInformation}
                                        loading={loadingUpdateStore}
                                    >
                                        <Typography sx={{textTransform: 'none'}}>
                                            Update Shipping Information
                                        </Typography>
                                    </LoadingButton>
                                </Box>
                            }
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    )
}