import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {NODE_ROUTE_URI, PARTNER_URI} from "../../../Utils/apiUrl";
import {getAccessToken} from "../../../Utils/doToken";
import axios from "axios";
import xlsx from "json-as-xlsx";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {useEffect, useRef, useState} from "react";
import {saveAs} from 'file-saver';
import dayjs from "dayjs";
import {ButtonBase, Divider, Link, Menu, MenuItem, Snackbar, Tooltip, useMediaQuery} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DownloadIcon from "@mui/icons-material/Download";
import Button from "@mui/material/Button";
import {Dialog, DialogActions, DialogContent, DialogTitle, makeStyles} from "@material-ui/core";
import TrackPackageItem from "../../Tracking/Business/TrackPackageItem";
import {CSVLink} from "react-csv";
import {ReactComponent as MultipleAddressLogo} from "../../../images/homepageIcon/MultipleAddress.svg";
import {ReactComponent as DomesticLogo} from "../../../images/homepageIcon/Domestic.svg";
import {ReactComponent as CrossBorderNoticeLogo} from "../../../images/homepageIcon/NeedToNotice.svg";
import {ReactComponent as CrossBorderReadyLogo} from "../../../images/homepageIcon/Ready-cross-border.svg";
import intl from "react-intl-universal";
import {separateTaxes} from "../../../Utils/calculateTax";
import {generateRandomString, mapCarrierLogo, mappingBackendOrderStatus} from "../../../Utils/Helper";
import {
    initShippingPackageCreateLabelPackageList,
    initShippingPackageCrossBorderItemList,
    updateShipFromPackageCreateLabelPackage,
    updateShippingPackageCreateLabelBatteryDetails,
    updateShippingPackageCreateLabelSignatureOptions, updateShippingPackageReturnLabelStatus,
    updateShipToPackageCreateLabelPackage
} from "../../../actions/BusinessShippingPackageAction";
import * as React from "react";
import Grid from "@mui/material/Grid";
import MuiAlert from "@mui/material/Alert";
import LoadingButton from "@mui/lab/LoadingButton";
import {blue, green, red, yellow} from "../../../Utils/styleConfig";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const styles = {
    ShippingOrderListTableLandingSectionLarge: {
        '@media (max-width: 480px)': {
            display: 'none'
        }
    },
    ShippingOrderListTableLandingSectionSmall: {
        display: 'none',
        '@media (max-width: 480px)': {
            display: 'flex',
            flexDirection: 'column'
        }
    },
    ShippingOrderListTableLandingText: {
        fontSize: '14px'
    },
    ShippingOrderListTableLandingMoreSection: {
        position: 'relative',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column'
    },
    ShippingOrderListTableLandingAction: {
        position: 'absolute',
        background: '#FFFFFF',
        right: 0,
        top: '100%',
        display: 'flex',
        flexDirection: 'column',
        zIndex: 1000,
        borderRadius: '5px',
        padding: '0 10px',
        boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)'
    },
    ShippingOrderListTableLandingActionEach: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
}

const useStyles = makeStyles({
    Button: {
        backgroundColor: '#1D8B45',
        color: '#FFFFFF',
        height: '100%',
        borderRadius: '5px',
        padding: '5px 25px',
        "&:hover": {
            backgroundColor: '#1D8B45',
            color: '#FFFFFF',
            filter: 'brightness(0.9)'
        },
    }
})

export const ShippingOrderListEach = ({
                                          index,
                                          order_id,
                                          order_info,
                                          partner_id,
                                          ref_order_no,
                                          cargo_control_no,
                                          account_base,
                                          total_amount,
                                          origi_shipping_item_file,
                                          created_time,
                                          currency_code,
                                          isCrossBorder,
                                          total_amount_include_tax,
                                          service_fee_include_gst,
                                          openActionNum,
                                          cbSetOpenAction,
                                          finalTotal
                                      }) => {

    const classes = useStyles();
    const storedToken = getAccessToken("access_token");

    const isMobile = useMediaQuery('(max-width: 480px)');
    const dispatch = useDispatch();

    const [transactionList, setTransactionList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [openAction, setOpenAction] = useState(false);
    const [trackingInfoDialogIsOpen, setTrackingInfoDialogIsOpen] = useState(false);
    const [trackingList, setTrackingList] = useState([]);
    // const [isUploadInvoice, setIsUploadInvoice] = useState(false);
    const [show, setShow] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [originalOrder, setOriginalOrder] = useState({});
    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [cancelOrderDialogIsOpen, setCancelOrderDialogIsOpen] = useState(false);
    const [isLoadingCancelOrder, setIsLoadingCancelOrder] = useState(false);

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleOpenShow = () => setShow(true);
    const handleCloseShow = () => setShow(false);

    const csvLink = useRef();
    const csvTrackingLink = useRef();
    const history = useHistory();

    const getTransactionListByOrderId = async () => {
        try {
            setIsLoading(true);
            const requestURI = `${PARTNER_URI}/shipping/list?&order_id=${order_id}&partner_id=${partner_id}`;
            const {data} = await axios.get(requestURI);
            const result = data.result;
            // setTotalNumber(resultTotalNumber);
            setTransactionList(result);
        } catch (e) {
            console.log(e);
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        getTransactionListByOrderId();
    }, [order_id]);

    const [transactions, setTransactions] = useState([
        [
            "Order ID",
            "Cargo Control Number",
            "Ref Order No",
            "Tran ID",
            "Carrier",
            "Client Number",
            "Product ID",
            "Exclude Tax",
            "Include Tax",
            "GST",
            "HST",
            "QST",
            "Total Brokerage Charge",
            "Total Duties Charge",
            "Total Duties Tax Charge",
            "Service Fee Exclude Tax",
            "Service Fee Include Tax",
            "Shipping Total Tax",

            "Freight Charge",
            "SurCharge",
            "Fuel Charge",
            "Collect Charge",
            "Residential Charge",
            "Insurance Charge",
            "Rural Charge",
            "Extended Area Charge",
            "Extra Care Charge",
            "Handling Charge",
            "Over Size Charge",
            "Over Weight Charge",
            "COD Charge",
            "COS Charge",
            "Dangerous Charge",
            "Surcharge Details",
            "Date",
            "Tracking #",
            "Labels",

            "Send From Name",
            // "Send From Company",
            "Send From Address",
            "Send From City",
            "Send From Province",
            "Send From Country",
            "Send From Postal Code",

            "Send To Name",
            // "Send To Company",
            "Send To Phone",
            "Send To Email",
            "Send To Address",
            "Send To City",
            "Send To Province",
            "Send To Country",
            "Send To Postal Code"
        ],
    ]);

    const [trackings, setTrackings] = useState([
        // ["Order ID", "Ref Order No", "Cargo Control Number", "Account Base", "Tracking Number", "Order Total Amount", "Transaction Amount", "Currency", "Transaction ID", "Ref Client No", "Ref Product No", "Labels", "Label URI", "Date"]
        ["Order ID", "Ref Order No", "Cargo Control Number", "Account Base", "Tracking Number", "Transaction ID", "Ref Client No", "Ref Product No", "Labels", "Label URI", "Date"]
    ])

    const excelFields = {
        "Order ID": "",
        "Cargo Control Number": "",
        "Ref Order No": "",
        "Tran ID": "",
        "Account Base": "",
        "Client ID": "",
        "Product ID": "",
        "Net Price": "",
        "Date": "",
        "Labels": "",
    }

    const toggleOpenAction = () => {
        setOpenAction(!openAction);
        cbSetOpenAction(order_id);
    }

    //handle tracking information dialog open and close
    const handleTrackingInfo = () => {
        setTrackingInfoDialogIsOpen(!trackingInfoDialogIsOpen);
    };

    const handleTrackingInfoClose = (event, reason) => {
        if (reason && reason == "backdropClick") {
            setTrackingInfoDialogIsOpen(!trackingInfoDialogIsOpen);
        }
    }

    const handleCancelOrder = async () => {
        setCancelOrderDialogIsOpen(!cancelOrderDialogIsOpen);
    }

    const handleCancelOrderClose = (event, reason) => {
        if (reason && reason == "backdropClick") {
            setCancelOrderDialogIsOpen(!cancelOrderDialogIsOpen);
        }
    }

    const cancelBackendOrders = async (orderId) => {
        setIsLoadingCancelOrder(true);
        let requestURL = `${PARTNER_URI}/loose-item/3rd-party/cancelBackendOrder/${orderId}`;
        try {
            await axios({
                method: 'delete',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${storedToken}`
                },
            })
            console.log('success');
            setErrorMessage('');
            handleSuccessMessage();
            setSuccessMessage(`Order ${orderId} was successfully deleted.`);
            setIsLoadingCancelOrder(false);
            setTimeout(() => window.location.reload(), 2000);
        } catch (e) {
            setErrorMessage(`Deletion of Order ${orderId} failed.`);
            handleErrorMessage();
            setIsLoadingCancelOrder(false);
        }
    }

    useEffect(() => {
        openActionNum !== order_id && setOpenAction(false);
    }, [openActionNum])

    const getTrackingList = async () => {
        const storedToken = getAccessToken("access_token");
        const requestURL = `${PARTNER_URI}/loose-item/3rd-party/getAllTrackingForShippingOrder`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${storedToken}`,
                },
                params: {
                    orderId: order_id
                }
            })
            console.log('result --> getTrackingList', result)
            const trackingData = result?.data?.filter((v) => v?.trackingResponse !== null);
            setTrackingList(trackingData);
        } catch (e) {
            console.log(e.response);
        }
    }

    useEffect(() => {
        getTrackingList();
    }, [])

    const getTransactionData = async () => {
        try {
            const {data} = await axios.get(`${PARTNER_URI}/shipping/list/getTransactionsByOrderId?partner_id=${partner_id}&order_id=${order_id}&account_base=${account_base}`);
            const result = data.result;
            // const resultArray = [
            //     ["Order ID", "Cargo Control Number", "Ref Order No", "Tran ID", "Account Base", "Client Number", "Product ID", "Exclude Tax", "Include Tax", "GST", "HST", "QST", "Date", "Tracking #", "Labels"],
            // ];
            const resultArray = [
                [
                    "Order ID",
                    "Cargo Control Number",
                    "Ref Order No",
                    "Tran ID",
                    "Carrier",
                    "Client Number",
                    "Product ID",
                    "Exclude Tax",
                    "Include Tax",
                    "GST",
                    "HST",
                    "QST",
                    "Total Brokerage Charge",
                    "Total Duties Charge",
                    "Total Duties Tax Charge",
                    "Service Fee Exclude Tax",
                    "Service Fee Include Tax",
                    "Shipping Total Tax",

                    "Freight Charge",
                    "SurCharge",
                    "Fuel Charge",
                    "Collect Charge",
                    "Residential Charge",
                    "Insurance Charge",
                    "Rural Charge",
                    "Extended Area Charge",
                    "Extra Care Charge",
                    "Handling Charge",
                    "Over Size Charge",
                    "Over Weight Charge",
                    "COD Charge",
                    "COS Charge",
                    "Dangerous Charge",
                    "Surcharge Details",
                    "Date",
                    "Tracking #",
                    "Labels",

                    "Send From Name",
                    // "Send From Company",
                    "Send From Address",
                    "Send From City",
                    "Send From Province",
                    "Send From Country",
                    "Send From Postal Code",

                    "Send To Name",
                    // "Send To Company",
                    "Send To Phone",
                    "Send To Email",
                    "Send To Address",
                    "Send To City",
                    "Send To Province",
                    "Send To Country",
                    "Send To Postal Code"
                ],
            ];
            for (const column of result) {
                const columnData = [];
                columnData.push(column.order_id);
                columnData.push(column.cargo_control_no);
                columnData.push(column.ref_order_no);
                columnData.push(column.tran_id);
                columnData.push(column.account_base);
                // Client ID
                columnData.push(column.ref_other_no);
                // Product ID
                columnData.push(column.ref_product_no);
                // Swap Price
                columnData.push(column.amount_gross);
                columnData.push(column.amount);

                const taxesObject = separateTaxes({
                    excludeTaxAmount: column.amount_gross,
                    includeTaxAmount: column.amount
                })
                // // GST
                // columnData.push(taxesObject.gstTax);
                // // PST
                // columnData.push(taxesObject.pstTax);
                // columnData.push(taxesObject["GST/HST"]);
                columnData.push(taxesObject.gstTax);
                columnData.push(taxesObject.hstTax);
                columnData.push(taxesObject.qstTax);

                columnData.push(column.total_brokerage_charge);
                columnData.push(column.total_duties_charge);
                columnData.push(column.total_duties_taxes_charge);
                columnData.push(column.service_fee_exclude_tax);
                columnData.push(column.service_fee_include_gst);
                columnData.push(column.shipping_total_tax);

                columnData.push(column.freight_charge);
                columnData.push(column.sur_charge);
                columnData.push(column.fuel_surcharge);
                columnData.push(column.collect_charge);
                columnData.push(column.residential_charge);
                columnData.push(column.insurance_charge);
                columnData.push(column.rural_charge);
                columnData.push(column.extended_area_charge);
                columnData.push(column.extra_care_charge);
                columnData.push(column.handling_charge);
                columnData.push(column.over_size_charge);
                columnData.push(column.over_weight_charge);
                columnData.push(column.cod_charge);
                columnData.push(column.cos_charge);
                columnData.push(column.dangerous_charge);
                columnData.push(column?.surcharge_details || "");

                columnData.push(column.tranacted_at);
                columnData.push(column.tran_tracking_nos);
                columnData.push(getLabelURL(column.label_pdf_uri, column.label_zip_uri) ? `https://storage.googleapis.com/${getLabelURL(column.label_pdf_uri, column.label_zip_uri)}` : "N/A");

                // Send From Info
                columnData.push(column.send_from_name);
                columnData.push(column.send_from_addr);
                columnData.push(column.send_from_city);
                columnData.push(column.send_from_province);
                columnData.push(column.send_from_country);
                columnData.push(column.send_from_postal_code);

                // Send To Info
                columnData.push(column.send_to_name);
                columnData.push(column.send_to_tel);
                columnData.push(column.send_to_email);
                columnData.push(column.send_to_addr);
                columnData.push(column.send_to_city);
                columnData.push(column.send_to_province);
                columnData.push(column.send_to_country);
                columnData.push(column.send_to_postal_code);

                resultArray.push(columnData);
            }
            setTransactions(resultArray);
            console.log(resultArray);
            csvLink.current.link.click()
        } catch (e) {
            console.log(e);
        }
    }

    const getTrackingsData = async () => {
        try {
            const {data} = await axios.post(`${PARTNER_URI}/shipping/list/order/trackings`, {
                partnerId: partner_id,
                orderId: order_id,
            });
            const result = data.result;
            const resultArray = [];
            for (const column of result) {
                const columnData = [];
                columnData.push(order_id);
                columnData.push(ref_order_no);
                columnData.push(cargo_control_no);
                columnData.push(account_base);
                columnData.push(column.tracking_no);
                // columnData.push(column.total_amount);
                // columnData.push(column.amount);
                // columnData.push(column.currency_code);
                columnData.push(column.tran_id);
                // Client ID
                columnData.push(column.ref_other_no);
                // Product ID
                columnData.push(column.ref_product_no);
                columnData.push(getLabelURL(column.label_pdf_uri, column.label_zip_uri) ? `https://storage.googleapis.com/${getLabelURL(column.label_pdf_uri, column.label_zip_uri)}` : "N/A");
                columnData.push(column.label_uri ? `https://storage.googleapis.com/${column.label_uri}` : "N/A");
                columnData.push(column.tranacted_at);

                resultArray.push(columnData);
            }
            setTrackings(v => {
                const temp = [...v];
                temp.push(...resultArray);
                return temp;
            });
            console.log(resultArray);
            csvTrackingLink.current.link.click()
        } catch (e) {
            console.log(e);
        }
    }

    const getLabelURL = (pdf, zip) => {
        if (pdf) {
            return pdf;
        }
        if (zip) {
            return zip;
        }
        return "";
    }

    const getTransactionDataExcel = async () => {
        try {
            const {data} = await axios.get(`${PARTNER_URI}/shipping/list/getTransactionsByOrderId?partner_id=${partner_id}&order_id=${order_id}&account_base=${account_base}`);
            const result = data.result;
            const resultArray = [];
            for (const column of result) {
                const columnData = {...excelFields};
                columnData["Order ID"] = order_id;
                columnData["Cargo Control Number"] = column.cargo_control_no;
                columnData["Ref Order No"] = ref_order_no;
                columnData["Tran ID"] = column.tran_id;
                columnData["Account Base"] = account_base;
                columnData["Client ID"] = column.ref_other_no;
                columnData["Product ID"] = column.ref_product_no;
                columnData["Net Price"] = column.amount;
                columnData["Date"] = column.tranacted_at;
                // columnData["Label PDF"] = column.label_pdf_uri ? `=HYPERLINK("https://storage.googleapis.com/${column.label_pdf_uri}")` : "N/A";
                // columnData["ZIP Label"] = column.label_zip_uri ? `=HYPERLINK("https://storage.googleapis.com/${column.label_zip_uri}")` : "N/A";
                columnData["Labels"] = getLabelURL(column.label_pdf_uri, column.label_zip_uri) ? `https://storage.googleapis.com/${getLabelURL(column.label_pdf_uri, column.label_zip_uri)}` : "N/A";
                resultArray.push(columnData);
            }
            console.log(resultArray);
            const excelBody = [
                {
                    sheet: "Transactions",
                    columns: [
                        {
                            label: "Order ID",
                            value: "orderId"
                        },
                        {
                            label: "Cargo Control Number",
                            value: "cargoControlNumber"
                        },
                        {
                            label: "Ref Order No",
                            value: "refOrderNumber"
                        },
                        {
                            label: "Tran ID",
                            value: "tranId"
                        },
                        {
                            label: "Account Base",
                            value: "accountBase"
                        },
                        {
                            label: "Client ID",
                            value: "clientId",
                        },
                        {
                            label: "Product ID",
                            value: "productId"
                        },
                        {
                            label: "Net Price",
                            value: "netPrice"
                        },
                        {
                            label: "Date",
                            value: "date"
                        },
                        {
                            label: "Labels",
                            value: "labels"
                        }
                    ],
                    content: resultArray.map(v => {
                        return {
                            orderId: v["Order ID"],
                            cargoControlNumber: v["Cargo Control Number"],
                            refOrderNumber: v["Ref Order No"],
                            tranId: v["Tran ID"],
                            accountBase: v["Account Base"],
                            clientId: v["Client ID"],
                            productId: v["Product ID"],
                            netPrice: v["Net Price"],
                            date: v["Date"],
                            labels: v["Labels"]
                        }
                    })
                }
            ]
            let settings = {
                fileName: `${order_id}-${ref_order_no}`,
            }
            // excelLink.current.link.click()
            xlsx(excelBody, settings)
        } catch (e) {
            console.log(e);
        }
    }
    const goToCrossBorderPage = () => {
        history.push(`/commercial-invoice/${order_id}`);
    }

    const downloadLabelIntoZip = async () => {
        try {
            const accessToken = getAccessToken("access_token");
            const {data} = await axios.get(`${PARTNER_URI}/shipping/list/getLabelZipByOrderId?partner_id=${partner_id}&order_id=${order_id}&account_base=${account_base}`, {
                responseType: "blob",
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
            const blobData = new Blob([data], {
                type: "application/zip"
            });
            saveAs(blobData, `${partner_id}-${order_id}.zip`);
            // console.log("labels: ", labels);
            // saveZip("my_project_files_to_download.zip", labels);
        } catch (e) {
            console.log(e);
        }
    }

    const getAdjustOrderDetails = async () => {
        const storedToken = getAccessToken("access_token");

        let requestURL = `${PARTNER_URI}/invoices/getPartnerShippingAdjustOrderDetailsByShippingOrderId`;

        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${storedToken}`
                },
                params: {
                    orderId: order_id,
                }
            })
            // console.log('result', result);

            setOriginalOrder(result?.data?.trackings[0]?.originalShippingOrderInfo);

        } catch (e) {
            console.log('error', e.response);
        }
    }

    const getOrderData = async (tranId) => {
        const storedToken = getAccessToken("access_token");
        try {

            const requestURI = `${PARTNER_URI}/shipping/list/getCloneShipmentInfoByTranId`;
            const result = await axios({
                method: 'get',
                url: requestURI,
                headers: {
                    Authorization: `Bearer ${storedToken}`,
                },
                params: {
                    tranId: tranId
                }
            })

            return result?.data;

        } catch (e) {
            console.log('[ReturnLabel] getOrderData api error', e?.response);
        }
    }

    const handleReturnOrCloneLabel = async (isReturn) => {
        const orderData = await getOrderData(order_info?.transactions[0].tran_id);
        console.log('[handleCreateCloneLabel] order data', orderData);
        const packageList = orderData?.packageData?.map(element => {
            const updatedElement = {
                ...element,
                packageInfoType: "customBox",
                packageId: generateRandomString(8)
            };
            return updatedElement;
        })
        const shipFrom = {
            postalCode: orderData?.shipFromPostalCode,
            province: {
                code: orderData?.shipFromProvince,
                name: orderData?.shipFromProvince
            },
            city: orderData?.shipFromCity,
            address: orderData?.shipFromAddress,
            address2: orderData?.shipFromAddressTwo,
            country: orderData?.shipFromCountry,
            displayAddress: {
                description: "",
                placeId: "",
                text: orderData?.shipFromAddress
            },
            name: orderData?.shipFromName,
            phone: orderData?.shipFromPhone,
            email: orderData?.shipFromEmail,
            company: orderData?.shipFromCompanyName,
            saveAddress: false,
            addressList: {
                name: orderData?.shipFromName,
                phone: orderData?.shipFromPhone,
                email: orderData?.shipFromEmail,
                address_email: orderData?.shipFromEmail,
                company_name: orderData?.shipFromCompanyName,
                city: null,
                province: null,
                country: null
            }
        };
        const shipTo = {
            postalCode: orderData?.shipToPostalCode,
            province: {
                code: orderData?.shipToProvince,
                name: orderData?.shipToProvince
            },
            city: orderData?.shipToCity,
            address: orderData?.shipToAddress,
            address2: orderData?.shipToAddressTwo,
            country: orderData?.shipToCountry,
            displayAddress: {
                description: "",
                placeId: "",
                text: orderData?.shipToAddress
            },
            name: orderData?.shipToName,
            phone: orderData?.shipToPhone,
            email: orderData?.shipToEmail,
            company: orderData?.shipToCompanyName,
            saveAddress: false,
            addressList: {
                name: orderData?.shipToName,
                phone: orderData?.shipToPhone,
                email: orderData?.shipToEmail,
                address_email: orderData?.shipToEmail,
                company_name: orderData?.shipToCompanyName,
                city: null,
                province: null,
                country: null
            }
        };
        if (orderData?.packingList?.length > 0) {
            const itemList = orderData?.packingList?.map(item => ({
                description: item?.item_desc,
                sku: item?.item_sku,
                weight: item?.weight,
                weightUnit: item?.weight_unit,
                UOM: item?.uom?.toUpperCase(),
                hscode: item?.hs_code,
                originCountryCode: item?.origi_country,
                currency: item?.currency_code,
                priceEach: item?.unit_price,
                quantity: item?.quantity,
                itemId: generateRandomString(8)
            }));
            dispatch(initShippingPackageCrossBorderItemList(itemList));
        }
        dispatch(initShippingPackageCreateLabelPackageList(packageList));
        dispatch(updateShipFromPackageCreateLabelPackage(shipFrom));
        dispatch(updateShipToPackageCreateLabelPackage(shipTo));
        dispatch(updateShippingPackageCreateLabelSignatureOptions(orderData?.packageData?.[0]?.packageSpecialServices?.signatureRequired));
        dispatch(updateShippingPackageCreateLabelBatteryDetails(orderData?.packageData?.[0]?.packageSpecialServices?.batteryDetails));
        dispatch(updateShippingPackageReturnLabelStatus(isReturn));
        history.push('/business/shipping-package/create-label');
        window.scrollTo(0, 0); // Scroll to the top
    }

    const handleCreateLabel = async () => {
        await handleReturnOrCloneLabel(true);
    }

    const handleCloneLabel = async () => {
        await handleReturnOrCloneLabel(false);
    }

    const handleOrderDetails = (order_info) => {
        if (+order_info?.is_adjust_charge === 1) {
            history.push(`/business/adjustOrderDashboard/${order_info?.order_id}`);
        } else if (+order_info?.status_id === 1 || +order_info?.status_id === 200) {
            history.push(`/business/package-order-dashboard/${order_info?.order_id}`);
        } else {
            history.push(`/business/shippingOrderDetails/${order_info?.backend_order_id}`);
        }
    }

    useEffect(() => {

        order_info?.is_adjust_charge && getAdjustOrderDetails();

    }, [order_info])

    console.log('order_info', order_info);

    return (
        <Box>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Dialog
                open={trackingInfoDialogIsOpen}
                onClose={handleTrackingInfoClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle
                    id="alert-dialog-title">{intl.get('HOME_PAGE.SHIPPING_ORDER.ACTION.TRACKING_DETAILS')}</DialogTitle>
                <DialogContent>
                    {trackingList?.length === 0 ? <Box>{intl.get('HOME_PAGE.SHIPPING_ORDER.ACTION.NO_RESULTS')}</Box> :
                        trackingList.map((trackedPackage, index) => {
                            return (
                                <TrackPackageItem
                                    key={index}
                                    activity={
                                        trackedPackage?.trackingResponse
                                            ? trackedPackage?.trackingResponse?.activity
                                            : undefined
                                    }
                                    trackingNo={trackedPackage?.trackingNumber}
                                    courier={trackedPackage?.accountBase}
                                    deliverStatus={trackedPackage?.trackingResponse?.status}
                                    transaction={trackedPackage?.transaction}
                                />
                            );
                        })}
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleTrackingInfo}
                        variant="outlined"
                        autoFocus
                        type="button"
                    >
                        {intl.get('HOME_PAGE.SHIPPING_ORDER.ACTION.CLOSE')}
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={cancelOrderDialogIsOpen}
                onClose={handleCancelOrderClose}
            >
                <DialogTitle>
                    {intl.get('HOME_PAGE.SHIPPING_ORDER.CANCEL_ORDER_DIALOG_TITLE')}
                </DialogTitle>
                <DialogContent>
                    {/*{intl.get('HOME_PAGE_DIALOG_CONTENT')}*/}
                    {intl.get('HOME_PAGE.SHIPPING_ORDER.CANCEL_ORDER_DIALOG_CONTENT')}
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleCancelOrder}
                        variant="contained"
                        autoFocus
                        className={classes.Button}
                    >
                        {intl.get('HOME_PAGE.SHIPPING_ORDER.CANCEL_ORDER_DIALOG_CLOSE')}
                    </Button>
                    <LoadingButton
                        onClick={() => cancelBackendOrders(order_info?.backend_order_id)}
                        variant="contained"
                        autoFocus
                        className={classes.Button}
                        loading={isLoadingCancelOrder}
                    >
                        {intl.get('HOME_PAGE.SHIPPING_ORDER.CANCEL_ORDER_DIALOG_CONFIRM')}
                    </LoadingButton>
                </DialogActions>
            </Dialog>
            <Grid container spacing={1}>
                <Grid item md={2.5} xs={4}>
                    <Grid container spacing={1}>
                        {!isMobile &&
                            <Grid item xs={4}>
                                <img
                                    src='https://storage.googleapis.com/uucargo-resource/image/icon/UUCargoLogo.png'
                                    alt="logo"
                                    height={40}
                                />
                            </Grid>
                        }
                        <Grid item md={8} xs={12}>
                            <Box
                                onClick={() => handleOrderDetails(order_info)}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'flex-start',
                                    height: '100%'
                                }}>
                                <Box sx={{display: 'flex', alignItems: 'center', gap: '5px'}}>
                                    <Link
                                        href={(+order_info?.is_adjust_charge === 1) ? `/business/adjustOrderDashboard/${order_id}` :
                                            (+order_info?.status_id === 1 || +order_info?.status_id === 200) ? `/business/package-order-dashboard/${order_id}` :
                                                `/business/shippingOrderDetails/${order_info?.backend_order_id}`}
                                        underline="hover"
                                        sx={{
                                            color: (+order_info?.status_id) === 0 ? yellow : (+order_info?.status_id) === 1 ? green : (+order_info?.status_id) === 2 ? red : blue,
                                            '&:hover': {
                                                textDecoration: 'underline',
                                                textDecorationColor: (+order_info?.status_id) === 0 ? yellow : (+order_info?.status_id) === 1 ? green : (+order_info?.status_id) === 2 ? red : blue, // Underline color on hover
                                            },
                                        }}
                                    >
                                        <Typography style={{
                                            fontSize: '14px',
                                            fontWeight: '600',
                                            textAlign: 'left',
                                            width: '100%',
                                            color: (+order_info?.status_id) === 0 ? yellow : (+order_info?.status_id) === 1 ? green : (+order_info?.status_id) === 2 ? red : blue,
                                        }}>
                                            {(+order_info?.status_id) === 1 ? `${order_info?.order_id}` : `${order_info?.backend_order_id}`}
                                        </Typography>
                                    </Link>
                                </Box>
                                <Typography style={{
                                    fontSize: '12px',
                                    fontWeight: '600',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap', // Ensures the text doesn't wrap to the next line
                                    width: '100%',
                                    textAlign: 'left'
                                }}>
                                    {ref_order_no}
                                </Typography>
                                <Typography style={{
                                    fontSize: '12px',
                                    textAlign: 'left',
                                    width: '100%'
                                }}>
                                    {dayjs(created_time).format("YYYY-MM-DD")}
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
                {
                    !isMobile &&
                    <Grid item md={2}>
                        <Grid item xs={12}>
                            <Box sx={{display: 'flex', alignItems: 'center', height: '100%'}}>
                                <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                                    {(order_info?.is_adjust_charge && +order_info?.is_adjust_charge !== 0) ? originalOrder?.send_from_name : order_info?.send_from_name}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box sx={{display: 'flex', alignItems: 'flex-start', height: '100%'}}>
                                {
                                    (!order_info?.is_adjust_charge || +order_info?.is_adjust_charge === 0) ?
                                        <Typography style={{fontSize: '12px', textAlign: 'left'}}>
                                            {order_info?.send_from_city}, {order_info?.send_from_province}, {order_info?.send_from_country}, {order_info?.send_from_postal_code}
                                        </Typography>
                                        :
                                        originalOrder
                                            ?
                                            <Typography style={{fontSize: '12px', textAlign: 'left'}}>
                                                {originalOrder?.send_from_city}, {originalOrder?.send_from_province}, {originalOrder?.send_from_postal_code}
                                            </Typography> :
                                            <Typography style={{fontSize: '12px', textAlign: 'left'}}>
                                                N/A
                                            </Typography>
                                }
                            </Box>
                        </Grid>
                    </Grid>
                }
                {
                    !isMobile &&
                    <Grid item md={2}>
                        {order_info?.isMultipleAddress ? <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-start'
                                }}
                            >
                                <MultipleAddressLogo width='50' height='50' fill='#1D8B45'/>
                            </Box>
                            :
                            <>
                                <Grid item xs={12}>
                                    <Box sx={{display: 'flex', alignItems: 'center', height: '100%'}}>
                                        <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                                            {(order_info?.is_adjust_charge && +order_info?.is_adjust_charge !== 0) ? originalOrder?.send_to_name : order_info?.send_to_name}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box sx={{display: 'flex', alignItems: 'flex-start', height: '100%'}}>
                                        {
                                            (!order_info?.is_adjust_charge || +order_info?.is_adjust_charge === 0) ?
                                                <Typography style={{fontSize: '12px', textAlign: 'left'}}>
                                                    {order_info?.send_to_city}, {order_info?.send_to_province}, {order_info?.send_to_country}, {order_info?.send_to_postal_code}
                                                </Typography>
                                                :
                                                originalOrder
                                                    ?
                                                    <Typography style={{fontSize: '12px', textAlign: 'left'}}>
                                                        {originalOrder?.send_to_city}, {originalOrder?.send_to_province}, {originalOrder?.send_to_postal_code}
                                                    </Typography> :
                                                    <Typography style={{fontSize: '12px', textAlign: 'left'}}>
                                                        N/A
                                                    </Typography>
                                        }
                                    </Box>
                                </Grid>
                            </>
                        }
                    </Grid>

                }
                <Grid item md={1} xs={3}>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        height: '100%',
                        justifyContent: 'center',
                        width: '100%'
                    }}>
                        <Typography style={{
                            fontSize: '10px',
                            textAlign: 'center',
                            color: '#FFFFFF',
                            borderRadius: '5px',
                            padding: '5px',
                            width: '80%',
                            backgroundColor: (+order_info?.status_id) === 0 ? yellow : (+order_info?.status_id) === 1 ? green : (+order_info?.status_id) === 2 ? red : blue
                        }}>
                            {mappingBackendOrderStatus(+order_info?.status_id, order_info?.crm_invoice_no)}
                        </Typography>
                    </Box>
                </Grid>
                {
                    !isMobile &&
                    <Grid item md={1} xs={3}>
                        <Tooltip
                            title={((+order_info?.status_id) === 0 || (+order_info?.status_id) === 3) ? "" : (!order_info?.isCrossBorder || order_info?.isCrossBorderPushed) ? 'Ready for Shipping' : 'Complete Your Cross Border Information'}
                            placement="bottom"
                        >
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: '100%',
                            }}>
                                {
                                    !order_info?.isCrossBorder ?
                                        <DomesticLogo width="35" height="35"/>
                                        : order_info?.isCrossBorderPushed ?
                                            <CrossBorderReadyLogo width="40" height="40"/>
                                            :
                                            <IconButton
                                                onClick={() => {
                                                    // history.push(`/commercial-invoice/${order_id}`);
                                                    history.push(`/business/cross-border-dashboard/${order_id}`);
                                                }}
                                            >
                                                <CrossBorderNoticeLogo width="40" height="40"/>
                                            </IconButton>
                                }
                            </Box>
                        </Tooltip>
                    </Grid>
                }
                {
                    !isMobile &&
                    <Grid item md={1} xs={3}>
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '100%'
                        }}>
                            <img src={mapCarrierLogo(order_info?.account_base?.toUpperCase())} alt="logo"
                                 width='40px'/>
                        </Box>
                    </Grid>
                }
                <Grid item md={1.5} xs={3}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%'
                    }}>
                        <Typography style={{fontSize: '14px'}}>
                            $ {finalTotal?.toFixed(2)}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item md={1} xs={2}>
                    <Box sx={styles.ShippingOrderListTableLandingMoreSection}>
                        <IconButton
                            onClick={handleClick}
                            disabled={+order_info?.status_id === 3 || +order_info?.status_id === 2}
                        >
                            <MoreVertIcon/>
                        </IconButton>
                        {
                            (+order_info?.status_id === 200 || +order_info?.status_id === 1) ?
                                <Menu
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose}
                                >
                                    <MenuItem
                                        onClick={() => {
                                            handleTrackingInfo();
                                            handleClose();
                                        }}
                                    >
                                        <Typography sx={styles.ShippingOrderListTableLandingText}>
                                            {intl.get('HOME_PAGE.SHIPPING_ORDER.ACTION.TRACKING_INFO')}
                                        </Typography>
                                    </MenuItem>
                                    <MenuItem>
                                        <Typography sx={styles.ShippingOrderListTableLandingText}>
                                            {intl.get('HOME_PAGE.SHIPPING_ORDER.ACTION.TRANSACTION')}
                                        </Typography>
                                        <IconButton onClick={() => {
                                            getTransactionData();
                                            handleClose();
                                        }}>
                                            <DownloadIcon fontSize="small"/>
                                        </IconButton>
                                        <CSVLink
                                            data={transactions}
                                            filename={`${order_id}-${ref_order_no}.csv`}
                                            className='hidden'
                                            ref={csvLink}
                                            target='_blank'
                                        />
                                    </MenuItem>
                                    <MenuItem>
                                        <Typography sx={styles.ShippingOrderListTableLandingText}>
                                            {intl.get('HOME_PAGE.SHIPPING_ORDER.ACTION.TRACKING')}
                                        </Typography>
                                        <IconButton onClick={() => {
                                            getTrackingsData();
                                            handleClose();
                                        }}>
                                            <DownloadIcon fontSize="small"/>
                                        </IconButton>
                                        <CSVLink
                                            data={trackings}
                                            filename={`${order_id}-${ref_order_no}-trackings.csv`}
                                            className='hidden'
                                            ref={csvTrackingLink}
                                            target='_blank'
                                        />
                                    </MenuItem>
                                    <MenuItem>
                                        <Typography sx={styles.ShippingOrderListTableLandingText}>
                                            {intl.get('HOME_PAGE.SHIPPING_ORDER.ACTION.LABELS')}
                                        </Typography>
                                        <IconButton onClick={downloadLabelIntoZip}>
                                            <DownloadIcon fontSize="small"/>
                                        </IconButton>
                                    </MenuItem>
                                    {
                                        (!order_info?.isMultipleAddress && +order_info?.transactions?.[0]?.is_return_label === 0) &&
                                        <MenuItem onClick={handleCreateLabel}>
                                            <Typography sx={styles.ShippingOrderListTableLandingText}>
                                                Create Return Label
                                            </Typography>
                                        </MenuItem>
                                    }
                                    {
                                        (!order_info?.isMultipleAddress && +order_info?.transactions?.[0]?.is_return_label === 0) &&
                                        <MenuItem onClick={handleCloneLabel}>
                                            <Typography sx={styles.ShippingOrderListTableLandingText}>
                                                Clone Label
                                            </Typography>
                                        </MenuItem>
                                    }
                                </Menu>
                                :
                                <Menu
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose}
                                >
                                    <MenuItem sx={{width: '100%', height: '100%'}}
                                              onClick={() => history.push(`/business/shippingOrderDetails/${order_info?.backend_order_id}`)}>
                                        <Typography sx={styles.ShippingOrderListTableLandingText}>
                                            {intl.get('HOME_PAGE.SHIPPING_ORDER.ACTION.PLACE_ORDER')}
                                        </Typography>
                                    </MenuItem>
                                    <MenuItem sx={{width: '100%', height: '100%'}}
                                              onClick={handleCancelOrder}>
                                        <Typography sx={styles.ShippingOrderListTableLandingText}>
                                            {intl.get('HOME_PAGE.SHIPPING_ORDER.ACTION.CANCEL_ORDER')}
                                        </Typography>
                                    </MenuItem>
                                </Menu>
                        }
                    </Box>
                </Grid>
                <Grid item md={12} xs={12}>
                    <Divider/>
                </Grid>
            </Grid>
        </Box>

    )
}