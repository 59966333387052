import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {PARTNER_URI} from "../../../Utils/apiUrl";
import axios from "axios";
import React, {Fragment, useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {Divider} from "@mui/material";
import CircularProgress from "@material-ui/core/CircularProgress";
import Box from "@mui/material/Box";

export const ShippingLTLQuickQuoteAIRate = () => {

    let moveFrom = useSelector(state => state?.shippingLTL?.quickQuoteMoveFrom);
    let moveTo = useSelector(state => state?.shippingLTL?.quickQuoteMoveTo);
    let palletListRedux = useSelector(state => state?.shippingLTL?.quickQuotePalletList);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [AIRate, setAIRate] = useState(null);

    const getLTLQuoteByDeepSeek = async () => {
        try {
            setLoading(true);
            let requestURL = `${PARTNER_URI}/shippingLTL/getLTLQuoteByDeepSeek`;

            let exchangedPackageData = palletListRedux.map(item => {
                let {length, width} = item;
                if (parseInt(length) < parseInt(width)) {
                    return {
                        ...item,
                        length: width,
                        width: length
                    }
                }
                return item;
            })

            const data = {
                shipFromCity: moveFrom?.city,
                shipFromProvince: moveFrom?.province?.code,
                shipFromCountry: moveFrom?.country,
                shipFromPostalCode: moveFrom?.postalCode,
                shipToCity: moveTo?.city,
                shipToProvince: moveTo?.province,
                shipToCountry: moveTo?.country,
                shipToPostalCode: moveTo?.postalCode,
                transitMode: [
                    "rail",
                    "road",
                    "Intermodal"
                ],
                accountBase: "Vitran",
                packageData: exchangedPackageData,
                sendNotifyEmail: false,
                userEmail: "",
                userPhone: ""
            }

            console.log('[getLTLRatingByOneService] api data', data);

            const result = await axios({
                method: 'post',
                url: requestURL,
                data: data
            })

            console.log('[getLTLQuoteByDeepSeek] result', result);

            setAIRate(result?.data?.ltlQuote);
            setError(false);
        } catch (e) {
            console.log('error', e?.response);
            setError(true);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        (async () => {
            await getLTLQuoteByDeepSeek();
        })()
    }, [])

    return (
        <>
            {
                loading ?
                    <Grid item xs={12}>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            width: '100%'
                        }}>
                            <CircularProgress/>
                        </Box>
                    </Grid>
                    :
                    (AIRate?.length > 0 || !error) &&
                    <Grid item xs={12}>
                        <Grid container spacing={0}>
                            <Grid item xs={12}>
                                <Typography>
                                    The estimated cost is for reference only and may be subject to change as shown
                                    below.
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography>
                                    Transit Mode
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography>
                                    Transit Days
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography>
                                    Cost
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider/>
                            </Grid>
                            {
                                AIRate?.map((rate, index) => (
                                    <Fragment key={index}>
                                        <Grid item xs={4}>
                                            <Typography>
                                                {rate?.transitMode?.toUpperCase()}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography>
                                                {rate?.transit_days || '2 - 10'} days
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography>
                                                $ {(+rate?.average_price)?.toFixed(2)} CAD
                                            </Typography>
                                        </Grid>
                                    </Fragment>
                                ))
                            }
                        </Grid>
                    </Grid>
            }
        </>
    )
}