import {
    TOGGLE_E_COMMERCE_CREATE_SHIPPING_LABEL,
    TOGGLE_E_COMMERCE_FULFILL_ITEMS, UPDATE_E_COMMERCE_FULFILLMENT_NOTIFICATION,
    UPDATE_E_COMMERCE_ITEM_LIST,
    UPDATE_E_COMMERCE_PROFILE_ID
} from "../actions/types";

const initialState = {
    itemList: [],
    profileId: '',
    isCreateShippingLabel: null,
    isFulfillItems: null,
    fulfillmentNotification: true
}

export const eCommerceReducer = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_E_COMMERCE_ITEM_LIST:
            return {...state, itemList: action?.payload}
        case UPDATE_E_COMMERCE_PROFILE_ID:
            return {...state, profileId: action?.payload}
        case TOGGLE_E_COMMERCE_CREATE_SHIPPING_LABEL:
            return {...state, isCreateShippingLabel: action?.payload}
        case TOGGLE_E_COMMERCE_FULFILL_ITEMS:
            return {...state, isFulfillItems: action?.payload}
        case UPDATE_E_COMMERCE_FULFILLMENT_NOTIFICATION:
            return {...state, fulfillmentNotification: action?.payload}
        default:
            return state
    }
}