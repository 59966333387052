import {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    extractAddress,
    extractFullAddress,
    isPositiveInteger,
    loadAsyncScript,
    provinces,
    provinces_states
} from "../../Utils/Helper";
import {updateLandingEstimatePallet} from "../../actions/landingEstimateAction";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import {PARTNER_URI} from "../../Utils/apiUrl";
import axios from "axios";
import Paper from "@mui/material/Paper";
import Autocomplete from "@material-ui/lab/Autocomplete";
import intl from "react-intl-universal";
import Grid from "@material-ui/core/Grid";
import FormControl from "@mui/material/FormControl";
import {FormLabel} from "@mui/material";

const style = {
    EstimatePackageRoot: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '10px'
    },
    EstimatePackageHeader: {
        fontSize: '16px',
        fontWeight: '600'
    },
    EstimatePackageItem: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '10px',
        width: '100%',
    },
    EstimatePackageItemSection: {
        display: 'flex',
        flexDirection: 'row',
        gap: '10px',
        alignItems: 'flex-end',
        width: '100%',
        '@media (max-width: 480px)': {
            flexDirection: 'column',
        }
    },
    EstimatePalletItemSectionBox: {
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        width: '100%',
    },
    EstimatePalletItemSectionWarningText: {
        fontSize: '12px',
        color: '#FF0303'
    }
}

const packagingTypeOptions = [
    {
        value: '48" x 40"',
        code: 1,
        weight: 'max 500lb',
        length: '48',
        width: '40',
        height: '55'
    },
    {
        value: '48" x 48"',
        code: 2,
        weight: 'max 500lb',
        length: '48',
        width: '48',
        height: '55'
    }];

export const EstimatePallet = ({currentLocation}) => {

    const dispatch = useDispatch();

    let missingKeys = useSelector(state => state?.landingEstimate?.landingEstimatePalletMissing);

    const [quantity, setQuantity] = useState(1);
    const [palletData, setPalletData] = useState({
        quantity: 1,
        packagingType: {
            value: '48" x 40"',
            code: 1,
            weight: 'max 400lb',
            length: '48',
            width: '40',
            height: '60'
        },
        pickupCity: null,
        pickupProvince: null,
        pickupCountry: null,
        pickupPostalCode: null,
        pickupDisplay: null,
        deliverCity: null,
        deliverProvince: null,
        deliverCountry: null,
        deliverPostalCode: null,
        deliverDisplay: null
    });
    const [movingFromPostalCodePredictions, setMovingFromPostalCodePredictions] = useState([]);
    const [movingToPostalCodePredictions, setMovingToPostalCodePredictions] = useState([]);

    const googleMapsPlaceAutocomplete = async (inputValue, type) => {
        let requestURL = `${PARTNER_URI}/googleMaps/placeAutocomplete`;

        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    input: inputValue,
                    types: type
                }
            })
            const {data: {data: {predictions}}} = result;
            const updatedPredictions = predictions?.map(each => {
                return {
                    description: each?.description,
                    text: each?.structured_formatting?.main_text,
                    placeId: each?.place_id
                }
            })

            return updatedPredictions;
        } catch (e) {
            console.log(e.response)
        }
    }

    const googleMapsPlaceDetails = async (placeId) => {
        let requestURL = `${PARTNER_URI}/googleMaps/placeDetails`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    placeId: placeId
                }
            });
            const place = result?.data?.data?.result;
            return place;

        } catch (e) {
            console.log(e.response)
        }
    }

    const handleChangeShipFromPostalCode = async (event, value) => {
        console.log('select', value);
        const place = await googleMapsPlaceDetails(value?.placeId);
        if (place) {
            const address = extractFullAddress(place);
            console.log('address', address);
            setPalletData(prevState => ({
                ...prevState,
                pickupCity: address?.city,
                pickupProvince: address?.state,
                pickupCountry: address?.country,
                pickupPostalCode: address?.zip,
                pickupDisplay: value
            }))
        } else {
            setPalletData(prevState => ({
                ...prevState,
                pickupCity: "",
                pickupProvince: "",
                pickupCountry: "",
                pickupPostalCode: "",
                pickupDisplay: {
                    ...prevState?.pickupDisplay,
                    description: '',
                    placeId: '',
                    text: ''
                }
            }))
        }
    }

    const handleChangeShipToPostalCode = async (event, value) => {
        console.log('select', value);
        const place = await googleMapsPlaceDetails(value?.placeId);
        if (place) {
            const address = extractFullAddress(place);
            console.log('address', address);
            setPalletData(prevState => ({
                ...prevState,
                deliverCity: address?.city,
                deliverProvince: address?.state,
                deliverCountry: address?.country,
                deliverPostalCode: address?.zip,
                deliverDisplay: value
            }))
        } else {
            setPalletData(prevState => ({
                ...prevState,
                deliverCity: "",
                deliverProvince: "",
                deliverCountry: "",
                deliverPostalCode: "",
                deliverDisplay: {
                    ...prevState?.deliverDisplay,
                    description: '',
                    placeId: '',
                    text: ''
                }
            }))
        }
    }

    const handleInputShipFromPostalCode = async (event, value) => {
        setPalletData(prevState => ({
            ...prevState,
            pickupPostalCode: value
        }))
        const predictions = await googleMapsPlaceAutocomplete(value, 'postal_code');
        console.log('handleInputShipFromPostalCode - predictions', predictions);
        setMovingFromPostalCodePredictions(predictions);
    }

    const handleInputShipToPostalCode = async (event, value) => {
        setPalletData(prevState => ({
            ...prevState,
            deliverPostalCode: value
        }))
        const predictions = await googleMapsPlaceAutocomplete(value, 'postal_code');
        console.log('handleInputShipFromPostalCode - predictions', predictions);
        setMovingToPostalCodePredictions(predictions);
    }

    useEffect(() => {
        dispatch(updateLandingEstimatePallet(palletData));
    }, [palletData])

    console.log('[EstimatePallet] pallet data', palletData);
    console.log('[EstimatePallet] missing keys', missingKeys);

    return (
        <Box sx={style.EstimatePackageRoot}>
            <Box sx={style.EstimatePackageItem}>
                <Typography style={style.EstimatePackageHeader}>
                    {intl.get('LANDING_PAGE.CALCULATOR.SHIPPING_PALLET')}
                </Typography>
                <Grid container spacing={2}>
                    <Grid item md={3} xs={6}>
                        <TextField
                            value={'48" x 40"'}
                            label={intl.get('LANDING_PAGE.CALCULATOR.TYPE')}
                            required
                            fullWidth
                            variant="standard"
                            disabled={true}
                        />
                    </Grid>
                    <Grid item md={3} xs={6}>
                        <TextField
                            value={'60"'}
                            label={intl.get('LANDING_PAGE.CALCULATOR.HEIGHT')}
                            required
                            fullWidth
                            variant="standard"
                            disabled={true}
                        />
                    </Grid>
                    <Grid item md={3} xs={6}>
                        <TextField
                            value={'400 lbs'}
                            label={intl.get('LANDING_PAGE.CALCULATOR.WEIGHT')}
                            required
                            fullWidth
                            variant="standard"
                            disabled={true}
                        />
                    </Grid>
                    <Grid item md={3} xs={6}>
                        <Box sx={style.EstimatePalletItemSectionBox}>
                            <TextField
                                value={quantity}
                                label={intl.get('LANDING_PAGE.CALCULATOR.QUANTITY')}
                                type="number"
                                required
                                fullWidth
                                variant="standard"
                                disabled={true}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Grid container spacing={2}>
                <Grid item md={12} xs={12}>
                    <Box sx={style.EstimatePackageItem}>
                        <Typography style={style.EstimatePackageHeader}>
                            {intl.get('LANDING_PAGE.CALCULATOR.PICKUP')}
                        </Typography>
                        <Box sx={style.EstimatePackageItemSection}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Box sx={style.EstimatePalletItemSectionBox}>
                                        <Autocomplete
                                            options={movingFromPostalCodePredictions}
                                            getOptionLabel={option => option?.description}
                                            onInputChange={handleInputShipFromPostalCode}
                                            onChange={handleChangeShipFromPostalCode}
                                            fullWidth
                                            PaperComponent={props => <Paper {...props} sx={{width: 400}}/>}
                                            renderInput={params => <TextField
                                                {...params}
                                                label={intl.get('LANDING_PAGE.CALCULATOR.POSTAL_CODE')}
                                                required
                                                variant='standard'
                                            />}
                                            renderOption={(option) => (
                                                <Typography
                                                    style={{fontSize: '12px'}}
                                                >
                                                    {option.description}
                                                </Typography>
                                            )}
                                        />
                                        <Typography sx={style.EstimatePalletItemSectionWarningText}>
                                            {(missingKeys.length > 0 && !palletData?.pickupPostalCode) && intl.get('LANDING_PAGE.CALCULATOR.REQUIRED')}
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Grid>
                <Grid item md={12} xs={12}>
                    <Box sx={style.EstimatePackageItem}>
                        <Typography style={style.EstimatePackageHeader}>
                            {intl.get('LANDING_PAGE.CALCULATOR.DESTINATION')}
                        </Typography>
                        <Box sx={style.EstimatePackageItemSection}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Box sx={style.EstimatePalletItemSectionBox}>
                                        <Autocomplete
                                            options={movingToPostalCodePredictions}
                                            getOptionLabel={option => option?.description}
                                            onInputChange={handleInputShipToPostalCode}
                                            onChange={handleChangeShipToPostalCode}
                                            fullWidth
                                            PaperComponent={props => <Paper {...props} sx={{width: 400}}/>}
                                            renderInput={params => <TextField
                                                {...params}
                                                label={intl.get('LANDING_PAGE.CALCULATOR.POSTAL_CODE')}
                                                required
                                                variant='standard'
                                            />}
                                            renderOption={(option) => (
                                                <Typography
                                                    style={{fontSize: '12px'}}
                                                >
                                                    {option.description}
                                                </Typography>
                                            )}
                                        />
                                        <Typography sx={style.EstimatePalletItemSectionWarningText}>
                                            {(missingKeys.length > 0 && !palletData?.deliverPostalCode) && intl.get('LANDING_PAGE.CALCULATOR.REQUIRED')}
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}