import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import {defaultText, green, greenButtonContained, greenButtonOutlined, sectionHeading} from "../../Utils/styleConfig";
import {Button, MenuItem, Select} from "@mui/material";
import Box from "@mui/material/Box";
import {useState} from "react";
import FormControl from "@mui/material/FormControl";
import {incoterms, isValidURL} from "../../Utils/Helper";
import * as React from "react";
import TextField from "@mui/material/TextField";
import LoadingButton from "@mui/lab/LoadingButton";
import {NODE_ROUTE_URI, PARTNER_URI} from "../../Utils/apiUrl";
import axios from "axios";
import {getAccessToken} from "../../Utils/doToken";

export const MarketplaceConnectStore = () => {

    const [marketplace, setMarketplace] = useState("");
    const [shopName, setShopName] = useState("");
    const [storeURL, setStoreURL] = useState("");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");

    const handleChangeMarketPlace = (event) => {
        setMarketplace(event?.target?.value);
    }

    const handleChangeShopName = (event) => {
        setShopName(event?.target?.value);
    }

    const handleChangeStoreURL = (event) => {
        setStoreURL(event?.target?.value);
    }

    const generateMarketplaceAccessRequestURL = async () => {
        try {
            const token = getAccessToken("access_token");

            setLoading(true);

            let requestURL = `${PARTNER_URI}/marketplace/generateMarketplaceAccessRequestURL`;

            let data = {
                marketplace: marketplace,
                shopName: shopName,
                // token: token,
                storeURL: storeURL
            }

            const result = await axios({
                method: 'post',
                url: requestURL,
                headers: {Authorization: `Bearer ${token}`},
                data: data
            })

            console.log('[generateMarketplaceAccessRequestURL] result', result)
            setError('');
            window.location.href = result?.data?.result;
        } catch (e) {
            console.log('[generateMarketplaceAccessRequestURL] e response', e?.response);
            setError(e?.response);
        } finally {
            setLoading(false)
        }
    }

    const handleContinueConnect = async () => {
        await generateMarketplaceAccessRequestURL()
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography sx={{fontSize: sectionHeading, fontWeight: '600', textAlign: 'center'}}>
                    Connect Store
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    height: '100%'
                }}>
                    <Typography sx={{fontWeight: '600'}}>
                        Marketplace
                    </Typography>
                </Box>
            </Grid>
            <Grid item xs={6}>
                <FormControl fullWidth>
                    <Select
                        value={marketplace}
                        onChange={handleChangeMarketPlace}
                        style={{fontSize: defaultText}} // font size of input text
                        size='small'
                    >
                        <MenuItem value='shopify'>
                            Shopify
                        </MenuItem>
                        <MenuItem value='wix'>
                            Wix
                        </MenuItem>
                        <MenuItem value='woocommerce'>
                            WooCommerce
                        </MenuItem>
                        <MenuItem value='amazon'>
                            Amazon
                        </MenuItem>
                        <MenuItem value='ebay'>
                            eBay
                        </MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            {
                marketplace && (
                    marketplace === 'shopify' ?
                        <>
                            <Grid item xs={6}>
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    height: '100%'
                                }}>
                                    <Typography sx={{fontWeight: '600'}}>
                                        Shop Name
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl fullWidth>
                                    <TextField
                                        value={shopName}
                                        onInput={handleChangeShopName}
                                        size='small'
                                        disabled={!marketplace}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-end'
                                }}>
                                    <LoadingButton
                                        variant='contained'
                                        sx={greenButtonContained}
                                        disabled={!shopName?.trim()}
                                        onClick={handleContinueConnect}
                                        loading={loading}
                                    >
                                        <Typography sx={{
                                            textTransform: 'none'
                                        }}>
                                            Continue
                                        </Typography>
                                    </LoadingButton>
                                </Box>
                            </Grid>
                        </>
                        :
                        marketplace === 'wix' ?
                            <Grid item xs={12}>
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-end'
                                }}>
                                    <LoadingButton
                                        variant='contained'
                                        sx={greenButtonContained}
                                        onClick={handleContinueConnect}
                                        loading={loading}
                                    >
                                        <Typography sx={{
                                            textTransform: 'none'
                                        }}>
                                            Continue
                                        </Typography>
                                    </LoadingButton>
                                </Box>
                            </Grid>
                            :
                            marketplace === 'woocommerce' ?
                                <>
                                    <Grid item xs={6}>
                                        <Box sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            height: '100%'
                                        }}>
                                            <Typography sx={{fontWeight: '600'}}>
                                                Store URL
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormControl fullWidth>
                                            <TextField
                                                value={storeURL}
                                                onInput={handleChangeStoreURL}
                                                size='small'
                                                disabled={!marketplace}
                                                placeholder="https://mystore.com"
                                                error={storeURL && !isValidURL(storeURL)}
                                                helperText={(storeURL && !isValidURL(storeURL)) && "Please entered valid URL"}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Box sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            height: '100%'
                                        }}>
                                            <Typography sx={{fontWeight: '600'}}>
                                                Shop Name
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormControl fullWidth>
                                            <TextField
                                                value={shopName}
                                                onInput={handleChangeShopName}
                                                size='small'
                                                disabled={!marketplace}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box sx={{
                                            display: 'flex',
                                            justifyContent: 'flex-end'
                                        }}>
                                            <LoadingButton
                                                variant='contained'
                                                sx={greenButtonContained}
                                                disabled={!storeURL?.trim()}
                                                onClick={handleContinueConnect}
                                                loading={loading}
                                            >
                                                <Typography sx={{
                                                    textTransform: 'none'
                                                }}>
                                                    Continue
                                                </Typography>
                                            </LoadingButton>
                                        </Box>
                                    </Grid>
                                </>
                                :
                                <Grid item xs={12}>
                                    <Typography sx={{
                                        fontSize: sectionHeading,
                                        textAlign: 'center',
                                        fontWeight: '600'
                                    }}>
                                        Coming Soon...
                                    </Typography>
                                    <Typography sx={{
                                        fontSize: defaultText,
                                        textAlign: 'center'
                                    }}>
                                        We're building something amazing. Stay tuned!
                                    </Typography>
                                </Grid>

                )
            }
            {
                error &&
                <Grid item xs={12}>
                    <Typography>
                        Failed to connect to the store. Please check your shop name, credentials, and internet
                        connection, or try again later.
                    </Typography>
                </Grid>
            }
        </Grid>
    )
}