import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import {checkHSCodeInValid, getCountryCode} from "../../Utils/Helper";
import {InputAdornment, Link, MenuItem, Select} from "@mui/material";
import HelpIcon from "@material-ui/icons/Help";
import Box from "@mui/material/Box";
import SearchIcon from "@material-ui/icons/Search";
import {HS_CODE_LOOKUP_URL} from "../../Utils/consInfo";
import TextField from "@mui/material/TextField";
import FormHelperText from "@mui/material/FormHelperText";
import {useEffect, useState} from "react";
import {americaProvinceCodeToNameMap, canadaProvinceCodeToNameMap} from "../shared";
import {useDispatch, useSelector} from "react-redux";
import {updateLandingEstimateCrossBorder} from "../../actions/landingEstimateAction";

export const EstimateDutyTax = ({errorList}) => {

    const dispatch = useDispatch();

    let missingKeys = useSelector(state => state?.landingEstimate?.landingEstimateCrossBorderMissing);

    // const CanadianProvinceList = Object.entries(canadaProvinceCodeToNameMap).map(([key, value]) => [key, value]);
    // const AmericanStateList = Object.entries(americaProvinceCodeToNameMap).map(([key, value]) => [key, value]);

    const [crossBorder, setCrossBorder] = useState({
        tranMode: "INT_TRUCK",
        shipmentType: "COMMERCIAL",
        exportCountryCode: 'CN',
        importCountryCode: 'CA',
        importProvinceCode: 'BC'
    });
    const [itemInfo, setItemInfo] = useState({
        description: `item`,
        sku: '',
        weight: null,
        weightUnit: 'lb',
        UOM: 'BOX',
        hscode: null,
        originCountryCode: 'CN',
        currency: 'CAD',
        priceEach: null,
        quantity: '1',
    });

    const handleExportCountryCode = event => {
        setCrossBorder(prevState => ({
            ...prevState,
            exportCountryCode: event.target.value
        }))
    }

    const handleImportCountryCode = event => {
        setCrossBorder(prevState => ({
            ...prevState,
            importCountryCode: event.target.value,
            importProvinceCode: event.target.value === 'CA' ? 'BC' : event.target.value === 'US' ? 'CA' : ''
        }))
    }

    const handleImportProvinceCode = event => {
        setCrossBorder(prevState => ({
            ...prevState,
            importProvinceCode: event.target.value
        }))
    }

    const handleHSCode = event => {
        const {value} = event.target;

        // Regular expression to allow only numbers and periods
        const regex = /^[0-9.]+$/;
        if (value === '' || regex.test(value)) {
            setItemInfo(prevState => ({
                ...prevState,
                hscode: value
            }))
        }
    }

    const handleWeight = event => {
        const {value} = event.target;
        if (/^\d*\.?\d*$/.test(value)) {
            setItemInfo(prevState => ({
                ...prevState,
                weight: value
            }))
        }
    }

    const handleQuantity = event => {
        setItemInfo(prevState => ({
            ...prevState,
            quantity: event.target.value
        }))
    }

    const handlePrice = event => {
        const {value} = event.target;
        if (/^\d*\.?\d*$/.test(value)) {
            setItemInfo(prevState => ({
                ...prevState,
                priceEach: value
            }))
        }
    }

    const handleCountryCode = event => {
        setItemInfo(prevState => ({
            ...prevState,
            originCountryCode: event.target.value
        }))
    }

    useEffect(() => {
        dispatch(updateLandingEstimateCrossBorder({crossBorder, itemInfo}))
    }, [crossBorder, itemInfo])

    console.log('[EstimateDutyTax] missingKeys', missingKeys);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography sx={{
                    fontSize: '16px',
                    fontWeight: '600'
                }}>
                    Duty & Tax
                </Typography>
            </Grid>
            <Grid item md={6} xs={12}>
                <FormControl fullWidth>
                    <FormLabel required sx={{fontSize: '14px', textAlign: 'left'}}>
                        HS Code
                    </FormLabel>
                    <TextField
                        value={itemInfo?.hscode}
                        size='small'
                        inputProps={{style: {fontSize: 14}}}
                        onInput={handleHSCode}
                        error={(missingKeys?.length > 0 && !itemInfo?.hscode) || checkHSCodeInValid(errorList, itemInfo?.hscode)}
                        helperText={(missingKeys?.length > 0 && !itemInfo?.hscode) ? 'Required' :
                            checkHSCodeInValid(errorList, itemInfo?.hscode) && 'HS Code is invalid'
                        }
                    />
                </FormControl>
            </Grid>
            <Grid item md={6} xs={12}>
                <FormControl fullWidth>
                    <FormLabel required sx={{fontSize: '14px', textAlign: 'left'}}>
                        Origin Country
                    </FormLabel>
                    <Select
                        value={itemInfo?.originCountryCode}
                        size='small'
                        style={{fontSize: 14, textAlign: 'left'}} // font size of input text
                        onChange={handleCountryCode}
                    >
                        {
                            getCountryCode()?.map(v => {
                                return <MenuItem value={v.code}>{v.name}</MenuItem>
                            })
                        }
                    </Select>
                </FormControl>
            </Grid>
            <Grid item md={4} xs={12}>
                <FormControl fullWidth>
                    <FormLabel required sx={{fontSize: '14px', textAlign: 'left'}}>
                        Quantity
                    </FormLabel>
                    <TextField
                        value={itemInfo?.quantity}
                        onInput={handleQuantity}
                        size='small'
                        inputProps={{style: {fontSize: 14}}} // font size of input text
                        type='number'
                        InputProps={{
                            style: {fontSize: 14}
                        }}
                        error={missingKeys?.length > 0 && itemInfo?.quantity <= 0}
                        helperText={
                            (missingKeys?.length > 0 && itemInfo?.quantity <= 0) && "Missing or less than 1"
                        }
                    >
                    </TextField>
                </FormControl>
            </Grid>
            <Grid item md={4} xs={12}>
                <FormControl fullWidth>
                    <FormLabel required sx={{fontSize: '14px', textAlign: 'left'}}>
                        Unit Weight
                    </FormLabel>
                    <TextField
                        value={itemInfo?.weight}
                        onInput={handleWeight}
                        size='small'
                        inputProps={{style: {fontSize: 14}}} // font size of input text
                        type='number'
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Typography sx={{fontSize: '14px'}}>
                                        {itemInfo?.weightUnit}
                                    </Typography>
                                </InputAdornment>
                            ),
                            style: {fontSize: 14}
                        }}
                        error={missingKeys?.length > 0 && itemInfo?.weight <= 0}
                        helperText={
                            (missingKeys?.length > 0 && itemInfo?.weight <= 0) && "Missing or less than 1"
                        }
                    >
                    </TextField>
                </FormControl>
            </Grid>
            <Grid item md={4} xs={12}>
                <FormControl fullWidth>
                    <FormLabel required sx={{fontSize: '14px', textAlign: 'left'}}>
                        Unit Price
                    </FormLabel>
                    <TextField
                        value={itemInfo?.priceEach}
                        size='small'
                        inputProps={{style: {fontSize: 14}}} // font size of input text
                        type='number'
                        onInput={handlePrice}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Typography sx={{fontSize: '14px'}}>
                                        {itemInfo?.currency}
                                    </Typography>
                                </InputAdornment>
                            ),
                            style: {fontSize: 14}
                        }}
                        error={missingKeys?.length > 0 && itemInfo?.priceEach <= 0}
                        helperText={
                            (missingKeys?.length > 0 && itemInfo?.priceEach <= 0) && "Missing or less than 1"
                        }
                    >
                    </TextField>
                </FormControl>
            </Grid>
            <Grid item md={6} xs={12}>
                <FormControl fullWidth>
                    <FormLabel required sx={{fontSize: '14px', textAlign: 'left'}}>
                        Export Country
                    </FormLabel>
                    <Select
                        value={crossBorder?.exportCountryCode}
                        size='small'
                        style={{fontSize: 14, textAlign: 'left'}} // font size of input text
                        onChange={handleExportCountryCode}
                        // error={!itemInfo?.originCountryCode}
                    >
                        {
                            getCountryCode()?.map(v => {
                                return <MenuItem value={v.code}>{v.name}</MenuItem>
                            })
                        }
                    </Select>
                </FormControl>
            </Grid>
            <Grid item md={6} xs={12}>
                <FormControl fullWidth>
                    <FormLabel required sx={{fontSize: '14px', textAlign: 'left'}}>
                        Import Country
                    </FormLabel>
                    <Select
                        value={crossBorder?.importCountryCode}
                        size='small'
                        style={{fontSize: 14, textAlign: 'left'}} // font size of input text
                        onChange={handleImportCountryCode}
                        // error={!itemInfo?.originCountryCode}
                    >
                        {
                            getCountryCode()?.map(v => {
                                return <MenuItem value={v.code}>{v.name}</MenuItem>
                            })
                        }
                    </Select>
                </FormControl>
            </Grid>
        </Grid>
    )
}