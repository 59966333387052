import React, {Fragment, useEffect, useState} from "react";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {carManufactures, numArray} from "../../Utils/Helper";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {makeStyles} from "@material-ui/core/styles";
import dayjs from "dayjs";
import {InputAdornment} from "@material-ui/core";

const useStyles = makeStyles({
    smallInput: {
        "& .MuiInputBase-input": {
            height: "0px", // Adjust the height as per your preference
            padding: "2px", // Adjust the padding as per your preference
            fontSize: "14px", // Adjust the font size as per your preference
        }
    }
});

export const WordPressQuoteCarList = ({cbHandleShippingDetails, validatedResult, lng}) => {

    const classes = useStyles();

    const [vehicleDetail, setVehicleDetail] = useState({
            make: null,
            model: null,
            year: null
        }
    );

    const handleInputCarMake = async (event, value) => {
        setVehicleDetail(prevState => ({
            ...prevState,
            make: value
        }));
    }

    const handleChangeCarMake = async (event, value) => {
        setVehicleDetail(prevState => ({
            ...prevState,
            make: value
        }));
    }

    const handleModel = event => {
        const {value} = event.target;
        setVehicleDetail(prevState => ({
            ...prevState,
            model: value
        }))
    }

    const handleYear = (event, value) => {
        setVehicleDetail(prevState => ({
            ...prevState,
            year: value
        }))
    }

    useEffect(() => {
        cbHandleShippingDetails(vehicleDetail);
    }, [vehicleDetail]);

    return (
        <Fragment>
            <Grid item md={4} xs={12}>
                <FormControl fullWidth>
                    <FormLabel required>
                        {lng === 'cn' ? '车的品牌' : "Make"}
                    </FormLabel>
                    <Autocomplete
                        freeSolo
                        value={vehicleDetail?.make}
                        options={carManufactures.filter((option) =>
                            option.toLowerCase().includes(vehicleDetail?.make?.toLowerCase() || "")
                        )}
                        onInputChange={handleInputCarMake}
                        onChange={handleChangeCarMake}
                        PaperComponent={props => <Paper {...props} sx={{width: '100%'}}/>}
                        renderInput={params => <TextField
                            {...params}
                            variant='outlined'
                            className={classes.smallInput}
                            error={validatedResult !== null && !vehicleDetail?.make}
                            helperText={(validatedResult !== null && !vehicleDetail?.make) && (lng === 'cn' ? "必填" : "Required")}
                        />}
                        renderOption={(option) => (
                            <Typography
                                style={{fontSize: '14px'}}
                            >
                                {option}
                            </Typography>
                        )}
                    />
                </FormControl>
            </Grid>
            <Grid item md={4} xs={12}>
                <FormControl fullWidth>
                    <FormLabel required>
                        {lng === 'cn' ? '车的型号' : "Model"}
                    </FormLabel>
                    <TextField
                        value={vehicleDetail?.model}
                        onInput={handleModel}
                        fullWidth
                        variant='outlined'
                        size='small'
                        InputProps={{
                            inputProps: {
                                min: 1
                            },
                            style: {fontSize: 14} // font size of input text
                        }}
                        error={validatedResult !== null && !vehicleDetail?.model}
                        helperText={(validatedResult !== null && !vehicleDetail?.model) && (lng === 'cn' ? "必填" : "Required")}
                    />
                </FormControl>
            </Grid>
            <Grid item md={4} xs={12}>
                <FormControl fullWidth>
                    <FormLabel required>
                        {lng === 'cn' ? '车的年份' : "Year"}
                    </FormLabel>
                    <Autocomplete
                        value={vehicleDetail?.year}
                        fullWidth
                        options={numArray(1996, new Date().getFullYear() + 1, 'desc')}
                        getOptionLabel={option => option}
                        onChange={handleYear}
                        renderInput={(params) => <TextField
                            variant="outlined"
                            {...params}
                            className={classes.smallInput}
                            error={validatedResult !== null && !vehicleDetail?.year}
                            helperText={(validatedResult !== null && !vehicleDetail?.year) && (lng === 'cn' ? "必填" : "Required")}
                        />}
                        renderOption={option => (
                            <Typography style={{fontSize: '14px'}}>
                                {option}
                            </Typography>
                        )}
                    />
                </FormControl>
            </Grid>
        </Fragment>
    )
}