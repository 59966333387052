import {Button, Container, Snackbar} from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {useSelector} from "react-redux";
import {MovingServiceRate} from "./MovingServiceRate";
import Divider from "@mui/material/Divider";
import * as React from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import {NODE_ROUTE_URI, PARTNER_URI} from "../../Utils/apiUrl";
import {getAccessToken} from "../../Utils/doToken";
import axios from "axios";
import {
    updateMovingServiceBasicRate,
    updateMovingServiceSummaryContactInfoMissing
} from "../../actions/movingServiceAction";
import {Fragment, useEffect, useState} from "react";
import MuiAlert from "@mui/material/Alert";
import {useHistory, useLocation, useParams} from "react-router-dom";
import {MovingServiceCreditCard} from "./MovingServiceCreditCard";
import {MovingServiceExpressRate} from "./MovingServiceExpressRate";
import {MovingServiceExpressIncludedServices} from "./MovingServiceExpressIncludedServices";
import intl from "react-intl-universal";
import dayjs from "dayjs";
import {ReactComponent as ShipmentOverviewIcon} from "../../images/NewDesignIcon/ShipmentOverview.svg";
import {ReactComponent as ShippingFromAndToIcon} from "../../images/NewDesignIcon/ShippingLocation.svg";
import {ReactComponent as PackageDetailIcon} from "../../images/NewDesignIcon/Package.svg";
import {ReactComponent as SchedulePickupIcon} from "../../images/NewDesignIcon/SchedulePickup.svg";
import {ReactComponent as RateDetailsIcon} from "../../images/NewDesignIcon/RateDetails.svg";
import {ReactComponent as ContactIcon} from "../../images/NewDesignIcon/AddressBook.svg";
import {ReactComponent as PackageTypeIcon} from "../../images/NewDesignIcon/AdditionalDetails.svg";
import {ReactComponent as PaidRuleIcon} from "../../images/NewDesignIcon/ChooseCarrierAndServices.svg";
import {ReactComponent as MapIcon} from "../../images/earth.svg";
import {ReactComponent as NoteIcon} from "../../images/NewDesignIcon/OtherInfo.svg";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import ImageIcon from '@mui/icons-material/Image';
import {
    convertCubicInToCubicMeter,
    convertCubicMeterToCubicIn,
    convertKGToLB,
    convertLBToKG,
    formatCurrency, getMissingKeysForMovingContact, isValidateEmail
} from "../../Utils/Helper";
import {selectCreateLabelShippingPackageRate} from "../../actions/shippingPackageAction";
import Carousel from "react-material-ui-carousel";
import Paper from "@mui/material/Paper";
import MovingServiceMapOverview from "./MovingServiceMapOverview";
import MovingServiceProgressBar from "./MovingServiceProgressBar";
import MovingItemTitle from "../../images/icons/movingItemTitle.png";
import {MovingServiceCarRate} from "./MovingServiceCarRate";
import {MovingServicePhotosAndDetails} from "./MovingServicePhotosAndDetails";
import {MovingServiceContact} from "./MovingServiceContact";
import {MovingServiceDate} from "./MovingServiceDate";
import {isValid} from "date-fns";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const styles = {
    MovingServiceSummaryRoot: {
        display: 'flex',
        flexDirection: 'column',
        gap: '30px',
        marginBottom: '40px',
    },
    MovingServiceSummaryButtonSection: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',

    },
    MovingServiceReviewRoot: {
        backgroundColor: '#FFFFFF',
        paddingBottom: '20px',
        marginBottom: '40px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        gap: '20px',
        display: 'flex',
        flexDirection: 'column',
        margin: '20px 0'
    },
    MovingServiceReviewSubHeading: {
        fontSize: '18px',
        fontWeight: '600',
        textAlign: 'left'
    },
    MovingServiceReviewCard: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        gap: '10px',
        display: 'flex',
        flexDirection: 'column',
    },
    MovingServiceReviewCardThreeCol: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        gap: '10px',
        display: 'flex',
        flexDirection: 'column',
    },
    MovingServiceReviewHeading: {
        fontSize: '20px',
        fontWeight: '600'
    },
    MovingServiceReviewButtonSection: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
    },
    MovingServiceReviewButton: {
        // width: '100%',
        // height: '100%',
        color: '#FFFFFF',
        fontSize: '16px',
        fontWeight: '600',
        padding: '10px 30px',
        borderRadius: '10px'
    },
    MovingServiceReviewImage: {
        objectFit: 'contain',
        width: '200px',
        height: '200px'
    },
    MovingServiceReviewImageBox: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
        gap: '15px',
    },
    MovingServiceReviewImageCard: {
        borderRadius: '10px',
        boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.5)',
        padding: '10px',
    },
    MovingServiceReviewImageSection: {
        display: 'flex',
        flexDirection: 'column',
        width: '220px',
        position: 'relative'
    },
    MovingServiceReviewButtonStartOver: {
        display: 'flex',
    },
    MovingServiceAdditionalFeeSectionHeadingCost: {
        fontWeight: '600',
        textAlign: 'right'
    },
    ShippingPackageCreateLabelOrderDetailsTextLeft: {
        fontSize: '16px',
        textAlign: 'left'
    },
    ShippingPackageCreateLabelOrderDetailsTextRight: {
        fontSize: '16px',
        textAlign: 'right'
    },
}

export const MovingServiceReview = () => {

    const {tab} = useParams();
    const history = useHistory();

    const {pathname} = useLocation();
    const startsWithBusiness = pathname.startsWith('/business');

    const token = getAccessToken("access_token");
    const ownerRole = useSelector(state => state?.user?.ownerRole);

    let movingRemarkNotes = useSelector(state => state?.movingService?.movingRemarkNotes);
    let movingFromAddress = useSelector(state => state?.movingService?.movingFromAddress);
    let movingToAddress = useSelector(state => state?.movingService?.movingToAddress);
    let basicRateInfo = useSelector(state => state?.movingService?.movingServiceBasicRateInfo);
    let contactInfo = useSelector(state => state?.movingService?.movingServiceSummaryContactInfo);
    let moveDate = useSelector(state => state?.movingService?.movingDate);
    let moveTime = useSelector(state => state?.movingService?.movingTime);
    let additionalService = useSelector(state => state?.movingService?.additionalService);
    let movingImage = useSelector(state => state?.movingService?.movingImage);
    let itemList = useSelector(state => state?.movingService?.itemList);
    let movingFurnitureList = useSelector(state => state?.movingService?.movingFurnitureList);
    let carList = useSelector(state => state?.movingService?.carList);
    let carRate = useSelector(state => state?.movingService?.movingServiceBasicRateInfoFromAI);
    let itemListFromAI = useSelector(state => state?.movingService?.movingItemListFromAI);
    let itemInput = useSelector(state => state?.movingService?.movingItemInput);
    let AIAssisted = useSelector(state => state?.movingService?.movingAIAssisted);

    const [loading, setLoading] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [additionalServiceFee, setAdditionalServiceFee] = useState();
    const [openMap, setOpenMap] = useState(false);
    const [validatedResult, setValidatedResult] = useState(null);

    const getAdditionServiceFee = async () => {
        let requestURL = `${NODE_ROUTE_URI}/movingService/getMovingCategoryAreaPriceByCategoriesAndAreas`;

        const cateIdArray = [];

        itemList?.forEach(item => {
            cateIdArray.push(item?.cate_id);
        })

        const data = {
            cateIdArray,
            pickupCity: movingFromAddress?.city,
            pickupProvince: movingFromAddress?.province?.code,
            deliverCity: movingToAddress?.city,
            deliverProvince: movingToAddress?.province?.code
        }

        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                data: data
            })

            console.log('addition service', result);
            setAdditionalServiceFee(result.data);
        } catch (e) {
            console.log(e.response);
        }
    }

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const handleError = (errorCode) => {
        switch (errorCode) {
            case 400:
                setErrorMessage("Bad Request: The server cannot process the request.");
                break;
            case 401:
                setErrorMessage("Unauthorized: Authentication is required to access the resource.");
                break;
            case 404:
                setErrorMessage("Not Found: The requested resource could not be found.");
                break;
            case 500:
                setErrorMessage("Internal Server Error: The server encountered an unexpected condition.");
                break;
            default:
                setErrorMessage("Unknown Error: An unknown error occurred.");
                break;
        }
    }

    const initBackendOrder = async () => {
        setLoading(true);
        const accessToken = getAccessToken("access_token");
        // let requestURL = `${NODE_ROUTE_URI}/movingService/initMovingOrder`;
        let requestURL = tab === 'item' ? `${NODE_ROUTE_URI}/movingService/initMovingOrderAndPayDeposit` :
            ownerRole === 1 ? `${NODE_ROUTE_URI}/movingExpressService/initMovingExpressOrder` :
                `${PARTNER_URI}/movingExpressService/initMovingExpressOrder`;

        const now = new Date();
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');
        const currentTime = `$${hours}:${minutes}`;
        const scheduledTime = moveTime?.movingTimeFrom || '00:00';

        const updatedItemList = AIAssisted ?
            itemInput?.content && itemListFromAI?.map(item => ({
                ...item,
                description: item?.name,
                lengthUnit: "in",
                weightUnit: "lb",
                packageSpecialServices: {
                    wrappingService: false,
                    packingService: false,
                    assemblyService: false,
                    disassemblyService: false
                }
            }))
            :
            itemList?.map(item => ({
                description: item.description,
                cateId: item.cate_id,
                length: item.length,
                width: item.width,
                height: item.height,
                weight: item.weight,
                volume: item.volume,
                lengthUnit: item.lengthUnit,
                weightUnit: item.weightUnit,
                quantity: item.quantity,
                packageSpecialServices: item.packageSpecialServices
            }));

        const updatedFurnitureList = AIAssisted ? [] : movingFurnitureList?.map(item => ({
            description: item.category,
            cateId: item.cate_id,
            length: item.length || 0,
            width: item.width || 0,
            height: item.height || 0,
            weight: item.weight || 0,
            volume: item.volume || 0,
            lengthUnit: item?.lengthUnit,
            weightUnit: item?.weightUnit,
            quantity: item.quantity,
            packageSpecialServices: item.packageSpecialServices
        }));

        const additionalServiceExpress = {
            storageDailyService: true
        }
        const items = [...updatedItemList, ...updatedFurnitureList];

        const messages = {
            additionalService: tab === 'item' ? additionalService : additionalServiceExpress,
            movingFromAddress: {
                ...movingFromAddress,
                streetAddress: contactInfo?.pickupAddress?.text,
                apt: contactInfo?.pickupAddress2
            },
            movingToAddress: {
                ...movingToAddress,
                streetAddress: contactInfo?.deliverAddress?.text,
                apt: contactInfo?.deliverAddress2
            },
            movingRemarkNotes
        }

        const data = {
            items: items,
            content: itemInput?.content,
            senderName: contactInfo.pickupName,
            senderEmail: contactInfo.pickupEmail,
            senderPhone: contactInfo.pickupPhone.replace(/\D/g, ""),
            deliverName: contactInfo.deliverName,
            deliverEmail: contactInfo.deliverEmail,
            deliverPhone: contactInfo.deliverPhone.replace(/\D/g, ""),
            pickupWarehouseId: movingFromAddress?.warehouse?.id || 0,
            pickupAddress: contactInfo?.pickupAddress2 ? `${contactInfo?.pickupAddress2} - ${contactInfo?.pickupAddress?.text}` : contactInfo?.pickupAddress?.text,
            pickupCity: movingFromAddress?.city,
            pickupProvince: movingFromAddress?.province?.code,
            pickupCountry: movingFromAddress?.province?.countryCode,
            pickupPostalCode: movingFromAddress?.postalCode,
            deliverWarehouseId: movingToAddress?.warehouse?.id || 0,
            deliverAddress: contactInfo?.deliverAddress2 ? `${contactInfo?.deliverAddress2} - ${contactInfo?.deliverAddress?.text}` : contactInfo?.deliverAddress?.text,
            deliverCity: movingToAddress?.city,
            deliverProvince: movingToAddress?.province?.code,
            deliverCountry: movingToAddress?.province?.countryCode,
            deliverPostalCode: movingToAddress?.postalCode,
            imageUrls: movingImage,
            scheduledPickupDate: `${dayjs(moveDate).format('YYYY-MM-DD')} ${moveTime?.movingTimeFrom}`,
            messages: JSON.stringify(messages),
            specialServices: [],
            accountBase: basicRateInfo?.accountBase || "",
            pickupHouseFeatures: {
                houseType: movingFromAddress?.propertyType?.houseType === 'Apartment' ? 'Condo' : movingFromAddress?.propertyType?.houseType,
                floorLevel: movingFromAddress?.floorLevel,
                staircaseNumber: "0",
                isNarrowStreet: movingFromAddress?.isNarrowStreet,
                drivewaySteepLevel: movingFromAddress?.drivewaySteepLevel,
                hasLift: !!movingFromAddress?.lift
            },
            deliverHouseFeatures: {
                houseType: movingToAddress?.propertyType?.houseType === 'Apartment' ? 'Condo' : movingToAddress?.propertyType?.houseType,
                floorLevel: movingToAddress?.floorLevel,
                staircaseNumber: "0",
                isNarrowStreet: movingToAddress?.isNarrowStreet,
                drivewaySteepLevel: movingToAddress?.drivewaySteepLevel,
                hasLift: !!movingToAddress?.lift
            }
        }

        try {
            const result = await axios({
                method: 'post',
                headers: {
                    Authorization: `Bearer ${accessToken}`
                },
                url: requestURL,
                data: data,
            })
            console.log('place order', result);
            setErrorMessage('');
            handleSuccessMessage();
            setSuccessMessage('Successfully place order.')
            setLoading(false);
            setDisabled(true);

            if (ownerRole === 1) {
                setTimeout(() => {
                    history.push(`/transportation/orderDetails/${result.data.id}`);
                }, 1000);
            } else {
                setTimeout(() => {
                    history.push(`/business/transportation/orderDashboard/${result.data.id}`);
                }, 1000);
            }
        } catch (e) {
            console.log(e.response);
            const errorCode = e?.response?.data?.error?.status;
            console.log('error code', errorCode);
            handleErrorMessage();
            handleError(errorCode);
            setLoading(false);
        }
    }

    const initVehicleBackendOrder = async () => {
        try {
            setLoading(true);
            let requestURL = startsWithBusiness ? `${PARTNER_URI}/movingCar/initMovingCarOrder` : `${NODE_ROUTE_URI}/movingCar/initMovingCarOrder`;

            const updatedCarList = carList?.map(car => ({
                make: car.make,
                model: car.model,
                yearOfProduction: dayjs(car.year)?.format('YYYY'),
                categoryValue: `CAR-SIZE-${car?.size}`,
                quantity: car.quantity
            }));

            const messages = {
                movingFromAddress: {
                    ...movingFromAddress,
                    streetAddress: contactInfo?.pickupAddress?.text,
                    apt: contactInfo?.pickupAddress2
                },
                movingToAddress: {
                    ...movingToAddress,
                    streetAddress: contactInfo?.deliver?.text,
                    apt: contactInfo?.deliverAddress2
                },
                carRatingFromAI: {
                    ...carRate?.transportOptions
                }
            }

            const data = {
                shipFromName: contactInfo?.pickupName,
                shipFromPhone: contactInfo?.pickupPhone,
                shipFromEmail: contactInfo?.pickupEmail,
                shipFromCompanyName: contactInfo?.pickupName,
                selfDropOff: movingFromAddress?.option !== "schedule",
                selfPickup: movingToAddress?.option !== "schedule",
                pickupWarehouseId: movingFromAddress?.warehouse?.id || 0,
                deliverWarehouseId: movingToAddress?.warehouse?.id || 0,
                shipFromAddress: contactInfo?.pickupAddress2 ? `${contactInfo?.pickupAddress2} - ${contactInfo?.pickupAddress?.text}` : contactInfo?.pickupAddress?.text,
                shipFromCity: movingFromAddress?.city,
                shipFromProvince: movingFromAddress?.province?.code,
                shipFromCountry: movingFromAddress?.country,
                shipFromPostalCode: movingFromAddress?.postalCode,
                shipToName: contactInfo?.deliverName,
                shipToPhone: contactInfo?.deliverPhone,
                shipToEmail: contactInfo?.deliverEmail,
                shipToCompanyName: contactInfo?.deliverName,
                shipToAddress: contactInfo?.deliverAddress2 ? `${contactInfo?.deliverAddress2} - ${contactInfo?.deliverAddress?.text}` : contactInfo?.deliverAddress?.text,
                shipToCity: movingToAddress?.city,
                shipToProvince: movingToAddress?.province?.code,
                shipToCountry: movingToAddress?.country,
                shipToPostalCode: movingToAddress?.postalCode,
                messages: JSON.stringify(messages),
                scheduledPickupDate: `${dayjs(moveDate).format('YYYY-MM-DD')} ${moveTime?.movingTimeFrom}`,
                packageData: updatedCarList,
                ratingFromAI: carRate?.total > 0,
                ratingDetailsFromAI: {
                    shippingFee: carRate?.total
                }
            }

            const result = await axios({
                method: 'post',
                headers: {
                    Authorization: `Bearer ${token}`
                },
                url: requestURL,
                data: data,
            })

            console.log('[initVehicleBackendOrder] book order', result);
            setErrorMessage('');
            handleSuccessMessage();
            setSuccessMessage('Successfully book order.')
            setLoading(false);

            setTimeout(() => {
                history.push(`${ownerRole === 1 ? '/' : '/business/'}transportation/movingVehicle/orderDashboard/${result.data.id}`);
            }, 1000);

        } catch (e) {
            console.log(e.response);
            const errorCode = e?.response?.data?.error?.status;
            console.log('error code', errorCode);
            handleErrorMessage();
            handleError(errorCode);
        } finally {
            setLoading(false);
        }
    }

    const handleBeforeUnload = (event) => {

        console.log('reload');
        // Customize the confirmation message
        const message = 'Are you sure you want to leave? Your unsaved changes may be lost.';

        // Set the confirmation message for modern browsers
        event.returnValue = message;

        // For older browsers
        return message;
    };

    const handleToggleMap = () => {
        setOpenMap(!openMap);
    }

    const handleStartOver = () => {
        history.push(`${ownerRole === 1 ? '/' : '/business/'}transportation/${tab}`);
        window.scrollTo(0, 0); // Scroll to the top
    }

    const handleEditList = () => {
        history.push(`${ownerRole === 1 ? '/' : '/business/'}transportation/${tab}/list`);
        window.scrollTo(0, 0); // Scroll to the top
    }

    const handleBack = () => {
        history.push(`${ownerRole === 1 ? '/' : '/business/'}transportation/${tab}`);
        window.scrollTo(0, 0); // Scroll to the top
    }

    const validateContactInfoMissing = () => {
        const missingKeys = getMissingKeysForMovingContact(contactInfo);
        console.log('missing keys - move to', missingKeys);
        // dispatch(updateMovingServiceSummaryContactInfoMissing(missingKeys));
        if (missingKeys.length === 0) {
            return false
        } else {
            return true
        }
    }

    const validateContactInfoPickupPhone = () => {

        const checkPhoneNumber = contactInfo.pickupPhone.replace(/\D/g, "");
        if (!isNaN(checkPhoneNumber) && checkPhoneNumber.length < 20 && checkPhoneNumber.length > 9) {
            return false
        } else {
            return true
        }
    }

    const validateContactInfoPickupEmail = () => {
        const result = isValidateEmail(contactInfo?.pickupEmail);
        return result
    }

    const validateContactInfoDeliverPhone = () => {
        const checkPhoneNumber = contactInfo.deliverPhone.replace(/\D/g, "");
        if (!isNaN(checkPhoneNumber) && checkPhoneNumber.length < 20 && checkPhoneNumber.length > 9) {
            return false
        } else {
            return true
        }
    }

    const validateContactInfoDeliverEmail = () => {
        const result = isValidateEmail(contactInfo?.deliverEmail);
        return result
    }

    const validateContactInfo = () => {
        const contactMissing = validateContactInfoMissing();
        const pickupPhoneError = validateContactInfoPickupPhone();
        const pickupEmailError = validateContactInfoPickupEmail();
        const deliverPhoneError = validateContactInfoDeliverPhone();
        const deliverEmailError = validateContactInfoDeliverEmail();
        console.log('missing', contactMissing);
        // console.log('phone', phoneError);
        // console.log('email', emailError);
        if (!contactMissing && !pickupPhoneError && pickupEmailError && !deliverPhoneError && deliverEmailError) {
            return false
        } else {
            return true
        }
    }

    const validateMoveDataAndTime = () => {
        if (isValid(moveDate?.$d) && (moveTime?.movingTimeFrom || moveTime?.pickupFlexible)) {
            return false
        } else {
            return true
        }
    }

    const validation = () => {
        setErrorMessage('');
        setToastOpen(false);
        validateContactInfo() && setErrorMessage(prevState => prevState + 'Contact Information is missing some required fields or containing invalid fields.');
        validateMoveDataAndTime() && setErrorMessage(prevState => prevState + 'Moving date or time is missing or invalid.');
        !contactInfo?.pickupAddress?.text && setErrorMessage(prevState => prevState + 'Pickup address is missing.');
        !contactInfo?.deliverAddress?.text && setErrorMessage(prevState => prevState + 'Deliver address is missing.');

        // validateWareHouse(movingFromAddress?.option, movingFromAddress?.warehouse) && setErrorMessage(prevState => prevState + 'Moving from warehouse is missing.');
        // validateWareHouse(movingToAddress?.option, movingToAddress?.warehouse) && setErrorMessage(prevState => prevState + 'Moving to warehouse is missing.');
        if (validateContactInfo()
            || validateMoveDataAndTime()
            || !contactInfo?.pickupAddress?.text
            || !contactInfo?.deliverAddress?.text
            // || validateWareHouse(movingFromAddress?.option, movingFromAddress?.warehouse)
            // || validateWareHouse(movingToAddress?.option, movingToAddress?.warehouse)
        ) {
            handleErrorMessage();
            return false
        } else {
            return true
        }
    }

    const handlePlaceOrder = async () => {

        const validationResult = validation();
        setValidatedResult(validationResult);
        if (validationResult) {
            if (tab === 'vehicle') {
                await initVehicleBackendOrder();
            } else {
                await initBackendOrder();
            }
        }
    }

    useEffect(() => {

        getAdditionServiceFee();

        // Add the event listener when the component mounts
        window.addEventListener('beforeunload', handleBeforeUnload);

        // Remove the event listener when the component unmounts
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []); // Empty dependency array means this effect runs once when the component mounts

    useEffect(() => {
        if (tab === 'vehicle' || +basicRateInfo?.finalTotal === 0 || !basicRateInfo?.finalTotal) {
            (async () => {

            })()
        }
    }, [basicRateInfo])

    console.log('[MovingServiceSummary] basicRateInfo', basicRateInfo);

    // if ((tab === 'item' || tab === 'express') && !basicRateInfo) { //todo: revise after able to get express rate
    //     return (
    //         <Container maxWidth='xl'>
    //             <Box sx={{
    //                 backgroundColor: '#FFFFFF',
    //                 padding: '20px',
    //                 marginBottom: '40px',
    //                 borderRadius: '10px',
    //                 boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
    //                 gap: '20px',
    //                 display: 'flex',
    //                 flexDirection: 'column',
    //             }}>
    //                 <Grid container spacing={2}>
    //                     <Grid item xs={12}>
    //                         <Typography variant='h4' sx={{textAlign: 'center'}}>
    //                             Transportation Service Review
    //                         </Typography>
    //                     </Grid>
    //                     <Grid item xs={12}>
    //                         <Typography style={{color: 'red', textAlign: 'left', fontSize: '16px'}}>
    //                             You've lost all the data. Please return to the home page and create a new
    //                             transportation.
    //                         </Typography>
    //                     </Grid>
    //                     <Grid item xs={12}>
    //                         <Box sx={{display: 'flex', width: '100%', justifyContent: 'flex-end', gap: '20px'}}>
    //                             <Button
    //                                 variant='contained'
    //                                 onClick={() => history.push(`${ownerRole === 1 ? '/' : '/business/'}transportation/${tab}`)}
    //                             >
    //                                 <Typography style={{textTransform: 'none'}}>
    //                                     Go Back Home
    //                                 </Typography>
    //                             </Button>
    //                         </Box>
    //                     </Grid>
    //                 </Grid>
    //             </Box>
    //         </Container>
    //     )
    // }

    console.log('[MovingServiceReview] movingFurnitureList', movingFurnitureList);
    console.log('[MovingServiceReview] itemList', itemList);
    console.log('[MovingServiceReview] itemListFromAI', itemListFromAI);

    console.log('[MovingServiceReview] itemInput', itemInput);
    console.log('[MovingServiceReview] AIAssisted', AIAssisted);

    console.log('[MovingServiceReview] basicRateInfo', basicRateInfo);

    console.log('[MovingServiceReview] carList', carList);
    console.log('[MovingServiceReview] carRate', carRate);

    return (
        <Container maxWidth='xl'>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Box sx={styles.MovingServiceSummaryRoot}>
                <Box display="flex" alignItems="center" p={2}>
                    <img src={MovingItemTitle} alt="Moving items"
                         style={{width: '150px', height: 'auto', marginRight: '16px'}}/>
                    <Box>
                        <Typography variant="h5" component="div" fontWeight="bold">
                            {tab === 'item' ? 'Local & Long-distance Moving' : tab === 'express' ? 'Box Moving Service' : 'Vehicle Moving Service'}
                        </Typography>
                        <Typography variant="body1">
                            {tab === 'item' ? "Move your residence locally or long-distance, whether it's a small apartment or a large house." : "Limited to Greater Vancouver, Toronto, Calgary, and Montreal Areas"}
                        < /Typography>
                    </Box>
                </Box>
                {/*<MovingServiceProgressBar progress={100}/>*/}
                <Box sx={styles.MovingServiceReviewRoot}>
                    <Box sx={{
                        display: 'flex',
                        gap: '10px',
                        padding: '20px 20px 0 20px'
                    }}>
                        <ShipmentOverviewIcon width={25} height={25}/>
                        <Typography sx={styles.MovingServiceReviewSubHeading}>
                            {intl.get('TRANSPORTATION_PAGE.TRANSPORTATION_SERVICE_REVIEW')}
                        </Typography>
                    </Box>
                    <Divider/>
                    <Box sx={{
                        backgroundColor: '#FFFFFF',
                        padding: '0 40px',
                        gap: '25px',
                        display: 'flex',
                    }}>
                        <Grid container spacing={4}>
                            <Grid item xs={12} md={6}>
                                <Box sx={{display: 'flex', width: '100%', flexDirection: 'column', gap: '40px'}}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={1}>
                                            <ShippingFromAndToIcon width={20} height={20}/>
                                        </Grid>
                                        <Grid item xs={11}>
                                            <Typography sx={{fontWeight: '600'}}>
                                                Moving From
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Divider/>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography>
                                                {movingFromAddress?.option === 'self' ? intl.get('MOVING.SELF_DROP_OFF') : intl.get('MOVING.SCHEDULED_PICKUP')}
                                            </Typography>
                                            <Typography>
                                                {movingFromAddress?.city}, {movingFromAddress?.province?.code}
                                            </Typography>
                                            <Typography>
                                                {movingFromAddress?.country}, {movingFromAddress?.postalCode}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={1}>
                                        <Grid item xs={1}>
                                            <ShippingFromAndToIcon width={20} height={20}/>
                                        </Grid>
                                        <Grid item xs={11}>
                                            <Typography sx={{fontWeight: '600'}}>
                                                Moving To
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Divider/>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography>
                                                {movingToAddress?.option === 'self' ? intl.get('MOVING.SELF_DROP_OFF') : intl.get('MOVING.SCHEDULED_PICKUP')}
                                            </Typography>
                                            <Typography>
                                                {movingToAddress?.city}, {movingToAddress?.province?.code}
                                            </Typography>
                                            <Typography>
                                                {movingToAddress?.country}, {movingToAddress?.postalCode}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={1}>
                                        <Grid item xs={1}>
                                            <MapIcon width={20} height={20}/>
                                        </Grid>
                                        <Grid item xs={9}>
                                            <Typography sx={{fontWeight: '600'}}>
                                                Map Overview
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Button
                                                onClick={handleToggleMap}
                                                sx={{padding: 0, justifyContent: 'right', width: '100%'}}>
                                                <Typography sx={{color: '#1D8B45', textTransform: 'none'}}>
                                                    {openMap ? 'Hide' : 'Show'}
                                                </Typography>
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Divider/>
                                        </Grid>
                                        {
                                            openMap &&
                                            <Grid item xs={12}>
                                                <MovingServiceMapOverview
                                                    apiKey={"AIzaSyDxbjdueUQWI1WFfiEviIkvspt8S95L-bQ"}
                                                    shipFrom={movingFromAddress}
                                                    shipTo={movingToAddress}
                                                />
                                            </Grid>
                                        }
                                    </Grid>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Box sx={{display: 'flex', width: '100%', flexDirection: 'column', gap: '40px'}}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={1}>
                                            <RateDetailsIcon width={20} height={20}/>
                                        </Grid>
                                        <Grid item xs={11}>
                                            <Typography sx={{fontWeight: '600'}}>
                                                Rate Details
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Divider/>
                                        </Grid>
                                        {
                                            tab === 'item' ? <MovingServiceRate tab={tab}/> :
                                                tab === 'vehicle' ? <MovingServiceCarRate tab={tab}/> :
                                                    +basicRateInfo?.totalChargeWithTaxes > 0 ?
                                                        <Grid item xs={12}>
                                                            <Grid container spacing={1}>
                                                                {
                                                                    +basicRateInfo?.boxFreightCharge > 0 &&
                                                                    <>
                                                                        <Grid item xs={8}>
                                                                            <Typography>
                                                                                {intl.get('COST.FREIGHT_CHARGE')}
                                                                            </Typography>
                                                                        </Grid>
                                                                        <Grid item xs={4}>
                                                                            <Typography style={{textAlign: 'right'}}>
                                                                                $ {(basicRateInfo?.boxFreightCharge)?.toFixed(2)}
                                                                            </Typography>
                                                                        </Grid>
                                                                    </>
                                                                }
                                                                {
                                                                    +basicRateInfo?.boxPickupCharge > 0 &&
                                                                    <>
                                                                        <Grid item xs={8}>
                                                                            <Typography>
                                                                                {intl.get('COST.PICKUP_CHARGE')}
                                                                            </Typography>
                                                                        </Grid>
                                                                        <Grid item xs={4}>
                                                                            <Typography style={{textAlign: 'right'}}>
                                                                                $ {(basicRateInfo?.boxPickupCharge)?.toFixed(2)}
                                                                            </Typography>
                                                                        </Grid>
                                                                    </>
                                                                }
                                                                {
                                                                    +basicRateInfo?.boxDeliverCharge > 0 &&
                                                                    <>
                                                                        <Grid item xs={8}>
                                                                            <Typography>
                                                                                {
                                                                                    movingToAddress?.option === 'self' ? intl.get('COST.OPERATION_CHARGE') : intl.get('COST.DELIVER_CHARGE')
                                                                                }
                                                                            </Typography>
                                                                        </Grid>
                                                                        <Grid item xs={4}>
                                                                            <Typography style={{textAlign: 'right'}}>
                                                                                $ {(basicRateInfo?.boxDeliverCharge)?.toFixed(2)}
                                                                            </Typography>
                                                                        </Grid>
                                                                    </>
                                                                }
                                                                <Grid item xs={12}>
                                                                    <Divider/>
                                                                </Grid>
                                                                <Grid item xs={8}>
                                                                    <Typography>
                                                                        {intl.get('COST.BOX_SHIPPING_CHARGE')}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item xs={4}>
                                                                    <Typography style={{textAlign: 'right'}}>
                                                                        $ {(+basicRateInfo?.shippingChargeDetails?.boxFreightCharge + +basicRateInfo?.shippingChargeDetails?.boxPickupCharge + +basicRateInfo?.shippingChargeDetails?.boxDeliverCharge)?.toFixed(2)}
                                                                    </Typography>
                                                                </Grid>
                                                                {
                                                                    basicRateInfo?.specialServicesCharges > 0 &&
                                                                    <>
                                                                        <Grid item xs={8}>
                                                                            <Typography>
                                                                                {intl.get('COST.ADDITIONAL_SERVICE_CHARGE')}
                                                                            </Typography>
                                                                        </Grid>
                                                                        <Grid item xs={4}>
                                                                            <Typography style={{textAlign: 'right'}}>
                                                                                $ {(basicRateInfo?.specialServicesCharges)?.toFixed(2)}
                                                                            </Typography>
                                                                        </Grid>
                                                                        <Grid item xs={12}>
                                                                            <Divider/>
                                                                        </Grid>
                                                                    </>
                                                                }
                                                                <Grid item xs={8}>
                                                                    <Typography>
                                                                        {intl.get('COST.SUBTOTAL')}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item xs={4}>
                                                                    <Typography style={{textAlign: 'right'}}>
                                                                        $ {(basicRateInfo?.totalCharge)?.toFixed(2)}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item xs={8}>
                                                                    <Typography>
                                                                        {intl.get('COST.TAX')}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item xs={4}>
                                                                    <Typography style={{textAlign: 'right'}}>
                                                                        $ {(basicRateInfo?.taxCharge)?.toFixed(2)}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <Divider/>
                                                                </Grid>
                                                                <Grid item xs={8}>
                                                                    <Typography>
                                                                        {intl.get('COST.TOTAL')}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item xs={4}>
                                                                    <Typography sx={{
                                                                        fontSize: '20px',
                                                                        fontWeight: '600',
                                                                        textAlign: 'right'
                                                                    }}>
                                                                        $ {(basicRateInfo?.totalChargeWithTaxes)?.toFixed(2)}
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        :
                                                        <Grid item xs={12}>
                                                            <Typography>
                                                                Based on the selections you've made, a quote cannot be
                                                                provided to you immediately.
                                                                Please select "Book" to send this to our operation team.
                                                            </Typography>
                                                        </Grid>

                                        }
                                    </Grid>
                                    <Grid container spacing={1}>
                                        <Grid item xs={1}>
                                            <PackageDetailIcon width={20} height={20}/>
                                        </Grid>
                                        <Grid item xs={11}>
                                            <Typography sx={{fontWeight: '600'}}>
                                                {tab === 'item' ? 'Item Details' : tab === 'vehicle' ? 'Vehicle Details' : 'Box Details'}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Divider/>
                                        </Grid>
                                        {
                                            (tab === 'vehicle' && carList?.length > 0) ? carList?.map((each, index) => (
                                                    <Fragment key={index}>
                                                        <Grid item xs={1}>
                                                            <Typography
                                                                style={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>
                                                                {`#${index + 1}`}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={5}>
                                                            <Typography
                                                                style={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>
                                                                {each?.make}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <Typography
                                                                style={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>
                                                                {each?.model}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                            <Typography
                                                                style={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>
                                                                {dayjs(each?.year)?.format('YYYY')}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={1}>
                                                            <Typography
                                                                style={styles.ShippingPackageCreateLabelOrderDetailsTextRight}>
                                                                x {each?.quantity}
                                                            </Typography>
                                                        </Grid>
                                                    </Fragment>
                                                ))
                                                :
                                                (tab === 'item' && AIAssisted && itemInput?.content) ?
                                                    <Typography>
                                                        {itemInput?.content}
                                                    </Typography>
                                                    :
                                                    <>
                                                        {
                                                            movingFurnitureList.length > 0 && movingFurnitureList?.map((each, index) => (
                                                                <Fragment key={index}>
                                                                    <Grid item xs={2}>
                                                                        <Typography
                                                                            style={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>
                                                                            {`#${index + 1}`}
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item xs={6}>
                                                                        <Typography
                                                                            style={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>
                                                                            {/*{each?.length} x {each?.width} x {each?.height} {each?.lengthUnit}*/}
                                                                            {each?.category}
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item xs={2}>
                                                                        <Typography
                                                                            style={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>
                                                                            {/*{each?.weight} {each?.weightUnit}*/}
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item xs={2}>
                                                                        <Typography
                                                                            style={styles.ShippingPackageCreateLabelOrderDetailsTextRight}>
                                                                            x {each?.quantity}
                                                                        </Typography>
                                                                    </Grid>
                                                                </Fragment>
                                                            ))
                                                        }
                                                        {
                                                            itemList.length > 0 && itemList?.map((each, index) => (
                                                                <Fragment key={index}>
                                                                    <Grid item xs={2}>
                                                                        <Typography
                                                                            style={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>
                                                                            {`#${index + 1}`}
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item xs={6}>
                                                                        <Typography
                                                                            style={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>
                                                                            {/*{each?.length} x {each?.width} x {each?.height} {each?.lengthUnit}*/}
                                                                            {each?.description}
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item xs={2}>
                                                                        <Typography
                                                                            style={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>
                                                                            {/*{each?.weight} {each?.weightUnit}*/}
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item xs={2}>
                                                                        <Typography
                                                                            style={styles.ShippingPackageCreateLabelOrderDetailsTextRight}>
                                                                            x {each?.quantity}
                                                                        </Typography>
                                                                    </Grid>
                                                                </Fragment>
                                                            ))
                                                        }
                                                    </>
                                        }
                                    </Grid>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
                <MovingServicePhotosAndDetails validatedResult={validatedResult}/>
                <Grid container spacing={2}>
                    {
                        basicRateInfo?.downPaymentAmount > 0 && (
                            <Grid item xs={12}>
                                <Typography sx={{fontSize: '18px'}}>
                                    {
                                        basicRateInfo?.orderSubType === 'EXPRESS' ? `Proceed to checkout to pay $ ${(basicRateInfo?.downPaymentAmount).toFixed(2) || 50} CAD.` : `Proceed to checkout to pay $ ${basicRateInfo?.downPaymentAmount || 50} CAD deposit and secure your order.`
                                    }
                                </Typography>
                                <Typography sx={{fontSize: '18px'}}>
                                    The payment is fully refundable before the order is picked up for
                                    moving.
                                </Typography>
                            </Grid>
                        )
                    }
                    <Grid item md={12}>
                        <Box sx={{
                            display: 'flex',
                            width: '100%',
                            justifyContent: 'flex-end',
                            gap: '20px'
                        }}>
                            <Button
                                variant='contained'
                                onClick={handleBack}
                                sx={{
                                    backgroundColor: '#F2BE22',
                                    "&:hover": {
                                        backgroundColor: '#F2BE22',
                                        filter: 'brightness(0.9)'
                                    }
                                }}
                            >
                                <Typography sx={{textTransform: 'none'}}>
                                    Back
                                </Typography>
                            </Button>
                            <LoadingButton
                                variant='contained'
                                color='success'
                                loading={loading}
                                onClick={handlePlaceOrder}
                            >
                                <Typography sx={{textTransform: 'none'}}>
                                    Book
                                </Typography>
                            </LoadingButton>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    )
}