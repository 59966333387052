import Box from "@mui/material/Box";
import Logo from "../../images/logo.png";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Menu, MenuItem} from "@mui/material";
import Typography from "@mui/material/Typography";
import LanguageIcon from '@mui/icons-material/Language';
import CalculateIcon from '@mui/icons-material/Calculate';
import {useHistory} from "react-router-dom";
import React, {useState} from "react";
import Cookies from "js-cookie";
import {getAccessToken} from "../../Utils/doToken";
import {NODE_ROUTE_URI} from "../../Utils/apiUrl";
import axios from "axios";
import intl from "react-intl-universal";
import {useMediaQuery} from "@material-ui/core";

export const LandingMenuBar = () => {
    const history = useHistory();

    const isMobile = useMediaQuery('(max-width: 480px)');

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const [openLanguage, setOpenLanguage] = useState(false);

    const handleOpen = (event) => {
        setAnchorEl(event.currentTarget);
    }

    const handleClose = () => {
        setAnchorEl(null);
    }

    const handleCalculator = () => {
        history.push('/shipping-rate-calculator')
    }

    const handleLanguageDialogClose = () => {
        setOpenLanguage(false);
        // setContactEmail('');
    };

    const handleLanguageDialogOpen = () => {
        setOpenLanguage(true);
        // setContactEmail();
        // setContactEmail(userInfo?.email);
    };

    const savePreferredLanguage = (language) => {
        const token = getAccessToken("access_token");
        let requestURI = `${NODE_ROUTE_URI}/login_middleware/savePreferredLanguage`;
        const data = {
            preferredLanguage: language
        }
        axios
            .post(requestURI, data, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then(r => console.log('response', r))
            .catch(err => console.log('error', err))
    }

    const onLocalChange = (value) => {
        Cookies.set('lang', value, {expires: 30});
        savePreferredLanguage(value);
        handleLanguageDialogOpen();
    }

    console.log('[LandingMenuBar] isMobile', isMobile);

    return (
        <Box sx={{
            display: 'flex',
            justifyContent: 'space-between',
        }}>
            <Dialog
                open={openLanguage}
                onClose={handleLanguageDialogClose}
            >
                <DialogTitle>
                    {intl.get('NAVBAR_LANGUAGE_TITLE')}
                </DialogTitle>
                <DialogContent>
                    <Box sx={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
                        <Typography>
                            {intl.get('NAVBAR_LANGUAGE_CONTENT')}
                        </Typography>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleLanguageDialogClose}
                    >
                        {intl.get('NAVBAR_LANGUAGE_NO')}
                    </Button>
                    <Button
                        onClick={() => window.location.reload()}
                    >
                        {intl.get('NAVBAR_LANGUAGE_CONFIRM')}
                    </Button>
                </DialogActions>
            </Dialog>
            <img src={'https://storage.googleapis.com/uucargo-resource/image/icon/UUCargoLogo-New.svg'} alt="logo"
                 style={{width: '100px'}}/>
            <Box sx={{
                display: 'flex',
                alignItems: 'center'
            }}>
                <Box sx={{
                    position: 'relative'
                }}>
                    <IconButton
                        onClick={handleOpen}
                    >
                        <LanguageIcon style={{color: '#1D8B45', fontSize: '30px'}}/>
                    </IconButton>
                    <Menu
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        PaperProps={{
                            elevation: 0,
                            sx: {
                                overflow: 'visible',
                                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                mt: 1.5,
                                '& .MuiAvatar-root': {
                                    width: 32,
                                    height: 32,
                                    ml: -0.5,
                                    mr: 1,
                                },
                                '&::before': {
                                    content: '""',
                                    display: 'block',
                                    position: 'absolute',
                                    top: 0,
                                    right: 14,
                                    width: 10,
                                    height: 10,
                                    bgcolor: 'background.paper',
                                    transform: 'translateY(-50%) rotate(45deg)',
                                    zIndex: 0,
                                },
                            },
                        }}
                        transformOrigin={{horizontal: 'right', vertical: 'top'}}
                        anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
                    >
                        <MenuItem value='en-US' onClick={() => onLocalChange('en-US')}>English-EN</MenuItem>
                        <MenuItem value='zh-CN' onClick={() => onLocalChange('zh-CN')}>中文(简体)-ZH</MenuItem>
                    </Menu>
                </Box>
                {/*{*/}
                {/*    isMobile ?*/}
                {/*        <Button*/}
                {/*            // variant="contained"*/}
                {/*            // sx={{*/}
                {/*            //     border: '2px solid #1D8B45',*/}
                {/*            //     "&:hover": {*/}
                {/*            //         border: '2px solid #1D8B45',*/}
                {/*            //         filter: 'brightness(0.9)'*/}
                {/*            //     }*/}
                {/*            // }}*/}
                {/*            startIcon={<CalculateIcon style={{color: '#1D8B45', fontSize: '30px'}}/>}*/}
                {/*            size='small'*/}
                {/*            onClick={handleCalculator}*/}
                {/*        />*/}
                {/*        :*/}
                {/*        <Button*/}
                {/*            variant="outlined"*/}
                {/*            sx={{*/}
                {/*                border: '2px solid #1D8B45',*/}
                {/*                "&:hover": {*/}
                {/*                    border: '2px solid #1D8B45',*/}
                {/*                    filter: 'brightness(0.9)'*/}
                {/*                }*/}
                {/*            }}*/}
                {/*            startIcon={<CalculateIcon style={{color: '#1D8B45', fontSize: '30px'}}/>}*/}
                {/*            size='small'*/}
                {/*            onClick={handleCalculator}*/}
                {/*        >*/}
                {/*            <Typography*/}
                {/*                sx={{fontSize: '14px', textTransform: 'none', color: '#1D8B45', fontWeight: '600'}}>*/}
                {/*                Shipping Calculator*/}
                {/*            </Typography>*/}
                {/*        </Button>*/}
                {/*}*/}
            </Box>
        </Box>
    )
}