import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {InputAdornment} from "@mui/material";
import TextField from "@mui/material/TextField";
import {useEffect, useState} from "react";
import * as React from "react";

export const MarketplaceOrderDashboardItemListEach = ({item, updateItemList, marketplace}) => {

    const [itemDetail, setItemDetail] = useState(item);

    const handleQuantity = event => {

        const {value} = event.target;

        if (/^\d*$/.test(value)) {
            const numericValue = Number(value);
            const maxQuantity = itemDetail?.remaining_quantity || Infinity; // Default to Infinity if item?.quantity is not defined
            setItemDetail(prevState => ({
                ...prevState,
                quantity: numericValue <= maxQuantity ? value : String(maxQuantity) // Clamp to maxQuantity
            }))
        }
    }

    useEffect(() => {
        setItemDetail(item);
    }, [item]);

    useEffect(() => {
        updateItemList(itemDetail);
    }, [itemDetail]);

    console.log('[MarketplaceOrderDashboardItemListEach] item', item);

    return (
        <Grid container spacing={2}>
            <Grid item xs={6}>
                <Typography>
                    {itemDetail?.product_name}
                </Typography>
            </Grid>
            <Grid item xs={3}>
                {
                    +itemDetail?.weight > 0 &&
                    <Typography>
                        {itemDetail?.weight?.toFixed(2)} {item?.weight_unit}
                    </Typography>
                }
            </Grid>
            <Grid item xs={3}>
                <TextField
                    value={itemDetail?.quantity}
                    onInput={handleQuantity}
                    fullWidth
                    variant='outlined'
                    type='number'
                    size='small'
                    disabled={marketplace === 'woocommerce'}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                of {itemDetail?.remaining_quantity}
                            </InputAdornment>
                        ),
                        inputProps: {
                            max: itemDetail?.remaining_quantity
                        },
                        style: {fontSize: 14} // font size of input text
                    }}
                />
            </Grid>
        </Grid>
    )
}