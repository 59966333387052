import Box from "@mui/material/Box";
import {ReactComponent as ItemListIcon} from "../../images/NewDesignIcon/PackageDetails.svg";
import Typography from "@mui/material/Typography";
import intl from "react-intl-universal";
import * as React from "react";
import {Divider} from "@mui/material";
import Grid from "@mui/material/Grid";
import {useSelector} from "react-redux";
import {Fragment} from "react";

const styles = {
    MarketplaceFulfilledItemListRoot: {
        backgroundColor: '#FFFFFF',
        // padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        // border: '1px solid #A9A9A9',
        gap: '20px',
        display: 'flex',
        flexDirection: 'column',
    },
    MarketplaceFulfilledItemListSectionBox: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start'
    },
    MarketplaceFulfilledItemListHeading: {
        fontSize: '18px',
        fontWeight: '600',
        textAlign: 'left'
    },
    MarketplaceFulfilledItemListSection: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '20px 20px 0 20px',
        alignItems: 'center'
    },
    MarketplaceFulfilledItemListText: {
        fontSize: '14px',
        textAlign: 'left',
        color: '#609966',
        fontWeight: '600',
        textTransform: 'none'
    },
    MarketplaceFulfilledItemListWarningText: {
        fontSize: '12px',
        color: "#FF0303"
    },
    MarketplaceFulfilledItemListModalBox: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: 500,
        maxHeight: 400,
        backgroundColor: '#FFFFFF',
        border: '2px solid #000',
        borderRadius: '10px',
        boxShadow: 24,
        padding: '40px 20px',
        overflow: 'auto'
    },
    MarketplaceFulfilledItemListSelectBox: {
        border: '1px solid #000000',
        borderRadius: '5px',
        padding: '10px',
        "&:hover": {
            cursor: "pointer",
            background: "aliceblue"
        },
    },
    MarketplaceFulfilledItemListAddressText: {
        fontSize: '14px'
    },
    MarketplaceFulfilledItemListContent: {
        backgroundColor: '#FFFFFF',
        padding: '0 40px',
        marginBottom: '40px',
        borderRadius: '10px',
        gap: '20px',
        display: 'flex',
        flexDirection: 'column',
    },
}

export const MarketplaceFulfilledItemList = () => {

    let eCommerceItemList = useSelector(state => state?.eCommerce?.itemList);

    return (
        <Box sx={styles.MarketplaceFulfilledItemListRoot}>
            <Box sx={styles.MarketplaceFulfilledItemListSection}>
                <Box sx={{
                    display: 'flex',
                    gap: '10px',
                }}>
                    <ItemListIcon width={25} height={25}/>
                    <Typography style={styles.MarketplaceFulfilledItemListHeading}>
                        Fulfill Items
                    </Typography>
                </Box>
            </Box>
            <Divider/>
            <Box sx={styles.MarketplaceFulfilledItemListContent}>
                <Grid container spacing={1}>
                    {
                        eCommerceItemList?.map((item, index) => (
                            <Fragment key={index}>
                                <Grid item xs={6}>
                                    <Typography>
                                        {item?.product_name}
                                    </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    {
                                        +item?.weight > 0 &&
                                        <Typography>
                                            {(+item?.weight)?.toFixed(2)} {item?.weight_unit}
                                        </Typography>
                                    }
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography>
                                        {item?.quantity}
                                    </Typography>
                                </Grid>
                            </Fragment>
                        ))
                    }
                </Grid>
            </Box>
        </Box>
    )
}