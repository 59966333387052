import { Route, Redirect } from "react-router-dom";
import {getAccessToken} from "../Utils/doToken";


const ProtectedRoute = ({ component: Component, ...rest }) => {
    const isAuthenticated = !!getAccessToken("access_token"); // or read token from cookie

    return (
        <Route
            {...rest}
            render={(props) =>
                isAuthenticated ? (
                    <Component {...props} />
                ) : (
                    <Redirect to={{ pathname: "/sign-in", state: { from: props.location } }} />
                )
            }
        />
    );
};

export default ProtectedRoute;

