import {Button, Container} from "@mui/material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {
    defaultText,
    green,
    greenButtonContained,
    greenButtonOutlined, light_orange,
    mainHeading, orange,
    sectionHeading, styles,
    textContent
} from "../../Utils/styleConfig";
import CanadaSuccessImage from "../../images/ParcelCanada.png";
import CrossBorderSuccessImage from "../../images/ParcelCrossBorder.png";
import Box from "@mui/material/Box";
import HomeIcon from '@mui/icons-material/Home';
import {useEffect, useState} from "react";
import ReceiptIcon from '@mui/icons-material/Receipt';
import {Link, useHistory, useLocation} from "react-router-dom";
import dayjs from "dayjs";
import {mapCarrierServiceName} from "../../Utils/Helper";
import CircularProgress from "@material-ui/core/CircularProgress";
import {NODE_ROUTE_URI, PARTNER_URI} from "../../Utils/apiUrl";
import axios from "axios";
import {getAccessToken} from "../../Utils/doToken";
import {useInterval} from "usehooks-ts";
import WarningIcon from "@mui/icons-material/Warning";

export const PackageShipmentSuccess = ({
                                           orderDetails,
                                           shippingOrderInfo,
                                           pickupInformation,
                                           loadingSuccess,
                                           fulfillmentList
                                       }) => {

    const accessToken = getAccessToken("access_token");
    const history = useHistory();
    const {pathname} = useLocation();
    const startsWithBusiness = pathname.startsWith('/business');
    const [pickup, setPickup] = useState('');
    const [fulfillmentLoading, setFulfillmentLoading] = useState(false);
    const [fulfillmentError, setFulfillmentError] = useState(false);
    const [fulfillmentStatusList, setFulfillmentStatusList] = useState([]);
    const [delay, setDelay] = useState(2000);

    const handleSchedulePickup = () => {
        history?.push(startsWithBusiness ? '/business/schedulepickup' : '/schedulepickup');
    }

    const handleShippingDashboard = () => {
        history?.push(startsWithBusiness ? `/business/package-order-dashboard/${shippingOrderInfo?.order_id}` : `/package-order-dashboard/${shippingOrderInfo?.order_id}`);
    }

    const handleCreateNewShipment = () => {
        history?.push(startsWithBusiness ? '/business/shipping-package/create-label' : '/shipping-package/create-label');
    }

    const handleCreateCommercialInvoice = () => {
        history?.push(startsWithBusiness ? `/business/cross-border-dashboard/${shippingOrderInfo?.order_id}` : `/cross-border-dashboard/${shippingOrderInfo?.order_id}`);
    }

    const getFulfillmentStatusByFulfillmentId = async (fulfillmentId) => {
        try {
            setFulfillmentLoading(true);
            let requestURL = startsWithBusiness ? `${PARTNER_URI}/marketplace/getFulfillmentStatusByFulfillmentId` : `${NODE_ROUTE_URI}/marketplace/getFulfillmentStatusByFulfillmentId`;

            const result = await axios({
                method: 'get',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
                params: {
                    fulfillmentId: fulfillmentId,
                }
            })

            console.log('[getFulfillmentStatusByFulfillmentId] result', result);
            addOrReplaceByMarketplaceFulfillmentId(result?.data);
            setFulfillmentError(false);
        } catch (e) {
            setFulfillmentError(true);

        } finally {
            setFulfillmentLoading(false);
        }
    }

    const checkFulfillmentStatus = () => {
        return fulfillmentStatusList?.some(each => each?.status === 0 || each?.status === 100);
    }

    const checkFulfillmentStatusSuccess = () => {
        return fulfillmentStatusList?.every((each => each?.status === 1));
    }

    const checkFulfillmentStatusFailed = () => {
        return fulfillmentStatusList.some((each => each?.status === -1 || each?.status === -2));
    }

    const addOrReplaceByMarketplaceFulfillmentId = (newItem) => {
        setFulfillmentStatusList((prevItems) => {
            // Use map to either replace or add the item
            return prevItems.some(item => item.marketplace_fulfillment_id === newItem.marketplace_fulfillment_id)
                ? prevItems.map(item =>
                    item.marketplace_fulfillment_id === newItem.marketplace_fulfillment_id
                        ? newItem
                        : item
                )
                : [...prevItems, newItem];
        });
    };

    useEffect(() => {
        if (pickupInformation) {
            if (pickupInformation?.message) {
                const pickupText =
                    <>You have not scheduled a {mapCarrierServiceName(shippingOrderInfo?.account_base)} pickup yet.</>;
                setPickup(pickupText);
            } else {
                const pickupText = <>{mapCarrierServiceName(shippingOrderInfo?.account_base)} will pickup your package
                    on <span
                        style={{
                            fontWeight: '600',
                            color: green
                        }}>{dayjs(pickupInformation?.appoint_date)?.format('dddd MMMM D, YYYY')}</span>.</>;
                setPickup(pickupText);
            }
        } else {
            const pickupText =
                <>You have not scheduled a {mapCarrierServiceName(shippingOrderInfo?.account_base)} pickup yet.</>;
            setPickup(pickupText);
        }
    }, [shippingOrderInfo, pickupInformation]);

    useEffect(() => {
        if (orderDetails) {
            if (orderDetails?.send_from_country !== orderDetails?.send_to_country) {

            }
        }
    }, [orderDetails]);

    useEffect(() => {
        (async () => {
            if (fulfillmentList?.length > 0) {
                for (const fulfillment of fulfillmentList) {
                    await getFulfillmentStatusByFulfillmentId(fulfillment?.marketplace_fulfillment_id);
                }
            }
        })()
    }, [fulfillmentList]);

    useInterval(
        () => {
            if (fulfillmentList?.length > 0) {
                console.log('call get fulfillment status api');
                for (const fulfillment of fulfillmentStatusList) {
                    if (fulfillment?.status === 0 || fulfillment?.status === 100) {
                        getFulfillmentStatusByFulfillmentId(fulfillment?.marketplace_fulfillment_id);
                    }
                }
            }
        },
        checkFulfillmentStatus() ? delay : null
    )

    if (loadingSuccess) {
        return (
            <Container maxWidth='xl'>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center'
                }}>
                    <CircularProgress/>
                </Box>
            </Container>
        )
    }

    console.log('[PackageShipmentSuccess] orderDetails', orderDetails);
    console.log('[PackageShipmentSuccess] shippingOrderInfo', shippingOrderInfo);
    console.log('[PackageShipmentSuccess] pickupInformation', pickupInformation);
    console.log('[PackageShipmentSuccess] fulfillmentList', fulfillmentList);
    console.log('[PackageShipmentSuccess] fulfillmentStatusList', fulfillmentStatusList);

    return (
        <Container maxWidth='xl'>
            <Box sx={{
                maxWidth: '80vw',
                width: '50vw',
                margin: '0 auto 40px'
            }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography sx={{fontSize: mainHeading, fontWeight: '600', textAlign: 'center'}}>
                            Congratulations. Your shipment has been created!
                        </Typography>
                        {
                            (fulfillmentList?.length > 0 && checkFulfillmentStatusSuccess()) &&
                            <Typography sx={{fontSize: mainHeading, fontWeight: '600', textAlign: 'center'}}>
                                Your items have been successfully fulfilled!
                            </Typography>
                        }
                    </Grid>
                    <Grid item xs={12}>
                        <Typography sx={{textAlign: 'center'}}>
                            {pickup} {
                            (!pickupInformation || pickupInformation?.message) &&
                            <span sx={{fontWeight: '600'}}>
                            <Button
                                sx={{
                                    padding: '0px',
                                }}
                                onClick={handleSchedulePickup}
                            >
                                <Typography
                                    sx={{
                                        textTransform: 'none',
                                        color: green,
                                        fontWeight: '600'
                                    }}>Schedule Pickup Now
                                </Typography>
                            </Button>
                            </span>
                        }
                        </Typography>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'center'
                        }}>
                            <img
                                src={orderDetails?.send_from_country === orderDetails?.send_to_country ? CanadaSuccessImage : CrossBorderSuccessImage}
                                alt="package-shipment-success" style={{
                                maxWidth: '450px',
                                width: '300px'
                            }}/>
                        </Box>
                        {
                            (orderDetails?.send_from_country === orderDetails?.send_to_country || shippingOrderInfo?.account_base?.toUpperCase() === 'CANADAPOST') ?
                                <Typography sx={{textAlign: 'center'}}>
                                    You can easily check and download your shipping labels from the Order
                                    Dashboard. <span style={{fontWeight: '600'}}> Don't forget to attach
                                    your shipping label to your package.</span>
                                </Typography>

                                :
                                <Typography sx={{textAlign: 'center'}}>
                                    One last step for international shipments: Before sending your packages, it's
                                    essential to <span style={{fontWeight: '600'}}>create or upload your commercial
                                    invoices</span> for customs brokerage purposes.
                                </Typography>

                        }
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'center'
                        }}>
                            {
                                (orderDetails?.send_from_country === orderDetails?.send_to_country || shippingOrderInfo?.account_base?.toUpperCase() === 'CANADAPOST') ?
                                    <Button
                                        variant="contained"
                                        sx={greenButtonContained}
                                        startIcon={<HomeIcon/>}
                                        onClick={handleShippingDashboard}
                                    >
                                        <Typography sx={{
                                            fontWeight: '600',
                                            fontSize: textContent,
                                            textTransform: 'none',
                                        }}>
                                            Shipping Dashboard
                                        </Typography>
                                    </Button>
                                    :
                                    <Button
                                        variant="contained"
                                        sx={greenButtonContained}
                                        startIcon={<ReceiptIcon/>}
                                        onClick={handleCreateCommercialInvoice}
                                    >
                                        <Typography sx={{
                                            fontWeight: '600',
                                            fontSize: textContent,
                                            textTransform: 'none',
                                        }}>
                                            Create Commercial Invoice
                                        </Typography>
                                    </Button>
                            }
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'center'
                        }}>
                            <Button
                                onClick={handleCreateNewShipment}
                            >
                                <Typography sx={{
                                    fontWeight: '600',
                                    fontSize: textContent,
                                    textTransform: 'none',
                                    color: green
                                }}>
                                    Create New Shipment
                                </Typography>
                            </Button>
                        </Box>
                    </Grid>
                    {
                        (fulfillmentList?.length > 0 && checkFulfillmentStatusFailed()) &&
                        <Grid item xs={12}>
                            <Box sx={styles.warning}>
                                <WarningIcon sx={{color: orange}}/>
                                <Typography sx={{fontSize: defaultText, textAlign: 'left'}}>
                                    Your items could not be fulfilled automatically.
                                    To complete the process, manually fulfill the items in the e-commerce order details
                                    by
                                    selecting the UUCargo shipment with order ID: {shippingOrderInfo?.order_id}.
                                    <span> <Link
                                        to={`/business/e-commerce/order/${fulfillmentList?.[0]?.marketplace_order_id}`}
                                        style={{color: '#1D8B45'}}>Click here to fulfill</Link></span>
                                </Typography>
                            </Box>
                        </Grid>
                    }
                </Grid>
            </Box>
        </Container>
    )
}