import {Container, Snackbar} from "@mui/material";
import Typography from "@mui/material/Typography";
import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import TextField from "@mui/material/TextField";
import {useSelector} from "react-redux";
import LoadingButton from "@mui/lab/LoadingButton";
import {greenButtonContained, textContent} from "../../Utils/styleConfig";
import {useState} from "react";
import MuiAlert from "@mui/material/Alert";
import {MuiTelInput} from "mui-tel-input";
import {extractFullAddress, getMissingKeysFromObject, isValidatePhone} from "../../Utils/Helper";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Paper from "@mui/material/Paper";
import {makeStyles} from "@material-ui/core/styles";
import {NODE_ROUTE_URI} from "../../Utils/apiUrl";
import axios from "axios";
import {getAccessToken} from "../../Utils/doToken";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const businessTypeOption = [
    "E-Commerce",
    "Logistic Transportation",
    "Warehouse",
    "Solution Provider",
    "Other"
]

const useStyles = makeStyles({
    smallInput: {
        "& .MuiInputBase-input": {
            height: "4px", // Adjust the height as per your preference
            padding: "2px", // Adjust the padding as per your preference
            fontSize: "14px", // Adjust the font size as per your preference
        }
    }
});

export const BecomeABusiness = () => {

    const userInfo = useSelector((state) => state.user);
    const classes = useStyles();

    const [emailValidation, setEmailValidation] = useState(null);
    const [validation, setValidation] = useState(true);
    const [loading, setLoading] = useState(false);
    const [companyInformation, setCompanyInformation] = useState({
        companyName: '',
        companyEmail: '',
        companyPhone: '',
        companyAddress: '',
        companyAddress2: '',
        companyPostalCode: '',
        companyCity: '',
        companyProvince: '',
        companyCountry: '',
        businessType: '',
        displayAddress: {
            description: '',
            text: '',
            placeId: ''
        }
    });
    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [addressList, setAddressList] = useState([]);
    const [companyExistenceResult, setCompanyExistenceResult] = useState(null);
    const [companyExistenceError, setCompanyExistenceError] = useState(null);
    const [isCheck, setIsCheck] = useState(false);
    const [registerError, setRegisterError] = useState(null);
    const [isRegister, setIsRegister] = useState(false);

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const verifyCompanyExistence = async () => {
        setLoading(true);
        let requestURL = `${NODE_ROUTE_URI}/login_middleware/verifyIfCompanyExists`;

        let data = {
            companyEmail: companyInformation?.companyEmail,
            companyName: companyInformation?.companyName
        }

        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                data: data

            })

            setCompanyExistenceResult(result?.data?.result);
            setCompanyExistenceError("");
        } catch (e) {
            setCompanyExistenceError(e?.response);
        } finally {
            setLoading(false);
            setIsCheck(true);
        }
    }

    const handleRegisterPartner = async () => {
        setLoading(true);
        let requestURL = `${NODE_ROUTE_URI}/login_middleware/registerPartnerForExistingUser`;
        const accessToken = getAccessToken("access_token");

        let data = {
            companyEmail: companyInformation?.companyEmail,
            companyName: companyInformation?.companyName,
            companyPhone: companyInformation?.companyPhone,
            address: companyInformation?.companyAddress,
            postalCode: companyInformation?.companyPostalCode,
            city: companyInformation?.companyCity,
            province: companyInformation?.companyProvince?.code,
            companyType: companyInformation?.businessType,
            sendWelcomeEmail: true
        }

        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${accessToken}`
                },
                data: data
            })

            setRegisterError(false);
        } catch (e) {
            setRegisterError(true);
        } finally {
            setLoading(false);
            setIsRegister(true);
        }
    }

    const googleMapsPlaceAutocomplete = async (inputValue, type) => {
        let requestURL = `${NODE_ROUTE_URI}/maps_apis/placeAutocomplete`;

        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    input: inputValue,
                    types: type
                }
            })
            const {data: {data: {predictions}}} = result;
            console.log('prediction', predictions);
            const updatedPredictions = predictions?.map(each => {
                return {
                    description: each?.description,
                    text: each?.structured_formatting?.main_text,
                    placeId: each?.place_id
                }
            })
            console.log('updated prediction', updatedPredictions);

            return updatedPredictions;

        } catch (e) {
            console.log(e.response)
        }
    }

    const googleMapsPlaceDetails = async (placeId) => {
        let requestURL = `${NODE_ROUTE_URI}/maps_apis/placeDetails`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    placeId: placeId
                }
            });
            const place = result?.data?.data?.result;
            return place;

        } catch (e) {
            console.log(e.response)
        }
    }

    const validateEmailAddress = async (email) => {
        let requestURL = `${NODE_ROUTE_URI}/validateEmail`;
        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                data: {
                    email: email,
                    validateRegex: true,
                    validateMx: true,
                    validateTypo: false,
                    validateDisposable: false,
                    validateSMTP: false
                }
            })
            console.log('[validateEmailAddress] result - ', result);
            return result?.data
        } catch (e) {
            console.log(e.response)
        }
    }

    const handleChangeCompanyName = event => {
        setCompanyInformation(prevState => ({
            ...prevState,
            companyName: event?.target?.value,
        }))
    }

    const handleChangeCompanyEmail = event => {
        setCompanyInformation(prevState => ({
            ...prevState,
            companyEmail: event?.target?.value,
        }))
    }

    const handleInputChangeAddress = async (event, value) => {
        const predictionsAddress = await googleMapsPlaceAutocomplete(value, 'address');
        setAddressList(predictionsAddress);
    }

    const handleChangeAddress = async (event, value) => {
        const place = await googleMapsPlaceDetails(value?.placeId);
        if (place) {
            const address = extractFullAddress(place);
            setCompanyInformation(prevState => ({
                ...prevState,
                displayAddress: value,
                companyPostalCode: address?.zip,
                companyCity: address?.sublocality || address?.city || address?.administrative_area_level_3,
                companyProvince: address?.state,
                companyCountry: address?.country?.code,
                companyAddress: value?.text
            }))
        } else {
            setCompanyInformation(prevState => ({
                ...prevState,
                companyAddress: '',
                displayAddress: {
                    ...prevState?.displayAddress,
                    description: '',
                    placeId: '',
                    text: ''
                },
            }))
        }
    }

    const handleChangeCompanyAddress2 = event => {
        setCompanyInformation(prevState => ({
            ...prevState,
            companyAddress2: event?.target?.value,
        }))
    }

    const handleChangeCompanyPhone = value => {
        setCompanyInformation(prevState => ({
            ...prevState,
            companyPhone: value,
        }))
    }

    const handleChangeBusinessType = (event, value) => {
        setCompanyInformation(prevState => ({
            ...prevState,
            businessType: value
        }))
    }

    const validatePhone = () => {
        const result = isValidatePhone(companyInformation?.companyPhone?.replace(/\D/g, '')?.trim());
        return result;
    }

    const validate = async () => {
        const missingKeys = getMissingKeysFromObject({
            companyName: companyInformation?.companyName?.trim(),
            companyEmail: companyInformation?.companyEmail?.trim(),
            companyPhone: companyInformation?.companyPhone?.replace(/\D/g, '')?.trim(),
            companyAddress: companyInformation?.companyAddress?.trim(),
        })

        const emailCheck = await validateEmailAddress(companyInformation?.companyEmail?.trim());
        setEmailValidation(emailCheck);
        const phoneCheck = validatePhone();

        console.log('[validate] missing keys', missingKeys);

        if (missingKeys?.length === 0 &&
            emailCheck?.valid &&
            phoneCheck) {
            return true
        } else {
            return false
        }
    }

    const handleCheckAvailability = async () => {
        const validationResult = await validate();
        setValidation(validationResult);
        if (validationResult) {
            await verifyCompanyExistence();
        }
    }

    console.log('[BecomeABusiness] user info', userInfo);
    console.log('[BecomeABusiness] companyInformation', companyInformation);
    console.log('[BecomeABusiness] validation', validation);
    console.log('[BecomeABusiness] email validation', emailValidation);

    return (
        <Container maxWidth='xl'>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Box sx={{
                gap: '15px',
                display: 'flex',
                flexDirection: 'column',
            }}>
                <Typography style={{fontSize: '24px', fontWeight: 600, textAlign: 'left'}}>
                    Become A Business
                </Typography>
                <Box sx={{
                    backgroundColor: '#FFFFFF',
                    padding: '20px',
                    marginBottom: '20px',
                    borderRadius: '10px',
                    boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
                    border: '1px solid #D1D1D1',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '20px'
                }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography style={{fontSize: '18px', fontWeight: 600, textAlign: 'left'}}>
                                User Information
                            </Typography>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <FormControl fullWidth>
                                <FormLabel sx={{fontSize: '14px', textAlign: 'left'}}>
                                    User Id
                                </FormLabel>
                                <TextField
                                    value={userInfo?.id}
                                    size='small'
                                    inputProps={{style: {fontSize: 14}}}
                                    disabled={true}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <FormControl fullWidth>
                                <FormLabel sx={{fontSize: '14px', textAlign: 'left'}}>
                                    Email Account
                                </FormLabel>
                                <TextField
                                    value={userInfo?.email}
                                    size='small'
                                    inputProps={{style: {fontSize: 14}}}
                                    disabled={true}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography style={{fontSize: '18px', fontWeight: 600, textAlign: 'left'}}>
                                Company Information
                            </Typography>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <FormControl fullWidth>
                                <FormLabel sx={{fontSize: '14px', textAlign: 'left'}} required>
                                    Company Name
                                </FormLabel>
                                <TextField
                                    value={companyInformation?.companyName}
                                    size='small'
                                    inputProps={{style: {fontSize: 14}}}
                                    onInput={handleChangeCompanyName}
                                    error={!validation && !companyInformation?.companyName?.trim()}
                                    helperText={
                                        (!validation && !companyInformation?.companyName?.trim()) && "Required"
                                    }
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <FormControl fullWidth>
                                <FormLabel sx={{fontSize: '14px', textAlign: 'left'}} required>
                                    Company Email
                                </FormLabel>
                                <TextField
                                    value={companyInformation?.companyEmail}
                                    size='small'
                                    inputProps={{style: {fontSize: 14}}}
                                    onInput={handleChangeCompanyEmail}
                                    error={(!validation && (!companyInformation?.companyEmail?.trim() || !emailValidation?.valid))}
                                    helperText={
                                        (!validation && ((!companyInformation?.companyEmail?.trim()) ? "Required" :
                                            !emailValidation?.valid && (emailValidation?.validators?.regex?.reason || emailValidation?.validators?.mx?.reason || "Not Valid")))
                                    }
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <FormControl fullWidth>
                                <FormLabel sx={{fontSize: '14px', textAlign: 'left'}} required>
                                    Company Address
                                </FormLabel>
                                <Autocomplete
                                    freeSolo
                                    value={companyInformation?.displayAddress}
                                    fullWidth
                                    options={addressList}
                                    filterOptions={(options, state) => options}
                                    getOptionLabel={option => option?.description}
                                    onInputChange={handleInputChangeAddress}
                                    onChange={handleChangeAddress}
                                    PaperComponent={props => <Paper {...props} sx={{width: 400}}/>}
                                    renderInput={params => <TextField
                                        {...params}
                                        className={classes.smallInput}
                                        variant="outlined"
                                        error={(!validation && !companyInformation?.companyAddress?.trim())}
                                        helperText={
                                            ((!validation && !companyInformation?.companyAddress?.trim()) && "Required")
                                        }
                                    />}
                                    renderOption={option => (
                                        <Typography style={{fontSize: '12px'}}>
                                            {option.description}
                                        </Typography>
                                    )}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <FormControl fullWidth>
                                <FormLabel sx={{fontSize: '14px', textAlign: 'left'}}>
                                    Company Address 2
                                </FormLabel>
                                <TextField
                                    value={companyInformation?.companyAddress2}
                                    size='small'
                                    inputProps={{style: {fontSize: 14}}}
                                    onInput={handleChangeCompanyAddress2}
                                    // error={companyInfoMissing?.includes('companyName')}
                                    // helperText={
                                    //     companyInfoMissing?.includes('companyName') && "Required"
                                    // }
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <FormControl fullWidth>
                                <FormLabel sx={{fontSize: '14px', textAlign: 'left'}} required>
                                    Company Phone
                                </FormLabel>
                                <MuiTelInput
                                    value={companyInformation?.companyPhone}
                                    fullWidth
                                    variant="outlined"
                                    defaultCountry="CA"
                                    inputProps={{style: {fontSize: 14}}}
                                    onChange={handleChangeCompanyPhone}
                                    size='small'
                                    error={(!validation && (!companyInformation?.companyPhone?.trim() || !validatePhone()))}
                                    helperText={
                                        (!validation && (!companyInformation?.companyPhone?.trim() ? "Required" :
                                            !validatePhone() && 'Invalid'))
                                    }
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <FormControl fullWidth>
                                <FormLabel sx={{fontSize: '14px', textAlign: 'left'}}>
                                    Business Type
                                </FormLabel>
                                <Autocomplete
                                    value={companyInformation?.businessType}
                                    fullWidth
                                    options={businessTypeOption}
                                    onChange={handleChangeBusinessType}
                                    PaperComponent={props => <Paper {...props} sx={{width: 400}}/>}
                                    renderInput={(params) => <TextField
                                        variant="outlined"
                                        {...params}
                                        className={classes.smallInput}
                                        size='small'
                                        renderOption={option => (
                                            <Typography style={{fontSize: '12px'}}>
                                                {option}
                                            </Typography>
                                        )}
                                    />}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                gap: '10px'
                            }}>
                                <LoadingButton
                                    loading={loading}
                                    variant='contained'
                                    sx={greenButtonContained}
                                    size='small'
                                    onClick={handleCheckAvailability}
                                >
                                    <Typography sx={{
                                        textTransform: 'none',
                                        fontSize: textContent
                                    }}>
                                        Check Availability
                                    </Typography>
                                </LoadingButton>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            {
                                isCheck && (
                                    companyExistenceResult ?
                                        <Typography>
                                            The company already exists. You are not able to register a new company. If you
                                            wish to join the company, please contact our operation team at op@uucargo.ca
                                            for assistance.
                                        </Typography>
                                        :
                                        <Typography>
                                            Your entered company name and email do not exist in our system. You can continue
                                            to register the company and convert your individual account into a business
                                            account.
                                        </Typography>
                                )
                            }
                        </Grid>
                        <Grid item xs={12}>
                            {
                                (isCheck && !companyExistenceResult) &&
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    gap: '10px'
                                }}>
                                    <LoadingButton
                                        loading={loading}
                                        variant='contained'
                                        sx={greenButtonContained}
                                        size='small'
                                        onClick={handleRegisterPartner}
                                    >
                                        <Typography sx={{
                                            textTransform: 'none',
                                            fontSize: textContent
                                        }}>
                                            Covert to Business Account
                                        </Typography>
                                    </LoadingButton>
                                </Box>
                            }
                        </Grid>
                        <Grid item xs={12}>
                            {
                                isRegister && (
                                    registerError ?
                                        <Typography>
                                            We encountered an issue while attempting to register your company or convert
                                            your account to a business account. Please check your information and try again.
                                            If the issue persists, contact our operation team at op@uucargo.ca
                                            for assistance.
                                        </Typography>
                                        :
                                        <Typography>
                                            Your company has been successfully registered, and your account has been
                                            converted to a business account. To start using your business account,
                                            please log out and log back in.
                                        </Typography>

                                )
                            }
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Container>
    )
}