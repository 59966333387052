import {Container} from "@mui/material";
import Box from "@mui/material/Box";
import React, {useEffect, useRef, useState} from "react";
import {LandingIntro} from "./LandingIntro";
import {Estimate} from "../LandingPage/Estimate";
import {LoadingContainer} from "../shared";
import {EstimateCost} from "../LandingPage/EstimateCost";
import {useDispatch, useSelector} from "react-redux";
import Grid from "@mui/material/Grid";
import LandingImage from "../../images/CoverImage.png";
import {LandingMenuBar} from "./LandingMenuBar";

const styles = {
    LandingShippingRateCalculatorRoot: {
        display: 'flex',
        margin: '0 auto',
        '@media (max-width: 480px)': {
            flexDirection: "column", // for phone and above
        },
    },
    LandingIntroImage: {
        width: '95%',
        padding: '5%'
    },
}

export const LandingShippingRateCalculator = () => {

    let isLoading = useSelector(state => state?.landingEstimate?.landingEstimateCostLoading);
    let isClickEstimate = useSelector(state => state?.landingEstimate?.landingEstimateIsClickEstimate);
    let estimatePackageCost = useSelector(state => state?.landingEstimate?.landingEstimatePackageCost);

    const [isCalculate, setIsCalculate] = useState(false);

    const dispatch = useDispatch();

    const estimateSection = useRef(null);

    const handleConfirm = () => {
        setIsCalculate(true);
    }

    const handleCancel = () => {
        setIsCalculate(false);
    }

    const scrollDown = (ref) => {
        console.log(ref.current.offsetTop)
        window.scrollTo({
            top: ref.current.offsetTop + 1000,
            behavior: 'smooth',
        });
    };

    useEffect(() => {
        if (estimatePackageCost.length > 0 && estimatePackageCost.length < 5) {
            scrollDown(estimateSection);
        }
    }, [estimatePackageCost])

    // useEffect(() => {
    //     document.body.classList.add('white-background');
    //
    //     return () => {
    //         document.body.classList.remove('white-background');
    //     };
    // }, []);

    return (
        <Container maxWidth={false} style={{padding: '0px', marginTop: '-75px'}}>
            <Grid container spacing={0}>
                <Grid item xs={12} md={5}>
                    <Box
                        ref={estimateSection}
                        sx={{
                            display: 'flex',
                            padding: '20px',
                            flexDirection: 'column',
                            height: '100%',
                            gap: '40px'
                        }}
                    >
                        <LandingMenuBar/>
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            height: '100%'
                        }}>
                            {
                                isLoading ? <LoadingContainer/> :
                                    isCalculate ? <EstimateCost handleCancel={handleCancel}/> :
                                        <Estimate handleConfirm={handleConfirm}/>
                            }
                        </Box>
                    </Box>
                </Grid>
                <LandingIntro/>
            </Grid>
        </Container>
    )
}