import {useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {NODE_ROUTE_URI} from "../../../Utils/apiUrl";
import axios from "axios";
import {getOrderDashboardCrossBorder} from "../../../actions/orderDashboardAction";
import {getAccessToken} from "../../../Utils/doToken";
import {Container} from "@mui/material";
import Grid from "@mui/material/Grid";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {PackageOrderDashboardTransaction} from "./PackageOrderDashboardTransaction";
import {PackageOrderDashboardTransactionDetails} from "./PackageOrderDashboardTransactionDetails";
import {PackageOrderDashboardDocuments} from "./PackageOrderDashboardDocuments";
import {PackageOrderDashboardCrossBorder} from "./PackageOrderDashboardCrossBorder";
import {PackageOrderDashboardAdjustOrder} from "./PackageOrderDashboardAdjustOrder";
import {PackageOrderDashboardPickupAppointment} from "./PackageOrderDashboardPickupAppointment";
import intl from "react-intl-universal";
import {PackageOrderDashboardCost} from "./PackageOrderDashboardCost";

export const PackageOrderDashboard = () => {

    const accessToken = getAccessToken("access_token");

    const {orderId} = useParams();
    const {firstname, id} = useSelector((state) => state.user);

    const [selectTransactionData, setSelectedTransactionData] = useState(null);
    const [orderInfo, setOrderInfo] = useState();

    const cbHandleSelectedTransactionData = (transactionData) => {
        setSelectedTransactionData(transactionData);
    }

    const getOrderInfoByOrderId = async () => {
        let requestURL = `${NODE_ROUTE_URI}/shippingOrderDetailsDashboard/endUser/order`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${accessToken}`
                },
                params: {
                    orderId: orderId
                }
            })

            setOrderInfo(result.data.result[0]);
        } catch (e) {
            console.log(e.response);
        }
    }

    useEffect(() => {
        getOrderInfoByOrderId();
    }, [orderId]);

    console.log('order info', orderInfo);

    return (
        <Container maxWidth='xl'>
            <Box sx={{
                marginBottom: '40px'
            }}>
                <Grid container spacing={4}>
                    <Grid item xs={8}>
                        <Box sx={{display: 'flex', justifyContent: 'flex-start', gap: '5px', alignItems: 'center'}}>
                            <CheckCircleIcon style={{color: '#1D8B45', fontSize: '30px'}}/>
                            <Typography style={{fontSize: '20px', fontWeight: '600'}}>
                                Your Order was successfully placed! <span
                                style={{color: '#1D8B45'}}> Order ID: {orderId}</span>
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={4}>
                        <Box sx={{display: 'flex', justifyContent: 'flex-start', gap: '5px', alignItems: 'center'}}>
                            <Typography style={{fontSize: '20px', fontWeight: '600'}}><span
                                style={{color: '#1D8B45'}}> Order Cost: $ {orderInfo?.prices?.final_total?.toFixed(2)} {orderInfo?.cross_border_shipment_type && 'CAD'}</span>
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item md={8} xs={12}>
                        <Grid container spacing={2}>
                            <Grid item md={12} xs={12}>
                                <PackageOrderDashboardTransaction
                                    orderId={orderId}
                                    orderInfo={orderInfo}
                                    cbHandleSelectedTransactionData={cbHandleSelectedTransactionData}
                                />
                            </Grid>
                            <Grid item md={12} xs={12}>
                                <PackageOrderDashboardTransactionDetails orderId={orderId}
                                                                         selectTransactionData={selectTransactionData}/>
                            </Grid>
                            <Grid item xs={12}>
                                <PackageOrderDashboardPickupAppointment orderId={orderId}/>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={4} xs={12}>
                        <Grid container spacing={2}>
                            <Grid item md={12} xs={12}>
                                <PackageOrderDashboardCost orderInfo={orderInfo}/>
                            </Grid>
                            <Grid item md={12} xs={12}>
                                <PackageOrderDashboardDocuments orderId={orderId}
                                                                orderInfo={orderInfo}/>
                            </Grid>
                            <Grid item xs={12}>
                                <PackageOrderDashboardCrossBorder orderId={orderId}
                                                                  orderInfo={orderInfo}/>
                            </Grid>
                            <Grid item xs={12}>
                                <PackageOrderDashboardAdjustOrder orderId={orderId}/>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    )
}