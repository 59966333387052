import React, {Fragment, useEffect, useState} from "react";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import TextField from "@mui/material/TextField";
import intl from "react-intl-universal";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import LoadingButton from "@mui/lab/LoadingButton";
import {NODE_ROUTE_URI} from "../../Utils/apiUrl";
import axios from "axios";

export const WordPressQuoteMovingItems = ({cbHandleShippingDetails, validatedResult, lng}) => {

    const [askMessage, setAskMessage] = useState({
        role: 'user',
        content: ''
    });

    const handleMovingDescription = (event) => {
        setAskMessage(prevState => ({
            ...prevState,
            content: event?.target?.value
        }));
    }

    useEffect(() => {
        cbHandleShippingDetails(askMessage);
    }, [askMessage])

    return (
        <Fragment>
            <Grid item xs={12}>
                <FormControl fullWidth>
                    <FormLabel required sx={{textAlign: 'left'}}>
                        {lng === 'cn' ? '简单填写搬运的物品' : "Simply enter what you want to move"}
                    </FormLabel>
                    <TextField
                        value={askMessage?.content}
                        onInput={handleMovingDescription}
                        fullWidth
                        multiline
                        rows={5}
                        variant="outlined"
                        size='small'
                        error={validatedResult !== null && !askMessage?.content?.trim()}
                        helperText={
                            validatedResult !== null && !askMessage?.content?.trim()
                                ? (lng === 'cn' ? "必填" : "Required")
                                : `${askMessage?.content?.length || 0}/350`
                        }
                        inputProps={{maxLength: 350}} // Set max length to 350 characters
                        placeholder={lng === 'cn' ? "请按'品名:数量'格式列明（例如：沙发:1，餐桌:1，椅子:4，电视:2，灯具:3）" : "List items with quantities, e.g., Sofa:1, Dining Table:1, Chair:4, TV:2, Lamp:3"}
                    />
                </FormControl>
            </Grid>
        </Fragment>
    )
}