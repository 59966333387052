import {Divider, Snackbar, useMediaQuery} from "@mui/material";
import {useState} from "react";
import MuiAlert from "@mui/material/Alert";
import * as React from "react";
import {PARTNER_URI} from "../../../Utils/apiUrl";
import axios from "axios";
import Grid from "@material-ui/core/Grid";
import Typography from "@mui/material/Typography";
import {ReactComponent as SuccessSubmitLogo} from "../../../images/crossborderIcon/SuccessSubmit.svg";
import {ReactComponent as InvoiceLogo} from "../../../images/crossborderIcon/Invoice.svg";
import Box from "@mui/material/Box";
import {BusinessCrossBorderDashboardInvoiceForm} from "./BusinessCrossBorderDashboardInvoiceForm";
import {Fragment} from "react";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const BusinessCrossBorderDashboardInvoiceList = ({
                                                            itemList,
                                                            trackingList,
                                                            invoiceStatus,
                                                            invoiceList,
                                                            isCreate,
                                                            closeCreateInvoice,
                                                            addInvoiceList,
                                                            removeInvoiceList,
                                                            shippingListData,
                                                            cbGetTrackingList,
                                                            clearInvoiceList,
                                                            getTrackingList,
                                                            loadingGetTrackList,
                                                            errorGetTrackList,
                                                            shipmentType,
                                                            getDocumentList
                                                        }) => {

    const isMobile = useMediaQuery('(max-width: 480px)');

    const [loading, setLoading] = useState(false);
    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const handleRemove = (invoice) => {
        removeInvoiceList(invoice);
    }

    const createCommercialInvoice = async () => {

        setLoading(true);
        let requestURL = `${PARTNER_URI}/commercialInvoice/generateCommercialInvoiceAndUpload/ups`;

        let data = {
            invoices: invoiceList
        }

        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                data: data
            });

            console.log('create invoice', result?.data);
            setErrorMessage('');
            setSuccessMessage('Successfully create invoices');
            handleSuccessMessage();
            setLoading(false);
            setTimeout(() => {
                getTrackingList();
                clearInvoiceList();
                cbGetTrackingList();
                getDocumentList();
                // window.location.reload();
            }, 2000);

        } catch (e) {
            console.log('e', e.response);
            setErrorMessage('Fail to create invoices');
            handleErrorMessage();
            setLoading(false);
        }
    }

    const handleCreateInvoice = () => {
        createCommercialInvoice();
    }

    if (invoiceStatus) {
        return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Divider/>
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{
                        display: 'flex',
                        minHeight: '200px',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%',
                        flexDirection: 'column'
                    }}>
                        <SuccessSubmitLogo width="75px" height="75px"/>
                        <Typography>
                            Invoices Submitted Successfully
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Divider/>
                </Grid>
            </Grid>
        )
    }

    if (isCreate) {
        return (
            <BusinessCrossBorderDashboardInvoiceForm
                itemList={itemList}
                trackingList={trackingList}
                invoiceList={invoiceList}
                closeCreateInvoice={closeCreateInvoice}
                addInvoiceList={addInvoiceList}
                shippingListData={shippingListData}
                loadingGetTrackList={loadingGetTrackList}
                errorGetTrackList={errorGetTrackList}
                shipmentType={shipmentType}
            />
        )
    }

    if (invoiceList?.length === 0) {
        return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Divider/>
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{
                        display: 'flex',
                        minHeight: '200px',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%',
                        flexDirection: 'column'
                    }}>
                        <InvoiceLogo width="75px" height="75px"/>
                        <Typography>
                            Your Commercial Invoice list is empty now
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Divider/>
                </Grid>
            </Grid>
        )
    }

    console.log('[BusinessCrossBorderDashboardInvoiceList] invoice list', invoiceList);

    return (
        <Fragment>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Divider/>
                </Grid>
                {!isMobile &&
                    <>
                        <Grid item md={2}>
                            <Typography style={{textAlign: 'center', fontSize: '14px'}}>
                                Invoice Number
                            </Typography>
                        </Grid>
                        <Grid item md={3}>
                            <Typography style={{textAlign: 'center', fontSize: '14px'}}>
                                Invoice For
                            </Typography>
                        </Grid>
                        <Grid item md={2}>
                            <Typography style={{textAlign: 'center', fontSize: '14px'}}>
                                Amount
                            </Typography>
                        </Grid>
                        <Grid item md={2}>
                            <Typography style={{textAlign: 'center', fontSize: '14px'}}>
                                Item Qty
                            </Typography>
                        </Grid>
                        <Grid item md={2}>
                            <Typography style={{textAlign: 'center', fontSize: '14px'}}>
                                Item Weight
                            </Typography>
                        </Grid>
                        <Grid item md={1}>
                            <Typography style={{textAlign: 'center', fontSize: '14px'}}>
                                Action
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider/>
                        </Grid>
                    </>
                }
                {
                    invoiceList?.map((element, index) => (
                        <Fragment key={index}>
                            <Grid item md={2} xs={12}>
                                <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                    {isMobile && 'Invoice No: '}{element?.invoiceNumber}
                                </Typography>
                            </Grid>
                            <Grid item md={3} xs={12}>
                                <Box sx={{
                                    display: 'flex',
                                    gap: '5px'
                                }}>
                                    {
                                        isMobile &&
                                        <Typography key={index} style={{textAlign: 'left', fontSize: '14px'}}>Tracking
                                            No:</Typography>
                                    }
                                    <Box>
                                        {
                                            element?.trackingNumbers?.map((each, index) => (
                                                <Typography key={index} style={{textAlign: 'left', fontSize: '14px'}}>
                                                    {each}
                                                </Typography>
                                            ))
                                        }
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item md={2} xs={12}>
                                <Typography style={{textAlign: isMobile ? 'left' : 'center', fontSize: '14px'}}>
                                    {isMobile && 'Amount: '}$ {element?.invoiceTotalAmount.toFixed(2)}
                                </Typography>
                            </Grid>
                            <Grid item md={2} xs={12}>
                                <Typography style={{textAlign: isMobile ? 'left' : 'center', fontSize: '14px'}}>
                                    {isMobile && 'Item Qty: '}{element?.totalQuantity}
                                </Typography>
                            </Grid>
                            <Grid item md={2} xs={12}>
                                <Typography style={{textAlign: isMobile ? 'left' : 'center', fontSize: '14px'}}>
                                    {isMobile && 'Item Weight: '}{element?.totalWeight} {element?.items[0]?.weightUnit}
                                </Typography>
                            </Grid>
                            <Grid item md={1} xs={12}>
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-end'
                                }}>
                                    <Button
                                        variant='contained'
                                        sx={{
                                            backgroundColor: '#C70039',
                                            "&:hover": {
                                                backgroundColor: '#C70039',
                                                filter: 'brightness(0.9)'
                                            }
                                        }}
                                        size='small'
                                        onClick={() => handleRemove(element)}
                                    >
                                        <Typography style={{textTransform: 'none', fontSize: '14px'}}>
                                            Delete
                                        </Typography>
                                    </Button>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider/>
                            </Grid>
                        </Fragment>
                    ))
                }
                <Grid item xs={12}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        padding: '20px'
                    }}>
                        <LoadingButton
                            variant='contained'
                            sx={{
                                backgroundColor: '#1D8B45',
                                "&:hover": {
                                    backgroundColor: '#1D8B45',
                                    filter: 'brightness(0.9)'
                                }
                            }}
                            loading={loading}
                            onClick={handleCreateInvoice}
                        >
                            <Typography style={{textTransform: 'none', fontSize: '16px'}}>
                                Create and Upload
                            </Typography>
                        </LoadingButton>
                    </Box>
                </Grid>
            </Grid>
        </Fragment>
    )
}