import React, {useEffect, useState} from "react";
import {Provider} from "react-redux";
import {BrowserRouter} from "react-router-dom";
import store from "./store/";
import {createMuiTheme} from '@material-ui/core/styles';
import "bootstrap/dist/css/bootstrap.min.css";

import "./styles/styles.css";
import Routes from "./routes/routes";
import MainContainer from "./components/mainContainer";
import * as intl from "react-intl-universal";
import enUS from '../src/locales/en-US.json';
import zhCN from '../src/locales/zh-CN.json';
import {green, light_green, yellow} from "./Utils/styleConfig";
import {CssBaseline, ThemeProvider} from "@material-ui/core";

const theme = createMuiTheme({
    palette: {
        primary: {
            main: green,
        },
        secondary: {
            main: yellow,
        },
    },
    overrides: {
        MuiCssBaseline: {
            '@global': {
                body: {
                    backgroundColor: '#ffffff', // Set background color to white
                },
            },
        },
    },
});

// ReactGA.initialize("G-48C69B3M9H");

const LOCALES_LIST = [
    {
        label: "English",
        value: "en-US",
    },
    {
        label: "简体中文",
        value: "zh-CN",
    },
];

const LOCALE_DATA = {
    "en-US": enUS,
    "zh-CN": zhCN,
}

function App() {

    const [initDone, setInitDone] = useState(false);

    const initializeIntl = () => {

        // 1. Get the currentLocale from cookie and browsers settings
        let currentLocale = intl.determineLocale({
            cookieLocaleKey: 'lang',
            localStorageLocaleKey: 'lang'
        })

        console.log('current locale', currentLocale);

        // 2. Fallback to "en-US" if the currentLocale isn't supported in LOCALES_LIST
        if (!LOCALES_LIST.some(item => item.value === currentLocale)) {
            currentLocale = "en-US"
        }

        // 3. Set currentLocale and load locale data
        setCurrentLocale(currentLocale);

        // 4. After loading locale data, start to render
        setInitDone(true);

    }

    const setCurrentLocale = (currentLocale) => {
        intl.init({
            currentLocale,
            locales: LOCALE_DATA,
        });
    };

    useEffect(() => {
        initializeIntl();
    }, [])

    return (
        <div>
            <ThemeProvider theme={theme}>
                <CssBaseline/>
                <Provider store={store}>
                    <BrowserRouter>
                        <Routes/>
                    </BrowserRouter>
                </Provider>
            </ThemeProvider>
        </div>
    );
}

export default App;
