import * as React from 'react';
import PropTypes from 'prop-types';
import {styled} from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Check from '@mui/icons-material/Check';
import SettingsIcon from '@mui/icons-material/Settings';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import VideoLabelIcon from '@mui/icons-material/VideoLabel';
import StepConnector, {stepConnectorClasses} from '@mui/material/StepConnector';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Typography from "@mui/material/Typography";
import {Backdrop, Button, IconButton} from "@mui/material";
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import {useHistory} from "react-router-dom";
import {useEffect} from "react";
import Box from "@mui/material/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import {green, grey, light_green} from "./styleConfig";

export const steps = ['Shipping Info', 'Choose Carrier', 'Order Overview', 'Place Order'];

export const InternationalSteps = ['Shipping Info', 'Choose Carrier', 'Cross Border', 'Order Overview', 'Place Order'];

export const ClaimSteps = ['Tracking Number', 'Shipment Information', 'Claim Details', 'Review & Send'];

export const QontoConnector = styled(StepConnector)(({theme}) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 10,
        left: 'calc(-50% + 16px)',
        right: 'calc(50% + 16px)',
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: '#1D8B45',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: '#1D8B45',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#C7C7C7',
        // borderColor: '#1D8B45',
        borderTopWidth: 3,
        borderRadius: 1,
    },
}));

export const QontoStepIconRoot = styled('div')(({theme, ownerState}) => ({
    color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#C7C7C7',
    display: 'flex',
    height: 22,
    alignItems: 'center',
    ...(ownerState.active && {
        color: '#1D8B45',
    }),
    '& .QontoStepIcon-completedIcon': {
        color: '#1D8B45',
        zIndex: 1,
        fontSize: 30,
    },
    '& .QontoStepIcon-circle': {
        width: 25,
        height: 25,
        borderRadius: '50%',
        border: ownerState.active ? '2px solid #1D8B45' : '2px solid #C7C7C7',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
}));

export const QontoStepIcon = (props) => {
    const {active, completed, className, icon} = props;
    return (
        <QontoStepIconRoot ownerState={{active}} className={className}>
            {
                completed ? (<CheckCircleIcon className="QontoStepIcon-completedIcon"/>) :
                    (
                        <div className="QontoStepIcon-circle">
                            <Typography sx={{fontSize: '14px',}}>
                                {icon}
                            </Typography>
                        </div>
                    )
            }
        </QontoStepIconRoot>
    );
}

QontoStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
};

export const ColorlibConnector = styled(StepConnector)(({theme}) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundImage:
                'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundImage:
                'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: 3,
        border: 0,
        backgroundColor:
            theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
        borderRadius: 1,
    },
}));

export const ColorlibStepIconRoot = styled('div')(({theme, ownerState}) => ({
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
        backgroundImage:
            'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }),
    ...(ownerState.completed && {
        backgroundImage:
            'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    }),
}));

export const ColorlibStepIcon = (props) => {
    const {active, completed, className} = props;

    const icons = {
        1: <SettingsIcon/>,
        2: <GroupAddIcon/>,
        3: <VideoLabelIcon/>,
    };

    return (
        <ColorlibStepIconRoot ownerState={{completed, active}} className={className}>
            {icons[String(props.icon)]}
        </ColorlibStepIconRoot>
    );
}

ColorlibStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node,
};

export const BackButton = () => {

    const history = useHistory();

    const handleBack = () => {
        history.goBack();
        setTimeout(() => {
            window.scrollTo(0, 0); // Scroll to the top after navigating back
        }, 0);
    }

    return (
        <IconButton onClick={handleBack}>
            <KeyboardReturnIcon sx={{color: '#454545'}}/>
        </IconButton>
    )
}

export const LoadingComponent = ({loading, message}) => {
    return (
        <Backdrop
            sx={(theme) => ({zIndex: theme.zIndex.drawer + 1})}
            open={loading}
        >
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center'
            }}>
                <Typography sx={{color: grey}}>
                    {message}
                </Typography>
                <CircularProgress size={50}/>
            </Box>
        </Backdrop>
    )
}

export const VerticalBar = ({color}) => {

    console.log('[VerticalBar] color', color);
    return (
        <Box
            sx={{
                backgroundColor: `${color}`,
                width: '2px',
                height: '20px',
                padding: 0,
                margin: 0
            }}
        />
    )
}

