import {useHistory, useLocation, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {NODE_ROUTE_URI, PARTNER_URI} from "../../Utils/apiUrl";
import axios from "axios";
import {getAccessToken} from "../../Utils/doToken";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {green, greenButtonContained, sectionHeading, subHeading} from "../../Utils/styleConfig";
import * as React from "react";
import {Button, Container} from "@mui/material";
import CircularProgress from "@material-ui/core/CircularProgress";
import Box from "@mui/material/Box";
import {useSelector} from "react-redux";

export const MarketplaceAuth = () => {

    const ownerRole = useSelector(state => state?.user?.ownerRole);
    const storedToken = getAccessToken("access_token");

    const history = useHistory();
    const {search} = useLocation();
    const {platform} = useParams();
    const {pathname} = useLocation();
    const domainWithPort = window.location.host;

    // Parse query parameters
    const queryParams = new URLSearchParams(search);

    // Get specific query parameters
    const code = queryParams.get("code");
    const hmac = queryParams.get("hmac");
    const host = queryParams.get("host");
    const shop = queryParams.get("shop");
    const timestamp = queryParams.get("timestamp");

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");

    const storeMarketplaceAccessTokenAndShopInfo = async () => {
        try {
            setLoading(true);

            let requestURL = ownerRole === 3 ? `${PARTNER_URI}/marketplace/storeMarketplaceAccessTokenAndStoreInfo` : `${NODE_ROUTE_URI}/marketplace/storeMarketplaceAccessTokenAndStoreInfo`;
            const shopName = shop?.split('.')?.[0];

            let
                data = {
                    marketplace: platform,
                    shopName: shopName,
                    authorizationCode: code
                }

            const result = await axios({
                method: 'post',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${storedToken}`
                },
                data: data
            })

            console.log('[storeMarketplaceAccessTokenAndShopInfo] result', result);
            // history?.push('/')
            setError('');

        } catch (e) {

            console.log('[storeMarketplaceAccessTokenAndShopInfo] e response', e?.response);

            setError(e?.response);

        } finally {
            setLoading(false);
        }
    }

    const handleStoreConnectionPage = () => {
        history.push(`${ownerRole === 3 ? '/business/' : '/'}e-commerce/store`)
    }

    useEffect(() => {

        if (ownerRole) {
            if (ownerRole === 1) {
                setError("We are unable to connect to the store at the moment because the logged-in account is an individual account, not a company account.");
            } else {
                if (platform === 'shopify') {
                    if (shop && ownerRole) {
                        (async () => {
                            await storeMarketplaceAccessTokenAndShopInfo();
                        })()
                    }
                } else if (platform === 'wix') {
                    (async () => {
                        await storeMarketplaceAccessTokenAndShopInfo();
                    })()
                } else {
                    (async () => {
                        // another one
                        // await storeMarketplaceAccessTokenAndShopInfo();
                    })()
                }
            }
        }
    }, [shop, code, platform, ownerRole])

    // useEffect(() => {
    //     if (!ownerRole) {
    //         history.push('/sign-in');
    //     }
    // },[ownerRole])

    // console.log('[MarketplaceAuth] platform', platform);
    // console.log('[MarketplaceAuth] pathname', pathname);
    // console.log('[MarketplaceAuth] domainWithPort', domainWithPort);
    // console.log('[MarketplaceAuth] search', search);
    // console.log('[MarketplaceAuth] code', code);
    // console.log('[MarketplaceAuth] shop', shop);
    // console.log('[MarketplaceAuth] ownerRole', ownerRole);

    return (
        <Container maxWidth='xl'>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography sx={{fontSize: sectionHeading, fontWeight: '600', textAlign: 'center'}}>
                        Store Connection
                    </Typography>
                </Grid>
                {
                    loading ?
                        <Grid item xs={12}>
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'center'
                            }}>
                                <CircularProgress/>
                            </Box>
                        </Grid>
                        :
                        error ?
                            <>
                                <Grid item xs={12}>
                                    <Typography>
                                        The connection to the {platform} store "{shop?.split('.')?.[0]}" could not be
                                        established. Please check your network settings or ensure that the store
                                        credentials are correct, and try again. Click the button below to return to the
                                        store page and reconnect.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box sx={{
                                        display: 'flex',
                                        justifyContent: 'flex-end'
                                    }}>
                                        <Button
                                            variant='contained'
                                            sx={greenButtonContained}
                                            onClick={handleStoreConnectionPage}
                                        >
                                            <Typography sx={{textTransform: 'none'}}>
                                                Store Connection
                                            </Typography>
                                        </Button>
                                    </Box>
                                </Grid>
                            </>
                            :
                            <>
                                <Grid item xs={12}>
                                    <Typography sx={{textAlign: 'center', fontSize: subHeading}}>
                                        The connection to the {platform} store has been successfully established.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box sx={{
                                        display: 'flex',
                                        justifyContent: 'flex-end'
                                    }}>
                                        <Button
                                            variant='contained'
                                            sx={greenButtonContained}
                                            onClick={handleStoreConnectionPage}
                                        >
                                            <Typography sx={{textTransform: 'none'}}>
                                                Store List
                                            </Typography>
                                        </Button>
                                    </Box>
                                </Grid>
                            </>
                }
            </Grid>
        </Container>
    )
}