import * as React from "react";
import MuiAlert from "@mui/material/Alert";
import {getAccessToken} from "../../Utils/doToken";
import {useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import {useEffect, useRef, useState} from "react";
import {NODE_ROUTE_URI, PARTNER_URI} from "../../Utils/apiUrl";
import axios from "axios";
import {generateRandomString} from "../../Utils/Helper";
import Box from "@mui/material/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import {Checkbox, MenuItem, Select, Snackbar} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import TextField from "@mui/material/TextField";
import LoadingButton from "@mui/lab/LoadingButton";
import {greenButtonContained} from "../../Utils/styleConfig";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const CrossBorderDashboardDocumentUpload = ({closeIsUpload, getDocumentList}) => {

    const storedToken = getAccessToken("access_token");

    const {order_id} = useParams();
    const partnerInfo = useSelector((state) => state.user);
    const inputPDFFile = useRef(null);

    const [availableTrackingList, setAvailableTrackingList] = useState([]);
    const [loadingGetTrackList, setLoadingGetTrackList] = useState(false);
    const [errorGetTrackList, setErrorGetTrackList] = useState(false);
    const [documentTypeList, setDocumentTypeList] = useState([]);
    const [loadingGetDocumentTypeList, setLoadingGetDocumentTypeList] = useState(false);
    const [errorGetDocumentTypeList, setErrorGetDocumentTypeList] = useState(false);
    const [documentType, setDocumentType] = useState();
    const [fileObj, setFileObj] = useState('');
    const [uploadLoading, setUploadLoading] = useState(false);
    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const uploadPDFToServer = async ({fileObj}) => {
        console.log('[uploadPDFToServer] fileObj', fileObj);
    }

    const onPDFDrop = async (e) => {

        console.log('[onPDFDrop] e.target.files[0]', e);

        if (!(e.target.files && e.target.files[0])) {
            return;
        }
        setFileObj(e.target.files[0]);
        // setPDFFileName(e.target.files[0].name);
        console.log('[onPDFDrop] e.target.files[0]', e.target.files[0]);

        await uploadPDFToServer({fileObj: e.target.files[0]});

    };

    const getTrackingList = async () => {
        setLoadingGetTrackList(true);
        let requestURI = `${NODE_ROUTE_URI}/commercialInvoice/getCrossBorderTrackingViewListByShippingOrderId`;
        try {
            const results = await axios({
                method: 'get',
                url: requestURI,
                headers: {
                    Authorization: `Bearer ${storedToken}`
                },
                params: {
                    shippingOrderId: order_id
                }
            })

            const {data} = results;

            console.log('get tracking list', data);

            const updatedTrackingList = [];

            data.forEach(element => {
                const randomId = generateRandomString(8);
                let object = {
                    trackingId: randomId,
                    isChecked: true,
                    trackingNumber: element?.tracking_no,
                    tranId: element?.tran_id,
                    shippingIdNumber: element?.shipping_id_no,
                    description: element?.item_name,
                    unitPrice: element?.unit_price,
                    quantity: 1,
                    UOM: element?.uom,
                    weight: element?.weight,
                    hsCode: element?.hs_code,
                    originalCountry: element?.origi_country,
                    currency: element?.currency_code,
                    weightUnit: element?.weight_unit,
                    length: element?.length,
                    height: element?.height,
                    width: element?.width,
                    dimensionUnit: element?.dimension_unit
                }
                updatedTrackingList.push(object);
            })
            console.log('update tracking list', updatedTrackingList);
            setAvailableTrackingList(updatedTrackingList);
            setErrorGetTrackList(false);
        } catch (e) {
            console.log(e)
            setErrorGetTrackList(true);
        } finally {
            setLoadingGetTrackList(false);
        }
    }

    const getCrossBorderDocumentTypeList = async () => {
        try {
            setLoadingGetDocumentTypeList(true);
            let requestURI = `${PARTNER_URI}/commercialInvoice/getCrossBorderDocumentTypeList`;
            const results = await axios({
                method: 'get',
                url: requestURI,
            })

            setDocumentTypeList(results?.data);
            setErrorGetDocumentTypeList(false);
        } catch (e) {
            setErrorGetDocumentTypeList(true)
        } finally {
            setLoadingGetDocumentTypeList(false);
        }
    }

    const handleCheckTracking = (e) => {
        console.log(e.target.value);
        const updateId = e.target.value;

        const updatedTrackingList = availableTrackingList?.map(item => {
            if (item?.trackingId === updateId) {
                return {...item, isChecked: !item.isChecked};
            } else {
                return item;
            }
        });
        setAvailableTrackingList(updatedTrackingList);
    }

    const handleChangeDocument = (e) => {
        setDocumentType(e?.target?.value);
    }

    const uploadDocument = async () => {
        try {
            setUploadLoading(true);

            let requestURI = `${NODE_ROUTE_URI}/commercialInvoice/uploadPaperlessDocument`;

            const trackingNumbers = availableTrackingList
                .filter(item => item.isChecked) // Keep only items where isChecked is true
                .map(item => item.trackingNumber); // Extract the trackingNumber property

            const formData = new FormData();

            trackingNumbers?.forEach(tracking => {
                formData.append('trackingNumbers', tracking);
            })
            formData.append("docType", documentType);
            formData.append('file', fileObj);

            const result = await axios.post(requestURI, formData, {
                headers: {
                    Authorization: `Bearer ${storedToken}`,
                },
            });

            setErrorMessage('');
            setSuccessMessage('Upload Document Successfully');
            handleSuccessMessage();
            return true;

        } catch (e) {
            console.log('[handleUploadDocument] error', e?.response);
            setErrorMessage('Fail To Upload Document');
            handleErrorMessage();
            return false;
        } finally {
            setUploadLoading(false);
        }
    }

    const handleUploadDocument = async () => {
        const uploadResult = await uploadDocument();
        if (uploadResult) {
            setTimeout(() => {
                getDocumentList();
                closeIsUpload();
            }, 2000);
        }
    }

    useEffect(() => {
        getTrackingList();
        getCrossBorderDocumentTypeList();
    }, [])

    if (loadingGetTrackList || loadingGetDocumentTypeList) {
        return (
            <Box sx={{
                display: 'flex',
                justifyContent: 'center'
            }}>
                <CircularProgress/>
            </Box>
        )
    }

    if ((!loadingGetTrackList && errorGetTrackList) || (!loadingGetDocumentTypeList && errorGetDocumentTypeList)) {
        return (
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
            }}>
                <Typography sx={{fontSize: '16px'}}>
                    Sorry We currently encounter some issues, please try again later.
                </Typography>
            </Box>
        )
    }



    return (
        <Grid container spacing={2}>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Grid item xs={12}>
                <FormControl fullWidth>
                    <FormLabel>
                        <Typography sx={{fontSize: '16px', fontWeight: '600', textAlign: 'left'}}>
                            Choose Package
                        </Typography>
                    </FormLabel>
                    <Grid container spacing={1}>
                        {
                            availableTrackingList?.length > 0 ? availableTrackingList?.map((element, index) => {
                                    return (
                                        <Grid item md={4} xs={12} key={index}>
                                            <Box sx={{
                                                display: 'flex',
                                                alignItems: 'center'
                                            }}>
                                                <Checkbox
                                                    checked={element?.isChecked}
                                                    onChange={handleCheckTracking}
                                                    value={element?.trackingId}
                                                />
                                                <Typography style={{fontSize: '14px'}}>
                                                    {element?.trackingNumber}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    )
                                }) :
                                <Grid item xs={12}>
                                    <Typography style={{fontSize: '16px', textAlign: 'center'}}>
                                        No Available Tracking Numbers
                                    </Typography>
                                </Grid>
                        }
                    </Grid>
                </FormControl>
            </Grid>
            <Grid item xs={6}>
                <FormControl fullWidth>
                    <FormLabel>
                        <Typography sx={{fontSize: '16px', fontWeight: '600', textAlign: 'left'}}>
                            Choose Document Type
                        </Typography>
                    </FormLabel>
                    <Select
                        value={documentType}
                        onChange={handleChangeDocument}
                        size='small'
                    >
                        {
                            documentTypeList?.map((document, index) => <MenuItem key={index} value={document?.value}>
                                {document?.name}
                            </MenuItem>)
                        }
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={6}>
                <FormControl fullWidth>
                    <FormLabel>
                        <Typography sx={{fontSize: '16px', fontWeight: '600', textAlign: 'left'}}>
                            Upload File
                        </Typography>
                    </FormLabel>
                    <input type="file" style={{display: "none"}} ref={inputPDFFile} onChange={onPDFDrop} accept=".pdf"
                           name="file"/>
                    <TextField
                        value={fileObj?.name}
                        size='small'
                        // disabled={true}
                        onClick={() => {
                            inputPDFFile.current.click()
                        }}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'flex-end'
                }}>
                    <LoadingButton
                        variant='contained'
                        sx={greenButtonContained}
                        size='small'
                        loading={uploadLoading}
                        onClick={handleUploadDocument}
                        disabled={!documentType || !fileObj || availableTrackingList?.every(tracking => tracking?.isChecked === false)}
                    >
                        <Typography sx={{textTransform: 'none', fontSize: '14px'}}>
                            Upload
                        </Typography>
                    </LoadingButton>
                </Box>
            </Grid>
        </Grid>
    )
}