import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import intl from "react-intl-universal";
import React, {useEffect, useState} from "react";
import {Divider, IconButton, Modal} from "@mui/material";
import Grid from "@mui/material/Grid";
import LoadingButton from "@mui/lab/LoadingButton";
import UploadIcon from "@mui/icons-material/Upload";
import FormHelperText from "@mui/material/FormHelperText";
import ClearIcon from "@mui/icons-material/Clear";
import {useDispatch, useSelector} from "react-redux";
import {LABEL_DOWNLOAD_URL_PREFIX, NODE_ROUTE_URI} from "../../Utils/apiUrl";
import axios from "axios";
import {updateMovingServiceImage, updateMovingServiceRemarkNotes} from "../../actions/movingServiceAction";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import TextField from "@mui/material/TextField";
import {ReactComponent as OtherInfo} from "../../images/NewDesignIcon/OtherInfo.svg";
import {MovingServiceContact} from "./MovingServiceContact";
import {MovingServiceDate} from "./MovingServiceDate";
import {useParams} from "react-router-dom";

const styles = {

    MovingServicePhotosAndDetailsSection: {
        backgroundColor: '#FFFFFF',
        paddingBottom: '20px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        gap: '20px',
        display: 'flex',
        flexDirection: 'column',
    },
    MovingServiceAddressHeading: {
        fontSize: '20px',
        fontWeight: '600',
        lineHeight: '20px'
    },
    MovingServiceImageUploadSection: {
        display: 'flex',
        border: '1px solid #000000',
        borderRadius: '5px',
        height: '15vh',
        alignItems: 'center',
        justifyContent: 'center'
    },
    MovingServiceImageEach: {
        objectFit: 'contain',
        width: '200px',
        height: '200px',
    },
    MovingServiceImageList: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
        gap: '15px',
        width: '100%'
    },
    MovingServiceImageCard: {
        borderRadius: '10px',
        boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.5)',
        padding: '10px',
        "&:hover": {
            cursor: 'pointer',
            transform: 'scale(1.05)',
            transition: '0.5s ease-in-out'
        },
    },
    MovingServiceImageSection: {
        display: 'flex',
        flexDirection: 'column',
        width: '220px',
        position: 'relative'
    },
    MovingServiceImageDeleteImage: {
        position: 'absolute',
        right: '0',
        top: '0'
    }
}

export const MovingServicePhotosAndDetails = ({validatedResult}) => {

    const {tab} = useParams();

    let imageError = useSelector(state => state?.movingService?.movingImageError);

    let movingImage = useSelector(state => state?.movingService?.movingImage);
    let movingRemarkNotes = useSelector(state => state?.movingService?.movingRemarkNotes);

    const [movingDetails, setMovingDetails] = useState('');
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [exceedLimit, setExceedLimit] = useState(false);
    const [previewImageURL, setPreviewImageURL] = useState('');
    const [openModal, setOpenModal] = useState(false);
    const [loading, setLoading] = useState(false);

    const dispatch = useDispatch();

    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => setOpenModal(false);

    const handleMovingDetails = (event) => {
        setMovingDetails(event.target.value);
    }

    const handleRemoveImage = (itemToRemove) => {
        const updatedItems = selectedFiles.filter((item) => item !== itemToRemove);
        setSelectedFiles(updatedItems);
    }

    const uploadImageToServer = async (uploadImages) => {
        if (!uploadImages) {
            setLoading(false);
            return;
        }

        let requestURL = `${NODE_ROUTE_URI}/movingService/uploadImage`;
        const data = new FormData();

        uploadImages.forEach(image => {
            data.append('images', image);
        })

        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                headers: {
                    "Content-Type": "multipart/form-data;",
                },
                data: data
            })

            setSelectedFiles((prevFiles) => [...prevFiles, ...result.data.urls]);
            setLoading(false);
        } catch (e) {
            console.log(e.response);
            setLoading(false);
        }
    }

    const handleFileChange = (event) => {
        setLoading(true);
        const files = Array.from(event.target.files);
        const allowedFormats = ['image/jpeg', 'image/png'];

        // Filter out files that are not in JPG or PNG format
        const selectedImages = files.filter((file) =>
            allowedFormats.includes(file.type)
        );

        const UploadImageNum = selectedImages.length + selectedFiles.length;

        if (UploadImageNum > 5) {
            setExceedLimit(true);
            setLoading(false);
        } else {
            setExceedLimit(false);
            uploadImageToServer(selectedImages);
        }
    };

    useEffect(() => {
        setSelectedFiles(movingImage);
        setMovingDetails(movingRemarkNotes);
    }, [])

    useEffect(() => {
        dispatch(updateMovingServiceImage(selectedFiles));
    }, [selectedFiles]);

    useEffect(() => {
        dispatch(updateMovingServiceRemarkNotes(movingDetails));
    }, [movingDetails])

    // console.log(selectedFiles);

    console.log('[MovingServicePhotosAndDetails] movingRemarkNotes redux', movingRemarkNotes);
    console.log('[MovingServicePhotosAndDetails] movingRemarkNotes', movingDetails);

    return (
        <Box sx={styles.MovingServicePhotosAndDetailsSection}>
            <Box sx={{
                display: 'flex',
                gap: '10px',
                padding: '20px 20px 0 20px'
            }}>
                <OtherInfo width={25} height={25}/>
                <Typography style={styles.MovingServiceAddressHeading}>
                    Tell us more about moving
                </Typography>
            </Box>
            <Divider/>
            <Box sx={{
                backgroundColor: '#FFFFFF',
                padding: '0 20px',
                gap: '40px',
                display: 'flex',
                flexDirection: 'column'
            }}>
                <MovingServiceContact validatedResult={validatedResult}/>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography sx={{fontWeight: '600'}}>
                            {intl.get('IMAGE.PHOTO')}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={styles.MovingServiceImageUploadSection}>
                            <input
                                accept="image/jpeg, image/png"
                                style={{display: 'none'}}
                                id="moving-image-upload"
                                multiple
                                type="file"
                                onChange={handleFileChange}
                                // disabled={selectedFiles.length < 5 ? false : true}
                            />
                            <label htmlFor="moving-image-upload" style={{margin: 0}}>
                                <LoadingButton
                                    variant="contained"
                                    sx={{
                                        backgroundColor: '#1D8B45',
                                        "&:hover": {
                                            backgroundColor: '#1D8B45',
                                            filter: 'brightness(0.9)'
                                        }
                                    }}
                                    component="span"
                                    endIcon={<UploadIcon/>}
                                    // disabled={selectedFiles.length < 5 ? false : true}
                                    loading={loading}
                                    size='small'
                                >
                                    <Typography style={{textTransform: 'none', fontSize: '14px'}}>
                                        {intl.get('BUTTON.UPLOAD_IMAGES')}
                                    </Typography>
                                </LoadingButton>
                            </label>
                        </Box>
                        {/*<FormHelperText*/}
                        {/*    sx={{*/}
                        {/*        color: "error.main",*/}
                        {/*    }}*/}
                        {/*>*/}
                        {/*    {exceedLimit && 'Maximum upload limit exceeded. You can only upload up to five images.'}*/}
                        {/*</FormHelperText>*/}
                        {/*<FormHelperText*/}
                        {/*    sx={{*/}
                        {/*        color: "error.main",*/}
                        {/*    }}*/}
                        {/*>*/}
                        {/*    {imageError === null ? "" : imageError && 'This is required, and please upload at least one moving image.'}*/}
                        {/*</FormHelperText>*/}
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'space-between'
                        }}>
                            <Typography sx={{fontSize: '12px', color: 'B5B5B5'}}>
                                {intl.get('IMAGE.FORMAT')}
                            </Typography>
                            <Typography sx={{fontSize: '12px', color: 'B5B5B5'}}>
                                {intl.get('IMAGE.SIZE')}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={styles.MovingServiceImageList}>
                            {
                                selectedFiles.length > 0 && selectedFiles.map((image, index) => {
                                    return (
                                        <Box sx={styles.MovingServiceImageSection}>
                                            <Box sx={styles.MovingServiceImageCard}>
                                                <img src={`https://storage.googleapis.com/${image}`}
                                                     style={styles.MovingServiceImageEach}
                                                     onClick={() => {
                                                         handleOpenModal();
                                                         setPreviewImageURL(image);
                                                     }}
                                                />
                                            </Box>
                                            <Typography style={{fontWeight: '600'}}>
                                                {image.split('_')[1]}
                                            </Typography>
                                            <IconButton
                                                sx={styles.MovingServiceImageDeleteImage}
                                                onClick={() => {
                                                    handleRemoveImage(image);
                                                }}
                                            >
                                                <ClearIcon/>
                                            </IconButton>
                                        </Box>
                                    )
                                })
                            }
                        </Box>
                        <Modal
                            open={openModal}
                            onClose={handleCloseModal}
                        >
                            <Box sx={styles.MovingServiceImageModalBox}>
                                <Box sx={styles.MovingServiceImageBox}>
                                    <img
                                        src={`https://storage.googleapis.com/${previewImageURL}`}
                                        style={styles.MovingServiceImageItem}
                                    />
                                </Box>
                            </Box>
                        </Modal>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography sx={{fontWeight: '600'}}>
                            Provide us with more details about your item.
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <TextField
                                value={movingDetails}
                                onInput={handleMovingDetails}
                                fullWidth
                                multiline
                                rows={4}
                                variant="outlined"
                                size='small'
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                <MovingServiceDate tab={tab} validatedResult={validatedResult}/>
            </Box>
        </Box>
    )
}