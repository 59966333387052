import {
    TOGGLE_E_COMMERCE_CREATE_SHIPPING_LABEL,
    TOGGLE_E_COMMERCE_FULFILL_ITEMS, UPDATE_E_COMMERCE_FULFILLMENT_NOTIFICATION,
    UPDATE_E_COMMERCE_ITEM_LIST,
    UPDATE_E_COMMERCE_PROFILE_ID
} from "./types";

export const updateECommerceItemList = itemList => ({
    type: UPDATE_E_COMMERCE_ITEM_LIST,
    payload: itemList
})

export const updateECommerceProfileId = profileId => ({
    type: UPDATE_E_COMMERCE_PROFILE_ID,
    payload: profileId
})

export const toggleECommerceCreateShippingLabel = fulfillmentOrderId => ({
    type: TOGGLE_E_COMMERCE_CREATE_SHIPPING_LABEL,
    payload: fulfillmentOrderId
})

export const toggleECommerceFulfillItems = fulfillmentOrderId => ({
    type: TOGGLE_E_COMMERCE_FULFILL_ITEMS,
    payload: fulfillmentOrderId
})

export const updateECommerceFulfillmentNotification = notification => ({
    type: UPDATE_E_COMMERCE_FULFILLMENT_NOTIFICATION,
    payload: notification
})