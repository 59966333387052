import React, {Fragment, useEffect, useState} from "react";
import {WordPressQuoteRatingCar} from "./WordPressQuoteRatingCar";
import {WordPressQuoteRatingMoving} from "./WordPressQuoteRatingMoving";
import {WordPressQuoteRatingLTL} from "./WordPressQuoteRatingLTL";
import {WordPressQuoteRatingPackage} from "./WordPressQuoteRatingPackage";

export const WordPressQuoteRating = ({
                                         tab,
                                         movingFrom,
                                         movingTo,
                                         shippingDetails,
                                         AIRate,
                                         APIRate,
                                         redirectToApp,
                                         lng
                                     }) => {

    return (
        <Fragment>
            {
                tab?.toLowerCase() === 'car' ?
                    <WordPressQuoteRatingCar AIRate={AIRate} APIRate={APIRate} redirectToApp={redirectToApp}
                                             lng={lng}/> :
                    tab?.toLowerCase() === 'moving' ?
                        <WordPressQuoteRatingMoving AIRate={AIRate}
                                                    APIRate={APIRate}
                                                    redirectToApp={redirectToApp}
                                                    movingFrom={movingFrom}
                                                    movingTo={movingTo}
                                                    shippingDetails={shippingDetails}
                                                    lng={lng}/> :
                        tab?.toLowerCase() === 'ltl' ?
                            <WordPressQuoteRatingLTL AIRate={AIRate} APIRate={APIRate} redirectToApp={redirectToApp}
                                                     lng={lng}/> :
                            <WordPressQuoteRatingPackage AIRate={AIRate} APIRate={APIRate}
                                                         redirectToApp={redirectToApp}
                                                         lng={lng}/>

            }
        </Fragment>
    )
}