import {GoogleOAuthProvider, useGoogleLogin} from "@react-oauth/google";
import {Button, Box} from "@mui/material";
import GoogleIcon from "../../../images/googleIcon";
import Typography from "@mui/material/Typography";
import React from "react";
import axios from "axios";

const CLIENT_ID = "928576037734-8nd81butrv8jls78ti9o8legltnh58i9.apps.googleusercontent.com";

const GoogleLoginButton = ({responseSocial}) => {
    const login = useGoogleLogin({
        onSuccess: async (tokenResponse) => {
            // console.log("Token Response:", tokenResponse);

            try {
                const {access_token} = tokenResponse;

                const userInfoResponse = await axios.get("https://www.googleapis.com/oauth2/v3/userinfo", {
                    headers: { Authorization: `Bearer ${access_token}` },
                });

                responseSocial({
                    ...userInfoResponse?.data,
                    accessToken: access_token,
                });

            } catch (error) {
                console.error("Error processing token:", error);
            }
        },
        onError: () => console.error("Google Login Failed"),
        flow: "implicit", // Ensures ID token is returned directly
    });

    return (
        <Button
            variant="contained"
            onClick={() => login()}
            style={{
                backgroundColor: "#FFFFFF",
                color: "#2c2c2c",
                width: "100%",
                display: "flex",
                justifyContent: "center",
            }}
            startIcon={ <GoogleIcon width="18px"/>}
        >
            <Box sx={{display: 'flex', justifyContent: 'center', width: '100%'}}>
                <Typography sx={{
                    textTransform: 'none',
                    fontSize: '14px'
                }}>
                    Sign in with Google
                </Typography>
            </Box>
        </Button>
    );
};

const GoogleAuthComponent = ({responseSocial}) => {
    return (
        <GoogleOAuthProvider clientId={CLIENT_ID}>
            <GoogleLoginButton responseSocial={responseSocial}/>
        </GoogleOAuthProvider>
    );
};

export default GoogleAuthComponent;
