import {Button, Container, Divider} from "@mui/material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import FormLabel from "@mui/material/FormLabel";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import React, {useState} from "react";
import {
    carManufactures,
    extractFullAddress,
    generateCarShippingQuoteRequest,
    getMissingKeysFromObject
} from "../../Utils/Helper";
import {NODE_ROUTE_URI, PARTNER_URI} from "../../Utils/apiUrl";
import axios from "axios";
import {makeStyles} from "@material-ui/core/styles";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import {greenButtonContained} from "../../Utils/styleConfig";
import dayjs from "dayjs";
import {updateMovingServiceBasicRate, updateMovingServiceBasicRateFromAI} from "../../actions/movingServiceAction";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles({
    smallInput: {
        "& .MuiInputBase-input": {
            height: "0px", // Adjust the height as per your preference
            padding: "2px", // Adjust the padding as per your preference
            fontSize: "14px", // Adjust the font size as per your preference
        }
    }
});

export const WordPressCarQuote = () => {

    const classes = useStyles();

    const [movingFromAddressPredictions, setMovingFromAddressPredictions] = useState([]);
    const [movingToAddressPredictions, setMovingToAddressPredictions] = useState([]);
    const [vehicleDetail, setVehicleDetail] = useState({
            make: null,
            model: null,
            year: null
        }
    );

    const [movingFrom, setMovingFrom] = useState({
        city: null,
        province: null,
        country: null,
        postalCode: null,
        displayPostalCode: {
            description: null,
            placeId: null,
            text: null
        },
        latitude: null,
        longitude: null
    });

    const [movingTo, setMovingTo] = useState({
        city: null,
        province: null,
        country: null,
        postalCode: null,
        displayPostalCode: {
            description: null,
            placeId: null,
            text: null
        },
        latitude: null,
        longitude: null
    });

    const [contact, setContact] = useState({
        email: null,
        phone: null,
    })
    const [validatedResult, setValidatedResult] = useState(null);
    const [validatedEmail, setValidatedEmail] = useState(null);
    const [APIRate, setAPIRate] = useState(null);
    const [AIRate, setAIRate] = useState(null);
    const [isCall, setIsCall] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    const googleMapsPlaceAutocomplete = async (inputValue, type) => {
        let requestURL = `${NODE_ROUTE_URI}/maps_apis/placeAutocomplete`;

        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    input: inputValue,
                    types: type
                }
            })
            const {data: {data: {predictions}}} = result;
            console.log('prediction', predictions);
            const updatedPredictions = predictions?.map(each => {
                return {
                    description: each?.description,
                    text: each?.structured_formatting?.main_text,
                    placeId: each?.place_id
                }
            })
            console.log('updated prediction', updatedPredictions);

            return updatedPredictions;

        } catch (e) {
            console.log(e.response)
        }
    }

    const googleMapsPlaceDetails = async (placeId) => {
        let requestURL = `${NODE_ROUTE_URI}/maps_apis/placeDetails`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    placeId: placeId
                }
            });
            const place = result?.data?.data?.result;
            return place;

        } catch (e) {
            console.log(e.response)
        }
    }

    const handleInputChangeMovingFromPostalCode = async (event, value) => {
        setMovingFrom(prevState => ({
            ...prevState,
            postalCode: value,
            displayPostalCode: {
                ...prevState?.displayPostalCode,
                description: value,
                placeId: '',
                text: value
            }
        }));
        const predictionsAddress = await googleMapsPlaceAutocomplete(value, 'postal_code');
        setMovingFromAddressPredictions(predictionsAddress);
    }

    const handleChangeMovingFromPostalCode = async (event, value) => {
        const place = await googleMapsPlaceDetails(value?.placeId);
        if (place) {
            const address = extractFullAddress(place);
            console.log('[handleChangeMovingFromPostalCode] address', address);
            console.log('[handleChangeMovingFromPostalCode] place', place);

            setMovingFrom(prevState => ({
                ...prevState,
                city: place?.vicinity || address?.sublocality || address?.city || address?.neighborhood || address?.administrative_area_level_3,
                province: address?.state?.code,
                country: address?.country?.code,
                postalCode: address?.zip,
                displayPostalCode: value,
                latitude: place?.geometry?.location?.lat,
                longitude: place?.geometry?.location?.lng
            }))
        } else {
            setMovingFrom(prevState => ({
                ...prevState,
                postalCode: '',
                displayPostalCode: {
                    ...prevState?.displayPostalCode,
                    description: '',
                    placeId: '',
                    text: ''
                },
                latitude: '',
                longitude: ''
            }))
        }
    }

    const handleInputChangeMovingToPostalCode = async (event, value) => {
        setMovingTo(prevState => ({
            ...prevState,
            postalCode: value,
            displayPostalCode: {
                ...prevState?.displayPostalCode,
                description: value,
                placeId: '',
                text: value
            }
        }));
        const predictionsAddress = await googleMapsPlaceAutocomplete(value, 'postal_code');
        setMovingToAddressPredictions(predictionsAddress);
    }

    const handleChangeMovingToPostalCode = async (event, value) => {
        const place = await googleMapsPlaceDetails(value?.placeId);
        if (place) {
            const address = extractFullAddress(place);
            setMovingTo(prevState => ({
                ...prevState,
                city: place?.vicinity || address?.sublocality || address?.city || address?.neighborhood || address?.administrative_area_level_3,
                province: address?.state?.code,
                country: address?.country?.code,
                postalCode: address?.zip,
                displayPostalCode: value,
                latitude: place?.geometry?.location?.lat,
                longitude: place?.geometry?.location?.lng
            }))
        } else {
            setMovingTo(prevState => ({
                ...prevState,
                postalCode: '',
                displayPostalCode: {
                    ...prevState?.displayPostalCode,
                    description: '',
                    placeId: '',
                    text: ''
                },
                latitude: '',
                longitude: ''
            }))
        }
    }

    const handleInputCarMake = async (event, value) => {
        setVehicleDetail(prevState => ({
            ...prevState,
            make: value
        }));
    }

    const handleChangeCarMake = async (event, value) => {
        setVehicleDetail(prevState => ({
            ...prevState,
            make: value
        }));
    }

    const handleModel = event => {
        const {value} = event.target;
        setVehicleDetail(prevState => ({
            ...prevState,
            model: value
        }))
    }

    const handleYear = (newValue) => {
        setVehicleDetail(prevState => ({
            ...prevState,
            year: newValue
        }))
    }

    const handleEmail = event => {
        const {value} = event.target;
        setContact(prevState => ({
            ...prevState,
            email: value
        }))
        setValidatedEmail(preState => ({
            ...preState,
            valid: true
        }))
    }

    const validateEmailAddress = async (email) => {
        let requestURL = `${NODE_ROUTE_URI}/validateEmail`;
        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                data: {
                    email: email,
                    validateRegex: true,
                    validateMx: true,
                    validateTypo: false,
                    validateDisposable: false,
                    validateSMTP: false
                }
            })
            console.log('[validateEmailAddress] result - ', result);
            return result?.data;
        } catch (e) {
            console.log(e.response)
        }
    }

    const validation = async () => {
        const movingFromValidation = getMissingKeysFromObject({
            city: movingFrom?.city,
            province: movingFrom?.province,
            country: movingFrom?.country,
            postalCode: movingFrom?.postalCode,
        })

        const movingToValidation = getMissingKeysFromObject({
            city: movingFrom?.city,
            province: movingFrom?.province,
            country: movingFrom?.country,
            postalCode: movingFrom?.postalCode,
        })

        const carValidation = getMissingKeysFromObject({...vehicleDetail});

        const contactEmailCheck = await validateEmailAddress(contact?.email);
        setValidatedEmail(contactEmailCheck);

        if (movingFromValidation?.length > 0 ||
            movingToValidation.length > 0 ||
            carValidation.length > 0 ||
            !contactEmailCheck?.valid
        ) {
            return false
        } else {
            return true
        }
    }

    const getCarQuoteByDeepSeek = async () => {
        try {
            setLoading(true);
            setIsCall(true);
            let requestURL = `${NODE_ROUTE_URI}/deepseek/admin/getCarQuoteByDeepSeek`;

            const carObject = {
                make: vehicleDetail.make,
                model: vehicleDetail.model,
                yearOfProduction: dayjs(vehicleDetail.year)?.format('YYYY'),
                categoryValue: "",
                quantity: 1
            }

            let updatedCarList = [];
            updatedCarList.push(carObject);

            const content = generateCarShippingQuoteRequest({
                shipFromAddress: "",
                shipFromCity: movingFrom?.city,
                shipFromProvince: movingFrom?.province,
                shipFromCountry: movingFrom?.country,
                shipFromPostalCode: movingFrom?.postalCode,
                shipToAddress: "",
                shipToCity: movingTo?.city,
                shipToProvince: movingTo?.province,
                shipToCountry: movingTo?.country,
                shipToPostalCode: movingTo?.postalCode,
                packageData: updatedCarList
            })

            const data = {
                messages: [
                    {
                        role: "user",
                        content: content
                    }
                ],
                systemRole: "CAR_QUOTE",
                shipFromCity: movingFrom?.city,
                shipFromProvince: movingFrom?.province,
                shipFromCountry: movingFrom?.country,
                shipToCity: movingTo?.city,
                shipToProvince: movingTo?.province,
                shipToCountry: movingTo?.country,
                packageData: updatedCarList
            }

            const result = await axios({
                method: 'post',
                url: requestURL,
                data: data
            })

            console.log('[getCarQuoteByDeepSeek] result', result);
            setAIRate(result?.data);
        } catch (e) {
            console.log('error', e?.response);
            setError(true);
        } finally {
            setLoading(false);
        }
    }

    const handleGetVehicleRating = async () => {
        try {
            setLoading(true);

            let requestURL = `${NODE_ROUTE_URI}/movingCar/getMovingCarRatingList`;

            const carObject = {
                make: vehicleDetail.make,
                model: vehicleDetail.model,
                yearOfProduction: dayjs(vehicleDetail.year)?.format('YYYY'),
                categoryValue: "",
                quantity: 1
            }

            let updatedCarList = [];
            updatedCarList.push(carObject);

            const data = {
                shipFromAddress: "",
                shipFromCity: movingFrom?.city,
                shipFromProvince: movingFrom?.province,
                shipFromCountry: movingFrom?.country,
                shipFromPostalCode: movingFrom?.postalCode,
                shipToAddress: "",
                shipToCity: movingTo?.city,
                shipToProvince: movingTo?.province,
                shipToCountry: movingTo?.country,
                shipToPostalCode: movingTo?.postalCode,
                packageData: updatedCarList
            }

            // console.log('[handleGetVehicleRating] data', data);

            const result = await axios({
                method: 'post',
                url: requestURL,
                // headers: {Authorization: `Bearer ${storedToken}`},
                data: data
            })

            console.log('[handleGetVehicleRating] rating result', result);
            // setErrorMessage('');

            if (+result?.data?.finalTotal > 0) {
                setLoading(false);
                setAPIRate(result?.data);
            } else {
                await getCarQuoteByDeepSeek();
            }
        } catch (e) {
            console.log(e.response);
            await getCarQuoteByDeepSeek();
        }
    }

    const handleGetRating = async () => {
        const validationResult = await validation();
        setValidatedResult(validationResult);
        if (validationResult) {
            await handleGetVehicleRating();
        }
    }

    const handleRedirectToApp = () => {
        window.top.location.href = 'https://app.uucargo.ca/sign-in';
    }

    const handleGetAnotherQuote = () => {
        setAPIRate(null);
        setAIRate(null);
        setError(false);
        setIsCall(false);
    }

    const redirectToApp = () => {

        return (
            <>
                <Grid item xs={12}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        gap: '10px'
                    }}>
                        <Button
                            variant='contained'
                            sx={greenButtonContained}
                            onClick={handleRedirectToApp}
                        >
                            <Typography sx={{textTransform: 'none'}}>
                                Sign In
                            </Typography>
                        </Button>
                        <Button
                            variant='contained'
                            sx={greenButtonContained}
                            onClick={handleGetAnotherQuote}
                        >
                            <Typography sx={{textTransform: 'none'}}>
                                Request Another Quote
                            </Typography>
                        </Button>
                    </Box>
                </Grid>
            </>
        )
    }

    if (isCall && loading) {
        return (
            <Container maxWidth='xl'>
                <Box sx={{
                    display: 'flex',
                    marginTop: '20px'
                }}>
                    <Grid container spacing={2}>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '100vw',
                            height: '100vh'
                        }}>
                            <CircularProgress/>
                        </Box>
                    </Grid>
                </Box>
            </Container>
        )
    }

    if (!loading && error) {
        return (
            <Container maxWidth='xl'>
                <Box sx={{
                    display: 'flex',
                    marginTop: '20px'
                }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography>
                                Based on the selections you've made, a quote cannot be provided to you
                                immediately.
                            </Typography>
                        </Grid>
                        {redirectToApp()}
                    </Grid>
                </Box>
            </Container>
        )
    }

    if (+APIRate?.finalTotal > 0 || +AIRate?.total > 0) {
        return (
            <Container maxWidth='xl'>
                <Box sx={{
                    display: 'flex',
                    marginTop: '20px'
                }}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Typography sx={{fontSize: '16px', textAlign: 'left'}}>
                                Subtotal
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography sx={{fontSize: '16px', textAlign: 'right'}}>
                                $ {(+APIRate?.totalCharge || +AIRate?.subtotal).toFixed(2)}
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography sx={{fontSize: '16px', textAlign: 'left'}}>
                                Tax
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography sx={{fontSize: '16px', textAlign: 'right'}}>
                                $ {(+APIRate?.taxCharge || +AIRate?.tax || 0).toFixed(2)}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider/>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography sx={{fontSize: '16px', textAlign: 'left'}}>
                                Total
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography sx={{fontSize: '16px', textAlign: 'right'}}>
                                $ {(+APIRate?.finalTotal || +AIRate?.total)?.toFixed(2)}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography sx={{fontSize: '16px', textAlign: 'left'}}>
                                Estimated shipping time
                                is {APIRate?.shippingDaysRange || AIRate?.days ? `${APIRate?.shippingDaysRange || AIRate?.days} days` : 'N/A'}.
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography>
                                The estimated cost is for reference only.
                            </Typography>
                        </Grid>
                        {
                            redirectToApp()
                        }
                    </Grid>
                </Box>
            </Container>
        )
    }

    console.log('[WordPressCarQuote] moving from', movingFrom);
    console.log('[WordPressCarQuote] validatedEmail', validatedEmail)
    console.log('[WordPressCarQuote] validated result', validatedResult)

    return (
        <Container maxWidth='xl'>
            <Box sx={{
                display: 'flex',
                marginTop: '20px'
            }}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <FormLabel required sx={{textAlign: 'left'}}>
                                Origin Postal Code
                            </FormLabel>
                            <Autocomplete
                                // freeSolo
                                value={movingFrom?.displayPostalCode}
                                fullWidth
                                options={movingFromAddressPredictions}
                                filterOptions={(options, state) => options}
                                getOptionLabel={option => option?.description}
                                onInputChange={handleInputChangeMovingFromPostalCode}
                                onChange={handleChangeMovingFromPostalCode}
                                PaperComponent={props => <Paper {...props}
                                                                sx={{width: 400}}/>}
                                renderInput={params => <TextField
                                    {...params}
                                    className={classes.smallInput}
                                    variant="outlined"
                                    error={validatedResult !== null && !movingFrom?.postalCode}
                                    helperText={(validatedResult !== null && !movingFrom?.postalCode) && 'Required'}
                                />}
                                renderOption={option => (
                                    <Typography style={{fontSize: '12px'}}>
                                        {option.description}
                                    </Typography>
                                )}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <FormLabel required sx={{textAlign: 'left'}}>
                                Destination Postal Code
                            </FormLabel>
                            <Autocomplete
                                // freeSolo
                                value={movingTo?.displayPostalCode}
                                fullWidth
                                options={movingToAddressPredictions}
                                filterOptions={(options, state) => options}
                                getOptionLabel={option => option?.description}
                                onInputChange={handleInputChangeMovingToPostalCode}
                                onChange={handleChangeMovingToPostalCode}
                                PaperComponent={props => <Paper {...props}
                                                                sx={{width: 400}}/>}
                                renderInput={params => <TextField
                                    {...params}
                                    className={classes.smallInput}
                                    variant="outlined"
                                    error={validatedResult !== null && !movingTo?.postalCode}
                                    helperText={(validatedResult !== null && !movingTo?.postalCode) && 'Required'}
                                />}
                                renderOption={option => (
                                    <Typography style={{fontSize: '12px'}}>
                                        {option.description}
                                    </Typography>
                                )}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <FormLabel required>
                                Make
                            </FormLabel>
                            <Autocomplete
                                freeSolo
                                value={vehicleDetail?.make}
                                options={carManufactures.filter((option) =>
                                    option.toLowerCase().includes(vehicleDetail?.make?.toLowerCase() || "")
                                )}
                                onInputChange={handleInputCarMake}
                                onChange={handleChangeCarMake}
                                PaperComponent={props => <Paper {...props} sx={{width: '100%'}}/>}
                                renderInput={params => <TextField
                                    {...params}
                                    variant='outlined'
                                    className={classes.smallInput}
                                    error={validatedResult !== null && !vehicleDetail?.make}
                                    helperText={(validatedResult !== null && !vehicleDetail?.make) && 'Required'}
                                />}
                                renderOption={(option) => (
                                    <Typography
                                        style={{fontSize: '14px'}}
                                    >
                                        {option}
                                    </Typography>
                                )}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <FormLabel required>
                                Model
                            </FormLabel>
                            <TextField
                                value={vehicleDetail?.model}
                                onInput={handleModel}
                                fullWidth
                                variant='outlined'
                                size='small'
                                InputProps={{
                                    inputProps: {
                                        min: 1
                                    },
                                    style: {fontSize: 14} // font size of input text
                                }}
                                error={validatedResult !== null && !vehicleDetail?.model}
                                helperText={(validatedResult !== null && !vehicleDetail?.model) && 'Required'}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <FormLabel required>
                                Year
                            </FormLabel>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    views={['year']}
                                    value={vehicleDetail?.year}
                                    onChange={handleYear}
                                    slotProps={{
                                        textField: {
                                            size: 'small',
                                            fullWidth: true,
                                            error: validatedResult !== null && !vehicleDetail?.year,
                                            helperText: (validatedResult !== null && !vehicleDetail?.year) && 'Required',
                                            InputProps: {
                                                sx: {
                                                    fontSize: '14px'
                                                }
                                            }
                                        }
                                    }}
                                    renderInput={(params) =>
                                        <TextField
                                            {...params} fullWidth
                                        />}
                                />
                            </LocalizationProvider>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <FormLabel required>
                                Email
                            </FormLabel>
                            <TextField
                                value={contact?.email}
                                onInput={handleEmail}
                                fullWidth
                                variant='outlined'
                                size='small'
                                InputProps={{
                                    inputProps: {
                                        min: 1
                                    },
                                    style: {fontSize: 14} // font size of input text
                                }}
                                error={validatedResult !== null && (!contact?.email || !validatedEmail?.valid)}
                                helperText={validatedResult !== null && (!contact?.email ? 'Required' : !validatedEmail?.valid && (
                                    validatedEmail?.validators?.mx?.reason || validatedEmail?.validators?.regex?.reason || "Invalid"))}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'center'
                        }}>
                            <LoadingButton
                                variant='contained'
                                sx={greenButtonContained}
                                // size='small'
                                loading={loading}
                                onClick={handleGetRating}
                                disabled={isCall}
                            >
                                <Typography sx={{textTransform: 'none'}}>
                                    Get Free Quote
                                </Typography>
                            </LoadingButton>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    )
}