import {makeStyles} from "@material-ui/core/styles";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {
    checkHSCodeInValid,
    generateRandomString,
    generateUniqueName,
    getCountryCode, isValidTariffCode,
    UOMList
} from "../../Utils/Helper";
import {
    duplicateShippingPackageCrossBorderItemList, removeShippingPackageCrossBorderItemList,
    updateShippingPackageCrossBorderItemList
} from "../../actions/shippingPackageAction";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import intl from "react-intl-universal";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import TextField from "@mui/material/TextField";
import {IconButton, InputAdornment, Link, MenuItem, Select} from "@mui/material";
import FormHelperText from "@mui/material/FormHelperText";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Box from "@mui/material/Box";
import duplicate from "../../images/Duplicate.png";
import remove from "../../images/Delete.png";
import {HSCode, NODE_ROUTE_URI} from "../../Utils/apiUrl";
import axios from "axios";
import {HS_CODE_LOOKUP_URL} from "../../Utils/consInfo";
import SearchIcon from "@mui/icons-material/Search";
import * as React from "react";
import {green} from "../../Utils/styleConfig";
import HelpIcon from "@mui/icons-material/Help";
import {invoiceTypeCommercial} from "../../Utils/config";
import {provinces, UOMListMap} from "../shared";

const useStyles = makeStyles({
    smallInput: {
        "& .MuiInputBase-input": {
            height: "0px", // Adjust the height as per your preference
            padding: "0px", // Adjust the padding as per your preference
            fontSize: "14px", // Adjust the font size as per your preference
        }
    }
});

export const ShippingPackageCrossBorderItem = ({
                                                   itemInfo,
                                                   index,
                                                   metric,
                                                   validation,
                                                   errorList,
                                                   handleOpenItemDescription,
                                                   declarationDutyFreeEntry,
                                                   shipmentPurpose
                                               }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    let itemListRedux = useSelector(state => state?.shippingPackage?.crossBorderItemList);
    let selectRate = useSelector(state => state?.businessShippingPackage?.createLabelRateSelect);

    const [itemDetail, setItemDetail] = useState(itemInfo);
    const [HSCodeList, setHSCodeList] = useState([]);

    const handleName = event => {
        setItemDetail(prevState => ({
            ...prevState,
            description: event.target.value
        }))
    }

    const handleSku = event => {
        setItemDetail(prevState => ({
            ...prevState,
            sku: event.target.value
        }))
    }

    const handleHSCode = event => {
        const {value} = event.target;

        // Regular expression to allow only numbers and periods
        const regex = /^[0-9.]+$/;
        if (value === '' || regex.test(value)) {
            setItemDetail(prevState => ({
                ...prevState,
                hscode: event.target.value
            }))
        }
    }

    const handleWeight = event => {
        const {value} = event.target;
        if (/^\d*\.?\d*$/.test(value)) {
            setItemDetail(prevState => ({
                ...prevState,
                weight: value
            }))
        }
    }

    const handleQuantity = event => {
        setItemDetail(prevState => ({
            ...prevState,
            quantity: event.target.value < 1 ? '1' : event.target.value
        }))
    }

    const handlePrice = event => {
        const {value} = event.target;
        if (/^\d*\.?\d*$/.test(value)) {
            setItemDetail(prevState => ({
                ...prevState,
                priceEach: value
            }))
        }
    }

    const handleCurrency = event => {
        setItemDetail(prevState => ({
            ...prevState,
            currency: event.target.value
        }))
    }

    const handleCountryCode = event => {
        setItemDetail(prevState => ({
            ...prevState,
            originCountryCode: event.target.value,
            originProvinceCode: event?.target?.value !== 'CA' && ''
        }))
    }

    const handleProvinceCode = event => {
        setItemDetail(prevState => ({
            ...prevState,
            originProvinceCode: event.target.value
        }))
    }

    const handleUOM = event => {
        setItemDetail(prevState => ({
            ...prevState,
            UOM: event.target.value
        }))
    }

    const handleRemove = () => {
        dispatch(removeShippingPackageCrossBorderItemList(itemDetail));
    }

    const handleDuplicate = () => {
        const itemId = generateRandomString(8);
        const duplicatedItem = {...itemDetail};
        duplicatedItem.itemId = itemId;
        const description = generateUniqueName(duplicatedItem.description, itemListRedux);
        duplicatedItem.description = description;
        dispatch(duplicateShippingPackageCrossBorderItemList(duplicatedItem));
    }

    useEffect(() => {
        setItemDetail(itemInfo);
    }, [itemInfo?.itemId]);

    useEffect(() => {
        setItemDetail(prevState => ({
            ...prevState,
            weightUnit: metric ? 'kg' : 'lb'
        }))
    }, [metric]);

    useEffect(() => {
        dispatch(updateShippingPackageCrossBorderItemList(itemDetail));
    }, [itemDetail])

    console.log('item detail', itemDetail);

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Typography style={{textAlign: 'left', color: '#1D8B45'}}>
                    # {index + 1}
                </Typography>
            </Grid>
            <Grid item md={11} xs={12}>
                <Grid container spacing={1}>
                    <Grid item md={12} xs={12}>
                        <FormControl fullWidth>
                            <FormLabel required sx={{fontSize: '14px', textAlign: 'left'}}>
                                Description <span> <IconButton sx={{padding: '0'}}><HelpIcon
                                sx={{color: green, fontSize: '16px'}} onClick={handleOpenItemDescription}/></IconButton></span>
                            </FormLabel>
                            <TextField
                                value={itemDetail?.description}
                                size='small'
                                inputProps={{style: {fontSize: 14}}} // font size of input text
                                placeholder={intl.get('CROSS_BORDER.DESCRIPTION_PLACEHOLDER')}
                                multiline
                                onInput={handleName}
                                error={!validation && !itemDetail?.description}
                                helperText={
                                    (!validation && !itemDetail?.description) && intl.get('CROSS_BORDER.MISSING_INVALID')
                                }
                            >
                            </TextField>
                        </FormControl>
                    </Grid>
                    <Grid item md={2} xs={12}>
                        <FormControl fullWidth>
                            <FormLabel sx={{fontSize: '14px', textAlign: 'left'}}>
                                SKU
                            </FormLabel>
                            <TextField
                                value={itemDetail?.sku}
                                size='small'
                                inputProps={{style: {fontSize: 14}}} // font size of input text
                                placeholder={intl.get('CROSS_BORDER.SKU')}
                                onInput={handleSku}
                                // error={pickupInformationMissingKeys?.includes('city')}
                                // helperText={
                                //     pickupInformationMissingKeys?.includes('city') && "City Required"
                                // }
                            >
                            </TextField>
                        </FormControl>
                    </Grid>
                    <Grid item md={2} xs={12}>
                        <FormControl fullWidth>
                            <FormLabel required sx={{fontSize: '14px', textAlign: 'left'}}>
                                Origin Country
                            </FormLabel>
                            <Select
                                value={itemDetail?.originCountryCode}
                                size='small'
                                style={{fontSize: 14}} // font size of input text
                                onChange={handleCountryCode}
                                error={!itemDetail?.originCountryCode}
                            >
                                {
                                    getCountryCode('ups', (+shipmentPurpose === +invoiceTypeCommercial && declarationDutyFreeEntry))?.map(v => {
                                        return <MenuItem value={v.code}>{v.name}</MenuItem>
                                    })
                                }
                            </Select>
                            <FormHelperText
                                sx={{
                                    color: "error.main",
                                }}
                            >
                                {!itemDetail?.originCountryCode && intl.get('CROSS_BORDER.REQUIRED')}
                            </FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item md={2} xs={12}>
                        <FormControl fullWidth>
                            <FormLabel required sx={{fontSize: '14px', textAlign: 'left'}}>
                                Origin Province
                            </FormLabel>
                            <Select
                                value={itemDetail?.originProvinceCode}
                                size='small'
                                style={{fontSize: 14}} // font size of input text
                                onChange={handleProvinceCode}
                                disabled={itemDetail?.originCountryCode !== 'CA'}
                                error={!validation && itemDetail?.originCountryCode === 'CA' && !itemDetail?.originProvinceCode}
                            >
                                {
                                    provinces?.map((each, index) => (
                                        <MenuItem
                                            key={index}
                                            value={each?.value}
                                        >
                                            {each?.value}
                                        </MenuItem>
                                    ))
                                }
                            </Select>
                            <FormHelperText
                                sx={{
                                    color: "error.main",
                                }}
                            >
                                {(!validation && itemDetail?.originCountryCode === 'CA' && !itemDetail?.originProvinceCode) && intl.get('CROSS_BORDER.MISSING_INVALID')}
                            </FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item md={2} xs={12}>
                        <FormControl fullWidth>
                            <FormLabel required sx={{fontSize: '14px', textAlign: 'left'}}>
                                Unit Of Measure
                            </FormLabel>
                            <Select
                                value={itemDetail?.UOM}
                                size='small'
                                style={{fontSize: 14}} // font size of input text
                                onChange={handleUOM}
                                error={!itemDetail?.UOM}
                            >
                                {
                                    selectRate?.accountBase?.toUpperCase() === 'CANADAPOST' ?
                                        UOMListMap?.map((v, i) => {
                                            return <MenuItem key={i} value={v?.code}>{v?.value}</MenuItem>
                                        })
                                        :
                                        UOMList?.map(v => {
                                            return <MenuItem value={v}>{v}</MenuItem>
                                        })
                                }
                            </Select>
                            <FormHelperText
                                sx={{
                                    color: "error.main",
                                }}
                            >
                                {!itemDetail?.UOM && intl.get('CROSS_BORDER.REQUIRED')}
                            </FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item md={4} xs={12}>
                        <FormControl fullWidth>
                            <FormLabel required sx={{fontSize: '14px', textAlign: 'left'}}>
                                {intl.get('CROSS_BORDER.HS_CODE')} <span><Link underline="none" target="_blank"
                                                                               rel="noreferrer" href={HSCode}><HelpIcon
                                sx={{color: green, fontSize: '16px'}}/></Link></span>
                            </FormLabel>
                            <TextField
                                value={itemDetail?.hscode}
                                size='small'
                                inputProps={{style: {fontSize: 14}}} // font size of input text
                                placeholder={intl.get('CROSS_BORDER.HS_CODE')}
                                onInput={handleHSCode}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Link href={HS_CODE_LOOKUP_URL}
                                                  target="_blank"
                                                  rel="noopener"
                                                  sx={{
                                                      color: '#1D8B45',
                                                      textDecoration: 'none', // Ensure no underline initially
                                                      '&:hover': {
                                                          textDecoration: 'underline',
                                                          color: '#1D8B45', // Ensure no underline on hover
                                                      },
                                                  }}>
                                                <Box sx={{display: 'flex'}}>
                                                    <SearchIcon sx={{color: '#454545'}}/>
                                                    <Typography
                                                        sx={{
                                                            textTransform: 'none',
                                                            color: '#1D8B45',
                                                            fontSize: '14px'
                                                        }}>
                                                        HS Code Lookup
                                                    </Typography>
                                                </Box>
                                            </Link>
                                        </InputAdornment>
                                    )
                                }}
                                error={(!validation && (!itemDetail?.hscode || !isValidTariffCode(itemDetail?.hscode))) || checkHSCodeInValid(errorList, itemDetail?.hscode)}
                                helperText={
                                    (!validation && !itemDetail?.hscode) ? intl.get('CROSS_BORDER.MISSING_INVALID') :
                                        (!validation && !isValidTariffCode(itemDetail?.hscode)) ? 'HS Code is invalid. Format must be 9999.99.99.99' :
                                            checkHSCodeInValid(errorList, itemDetail?.hscode) && 'HS Code is invalid'
                                }
                            />
                            {
                                checkHSCodeInValid(errorList, itemDetail?.hscode) &&
                                <Link underline="none" target="_blank" rel="noreferrer" href={HSCode}
                                      sx={{
                                          textDecoration: 'none',
                                          '&:hover': {textDecoration: 'underline', color: green}
                                      }}>
                                    <Typography sx={{
                                        fontSize: '14px', color: green
                                    }}>
                                        Learn more about correctly inputting HS codes.
                                    </Typography>
                                </Link>
                            }
                        </FormControl>
                    </Grid>
                    <Grid item md={2} xs={12}>
                        <FormControl fullWidth>
                            <FormLabel required sx={{fontSize: '14px', textAlign: 'left'}}>
                                Quantity
                            </FormLabel>
                            <TextField
                                value={itemDetail?.quantity}
                                onInput={handleQuantity}
                                size='small'
                                inputProps={{style: {fontSize: 14}}} // font size of input text
                                placeholder='quantity'
                                type='number'
                                InputProps={{
                                    inputProps: {
                                        min: 1
                                    },
                                    style: {fontSize: 14}
                                }}
                                error={itemDetail?.quantity <= 0}
                                helperText={
                                    itemDetail?.quantity <= 0 && intl.get('CROSS_BORDER.REQUIRED')
                                }
                            >
                            </TextField>
                        </FormControl>
                    </Grid>
                    <Grid item md={2} xs={12}>
                        <FormControl fullWidth>
                            <FormLabel required sx={{fontSize: '14px', textAlign: 'left'}}>
                                Unit Weight
                            </FormLabel>
                            <TextField
                                value={itemDetail?.weight}
                                onInput={handleWeight}
                                size='small'
                                inputProps={{style: {fontSize: 14}}} // font size of input text
                                placeholder={intl.get('CROSS_BORDER.UNIT_WEIGHT')}
                                type='number'
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {itemDetail?.weightUnit}
                                        </InputAdornment>
                                    ),
                                    inputProps: {
                                        min: 1
                                    },
                                    style: {fontSize: 14}
                                }}
                                error={!validation && itemDetail?.weight <= 0}
                                helperText={
                                    (!validation && itemDetail?.weight <= 0) && intl.get('CROSS_BORDER.MISSING_INVALID')
                                }
                            >
                            </TextField>
                        </FormControl>
                    </Grid>
                    <Grid item md={2} xs={12}>
                        <FormControl fullWidth>
                            <FormLabel sx={{fontSize: '14px', textAlign: 'left'}}>
                                Total Weight
                            </FormLabel>
                            <TextField
                                value={itemDetail?.weight * itemDetail?.quantity}
                                size='small'
                                inputProps={{style: {fontSize: 14}}} // font size of input text
                                placeholder='total Weight'
                                type='number'
                                disabled={true}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {itemDetail?.weightUnit}
                                        </InputAdornment>
                                    ),
                                    inputProps: {
                                        min: 1
                                    },
                                    style: {fontSize: 14}
                                }}
                                // error={pickupInformationMissingKeys?.includes('city')}
                                // helperText={
                                //     pickupInformationMissingKeys?.includes('city') && "City Required"
                                // }
                            >
                            </TextField>
                        </FormControl>
                    </Grid>
                    <Grid item md={2} xs={12}>
                        <FormControl required fullWidth>
                            <FormLabel sx={{fontSize: '14px', textAlign: 'left'}}>
                                Currency
                            </FormLabel>
                            <Select
                                value={itemDetail?.currency}
                                size='small'
                                style={{fontSize: 14}} // font size of input text
                                onChange={handleCurrency}
                                disabled={selectRate?.accountBase?.toUpperCase() === 'CANADAPOST'}
                                // error={pickupInformationMissingKeys?.includes('country')}
                            >
                                <MenuItem value='CAD'>CAD</MenuItem>
                                <MenuItem value='USD'>USD</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item md={2} xs={12}>
                        <FormControl fullWidth>
                            <FormLabel required sx={{fontSize: '14px', textAlign: 'left'}}>
                                Unit Price
                            </FormLabel>
                            <TextField
                                value={itemDetail?.priceEach}
                                size='small'
                                inputProps={{style: {fontSize: 14}}} // font size of input text
                                placeholder={intl.get('CROSS_BORDER.UNIT_PRICE')}
                                type='number'
                                onInput={handlePrice}
                                error={!validation && itemDetail?.priceEach <= 0}
                                helperText={
                                    (!validation && itemDetail?.priceEach <= 0) && intl.get('CROSS_BORDER.MISSING_INVALID')
                                }
                            >
                            </TextField>
                        </FormControl>
                    </Grid>
                    <Grid item md={2} xs={12}>
                        <FormControl fullWidth>
                            <FormLabel sx={{fontSize: '14px', textAlign: 'left'}}>
                                Total Price
                            </FormLabel>
                            <TextField
                                value={itemDetail?.priceEach * itemDetail?.quantity}
                                size='small'
                                inputProps={{style: {fontSize: 14}}} // font size of input text
                                placeholder='total price'
                                type='number'
                                disabled={true}
                                // error={pickupInformationMissingKeys?.includes('city')}
                                // helperText={
                                //     pickupInformationMissingKeys?.includes('city') && "City Required"
                                // }
                            >
                            </TextField>
                        </FormControl>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item md={1} xs={12}>
                <Box sx={{
                    display: 'flex',
                    // flexDirection: isMobile ? 'row' : 'column',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    height: '100%',
                    width: '100%',
                    gap: '10px'
                }}>
                    <IconButton
                        color='success'
                        onClick={handleDuplicate}
                        sx={{padding: 0}}
                    >
                        <img src={duplicate} width='30px'/>
                    </IconButton>
                    <IconButton
                        color='error'
                        onClick={handleRemove}
                        sx={{padding: 0}}
                    >
                        <img src={remove} width='30px'/>
                    </IconButton>
                </Box>
            </Grid>
        </Grid>
    )

}