import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {Button, IconButton, MenuItem, Select} from "@mui/material";
import {
    addMovingServiceCarList,
    removeMovingServiceCarList,
    updateMovingServiceCarList
} from "../../actions/movingServiceAction";
import {useDispatch, useSelector} from "react-redux";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import {useEffect, useState} from "react";
import duplicate from "../../images/Duplicate.png";
import remove from "../../images/Delete.png";
import Paper from "@mui/material/Paper";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {carManufactures, carSizeList, generateRandomString} from "../../Utils/Helper";
import {makeStyles} from "@material-ui/core";
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import FormHelperText from "@mui/material/FormHelperText";

const style = {
    MovingServiceCarEachRoot: {
        display: 'flex',
        gap: '10px',
        flexDirection: 'column'
    },
    MovingServiceCarEachCell: {
        display: 'flex',
        alignItems: 'flex-end',
    },
    MovingServiceCarEachButton: {
        padding: 0
    }
}

const useStyles = makeStyles({
    customTextField: {
        "& input::placeholder": {
            fontSize: "14px"
        }
    },
    smallInput: {
        "& .MuiInputBase-input": {
            height: "2px", // Adjust the height as per your preference
            padding: "2px", // Adjust the padding as per your preference
            fontSize: "14px", // Adjust the font size as per your preference
        },
    }
});

export const MovingServiceCarEach = ({vehicleInfo, index, validatedResult}) => {

    const classes = useStyles();

    const dispatch = useDispatch();
    const [vehicleDetail, setVehicleDetail] = useState(vehicleInfo);

    const handleInputCarMake = async (event, value) => {
        setVehicleDetail(prevState => ({
            ...prevState,
            make: value
        }));
    }

    const handleChangeCarMake = async (event, value) => {
        setVehicleDetail(prevState => ({
            ...prevState,
            make: value
        }));
    }

    const handleModel = event => {
        const {value} = event.target;
        setVehicleDetail(prevState => ({
            ...prevState,
            model: value
        }))
    }

    const handleSize = event => {
        const {value} = event.target;
        setVehicleDetail(prevState => ({
            ...prevState,
            size: value,
        }))
    }

    const handleYear = (newValue) => {
        setVehicleDetail(prevState => ({
            ...prevState,
            year: newValue
        }))
    }

    const handleQuantity = event => {

        const {value} = event.target;
        if (/^\d*\.?\d*$/.test(value)) {
            setVehicleDetail(prevState => ({
                ...prevState,
                quantity: value
            }))
        }
    }

    const handleRemove = () => {
        dispatch(removeMovingServiceCarList(vehicleDetail));
    }

    const handleDuplicate = () => {
        const vehicleId = generateRandomString(8);
        const duplicatedVehicle = {...vehicleDetail};
        duplicatedVehicle.vehicleId = vehicleId;
        dispatch(addMovingServiceCarList(duplicatedVehicle));
    }

    useEffect(() => {
        setVehicleDetail(vehicleInfo);
    }, [vehicleInfo?.vehicleId]);

    useEffect(() => {
        dispatch(updateMovingServiceCarList(vehicleDetail));
    }, [vehicleDetail])

    console.log('[MovingServiceCarEach] vehicle details', vehicleDetail);
    console.log('[MovingServiceCarEach] validatedResult', validatedResult);

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '15px'
        }}>
            <Box>
                <Typography style={{fontSize: '16px', textAlign: 'left'}}>
                    # {index + 1}
                </Typography>
            </Box>
            <Grid container spacing={1}>
                <Grid item xs={2.75}>
                    <FormControl fullWidth>
                        <FormLabel required>
                            Make
                        </FormLabel>
                        <Autocomplete
                            freeSolo
                            value={vehicleDetail?.make}
                            options={carManufactures.filter((option) =>
                                option.toLowerCase().includes(vehicleDetail?.make?.toLowerCase() || "")
                            )}
                            onInputChange={handleInputCarMake}
                            onChange={handleChangeCarMake}
                            PaperComponent={props => <Paper {...props} sx={{width: '100%'}}/>}
                            renderInput={params => <TextField
                                {...params}
                                variant='outlined'
                                className={classes.smallInput}
                                error={validatedResult !== null && !vehicleDetail?.make}
                                helperText={(validatedResult !== null && !vehicleDetail?.make) && 'Required'}
                            />}
                            renderOption={(option) => (
                                <Typography
                                    style={{fontSize: '14px'}}
                                >
                                    {option}
                                </Typography>
                            )}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={2.75}>
                    <FormControl fullWidth>
                        <FormLabel required>
                            Model
                        </FormLabel>
                        <TextField
                            value={vehicleDetail?.model}
                            onInput={handleModel}
                            fullWidth
                            variant='outlined'
                            size='small'
                            InputProps={{
                                inputProps: {
                                    min: 1
                                },
                                style: {fontSize: 14} // font size of input text
                            }}
                            error={validatedResult !== null && !vehicleDetail?.model}
                            helperText={(validatedResult !== null && !vehicleDetail?.model) && 'Required'}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={2.75}>
                    <FormControl fullWidth>
                        <FormLabel>
                            Size
                        </FormLabel>
                        <Select
                            value={vehicleDetail?.size}
                            size='small'
                            onChange={handleSize}
                            style={{textAlign: 'left', fontSize: '14px'}} // font size of input text
                            // error={validatedResult !== null && !vehicleDetail?.size}
                        >
                            {
                                carSizeList?.map((size, index) => (
                                    <MenuItem key={index} value={size}>
                                        {size}
                                    </MenuItem>
                                ))
                            }
                        </Select>
                        {/*{*/}
                        {/*    (validatedResult !== null && !vehicleDetail?.size) &&*/}
                        {/*    <FormHelperText sx={{color: 'error.main'}}>*/}
                        {/*        Required*/}
                        {/*    </FormHelperText>*/}
                        {/*}*/}
                    </FormControl>
                </Grid>
                <Grid item xs={1.5}>
                    <FormControl fullWidth>
                        <FormLabel required>
                            Year
                        </FormLabel>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                views={['year']}
                                value={vehicleDetail?.year}
                                onChange={handleYear}
                                slotProps={{
                                    textField: {
                                        size: 'small',
                                        fullWidth: true,
                                        error: validatedResult !== null && !vehicleDetail?.year,
                                        helperText: (validatedResult !== null && !vehicleDetail?.year) && 'Required',
                                        InputProps: {
                                            sx: {
                                                fontSize: '14px'
                                            }
                                        }
                                    }
                                }}
                                renderInput={(params) =>
                                    <TextField
                                        {...params} fullWidth
                                    />}
                            />
                        </LocalizationProvider>
                    </FormControl>
                </Grid>
                <Grid item xs={1.25}>
                    <FormControl fullWidth>
                        <FormLabel required>
                            Quantity
                        </FormLabel>
                        <TextField
                            value={vehicleDetail?.quantity}
                            onInput={handleQuantity}
                            fullWidth
                            variant='outlined'
                            size='small'
                            InputProps={{
                                inputProps: {
                                    min: 1
                                },
                                style: {fontSize: 14} // font size of input text
                            }}
                            error={validatedResult !== null && (!vehicleDetail?.quantity || +vehicleDetail?.quantity <= 0)}
                            helperText={validatedResult !== null && (!vehicleDetail?.quantity ? 'Required' : vehicleDetail?.quantity <= 0 && 'Invalid')}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={0.5}>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        height: '100%',
                        justifyContent: 'center'
                    }}>
                        <IconButton
                            color='success'
                            onClick={handleDuplicate}
                            sx={{padding: 0}}
                        >
                            <img src={duplicate} width='30px'/>
                        </IconButton>
                    </Box>
                </Grid>
                <Grid item xs={0.5}>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        height: '100%',
                        justifyContent: 'center'
                    }}>
                        <IconButton
                            color='error'
                            onClick={handleRemove}
                            sx={{padding: 0}}
                        >
                            <img src={remove} width='30px'/>
                        </IconButton>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )

}