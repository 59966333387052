import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import TextField from "@mui/material/TextField";
import {Button, IconButton, Stack, Switch, Tooltip} from "@mui/material";
import React, {useEffect, useState} from "react";
import {MovingServiceItemAdd} from "./MovingServiceItemAdd";
import {useDispatch, useSelector} from "react-redux";
import {
    addMovingServiceItemList, clearMovingServiceItemList,
    toggleMovingServiceAddItem,
    updateMovingServiceMetricUnit
} from "../../actions/movingServiceAction";
import {MovingServiceItemEach} from "./MovingServiceItemEach";
import {MovingServiceItemEdit} from "./MovingServiceItemEdit";
import Divider from "@mui/material/Divider";
import addPackage from "../../images/Create Label.png";
import {styled} from "@mui/material/styles";
import {generateRandomString} from "../../Utils/Helper";
import {
    addCreateLabelShippingPackagePackageList,
    updateCreateLabelShippingPackageMetricUnit
} from "../../actions/shippingPackageAction";
import {ShippingPackageCreateLabelPackageEach} from "../ShippingPackage/ShippingPackageCreateLabelPackageEach";
import {MovingServiceItemList} from "./MovingServiceItemList";
import intl from "react-intl-universal";
import {useParams} from "react-router-dom";
import {inchesToCubicMeters} from "../../Utils/calculateVolume";
import {ReactComponent as PackageDetailsIcon} from "../../images/NewDesignIcon/Package.svg";

const styles = {
    MovingServiceItemRoot: {
        backgroundColor: '#FFFFFF',
        paddingBottom: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        gap: '20px',
        display: 'flex',
        flexDirection: 'column',
    },
    MovingServiceAddressHeading: {
        fontSize: '20px',
        fontWeight: '600',
        lineHeight: '20px'
    },
    MovingServiceItemRootRow: {
        display: 'flex',
        gap: '10px',
        justifyContent: 'flex-end',
    },

    MovingServiceItemContent: {
        display: 'flex',
        flexDirection: 'column',
    },

    MovingServiceItemHeading: {
        fontSize: '20px',
        fontWeight: '600'
    },
}

const AntSwitch = styled(Switch)(({theme}) => ({
    width: 30,
    height: 18,
    padding: 0,
    display: 'flex',
    '&:active': {
        '& .MuiSwitch-thumb': {
            width: 17,
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(9px)',
        },
    },
    '& .MuiSwitch-switchBase': {
        padding: 2,
        '&.Mui-checked': {
            transform: 'translateX(12px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.mode === 'dark' ? '#609966' : '#609966',
            },
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
        width: 14,
        height: 14,
        borderRadius: 6,
        transition: theme.transitions.create(['width'], {
            duration: 200,
        }),
    },
    '& .MuiSwitch-track': {
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor:
            theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
        boxSizing: 'border-box',
    },
}));

export const MovingServiceItem = ({tab, validatedResult}) => {

    const dispatch = useDispatch();

    let metric = useSelector(state => state?.movingService?.movingServiceMetric);
    let itemListRedux = useSelector(state => state?.movingService?.itemList);

    const [itemList, setItemList] = useState([]);
    const [index, setIndex] = useState(1);

    const [itemVolume, setItemVolume] = useState(0);
    const [itemQuantity, setItemQuantity] = useState(0);

    const handleMetric = (event) => {
        dispatch(updateMovingServiceMetricUnit(event.target.checked));
    }

    const handleAddItem = () => {

        const itemId = generateRandomString(8);

        const newItemGeneral = {
            description: null,
            category: null,
            type: 'item',
            boxSize: null,
            length: 0,
            width: 0,
            height: 0,
            weight: 0,
            volume: 0,
            lengthUnit: metric ? 'cm' : 'in',
            weightUnit: metric ? 'kg' : 'lb',
            quantity: 1,
            cate_id: 0,
            categoryInfo: null,
            itemId: itemId,
            packageSpecialServices: {
                wrappingService: false,
                packingService: false,
                assemblyService: false,
                disassemblyService: false
            }
        }

        const newItemExpress = {
            description: null,
            category: 'Box',
            type: 'box',
            boxSize: 'XLBox',
            length: metric ? 60 : 23.622,
            width: metric ? 60 : 23.622,
            height: metric ? 60 : 23.622,
            weight: metric ? 29.48 : 65,
            lengthUnit: metric ? 'cm' : 'in',
            weightUnit: metric ? 'kg' : 'lb',
            quantity: 1,
            cate_id: 100,
            categoryInfo: {category_name: 'Box'},
            itemId: itemId
        }
        tab === 'express' ? dispatch(addMovingServiceItemList(newItemExpress)) : dispatch(addMovingServiceItemList(newItemGeneral))
        setIndex(prevState => prevState + 1);
    }

    useEffect(() => {
        if (itemListRedux?.length > 0) {
            if (+itemListRedux?.[0]?.cate_id === 100 && tab === 'express') {
                setItemList(itemListRedux);
            } else if (+itemListRedux?.[0]?.cate_id !== 100 && tab === 'item') {
                setItemList(itemListRedux);
            } else {
                dispatch(clearMovingServiceItemList());
            }
        } else {
            setItemList([]);
        }
    }, [itemListRedux]);

    console.log('[MovingServiceItem] itemList local', itemList);
    console.log('[MovingServiceItem] itemList redux', itemListRedux);

    useEffect(() => {
        let totalVolume = 0;
        let totalQuantity = 0;
        for (const existingItem of itemListRedux) {
            const {length, width, height, quantity, lengthUnit} = existingItem;
            const volumeCubicMeter = (inchesToCubicMeters({
                length,
                height,
                width
            }) || 0) * +quantity;
            console.log("volumeCubicMeter", volumeCubicMeter);
            totalVolume += volumeCubicMeter;
            totalQuantity += +quantity;
        }
        setItemVolume(totalVolume.toFixed(2));
        setItemQuantity(+totalQuantity);
    }, [itemListRedux])

    return (
        <Box sx={styles.MovingServiceItemRoot}>
            <Box sx={{
                display: 'flex',
                gap: '10px',
                padding: '20px 20px 0 20px',
                justifyContent: 'space-between',
                alignItems: 'center'
            }}>
                <Box sx={{
                    display: 'flex',
                    gap: '10px',
                    alignItems: 'center'
                }}>
                    <PackageDetailsIcon width={25} height={25}/>
                    <Typography style={styles.MovingServiceAddressHeading}>
                        Customized Item List
                    </Typography>
                </Box>
                <Tooltip
                    placement="top"
                    arrow
                    componentsProps={{
                        tooltip: {
                            sx: {
                                bgcolor: 'white',
                                color: 'black',
                                boxShadow: 1,
                                border: '1px solid #ddd',
                                borderRadius: '8px', // Added border radius
                            },
                        },
                        arrow: {
                            sx: {
                                color: 'white',
                            },
                        },
                    }}
                    title={
                        <Box sx={{backgroundColor: "#ffffff", p: 1}}>
                            We calculate your move cost by estimating the total volume of items to be moved.
                            This volume helps determine the required number of movers and the appropriate
                            truck size, ensuring a smooth and efficient move.
                        </Box>
                    }>
                    <Typography sx={{
                        fontSize: '20px',
                        fontWeight: '600',
                        color: "green",
                        cursor: "pointer"
                    }}>
                        Total Quantity: {itemQuantity}, Estimating {itemVolume} m³
                    </Typography>
                </Tooltip>
            </Box>
            <Divider/>
            <Box sx={{
                backgroundColor: '#FFFFFF',
                padding: '0 40px',
                gap: '40px',
                display: 'flex',
            }}>
                <Grid container spacing={2}>
                    <Grid item md={12}>
                        <Box sx={styles.MovingServiceItemRootRow}>
                            <Stack direction="row" spacing={1} alignItems="center">
                                <AntSwitch
                                    checked={metric}
                                    onChange={handleMetric}
                                    inputProps={{'aria-label': 'ant design'}}
                                />
                                <Typography>
                                    {intl.get('ITEM_LIST.METRIC')}
                                </Typography>
                            </Stack>
                            <Stack direction="row" spacing={2} alignItems="center">
                                <IconButton
                                    sx={{color: '#609966'}}
                                    onClick={handleAddItem}
                                >
                                    <img src={addPackage} width='30px'/>
                                </IconButton>
                                <Typography>
                                    {intl.get('ITEM_LIST.NEW_ITEM')}
                                </Typography>
                            </Stack>
                        </Box>
                    </Grid>
                    {
                        itemList?.length > 0 ? itemList?.map((eachItem, index) =>
                            <Grid item xs={12} key={index}>
                                <MovingServiceItemList
                                    itemInfo={eachItem}
                                    index={index}
                                    tab={tab}
                                    validatedResult={validatedResult}
                                />
                            </Grid>
                        ) : <Grid item xs={12}>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                border: '1px solid #B7B7B7',
                                padding: '20px',
                                borderRadius: '10px'
                            }}>
                                <Typography style={{fontSize: '20px', fontWeight: '600', color: '#7F8487'}}>
                                    {intl.get('ITEM_LIST.EMPTY_ITEM_LIST')}
                                </Typography>
                                <IconButton
                                    sx={{color: '#1D8B45'}}
                                    onClick={handleAddItem}
                                >
                                    {/*<AddBoxIcon/>*/}
                                    <img src={addPackage} width='30px'/>
                                </IconButton>
                                <Typography style={{fontSize: '12px', fontWeight: '600', color: '#1D8B45'}}>
                                    {intl.get('ITEM_LIST.ADD_NEW_TEXT')}
                                </Typography>
                            </Box>
                        </Grid>
                    }
                </Grid>
            </Box>
        </Box>
    )
}