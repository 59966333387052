import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {useSelector} from "react-redux";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import {formatCurrency} from "../../Utils/Helper";
import intl from "react-intl-universal";
import MovingServiceProgressBar from "./MovingServiceProgressBar";
import React from "react";

const styles = {
    MovingServiceRateRoot: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        gap: '10px',
        display: 'flex',
        flexDirection: 'column',
    },
    MovingServiceRateHeading: {
        fontSize: '20px',
        fontWeight: '600'
    },
    MovingServiceRateSubHeading: {
        fontSize: '18px',
        fontWeight: '600'
    },
}

export const MovingServiceRate = ({tab}) => {

    let basicRateInfo = useSelector(state => state?.movingService?.movingServiceBasicRateInfo);

    console.log('[MovingServiceRate] basic rate info', basicRateInfo);

    {/*If TOTAL Charge is 0, this request need to be reviewed*/
    }
    if (!basicRateInfo?.totalCharge || +basicRateInfo?.totalCharge === 0) {
        return (
            <Grid item xs={12}>
                <Typography sx={{textAlign: 'left'}}>
                    Based on the selections you've made, a quote cannot be provided to you immediately. Please select
                    "Book" to send this to our operation team.
                </Typography>
            </Grid>
        )
    }

    return (
        <>
            <Grid item xs={7}>
                <Typography>
                    Freight Charge
                </Typography>
            </Grid>
            <Grid item xs={5}>
                <Typography sx={{textAlign: 'right'}}>
                    $ {basicRateInfo?.freightCharge?.toFixed(2)}
                </Typography>
            </Grid>
            <Grid item xs={7}>
                <Typography>
                    Pickup Charge
                </Typography>
            </Grid>
            <Grid item xs={5}>
                <Typography sx={{textAlign: 'right'}}>
                    $ {basicRateInfo?.pickupCharge?.toFixed(2)}
                </Typography>
            </Grid>
            <Grid item xs={7}>
                <Typography>
                    Delivery Charge
                </Typography>
            </Grid>
            <Grid item xs={5}>
                <Typography sx={{textAlign: 'right'}}>
                    $ {basicRateInfo?.deliverCharge?.toFixed(2)}
                </Typography>
            </Grid>
            {
                +basicRateInfo?.storageCharge > 0 &&
                <>
                    <Grid item xs={7}>
                        <Typography>
                            Storage Charge
                        </Typography>
                    </Grid>
                    <Grid item xs={5}>
                        <Typography sx={{textAlign: 'right'}}>
                            $ {basicRateInfo?.storageCharge?.toFixed(2)}
                        </Typography>
                    </Grid>
                </>
            }
            {
                +basicRateInfo?.pelletingCharge > 0 &&
                <>
                    <Grid item xs={7}>
                        <Typography>
                            Pallet Charge
                        </Typography>
                    </Grid>
                    <Grid item xs={5}>
                        <Typography sx={{textAlign: 'right'}}>
                            $ {basicRateInfo?.pelletingCharge?.toFixed(2)}
                        </Typography>
                    </Grid>
                </>
            }
            <Grid item xs={12}>
                <Divider/>
            </Grid>
            <Grid item xs={7}>
                <Typography>
                    Subtotal
                </Typography>
            </Grid>
            <Grid item xs={5}>
                <Typography sx={{textAlign: 'right'}}>
                    $ {basicRateInfo?.totalCharge?.toFixed(2)}
                </Typography>
            </Grid>
            <Grid item xs={7}>
                <Typography>
                    Tax
                </Typography>
            </Grid>
            <Grid item xs={5}>
                <Typography sx={{textAlign: 'right'}}>
                    $ {basicRateInfo?.taxCharge?.toFixed(2)}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Divider/>
            </Grid>
            <Grid item xs={7}>
                <Typography>
                    Total
                </Typography>
            </Grid>
            <Grid item xs={5}>
                <Typography sx={{textAlign: 'right'}}>
                    $ {basicRateInfo?.totalChargeWithTaxes?.toFixed(2)}
                </Typography>
            </Grid>
        </>
    )
}
