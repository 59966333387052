import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState, Fragment} from "react";
import {
    ButtonBase,
    ClickAwayListener,
    Container, Divider,
    Snackbar, Step, StepLabel, Stepper,
    Tooltip,
    tooltipClasses,
    useMediaQuery
} from "@mui/material";
import Typography from "@mui/material/Typography";
import intl from "react-intl-universal";
import Box from "@mui/material/Box";
import {isValidateEmail, mapLTLLogo} from "../../../Utils/Helper";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import HelpIcon from "@mui/icons-material/Help";
import {styled} from "@mui/material/styles";
import {useHistory} from "react-router-dom";
import LoadingButton from "@mui/lab/LoadingButton";
import TextField from "@mui/material/TextField";
import {LABEL_DOWNLOAD_URL_PREFIX, PARTNER_URI} from "../../../Utils/apiUrl";
import {
    SHIPPING_LTL_GENERAL_PRIVATE_RESIDENT_DELIVERY_CODE,
    SHIPPING_LTL_GENERAL_PRIVATE_RESIDENT_PICKUP_CODE
} from "../../shared";
import axios from "axios";
import {deleteToken, getAccessToken} from "../../../Utils/doToken";
import * as React from "react";
import MuiAlert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";
import {
    selectShippingLTLCreateLabelRate,
    updateShippingLTLCreateLabelMoveFrom, updateShippingLTLCreateLabelMoveTo
} from "../../../actions/shippingLTLAction";
import {ShippingLTLCreateLabelRateAverage} from "./ShippingLTLCreateLabelRateAverage";
import {InternationalSteps, QontoConnector, QontoStepIcon, steps} from "../../../Utils/CustomComponent";
import {
    ReactComponent as ChooseCarrierAndServicesIcon
} from "../../../images/NewDesignIcon/ChooseCarrierAndServices.svg";
import {ReactComponent as CheckIcon} from "../../../images/NewDesignIcon/CheckIcon.svg";
import InputLabel from "@mui/material/InputLabel";
import Grid from "@mui/material/Grid";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const styles = {
    ShippingPackageCreateLabelRateRoot: {
        backgroundColor: '#FFFFFF',
        // padding: '20px',
        marginBottom: '40px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        gap: '20px',
        display: 'flex',
        flexDirection: 'column',
        margin: '20px 0'
    },
    ShippingPackageCreateLabelRateHeading: {
        fontSize: '18px',
        fontWeight: '600',
        textAlign: 'left'
    },
    ShippingPackageCreateLabelRateSubHeading: {
        fontSize: '16px',
        fontWeight: '600',
        paddingLeft: '10px'
    },
    ShippingPackageCreateLabelRateOtherBox: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
        height: '100%',
        flexDirection: 'column'
    },
    ShippingPackageCreateLabelRateImageBox: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
        paddingLeft: '20px',
        gap: '10px',
        flexDirection: 'column'
    },
    ShippingPackageCreateLabelRateOtherBoxMobile: {
        display: 'flex',
        alignItems: 'flex-start',
        paddingLeft: '20px',
        justifyContent: 'center',
        height: '100%',
        flexDirection: 'column'
    },
    ShippingPackageCreateLabelRateListContent: {
        backgroundColor: '#FFFFFF',
        padding: '0 40px',
        marginBottom: '40px',
        borderRadius: '10px',
        gap: '20px',
        display: 'flex',
        flexDirection: 'column',
    },
}

const LightTooltip = styled(({className, ...props}) => (
    <Tooltip {...props} classes={{popper: className}}/>
))(({theme}) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
}));

export const ShippingLTLCreateLabelRateList = () => {

    const history = useHistory();

    const isMobile = useMediaQuery('(max-width: 600px)');

    const token = getAccessToken("access_token");

    const dispatch = useDispatch();

    const userInfo = useSelector((state) => state.user);
    let rateRedux = useSelector(state => state?.shippingLTL?.createLabelRate);
    let calledAllCarrier = useSelector(state => state?.shippingLTL?.createLabelCallCarrier);
    let shipFrom = useSelector(state => state?.shippingLTL?.createLabelShipFrom);
    let shipTo = useSelector(state => state?.shippingLTL?.createLabelShipTo);
    let specialService = useSelector(state => state?.shippingLTL?.createLabelSpecialService);
    let palletListRedux = useSelector(state => state?.shippingLTL?.createLabelPalletList);
    let referenceNumberRedux = useSelector(state => state?.shippingLTL?.createLabelReferenceNumber);
    let commentRedux = useSelector(state => state?.shippingLTL?.createLabelComment);
    let dateAndTimeRedux = useSelector(state => state?.shippingLTL?.createLabelDateAndTime);

    const [contactEmail, setContactEmail] = useState(userInfo?.email);
    const [contactEmailValidate, setContactEmailValidate] = useState(true);
    const [loading, setLoading] = useState(true);
    const [rateList, setRateList] = useState([]);
    const [selectedRate, setSelectedRate] = useState(null);
    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [availableService, setAvailableService] = useState([]);
    const [allService, setAllService] = useState([]);
    const [open, setOpen] = useState(-1);

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const handleError = (errorCode) => {
        switch (errorCode) {
            case 400:
                setErrorMessage("Bad Request: The server cannot process the request.");
                break;
            case 401:
                setErrorMessage("Unauthorized: Authentication is required to access the resource.");
                break;
            case 404:
                setErrorMessage("Not Found: The requested resource could not be found.");
                break;
            case 500:
                setErrorMessage("Internal Server Error: The server encountered an unexpected condition.");
                break;
            default:
                setErrorMessage("Unknown Error: An unknown error occurred.");
                break;
        }
    }

    const getAllServiceNames = async () => {

        let requestURL = `${PARTNER_URI}/shippingLTL/getAllAvailableServiceNames`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${token}`
                },
                params: {
                    count: 1
                }
            })
            console.log('result', result);
            setAllService(result?.data);
            const updateRate = result?.data?.map(item => ({
                ...item,
                serviceName: item?.account_base?.toLowerCase(),
                loading: false,
                finalTotalBeforeTax: 0
            }));
        } catch (e) {
            console.log('error', e);
        }
    }

    const getAllAvailableServiceNames = async () => {

        const totalQty = palletListRedux?.reduce((acc, item) => acc + (+item?.quantity), 0);

        if (totalQty > 25) {
            setAvailableService([]);
        } else {
            let requestURL = `${PARTNER_URI}/shippingLTL/getAllAvailableServiceNames`;
            try {
                const result = await axios({
                    method: 'get',
                    url: requestURL,
                    headers: {
                        Authorization: `Bearer ${token}`
                    },
                    params: {
                        count: 1
                    }
                })
                console.log('result', result);
                setAvailableService(result?.data);
            } catch (e) {
                console.log('error', e);
            }
        }
    }

    const customQuote = async () => {
        // setLoadingCustomQuote(true);
        let requestURI = `${PARTNER_URI}/shippingLTL/LTLRequestQuote`;
        let data = {
            shipperName: shipFrom?.name,
            shipperPhone: shipFrom?.phone?.replace(/[^a-zA-Z0-9]/g, ''),
            shipperCompanyName: shipFrom?.company || shipFrom?.name,
            shipperEmail: shipFrom?.email,
            shipperAddress: shipFrom?.address,
            shipperAddressTwo: shipFrom?.address2 || "",
            shipperAddressType: specialService.some(service => service.serviceCode === SHIPPING_LTL_GENERAL_PRIVATE_RESIDENT_PICKUP_CODE) ? "RESIDENTIAL" : "WAREHOUSE",
            shipperCity: shipFrom?.city,
            shipperProvince: shipFrom?.province?.code,
            shipperPostalCode: shipFrom?.postalCode,
            shipperCountry: shipFrom?.province?.countryCode,
            consigneeName: shipTo?.name,
            consigneePhone: shipTo?.phone?.replace(/[^a-zA-Z0-9]/g, ''),
            consigneeCompanyName: shipTo?.company || shipTo?.name,
            consigneeEmail: shipTo?.email,
            consigneeAddress: shipTo?.address,
            consigneeAddressTwo: shipTo?.address2 || "",
            consigneeAddressType: specialService.some(service => service.serviceCode === SHIPPING_LTL_GENERAL_PRIVATE_RESIDENT_DELIVERY_CODE) ? "RESIDENTIAL" : "WAREHOUSE",
            consigneeCity: shipTo?.city,
            consigneeProvince: shipTo?.province?.code,
            consigneePostalCode: shipTo?.postalCode,
            consigneeCountry: shipTo?.province?.countryCode,
            shipToName: shipTo?.name,
            shipToPhone: shipTo?.phone?.replace(/[^a-zA-Z0-9]/g, ''),
            shipToCompanyName: shipTo?.company || shipTo?.name,
            shipToEmail: shipTo?.email,
            shipToAddress: shipTo?.address,
            shipToAddressType: specialService.some(service => service.serviceCode === SHIPPING_LTL_GENERAL_PRIVATE_RESIDENT_DELIVERY_CODE) ? "RESIDENTIAL" : "WAREHOUSE",
            shipToCity: shipTo?.city,
            shipToProvince: shipTo?.province?.code,
            shipToPostalCode: shipTo?.postalCode,
            shipToCountry: shipTo?.province?.countryCode,
            soldToName: shipTo?.name,
            soldToPhone: shipTo?.phone?.replace(/[^a-zA-Z0-9]/g, ''),
            soldToCompanyName: shipTo?.company || shipTo?.name,
            soldToEmail: shipTo?.email,
            soldToAddress: shipTo?.address,
            soldToAddressType: specialService.some(service => service.serviceCode === SHIPPING_LTL_GENERAL_PRIVATE_RESIDENT_DELIVERY_CODE) ? "RESIDENTIAL" : "WAREHOUSE",
            soldToCity: shipTo?.city,
            soldToProvince: shipTo?.province?.code,
            soldToPostalCode: shipTo?.postalCode,
            soldToCountry: shipTo?.province?.countryCode,
            serviceName: "",
            shipDate: dateAndTimeRedux?.shipDate,
            pickupLocationCloseTime: `${dateAndTimeRedux?.shipDate} ${dateAndTimeRedux?.latestPickupTime}`,
            pickupLocationOpenTime: `${dateAndTimeRedux?.shipDate} ${dateAndTimeRedux?.earliestPickupTime}`,
            pickupAvailableTime: `${dateAndTimeRedux?.shipDate} ${dateAndTimeRedux?.availablePickupTime}`,
            pickupDate: dateAndTimeRedux?.pickupDate,
            transMode: "",
            shipmentType: "",
            pickupInstruction: "",
            packageData: palletListRedux,
            specialServices: specialService,
            email: contactEmail,
            refNumber: referenceNumberRedux,
            comments: commentRedux
        }

        try {
            const result = await axios({
                method: 'post',
                url: requestURI,
                headers: {
                    Authorization: `Bearer ${token}`
                },
                data: data
            })
            setErrorMessage('');
            handleSuccessMessage();
            setSuccessMessage('Successfully send quote request.')
            // setLoadingCustomQuote(false);

        } catch (e) {
            console.log(e.response)
            const errorCode = e?.response?.data?.error?.status;
            console.log('error code', errorCode);
            handleErrorMessage();
            handleError(errorCode);
            // setLoadingCustomQuote(false);
        }
    }

    const handleClose = (index) => {
        // console.log('click away', index);
        // console.log('open index', open);
        // setOpen(-1);
        index === open && setOpen(-1);
    };

    const handleOpen = (index) => {
        // console.log('click open', index);
        // console.log('open index', open);
        setOpen(index);
    };

    const handleSelect = (rate) => {
        setSelectedRate(rate);
    }

    const handleStartOver = () => {
        history.push('/business/shipping-ltl/create-label', {
                savedShipFrom: shipFrom,
                savedShipTo: shipTo
            }
        );
    }

    const handleOrderDetails = () => {
        history.push('/business/shipping-ltl/create-label/order-details')
    }

    const handleCrossBorderOrderDetails = () => {
        history.push('/business/shipping-ltl/create-label/cross-border-order-details')
    }

    const handleContactEmail = (event) => {
        const emailAddress = event.target.value;
        const validateEmail = isValidateEmail(emailAddress);
        setContactEmail(event.target.value);
        setContactEmailValidate(validateEmail);
    }

    const handleGetRateFromEmail = () => {
        customQuote();
    }

    const handleBeforeUnload = (event) => {

        console.log('reload');
        // Customize the confirmation message
        const message = 'Are you sure you want to leave? Your unsaved changes may be lost.';

        // Set the confirmation message for modern browsers
        event.returnValue = message;

        // For older browsers
        return message;
    };

    const cbHandleLoading = () => {
        setLoading(false);
    }

    useEffect(() => {
        getAllAvailableServiceNames();
        getAllServiceNames();
    }, [])

    useEffect(() => {
        // Add the event listener when the component mounts
        window.addEventListener('beforeunload', handleBeforeUnload);

        // Remove the event listener when the component unmounts
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []); // Empty dependency array means this effect runs once when the component mounts

    useEffect(() => {
        dispatch(selectShippingLTLCreateLabelRate(selectedRate));
        dispatch(updateShippingLTLCreateLabelMoveFrom({
            ...shipFrom,
            city: selectedRate?.shipperCity
        }));
        dispatch(updateShippingLTLCreateLabelMoveTo({
            ...shipTo,
            city: selectedRate?.consigneeCity
        }));
    }, [selectedRate])

    useEffect(() => {
        // Step 1: Sort the array based on 'total'
        const sortedRate = [...rateRedux]?.sort((a, b) => (a?.costInCAD || Infinity) - (b?.costInCAD || Infinity));
        setRateList(sortedRate);
        setSelectedRate(sortedRate[0]);
    }, [rateRedux]);

    useEffect(() => {
        if (calledAllCarrier > 0) {
            if (calledAllCarrier === availableService?.length) {
                setLoading(false);
            } else {
                setLoading(true);
            }
        } else {
            setLoading(false);
        }
    }, [calledAllCarrier, availableService])

    if (!shipFrom?.country) {
        return (
            <Container maxWidth='xl'>
                <Box style={styles.ShippingPackageCreateLabelRateRoot}>
                    <Box sx={{
                        display: 'flex',
                        gap: '10px',
                        padding: '20px 20px 0 20px'
                    }}>
                        <ChooseCarrierAndServicesIcon width={25} height={25}/>
                        <Typography style={styles.ShippingPackageCreateLabelRateHeading}>
                            {intl.get('SHIPPING_PACKAGE_CREATE_LABEL.CHOOSE_CARRIER.CARRIER_TITLE')}
                        </Typography>
                    </Box>
                    <Divider/>
                    <Box sx={styles.ShippingPackageCreateLabelRateListContent}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography style={{color: 'red', textAlign: 'left', fontSize: '16px'}}>
                                    You've lost all the data. Please return to the previous page and create a new
                                    shipment.
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Box sx={{display: 'flex', width: '100%', justifyContent: 'flex-end', gap: '20px'}}>
                                    <Button
                                        variant='contained'
                                        onClick={handleStartOver}
                                    >
                                        <Typography style={{textTransform: 'none'}}>
                                            Go Back
                                        </Typography>
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Container>
        )
    }

    console.log('[ShippingLTLCreateLabelRateList] rate redux', rateRedux);
    console.log('[ShippingLTLCreateLabelRateList] rate list', rateList);
    console.log('[ShippingLTLCreateLabelRateList] call carrier index', calledAllCarrier);
    console.log('[ShippingLTLCreateLabelRateList] available service', availableService);
    return (
        <Container maxWidth='xl'>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Stepper alternativeLabel activeStep={1} connector={<QontoConnector/>}>
                {
                    steps.map((label, index) => (
                        // (shipFrom?.country === shipTo?.country ? steps : InternationalSteps).map((label, index) => (
                        <Step key={label}>
                            {/*<StepButton onClick={handleStep(index)}>*/}
                            <StepLabel StepIconComponent={QontoStepIcon}>
                                <Typography sx={{color: index <= 1 ? '#1D8B45' : '#C7C7C7', fontSize: '14px'}}>
                                    {label}
                                </Typography>
                            </StepLabel>
                            {/*</StepButton>*/}
                        </Step>
                    ))
                }
            </Stepper>
            <Box sx={styles.ShippingPackageCreateLabelRateRoot}>
                <Box sx={{
                    display: 'flex',
                    gap: '10px',
                    padding: '20px 20px 0 20px'
                }}>
                    <ChooseCarrierAndServicesIcon width={25} height={25}/>
                    <Typography style={styles.ShippingPackageCreateLabelRateHeading}>
                        {intl.get('SHIPPING_PACKAGE_CREATE_LABEL.CHOOSE_CARRIER.CARRIER_TITLE')}
                    </Typography>
                </Box>
                <Divider/>
                <Box sx={styles.ShippingPackageCreateLabelRateListContent}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                flexDirection: 'column'
                            }}>
                                {
                                    loading ? <CircularProgress/>
                                        :
                                        <>
                                            <Box sx={{display: 'flex', gap: '5px'}}>
                                                <CheckIcon width={20} height={20}/>
                                                <Typography sx={{fontWeight: '600'}}>
                                                    All Rates Retrieved!
                                                </Typography>
                                            </Box>
                                            <Typography sx={{fontSize: '14px'}}>
                                                Choose the best carrier for you based on delivery time and reliability.
                                            </Typography>
                                        </>
                                }
                            </Box>
                        </Grid>
                        {
                            rateList?.length > 0 ?
                                <>
                                    {
                                        !isMobile &&
                                        <>
                                            <Grid item xs={2}>
                                            </Grid>
                                            <Grid item xs={2.5}>
                                                <Box sx={styles.ShippingPackageCreateLabelRateOtherBox}>
                                                    <Typography sx={styles.ShippingPackageCreateLabelRateSubHeading}>
                                                        {intl.get('SHIPPING_LTL_QUICK_QUOTE.CHOOSE_CARRIER.CARRIER')}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Box sx={styles.ShippingPackageCreateLabelRateOtherBox}>
                                                    <Typography sx={styles.ShippingPackageCreateLabelRateSubHeading}>
                                                        {intl.get('SHIPPING_LTL_QUICK_QUOTE.CHOOSE_CARRIER.TIME')}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={2.5}>
                                                <Box sx={styles.ShippingPackageCreateLabelRateOtherBox}>
                                                    <Typography sx={styles.ShippingPackageCreateLabelRateSubHeading}>
                                                        {intl.get('SHIPPING_LTL_QUICK_QUOTE.CHOOSE_CARRIER.PRICE')}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={2}>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Divider/>
                                            </Grid>
                                        </>
                                    }
                                    {
                                        rateList?.map((each, index) => {
                                            return (
                                                <Grid item xs={12} key={index}>
                                                    <Box sx={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        gap: '10px',
                                                        marginBottom: index === 0 && "20px"
                                                    }}>
                                                        {
                                                            index === 0 && <Box sx={{
                                                                display: 'flex',
                                                                justifyContent: 'flex-start',
                                                            }}>
                                                                <Typography sx={{
                                                                    backgroundColor: '#1D8B45',
                                                                    color: '#FFFFFF',
                                                                    fontSize: '14px',
                                                                    padding: '5px 10px',
                                                                    borderRadius: '5px'
                                                                }}>
                                                                    Recommendation
                                                                </Typography>
                                                            </Box>
                                                        }
                                                        <ButtonBase sx={{
                                                            width: '100%',
                                                            height: '100%',
                                                            padding: '20px 0',
                                                            borderRadius: '5px',
                                                            border: each === selectedRate ? '3px solid #609966' : '1px solid #000000',
                                                            backgroundColor: index === 0 && '#F3FDE8'
                                                        }}
                                                                    onClick={() => handleSelect(each)}
                                                                    disabled={loading}
                                                        >
                                                            <Box sx={{
                                                                display: 'flex',
                                                                flexDirection: 'column',
                                                                width: '100%',
                                                                height: '100%',
                                                            }}>
                                                                <Grid container spacing={2}>
                                                                    <Grid item md={2} xs={3}>
                                                                        <Box
                                                                            sx={styles?.ShippingPackageCreateLabelRateImageBox}>
                                                                            <img
                                                                                // src={mapLTLLogo(each?.serviceName)}
                                                                                src={`${LABEL_DOWNLOAD_URL_PREFIX}${each?.icon_uri}`}
                                                                                alt="logo"
                                                                                width='60px'/>
                                                                        </Box>
                                                                    </Grid>
                                                                    <Grid item md={2.5} xs={9}>
                                                                        <Box
                                                                            sx={styles?.ShippingPackageCreateLabelRateOtherBox}>
                                                                            <Typography
                                                                                style={styles?.ShippingPackageQuickQuoteRateListText}>
                                                                                {
                                                                                    (each?.carrierName !== each?.serviceName) ? each?.carrierName : each?.serviceName.toUpperCase() === "DAYANDROSS" ? "DAY && ROSS" : each?.serviceName.toUpperCase() === "TFORCE" ? "TForce" : each?.serviceName.toUpperCase()
                                                                                }
                                                                            </Typography>
                                                                        </Box>
                                                                    </Grid>
                                                                    <Grid item md={3} xs={12}>
                                                                        <Box sx={
                                                                            isMobile ?
                                                                                styles?.ShippingPackageCreateLabelRateOtherBoxMobile :
                                                                                styles?.ShippingPackageCreateLabelRateOtherBox
                                                                        }>
                                                                            <Typography
                                                                                style={styles.ShippingPackageQuickQuoteRateListText}>
                                                                                {each?.transitDays || "2 - 10"} days
                                                                            </Typography>
                                                                        </Box>
                                                                    </Grid>
                                                                    <Grid item md={2.5} xs={12}>
                                                                        <Box sx={
                                                                            isMobile ?
                                                                                styles?.ShippingPackageCreateLabelRateOtherBoxMobile :
                                                                                styles?.ShippingPackageCreateLabelRateOtherBox
                                                                        }>
                                                                            <Box sx={{
                                                                                display: 'flex',
                                                                                gap: '5px',
                                                                                alignItems: 'center'
                                                                            }}>
                                                                                <Typography style={{
                                                                                    fontSize: '16px',
                                                                                    fontWeight: '600',
                                                                                }}>
                                                                                    ${(+each?.finalTotal).toFixed(2)} {each?.currency}
                                                                                </Typography>
                                                                                <ClickAwayListener
                                                                                    onClickAway={() => handleClose(index)}>
                                                                                    <div>
                                                                                        <LightTooltip
                                                                                            open={open === index}
                                                                                            PopperProps={{
                                                                                                disablePortal: true,
                                                                                            }}
                                                                                            disableFocusListener
                                                                                            disableHoverListener
                                                                                            disableTouchListener
                                                                                            onClose={() => handleClose(index)}
                                                                                            // onOpen={() => handleOpen(index)}
                                                                                            sx={{
                                                                                                padding: '0',
                                                                                                margin: '0'
                                                                                            }}
                                                                                            placement="right-start"
                                                                                            title={
                                                                                                <Box sx={{
                                                                                                    display: 'flex',
                                                                                                    flexDirection: 'column',
                                                                                                    // width: '300px',
                                                                                                    padding: '10px',
                                                                                                    gap: '15px'
                                                                                                }}>
                                                                                                    <Grid container
                                                                                                          spacing={1}>
                                                                                                        <Grid item
                                                                                                              xs={8}>
                                                                                                            <Typography
                                                                                                                style={{
                                                                                                                    fontSize: '12px',
                                                                                                                    textAlign: 'left'
                                                                                                                }}>
                                                                                                                Shipping
                                                                                                                Fee
                                                                                                            </Typography>
                                                                                                        </Grid>
                                                                                                        <Grid item
                                                                                                              xs={4}>
                                                                                                            <Typography
                                                                                                                style={{
                                                                                                                    fontSize: '12px',
                                                                                                                    textAlign: 'right'
                                                                                                                }}>
                                                                                                                ${(+each?.shippingFee).toFixed(2)} {each?.currency}
                                                                                                            </Typography>
                                                                                                        </Grid>
                                                                                                        <Grid item
                                                                                                              xs={8}>
                                                                                                            <Typography
                                                                                                                style={{
                                                                                                                    fontSize: '12px',
                                                                                                                    textAlign: 'left'
                                                                                                                }}>
                                                                                                                Tax
                                                                                                            </Typography>
                                                                                                        </Grid>
                                                                                                        <Grid item
                                                                                                              xs={4}>
                                                                                                            <Typography
                                                                                                                style={{
                                                                                                                    fontSize: '12px',
                                                                                                                    textAlign: 'right'
                                                                                                                }}>
                                                                                                                ${(+each?.taxCharge).toFixed(2)} {each?.currency}
                                                                                                            </Typography>
                                                                                                        </Grid>
                                                                                                        {(each?.ccrossBorderFee > 0) &&
                                                                                                            <>
                                                                                                                <Grid
                                                                                                                    item
                                                                                                                    xs={8}>
                                                                                                                    <Typography
                                                                                                                        style={{
                                                                                                                            fontSize: '12px',
                                                                                                                            textAlign: 'left'
                                                                                                                        }}>
                                                                                                                        Cross-border
                                                                                                                        Fee
                                                                                                                    </Typography>
                                                                                                                </Grid>
                                                                                                                <Grid
                                                                                                                    item
                                                                                                                    xs={4}>
                                                                                                                    <Typography
                                                                                                                        style={{
                                                                                                                            fontSize: '12px',
                                                                                                                            textAlign: 'right'
                                                                                                                        }}>
                                                                                                                        ${(+each?.crossBorderFee || 0).toFixed(2)} {each?.currency}
                                                                                                                    </Typography>
                                                                                                                </Grid>
                                                                                                            </>
                                                                                                        }
                                                                                                        <Grid item
                                                                                                              xs={8}>
                                                                                                            <Typography
                                                                                                                style={{
                                                                                                                    fontSize: '12px',
                                                                                                                    textAlign: 'left',
                                                                                                                    color: '#1D8B45',
                                                                                                                    fontWeight: '600'
                                                                                                                }}>
                                                                                                                Total
                                                                                                            </Typography>
                                                                                                        </Grid>
                                                                                                        <Grid item
                                                                                                              xs={4}>
                                                                                                            <Typography
                                                                                                                style={{
                                                                                                                    fontSize: '12px',
                                                                                                                    textAlign: 'right',
                                                                                                                    color: '#1D8B45',
                                                                                                                    fontWeight: '600'
                                                                                                                }}>
                                                                                                                ${(+each?.finalTotal).toFixed(2)} {each?.currency}
                                                                                                            </Typography>
                                                                                                        </Grid>
                                                                                                    </Grid>
                                                                                                </Box>
                                                                                            }
                                                                                        >
                                                                                            <IconButton
                                                                                                onClick={() => handleOpen(index)}>
                                                                                                <HelpIcon
                                                                                                    sx={{color: '#1D8B45'}}/>
                                                                                            </IconButton>
                                                                                        </LightTooltip>
                                                                                    </div>
                                                                                </ClickAwayListener>
                                                                            </Box>
                                                                        </Box>
                                                                    </Grid>
                                                                    <Grid item md={2} xs={12}>
                                                                        <Box sx={
                                                                            isMobile ?
                                                                                styles?.ShippingPackageCreateLabelRateOtherBoxMobile :
                                                                                styles?.ShippingPackageCreateLabelRateOtherBox
                                                                        }>
                                                                            {selectedRate === each ?
                                                                                <Button
                                                                                    variant='contained'
                                                                                    size='large'
                                                                                    color='success'
                                                                                    disabled={loading}
                                                                                >
                                                                                    {intl.get('SHIPPING_PACKAGE_CREATE_LABEL.CHOOSE_CARRIER.SELECTED')}
                                                                                </Button>
                                                                                :
                                                                                <Button
                                                                                    variant='outlined'
                                                                                    size='large'
                                                                                    color='success'
                                                                                    onClick={() => handleSelect(each)}
                                                                                    disabled={loading}
                                                                                >
                                                                                    {intl.get('SHIPPING_PACKAGE_CREATE_LABEL.CHOOSE_CARRIER.SELECT')}
                                                                                </Button>
                                                                            }
                                                                        </Box>
                                                                    </Grid>
                                                                </Grid>
                                                            </Box>
                                                        </ButtonBase>
                                                    </Box>
                                                </Grid>
                                            )
                                        })
                                    }
                                    <Grid item xs={12}>
                                        <Box sx={{display: 'flex', alignItems: 'center'}}>
                                            <Typography style={{color: 'red'}}>
                                                *
                                            </Typography>
                                            <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                                Rates may be changed for the variable DG class.
                                            </Typography>
                                        </Box>
                                    </Grid>
                                </>
                                :
                                loading ? <Grid item xs={12}>
                                        <CircularProgress/>
                                    </Grid> :
                                    <>
                                        <Grid item xs={12}>
                                            <Box sx={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
                                                <Typography style={{textAlign: 'left'}}>
                                                    {intl.get('SHIPPING_LTL_QUICK_QUOTE.CONTACT_OP_MESSAGE')}
                                                </Typography>
                                                <Typography style={{
                                                    fontSize: '20px',
                                                    fontWeight: '600',
                                                    textAlign: 'left'
                                                }}>
                                                    {intl.get('SHIPPING_LTL_QUICK_QUOTE.CONTACT_EMAIL')}
                                                </Typography>
                                                <TextField
                                                    // defaultValue={userInfo?.email}
                                                    value={contactEmail}
                                                    onInput={handleContactEmail}
                                                    fullWidth
                                                    size='small'
                                                    disabled={loading}
                                                />
                                                <Typography style={{fontSize: '12px', color: '#FF0303'}}>
                                                    {contactEmailValidate ? "" : intl.get('SHIPPING_LTL_QUICK_QUOTE.DIALOG.VALID_EMAIL')}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </>
                        }
                    </Grid>
                </Box>
            </Box>
            <Grid container spacing={2}>
                {
                    rateList?.length > 0 ?
                        <Grid item xs={12}>
                            <Box sx={{
                                display: 'flex',
                                width: '100%',
                                justifyContent: 'flex-end',
                                gap: '20px'
                            }}>
                                <LoadingButton
                                    size='large'
                                    variant='contained'
                                    onClick={handleStartOver}
                                    sx={{
                                        backgroundColor: '#F2BE22',
                                        "&:hover": {
                                            backgroundColor: '#F2BE22',
                                            filter: 'brightness(0.9)'
                                        }
                                    }}
                                    loading={loading}
                                >
                                    <Typography style={{textTransform: 'none', fontSize: '16px'}}>
                                        Back
                                    </Typography>
                                </LoadingButton>
                                {
                                    selectedRate && (
                                        (shipFrom?.country === shipTo?.country) ?
                                            <LoadingButton
                                                size='large'
                                                color="success"
                                                variant='contained'
                                                // disabled={!selectedRate}
                                                loading={loading}
                                                onClick={handleOrderDetails}
                                            >
                                                <Typography style={{textTransform: 'none', fontSize: '16px'}}>
                                                    Next
                                                </Typography>
                                            </LoadingButton>
                                            :
                                            <LoadingButton
                                                size='large'
                                                color="success"
                                                variant='contained'
                                                // disabled={!selectedRate}
                                                onClick={handleOrderDetails}
                                                loading={loading}
                                            >
                                                <Typography style={{textTransform: 'none', fontSize: '16px'}}>
                                                    Next
                                                </Typography>
                                            </LoadingButton>
                                    )
                                }
                            </Box>
                        </Grid>
                        :
                        <Grid item xs={12}>
                            <Box sx={{
                                display: 'flex',
                                width: '100%',
                                justifyContent: 'flex-end',
                                gap: '20px'
                            }}>
                                <LoadingButton
                                    size='large'
                                    variant='contained'
                                    onClick={handleStartOver}
                                    sx={{
                                        backgroundColor: '#F2BE22',
                                        "&:hover": {
                                            backgroundColor: '#F2BE22',
                                            filter: 'brightness(0.9)'
                                        }
                                    }}
                                    loading={loading}
                                >
                                    <Typography style={{textTransform: 'none', fontSize: '16px'}}>
                                        Back
                                    </Typography>
                                </LoadingButton>
                                <LoadingButton
                                    variant='contained'
                                    onClick={handleGetRateFromEmail}
                                    disabled={!contactEmail || contactEmail.replace(/\s+/g, '') === ""}
                                    sx={{
                                        backgroundColor: '#1D8B45',
                                        "&:hover": {
                                            backgroundColor: '#1D8B45',
                                            filter: 'brightness(0.9)'
                                        }
                                    }}
                                    loading={loading}
                                >
                                    <Typography style={{textTransform: 'none', fontSize: '16px'}}>
                                        {intl.get('SHIPPING_LTL_QUICK_QUOTE.REQUEST_RATE_BTN')}
                                    </Typography>
                                </LoadingButton>
                            </Box>
                        </Grid>
                }
            </Grid>
            {
                rateList?.length > 0 &&
                <Box sx={styles.ShippingPackageCreateLabelRateRoot}>
                    <Box sx={{
                        display: 'flex',
                        gap: '10px',
                        padding: '20px 20px 0 20px'
                    }}>
                        <Typography style={styles.ShippingPackageCreateLabelRateHeading}>
                            Get Rate Via Email
                        </Typography>
                    </Box>
                    <Divider/>
                    <Box sx={styles.ShippingPackageCreateLabelRateListContent}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Box sx={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
                                    <Typography style={{textAlign: 'left'}}>
                                        To receive a quote via email, kindly click on the 'Request Rate via Email'
                                        button, and the operator will promptly assist you with the information you
                                        need.
                                    </Typography>
                                    <InputLabel required>
                                        {intl.get('SHIPPING_LTL_QUICK_QUOTE.CONTACT_EMAIL')}
                                    </InputLabel>
                                    <TextField
                                        // defaultValue={userInfo?.email}
                                        value={contactEmail}
                                        onInput={handleContactEmail}
                                        fullWidth
                                        size='small'
                                        disabled={loading}
                                    />
                                    <Typography style={{fontSize: '12px', color: '#FF0303'}}>
                                        {contactEmailValidate ? "" : intl.get('SHIPPING_LTL_QUICK_QUOTE.DIALOG.VALID_EMAIL')}
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            }
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box sx={{
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'flex-end',
                        gap: '20px',
                        marginBottom: '40px'
                    }}>
                        <LoadingButton
                            variant='contained'
                            onClick={handleGetRateFromEmail}
                            disabled={!contactEmail || contactEmail.replace(/\s+/g, '') === ""}
                            sx={{
                                backgroundColor: '#1D8B45',
                                "&:hover": {
                                    backgroundColor: '#1D8B45',
                                    filter: 'brightness(0.9)'
                                }
                            }}
                            loading={loading}
                        >
                            <Typography style={{textTransform: 'none', fontSize: '16px'}}>
                                {intl.get('SHIPPING_LTL_QUICK_QUOTE.REQUEST_RATE_BTN')}
                            </Typography>
                        </LoadingButton>
                    </Box>
                </Grid>
            </Grid>
            {/*<ShippingLTLCreateLabelRateAverage*/}
            {/*    allService={allService}*/}
            {/*    calledAllCarrier={calledAllCarrier}*/}
            {/*    availableService={availableService}*/}
            {/*    cbHandleLoading={cbHandleLoading}/>*/}
        </Container>
    )
}