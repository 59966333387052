import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {Fragment, useEffect, useState} from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    InputAdornment,
    Snackbar
} from "@mui/material";
import {
    green,
    greenButtonContained, grey,
    greyButtonContained, lightgrey,
    sectionHeading,
    styles,
    subHeading
} from "../../Utils/styleConfig";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import {
    MarketplaceOrderDashboardItemListEach
} from "./MarketplaceOrderDashboardItemListEach";
import FormHelperText from "@mui/material/FormHelperText";
import {Link, useHistory} from "react-router-dom";
import {
    updateReferenceOrderNumberCreateLabelPackage,
    updateShipFromPackageCreateLabelPackage,
    updateShipToPackageCreateLabelPackage
} from "../../actions/BusinessShippingPackageAction";
import {
    updateCreateLabelShippingPackageReferenceOrderNumber,
    updateCreateLabelShippingPackageShipFrom,
    updateCreateLabelShippingPackageShipTo
} from "../../actions/shippingPackageAction";
import {useDispatch, useSelector} from "react-redux";
import {
    toggleECommerceCreateShippingLabel,
    toggleECommerceFulfillItems, updateECommerceFulfillmentNotification,
    updateECommerceItemList
} from "../../actions/eCommerceAction";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Autocomplete from "@material-ui/lab/Autocomplete";
import intl from "react-intl-universal";
import * as React from "react";
import {makeStyles} from "@material-ui/core/styles";
import LoadingButton from "@mui/lab/LoadingButton";
import {NODE_ROUTE_URI, PARTNER_URI} from "../../Utils/apiUrl";
import axios from "axios";
import {getAccessToken} from "../../Utils/doToken";
import MuiAlert from "@mui/material/Alert";
import dayjs from "dayjs";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import {invoiceTypeCommercial} from "../../Utils/config";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const useStyles = makeStyles({
    smallInput: {
        "& .MuiInputBase-input": {
            height: "4px", // Adjust the height as per your preference
            padding: "2px", // Adjust the padding as per your preference
            fontSize: "16px", // Adjust the font size as per your preference
        }
    }
});

export const MarketplaceOrderDashboardItemList = ({
                                                      handleSyncOrderDetail,
                                                      orderData,
                                                      fulfillmentData,
                                                      startsWithBusiness,
                                                      fulFilled
                                                  }) => {

    const history = useHistory();
    const dispatch = useDispatch();
    const classes = useStyles();
    const accessToken = getAccessToken("access_token");

    const isCreateShippingLabelRedux = useSelector(state => state?.eCommerce?.isCreateShippingLabel);
    const isFulfillItemsRedux = useSelector(state => state?.eCommerce?.isFulfillItems);

    const [isCreateShippingLabel, setIsCreateShippingLabel] = useState(false);
    const [isFulfillItems, setIsFulfillItems] = useState(false);
    const [itemList, setItemList] = useState([]);
    const [searchShippingOrderList, setSearchShippingOrderList] = useState([]);
    const [notifyCustomer, setNotifyCustomer] = useState(true);

    const [shippingOrder, setShippingOrder] = useState({
        order_id: null,
        shipping_order_id: null,
        deliver_name: null,
        deliver_area: null,
        deliver_postal_code: null,
        deliver_phone: null

    });
    const [dialogOpen, setDialogOpen] = useState(false);
    const [isCancelled, setIsCancelled] = useState(false);
    const [loadingCancelFulfillment, setLoadingCancelFulfillment] = useState(false);
    const [loadingFulfillment, setLoadingFulfillment] = useState(false);
    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const handleDialogOpen = () => {
        setDialogOpen(true);
    }

    const handleDialogClose = () => {
        setDialogOpen(false);
    }

    const handleCreateShippingLabel = async () => {
        setIsCreateShippingLabel(true);
    }

    const handleCancelCreateShippingLabel = () => {
        setIsCreateShippingLabel(false);
        resetItemList();
    }

    const handleFulfillItems = async () => {
        setIsFulfillItems(true);
    }

    const handleCancelFulfillItems = () => {
        setIsFulfillItems(false);
        resetItemList();
    }

    const updateItemList = (updatedItem) => {
        const updatedItemList = itemList?.map(item => (
            item?.marketplace_product_id === updatedItem?.marketplace_product_id ? updatedItem : item
        ))
        setItemList(updatedItemList);
    }

    const resetItemList = () => {
        if (fulFilled) {
            const updateItemList = fulfillmentData?.fulfillmentLineItems?.map(item => ({
                ...item,
                quantity: item?.product_quantity
            }))
            setItemList(updateItemList);
        } else {
            const updateItemList = fulfillmentData?.unfulfilledProducts?.map(item => ({
                ...item,
                quantity: item?.remaining_quantity
            }))
            setItemList(updateItemList);
        }
    }

    const checkAreAllQuantitiesZero = () => {
        return itemList?.every(item => +item?.quantity === 0);
    }

    const handleNotifyCustomer = (event) => {
        setNotifyCustomer(event?.target?.checked);
    }

    const handleContinueCreateShippingLabel = () => {

        const shipForm = {
            postalCode: orderData?.pickup_postal_code,
            province: {
                code: orderData?.pickup_province,
                name: orderData?.pickup_province
            },
            city: orderData?.pickup_city,
            address: orderData?.pickup_address,
            address2: orderData?.pickup_address_line2,
            country: orderData?.pickup_country,
            displayAddress: {
                description: null,
                placeId: null,
                text: orderData?.pickup_address
            },
            name: orderData?.pickup_name,
            phone: orderData?.pickup_phone || null,
            phoneExt: "",
            email: orderData?.pickup_email,
            company: orderData?.pickup_company,
            saveAddress: false,
            addressList: {
                name: orderData?.pickup_name,
                phone: orderData?.pickup_phone || null,
                email: orderData?.pickup_email,
                address_email: orderData?.pickup_email,
                company_name: orderData?.pickup_company,
                city: null,
                province: null,
                country: null
            }
        }

        const shipTo = {
            postalCode: orderData?.deliver_postal_code,
            province: {
                code: orderData?.deliver_province,
                name: orderData?.deliver_province
            },
            city: orderData?.deliver_city,
            address: orderData?.deliver_address,
            address2: orderData?.deliver_address_line2,
            country: orderData?.deliver_country,
            displayAddress: {
                description: null,
                placeId: null,
                text: orderData?.deliver_address
            },
            name: orderData?.deliver_name,
            phone: orderData?.deliver_phone || null,
            phoneExt: "",
            email: orderData?.deliver_email,
            company: orderData?.deliver_company,
            saveAddress: false,
            addressList: {
                name: orderData?.deliver_name,
                phone: orderData?.deliver_phone || null,
                email: orderData?.deliver_email,
                address_email: orderData?.deliver_email,
                company_name: orderData?.deliver_company,
                city: null,
                province: null,
                country: null
            }
        }

        if (startsWithBusiness) {
            dispatch(updateShipFromPackageCreateLabelPackage(shipForm));
            dispatch(updateShipToPackageCreateLabelPackage(shipTo));
            dispatch(updateReferenceOrderNumberCreateLabelPackage(`${orderData?.store_name}-${orderData?.order_name}`));

        } else {
            dispatch(updateCreateLabelShippingPackageShipFrom(shipForm));
            dispatch(updateCreateLabelShippingPackageShipTo(shipTo));
            dispatch(updateCreateLabelShippingPackageReferenceOrderNumber(`${orderData?.store_name}-${orderData?.order_name}`));
        }

        const updatedItemList = itemList?.filter(item =>
            +item?.quantity !== 0 &&
            item?.quantity !== undefined &&
            item?.quantity !== null &&
            item?.quantity !== ""
        );

        dispatch(updateECommerceItemList(updatedItemList));
        dispatch(updateECommerceFulfillmentNotification(notifyCustomer));

        history.push(`${startsWithBusiness ? '/business/' : '/'}shipping-package/create-label`);
    }

    const cancelFulfillmentByFulfillmentId = async () => {
        try {
            setLoadingCancelFulfillment(true)
            let requestURL = startsWithBusiness ? `${PARTNER_URI}/marketplace/cancelFulfillmentByFulfillmentId` : `${NODE_ROUTE_URI}/marketplace/cancelFulfillmentByFulfillmentId`;

            const result = await axios({
                method: 'post',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
                data: {
                    fulfillmentId: fulfillmentData?.marketplace_fulfillment_id,
                    cancelBackendOrder: false
                }
            })

            console.log('[cancelFulfillmentByFulfillmentId] result', result);
            setIsCancelled(true);
            handleDialogClose();
            setErrorMessage('');
            setSuccessMessage('The fulfillment has been successfully canceled.');
            handleSuccessMessage();
        } catch (e) {
            setErrorMessage('The fulfillment could not be canceled successfully.');
            handleErrorMessage();
        } finally {
            setLoadingCancelFulfillment(false);
        }
    }

    const handleCancelFulfillment = async () => {
        await cancelFulfillmentByFulfillmentId()
    }

    const handleSyncOrder = () => {
        setIsCancelled(false);
        handleSyncOrderDetail();
    }

    const connectMarketplaceFulfillmentOrdersWithExistingBackendOrder = async () => {
        try {
            setLoadingFulfillment(true);
            let requestURL = startsWithBusiness ? `${PARTNER_URI}/marketplace/connectMarketplaceFulfillmentOrdersWithExistingBackendOrder` : `${NODE_ROUTE_URI}/marketplace/connectMarketplaceFulfillmentOrdersWithExistingBackendOrder`;

            const updatedItemList = itemList?.filter(item =>
                +item?.quantity !== 0 &&
                item?.quantity !== undefined &&
                item?.quantity !== null &&
                item?.quantity !== ""
            );

            const fulfilledItemList = updatedItemList?.map(item => ({
                productId: item?.id,
                quantity: item?.quantity
            }))

            const data = {
                fulfillmentOrders: [
                    {
                        fulfillmentOrderId: fulfillmentData?.marketplace_fulfillment_order_id,
                        fulfillmentProducts: fulfilledItemList
                    }
                ],
                backendOrderId: shippingOrder?.order_id,
                notifyCustomer: notifyCustomer
            }

            await axios({
                method: 'post',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
                data: data
            })

            setErrorMessage('');
            setSuccessMessage(' Your items have been successfully fulfilled!.');
            handleSuccessMessage();
            return true;
        } catch (e) {
            setErrorMessage('Your items could not be fulfilled.');
            handleErrorMessage();
            return false;
        } finally {
            setLoadingFulfillment(false);
        }
    }

    const getAvailableBackendOrderListForMarketplaceOrder = async () => {
        try {
            let requestURL = startsWithBusiness ? `${PARTNER_URI}/marketplace/getAvailableBackendOrderListForMarketplaceOrder` : `${NODE_ROUTE_URI}/marketplace/getAvailableBackendOrderListForMarketplaceOrder`;

            const result = await axios({
                method: 'get',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
                params: {
                    page: 0,
                    pageSize: 50
                }
            })
            console.log('[getAvailableBackendOrderListForMarketplaceOrder] result', result);
            setSearchShippingOrderList(result?.data?.data);
        } catch (e) {

        } finally {

        }
    }

    const handleChangeShippingOrder = (event, newValue) => {
        console.log('[handleChangeShippingOrder] newValue', newValue);

        setShippingOrder(prevState => ({
            ...prevState,
            order_id: newValue?.order_id,
            shipping_order_id: newValue?.shipping_order_id,
            deliver_name: newValue?.deliver_name,
            deliver_area: newValue?.deliver_area,
            deliver_phone: newValue?.deliver_phone,
            deliver_postal_code: newValue?.deliver_postal_code
        }))
        ;

    }

    const handleContinueFulfillItems = async () => {
        const fulfillmentResult = await connectMarketplaceFulfillmentOrdersWithExistingBackendOrder();
        fulfillmentResult && handleSyncOrderDetail();
    }

    useEffect(() => {
        getAvailableBackendOrderListForMarketplaceOrder();
    }, [])

    useEffect(() => {
        resetItemList();
    }, [fulfillmentData]);

    // console.log('[MarketplaceOrderDashboardItemList] item list', itemList);
    // console.log('[MarketplaceOrderDashboardItemList] fulfillmentData', fulfillmentData);
    // console.log('[MarketplaceOrderDashboardItemList] isCreateShippingLabelRedux', isCreateShippingLabelRedux);
    // console.log('[MarketplaceOrderDashboardItemList] searchShippingOrderList', searchShippingOrderList);
    // console.log('[MarketplaceOrderDashboardItemList] shippingOrder', shippingOrder);
    // console.log('[MarketplaceOrderDashboardItemList] notifyCustomer', notifyCustomer);
    console.log('[MarketplaceOrderDashboardItemList] orderData', orderData);

    return (
        <Box sx={styles?.root2}>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Dialog
                maxWidth="sm"
                fullWidth
                open={dialogOpen}
                onClose={handleDialogClose}
            >
                <DialogTitle>
                    <Typography sx={{fontSize: sectionHeading, fontWeight: '600'}}>
                        Cancel Fulfillment
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Typography>
                        You are about to cancel the fulfillment for this order. Please note that this action is final
                        and cannot be undone.
                    </Typography>
                    <Typography>
                        Would you like to proceed with the cancellation?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        gap: '10px'
                    }}>
                        <Button
                            variant='contained'
                            sx={greyButtonContained}
                            size='small'
                            onClick={handleDialogClose}
                        >
                            <Typography sx={{textTransform: 'none'}}>
                                No, Keep Fulfillment Active
                            </Typography>
                        </Button>
                        <LoadingButton
                            variant='contained'
                            sx={greenButtonContained}
                            size='small'
                            onClick={handleCancelFulfillment}
                            loading={loadingCancelFulfillment}
                        >
                            <Typography sx={{textTransform: 'none'}}>
                                Yes, Cancel Fulfillment
                            </Typography>
                        </LoadingButton>
                    </Box>
                </DialogActions>
            </Dialog>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box sx={styles?.section}>
                        <Typography sx={{fontSize: subHeading, color: fulFilled && green}}>
                            {fulFilled ? 'Fulfilled' : 'Unfulfilled'}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Divider/>
                </Grid>
                {
                    isCancelled ?
                        <>
                            <Grid item xs={12}>
                                <Box sx={styles?.content}>
                                    <Typography>
                                        The fulfillment has been successfully canceled.
                                    </Typography>
                                    <Box sx={{
                                        display: 'flex',
                                        justifyContent: 'flex-end'
                                    }}>
                                        <Button
                                            variant='contained'
                                            sx={greenButtonContained}
                                            size='small'
                                            onClick={handleSyncOrder}
                                        >
                                            <Typography sx={{textTransform: 'none'}}>
                                                Sync Order
                                            </Typography>
                                        </Button>
                                    </Box>
                                </Box>
                            </Grid>
                        </>
                        :
                        <>
                            <Grid item xs={12}>
                                <Box sx={styles?.content}>
                                    <Grid container spacing={1}>
                                        {
                                            orderData?.marketplace === 'shopify' &&
                                            <Grid item xs={12}>
                                                <Typography sx={{color: grey}}>
                                                    Service
                                                </Typography>
                                                <Typography>
                                                    {+fulfillmentData?.can_fulfill === 1 ? 'Shopify-Managed Fulfillment Services' : 'Third-Party Fulfillment Services'}
                                                </Typography>
                                            </Grid>
                                        }
                                        {
                                            orderData?.marketplace === 'shopify' &&
                                            <Grid item xs={12}>
                                                <Typography sx={{color: grey}}>
                                                    Location
                                                </Typography>
                                                <Typography>
                                                    {fulfillmentData?.location}
                                                </Typography>
                                            </Grid>
                                        }
                                        {
                                            fulFilled &&
                                            <>
                                                <Grid item xs={12}>
                                                    <Typography sx={{color: grey}}>
                                                        Fulfilled
                                                    </Typography>
                                                    <Typography>
                                                        {dayjs(fulfillmentData?.fuilfilled_at)?.format('MMMM DD, YYYY')}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography sx={{color: grey}}>
                                                        Tracking Number
                                                    </Typography>
                                                    <Typography>
                                                        {fulfillmentData?.tracking_nos}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    {
                                                        fulfillmentData?.uuc_order_id ?
                                                            <>
                                                                <Typography sx={{color: grey}}>
                                                                    UUCargo Shipping Order Id
                                                                </Typography>
                                                                <Link
                                                                    to={`${startsWithBusiness ? '/business/' : '/'}package-order-dashboard/${fulfillmentData?.shipping_order_id}`}
                                                                    style={{color: '#1D8B45'}}>
                                                                    <Typography>
                                                                        {fulfillmentData?.shipping_order_id}
                                                                    </Typography>
                                                                </Link>
                                                            </>
                                                            :
                                                            <>
                                                                <Typography sx={{color: grey}}>
                                                                    Provider
                                                                </Typography>
                                                                <Typography>
                                                                    Fulfilled outside of UUCargo
                                                                </Typography>
                                                            </>
                                                    }
                                                </Grid>
                                            </>
                                        }
                                    </Grid>
                                </Box>
                            </Grid>
                            {
                                isCreateShippingLabel ?
                                    <>
                                        <Grid item xs={12}>
                                            <Box sx={styles?.content}>
                                                <Grid container spacing={2}>
                                                    {
                                                        itemList?.map((item, index) => (
                                                            <Grid item xs={12} key={index}>
                                                                <MarketplaceOrderDashboardItemListEach item={item}
                                                                                                       updateItemList={updateItemList}
                                                                                                       marketplace={orderData?.marketplace}/>
                                                            </Grid>
                                                        ))
                                                    }
                                                </Grid>
                                            </Box>
                                        </Grid>
                                        {
                                            orderData?.marketplace === 'shopify' &&
                                            <Grid item md={5} xs={12}>
                                                <Box sx={{
                                                    ...styles?.content,
                                                    display: 'flex',
                                                    alignItems: 'flex-end',
                                                    height: '100%'
                                                }}>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                sx={{
                                                                    color: green,
                                                                    '&.Mui-checked': {
                                                                        color: green,
                                                                    },
                                                                }}
                                                                checked={notifyCustomer}
                                                                onChange={handleNotifyCustomer}
                                                            />
                                                        }
                                                        label="Send a notification to the customer"
                                                    />
                                                </Box>
                                            </Grid>
                                        }
                                        <Grid item xs={12}>
                                            <Box sx={{
                                                ...styles?.content,
                                                display: 'flex',
                                                justifyContent: 'flex-end',
                                                gap: '10px'
                                            }}>
                                                <Button
                                                    variant='contained'
                                                    sx={greyButtonContained}
                                                    size='small'
                                                    onClick={handleCancelCreateShippingLabel}
                                                >
                                                    <Typography sx={{
                                                        textTransform: 'none'
                                                    }}>
                                                        Cancel
                                                    </Typography>
                                                </Button>
                                                <Button
                                                    variant='contained'
                                                    sx={greenButtonContained}
                                                    size='small'
                                                    onClick={handleContinueCreateShippingLabel}
                                                    disabled={checkAreAllQuantitiesZero()}
                                                >
                                                    <Typography sx={{
                                                        textTransform: 'none'
                                                    }}>
                                                        Continue Create Shipping Label
                                                    </Typography>
                                                </Button>
                                            </Box>
                                        </Grid>
                                    </>
                                    :
                                    isFulfillItems ?
                                        <>
                                            <Grid item xs={12}>
                                                <Box sx={styles?.content}>
                                                    <Grid container spacing={2}>
                                                        {
                                                            itemList?.map((item, index) => (
                                                                <Grid item xs={12} key={index}>
                                                                    <MarketplaceOrderDashboardItemListEach item={item}
                                                                                                           updateItemList={updateItemList}
                                                                                                           marketplace={orderData?.marketplace}
                                                                    />
                                                                </Grid>
                                                            ))
                                                        }
                                                    </Grid>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Box sx={styles?.content}>
                                                    <Grid container spacing={2}>
                                                        <Grid item md={7} xs={12}>
                                                            <FormControl fullWidth>
                                                                <FormLabel>
                                                                    Shipping Order
                                                                </FormLabel>
                                                                <Autocomplete
                                                                    value={shippingOrder}
                                                                    fullWidth
                                                                    options={searchShippingOrderList}
                                                                    getOptionLabel={option => {
                                                                        if (!option) return '';
                                                                        const {
                                                                            account_base,
                                                                            shipping_order_id,
                                                                            deliver_name,
                                                                            deliver_phone,
                                                                            deliver_area
                                                                        } = option;

                                                                        // Build the label, excluding null/undefined values
                                                                        return [
                                                                            account_base,
                                                                            shipping_order_id,
                                                                            deliver_name,
                                                                            deliver_phone,
                                                                            deliver_area
                                                                        ]
                                                                            .filter(value => value !== null && value !== undefined) // Exclude null/undefined
                                                                            .join(' | '); // Join with separator
                                                                    }}
                                                                    onChange={handleChangeShippingOrder}
                                                                    renderInput={params => <TextField
                                                                        {...params}
                                                                        className={classes.smallInput}
                                                                        variant="outlined"
                                                                        inputProps={{
                                                                            ...params.inputProps,
                                                                            maxLength: 32, // Set maxLength in inputProps
                                                                        }}
                                                                        // error={validation?.shipToMissing?.includes('name')}
                                                                        // helperText={validation?.shipToMissing?.includes('name') && intl.get('SHIPPING_INFORMATION.REQUIRED')}
                                                                    />}
                                                                    renderOption={option => (
                                                                        <Typography style={{fontSize: '14px'}}>
                                                                            {option?.account_base} | {option?.shipping_order_id} | {option?.deliver_name} | {option?.deliver_phone} | {option?.deliver_area}
                                                                        </Typography>
                                                                    )}
                                                                />
                                                            </FormControl>
                                                        </Grid>
                                                        {
                                                            orderData?.marketplace === 'shopify' &&
                                                            <Grid item md={5} xs={12}>
                                                                <Box sx={{
                                                                    display: 'flex',
                                                                    alignItems: 'flex-end',
                                                                    height: '100%'
                                                                }}>
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Checkbox
                                                                                sx={{
                                                                                    color: green,
                                                                                    '&.Mui-checked': {
                                                                                        color: green,
                                                                                    },
                                                                                }}
                                                                                checked={notifyCustomer}
                                                                                onChange={handleNotifyCustomer}
                                                                            />
                                                                        }
                                                                        label="Send a notification to the customer"
                                                                    />
                                                                </Box>
                                                            </Grid>
                                                        }
                                                    </Grid>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Box sx={{
                                                    ...styles?.content,
                                                    display: 'flex',
                                                    justifyContent: 'flex-end',
                                                    gap: '10px'
                                                }}>
                                                    <Button
                                                        variant='contained'
                                                        sx={greyButtonContained}
                                                        size='small'
                                                        onClick={handleCancelFulfillItems}
                                                    >
                                                        <Typography sx={{
                                                            textTransform: 'none'
                                                        }}>
                                                            Cancel
                                                        </Typography>
                                                    </Button>
                                                    <Button
                                                        variant='contained'
                                                        sx={greenButtonContained}
                                                        size='small'
                                                        onClick={handleContinueFulfillItems}
                                                        disabled={checkAreAllQuantitiesZero() || !shippingOrder?.order_id}
                                                        loading={loadingFulfillment}
                                                    >
                                                        <Typography sx={{
                                                            textTransform: 'none'
                                                        }}>
                                                            Continue Fulfill Items
                                                        </Typography>
                                                    </Button>
                                                </Box>
                                            </Grid>
                                        </>
                                        :
                                        <>
                                            <Grid item xs={12}>
                                                <Box sx={styles?.content}>
                                                    <Typography sx={{color: grey}}>
                                                        Item List
                                                    </Typography>
                                                    {
                                                        itemList?.map((item, index) => (
                                                            <Grid container spacing={2} key={index}>
                                                                <Grid item xs={6}>
                                                                    <Typography>
                                                                        {item?.product_name}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item xs={3}>
                                                                    {
                                                                        +item?.weight > 0 &&
                                                                        <Typography>
                                                                            {item?.weight?.toFixed(2)} {item?.weight_unit}
                                                                        </Typography>
                                                                    }
                                                                </Grid>
                                                                <Grid item xs={3}>
                                                                    <Typography>
                                                                        {item?.quantity}
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                        ))
                                                    }
                                                </Box>
                                            </Grid>
                                            {
                                                (fulFilled && fulfillmentData?.uuc_order_id) &&
                                                <Grid item xs={12}>
                                                    <Box sx={{
                                                        ...styles?.content,
                                                        display: 'flex',
                                                        justifyContent: 'flex-end',
                                                        gap: '10px'
                                                    }}>
                                                        <Button
                                                            variant='contained'
                                                            sx={greyButtonContained}
                                                            size='small'
                                                            onClick={handleDialogOpen}
                                                        >
                                                            <Typography sx={{
                                                                textTransform: 'none'
                                                            }}>
                                                                Cancel Fulfillment
                                                            </Typography>
                                                        </Button>
                                                    </Box>
                                                </Grid>
                                            }
                                            {
                                                (+fulfillmentData?.can_fulfill === 1 && !fulFilled) &&
                                                <Grid item xs={12}>
                                                    <Box sx={{
                                                        ...styles?.content,
                                                        display: 'flex',
                                                        justifyContent: 'flex-end',
                                                        gap: '10px'
                                                    }}>
                                                        <Button
                                                            variant='contained'
                                                            sx={greenButtonContained}
                                                            size='small'
                                                            onClick={handleFulfillItems}
                                                        >
                                                            <Typography sx={{
                                                                textTransform: 'none'
                                                            }}>
                                                                Fulfill Items
                                                            </Typography>
                                                        </Button>
                                                        <Button
                                                            variant='contained'
                                                            sx={greenButtonContained}
                                                            size='small'
                                                            onClick={handleCreateShippingLabel}
                                                        >
                                                            <Typography sx={{
                                                                textTransform: 'none'
                                                            }}>
                                                                Create Shipping Label
                                                            </Typography>
                                                        </Button>
                                                    </Box>
                                                </Grid>
                                            }
                                        </>
                            }
                        </>
                }
            </Grid>
        </Box>
    )
}