import React from "react";
import {Box} from "@material-ui/core";
import AzureAuthenticationButton from "./azure/azure-authentication-component";
import {GoogleOAuthProvider, GoogleLogin} from "@react-oauth/google";
import GoogleSignIn from "./GoogleSignIn";

const CLIENT_ID = "928576037734-8nd81butrv8jls78ti9o8legltnh58i9.apps.googleusercontent.com";

const SocialLogin = (props) => {

    return (
        <Box display="flex" flexDirection="column" alignItems="center">
            <Box id="button2" mb={2} sx={{width: "100%"}}>
                {/*<GoogleOAuthProvider clientId={CLIENT_ID}>*/}
                {/*    <GoogleLogin*/}
                {/*        onSuccess={props.responseSocial()}*/}
                {/*        onError={props.responseSocial()}*/}
                {/*        width="100%"*/}
                {/*    />*/}
                {/*</GoogleOAuthProvider>*/}
                <GoogleSignIn responseSocial={props.responseSocial("google")}/>
            </Box>
            <Box sx={{width: "100%"}}>
                <AzureAuthenticationButton onSuccess={props.responseSocial("microsoft")}/>
            </Box>
        </Box>
    );
};

export default SocialLogin;
