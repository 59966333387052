import {Button, Container, Step, StepLabel, Stepper} from "@mui/material";
import {getAccessToken} from "../../../Utils/doToken";
import {useHistory, useParams} from "react-router-dom";
import {LABEL_DOWNLOAD_URL_PREFIX, LABEL_DOWNLOAD_URL_PREFIX_ALTERNATE, PARTNER_URI} from "../../../Utils/apiUrl";
import axios from "axios";
import {Fragment, useEffect, useState} from "react";
import {InternationalSteps, QontoConnector, QontoStepIcon, steps} from "../../../Utils/CustomComponent";
import Typography from "@mui/material/Typography";
import * as React from "react";
import Box from "@mui/material/Box";
import {ReactComponent as ShipmentOverviewIcon} from "../../../images/NewDesignIcon/ShipmentOverview.svg";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import {ReactComponent as ShippingFromAndToIcon} from "../../../images/NewDesignIcon/ShippingLocation.svg";
import {ReactComponent as PackageDetailIcon} from "../../../images/homepageIcon/Pallet.svg";
import {ReactComponent as PackageTypeIcon} from "../../../images/NewDesignIcon/AdditionalDetails.svg";
import {mapLTLLogo, mapSpecialService} from "../../../Utils/Helper";
import {ReactComponent as SchedulePickupIcon} from "../../../images/NewDesignIcon/SchedulePickup.svg";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import dayjs from "dayjs";
import {ReactComponent as RateDetailsIcon} from "../../../images/NewDesignIcon/RateDetails.svg";
import intl from "react-intl-universal";
import {useInterval} from "usehooks-ts";
import {BusinessLTLShippingOrderDetailsPayment} from "./BusinessLTLShippingOrderDetailsPayment";
import CircularProgress from "@material-ui/core/CircularProgress";
import {LTLShipmentSuccess} from "../../SuccessShipment/LTLShipmentSuccess";

const styles = {
    ShippingPackageCreateLabelOrderDetailsRoot: {
        backgroundColor: '#FFFFFF',
        paddingBottom: '20px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        gap: '20px',
        display: 'flex',
        flexDirection: 'column',
        margin: '20px 0'
    },
    ShippingPackageCreateLabelOrderDetailsHeading: {
        fontSize: '20px',
        fontWeight: '600',
        textAlign: 'left'
    },
    ShippingPackageCreateLabelOrderDetailsSubHeading: {
        fontSize: '18px',
        fontWeight: '600',
        textAlign: 'left'
    },
    ShippingPackageCreateLabelOrderDetailsTitle: {
        fontSize: '16px',
        textAlign: 'center'
    },
    ShippingPackageCreateLabelOrderDetailsTextLeft: {
        fontSize: '16px',
        textAlign: 'left'
    },
    ShippingPackageCreateLabelOrderDetailsTextRight: {
        fontSize: '16px',
        textAlign: 'right'
    },
    ShippingPackageCreateLabelOrderDetailsTitleRight: {
        fontSize: '18px',
        textAlign: 'right',
        fontWeight: '600'
    },
    ShippingPackageCreateLabelOrderDetailsSection: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '40px',
        borderRadius: '10px',
        // boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        border: '1px solid #A9A9A9',
        gap: '20px',
        display: 'flex',
        flexDirection: 'column',
    },
    ShippingPackageCreateLabelOrderDetailsSectionPrice: {
        // backgroundColor: '#FFFFFF',
        padding: '20px',
        // borderRadius: '10px 10px 0 0',
        // border: '2px solid #609966',
        gap: '20px',
        display: 'flex',
        flexDirection: 'column',
    },
    ShippingPackageCreateLabelOrderDetailsOtherBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        height: '100%'
    },
    ShippingPackageCreateLabelOrderDetailsOtherBoxRight: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        height: '100%'
    },
    ShippingPackageCreateLabelOrderDetailsOtherBoxCenter: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%'
    },
    ShippingPackageCreateLabelOrderDetailsImageBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        paddingLeft: '20px',
        gap: '10px'
    },
}

export const BusinessLTLShippingOrderDetails = () => {

    const accessToken = getAccessToken("access_token");

    const history = useHistory();

    const {orderId} = useParams();

    const [creditCardList, setCreditCardList] = useState([]);
    const [creditCardLoading, setCreditCardLoading] = useState(false);
    const [creditCardError, setCreditCardError] = useState(false);
    const [delay, setDelay] = useState(2000);
    const [pageLoading, setPageLoading] = useState(true);
    const [specialServices, setSpecialServices] = useState([]);
    const [orderDetails, setOrderDetails] = useState(null);
    const [backendOrderStatus, setBackendOrderStatus] = useState(-1);

    const getValidCardList = async () => {
        const requestURL = `${PARTNER_URI}/stripe/validCreditCardList`;
        setCreditCardLoading(true);
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                headers: {Authorization: `Bearer ${accessToken}`},
            })
            setCreditCardList(result?.data);
            setCreditCardError(false);
        } catch (e) {
            console.log('error', e.response);
            setCreditCardError(true);
        } finally {
            setCreditCardLoading(false);
        }
    }

    const getOrderData = async () => {
        setPageLoading(true);
        const requestURL = `${PARTNER_URI}/shippingLTL/getLTLOrderDetailsByOrderId`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
                params: {
                    orderId: orderId
                }
            })
            console.log('[BusinessLTLShippingOrderDetails] getOrderData api result', result);

            const serviceList = result?.data?.bolInfo?.pickup_instruction
            // console.log('special service list', serviceList);

            if (serviceList) {
                const services = serviceList.split('\n');
                // console.log('remark note', remarkNote);
                const specialServices = services?.map(element => {
                    return {serviceCode: element}
                })
                // console.log('special service', specialServices);
                setSpecialServices(specialServices);
            }

            setBackendOrderStatus(result?.data?.status_id);
            setOrderDetails(result?.data);

        } catch (e) {
            console.log('e', e?.response);
        } finally {
            setPageLoading(false);
        }
    }

    const getBackendOrderStatus = async () => {
        try {
            const requestURI = `${PARTNER_URI}/looseItemRoute/getBackendOrderStatusByOrderId/${orderId}`;
            const {data} = await axios({
                method: 'get',
                url: requestURI,
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            })
            console.log('[getBackendOrderStatus] data', data);
            setBackendOrderStatus(data?.orderStatus);
        } catch (e) {
            setBackendOrderStatus(0);
            console.log(e);
        }
    }

    useEffect(() => {
        (async () => {
            await getValidCardList();
        })()
    }, [])

    useEffect(() => {
        if (orderId) {
            (async () => {
                await getOrderData();
            })();
        }
    }, [orderId]);

    useInterval(
        () => {
            if (orderId) {
                getBackendOrderStatus();
            }
        },
        // Delay in milliseconds or null to stop it
        (backendOrderStatus === 0 || backendOrderStatus === 100) ? delay : null,
    );

    if (pageLoading || creditCardLoading) {
        return (
            <Container maxWidth='xl'>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh'
                }}>
                    <CircularProgress/>
                </Box>
            </Container>
        )
    }

    if (+backendOrderStatus === 1) {
        return (
            <LTLShipmentSuccess
                orderDetails={orderDetails}/>
        )
    }

    console.log("[BusinessLTLShippingOrderDetails] backend order status: ", backendOrderStatus);

    return (
        <Container maxWidth='xl'>
            <Stepper alternativeLabel
                     activeStep={backendOrderStatus === 1 ? 4 : 3}
                // activeStep={orderDetails?.send_from_country === orderDetails?.send_to_country ? 3 : 4}
                     connector={<QontoConnector/>}>
                {
                    steps.map((label, index) => (
                        // (orderDetails?.send_from_country === orderDetails?.send_to_country ? steps : InternationalSteps).map((label, index) => (
                        <Step key={label}>
                            {/*<StepButton>*/}
                            <StepLabel StepIconComponent={QontoStepIcon}>
                                <Typography sx={{color: index <= 4 ? '#1D8B45' : '#C7C7C7', fontSize: '14px'}}>
                                    {label}
                                </Typography>
                            </StepLabel>
                            {/*</StepButton>*/}
                        </Step>
                    ))
                }
            </Stepper>
            <Box sx={styles.ShippingPackageCreateLabelOrderDetailsRoot}>
                <Box sx={{
                    display: 'flex',
                    gap: '10px',
                    padding: '20px 20px 0 20px'
                }}>
                    <ShipmentOverviewIcon width={25} height={25}/>
                    <Typography style={styles.ShippingPackageCreateLabelOrderDetailsSubHeading}>
                        Place Order
                    </Typography>
                </Box>
                <Divider/>
                {
                    pageLoading ?
                        <Box sx={{
                            backgroundColor: '#FFFFFF',
                            padding: '0 40px',
                            gap: '40px',
                            display: 'flex',
                            justifyContent: 'center'
                        }}>
                            <CircularProgress/>
                        </Box>
                        :
                        <Box sx={{
                            backgroundColor: '#FFFFFF',
                            padding: '0 40px',
                            gap: '40px',
                            display: 'flex',
                        }}>
                            <Grid container spacing={4}>
                                <Grid item md={6} xs={12}>
                                    <Box sx={{display: 'flex', width: '100%', flexDirection: 'column', gap: '60px'}}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={1}>
                                                <ShippingFromAndToIcon width={20} height={20}/>
                                            </Grid>
                                            <Grid item xs={11}>
                                                <Typography sx={{fontWeight: '600'}}>
                                                    Shipping From
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Divider/>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography
                                                    style={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>
                                                    {orderDetails?.pickup_company || orderDetails?.sender}
                                                </Typography>
                                                <Typography
                                                    style={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>
                                                    {orderDetails?.pickup_address_line2 && `${orderDetails?.pickup_address_line2} - `}{orderDetails?.pickup_address}
                                                </Typography>
                                                <Typography
                                                    style={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>
                                                    {orderDetails?.pickup_area}, {orderDetails?.send_from_country}, {orderDetails?.pickup_postal_code}
                                                </Typography>
                                                {
                                                    orderDetails?.pickup_email &&
                                                    <Typography
                                                        style={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>
                                                        {orderDetails?.pickup_email}
                                                    </Typography>
                                                }
                                                <Typography
                                                    style={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>
                                                    {orderDetails?.pickup_phone}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={1}>
                                            <Grid item xs={1}>
                                                <ShippingFromAndToIcon width={20} height={20}/>
                                            </Grid>
                                            <Grid item xs={11}>
                                                <Typography sx={{fontWeight: '600'}}>
                                                    Shipping To
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Divider/>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography
                                                    style={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>
                                                    {orderDetails?.deliver_company || orderDetails?.deliver_name}
                                                </Typography>
                                                <Typography
                                                    style={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>
                                                    {orderDetails?.deliver_address_line2 && `${orderDetails?.deliver_address_line2} - `}{orderDetails?.deliver_address}
                                                </Typography>
                                                <Typography
                                                    style={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>
                                                    {orderDetails?.deliver_area}, {orderDetails?.send_to_country}, {orderDetails?.deliver_postal_code}
                                                </Typography>
                                                {
                                                    orderDetails?.deliver_email &&
                                                    <Typography
                                                        style={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>
                                                        {orderDetails?.deliver_email}
                                                    </Typography>
                                                }
                                                <Typography
                                                    style={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>
                                                    {orderDetails?.deliver_phone}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={1}>
                                            <Grid item xs={1}>
                                                <PackageDetailIcon width={20} height={20}/>
                                            </Grid>
                                            <Grid item xs={11}>
                                                <Typography sx={{fontWeight: '600'}}>
                                                    Pallet Details
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Divider/>
                                            </Grid>
                                            {
                                                orderDetails?.packages?.length > 0 && orderDetails?.packages?.map((each, index) => (
                                                    <Fragment key={index}>
                                                        <Grid item xs={2}>
                                                            <Typography
                                                                style={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>
                                                                {`#${index + 1}`}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Typography
                                                                style={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>
                                                                {each?.length} x {each?.width} x {each?.height} {each?.dimension_unit}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                            <Typography
                                                                style={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>
                                                                {each?.weight} {each?.weight_unit}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                            <Typography
                                                                style={styles.ShippingPackageCreateLabelOrderDetailsTextRight}>
                                                                x {each?.number}
                                                            </Typography>
                                                        </Grid>
                                                    </Fragment>
                                                ))
                                            }
                                        </Grid>
                                        <Grid container spacing={1}>
                                            <Grid item xs={1}>
                                                <PackageTypeIcon width={20} height={20}/>
                                            </Grid>
                                            <Grid item xs={11}>
                                                <Typography sx={{fontWeight: '600'}}>
                                                    Additional Details
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Divider/>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography>
                                                    Reference Order Number: {orderDetails?.bolInfo?.ref_no || 'N/A'}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography>
                                                    Special Services: {specialServices?.length === 0 && 'N/A'}
                                                </Typography>
                                            </Grid>
                                            {
                                                specialServices?.length > 0 && specialServices?.map((each, index) => (
                                                    <Grid item xs={6} key={index}>
                                                        <Typography
                                                            style={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>
                                                            {mapSpecialService(each?.serviceCode)}
                                                        </Typography>
                                                    </Grid>
                                                ))
                                            }
                                            <Grid item xs={12}>
                                                <Typography>
                                                    Remark Notes: {orderDetails?.messages || 'N/A'}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Box sx={{display: 'flex', width: '100%', flexDirection: 'column', gap: '60px'}}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={1}>
                                                <SchedulePickupIcon width={20} height={20}/>
                                            </Grid>
                                            <Grid item xs={11}>
                                                <Typography sx={{fontWeight: '600'}}>
                                                    Schedule Pickup
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Divider/>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography>
                                                    <CheckCircleIcon sx={{color: '#1D8B45'}}/> Pickup Schedule
                                                    on {dayjs(orderDetails?.bolInfo?.pickup_date)?.format('MMMM D, YYYY')}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography>
                                                    From {dayjs(orderDetails?.bolInfo?.pickup_location_open_time, 'HH:mm')?.format('h:mm A')} to {dayjs(orderDetails?.bolInfo?.pickup_location_close_time, 'HH:mm')?.format('h:mm A')}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={1}>
                                            <Grid item xs={1}>
                                                <RateDetailsIcon width={20} height={20}/>
                                            </Grid>
                                            <Grid item xs={11}>
                                                <Typography sx={{fontWeight: '600'}}>
                                                    Rate Details
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Divider/>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <img
                                                    src={`${LABEL_DOWNLOAD_URL_PREFIX}${orderDetails?.icon_uri}`}
                                                    alt="logo"
                                                    width='60px'/>
                                            </Grid>
                                            <Grid item xs={9}>
                                                <Grid container spacing={1}>
                                                    <Grid item xs={6}>
                                                        <Typography>
                                                            {
                                                                orderDetails?.account_base_combine_name.toUpperCase() === "DAYANDROSS" ? "DAY && ROSS" : orderDetails?.account_base_combine_name.toUpperCase() === "TFORCE" ? "TForce" : orderDetails?.account_base_combine_name.toUpperCase()
                                                            }
                                                        </Typography>
                                                        <Typography>
                                                            2-10 business day(s)
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Typography
                                                            style={styles.ShippingPackageCreateLabelOrderDetailsTitleRight}>
                                                            $ {orderDetails?.final_total?.toFixed(2)}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Divider/>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Typography
                                                            style={styles.ShippingPackageCreateLabelOrderDetailsTextRight}>
                                                            {intl.get('ORDER_DASHBOARD.SHIPPING_FEE')}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Typography
                                                            style={styles.ShippingPackageCreateLabelOrderDetailsTextRight}>
                                                            $ {((orderDetails?.total).toFixed(2))}
                                                        </Typography>
                                                    </Grid>
                                                    {
                                                        +orderDetails?.prices?.coupon > 0 &&
                                                        <>
                                                            <Grid item xs={6}>
                                                                <Typography
                                                                    style={styles.ShippingPackageCreateLabelOrderDetailsTextRight}>
                                                                    Coupon
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <Typography
                                                                    style={styles.ShippingPackageCreateLabelOrderDetailsTextRight}>
                                                                    - ${(+orderDetails?.prices?.coupon)?.toFixed(2)}
                                                                </Typography>
                                                            </Grid>
                                                        </>
                                                    }
                                                    <Grid item xs={6}>
                                                        <Typography
                                                            style={styles.ShippingPackageCreateLabelOrderDetailsTextRight}>
                                                            {intl.get('ORDER_DASHBOARD.TAX')}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Typography
                                                            style={styles.ShippingPackageCreateLabelOrderDetailsTextRight}>
                                                            $ {((orderDetails?.taxes).toFixed(2))}
                                                        </Typography>
                                                    </Grid>
                                                    {
                                                        orderDetails?.prices?.brokerage_fee > 0 &&
                                                        <>
                                                            <Grid item xs={6}>
                                                                <Typography
                                                                    style={styles.ShippingPackageCreateLabelOrderDetailsTextRight}>
                                                                    Custom Brokerage Fee
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <Typography
                                                                    style={styles.ShippingPackageCreateLabelOrderDetailsTextRight}>
                                                                    $ {((+orderDetails?.prices?.brokerage_fee).toFixed(2))}
                                                                </Typography>
                                                            </Grid>
                                                        </>
                                                    }
                                                    {
                                                        orderDetails?.prices?.duty_tax > 0 &&
                                                        <>
                                                            <Grid item xs={6}>
                                                                <Typography
                                                                    style={styles.ShippingPackageCreateLabelOrderDetailsTextRight}>
                                                                    Duty And Tax
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <Typography
                                                                    style={styles.ShippingPackageCreateLabelOrderDetailsTextRight}>
                                                                    $ {(+orderDetails?.prices?.duty_tax)?.toFixed(2)}
                                                                </Typography>
                                                            </Grid>
                                                        </>
                                                    }
                                                    <Grid item xs={12}>
                                                        <Divider/>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Typography
                                                            style={styles.ShippingPackageCreateLabelOrderDetailsTitleRight}>
                                                            Total
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Typography
                                                            style={styles.ShippingPackageCreateLabelOrderDetailsTitleRight}>
                                                            $ {orderDetails?.final_total?.toFixed(2)}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                }
            </Box>
            <Box sx={{
                marginBottom: '40px'
            }}>
                {
                    backendOrderStatus === 0 ?
                        <BusinessLTLShippingOrderDetailsPayment orderDetails={orderDetails}
                                                                orderId={orderId}
                                                                creditCardList={creditCardList}
                                                                getValidCardList={getValidCardList}
                                                                getOrderData={getOrderData}/>
                        :
                        backendOrderStatus === 100 ?
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography sx={{
                                        fontSize: '20px',
                                        fontWeight: '600',
                                        textAlign: 'center'
                                    }}>
                                        Your payment was successfully processed, and the order is currently being
                                        processed.
                                    </Typography>
                                </Grid>
                            </Grid>
                            :
                            backendOrderStatus === 200 ?
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography sx={{
                                            fontSize: '20px',
                                            fontWeight: '600',
                                            textAlign: 'center'
                                        }}>
                                            Your payment has been successfully processed, and your order is now under
                                            review by a UUCargo operator.
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box sx={{
                                            display: 'flex',
                                            justifyContent: 'center'
                                        }}>
                                            <Button
                                                variant='contained'
                                                sx={{
                                                    backgroundColor: '#1D8B45',
                                                    "&:hover": {
                                                        backgroundColor: '#1D8B45',
                                                        filter: 'brightness(0.9)'
                                                    }
                                                }}
                                                onClick={() => history.push(`/business/home`)}
                                            >
                                                <Typography sx={{textTransform: 'none'}}>
                                                    Back To Home Page
                                                </Typography>
                                            </Button>
                                        </Box>
                                    </Grid>
                                </Grid>
                                :
                                backendOrderStatus === 1 ?
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Typography sx={{
                                                fontSize: '20px',
                                                fontWeight: '600',
                                                textAlign: 'center'
                                            }}>
                                                Thank you! Your order has been placed.
                                            </Typography>
                                        </Grid>
                                        {
                                            orderDetails?.send_from_country !== orderDetails?.send_to_country &&
                                            < Grid item xs={12}>
                                                <Typography sx={{
                                                    fontSize: '20px',
                                                    fontWeight: '600',
                                                    textAlign: 'center'
                                                }}>
                                                    The next step is to upload the commercial invoice by pressing the
                                                    button below.
                                                </Typography>
                                            </Grid>
                                        }
                                        <Grid item xs={12}>
                                            <Box sx={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                gap: '10px',
                                            }}>
                                                <Button
                                                    // variant='contained'
                                                    onClick={() => history.push(`/business/ltl-order-dashboard/${orderId}`)}
                                                >
                                                    <Typography sx={{textTransform: 'none', color: '#1D8B45'}}>
                                                        See Details of Order
                                                    </Typography>
                                                </Button>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    :
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Typography sx={{
                                                fontSize: '20px',
                                                fontWeight: '600',
                                                textAlign: 'center'
                                            }}>
                                                The order has been canceled.
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box sx={{
                                                display: 'flex',
                                                justifyContent: 'center'
                                            }}>
                                                <Button
                                                    variant='contained'
                                                    sx={{
                                                        backgroundColor: '#1D8B45',
                                                        "&:hover": {
                                                            backgroundColor: '#1D8B45',
                                                            filter: 'brightness(0.9)'
                                                        }
                                                    }}
                                                    onClick={() => history.push(`/business/home`)}
                                                >
                                                    <Typography sx={{textTransform: 'none'}}>
                                                        Back To Home Page
                                                    </Typography>
                                                </Button>
                                            </Box>
                                        </Grid>
                                    </Grid>
                }
            </Box>
        </Container>
    )
}