import {Container, useMediaQuery} from "@mui/material";
import Box from "@mui/material/Box";
import {ShippingPackageQuickQuoteLocation} from "./ShippingPackageQuickQuoteLocation";
import {ShippingPackageQuickQuoteGetRate} from "./ShippingPackageQuickQuoteGetRate";
import {ShippingPackageQuickQuoteRateList} from "./ShippingPackageQuickQuoteRateList";
import {useSelector} from "react-redux";
import {useEffect, useRef, useState} from "react";
import {ShippingPackageQuickQuotePackage} from "./ShippingPackageQuickQuotePackage";
import {ShippingPackageQuickQuoteShippingOptions} from "./ShippingPackageQuickQuoteShippingOptions";
import intl from "react-intl-universal";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import {useHistory} from "react-router-dom";
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import PublicIcon from '@mui/icons-material/Public';
import {ReactComponent as CanadaIcon} from "../../images/homepageIcon/CA.svg";
import {ReactComponent as USAIcon} from "../../images/homepageIcon/USA.svg";
import {ReactComponent as NorthAmericanIcon} from "../../images/icons/Canada&USA.svg";
import {ReactComponent as InternationalIcon} from "../../images/icons/International.svg";
import * as React from "react";
import {defaultText, light_orange, orange} from "../../Utils/styleConfig";
import WarningIcon from "@mui/icons-material/Warning";

const styles = {
    ShippingPackageQuickQuoteRoot: {
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        // margin: '20px 0 100px'
    },
    ShippingPackageQuickQuoteSection: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.3)',
        gap: '10px',
        display: 'flex',
        flexDirection: 'column'
    },
    ShippingPackageQuickQuoteButtonSection: {
        // backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        // borderRadius: '10px',
        // boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        gap: '10px',
        display: 'flex',
        flexDirection: 'column'
    },
    ShippingPackageQuickQuoteHeading: {
        fontSize: '20px',
        fontWeight: '600',
        textAlign: 'left'
    },
}

export const ShippingPackageQuickQuote = () => {

    const history = useHistory();

    const isMobile = useMediaQuery('(max-width: 600px)');

    const rateSection = useRef(null);

    let isGetRate = useSelector(state => state?.shippingPackage?.quickQuoteIsGetRate);
    let packageListRedux = useSelector(state => state?.shippingPackage?.quickQuotePackageList);

    const [isCallAllCarrier, setIsCallAllCarrier] = useState(0);

    const getIsCallAllCarrier = (data) => {
        console.log('is call all carrier', data);
        setIsCallAllCarrier(data);
    }

    const scrollDown = (ref) => {
        console.log(ref.current.offsetTop)
        window.scrollTo({
            top: ref.current.offsetTop,
            behavior: 'smooth',
        });
    };

    useEffect(() => {
        if (isGetRate) {
            scrollDown(rateSection);
        }
    }, [isGetRate])

    console.log('[ShippingPackageQuickQuote] isGetRate', isGetRate);

    return (
        <Container maxWidth='xl'>
            <Box sx={styles.ShippingPackageQuickQuoteRoot}>
                {/*<Box sx={{*/}
                {/*    padding: '15px',*/}
                {/*    border: '2px solid #FF8911',*/}
                {/*    borderRadius: '5px',*/}
                {/*    backgroundColor: light_orange,*/}
                {/*    display: 'flex',*/}
                {/*    gap: '10px'*/}
                {/*}}>*/}
                {/*    <WarningIcon sx={{color: orange}}/>*/}
                {/*    <Typography sx={{fontSize: defaultText, textAlign: 'left'}}>*/}
                {/*        <span*/}
                {/*            style={{fontWeight: '600'}}>{intl.get('WARNING.BOND_TEXT')}</span> {intl.get('WARNING.CONTENT_TEXT')}*/}
                {/*    </Typography>*/}
                {/*</Box>*/}
                <Box sx={styles.ShippingPackageQuickQuoteSection}>
                    <Typography style={styles.ShippingPackageQuickQuoteHeading}>
                        Select Your Packaging Type / Service
                    </Typography>
                    <Box sx={{
                        width: isMobile ? '300px' : '100%',
                        display: 'flex',
                        justifyContent: 'flex-start',
                        flexWrap: 'wrap',
                        gap: '10px'
                    }}>
                        <Box sx={{display: 'flex', alignItems: 'center'}}>
                            <Checkbox
                                icon={<RadioButtonUncheckedIcon/>}
                                checkedIcon={<CheckCircleIcon/>}
                                color="success"
                                checked
                            />
                            <NorthAmericanIcon width="40" height="40"/>
                            <Typography style={{color: '#545454', padding: '0 5px'}}>
                                North America Shipment
                            </Typography>
                        </Box>
                        <Box sx={{display: 'flex', alignItems: 'center'}}>
                            <Checkbox
                                icon={<RadioButtonUncheckedIcon/>}
                                checkedIcon={<CheckCircleIcon/>}
                                color="success"
                                onClick={() => history.push('/shipping-package/international-shipment')}
                            />
                            <InternationalIcon width="40" height="40"/>
                            <Typography style={{color: '#545454', padding: '0 5px'}}>
                                International Shipment
                            </Typography>
                        </Box>
                    </Box>
                </Box>
                <Box sx={styles.ShippingPackageQuickQuoteSection}>
                    <ShippingPackageQuickQuoteLocation/>
                </Box>
                <Box sx={styles.ShippingPackageQuickQuoteSection}>
                    <ShippingPackageQuickQuotePackage packageListRedux={packageListRedux}/>
                </Box>
                <Box sx={styles.ShippingPackageQuickQuoteSection}>
                    <ShippingPackageQuickQuoteShippingOptions/>
                </Box>
                <Box sx={styles.ShippingPackageQuickQuoteButtonSection} ref={rateSection}>
                    <ShippingPackageQuickQuoteGetRate getIsCallAllCarrier={getIsCallAllCarrier}/>
                </Box>
                {isGetRate &&
                    <Box sx={styles.ShippingPackageQuickQuoteSection}>
                        <ShippingPackageQuickQuoteRateList isCallAllCarrier={isCallAllCarrier}/>
                    </Box>
                }
            </Box>
        </Container>
    )
}