import React, {Fragment, useEffect, useState} from "react";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@mui/material/TextField";
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@mui/material/Typography";
import {InputAdornment} from "@material-ui/core";
import {MenuItem, Select} from "@mui/material";
import FormHelperText from "@mui/material/FormHelperText";
import intl from "react-intl-universal";

const palletType = [
    {name: '48x40', length: '48', width: '40'},
    {name: '48x48', length: '48', width: '48'},
]

const heightType = ['60', '70', '80']

const weightType = ['400', '600', '800', '1000']

const useStyles = makeStyles({
    smallInput: {
        "& .MuiInputBase-input": {
            height: "0px", // Adjust the height as per your preference
            padding: "2px", // Adjust the padding as per your preference
            fontSize: "14px", // Adjust the font size as per your preference
        }
    }
});

export const WordPressQuotePalletList = ({cbHandleShippingDetails, validatedResult, lng}) => {

    const classes = useStyles();

    const [palletDetail, setPalletDetail] = useState({
            type: {
                name: '48x40',
                length: '48',
                width: '40'
            },
            length: '48',
            width: '40',
            height: '60',
            weight: '400',
            lengthUnit: 'in',
            weightUnit: 'lb',
            quantity: "1"
        }
    );

    const handleType = (event, value) => {
        setPalletDetail(prevState => ({
            ...prevState,
            type: value,
            length: value?.length,
            width: value?.width,
        }))
    }

    const handleHeight = (event, value) => {
        setPalletDetail(prevState => ({
            ...prevState,
            height: value
        }))
    }

    const handleWeight = (event, value) => {
        setPalletDetail(prevState => ({
            ...prevState,
            weight: value
        }))
    }

    const handleQuantity = event => {
        const {value} = event.target;
        if (/^\d*\.?\d*$/.test(value)) {
            const maximum = 10;
            setPalletDetail(prevState => ({
                ...prevState,
                quantity: value > (+maximum) ? maximum : value
            }))
        }
    }

    useEffect(() => {
        cbHandleShippingDetails(palletDetail);
    }, [palletDetail]);

    return (
        <Fragment>
            <Grid item md={3} xs={12}>
                <FormControl fullWidth>
                    <FormLabel required>
                        {lng === 'cn' ? '托盘类型' : "Pallet Type"}
                    </FormLabel>
                    <Autocomplete
                        value={palletDetail?.type}
                        fullWidth
                        options={palletType}
                        getOptionLabel={option => option.name}
                        onChange={handleType}
                        renderInput={(params) => <TextField
                            variant="outlined"
                            {...params}
                            className={classes.smallInput}
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <>
                                        {params.InputProps.endAdornment}
                                        {/* Add your custom adornment here, e.g. an icon or spinner */}
                                        <InputAdornment position="end">
                                            {palletDetail?.lengthUnit}
                                        </InputAdornment>
                                    </>
                                ),
                            }}
                            error={validatedResult !== null && !palletDetail?.type?.name}
                            helperText={(validatedResult !== null && !palletDetail?.type?.name) && (lng === 'cn' ? "必填" : "Required")}
                        />}
                        renderOption={option => (
                            <Typography style={{fontSize: '14px'}}>
                                {option?.name}
                            </Typography>
                        )}
                    />
                </FormControl>
            </Grid>
            <Grid item md={3} xs={12}>
                <FormControl fullWidth>
                    <FormLabel required>
                        {lng === 'cn' ? '高' : "Height"}
                    </FormLabel>
                    <Autocomplete
                        value={palletDetail?.height}
                        fullWidth
                        options={heightType}
                        getOptionLabel={option => option}
                        onChange={handleHeight}
                        renderInput={(params) => <TextField
                            variant="outlined"
                            {...params}
                            className={classes.smallInput}
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <>
                                        {params.InputProps.endAdornment}
                                        {/* Add your custom adornment here, e.g. an icon or spinner */}
                                        <InputAdornment position="end">
                                            {palletDetail?.lengthUnit}
                                        </InputAdornment>
                                    </>
                                ),
                            }}
                            error={validatedResult !== null && (+palletDetail?.height <= 0 || !palletDetail?.height)}
                            helperText={validatedResult !== null && (!palletDetail?.height ? (lng === 'cn' ? "必填" : "Required") : +palletDetail?.height <= 0 && (lng === 'cn' ? "无效" : "Invalid"))}
                        />}
                        renderOption={option => (
                            <Typography style={{fontSize: '14px'}}>
                                {option}
                            </Typography>
                        )}
                    />
                </FormControl>
            </Grid>
            <Grid item md={3} xs={12}>
                <FormControl fullWidth>
                    <FormLabel required>
                        {lng === 'cn' ? '重量' : "Weight"}
                    </FormLabel>
                    <Autocomplete
                        value={palletDetail?.weight}
                        fullWidth
                        options={weightType}
                        getOptionLabel={option => option}
                        onChange={handleWeight}
                        renderInput={(params) => <TextField
                            variant="outlined"
                            {...params}
                            className={classes.smallInput}
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <>
                                        {params.InputProps.endAdornment}
                                        {/* Add your custom adornment here, e.g. an icon or spinner */}
                                        <InputAdornment position="end">
                                            {palletDetail?.weightUnit}
                                        </InputAdornment>
                                    </>
                                ),
                            }}
                            error={validatedResult !== null && (+palletDetail?.weight <= 0 || !palletDetail?.weight)}
                            helperText={validatedResult !== null && (!palletDetail?.weight ? (lng === 'cn' ? "必填" : "Required") : +palletDetail?.weight <= 0 && (lng === 'cn' ? "无效" : "Invalid"))}
                        />}
                        renderOption={option => (
                            <Typography style={{fontSize: '14px'}}>
                                {option}
                            </Typography>
                        )}
                    />
                </FormControl>
            </Grid>
            <Grid item md={3} xs={12}>
                <FormControl fullWidth>
                    <FormLabel required>
                        {lng === 'cn' ? '数量' : "Quantity"}
                    </FormLabel>
                    <TextField
                        value={palletDetail?.quantity}
                        onInput={handleQuantity}
                        fullWidth
                        variant='outlined'
                        type='number'
                        size='small'
                        InputProps={{
                            inputProps: {
                                min: 1
                            },
                            style: {fontSize: 14}
                        }}
                        error={validatedResult !== null && (+palletDetail?.quantity <= 0 || !palletDetail?.quantity)}
                        helperText={validatedResult !== null && (!palletDetail?.quantity ? (lng === 'cn' ? "必填" : "Required") : +palletDetail?.quantity <= 0 && (lng === 'cn' ? "无效" : "Invalid"))}
                    />
                </FormControl>
            </Grid>
        </Fragment>
    )
}