import {Container} from "@mui/material";
import axios from "axios";
import {useEffect, useState} from "react";
import {loadStripe} from "@stripe/stripe-js";
import {Elements} from "@stripe/react-stripe-js";
import {CreditCardSetupForm} from "./CreditCardSetupForm";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import {getAccessToken} from "../../Utils/doToken";
import {LoadingContainer} from "../shared";
import {NODE_ROUTE_URI} from "../../Utils/apiUrl";



export const CreditCardSetup = ({cbGetValidCardList, handleClose, selectCardId}) => {

    const storedToken = getAccessToken("access_token");

    const [clientSecret, setClientSecret] = useState("");
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [stripePromise, setStripePromise] = useState(null);

    const setupIntent = async () => {
        const requestURL = `${NODE_ROUTE_URI}/stripe/setupIntent`;
        setLoading(true);
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                headers: {Authorization: `Bearer ${storedToken}`},
            })
            console.log('result', result);
            setClientSecret(result.data.client_secret);
            setError(false);
            setLoading(false);

        } catch (e) {
            console.log('error', e.response);
            setError(true);
            setLoading(false);
        }
    }

    const loadStripeLibrary = async () => {
        const stripe = await loadStripe(
            process.env.NODE_ENV === "development" ? "pk_test_51IK8FMH0JPRrjEMmbN0wv8VwP6yvr8SPUd1Kqgsgw930pSRYVrPQxpwRl2XU9VBHcsJjh8uj0CFV7dq3asN9H8w500ihTaHCIB" : "pk_live_51IK8FMH0JPRrjEMm7eHYO7OrcX5T7ddazAQysXyyD4iYnQrTAFY7NWJoH0L28yv48g1KOBUnMH9reHqu1ofPlTbw00YwMFKWVH"
        );
        setStripePromise(stripe);
    };

    useEffect(() => {
        setupIntent();
    }, [])

    useEffect(() => {
        if (clientSecret) {
            loadStripeLibrary();
        }
    },[clientSecret])

    if (loading) {
        return <Box sx={{display: 'flex', justifyContent: 'center', padding: '20px'}}>
            <CircularProgress/>
        </Box>
    }

    if (error && !loading) {
        return <>
            <h3>Sorry We currently encounter some issues, please try again later.</h3>
        </>
    }

    return (
        <Container maxWidth='xl'>
            {loading ? <LoadingContainer/>
                :
                <Elements stripe={stripePromise}>
                    <CreditCardSetupForm clientSecret={clientSecret} cbGetValidCardList={cbGetValidCardList}
                                         handleClose={handleClose} selectCardId={selectCardId}/>
                </Elements>
            }
        </Container>
    )
}
