import React from "react";
import AzureAuthenticationContext from "./azure-authentication-context";

import {Button, Box} from "@material-ui/core";
import MicrosoftLogo from "../../../Images/icons/microsoftLogo";

import {makeStyles} from "@material-ui/core/styles";
import intl from "react-intl-universal";
import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";

const ua = window.navigator.userAgent;
const msie = ua.indexOf("MSIE ");
const msie11 = ua.indexOf("Trident/");
const isIE = msie > 0 || msie11 > 0;

// Log In, Log Out button
const AzureAuthenticationButton = ({onSuccess}) => {
    const classes = useStyles();
    // Azure client context
    const authenticationModule = new AzureAuthenticationContext();

    // const [authenticated, setAuthenticated] = useState(false);
    // const [user, setUser] = useState();

    const logIn = (method) => {
        const typeName = "loginPopup";
        const logInType = isIE ? "loginRedirect" : typeName;

        // Azure Login
        authenticationModule.login(logInType, onSuccess);
    };

    const showLogInButton = () => {
        return (

            <Button
                id="authenticationButton"
                variant="contained"
                onClick={() => logIn("loginPopup")}
                style={{
                    backgroundColor: "#2c2c2e",
                    color: "white",
                    width: "100%",
                    display: 'flex',
                    justifyContent: 'center'
                }}
                startIcon={ <MicrosoftLogo width="18px"/>}
            >
                <Box sx={{display: 'flex', justifyContent: 'center', width: '100%'}}>
                    <Typography sx={{
                        textTransform: 'none',
                        fontSize: '14px'
                    }}>
                        Sign in with Microsoft
                    </Typography>
                </Box>
            </Button>
        );
    };

    return (
        <div id="authentication">
            {authenticationModule.isAuthenticationConfigured ? (
                showLogInButton()
            ) : (
                <div>Authentication Client ID is not configured.</div>
            )}
        </div>
    );
};

const useStyles = makeStyles((theme) => ({
    socialButton: {
        display: "flex",
        justifyContent: "start",
    },
}));

export default AzureAuthenticationButton;
