import Box from "@mui/material/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@mui/material/Typography";
import intl from "react-intl-universal";
import {Divider} from "@mui/material";
import {Fragment, useEffect, useState} from "react";

const style = {
    LTLOrderDashboardPaymentRoot: {
        border: '1px solid #B2B2B2',
        borderRadius: '10px',
        padding: '10px 0',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
    }
}

export const LTLOrderDashboardPayment = ({orderData}) => {

    const [totalDue, setTotalDue] = useState(0);
    const [subTotal, setSubtotal] = useState(0);
    const [totalAmount, setTotalAmount] = useState(0);

    const [transactionFee, setTransactionFee] = useState(0);
    const [payAliPayAmount, setPayAliPayAmount] = useState(0);
    const [payCreditAmount, setPayCreditAmount] = useState(0);
    const [payCreditCardAmount, setPayCreditCardAmount] = useState(0);
    const [payWechatAmount, setPayWechatAmount] = useState(0);

    useEffect(() => {
        if (orderData?.backendPayments?.length > 0) {

            setTransactionFee(orderData?.creditCardTransactionFee || 0);
            setPayAliPayAmount(orderData?.payAliPayAmount || 0);
            setPayCreditAmount(orderData?.payCreditAmount || 0);
            setPayCreditCardAmount(orderData?.payCreditCardAmount || 0);
            setPayWechatAmount(orderData?.payWechatAmount || 0);

            const subTotal = +orderData?.final_total - +orderData?.payCreditAmount;
            const totalAmount = subTotal + orderData?.creditCardTransactionFee;
            const totalDue = +subTotal - +orderData?.payAliPayAmount - +orderData?.payCreditCardAmount - +orderData?.payWechatAmount

            setSubtotal(subTotal);
            setTotalAmount(totalAmount);
            setTotalDue(+totalDue <= 0.02 ? 0 : totalDue);
        }
    }, [orderData]);

    // console.log('[LTLOrderDashboardPayment] order data', orderData);
    // console.log('[LTLOrderDashboardPayment] order data', orderData);
    // console.log('[LTLOrderDashboardPayment] creditPayments', creditPayments);
    // console.log('[LTLOrderDashboardPayment] otherPayments', otherPayments);
    // console.log('[LTLOrderDashboardPayment] totalCredit', totalCredit);
    // console.log('[LTLOrderDashboardPayment] subTotal', subTotal);
    // console.log('[LTLOrderDashboardPayment] totalCredit', totalCredit);
    // console.log('[LTLOrderDashboardPayment] totalAmount', totalAmount);
    // console.log('[LTLOrderDashboardPayment] totalDue', totalDue);
    return (
        <Box sx={style.LTLOrderDashboardPaymentRoot}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        padding: '10px',
                        borderBottom: '1px solid #B2B2B2'
                    }}>
                        <Typography sx={{fontSize: '16px', fontWeight: '600'}}>
                            Payment Details
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={7}>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        height: '100%',
                        justifyContent: 'flex-start',
                        padding: '0 10px',
                    }}>
                        <Typography style={{fontSize: '14px'}}>
                            Shipping Fee
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={5}>
                    <Typography style={{
                        textAlign: 'right',
                        fontSize: '14px',
                        color: '#B454545',
                        paddingRight: '10px'
                    }}>
                        $ {(+orderData?.prices?.total + +orderData?.prices?.coupon)?.toFixed(2)}
                    </Typography>
                </Grid>
                {
                    +orderData?.prices?.coupon > 0 &&
                    <>
                        <Grid item xs={7}>
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                height: '100%',
                                justifyContent: 'flex-start',
                                padding: '0 10px'
                            }}>
                                <Typography style={{fontSize: '14px'}}>
                                    {/*{intl.get('ORDER_DASHBOARD.SHIPPING_DOCUMENTS.TRANSACTION_DETAILS')}*/}
                                    Coupon
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={5}>
                            <Typography style={{
                                textAlign: 'right',
                                fontSize: '14px',
                                color: '#B454545',
                                paddingRight: '10px'
                            }}>
                                - $ {(+orderData?.prices?.coupon)?.toFixed(2)}
                            </Typography>
                        </Grid>
                    </>
                }
                <Grid item xs={7}>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        height: '100%',
                        justifyContent: 'flex-start',
                        padding: '0 10px',
                    }}>
                        <Typography style={{fontSize: '14px'}}>
                            Taxes
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={5}>
                    <Typography style={{
                        textAlign: 'right',
                        fontSize: '14px',
                        color: '#B454545',
                        paddingRight: '10px'
                    }}>
                        $ {(+orderData?.prices?.taxes)?.toFixed(2)}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Divider/>
                </Grid>
                <Grid item xs={7}>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        height: '100%',
                        justifyContent: 'flex-start',
                        padding: '0 10px',
                    }}>
                        <Typography style={{fontSize: '14px'}}>
                            Order Price
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={5}>
                    <Typography style={{
                        textAlign: 'right',
                        fontSize: '14px',
                        color: '#B454545',
                        paddingRight: '10px'
                    }}>
                        $ {(+orderData?.final_total)?.toFixed(2)}
                    </Typography>
                </Grid>
                {
                    orderData?.backendPayments?.length > 0 &&
                    <>
                        {
                            +payCreditAmount > 0 &&
                            <>
                                <Grid item xs={7}>
                                    <Box sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        height: '100%',
                                        justifyContent: 'flex-start',
                                        padding: '0 10px'
                                    }}>
                                        <Typography style={{fontSize: '14px'}}>
                                            UUCargo Credit
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={5}>
                                    <Typography style={{
                                        textAlign: 'right',
                                        fontSize: '14px',
                                        color: '#B454545',
                                        paddingRight: '10px'
                                    }}>
                                        - $ {(+payCreditAmount)?.toFixed(2)}
                                    </Typography>
                                </Grid>
                            </>
                        }
                        {
                            (+subTotal > 0 && +payCreditAmount > 0) &&
                            <>
                                <Grid item xs={12}>
                                    <Divider/>
                                </Grid>
                                <Grid item xs={7}>
                                    <Box sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        height: '100%',
                                        justifyContent: 'flex-start',
                                        padding: '0 10px'
                                    }}>
                                        <Typography style={{fontSize: '14px'}}>
                                            Subtotal
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={5}>
                                    <Typography style={{
                                        textAlign: 'right',
                                        fontSize: '14px',
                                        color: '#B454545',
                                        paddingRight: '10px'
                                    }}>
                                        $ {(+subTotal)?.toFixed(2)}
                                    </Typography>
                                </Grid>
                            </>
                        }
                        {
                            +transactionFee > 0 &&
                            <>
                                <Grid item xs={7}>
                                    <Box sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        height: '100%',
                                        justifyContent: 'flex-start',
                                        padding: '0 10px'
                                    }}>
                                        <Typography style={{fontSize: '14px'}}>
                                            {/*{intl.get('ORDER_DASHBOARD.SHIPPING_DOCUMENTS.TRANSACTION_DETAILS')}*/}
                                            Transaction Fee
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={5}>
                                    <Typography style={{
                                        textAlign: 'right',
                                        fontSize: '14px',
                                        color: '#B454545',
                                        paddingRight: '10px'
                                    }}>
                                        $ {(+transactionFee)?.toFixed(2)}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Divider/>
                                </Grid>
                                <Grid item xs={7}>
                                    <Box sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        height: '100%',
                                        justifyContent: 'flex-start',
                                        padding: '0 10px'
                                    }}>
                                        <Typography style={{fontSize: '14px'}}>
                                            {/*{intl.get('ORDER_DASHBOARD.SHIPPING_DOCUMENTS.TRANSACTION_DETAILS')}*/}
                                            Total Amount
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={5}>
                                    <Typography style={{
                                        textAlign: 'right',
                                        fontSize: '14px',
                                        color: '#B454545',
                                        paddingRight: '10px'
                                    }}>
                                        $ {(+totalAmount)?.toFixed(2)}
                                    </Typography>
                                </Grid>
                            </>
                        }
                        {
                            +payCreditCardAmount > 0 &&
                            <>
                                <Grid item xs={7}>
                                    <Box sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        height: '100%',
                                        justifyContent: 'flex-start',
                                        padding: '0 10px'
                                    }}>
                                        <Typography style={{fontSize: '14px'}}>
                                            {/*{intl.get('ORDER_DASHBOARD.SHIPPING_DOCUMENTS.TRANSACTION_DETAILS')}*/}
                                            Credit Card
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={5}>
                                    <Typography style={{
                                        textAlign: 'right',
                                        fontSize: '14px',
                                        color: '#B454545',
                                        paddingRight: '10px'
                                    }}>
                                        - $ {(+payCreditCardAmount)?.toFixed(2)}
                                    </Typography>
                                </Grid>
                            </>
                        }
                        {
                            +transactionFee > 0 &&
                            <>
                                <Grid item xs={7}>
                                    <Box sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        height: '100%',
                                        justifyContent: 'flex-start',
                                        padding: '0 10px'
                                    }}>
                                        <Typography style={{fontSize: '14px'}}>
                                            {/*{intl.get('ORDER_DASHBOARD.SHIPPING_DOCUMENTS.TRANSACTION_DETAILS')}*/}
                                            Transaction Fee
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={5}>
                                    <Typography style={{
                                        textAlign: 'right',
                                        fontSize: '14px',
                                        color: '#B454545',
                                        paddingRight: '10px'
                                    }}>
                                        - $ {(+transactionFee)?.toFixed(2)}
                                    </Typography>
                                </Grid>
                            </>
                        }
                        {
                            +payAliPayAmount > 0 &&
                            <>
                                <Grid item xs={7}>
                                    <Box sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        height: '100%',
                                        justifyContent: 'flex-start',
                                        padding: '0 10px'
                                    }}>
                                        <Typography style={{fontSize: '14px'}}>
                                            {/*{intl.get('ORDER_DASHBOARD.SHIPPING_DOCUMENTS.TRANSACTION_DETAILS')}*/}
                                            Ali Pay
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={5}>
                                    <Typography style={{
                                        textAlign: 'right',
                                        fontSize: '14px',
                                        color: '#B454545',
                                        paddingRight: '10px'
                                    }}>
                                        - $ {(+payAliPayAmount)?.toFixed(2)}
                                    </Typography>
                                </Grid>
                            </>
                        }
                        {
                            +payWechatAmount > 0 &&
                            <>
                                <Grid item xs={7}>
                                    <Box sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        height: '100%',
                                        justifyContent: 'flex-start',
                                        padding: '0 10px'
                                    }}>
                                        <Typography style={{fontSize: '14px'}}>
                                            {/*{intl.get('ORDER_DASHBOARD.SHIPPING_DOCUMENTS.TRANSACTION_DETAILS')}*/}
                                            WeChat Pay
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={5}>
                                    <Typography style={{
                                        textAlign: 'right',
                                        fontSize: '14px',
                                        color: '#B454545',
                                        paddingRight: '10px'
                                    }}>
                                        - $ {(+payWechatAmount)?.toFixed(2)}
                                    </Typography>
                                </Grid>
                            </>
                        }
                        <Grid item xs={12}>
                            <Divider/>
                        </Grid>
                        <Grid item xs={7}>
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                height: '100%',
                                justifyContent: 'flex-start',
                                padding: '0 10px'
                            }}>
                                <Typography style={{fontSize: '14px'}}>
                                    {/*{intl.get('ORDER_DASHBOARD.SHIPPING_DOCUMENTS.TRANSACTION_DETAILS')}*/}
                                    Total Due
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={5}>
                            <Typography style={{
                                textAlign: 'right',
                                fontSize: '14px',
                                color: '#B454545',
                                paddingRight: '10px'
                            }}>
                                $ {(+totalDue)?.toFixed(2)}
                            </Typography>
                        </Grid>
                    </>
                }
            </Grid>
        </Box>
    )
}