import {useParams} from "react-router-dom";
import {Button, Container, Divider} from "@mui/material";
import Box from "@mui/material/Box";
import {green, greenButtonContained} from "../../Utils/styleConfig";
import Grid from "@mui/material/Grid";
import {WordPressQuoteLocation} from "./WordPressQuoteLocation";
import React, {useState} from "react";
import {WordPressQuoteShippingDetails} from "./WordPressQuoteShippingDetails";
import {WordPressQuoteContact} from "./WordPressQuoteContact";
import LoadingButton from "@mui/lab/LoadingButton";
import Typography from "@mui/material/Typography";
import {
    checkAPICallLimit,
    generateCarShippingQuoteRequest,
    getAPICallTimesFromCookie,
    getInvalidKeysFromObject,
    getMissingKeysFromObject, setAPICallTimesInCookie
} from "../../Utils/Helper";
import {NODE_ROUTE_URI, PARTNER_URI} from "../../Utils/apiUrl";
import axios from "axios";
import dayjs from "dayjs";
import {updateMovingServiceBasicRate, updateMovingServiceBasicRateFromAI} from "../../actions/movingServiceAction";
import CircularProgress from "@material-ui/core/CircularProgress";
import {WordPressQuoteRating} from "./WordPressQuoteRating";
import {LoadingComponent} from "../../Utils/CustomComponent";
import FormHelperText from "@mui/material/FormHelperText";

export const WordPressQuote = () => {

    const {tab} = useParams();
    const {lng} = useParams();

    const [movingFrom, setMovingFrom] = useState(null);
    const [movingTo, setMovingTo] = useState(null);
    const [shippingDetails, setShippingDetails] = useState(null);
    const [contact, setContact] = useState(null);
    const [validatedResult, setValidatedResult] = useState(null);
    const [validatedEmail, setValidatedEmail] = useState(null);
    const [APIRate, setAPIRate] = useState(null);
    const [AIRate, setAIRate] = useState(null);
    const [isCall, setIsCall] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [apiCheck, setAPICheck] = useState(null);

    const cbHandleMovingFrom = (data) => {
        setMovingFrom(data);
    }

    const cbHandleMovingTo = (data) => {
        setMovingTo(data);
    }

    const cbHandleShippingDetails = (data) => {
        setShippingDetails(data);
    }

    const cbHandleContact = (data) => {
        setContact(data);
    }

    const cbHandleValidatedEmail = (data) => {
        setValidatedEmail(data);
    }

    const handleGetPackageRating = async () => {
        try {
            setIsCall(true);
            setLoading(true);
            let requestURL = `${PARTNER_URI}/loose-item/3rd-party/partner/rates/landing/all`;

            const packageObject = {
                length: shippingDetails?.length,
                height: shippingDetails?.height,
                width: shippingDetails?.width,
                quantity: shippingDetails?.quantity,
                weight: shippingDetails?.weight,
                lengthUnit: shippingDetails?.lengthUnit,
                weightUnit: shippingDetails?.weightUnit,
            }

            let updatedPackageList = [];
            updatedPackageList.push(packageObject);

            const data = {
                rateData: {
                    shipFromAddress: "",
                    shipFromAddressTwo: "",
                    shipFromSubCity: movingFrom?.subCity,
                    shipFromCity: movingFrom?.city,
                    shipFromProvince: movingFrom?.province,
                    shipFromPostalCode: movingFrom?.postalCode,
                    shipFromCountry: movingFrom?.country,
                    shipFromName: "",
                    shipFromPhone: "",
                    shipToAddress: "",
                    shipToAddressTwo: "",
                    shipToSubCity: movingTo?.subCity,
                    shipToCity: movingTo?.city,
                    shipToPostalCode: movingTo?.postalCode,
                    shipToProvince: movingTo?.province,
                    shipToCountry: movingTo?.country,
                    shipToName: "",
                    shipToPhone: "",
                    packageData: updatedPackageList,
                },
                sendNotifyEmail: true,
                userEmail: contact?.email,
                userPhone: contact?.phone
            }

            const result = await axios({
                method: 'post',
                url: requestURL,
                data: data
            })

            setAPIRate(result?.data);

        } catch (e) {
            setError(true);
        } finally {
            setLoading(false);
        }
    }

    const getLTLQuoteByDeepSeek = async () => {
        try {
            setLoading(true);
            let requestURL = `${PARTNER_URI}/shippingLTL/getLTLQuoteByDeepSeek`;
            const palletObject = {
                length: shippingDetails?.length,
                height: shippingDetails?.height,
                width: shippingDetails?.width,
                quantity: shippingDetails?.quantity,
                weight: shippingDetails?.weight,
                lengthUnit: shippingDetails?.lengthUnit,
                weightUnit: shippingDetails?.weightUnit,
            }

            let updatedPalletList = [];
            updatedPalletList.push(palletObject);

            const data = {
                shipFromCity: movingFrom?.city,
                shipFromProvince: movingFrom?.province,
                shipFromCountry: movingFrom?.country,
                shipFromPostalCode: movingFrom?.postalCode,
                shipToCity: movingTo?.city,
                shipToProvince: movingTo?.province,
                shipToCountry: movingTo?.country,
                shipToPostalCode: movingTo?.postalCode,
                transitMode: [
                    "rail",
                    "road",
                    "Intermodal"
                ],
                accountBase: "Vitran",
                packageData: updatedPalletList,
                sendNotifyEmail: true,
                userEmail: contact?.email,
                userPhone: contact?.phone
            }

            const result = await axios({
                method: 'post',
                url: requestURL,
                data: data
            })

            console.log('[getLTLQuoteByDeepSeek] result', result);

            setAIRate(result?.data?.ltlQuote);

        } catch (e) {
            console.log('error', e?.response);
            setError(true);
        } finally {
            setLoading(false);
        }
    }

    const handleGetLTLRating = async () => {
        try {
            setIsCall(true);
            setLoading(true);
            let requestURL = `${PARTNER_URI}/shippingLTL/LTLShippingRatingForLanding`;

            const palletObject = {
                length: shippingDetails?.length,
                height: shippingDetails?.height,
                width: shippingDetails?.width,
                quantity: shippingDetails?.quantity,
                weight: shippingDetails?.weight,
                description: "",
                lengthUnit: shippingDetails?.lengthUnit,
                weightUnit: shippingDetails?.weightUnit,
                hscode: "",
                originCountryCode: "",
                currency: "",
                priceEach: "",
                UOM: ""
            }

            let updatedPalletList = [];
            updatedPalletList.push(palletObject);

            const data = {
                shipperAddress: "",
                shipperAddressType: "warehouse",
                shipperSubCity: movingFrom?.subCity,
                shipperCity: movingFrom?.city,
                shipperProvince: movingFrom?.province,
                shipperPostalCode: movingFrom?.postalCode,
                shipperCountry: movingFrom?.country,
                consigneeAddress: "",
                consigneeAddressType: "warehouse",
                consigneeSubCity: movingTo?.subCity,
                consigneeCity: movingTo?.city,
                consigneeProvince: movingTo?.province,
                consigneePostalCode: movingTo?.postalCode,
                consigneeCountry: movingTo?.country,
                packageData: updatedPalletList,
                sendNotifyEmail: true,
                userEmail: contact?.email,
                userPhone: contact?.phone
            }

            const result = await axios({
                method: 'post',
                url: requestURL,
                data: data
            })

            console.log('[handleGetLTLRating] result', result);

            if (result?.data?.length > 0) {
                setLoading(false);
                setAPIRate(result?.data);
            } else {
                await getLTLQuoteByDeepSeek();
            }

        } catch (e) {
            console.log(e.response);
            await getLTLQuoteByDeepSeek();
        }
    }

    const handleConvertToItemList = async () => {
        try {
            setIsCall(true);
            setLoading(true);
            let requestURL = `${NODE_ROUTE_URI}/deepSeek/admin/getMovingInformationByDeepSeek`;

            const messages = [];
            messages.push(shippingDetails)

            const result = await axios({
                method: 'post',
                url: requestURL,
                data: {
                    systemRole: "MOVING",
                    shipFromCity: movingFrom?.city,
                    shipFromProvince: movingFrom?.province?.code,
                    shipFromPostalCode: movingFrom?.postalCode,
                    shipFromCountry: movingFrom?.country,
                    shipFromHouseType: movingFrom?.propertyType?.houseType,
                    shipFromHasLift: movingFrom?.lift !== 0,
                    shipFromFloorLevel: movingFrom?.floorLevel,
                    shipToCity: movingTo?.city,
                    shipToProvince: movingTo?.province?.code,
                    shipToPostalCode: movingTo?.postalCode,
                    shipToCountry: movingTo?.country,
                    shipToHouseType: movingTo?.propertyType?.houseType,
                    shipToHasLift: movingTo?.lift !== 0,
                    shipToFloorLevel: movingTo?.floorLevel,
                    items: shippingDetails?.content,
                    workers: "2",
                    messages: messages,
                }
            })

            console.log('[handleConvertToItemList] result', result);

            const itemList = result?.data?.items;

            if (itemList?.length === 0) {
                setError(true);
            } else {
                await handleGetMovingRating(result?.data);
            }

        } catch (e) {
            setError(true);
        }
    }

    const handleGetMovingRating = async (deepSeekData) => {
        setLoading(true);
        let requestURL = `${NODE_ROUTE_URI}/movingService/admin/movingItemGetGeneralRatingFromAI`;

        const updatedFurnitureList = deepSeekData?.items?.map(item => ({
            ...item,
            lengthUnit: "in",
            weightUnit: "lb",
            packageSpecialServices: {
                wrappingService: false,
                packingService: false,
                assemblyService: false,
                disassemblyService: false
            }
        }))

        const additionalService = {
            scheduleDelivery: false,
            whiteGlove: false,
            packingService: false,
            wrappingService: false,
            assemblyService: false,
            disassemblyService: false,
            storageService: {
                cubicMeterSpace: 0,
                storageDays: 0
            }
        }

        const specialServices = Object.keys(additionalService)
            .filter(key => typeof additionalService[key] === 'boolean' && additionalService[key])
            .map(serviceName => ({serviceName}));

        const data = {
            items: updatedFurnitureList,
            boxList: null,
            pickupWarehouseId: movingFrom?.warehouse?.id || null,
            selfDropOff: movingFrom?.option === 'self',
            pickupAddress: null,
            pickupSubCity: movingFrom?.subCity,
            pickupCity: movingFrom?.city,
            pickupProvince: movingFrom?.province,
            pickupCountry: movingFrom?.country,
            pickupPostalCode: movingFrom?.postalCode,
            deliverWarehouseId: movingTo?.warehouse?.id || null,
            selfPickup: movingTo?.option === 'self',
            deliverAddress: null,
            deliverSubCity: movingTo?.subCity,
            deliverCity: movingTo?.city,
            deliverProvince: movingTo?.province,
            deliverCountry: movingTo?.country,
            deliverPostalCode: movingTo?.postalCode,
            specialServices: specialServices,
            pickupHouseFeatures: {
                houseType: movingFrom?.propertyType?.houseType === 'Apartment' ? 'Condo' : movingFrom?.propertyType?.houseType,
                floorLevel: movingFrom?.floorLevel,
                staircaseNumber: "0",
                isNarrowStreet: movingFrom?.isNarrowStreet,
                drivewaySteepLevel: movingFrom?.isSteepDriveway ? 1 : 0,
                hasLift: !!movingFrom?.lift
            },
            deliverHouseFeatures: {
                houseType: movingTo?.propertyType?.houseType === 'Apartment' ? 'Condo' : movingTo?.propertyType?.houseType,
                floorLevel: movingTo?.floorLevel,
                staircaseNumber: "0",
                isNarrowStreet: movingTo?.isNarrowStreet,
                drivewaySteepLevel: movingTo?.isSteepDriveway ? 1 : 0,
                hasLift: !!movingTo?.lift
            },
            sendNotifyEmail: true,
            userEmail: contact?.email,
            userPhone: contact?.phone,
            loadingTime: deepSeekData?.loadingTime,
            unloadingTime: deepSeekData?.unloadingTime
        }

        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                data: data
            })

            console.log('[handleGetMovingRating] result', result);
            setAPIRate(result?.data);
        } catch (e) {
            console.log(e.response);
            setError(true);
        } finally {
            setLoading(false);
        }
    }

    const getCarQuoteByDeepSeek = async () => {
        try {
            setLoading(true);
            setIsCall(true);
            let requestURL = `${NODE_ROUTE_URI}/deepseek/admin/getCarQuoteByDeepSeek`;

            const carObject = {
                make: shippingDetails.make,
                model: shippingDetails.model,
                yearOfProduction: shippingDetails.year,
                categoryValue: "",
                quantity: 1
            }

            let updatedCarList = [];
            updatedCarList.push(carObject);

            const content = generateCarShippingQuoteRequest({
                shipFromAddress: "",
                shipFromCity: movingFrom?.city,
                shipFromProvince: movingFrom?.province,
                shipFromCountry: movingFrom?.country,
                shipFromPostalCode: movingFrom?.postalCode,
                shipToAddress: "",
                shipToCity: movingTo?.city,
                shipToProvince: movingTo?.province,
                shipToCountry: movingTo?.country,
                shipToPostalCode: movingTo?.postalCode,
                packageData: updatedCarList,
            })

            const data = {
                messages: [
                    {
                        role: "user",
                        content: content
                    }
                ],
                systemRole: "CAR_QUOTE",
                shipFromCity: movingFrom?.city,
                shipFromProvince: movingFrom?.province,
                shipFromCountry: movingFrom?.country,
                shipFromPostalCode: movingFrom?.postalCode,
                shipToCity: movingTo?.city,
                shipToProvince: movingTo?.province,
                shipToCountry: movingTo?.country,
                shipToPostalCode: movingTo?.postalCode,
                packageData: updatedCarList,
                sendNotifyEmail: true,
                userEmail: contact?.email,
                userPhone: contact?.phone
            }

            const result = await axios({
                method: 'post',
                url: requestURL,
                data: data
            })

            console.log('[getCarQuoteByDeepSeek] result', result);
            setAIRate(result?.data);
        } catch (e) {
            console.log('error', e?.response);
            setError(true);
        } finally {
            setLoading(false);
        }
    }

    const handleGetVehicleRating = async () => {
        try {
            setLoading(true);

            let requestURL = `${NODE_ROUTE_URI}/movingCar/getMovingCarRatingList`;

            const carObject = {
                make: shippingDetails.make,
                model: shippingDetails.model,
                yearOfProduction: shippingDetails.year,
                categoryValue: "",
                quantity: 1
            }

            let updatedCarList = [];
            updatedCarList.push(carObject);

            const data = {
                shipFromAddress: "",
                shipFromSubCity: movingFrom?.subCity,
                shipFromCity: movingFrom?.city,
                shipFromProvince: movingFrom?.province,
                shipFromCountry: movingFrom?.country,
                shipFromPostalCode: movingFrom?.postalCode,
                shipToAddress: "",
                shipToSubCity: movingTo?.subCity,
                shipToCity: movingTo?.city,
                shipToProvince: movingTo?.province,
                shipToCountry: movingTo?.country,
                shipToPostalCode: movingTo?.postalCode,
                packageData: updatedCarList,
                sendNotifyEmail: true,
                userEmail: contact?.email,
                userPhone: contact?.phone
            }

            // console.log('[handleGetVehicleRating] data', data);

            const result = await axios({
                method: 'post',
                url: requestURL,
                // headers: {Authorization: `Bearer ${storedToken}`},
                data: data
            })

            console.log('[handleGetVehicleRating] rating result', result);
            // setErrorMessage('');

            if (+result?.data?.finalTotal > 0) {
                setLoading(false);
                setAPIRate(result?.data);
            } else {
                await getCarQuoteByDeepSeek();
            }
        } catch (e) {
            console.log(e.response);
            await getCarQuoteByDeepSeek();
        }
    }

    const validateEmailAddress = async (email) => {
        let requestURL = `${NODE_ROUTE_URI}/validateEmail`;
        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                data: {
                    email: email,
                    validateRegex: true,
                    validateMx: true,
                    validateTypo: false,
                    validateDisposable: false,
                    validateSMTP: false
                }
            })
            console.log('[validateEmailAddress] result - ', result);
            return result?.data;
        } catch (e) {
            console.log(e.response)
        }
    }

    const validationShippingDetails = (shippingDetails) => {
        switch (tab.toLowerCase()) {
            case 'car':
                const carValidation = getMissingKeysFromObject({...shippingDetails});
                return carValidation?.length === 0;
            case 'moving':
                const movingValidation = getMissingKeysFromObject({...shippingDetails});
                return movingValidation?.length === 0;
            case 'ltl':
                const palletMissing = getMissingKeysFromObject({...shippingDetails});
                const palletInvalid = getInvalidKeysFromObject({
                    length: shippingDetails?.length,
                    width: shippingDetails?.width,
                    height: shippingDetails?.height,
                    weight: shippingDetails?.weight,
                    quantity: shippingDetails?.quantity
                });
                return (palletMissing?.length === 0 && palletInvalid?.length === 0);
            case 'package':
                const packageMissing = getMissingKeysFromObject({...shippingDetails});
                const packageInvalid = getInvalidKeysFromObject({
                    length: shippingDetails?.length,
                    width: shippingDetails?.width,
                    height: shippingDetails?.height,
                    weight: shippingDetails?.weight,
                    quantity: shippingDetails?.quantity
                });
                return (packageMissing?.length === 0 && packageInvalid?.length === 0);
            default:
                return true
        }
    }

    const validation = async () => {
        const movingFromValidation = getMissingKeysFromObject({
            city: movingFrom?.city || movingFrom?.subCity,
            province: movingFrom?.province,
            country: movingFrom?.country,
            postalCode: movingFrom?.postalCode,
            houseType: tab?.toLowerCase() === 'moving' ? movingFrom?.propertyType : "N/A"
        })

        const movingToValidation = getMissingKeysFromObject({
            city: movingTo?.city || movingTo?.subCity,
            province: movingTo?.province,
            country: movingTo?.country,
            postalCode: movingTo?.postalCode,
            houseType: tab?.toLowerCase() === 'moving' ? movingTo?.propertyType : "N/A"
        })

        const shippingDetailsValidation = validationShippingDetails(shippingDetails);

        const contactEmailCheck = await validateEmailAddress(contact?.email);
        cbHandleValidatedEmail(contactEmailCheck)

        if (movingFromValidation?.length > 0 ||
            movingToValidation.length > 0 ||
            !shippingDetailsValidation ||
            !contactEmailCheck?.valid
        ) {
            return false
        } else {
            return true
        }
    }

    const handleGetRatingMap = async () => {
        switch (tab.toLowerCase()) {
            case 'car':
                await handleGetVehicleRating();
                break;
            case 'moving':
                await handleConvertToItemList();
                break;
            case 'ltl':
                await handleGetLTLRating();
                break
            case 'package':
                await handleGetPackageRating();
                break
            default:
                return true
        }
    }

    const handleGetRating = async () => {
        const validationResult = await validation();
        setValidatedResult(validationResult);
        if (validationResult) {
            const apiCheckResult = checkAPICallLimit('iframe');
            setAPICheck(apiCheckResult);
            console.log('[handleGetRating] api check result', apiCheckResult);
            if (apiCheckResult?.allowed) {
                await handleGetRatingMap();
            }
        }
    }

    const handleRedirectToApp = () => {
        window.top.location.href = 'https://app.uucargo.ca/sign-in';
    }

    const handleGetAnotherQuote = () => {
        setAPIRate(null);
        setAIRate(null);
        setError(false);
        setIsCall(false);
        setMovingFrom(null);
        setMovingTo(null);
        setShippingDetails(null);
        setContact(null);
        setValidatedEmail(null);
        setValidatedResult(null);
    }

    const redirectToApp = () => {

        return (
            <>
                <Grid item md={6} xs={12}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center'
                    }}>
                        <Button
                            variant='contained'
                            sx={greenButtonContained}
                            onClick={handleRedirectToApp}
                        >
                            <Typography sx={{textTransform: 'none'}}>
                                {lng === 'cn' ? '登录' : "Sign In"}
                            </Typography>
                        </Button>
                    </Box>
                </Grid>
                <Grid item md={6} xs={12}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center'
                    }}>
                        <Button
                            variant='contained'
                            sx={greenButtonContained}
                            onClick={handleGetAnotherQuote}
                        >
                            <Typography sx={{textTransform: 'none'}}>
                                {lng === 'cn' ? '获取新报价' : "Request Another Quote"}
                            </Typography>
                        </Button>
                    </Box>
                </Grid>
            </>
        )
    }

    if (!loading && error) {
        return (
            <Container maxWidth='xl'>
                <Box sx={{
                    display: 'flex',
                    padding: '40px 0px'
                }}>
                    <Box sx={{
                        border: `10px solid ${green}`,
                        borderRadius: '10px',
                        padding: '20px',
                        width: '100%',
                        minHeight: tab?.toLowerCase() === 'moving' ? '600px' : '350px'
                    }}>
                        <Grid container spacing={4}>
                            <Grid item xs={12}>
                                <Typography>
                                    {lng === 'cn' ? '根据您当前的选择，系统无法实时生成报价。' : "Based on the selections you've made, a quote cannot be provided immediately."}
                                </Typography>
                            </Grid>
                            {redirectToApp()}
                        </Grid>
                    </Box>
                </Box>
            </Container>
        )
    }

    if (APIRate || AIRate) {
        return (
            <Container maxWidth='xl'>
                <Box sx={{
                    display: 'flex',
                    padding: '40px 0px'
                }}>
                    <Box sx={{
                        border: `10px solid ${green}`,
                        borderRadius: '10px',
                        padding: '20px',
                        width: '100%',
                        minHeight: tab?.toLowerCase() === 'moving' ? '600px' : '350px'
                    }}>
                        <Grid container spacing={4}>
                            <WordPressQuoteRating
                                tab={tab}
                                redirectToApp={redirectToApp}
                                movingFrom={movingFrom}
                                movingTo={movingTo}
                                shippingDetails={shippingDetails}
                                APIRate={APIRate}
                                AIRate={AIRate}
                                lng={lng}/>
                        </Grid>
                    </Box>
                </Box>
            </Container>
        )
    }

    console.log('[WordPressQuote] movingFrom', movingFrom);
    console.log('[WordPressQuote] movingTo', movingTo);
    console.log('[WordPressQuote] shippingDetails', shippingDetails);
    console.log('[WordPressQuote] validated result', validatedResult);
    console.log('[WordPressQuote] lng', lng);
    console.log('[WordPressQuote] tab', tab);

    return (
        <Container maxWidth='xl'>
            <Box sx={{
                display: 'flex',
                padding: '40px 0px'
            }}>
                <Box sx={{
                    border: `10px solid ${green}`,
                    borderRadius: '10px',
                    padding: '20px',
                    width: '100%',
                    minHeight: tab?.toLowerCase() === 'moving' ? '600px' : '350px'
                }}>
                    <Grid container spacing={4}>
                        <WordPressQuoteLocation tab={tab}
                                                cbHandleMovingFrom={cbHandleMovingFrom}
                                                cbHandleMovingTo={cbHandleMovingTo}
                                                validatedResult={validatedResult}
                                                lng={lng}/>
                        <WordPressQuoteShippingDetails tab={tab}
                                                       cbHandleShippingDetails={cbHandleShippingDetails}
                                                       validatedResult={validatedResult}
                                                       lng={lng}/>
                        <WordPressQuoteContact tab={tab} cbHandleContact={cbHandleContact}
                                               validatedResult={validatedResult}
                                               validatedEmail={validatedEmail}
                                               lng={lng}/>
                        <Grid item xs={12}>
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                flexDirection: 'column',
                                alignItems: 'center'
                            }}>
                                <LoadingButton
                                    variant='contained'
                                    sx={greenButtonContained}
                                    // size='small'
                                    // loading={loading}
                                    onClick={handleGetRating}
                                    disabled={isCall}
                                >
                                    <Typography sx={{textTransform: 'none'}}>
                                        {lng === 'cn' ? '获取免费报价' : "Get Free Quote"}
                                    </Typography>
                                </LoadingButton>
                                {
                                    (!apiCheck?.allowed && apiCheck !== null) &&
                                    <FormHelperText sx={{color: 'error.main'}}>
                                        {lng === 'cn' ? `10分钟内仅允许10次免费查询，请于 ${dayjs(apiCheck?.nextAvailable).format('h:mm:ss a')} 后再试。` : `Maximum 10 free quotes per 10 minutes. Try again at ${dayjs(apiCheck?.nextAvailable).format('h:mm:ss a')}.`}
                                    </FormHelperText>
                                }
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <LoadingComponent loading={loading} message={lng === 'cn' ? "正在加载报价" : "Loading quote..."}/>
        </Container>
    )

}