import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {Divider} from "@mui/material";
import React, {Fragment} from "react";

export const WordPressQuoteRatingCar = ({APIRate, AIRate, redirectToApp, lng}) => {

    if (+APIRate === 0 && +AIRate === 0) {
        return (
            <Fragment>
                <Grid item xs={12}>
                    <Typography>
                        {lng === 'cn' ? '根据您当前的选择，系统无法实时生成报价。' : "Based on the selections you've made, a quote cannot be provided immediately."}
                    </Typography>
                </Grid>
                {redirectToApp()}
            </Fragment>
        )
    }

    return (
        <Fragment>
            <Grid item xs={6}>
                <Typography sx={{fontSize: '16px', textAlign: 'left'}}>
                    {lng === 'cn' ? '小计' : "Subtotal"}
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography sx={{fontSize: '16px', textAlign: 'right'}}>
                    $ {(+APIRate?.totalCharge || +AIRate?.subtotal).toFixed(2)}
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography sx={{fontSize: '16px', textAlign: 'left'}}>
                    {lng === 'cn' ? '税费' : "Tax"}
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography sx={{fontSize: '16px', textAlign: 'right'}}>
                    $ {(+APIRate?.taxCharge || +AIRate?.tax || 0).toFixed(2)}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Divider/>
            </Grid>
            <Grid item xs={6}>
                <Typography sx={{fontSize: '16px', textAlign: 'left'}}>
                    {lng === 'cn' ? '总计' : "Total"}
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography sx={{fontSize: '16px', textAlign: 'right'}}>
                    $ {(+APIRate?.finalTotal || +AIRate?.total)?.toFixed(2)}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography sx={{fontSize: '16px', textAlign: 'left'}}>
                    {lng === 'cn' ? '预计运输时间:' : "Estimated shipping time:"} {APIRate?.shippingDaysRange || AIRate?.days ? `${APIRate?.shippingDaysRange || AIRate?.days} ${lng === 'cn' ? '天' : "days"}` : 'N/A'}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography>
                    {lng === 'cn' ? '预估费用仅供参考。' : "The estimated cost is for reference only."}
                </Typography>
            </Grid>
            {
                redirectToApp()
            }
        </Fragment>
    )
}