import Box from "@mui/material/Box";
import FormLabel from "@mui/material/FormLabel";
import Typography from "@mui/material/Typography";
import * as React from "react";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import LoadingButton from "@mui/lab/LoadingButton";
import {greenButtonContained} from "../../Utils/styleConfig";
import TextField from "@mui/material/TextField";
import {useState} from "react";
import {useLocation} from "react-router-dom";
import {getAccessToken} from "../../Utils/doToken";
import {NODE_ROUTE_URI, PARTNER_URI} from "../../Utils/apiUrl";
import axios from "axios";
import MuiAlert from "@mui/material/Alert";
import {Snackbar} from "@mui/material";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const Coupon = ({orderId, refreshBackendOrder}) => {

    const {pathname} = useLocation();
    const startsWithBusiness = pathname.startsWith('/business');
    const accessToken = getAccessToken("access_token");

    const [couponCode, setCouponCode] = useState('');
    const [couponResult, setCouponResult] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const handleCouponCode = (event) => {
        setCouponCode(event?.target?.value);
    }

    const handleApplyCoupon = async () => {
        try {
            setLoading(true);

            let requestURL = startsWithBusiness ? `${PARTNER_URI}/looseItemRoute/applyCouponForBackendOrder` : `${NODE_ROUTE_URI}/looseItem/applyCouponForBackendOrder`;

            const data = {
                backendOrderId: orderId,
                couponCode
            }

            const result = await axios({
                method: 'post',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${accessToken}`
                },
                data: data
            })
            setError(false);

            setCouponResult(result?.data);
            if (result?.data?.valid) {
                setErrorMessage('');
                setSuccessMessage(result?.data?.result);
                handleSuccessMessage();
                setTimeout(() => refreshBackendOrder(orderId), 2000);
            } else {
                setErrorMessage(result?.data?.result);
                handleErrorMessage();
            }

        } catch (e) {
            setError(true);
        } finally {
            setLoading(false)
        }
    }

    // console.log('[Coupon] coupon code', couponCode);
    // console.log('[Coupon] coupon result', couponResult);

    return (
        <Grid container spacing={1}>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Grid item md={8} xs={12}>
                <FormControl fullWidth>
                    <FormLabel>
                        <Typography sx={{fontWeight: '600'}}>
                            Coupon
                        </Typography>
                    </FormLabel>
                    <TextField
                        value={couponCode}
                        onInput={handleCouponCode}
                        size='small'
                        error={(error || (couponResult !== null && !couponResult?.valid))}
                        helperText={error ? 'Sorry We currently encounter some issues, please try again later.' :
                            (couponResult !== null) && couponResult?.result}
                        sx={{
                            "& .MuiOutlinedInput-root": {
                                "&.Mui-focused fieldset": {
                                    borderColor: couponResult?.valid ? "green" : "", // Green border if valid
                                },
                            },
                            "& .MuiFormHelperText-root": {
                                color: couponResult?.valid ? "green" : "", // Green text if valid
                            },
                        }}
                    />
                </FormControl>
            </Grid>
            <Grid item md={4} xs={12}>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'flex-end',
                    height: '100%'
                }}>
                    <LoadingButton
                        variant='contained'
                        sx={greenButtonContained}
                        size='small'
                        disabled={!couponCode?.trim()}
                        onClick={handleApplyCoupon}
                        loading={loading}
                    >
                        <Typography sx={{
                            textTransform: 'none'
                        }}>
                            Apply
                        </Typography>
                    </LoadingButton>
                </Box>
            </Grid>
        </Grid>
    )
}