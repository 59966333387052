import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {Divider} from "@mui/material";
import React, {Fragment} from "react";
import {LABEL_DOWNLOAD_URL_PREFIX} from "../../Utils/apiUrl";
import Box from "@mui/material/Box";

export const WordPressQuoteRatingPackage = ({APIRate, AIRate, redirectToApp, lng}) => {

    if (APIRate?.length === 0) {
        return (
            <Fragment>
                <Grid item xs={12}>
                    <Typography>
                        {lng === 'cn' ? '根据您当前的选择，系统无法实时生成报价。' : "Based on the selections you've made, a quote cannot be provided immediately."}
                    </Typography>
                </Grid>
                {redirectToApp()}
            </Fragment>
        )
    }

    return (
        <Fragment>
            <Grid item xs={12}>
                <Grid container spacing={2}>
                    {
                        APIRate?.length > 0 &&
                        <>
                            <Grid item xs={4}>
                                <Typography>
                                    {lng === 'cn' ? '承运商' : "Carrier"}
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography>
                                    {lng === 'cn' ? '运输天数' : "Transit Days"}
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography>
                                    {lng === 'cn' ? '费用' : "Cost"}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider/>
                            </Grid>
                            {
                                APIRate?.map((rate, index) => (
                                    <Fragment key={index}>
                                        <Grid item xs={4}>
                                            <Box>
                                                <img src={`${LABEL_DOWNLOAD_URL_PREFIX}${rate?.icon_uri}`} alt="icon"
                                                     width='40px'/>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography>
                                                {rate?.shipRange || '2 - 10'} days
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography>
                                                $ {(+rate?.finalTotalAmount?.value)?.toFixed(2)} {rate?.finalTotalAmount?.currency}
                                            </Typography>
                                        </Grid>
                                    </Fragment>
                                ))
                            }
                        </>
                    }
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Typography>
                    {lng === 'cn' ? '预估费用仅供参考。' : "The estimated cost is for reference only."}
                </Typography>
            </Grid>
            {
                redirectToApp()
            }
        </Fragment>
    )
}