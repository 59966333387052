import {Backdrop, Button, Container, Snackbar} from "@mui/material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {defaultText, greenButtonContained, mainHeading, sectionHeading} from "../../Utils/styleConfig";
import * as React from "react";
import Box from "@mui/material/Box";
import {useHistory, useLocation, useParams} from "react-router-dom";
import {getAccessToken} from "../../Utils/doToken";
import {useEffect, useState} from "react";
import {LABEL_DOWNLOAD_URL_PREFIX_ALTERNATE, NODE_ROUTE_URI, PARTNER_URI} from "../../Utils/apiUrl";
import axios from "axios";
import {MarketplaceOrderDashboardItemList} from "./MarketplaceOrderDashboardItemList";
import {MarketplaceOrderDashboardRecipient} from "./MarketplaceOrderDashboardRecipient";
import {MarketplaceOrderDashboardSender} from "./MarketplaceOrderDashboardSender";
import CircularProgress from "@material-ui/core/CircularProgress";
import dayjs from "dayjs";
import LoadingButton from "@mui/lab/LoadingButton";
import MuiAlert from "@mui/material/Alert";
import {useDispatch} from "react-redux";
import {toggleECommerceCreateShippingLabel, toggleECommerceFulfillItems} from "../../actions/eCommerceAction";
import {LoadingComponent} from "../../Utils/CustomComponent";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const MarketplaceOrderDashboard = () => {

    const accessToken = getAccessToken("access_token");
    const history = useHistory();
    const dispatch = useDispatch();

    const {pathname} = useLocation();
    const startsWithBusiness = pathname.startsWith('/business');
    const {orderId} = useParams();

    const [loading, setLoading] = useState(false);
    const [orderData, setOrderData] = useState(null);
    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [message, setMessage] = useState('');

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const syncMarkerPlaceOrderDetails = async () => {
        try {
            setLoading(true);
            setMessage('Syncing order data, please wait...')
            let requestURL = startsWithBusiness ? `${PARTNER_URI}/marketplace/syncMarketplaceOrderByMarketplaceOrderId` : `${NODE_ROUTE_URI}/marketplace/syncMarketplaceOrderByMarketplaceOrderId`;

            const result = await axios({
                method: 'get',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
                params: {
                    marketplaceOrderId: orderId
                }
            })

            console.log('[syncMarkerPlaceOrderDetails] result', result);
            setErrorMessage('');
            setSuccessMessage('Order synced successfully!');
            handleSuccessMessage();
            return true;
        } catch (e) {
            console.log('[syncMarkerPlaceOrderDetails] error', e?.response)
            setErrorMessage('Failed to sync the order. Please try again.');
            handleErrorMessage();
            return false;
        } finally {
            setLoading(false);
        }
    }

    const getMarketplaceOrderDetails = async (checkSync) => {
        try {
            setLoading(true);
            setMessage('Fetching order details, please wait...');
            let requestURL = startsWithBusiness ? `${PARTNER_URI}/marketplace/getMarketplaceOrderDetailsByMarketplaceOrderId` : `${NODE_ROUTE_URI}/marketplace/getMarketplaceOrderDetailsByMarketplaceOrderId`;

            const result = await axios({
                method: 'get',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
                params: {
                    marketplaceOrderId: orderId
                }
            })

            setOrderData(result?.data);

            if (checkSync) {
                if ((result?.data?.status_name !== 'FULFILLED' && result?.data?.unfulfilled?.some(each => each?.can_fulfill === 1))) {
                    await handleSyncOrderDetail();
                }
            }

        } catch (e) {
            console.log('[getMarketplaceOrderDetails] error', e?.response)

        } finally {
            setLoading(false);
        }
    }

    const handleBackOrderList = () => {
        history.push(`${startsWithBusiness ? '/business/' : '/'}e-commerce/order`)
    }

    const handleSyncOrderDetail = async () => {
        const syncResult = await syncMarkerPlaceOrderDetails();
        syncResult && await getMarketplaceOrderDetails(false);
    }

    useEffect(() => {
        (async () => {
            if (orderId) {
                await getMarketplaceOrderDetails(true);
            }
        })()
    }, [orderId]);

    console.log('[MarketplaceOrderDashboard] order data', orderData);

    return (
        <Container maxWidth='xl'>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Box sx={{display: 'flex', flexDirection: 'column', gap: '20px', marginBottom: '40px'}}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Typography sx={{fontSize: mainHeading, fontWeight: '600'}}>
                            Order Dashboard
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'flex-end'
                        }}>
                            <LoadingButton
                                variant='contained'
                                sx={greenButtonContained}
                                onClick={handleSyncOrderDetail}
                            >
                                <Typography sx={{
                                    textTransform: 'none'
                                }}>
                                    Sync Order Detail
                                </Typography>
                            </LoadingButton>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={{
                            display: 'flex',
                            gap: '10px'
                        }}>
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}>
                                <img src={`${LABEL_DOWNLOAD_URL_PREFIX_ALTERNATE}${orderData?.icon_uri}`} alt="logo"
                                     width='30px'/>
                            </Box>
                            <Typography sx={{fontSize: sectionHeading}}>
                                {orderData?.order_name}
                            </Typography>
                            <Typography sx={{fontSize: sectionHeading}}>
                                {orderData?.status_name}
                            </Typography>
                        </Box>
                        <Typography>
                            <Typography sx={{fontSize: defaultText}}>
                                {dayjs(orderData?.created_at)?.format('MMMM DD, YYYY')}
                            </Typography>
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <MarketplaceOrderDashboardSender orderData={orderData}/>
                    </Grid>
                    <Grid item xs={6}>
                        <MarketplaceOrderDashboardRecipient orderData={orderData}/>
                    </Grid>
                    {
                        orderData?.unfulfilled?.length > 0 && orderData?.unfulfilled?.map((fulfillmentData, index) => (
                            <Grid item xs={12} key={index}>
                                <MarketplaceOrderDashboardItemList
                                    handleSyncOrderDetail={handleSyncOrderDetail}
                                    orderData={orderData}
                                    fulFilled={false}
                                    fulfillmentData={fulfillmentData}
                                    startsWithBusiness={startsWithBusiness}/>
                            </Grid>
                        ))
                    }
                    {
                        orderData?.fulfilled?.length > 0 && orderData?.fulfilled?.map((fulfillmentData, index) => (
                            <Grid item xs={12} key={index}>
                                <MarketplaceOrderDashboardItemList
                                    handleSyncOrderDetail={handleSyncOrderDetail}
                                    orderData={orderData}
                                    fulFilled={true}
                                    fulfillmentData={fulfillmentData}
                                    startsWithBusiness={startsWithBusiness}/>
                            </Grid>
                        ))
                    }
                    <Grid item xs={12}>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            gap: '10px'
                        }}>
                            <Button
                                variant='contained'
                                sx={greenButtonContained}
                                onClick={handleBackOrderList}
                                size='small'
                            >
                                <Typography sx={{textTransform: 'none'}}>
                                    Back Order List
                                </Typography>
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <LoadingComponent loading={loading} message={message}/>
        </Container>
    )
}