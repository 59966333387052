import Box from "@mui/material/Box";
import {ReactComponent as PackageDetailsIcon} from "../../images/NewDesignIcon/Package.svg";
import Typography from "@mui/material/Typography";
import React, {Fragment, useEffect, useState} from "react";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import FormLabel from "@mui/material/FormLabel";
import {useDispatch, useSelector} from "react-redux";
import {updateMovingServiceItemInput, updateMovingServiceItemListFromAI} from "../../actions/movingServiceAction";
import Grid from "@mui/material/Grid";
import LoadingButton from "@mui/lab/LoadingButton";
import {NODE_ROUTE_URI, PARTNER_URI} from "../../Utils/apiUrl";
import axios from "axios";
import {getAccessToken} from "../../Utils/doToken";
import intl from "react-intl-universal";

const styles = {
    MovingServiceItemRoot: {
        backgroundColor: '#FFFFFF',
        paddingBottom: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        gap: '20px',
        display: 'flex',
        flexDirection: 'column',
    },
    MovingServiceAddressHeading: {
        fontSize: '20px',
        fontWeight: '600',
        lineHeight: '20px'
    },
    MovingServiceItemRootRow: {
        display: 'flex',
        gap: '10px',
        justifyContent: 'flex-end',
    },

    MovingServiceItemContent: {
        display: 'flex',
        flexDirection: 'column',
    },

    MovingServiceItemHeading: {
        fontSize: '20px',
        fontWeight: '600'
    },
}

export const MovingServiceItemListInput = ({validatedResult}) => {

    let itemInput = useSelector(state => state?.movingService?.movingItemInput);
    // let itemListFromAI = useSelector(state => state?.movingService?.movingItemListFromAI);

    // const token = getAccessToken("access_token");
    const dispatch = useDispatch();

    const [askMessage, setAskMessage] = useState({
        role: 'user',
        content: ''
    });
    // const [loading, setLoading] = useState(false);
    // const [error, setError] = useState(false);
    // const [itemList, setItemList] = useState([]);

    const handleMovingDescription = (event) => {
        setAskMessage(prevState => ({
            ...prevState,
            content: event?.target?.value
        }));
    }

    // const handleConvertToItemList = async () => {
    //     try {
    //         setLoading(true);
    //         let requestURL = `${NODE_ROUTE_URI}/deepSeek/endUser/createChatCompletion`;
    //
    //         const messages = [];
    //         messages.push(askMessage);
    //
    //         const result = await axios({
    //             method: 'post',
    //             url: requestURL,
    //             headers: {
    //                 Authorization: `Bearer ${token}`
    //             },
    //             data: {
    //                 messages: messages,
    //                 systemRole: "ITEM_LIST"
    //             }
    //         })
    //
    //         console.log('[handleConvertToItemList] result', result);
    //         setItemList(result?.data?.items);
    //
    //         setError(false);
    //
    //     } catch (e) {
    //         setError(true);
    //     } finally {
    //         setLoading(false);
    //     }
    // }

    useEffect(() => {
        setAskMessage(prevState => ({
            ...prevState,
            content: itemInput?.content
        }));
        // setItemList(itemListFromAI);
    }, [])

    useEffect(() => {
        dispatch(updateMovingServiceItemInput(askMessage));
    }, [askMessage]);

    // useEffect(() => {
    //     dispatch(updateMovingServiceItemListFromAI(itemList));
    // }, [itemList]);

    console.log('[MovingServiceItemListInput] askMessage', askMessage);
    console.log('[MovingServiceItemListInput] itemInput', itemInput);
    // console.log('[MovingServiceItemListInput] item list', itemList);
    // console.log('[MovingServiceItemListInput] item list from AI', itemListFromAI);

    return (
        <Box sx={styles.MovingServiceItemRoot}>
            <Box sx={{
                display: 'flex',
                gap: '10px',
                padding: '20px 20px 0 20px',
                justifyContent: 'space-between',
                alignItems: 'center'
            }}>
                <Box sx={{
                    display: 'flex',
                    gap: '10px',
                    alignItems: 'center'
                }}>
                    <PackageDetailsIcon width={25} height={25}/>
                    <Typography style={styles.MovingServiceAddressHeading}>
                        Item List
                    </Typography>
                </Box>
            </Box>
            <Divider/>
            <Box sx={{
                backgroundColor: '#FFFFFF',
                padding: '0 40px',
                gap: '40px',
                display: 'flex',
            }}>
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <FormLabel required sx={{textAlign: 'left'}}>
                                Simply enter what you want to move
                            </FormLabel>
                            <TextField
                                value={askMessage?.content}
                                onInput={handleMovingDescription}
                                fullWidth
                                multiline
                                rows={5}
                                variant="outlined"
                                size='small'
                                error={validatedResult !== null && !askMessage?.content?.trim()}
                                helperText={
                                    validatedResult !== null && !askMessage?.content?.trim()
                                        ? "Required"
                                        : `${askMessage?.content?.length || 0}/350`
                                }
                                inputProps={{maxLength: 350}} // Set max length to 250 characters
                                // disabled={loading}
                                placeholder={intl.get('MOVING.AI_ITEM_LIST_INPUT_PLACEHOLDER')}
                            />
                        </FormControl>
                    </Grid>
                    {/*<Grid item xs={4}>*/}
                    {/*    <Typography sx={{fontWeight: '600', textAlign: 'center'}}>*/}
                    {/*        Converted Item List*/}
                    {/*    </Typography>*/}
                    {/*    <Grid container spacing={0}>*/}
                    {/*        {*/}
                    {/*            itemList?.map((each, index) => (*/}
                    {/*                <Fragment key={index}>*/}
                    {/*                    <Grid item xs={10}>*/}
                    {/*                        <Typography>*/}
                    {/*                            {each?.name}*/}
                    {/*                        </Typography>*/}
                    {/*                    </Grid>*/}
                    {/*                    <Grid item xs={2}>*/}
                    {/*                        <Typography>*/}
                    {/*                            {each?.quantity}*/}
                    {/*                        </Typography>*/}
                    {/*                    </Grid>*/}
                    {/*                </Fragment>*/}
                    {/*            ))*/}
                    {/*        }*/}
                    {/*    </Grid>*/}
                    {/*</Grid>*/}
                    {/*<Grid item xs={12}>*/}
                    {/*    <Box sx={{*/}
                    {/*        display: 'flex',*/}
                    {/*        justifyContent: 'flex-end'*/}
                    {/*    }}>*/}
                    {/*        <LoadingButton*/}
                    {/*            variant="contained"*/}
                    {/*            sx={{*/}
                    {/*                backgroundColor: '#1D8B45',*/}
                    {/*                "&:hover": {*/}
                    {/*                    backgroundColor: '#1D8B45',*/}
                    {/*                    filter: 'brightness(0.9)'*/}
                    {/*                }*/}
                    {/*            }}*/}
                    {/*            size='small'*/}
                    {/*            loading={loading}*/}
                    {/*            onClick={handleConvertToItemList}*/}
                    {/*            disabled={!askMessage?.content?.trim()}*/}
                    {/*        >*/}
                    {/*            <Typography sx={{textTransform: 'none'}}>*/}
                    {/*                Convert to Item List*/}
                    {/*            </Typography>*/}
                    {/*        </LoadingButton>*/}
                    {/*    </Box>*/}
                    {/*</Grid>*/}
                    {/*{*/}
                    {/*    error &&*/}
                    {/*    <Grid item xs={12}>*/}
                    {/*        <Typography sx={{color: 'error.main'}}>*/}
                    {/*            We're experiencing an issue converting your item inputs into an item list using AI.*/}
                    {/*            Please try again later or use the traditional method to select items and get a quote.*/}
                    {/*        </Typography>*/}
                    {/*    </Grid>*/}
                    {/*}*/}
                </Grid>
            </Box>
        </Box>
    )
}