import {Button, Container, Snackbar} from "@mui/material";
import MovingServiceProgressBar from "./MovingServiceProgressBar";
import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import {MovingServiceContact} from "./MovingServiceContact";
import {MovingServiceDate} from "./MovingServiceDate";
import {MovingServiceAdditionalService} from "./MovingServiceAdditionalService";
import {MovingServiceImage} from "./MovingServiceImage";
import {useState} from "react";
import Typography from "@mui/material/Typography";
import intl from "react-intl-universal";
import LoadingButton from "@mui/lab/LoadingButton";
import {useHistory, useLocation, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {NODE_ROUTE_URI, PARTNER_URI} from "../../Utils/apiUrl";
import axios from "axios";
import {
    updateMovingServiceBasicRate,
    updateMovingServiceSummaryContactInfoMissing
} from "../../actions/movingServiceAction";
import MuiAlert from "@mui/material/Alert";
import {getMissingKeysForMovingContact, isValidateEmail} from "../../Utils/Helper";
import {isValid} from "date-fns";
import dayjs from "dayjs";
import MovingItemTitle from "../../images/icons/movingItemTitle.png";
import {getAccessToken} from "../../Utils/doToken";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const styles = {
    MovingServiceSummaryRoot: {
        display: 'flex',
        flexDirection: 'column',
        gap: '30px',
        marginBottom: '40px',
    },
    MovingServiceSummaryButtonSection: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',

    },
}

export const MovingServiceOther = () => {

    const {tab} = useParams();

    const history = useHistory();
    const {pathname} = useLocation();
    const startsWithBusiness = pathname.startsWith('/business');

    const dispatch = useDispatch();
    const storedToken = getAccessToken("access_token");
    const ownerRole = useSelector(state => state?.user?.ownerRole);

    let carList = useSelector(state => state?.movingService?.carList);
    let itemList = useSelector(state => state?.movingService?.itemList);
    let movingFurnitureList = useSelector(state => state?.movingService?.movingFurnitureList);
    let movingFromAddress = useSelector(state => state?.movingService?.movingFromAddress);
    let movingToAddress = useSelector(state => state?.movingService?.movingToAddress);
    let additionalService = useSelector(state => state?.movingService?.additionalService);
    let contactInfo = useSelector(state => state?.movingService?.movingServiceSummaryContactInfo);
    let moveDate = useSelector(state => state?.movingService?.movingDate);
    let moveTime = useSelector(state => state?.movingService?.movingTime);

    const [contactValidate, setContactValidate] = useState(null);
    const [loading, setLoading] = useState(false);
    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const validateContactInfoMissing = () => {
        const missingKeys = getMissingKeysForMovingContact(contactInfo);
        console.log('missing keys - move to', missingKeys);
        dispatch(updateMovingServiceSummaryContactInfoMissing(missingKeys));
        if (missingKeys.length === 0) {
            return false
        } else {
            return true
        }
    }

    const validateContactInfoPickupPhone = () => {

        const checkPhoneNumber = contactInfo.pickupPhone.replace(/\D/g, "");
        if (!isNaN(checkPhoneNumber) && checkPhoneNumber.length < 20 && checkPhoneNumber.length > 9) {
            return false
        } else {
            return true
        }
    }

    const validateContactInfoPickupEmail = () => {
        const result = isValidateEmail(contactInfo?.pickupEmail);
        return result
    }

    const validateContactInfoDeliverPhone = () => {
        const checkPhoneNumber = contactInfo.deliverPhone.replace(/\D/g, "");
        if (!isNaN(checkPhoneNumber) && checkPhoneNumber.length < 20 && checkPhoneNumber.length > 9) {
            return false
        } else {
            return true
        }
    }

    const validateContactInfoDeliverEmail = () => {
        const result = isValidateEmail(contactInfo?.deliverEmail);
        return result
    }

    const validateContactInfo = () => {
        const contactMissing = validateContactInfoMissing();
        const pickupPhoneError = validateContactInfoPickupPhone();
        const pickupEmailError = validateContactInfoPickupEmail();
        const deliverPhoneError = validateContactInfoDeliverPhone();
        const deliverEmailError = validateContactInfoDeliverEmail();
        setContactValidate(prevState => ({
            ...prevState,
            pickupPhoneError,
            pickupEmailError: !pickupEmailError,
            deliverPhoneError,
            deliverEmailError: !deliverEmailError
        }))
        console.log('missing', contactMissing);
        // console.log('phone', phoneError);
        // console.log('email', emailError);
        if (!contactMissing && !pickupPhoneError && pickupEmailError && !deliverPhoneError && deliverEmailError) {
            return false
        } else {
            return true
        }
    }

    const validateMoveDataAndTime = () => {
        if (isValid(moveDate?.$d) && (moveTime?.movingTimeFrom || moveTime?.pickupFlexible)) {
            return false
        } else {
            return true
        }
    }

    const validateWareHouse = (option, warehouse) => {
        if (option === 'schedule') {
            return false
        } else {
            if (warehouse) {
                return false
            } else {
                return true
            }
        }
    }

    const validation = () => {
        setErrorMessage('');
        setToastOpen(false);
        validateContactInfo() && setErrorMessage(prevState => prevState + 'Contact Information is missing some required fields or containing invalid fields.');
        validateMoveDataAndTime() && setErrorMessage(prevState => prevState + 'Moving date or time is missing or invalid.');
        // validateWareHouse(movingFromAddress?.option, movingFromAddress?.warehouse) && setErrorMessage(prevState => prevState + 'Moving from warehouse is missing.');
        // validateWareHouse(movingToAddress?.option, movingToAddress?.warehouse) && setErrorMessage(prevState => prevState + 'Moving to warehouse is missing.');
        if (validateContactInfo()
            || validateMoveDataAndTime()
            // || validateWareHouse(movingFromAddress?.option, movingFromAddress?.warehouse)
            // || validateWareHouse(movingToAddress?.option, movingToAddress?.warehouse)
        ) {
            handleErrorMessage();
            return false
        } else {
            return true
        }
    }

    const handleGetRating = async () => {
        setLoading(true);

        let requestURL = `${NODE_ROUTE_URI}/movingService/movingItemGetRatingForLocalMoving`;

        const updatedItemList = itemList?.map(item => ({
            description: item.category,
            cateId: item.cate_id,
            length: item.length,
            width: item.width,
            height: item.height,
            weight: item.weight,
            volume: item.volume,
            lengthUnit: item.lengthUnit,
            weightUnit: item.weightUnit,
            quantity: item.quantity,
            packageSpecialServices: item.packageSpecialServices
        }));

        const updatedFurnitureList = movingFurnitureList?.map(item => ({
            description: item.category,
            cateId: item.cate_id,
            length: item.length,
            width: item.width,
            height: item.height,
            weight: item.weight,
            volume: item.volume,
            lengthUnit: item?.lengthUnit,
            weightUnit: item?.weightUnit,
            quantity: item.quantity,
            packageSpecialServices: item.packageSpecialServices
        }));

        // console.log(updatedItemList);
        // const items = [...updatedItemList, ...updatedFurnitureList];

        const specialServices = Object.keys(additionalService)
            .filter(key => typeof additionalService[key] === 'boolean' && additionalService[key])
            .map(serviceName => ({serviceName}));

        const data = {
            items: updatedFurnitureList,
            boxList: updatedItemList,
            pickupWarehouseId: movingFromAddress?.warehouse?.id || 0,
            selfDropOff: movingFromAddress?.option === 'self',
            pickupAddress: movingFromAddress?.streetAddress,
            pickupCity: movingFromAddress?.city,
            pickupProvince: movingFromAddress?.province?.code,
            pickupCountry: movingFromAddress?.country,
            pickupPostalCode: movingFromAddress?.postalCode,
            deliverWarehouseId: movingToAddress?.warehouse?.id || 0,
            selfPickup: movingToAddress?.option === 'self',
            deliverAddress: movingToAddress?.streetAddress,
            deliverCity: movingToAddress?.city,
            deliverProvince: movingToAddress?.province?.code,
            deliverCountry: movingToAddress?.country,
            deliverPostalCode: movingToAddress?.postalCode,
            specialServices: specialServices,
            pickupHouseFeatures: {
                houseType: movingFromAddress?.propertyType?.houseType === 'Apartment' ? 'Condo' : movingFromAddress?.propertyType?.houseType,
                floorLevel: movingFromAddress?.floorLevel,
                staircaseNumber: "0",
                isNarrowStreet: movingFromAddress?.isNarrowStreet,
                drivewaySteepLevel: movingFromAddress?.drivewaySteepLevel,
                hasLift: !!movingFromAddress?.lift
            },
            deliverHouseFeatures: {
                houseType: movingToAddress?.propertyType?.houseType === 'Apartment' ? 'Condo' : movingToAddress?.propertyType?.houseType,
                floorLevel: movingToAddress?.floorLevel,
                staircaseNumber: "0",
                isNarrowStreet: movingToAddress?.isNarrowStreet,
                drivewaySteepLevel: movingToAddress?.drivewaySteepLevel,
                hasLift: !!movingToAddress?.lift
            }
        }

        console.log('[handleGetRating] data', data);

        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                data: data
            })
            // console.log(result.data);
            // setCategoryList(result.data);
            console.log('[handleGetRating] rating result', result);
            setErrorMessage('');
            // handleSuccessMessage();
            // setTimeout(() => {
            //     history.push(`/transportation/${tab}/rate`);
            // }, 1000);
            dispatch(updateMovingServiceBasicRate(result?.data));
        } catch (e) {
            console.log(e.response);
            // console.log('error code', errorCode);
            // handleErrorMessage();
        } finally {
            setLoading(false);
            history.push(`${ownerRole === 1 ? '/' : '/business/'}transportation/${tab}/review`);
            window.scrollTo(0, 0); // Scroll to the top
        }
    }

    const handleGetVehicleRating = async () => {
        try {
            setLoading(true);

            let requestURL = startsWithBusiness ? `${PARTNER_URI}/movingCar/getMovingCarRatingList` : `${NODE_ROUTE_URI}/movingCar/getMovingCarRatingList`;

            console.log('[handleGetVehicleRating] carList', carList);

            const updatedCarList = carList?.map(car => ({
                make: car.make,
                model: car.model,
                yearOfProduction: dayjs(car.year)?.format('YYYY'),
                categoryValue: `CAR-SIZE-${car?.size}`,
                quantity: car.quantity
            }));

            console.log('[handleGetVehicleRating] updatedCarList', updatedCarList);

            const data = {
                shipFromAddress: movingFromAddress?.option === 'self' ? movingFromAddress?.warehouse?.address : movingFromAddress?.streetAddress,
                shipFromCity: movingFromAddress?.option === 'self' ? movingFromAddress?.warehouse?.city : movingFromAddress?.city,
                shipFromProvince: movingFromAddress?.option === 'self' ? movingFromAddress?.warehouse?.province : movingFromAddress?.province?.code,
                shipFromCountry: movingFromAddress?.option === 'self' ? movingFromAddress?.warehouse?.country : movingFromAddress?.country,
                shipFromPostalCode: movingFromAddress?.option === 'self' ? movingFromAddress?.warehouse?.postal_code : movingFromAddress?.postalCode,
                shipToAddress: movingToAddress?.option === 'self' ? movingToAddress?.warehouse?.address : movingToAddress?.streetAddress,
                shipToCity: movingToAddress?.option === 'self' ? movingToAddress?.warehouse?.city : movingToAddress?.city,
                shipToProvince: movingToAddress?.option === 'self' ? movingToAddress?.warehouse?.province : movingToAddress?.province?.code,
                shipToCountry: movingToAddress?.option === 'self' ? movingToAddress?.warehouse?.country : movingToAddress?.country,
                shipToPostalCode: movingToAddress?.option === 'self' ? movingToAddress?.warehouse?.postal_code : movingToAddress?.postalCode,
                packageData: updatedCarList
            }

            // console.log('[handleGetVehicleRating] data', data);

            const result = await axios({
                method: 'post',
                url: requestURL,
                headers: {Authorization: `Bearer ${storedToken}`},
                data: data
            })

            console.log('[handleGetVehicleRating] rating result', result);
            // setErrorMessage('');

            dispatch(updateMovingServiceBasicRate(result?.data));
        } catch (e) {
            console.log(e.response);

        } finally {
            setLoading(false);
            history.push(`${ownerRole === 1 ? '/' : '/business/'}transportation/${tab}/review`);
            window.scrollTo(0, 0); // Scroll to the top
        }
    }

    const handleBack = () => {
        history.push(`${ownerRole === 1 ? '/' : '/business/'}transportation/${tab}/list`);
        window.scrollTo(0, 0); // Scroll to the top
    }

    const handleNext = async () => {
        if (validation()) {
            if (tab === 'vehicle') {
                await handleGetVehicleRating();
                console.log('call vehicle api')
            } else {
                await handleGetRating();
                console.log('call api')
            }
        }
    }

    // console.log('[MovingServiceOther] item list', itemList);
    console.log('[MovingServiceOther] car list', carList);
    // console.log('[MovingServiceOther] movingFurnitureList', movingFurnitureList);
    console.log('[MovingServiceOther] movingFromAddress', movingFromAddress);
    console.log('[MovingServiceOther] movingToAddress', movingToAddress);
    // console.log('[MovingServiceOther] additionalService', additionalService);
    // console.log('[MovingServiceOther] moving date', dayjs(moveDate).format('YYYY-MM-DD'));
    // console.log('[MovingServiceOther] moving time', moveTime);

    return (
        <Container maxWidth='xl'>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Box sx={styles.MovingServiceSummaryRoot}>
                <Box display="flex" alignItems="center" p={2}>
                    <img src={MovingItemTitle} alt="Moving items"
                         style={{width: '150px', height: 'auto', marginRight: '16px'}}/>
                    <Box>
                        <Typography variant="h5" component="div" fontWeight="bold">
                            {tab === 'item' ? 'Local & Long-distance Moving' : tab === 'express' ? 'Box Moving Service' : 'Vehicle Moving Service'}
                        </Typography>
                        <Typography variant="body1">
                            {tab === 'item' ? "Move your residence locally or long-distance, whether it's a small apartment or a large house." : "Limited to Greater Vancouver, Toronto, Calgary, and Montreal Areas"}
                        < /Typography>
                    </Box>
                </Box>
                <MovingServiceProgressBar progress={75}/>
                <Grid container spacing={2}>
                    <Grid item md={12} xs={12}>
                        <Box sx={styles.MovingServiceSummaryRoot}>
                            <MovingServiceContact contactValidate={contactValidate}/>
                        </Box>
                    </Grid>
                    <Grid item md={12} xs={12}>
                        <Box sx={styles.MovingServiceSummaryRoot}>
                            <MovingServiceDate tab={tab}/>
                        </Box>
                    </Grid>
                    <Grid item md={12} xs={12}>
                        <Box sx={styles.MovingServiceSummaryButtonSection}>
                            <Button
                                variant="contained"
                                sx={{
                                    backgroundColor: '#F2BE22',
                                    "&:hover": {
                                        backgroundColor: '#F2BE22',
                                        filter: 'brightness(0.9)'
                                    }
                                }}
                                onClick={handleBack}
                            >
                                <Typography style={{textTransform: 'none'}}>
                                    {intl.get('BUTTON.BACK')}
                                </Typography>
                            </Button>
                            <LoadingButton
                                loading={loading}
                                variant="contained"
                                sx={{
                                    backgroundColor: '#1D8B45',
                                    "&:hover": {
                                        backgroundColor: '#1D8B45',
                                        filter: 'brightness(0.9)'
                                    }
                                }}
                                onClick={handleNext}
                            >
                                <Typography style={{textTransform: 'none'}}>
                                    {intl.get('BUTTON.NEXT')}
                                </Typography>
                            </LoadingButton>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    )
}