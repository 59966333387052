import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import intl from "react-intl-universal";
import {Divider, useMediaQuery} from "@mui/material";
import {MovingVehicleOrderEach} from "./MovingVehicleOrderEach";

export const MovingVehicleOrderTable = ({orderData, startsWithBusiness}) => {

    const isMobile = useMediaQuery('(max-width: 480px)');

    return (
        <Grid container spacing={1}>
            <Grid item md={1.5} xs={5}>
                <Typography sx={{fontSize: '14px', fontWeight: '600', textAlign: 'center'}}>
                    Order
                </Typography>
            </Grid>
            {!isMobile &&
                <Grid item md={2.25}>
                    <Typography sx={{fontSize: '14px', fontWeight: '600', textAlign: 'left'}}>
                        Move From
                    </Typography>
                </Grid>
            }
            {!isMobile &&
                <Grid item md={2.25}>
                    <Typography sx={{fontSize: '14px', fontWeight: '600', textAlign: 'left'}}>
                        Move To
                    </Typography>
                </Grid>
            }
            {!isMobile &&
                <Grid item md={2}>
                    <Typography sx={{fontSize: '14px', fontWeight: '600', textAlign: 'left'}}>
                        Contact
                    </Typography>
                </Grid>
            }
            {!isMobile &&
                <Grid item md={1}>
                    <Typography sx={{fontSize: '14px', fontWeight: '600', textAlign: 'center'}}>
                        Vehicle
                    </Typography>
                </Grid>
            }
            <Grid item md={1.5} xs={3.5}>
                <Typography sx={{fontSize: '14px', fontWeight: '600', textAlign: 'center'}}>
                    Charge
                </Typography>
            </Grid>
            <Grid item md={1.5} xs={3.5}>
                <Typography sx={{fontSize: '14px', fontWeight: '600', textAlign: 'center'}}>
                    Status
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Divider/>
            </Grid>
            {
                orderData?.map((order, index) => (
                    <Grid item xs={12} key={index}>
                        <MovingVehicleOrderEach order={order} startsWithBusiness={startsWithBusiness}/>
                    </Grid>
                ))
            }
        </Grid>
    )
}