import {useHistory, useLocation, useParams} from "react-router-dom";
import {Button, Container} from "@mui/material";
import {useEffect, useState} from "react";
import {PARTNER_URI} from "../../Utils/apiUrl";
import axios from "axios";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import {greenButtonContained, sectionHeading, subHeading} from "../../Utils/styleConfig";
import Box from "@mui/material/Box";
import * as React from "react";
import {getAccessToken} from "../../Utils/doToken";
import {useSelector} from "react-redux";

export const MarketplaceTemp = () => {

    const storedToken = getAccessToken("access_token");
    const ownerRole = useSelector(state => state?.user?.ownerRole);

    const history = useHistory();
    const {search} = useLocation();
    const {platform} = useParams();
    const {pathname} = useLocation();
    const domainWithPort = window.location.host;

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [delay] = useState(2000);
    const [userProfile, setUserProfile] = useState(null);

    // Parse query parameters
    const queryParams = new URLSearchParams(search);

    const token = queryParams.get("token");
    const success = queryParams.get("success");
    const userId = queryParams.get("user_id");

    const generateMarketplaceAccessRequestURL = async () => {
        try {

            setLoading(true);

            let requestURL = `${PARTNER_URI}/marketplace/generateMarketplaceAccessRequestURL`;

            let data = {
                marketplace: platform,
                token: token
            }

            const result = await axios({
                method: 'post',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${storedToken}`
                },
                data: data
            })

            console.log('[generateMarketplaceAccessRequestURL] result', result)
            setError('');
            window.location.href = result?.data?.result;
        } catch (e) {
            console.log('[generateMarketplaceAccessRequestURL] e response', e?.response);
            setError('Unable to connect to the store. Please try again later.');
        } finally {
            setLoading(false)
        }
    }

    const verifyMarketplaceProfileUserToken = async () => {
        try {

            setLoading(true);

            let requestURL = `${PARTNER_URI}/marketplace/verifyMarketplaceProfileUserToken`;

            let data = {
                token: userId
                // token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJtYXJrZXRwbGFjZSI6Indvb2NvbW1lcmNlIiwidXNlcklkIjoxMTMsIm93bmVyUm9sZSI6Mywic3RvcmVVUkwiOiJodHRwOi8vMzQuMTMwLjk0LjI0Mjo5MDgwIiwiaWF0IjoxNzM5NDc1OTYzLCJleHAiOjE3NDIwNjc5NjN9.cfiLtyP8vaaOS6ePDB-czoAzTlAUwKtaSSgSpRsXnal"
            }

            const result = await axios({
                method: 'post',
                url: requestURL,
                data: data
            })

            console.log('[verifyMarketplaceProfileUserToken] result', result);
            if (result?.data?.result === null) {
                console.log('null')
                setUserProfile(null);
            } else {
                console.log('redirect')
                history.push(`/marketplace/woocommerce/auth`);
            }
        } catch (e) {
            console.log('[verifyMarketplaceProfileUserToken] e response', e?.response);
            setError(e?.response);
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {

        if (ownerRole === 1) {
            setError("We are unable to connect to the store at the moment because the logged-in account is an individual account, not a company account.");
        } else {
            if (platform === 'wix') {
                generateMarketplaceAccessRequestURL();
            }
        }
    }, [token, ownerRole]);

    useEffect(() => {
        if (ownerRole === 1) {
            setError("We are unable to connect to the store at the moment because the logged-in account is an individual account, not a company account.");
        } else {
            if (platform === 'woocommerce') {
                if (success === "1" && userId) {

                    const interval = setInterval(() => {
                        console.log('call api');
                        verifyMarketplaceProfileUserToken();
                    }, 2000)

                    const timeout = setTimeout(() => {
                        clearInterval(interval);
                        // console.log('[user profile] - use profile', userProfile);
                        if (!userProfile) {
                            setError('We are unable to connect to the store at the moment. Please try again later.');
                        }
                    }, 10000);

                    return () => {
                        clearInterval(interval);
                        clearTimeout(timeout);
                    }
                } else if (success === "0") {
                    setError('We are unable to connect to the store at the moment. Please try again later.');
                }
            }
        }
    }, [success, userId, ownerRole])

    // console.log('[MarketplaceAuth] platform', platform);
    // console.log('[MarketplaceAuth] pathname', pathname);
    // console.log('[MarketplaceAuth] domainWithPort', domainWithPort);
    // console.log('[MarketplaceAuth] search', search);
    // console.log('[MarketplaceAuth] token', token);
    // console.log('[MarketplaceAuth] success', success);
    // console.log('[MarketplaceAuth] userId', userId);

    if (error) {
        return (
            <Container maxWidth='xl'>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography sx={{fontSize: sectionHeading, fontWeight: '600', textAlign: 'center'}}>
                            Store Connection
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography sx={{textAlign: 'center', fontSize: subHeading}}>
                            {error}
                        </Typography>
                    </Grid>
                    {
                        +ownerRole === 3 &&
                        <Grid item xs={12}>
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'flex-end'
                            }}>
                                <Button
                                    variant='contained'
                                    sx={greenButtonContained}
                                    onClick={() => history.push('/business/e-commerce/store')}
                                >
                                    <Typography sx={{
                                        textTransform: 'none'
                                    }}>
                                        Store Connection
                                    </Typography>
                                </Button>
                            </Box>
                        </Grid>
                    }
                </Grid>
            </Container>
        )
    }

    return (
        <Container maxWidth='xl'>
            <CircularProgress/>
        </Container>
    )
}