import Typography from "@mui/material/Typography";
import {Fragment, useEffect, useState} from "react";
import {NODE_ROUTE_URI, PARTNER_URI} from "../../Utils/apiUrl";
import {getAccessToken} from "../../Utils/doToken";
import axios from "axios";
import Grid from "@mui/material/Grid";
import {Divider} from "@mui/material";
import Box from "@mui/material/Box";
import * as React from "react";
import {MarketplaceStoreEach} from "./MarketplaceStoreEach";
import CircularProgress from "@material-ui/core/CircularProgress";

export const MarketplaceStoreList = ({startsWithBusiness}) => {

    const [loading, setLoading] = useState(false);
    const [storeList, setStoreList] = useState([]);
    const [platform, setPlatform] = useState('all');
    const [error, setError] = useState(false);

    const getMarketplaceStoreList = async () => {
        try {

            setLoading(true);
            const storedToken = getAccessToken("access_token");

            let requestURL = startsWithBusiness ? `${PARTNER_URI}/marketplace/getMarketplaceProfileList` : `${NODE_ROUTE_URI}/marketplace/getMarketplaceProfileList`;

            const result = await axios({
                method: 'get',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${storedToken}`
                },
                params: {
                    marketplace: platform === 'all' ? null : platform
                }
            })
            console.log('[getMarketplaceStoreList] result', result);

            setStoreList(result?.data);
            setError(false)
        } catch (e) {
            console.log('[getMarketplaceStoreList] error', e?.response);

            setError(true);

        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        (async () => {
            await getMarketplaceStoreList();
        })()
    }, []);

    if (loading) {
        return (
            <Box sx={{display: 'flex', justifyContent: 'center', padding: '30px'}}>
                <CircularProgress/>
            </Box>
        )
    }

    if (error && !loading) {
        return <Box sx={{maxWidth: '450px', padding: '15px 30px 15px',}}>
            <Typography sx={{fontWeight: '600'}}>
                Sorry We currently encounter some issues, please try again later.
            </Typography>
        </Box>
    }

    return (
        <Grid container spacing={2}>
            {
                storeList?.length === 0 ?
                    <>
                        <Grid item xs={12}>
                            <Typography>
                                No stores are connected yet. Please connect a store to get started.
                            </Typography>
                        </Grid>
                    </>
                    :
                    <>
                        <Grid item xs={2}>
                            <Typography sx={{textAlign: 'left', fontWeight: '600'}}>
                                Platform
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography sx={{textAlign: 'left', fontWeight: '600'}}>
                                Store Name
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography sx={{textAlign: 'left', fontWeight: '600'}}>
                                Store Location
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography sx={{textAlign: 'left', fontWeight: '600'}}>
                                Action
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider/>
                        </Grid>
                        {
                            storeList?.map((store, index) => (
                                <Fragment key={index}>
                                    <MarketplaceStoreEach store={store} startsWithBusiness={startsWithBusiness}/>
                                </Fragment>
                            ))
                        }
                    </>
            }
        </Grid>
    )
}