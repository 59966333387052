import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {getAccessToken} from "../../Utils/doToken";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {useState, Fragment, useEffect} from "react";
import * as React from "react";
import MuiAlert from "@mui/material/Alert";
import {Button, Container, Snackbar, Step, StepButton, StepLabel, Stepper} from "@mui/material";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import {costWithCanadianCurrencyCode, isValidatePhone, mapCarrierLogo} from "../../Utils/Helper";
import AccordionSummary from "@mui/material/AccordionSummary";
import Accordion from "@mui/material/Accordion";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LoadingButton from "@mui/lab/LoadingButton";
import AccordionDetails from "@mui/material/AccordionDetails";
import Divider from "@mui/material/Divider";
import {NODE_ROUTE_URI} from "../../Utils/apiUrl";
import axios from "axios";
import {
    clearCreateLabelShippingPackagePackageList,
    clearCreateLabelShippingPackageRateList,
    clearQuickQuoteShippingPackagePackageList,
    clearShippingPackageCrossBorderItemList, selectCreateLabelShippingPackageRate,
    updateCreateLabelShippingPackageShipFrom,
    updateCreateLabelShippingPackageShipTo,
    updateShippingPackageInternationalShipmentShipFrom,
    updateShippingPackageInternationalShipmentShipTo,
    updateShippingPackageReturnLabelStatus,
    updateShippingPackageSchedulePickupInformation,
    updateShippingPackageSchedulePickupRate
} from "../../actions/shippingPackageAction";
import {InternationalSteps, QontoConnector, QontoStepIcon, steps} from "../../Utils/CustomComponent";
import {ReactComponent as SchedulePickupIcon} from "../../images/NewDesignIcon/SchedulePickup.svg";
import {ReactComponent as ShippingFromAndToIcon} from "../../images/NewDesignIcon/ShippingLocation.svg";
import {ReactComponent as PackageDetailIcon} from "../../images/NewDesignIcon/Package.svg";
import {ReactComponent as PackageTypeIcon} from "../../images/NewDesignIcon/AdditionalDetails.svg";
import {ReactComponent as RateDetailsIcon} from "../../images/NewDesignIcon/RateDetails.svg";
import {ReactComponent as ShipmentOverviewIcon} from "../../images/NewDesignIcon/ShipmentOverview.svg";
import dayjs from "dayjs";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {defaultText, light_orange, orange} from "../../Utils/styleConfig";
import WarningIcon from "@mui/icons-material/Warning";
import {
    updateLandingClickEstimate,
    updateLandingEstimateCostLoading,
    updateLandingEstimateCrossBorderCost
} from "../../actions/landingEstimateAction";
import intl from "react-intl-universal";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const styles = {
    ShippingPackageCreateLabelOrderDetailsRoot: {
        backgroundColor: '#FFFFFF',
        paddingBottom: '20px',
        marginBottom: '40px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        gap: '20px',
        display: 'flex',
        flexDirection: 'column',
        margin: '20px 0'
    },
    ShippingPackageCreateLabelRateListContent: {
        backgroundColor: '#FFFFFF',
        padding: '0 40px',
        marginBottom: '40px',
        borderRadius: '10px',
        gap: '20px',
        display: 'flex',
        flexDirection: 'column',
    },
    ShippingPackageCreateLabelOrderDetailsHeading: {
        fontSize: '20px',
        fontWeight: '600',
        textAlign: 'left'
    },
    ShippingPackageCreateLabelOrderDetailsSubHeading: {
        fontSize: '18px',
        fontWeight: '600',
        textAlign: 'left'
    },
    ShippingPackageCreateLabelOrderDetailsTitle: {
        fontSize: '16px',
        textAlign: 'center'
    },
    ShippingPackageCreateLabelOrderDetailsTextLeft: {
        fontSize: '16px',
        textAlign: 'left'
    },
    ShippingPackageCreateLabelOrderDetailsTextRight: {
        fontSize: '16px',
        textAlign: 'right'
    },
    ShippingPackageCreateLabelOrderDetailsTitleRight: {
        fontSize: '18px',
        textAlign: 'right',
        fontWeight: '600'
    },
    ShippingPackageCreateLabelOrderDetailsSection: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '40px',
        borderRadius: '10px',
        // boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        border: '1px solid #A9A9A9',
        gap: '20px',
        display: 'flex',
        flexDirection: 'column',
    },
    ShippingPackageCreateLabelOrderDetailsSectionPrice: {
        // backgroundColor: '#FFFFFF',
        padding: '20px',
        // borderRadius: '10px 10px 0 0',
        // border: '2px solid #609966',
        gap: '20px',
        display: 'flex',
        flexDirection: 'column',
    },
    ShippingPackageCreateLabelOrderDetailsOtherBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        height: '100%'
    },
    ShippingPackageCreateLabelOrderDetailsOtherBoxRight: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        height: '100%'
    },
    ShippingPackageCreateLabelOrderDetailsOtherBoxCenter: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%'
    },
    ShippingPackageCreateLabelOrderDetailsImageBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        paddingLeft: '20px',
        gap: '10px'
    },
}

export const ShippingPackageCreateLabelOrderDetails = () => {
    const dispatch = useDispatch();
    const token = getAccessToken("access_token");
    const isMobile = useMediaQuery('(max-width: 600px)');
    const history = useHistory();

    let shipmentPurpose = useSelector(state => state?.shippingPackage?.shipmentPurpose);
    let declarationDutyFreeEntry = useSelector(state => state?.shippingPackage?.declarationDutyFreeEntry);
    let pickupInformation = useSelector(state => state?.shippingPackage?.createLabelPickupInformation);
    let returnLabel = useSelector(state => state?.shippingPackage?.returnLabel);
    let signatureOptions = useSelector(state => state?.shippingPackage?.createLabelSignatureOptions);
    let batteryDetails = useSelector(state => state?.shippingPackage?.createLabelBatteryDetails);
    let selectRate = useSelector(state => state?.shippingPackage?.createLabelSelectedRate);
    let packageListRedux = useSelector(state => state?.shippingPackage?.createLabelPackageList);
    let shipFrom = useSelector(state => state?.shippingPackage?.createLabelShipFrom);
    let shipTo = useSelector(state => state?.shippingPackage?.createLabelShipTo);
    let referenceOrderNumber = useSelector(state => state?.shippingPackage?.createLabelReferenceOrderNumber);
    let crossBorder = useSelector(state => state?.shippingPackage?.createLabelCrossBorder);
    let crossBorderRate = useSelector(state => state?.shippingPackage?.crossBorderRate);
    let crossBorderItemListRedux = useSelector(state => state?.shippingPackage?.crossBorderItemList);
    let pickupRateRedux = useSelector(state => state?.shippingPackage?.createLabelPickupRate);
    const userInfo = useSelector((state) => state.user);

    const [loadingPlaceOrder, setLoadingPlaceOrder] = useState(false);
    const [loadingOneClick, setLoadingOneClick] = useState(false);

    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [confirmationProceed, setConfirmationProceed] = useState(false);
    const [dutyAndTax, setDutyAndTax] = useState(0);

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const getRatingByDutyAndTax = async () => {
        let requestURL = `${NODE_ROUTE_URI}/brokerage/admin/landedCostByServiceName`;

        let data = {
            serviceName: "ups",
            importCountryCode: shipTo?.country,
            importProvince: shipTo?.province?.code,
            exportCountryCode: shipFrom?.country,
            transModes: crossBorder?.tranMode,
            shipmentType: crossBorder?.shipmentType,
            shipmentItems: crossBorderItemListRedux
        }

        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                data: data
            })
            console.log('[endUserGetRatingByDutyAndTax] result', result.data);
            setDutyAndTax(result?.data);
        } catch (e) {
            console.log(e.response);
        }
    }

    const initBackendOrder = async () => {
        setLoadingPlaceOrder(true);
        let requestURL = `${NODE_ROUTE_URI}/looseItem/endUser/init3PLOrder`;

        let packageData = packageListRedux.map(element => {
            const updatedElement = {...element};
            updatedElement.packageSpecialServices = {
                ...updatedElement.packageSpecialServices,
                batteryDetails: batteryDetails,
            };
            return updatedElement;
        });

        packageData = packageData.map(element => {
            const updatedElement = {...element};
            updatedElement.packageSpecialServices = {
                ...updatedElement.packageSpecialServices,
                signatureRequired: signatureOptions
            }
            return updatedElement;
        })

        const purpose = `${+shipmentPurpose === 1 ? 'Commercial' : 'Personal'}`
        const form = `${(+shipmentPurpose === 1 && declarationDutyFreeEntry) ? 'NAFTA' : (+shipmentPurpose === 2 && declarationDutyFreeEntry) ? "Form 3299" : 'N/A'}`

        const data = {
            accountBaseCombinedName: selectRate?.accountBaseCombineName,
            serviceType: selectRate?.serviceType,
            shipFromName: shipFrom?.name,
            shipFromCompanyName: shipFrom?.company || shipFrom?.name,
            shipFromPhone: shipFrom?.phone?.replace(/\D/g, ''),
            shipFromPhoneExtension: shipFrom?.phoneExt?.replace(/\D/g, ''),
            shipFromEmail: shipFrom?.email,
            shipFromAddress: shipFrom?.displayAddress?.text,
            shipFromAddressTwo: shipFrom?.address2,
            shipFromCity: shipFrom?.city,
            shipFromProvince: shipFrom?.province?.code,
            shipFromCountry: shipFrom?.country,
            shipFromPostalCode: shipFrom?.postalCode.replace(/\s+/g, ''),
            shipToName: shipTo?.name,
            shipToCompanyName: shipTo?.company || shipTo?.name,
            shipToPhone: shipTo?.phone?.replace(/\D/g, ''),
            shipToPhoneExtension: shipTo?.phoneExt?.replace(/\D/g, ''),
            shipToEmail: shipTo?.email,
            shipToAddress: shipTo?.displayAddress?.text,
            shipToAddressTwo: shipTo?.address2,
            shipToCity: shipTo?.city,
            shipToProvince: shipTo?.province?.code,
            shipToCountry: shipTo?.country,
            shipToPostalCode: shipTo?.postalCode.replace(/\s+/g, ''),
            cargoControlNumber: "",
            shipmentSpecialServices: {
                returnShipment: returnLabel
            },
            refOrderNumber: referenceOrderNumber,
            shipmentType: shipFrom?.country === shipTo?.country ? null : crossBorder?.shipmentType,
            transMode: shipFrom?.country === shipTo?.country ? null : crossBorder?.tranMode,
            packageData: packageData,
            packingList: crossBorderItemListRedux,
            declarationDutyFreeEntry: declarationDutyFreeEntry,
            messages: `${purpose} ${form}`,
            notDeliveredOption: crossBorder?.option
        }

        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${token}`
                },
                data: data
            })
            console.log('api init backend order', result);
            // setErrorMessage('');
            // setSuccessMessage('Successfully place order');
            // handleSuccessMessage();
            dispatch(updateCreateLabelShippingPackageShipFrom({
                postalCode: null,
                province: null,
                city: null,
                address: null,
                address2: null,
                country: null,
                displayAddress: {
                    description: null,
                    placeId: null,
                    text: null
                },
                name: null,
                phone: null,
                email: null,
                company: null,
                saveAddress: false,
            }))
            dispatch(updateCreateLabelShippingPackageShipTo({
                postalCode: null,
                province: null,
                city: null,
                address: null,
                address2: null,
                country: null,
                displayAddress: {
                    description: null,
                    placeId: null,
                    text: null
                },
                name: null,
                phone: null,
                email: null,
                company: null,
                saveAddress: false,
            }))
            dispatch(clearCreateLabelShippingPackageRateList());
            dispatch(clearCreateLabelShippingPackagePackageList());
            dispatch(clearQuickQuoteShippingPackagePackageList());
            dispatch(updateShippingPackageInternationalShipmentShipFrom(null));
            dispatch(updateShippingPackageInternationalShipmentShipTo(null));
            dispatch(clearShippingPackageCrossBorderItemList());
            dispatch(updateShippingPackageReturnLabelStatus(false));
            const backendOrderId = result?.data?.id;
            return backendOrderId;
        } catch (e) {
            console.log(e.response);
            handleErrorMessage();
            setErrorMessage(`Fail to proceed.`);

            return -1;
        } finally {
            setLoadingPlaceOrder(false);
        }
    }

    const initPickup = async (backendOrderId) => {
        setLoadingPlaceOrder(true);
        let requestURL = `${NODE_ROUTE_URI}/packagesPickupAppointment/initPickupAppointment`;

        const pickupDate = dayjs(pickupInformation?.pickupDate).format("YYYYMMDD");
        const pickupReadyTime = dayjs(pickupInformation?.pickupReadyTime).format('HHmm');
        const pickupCloseTime = dayjs(pickupInformation?.pickupCloseTime).format('HHmm');

        let data = {
            pickupDateInfo: {
                pickupDate: pickupDate,
                readyTime: pickupReadyTime,
                closeTime: pickupCloseTime
            },
            pickupAddress: {
                address: pickupInformation?.address,
                addressTwo: pickupInformation?.address2,
                city: pickupInformation?.city,
                province: pickupInformation?.province?.code,
                postalCode: pickupInformation?.postalCode?.replace(/\s+/g, ''),
                country: pickupInformation?.country,
                isResidential: pickupInformation?.isResidential,
                pickupPoint: pickupInformation?.pickupPoint
            },
            contact: {
                name: pickupInformation?.name,
                companyName: pickupInformation?.name,
                phone: pickupInformation?.phone,
                email: pickupInformation?.email,
                phoneExtension: ''
            },
            accountBase: selectRate?.accountBase,
            accountNumber: selectRate?.accountNumber,
            backendOrderId: backendOrderId,
            remarkNotes: pickupInformation?.remarkNotes
        }

        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${token}`
                },
                data: data
            })

            console.log('[ShippingPackageCreateLabelOrderDetails] initPickup api result', result);

        } catch (e) {
            console.log('error', e);
            handleErrorMessage();
            setErrorMessage(`Fail to proceed schedule a pickup.`);
        } finally {
            setLoadingPlaceOrder(false);
            dispatch(updateShippingPackageSchedulePickupRate(null));
            dispatch(updateShippingPackageSchedulePickupInformation(null));
            history.push(`/shippingOrderDetails/${backendOrderId}`);
        }

    }

    const oneClickOrder = async () => {
        setLoadingOneClick(true);
        let requestURL = `${NODE_ROUTE_URI}/looseItem/init3PLOrderAndPayWithCreditCard`;

        let packageData = packageListRedux.map(element => {
            const updatedElement = {...element};
            updatedElement.packageSpecialServices = {
                ...updatedElement.packageSpecialServices,
                batteryDetails: batteryDetails,
            };
            return updatedElement;
        });

        packageData = packageData.map(element => {
            const updatedElement = {...element};
            updatedElement.packageSpecialServices = {
                ...updatedElement.packageSpecialServices,
                signatureRequired: signatureOptions
            }
            return updatedElement;
        })

        let pickupDetails = null;
        if (pickupInformation?.isPickup === 1) {
            const pickupDate = dayjs(pickupInformation?.pickupDate).format("YYYYMMDD");
            const pickupReadyTime = dayjs(pickupInformation?.pickupReadyTime).format('HHmm');
            const pickupCloseTime = dayjs(pickupInformation?.pickupCloseTime).format('HHmm');
            pickupDetails = {
                pickupDateInfo: {
                    pickupDate: pickupDate,
                    readyTime: pickupReadyTime,
                    closeTime: pickupCloseTime
                },
                pickupAddress: {
                    address: pickupInformation?.address,
                    addressTwo: pickupInformation?.address2,
                    city: pickupInformation?.city,
                    province: pickupInformation?.province?.code,
                    postalCode: pickupInformation?.postalCode?.replace(/\s+/g, ''),
                    country: pickupInformation?.country,
                    isResidential: pickupInformation?.isResidential,
                    pickupPoint: pickupInformation?.pickupPoint
                },
                contact: {
                    name: pickupInformation?.name,
                    companyName: pickupInformation?.name,
                    phone: pickupInformation?.phone,
                    email: pickupInformation?.email,
                    phoneExtension: ''
                },
                remarkNotes: pickupInformation?.remarkNotes
            }
        }

        const data = {
            accountBaseCombinedName: selectRate?.accountBaseCombineName,
            serviceType: selectRate?.serviceType,
            shipFromName: shipFrom?.name,
            shipFromCompanyName: shipFrom?.company || shipFrom?.name,
            shipFromPhone: shipFrom?.phone?.replace(/\D/g, ''),
            shipFromPhoneExtension: shipFrom?.phoneExt?.replace(/\D/g, ''),
            shipFromEmail: shipFrom?.email,
            shipFromAddress: shipFrom?.displayAddress?.text,
            shipFromAddressTwo: shipFrom?.address2,
            shipFromCity: shipFrom?.city,
            shipFromProvince: shipFrom?.province?.code,
            shipFromCountry: shipFrom?.country,
            shipFromPostalCode: shipFrom?.postalCode.replace(/\s+/g, ''),
            shipToName: shipTo?.name,
            shipToCompanyName: shipTo?.company || shipTo?.name,
            shipToPhone: shipTo?.phone?.replace(/\D/g, ''),
            shipToPhoneExtension: shipTo?.phoneExt?.replace(/\D/g, ''),
            shipToEmail: shipTo?.email,
            shipToAddress: shipTo?.displayAddress?.text,
            shipToAddressTwo: shipTo?.address2,
            shipToCity: shipTo?.city,
            shipToProvince: shipTo?.province?.code,
            shipToCountry: shipTo?.country,
            shipToPostalCode: shipTo?.postalCode.replace(/\s+/g, ''),
            cargoControlNumber: "",
            shipmentSpecialServices: {
                returnShipment: returnLabel
            },
            refOrderNumber: referenceOrderNumber,
            shipmentType: shipFrom?.country === shipTo?.country ? null : crossBorder?.shipmentType,
            transMode: shipFrom?.country === shipTo?.country ? null : crossBorder?.tranMode,
            packageData: packageData,
            packingList: crossBorderItemListRedux,
            declarationDutyFreeEntry: declarationDutyFreeEntry,
            pickupDetails: pickupDetails,
            notDeliveredOption: crossBorder?.option
        }

        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${token}`
                },
                data: data
            })
            console.log('api init backend order', result);
            dispatch(updateCreateLabelShippingPackageShipFrom({
                postalCode: null,
                province: null,
                city: null,
                address: null,
                address2: null,
                country: null,
                displayAddress: {
                    description: null,
                    placeId: null,
                    text: null
                },
                name: null,
                phone: null,
                email: null,
                company: null,
                saveAddress: false,
            }))
            dispatch(updateCreateLabelShippingPackageShipTo({
                postalCode: null,
                province: null,
                city: null,
                address: null,
                address2: null,
                country: null,
                displayAddress: {
                    description: null,
                    placeId: null,
                    text: null
                },
                name: null,
                phone: null,
                email: null,
                company: null,
                saveAddress: false,
            }))
            dispatch(clearCreateLabelShippingPackageRateList());
            dispatch(clearCreateLabelShippingPackagePackageList());
            dispatch(clearQuickQuoteShippingPackagePackageList());
            dispatch(updateShippingPackageInternationalShipmentShipFrom(null));
            dispatch(updateShippingPackageInternationalShipmentShipTo(null));
            dispatch(clearShippingPackageCrossBorderItemList());
            dispatch(updateShippingPackageReturnLabelStatus(false));
            // setTimeout(() => {
            //     history.push(`/shippingOrderDetails/${result?.data?.id}`);
            // }, 1000)
        } catch (e) {
            console.log(e.response);
            handleErrorMessage();
            setErrorMessage(`Fail to place order. ${e.response?.data?.message || 'Please try again later.'}`);
        } finally {
            setLoadingOneClick(false);
        }

    }

    const northAmericaShipment = () => {
        if ((shipFrom?.country?.toLowerCase() === 'ca' || shipFrom?.country?.toLowerCase() === 'us') && (shipTo?.country?.toLowerCase() === 'ca' || shipTo?.country?.toLowerCase() === 'us')) {
            return true
        } else {
            return false
        }
    }

    const handleBack = () => {
        // history.push('/shipping-package/create-label/rate-list');
        if (shipFrom?.country === shipTo?.country) {
            history.push('/shipping-package/create-label/rate-list');
        } else {
            history.push('/shipping-package/cross-border-details');
        }
    }

    const handleStep = (step) => () => {
        step === 0 ? history.push('/shipping-package/create-label') : step === 1 && history.push('/shipping-package/international-shipment/rate-list');
    };

    const handlePlaceOrder = async () => {
        const backendOrderId = await initBackendOrder();
        console.log('backendOrderId', backendOrderId);
        if (backendOrderId) {
            if (pickupInformation?.isPickup === 1) {
                await initPickup(backendOrderId);
            } else {
                dispatch(updateShippingPackageSchedulePickupRate(null));
                dispatch(updateShippingPackageSchedulePickupInformation(null));
                history.push(`/shippingOrderDetails/${backendOrderId}`);
            }
        }
    }

    const handleOneClickOrder = () => {
        oneClickOrder();
    }

    const handleBackToShippingInfo = () => {
        history?.push('/shipping-package/create-label');
    }

    const handleBackToChooseCarrier = () => {
        history.push('/shipping-package/create-label/rate-list');
    }

    const handleContinueProceed = () => {
        setConfirmationProceed(true);
    }

    const handleCancelProceed = () => {
        setConfirmationProceed(false);
    }

    const handleStartOver = () => {
        dispatch(selectCreateLabelShippingPackageRate(null));
        history.push('/shipping-package/create-label');
    }

    useEffect(() => {
        if ((shipFrom?.country !== shipTo?.country) && !declarationDutyFreeEntry) {
            getRatingByDutyAndTax();
        }
    }, [declarationDutyFreeEntry])

    // console.log('select rate', selectRate);
    // console.log('[ShippingPackageCreateLabelOrderDetails] package list', packageListRedux);
    // console.log('ship from', shipFrom);
    // console.log('user info', userInfo);
    // console.log('reference order number', referenceOrderNumber);
    // console.log('cross border', crossBorder);
    // console.log('[ShippingPackageCreateLabelOrderDetails] crossBorderRate', crossBorderRate);
    // console.log('[ShippingPackageCreateLabelOrderDetails] pickupRateRedux', pickupRateRedux);
    // console.log('[ShippingPackageCreateLabelOrderDetails] return label', returnLabel);
    // console.log('[ShippingPackageCreateLabelOrderDetails] signature option', signatureOptions);
    // console.log('[ShippingPackageCreateLabelOrderDetails] battery detail', batteryDetails);
    // console.log('[ShippingPackageCreateLabelOrderDetails] referenceOrderNumber', referenceOrderNumber);
    // console.log('[ShippingPackageCreateLabelOrderDetails] pickupInformation', pickupInformation);
    // console.log('[ShippingPackageCreateLabelOrderDetails] selectRate', selectRate);
    // console.log('[ShippingPackageCreateLabelOrderDetails] total', (+selectRate?.finalTotalAmount?.value + +pickupRateRedux?.totalChargeWithTaxes).toFixed(2));
    // console.log('[ShippingPackageCreateLabelOrderDetails] total charge with tax', (+pickupRateRedux?.totalChargeWithTaxes));
    console.log('[ShippingPackageCreateLabelOrderDetails] user information', userInfo);

    return (
        <Container maxWidth='xl'>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Stepper alternativeLabel activeStep={2}
                     connector={<QontoConnector/>}>
                {
                    (shipFrom?.country === shipTo?.country ? steps : InternationalSteps).map((label, index) => (
                        <Step key={label}>
                            {/*<StepButton onClick={handleStep(index)}>*/}
                            <StepLabel StepIconComponent={QontoStepIcon}>
                                <Typography sx={{color: index <= 2 ? '#1D8B45' : '#C7C7C7', fontSize: '14px'}}>
                                    {label}
                                </Typography>
                            </StepLabel>
                            {/*</StepButton>*/}
                        </Step>
                    ))
                }
            </Stepper>
            <Box sx={styles.ShippingPackageCreateLabelOrderDetailsRoot}>
                <Box sx={{
                    display: 'flex',
                    gap: '10px',
                    padding: '20px 20px 0 20px'
                }}>
                    <ShipmentOverviewIcon width={25} height={25}/>
                    <Typography style={styles.ShippingPackageCreateLabelOrderDetailsSubHeading}>
                        Shipment Overview
                    </Typography>
                </Box>
                <Divider/>
                <Box sx={{
                    backgroundColor: '#FFFFFF',
                    padding: '0 40px',
                    gap: '40px',
                    display: 'flex',
                }}>
                    <Grid container spacing={4}>
                        <Grid item xs={12} md={6}>
                            <Box sx={{display: 'flex', width: '100%', flexDirection: 'column', gap: '60px'}}>
                                <Grid container spacing={1}>
                                    <Grid item xs={1}>
                                        <ShippingFromAndToIcon width={20} height={20}/>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <Typography sx={{fontWeight: '600'}}>
                                            Shipping From
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Button onClick={handleBackToShippingInfo}
                                                sx={{padding: 0, justifyContent: 'right', width: '100%'}}>
                                            <Typography sx={{color: '#1D8B45', textTransform: 'none'}}>
                                                Edit
                                            </Typography>
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider/>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography style={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>
                                            {shipFrom?.name}
                                        </Typography>
                                        <Typography style={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>
                                            {shipFrom?.company}
                                        </Typography>
                                        <Typography style={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>
                                            {shipFrom?.address2 && `${shipFrom?.address2} - `}{shipFrom?.address}
                                        </Typography>
                                        <Typography style={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>
                                            {shipFrom?.city}, {shipFrom?.province?.code}, {shipFrom?.country}, {shipFrom?.postalCode}
                                        </Typography>
                                        {
                                            shipFrom?.email &&
                                            <Typography style={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>
                                                {shipFrom?.email}
                                            </Typography>
                                        }
                                        <Typography style={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>
                                            {shipFrom?.phone} {shipFrom?.phoneExt && `ext. ${shipFrom?.phoneExt}`}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={1}>
                                    <Grid item xs={1}>
                                        <ShippingFromAndToIcon width={20} height={20}/>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <Typography sx={{fontWeight: '600'}}>
                                            Shipping To
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Button onClick={handleBackToShippingInfo}
                                                sx={{padding: 0, justifyContent: 'right', width: '100%'}}>
                                            <Typography sx={{color: '#1D8B45', textTransform: 'none'}}>
                                                Edit
                                            </Typography>
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider/>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography style={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>
                                            {shipTo?.name}
                                        </Typography>
                                        <Typography style={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>
                                            {shipTo?.company}
                                        </Typography>
                                        <Typography style={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>
                                            {shipTo?.address2 && `${shipTo?.address2} - `}{shipTo?.address}
                                        </Typography>
                                        <Typography style={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>
                                            {shipTo?.city}, {shipTo?.province?.code}, {shipTo?.country}, {shipTo?.postalCode}
                                        </Typography>
                                        {
                                            shipTo?.email &&
                                            <Typography style={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>
                                                {shipTo?.email}
                                            </Typography>
                                        }
                                        <Typography style={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>
                                            {shipTo?.phone} {shipTo?.phoneExt && `ext. ${shipTo?.phoneExt}`}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={1}>
                                    <Grid item xs={1}>
                                        <PackageDetailIcon width={20} height={20}/>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <Typography sx={{fontWeight: '600'}}>
                                            Package Details
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Button onClick={handleBackToShippingInfo}
                                                sx={{padding: 0, justifyContent: 'right', width: '100%'}}>
                                            <Typography sx={{color: '#1D8B45', textTransform: 'none'}}>
                                                Edit
                                            </Typography>
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider/>
                                    </Grid>
                                    {
                                        packageListRedux.length > 0 && packageListRedux?.map((each, index) => (
                                            <Fragment key={index}>
                                                <Grid item xs={2}>
                                                    <Typography
                                                        style={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>
                                                        {`#${index + 1}`}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography
                                                        style={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>
                                                        {each?.length} x {each?.width} x {each?.height} {each?.lengthUnit}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <Typography
                                                        style={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>
                                                        {each?.weight} {each?.weightUnit}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <Typography
                                                        style={styles.ShippingPackageCreateLabelOrderDetailsTextRight}>
                                                        x {each?.quantity}
                                                    </Typography>
                                                </Grid>
                                            </Fragment>
                                        ))
                                    }
                                </Grid>
                                <Grid container spacing={1}>
                                    <Grid item xs={1}>
                                        <PackageTypeIcon width={20} height={20}/>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <Typography sx={{fontWeight: '600'}}>
                                            Additional Details
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Button onClick={handleBackToShippingInfo}
                                                sx={{padding: 0, justifyContent: 'right', width: '100%'}}>
                                            <Typography sx={{color: '#1D8B45', textTransform: 'none'}}>
                                                Edit
                                            </Typography>
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider/>
                                    </Grid>
                                    {
                                        signatureOptions &&
                                        <Grid item xs={12}>
                                            <Typography>
                                                Include signature options
                                            </Typography>
                                        </Grid>
                                    }
                                    {
                                        batteryDetails?.material &&
                                        <Grid item xs={12}>
                                            <Typography>
                                                Include lithium batteries
                                            </Typography>
                                        </Grid>
                                    }
                                    {
                                        returnLabel &&
                                        <Grid item xs={12}>
                                            <Typography>
                                                Return Label
                                            </Typography>
                                        </Grid>
                                    }
                                    <Grid item xs={12}>
                                        <Typography>
                                            Reference Order Number: {referenceOrderNumber || 'N/A'}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Box sx={{display: 'flex', width: '100%', flexDirection: 'column', gap: '60px'}}>
                                <Grid container spacing={1}>
                                    <Grid item xs={1}>
                                        <SchedulePickupIcon width={20} height={20}/>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <Typography sx={{fontWeight: '600'}}>
                                            Schedule Pickup
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Button onClick={handleBackToChooseCarrier}
                                                sx={{padding: 0, justifyContent: 'right', width: '100%'}}>
                                            <Typography sx={{color: '#1D8B45', textTransform: 'none'}}>
                                                Edit
                                            </Typography>
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider/>
                                    </Grid>
                                    {
                                        pickupInformation?.isPickup === 1 ?
                                            <>
                                                <Grid item xs={12}>
                                                    <Typography>
                                                        <CheckCircleIcon sx={{color: '#1D8B45'}}/> Pickup Schedule
                                                        on {dayjs(pickupInformation?.pickupDate)?.format('MMMM D, YYYY')}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography>
                                                        {pickupInformation?.name}, {pickupInformation?.phone}, {pickupInformation?.pickupPoint},
                                                        From {dayjs(pickupInformation?.pickupReadyTime)?.format('h:mm A')} to {dayjs(pickupInformation?.pickupCloseTime)?.format('h:mm A')}
                                                    </Typography>
                                                </Grid>
                                            </>
                                            :
                                            pickupInformation?.isPickup === 2 ?
                                                <Grid item xs={12}>
                                                    <Typography>
                                                        Schedule pickup later
                                                    </Typography>
                                                </Grid>
                                                :
                                                <Grid item xs={12}>
                                                    <Typography>
                                                        Drop off the package
                                                    </Typography>
                                                </Grid>

                                    }
                                </Grid>
                                <Grid container spacing={1}>
                                    <Grid item xs={1}>
                                        <RateDetailsIcon width={20} height={20}/>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <Typography sx={{fontWeight: '600'}}>
                                            Rate Details
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Button onClick={handleBackToChooseCarrier}
                                                sx={{padding: 0, justifyContent: 'right', width: '100%'}}>
                                            <Typography sx={{color: '#1D8B45', textTransform: 'none'}}>
                                                Edit
                                            </Typography>
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider/>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <img
                                            src={mapCarrierLogo(selectRate?.accountBase)}
                                            alt="logo"
                                            width='60px'/>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={6}>
                                                <Typography>
                                                    {selectRate?.accountBase}
                                                </Typography>
                                                <Typography>
                                                    {selectRate?.shipRange} business day(s)
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography
                                                    style={styles.ShippingPackageCreateLabelOrderDetailsTitleRight}>
                                                    {
                                                        selectRate?.isCrossBoarder ?
                                                            costWithCanadianCurrencyCode(shipFrom?.country, shipTo?.country, (+selectRate?.finalTotalAmount?.value + (+crossBorderRate?.grandTotal || 0) + (+pickupRateRedux?.totalChargeWithTaxes || 0)))
                                                            :
                                                            costWithCanadianCurrencyCode(shipFrom?.country, shipTo?.country, (+selectRate?.finalTotalAmount?.value + (+pickupRateRedux?.totalChargeWithTaxes || 0)))
                                                    }
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Divider/>
                                            </Grid>
                                            {
                                                selectRate?.shipmentCharges?.map((each, index) => {
                                                    const cost = +each?.value
                                                    if (cost !== 0) {
                                                        return (
                                                            <Fragment key={index}>
                                                                <Grid item xs={6}>
                                                                    <Typography
                                                                        style={styles.ShippingPackageCreateLabelOrderDetailsTextRight}>
                                                                        {each?.description}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item xs={6}>
                                                                    <Typography
                                                                        style={styles.ShippingPackageCreateLabelOrderDetailsTextRight}>
                                                                        {costWithCanadianCurrencyCode(shipFrom?.country, shipTo?.country, +each?.value)}
                                                                    </Typography>
                                                                </Grid>
                                                            </Fragment>
                                                        )
                                                    }
                                                })
                                            }
                                            {
                                                +(pickupRateRedux?.totalCharge) > 0 &&
                                                <>
                                                    <Grid item xs={6}>
                                                        <Typography
                                                            style={styles.ShippingPackageCreateLabelOrderDetailsTextRight}>
                                                            Pickup Fee
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Typography
                                                            style={styles.ShippingPackageCreateLabelOrderDetailsTextRight}>
                                                            {costWithCanadianCurrencyCode(shipFrom?.country, shipTo?.country, +pickupRateRedux?.totalCharge)}
                                                        </Typography>
                                                    </Grid>
                                                </>
                                            }
                                            {
                                                +(selectRate?.serviceFee?.value) > 0 &&
                                                <>
                                                    <Grid item xs={6}>
                                                        <Typography
                                                            style={styles.ShippingPackageCreateLabelOrderDetailsTextRight}>
                                                            Service Fee
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Typography
                                                            style={styles.ShippingPackageCreateLabelOrderDetailsTextRight}>
                                                            {costWithCanadianCurrencyCode(shipFrom?.country, shipTo?.country, +selectRate?.serviceFee?.value)}
                                                        </Typography>
                                                    </Grid>
                                                </>
                                            }
                                            <Grid item xs={12}>
                                                <Divider/>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography
                                                    style={styles.ShippingPackageCreateLabelOrderDetailsTextRight}>
                                                    Subtotal
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography
                                                    style={styles.ShippingPackageCreateLabelOrderDetailsTextRight}>
                                                    {costWithCanadianCurrencyCode(shipFrom?.country, shipTo?.country, (+selectRate?.totalCharge?.value) + selectRate?.serviceFee?.value + (+pickupRateRedux?.totalCharge || 0))}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography
                                                    style={styles.ShippingPackageCreateLabelOrderDetailsTextRight}>
                                                    Tax
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography
                                                    style={styles.ShippingPackageCreateLabelOrderDetailsTextRight}>
                                                    {costWithCanadianCurrencyCode(shipFrom?.country, shipTo?.country, ((+pickupRateRedux?.taxCharge || 0) + (+selectRate?.serviceFeeTax?.value) + selectRate?.taxes?.reduce((total, item) => total + (+item?.value), 0)))}
                                                </Typography>
                                            </Grid>
                                            {
                                                selectRate?.isCrossBoarder &&
                                                <>
                                                    <Grid item xs={6}>
                                                        <Typography
                                                            style={styles.ShippingPackageCreateLabelOrderDetailsTextRight}>
                                                            Total Brokerage Fee
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Typography
                                                            style={styles.ShippingPackageCreateLabelOrderDetailsTextRight}>
                                                            {costWithCanadianCurrencyCode(shipFrom?.country, shipTo?.country, +crossBorderRate?.totalBrokerageFees)}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Typography
                                                            style={styles.ShippingPackageCreateLabelOrderDetailsTextRight}>
                                                            Total Duty and Tax
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Typography
                                                            style={styles.ShippingPackageCreateLabelOrderDetailsTextRight}>
                                                            {costWithCanadianCurrencyCode(shipFrom?.country, shipTo?.country, +crossBorderRate?.totalDutyandTax)}
                                                        </Typography>
                                                    </Grid>
                                                </>
                                            }
                                            <Grid item xs={12}>
                                                <Divider/>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography
                                                    style={styles.ShippingPackageCreateLabelOrderDetailsTitleRight}>
                                                    Total
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography
                                                    style={styles.ShippingPackageCreateLabelOrderDetailsTitleRight}>
                                                    {selectRate?.isCrossBoarder ?
                                                        costWithCanadianCurrencyCode(shipFrom?.country, shipTo?.country, (+selectRate?.finalTotalAmount?.value + (+crossBorderRate?.grandTotal || 0) + (+pickupRateRedux?.totalChargeWithTaxes || 0)))
                                                        :
                                                        costWithCanadianCurrencyCode(shipFrom?.country, shipTo?.country, (+selectRate?.finalTotalAmount?.value + (+pickupRateRedux?.totalChargeWithTaxes || 0)))
                                                    }
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    {
                                        +dutyAndTax?.totalDutyandTax > 0 &&
                                        <Grid item xs={12}>
                                            <Typography>
                                                The duties and taxes for this shipment are estimated at <span
                                                style={{fontWeight: '600'}}>$ {(+dutyAndTax?.totalDutyandTax)?.toFixed(2)}. </span>
                                                Final charges will be confirmed by the customs authorities in the
                                                destination country and may differ from the estimate.
                                            </Typography>
                                        </Grid>
                                    }
                                </Grid>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <Box sx={{
                marginBottom: '40px'
            }}>
                <Grid container spacing={2}>
                    {/*{*/}
                    {/*    selectRate?.accountBase?.toLowerCase() === 'canadapost' &&*/}
                    {/*    <Grid item xs={12}>*/}
                    {/*        <Box sx={{*/}
                    {/*            padding: '15px',*/}
                    {/*            border: '2px solid #FF8911',*/}
                    {/*            borderRadius: '5px',*/}
                    {/*            backgroundColor: light_orange,*/}
                    {/*            display: 'flex',*/}
                    {/*            gap: '10px'*/}
                    {/*        }}>*/}
                    {/*            <WarningIcon sx={{color: orange}}/>*/}
                    {/*            <Typography sx={{fontSize: defaultText, textAlign: 'left'}}>*/}
                    {/*                <span*/}
                    {/*                    style={{fontWeight: '600'}}>{intl.get('WARNING.BOND_TEXT')}</span> {intl.get('WARNING.CONTENT_TEXT')}*/}
                    {/*            </Typography>*/}
                    {/*        </Box>*/}
                    {/*    </Grid>*/}
                    {/*}*/}
                    {
                        confirmationProceed ?
                            <>
                                <Grid item xs={12}>
                                    <Typography>
                                        Would you like to start over? Please select 'Confirm' to begin again or 'Cancel'
                                        to
                                        continue with your current session.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box sx={{
                                        display: 'flex',
                                        width: '100%',
                                        justifyContent: 'flex-end',
                                        gap: '20px',
                                    }}>
                                        <Button
                                            size='large'
                                            variant='contained'
                                            onClick={handleCancelProceed}
                                            sx={{
                                                backgroundColor: '#F2BE22',
                                                "&:hover": {
                                                    backgroundColor: '#F2BE22',
                                                    filter: 'brightness(0.9)'
                                                }
                                            }}
                                        >
                                            <Typography sx={{textTransform: 'none'}}>
                                                Cancel
                                            </Typography>
                                        </Button>
                                        <LoadingButton
                                            size='large'
                                            variant='contained'
                                            color='success'
                                            loading={loadingPlaceOrder}
                                            onClick={handleStartOver}
                                        >
                                            <Typography sx={{textTransform: 'none'}}>
                                                Confirm
                                            </Typography>
                                        </LoadingButton>
                                    </Box>
                                </Grid>
                            </>
                            :
                            <>
                                <Grid item md={4}>
                                    <Box sx={{
                                        display: 'flex',
                                        width: '100%',
                                        justifyContent: 'flex-start',
                                        gap: '20px',
                                    }}>
                                        <Button
                                            size='large'
                                            variant='contained'
                                            onClick={handleContinueProceed}
                                            sx={{
                                                backgroundColor: '#454545',
                                                "&:hover": {
                                                    backgroundColor: '#454545',
                                                    filter: 'brightness(0.9)'
                                                }
                                            }}
                                        >
                                            <Typography sx={{textTransform: 'none'}}>
                                                Start Over
                                            </Typography>
                                        </Button>
                                    </Box>
                                </Grid>
                                <Grid item md={8}>
                                    <Box sx={{
                                        display: 'flex',
                                        width: '100%',
                                        justifyContent: 'flex-end',
                                        gap: '20px'
                                    }}>
                                        <Button
                                            size='large'
                                            variant='contained'
                                            onClick={handleBack}
                                            sx={{
                                                backgroundColor: '#F2BE22',
                                                "&:hover": {
                                                    backgroundColor: '#F2BE22',
                                                    filter: 'brightness(0.9)'
                                                }
                                            }}
                                        >
                                            <Typography sx={{textTransform: 'none'}}>
                                                Back
                                            </Typography>
                                        </Button>
                                        <LoadingButton
                                            size='large'
                                            variant='contained'
                                            color='success'
                                            loading={loadingPlaceOrder}
                                            onClick={handlePlaceOrder}
                                        >
                                            <Typography sx={{textTransform: 'none'}}>
                                                Check Out
                                            </Typography>
                                        </LoadingButton>
                                        {
                                            +userInfo?.oneClickStatus === 1 &&
                                            <LoadingButton
                                                size='large'
                                                variant='contained'
                                                color='success'
                                                loading={loadingOneClick}
                                                onClick={handleOneClickOrder}
                                            >
                                                <Typography sx={{textTransform: 'none'}}>
                                                    One Click Order
                                                </Typography>
                                            </LoadingButton>
                                        }
                                    </Box>
                                </Grid>
                            </>
                    }
                </Grid>
            </Box>
        </Container>

    )

}