import {useEffect, useState} from "react";
import {useLocation, useParams} from "react-router-dom";
import {getAccessToken} from "../../Utils/doToken";
import {NODE_ROUTE_URI, PARTNER_URI} from "../../Utils/apiUrl";
import axios from "axios";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import * as React from "react";
import {Container} from "@mui/material";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@mui/material/Grid";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import intl from "react-intl-universal";
import {MovingVehicleOrderDashboardSummary} from "./MovingVehicleOrderDashboardSummary";
import {MovingVehicleOrderDashboardTransportation} from "./MovingVehicleOrderDashboardTransportation";
import {MovingVehicleOrderDashboardPrice} from "./MovingVehicleOrderDashboardPrice";
import {MovingVehicleOrderDashboardContact} from "./MovingVehicleOrderDashboardContact";

export const MovingVehicleOrderDashboard = () => {

    const {pathname} = useLocation();
    const startsWithBusiness = pathname.startsWith('/business');
    const token = getAccessToken("access_token");
    const {orderId} = useParams();

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [orderData, setOrderData] = useState(null);

    const getMovingVehicleOrderDetails = async () => {
        try {
            setLoading(true);
            let requestURL = startsWithBusiness ? `${PARTNER_URI}/movingCar/getMovingCarOrderDetails` : `${NODE_ROUTE_URI}/movingCar/getMovingCarOrderDetails`;

            const result = await axios({
                method: 'get',
                url: requestURL,
                headers: {Authorization: `Bearer ${token}`},
                params: {
                    // queryString: searchString || "",
                    backendOrderId: orderId,
                }
            })
            console.log('[getMovingVehicleOrderDetails] - result', result);
            setOrderData(result?.data);
            setError(false);
        } catch (e) {
            setError(true);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (orderId) {
            (async () => {
                await getMovingVehicleOrderDetails();
            })()
        }
    }, [orderId])

    if (loading) {
        return (
            <Container maxWidth='xl'>
                <Box sx={{display: 'flex', justifyContent: 'center', padding: '30px'}}>
                    <CircularProgress/>
                </Box>
            </Container>
        )
    }

    if (error && !loading) {
        return (
            <Container maxWidth='xl'>
                <Box sx={{padding: '15px 30px 15px',}}>
                    <Typography sx={{fontWeight: '600', textAlign: 'center'}}>
                        Sorry We currently encounter some issues, please try again later.
                    </Typography>
                </Box>
            </Container>
        )
    }

    return (
        <Container maxWidth='xl'>
            <Box sx={{
                marginBottom: '40px'
            }}>
                <Grid container spacing={2} sx={{marginBottom: '40px'}}>
                    <Grid item md={8} xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <MovingVehicleOrderDashboardSummary orderData={orderData}/>
                            </Grid>
                            <Grid item xs={12}>
                                <MovingVehicleOrderDashboardTransportation orderData={orderData}/>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={4} xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <MovingVehicleOrderDashboardPrice orderData={orderData}/>
                            </Grid>
                            <Grid item xs={12}>
                                <MovingVehicleOrderDashboardContact orderData={orderData}/>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </Container>

    )
}