import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {Button, Link} from "@mui/material";
import {ReactComponent as CrossBorderLogo} from "../../images/crossborderIcon/CrossBorder.svg";
import React, {useEffect, useState} from "react";
import {CrossBorderDashboardInvoiceList} from "./CrossBorderDashboardInvoiceList";
import {useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import {getAccessToken} from "../../Utils/doToken";
import {FORM_3299, FORM_434, FORM_B232, FROM_3299_PDF, NODE_ROUTE_URI} from "../../Utils/apiUrl";
import axios from "axios";
import {CrossBorderDashboardItemList} from "./CrossBorderDashboardItemList";
import {generateRandomString} from "../../Utils/Helper";
import {defaultText, green} from "../../Utils/styleConfig";

export const CrossBorderDashboardInvoice = ({shippingListData, cbGetTrackingList, form, getDocumentList}) => {

    const token = getAccessToken("access_token");

    const {order_id} = useParams();
    const partnerInfo = useSelector((state) => state.user);
    const [itemList, setItemList] = useState([]);
    const [invoiceStatus, setInvoiceStatus] = useState(false);
    const [trackingList, setTrackingList] = useState([]);
    const [invoiceList, setInvoiceList] = useState([]);
    const [isCreate, setIsCreate] = useState(false);
    const [loadingGetTrackList, setLoadingGetTrackList] = useState(false);
    const [errorGetTrackList, setErrorGetTrackList] = useState(false);
    const [loadingGetItemList, setLoadingGetItemList] = useState(false);
    const [errorGetItemList, setErrorGetItemList] = useState(false);
    const [shipmentType, setShipmentType] = useState('');

    const openCreateInvoice = () => {
        setIsCreate(true);
    }

    const closeCreateInvoice = () => {
        setIsCreate(false);
    }

    const addInvoiceList = (invoice) => {
        console.log('invoice data', invoice);
        setInvoiceList(prevState => [...prevState, invoice]);
    }

    const removeInvoiceList = (invoice) => {
        const invoiceIdToFind = invoice?.invoiceId;
        const filterInvoiceList = invoiceList?.filter(each => each?.invoiceId !== invoiceIdToFind);
        setInvoiceList(filterInvoiceList);
    }

    const clearInvoiceList = () => {
        setInvoiceList([]);
    }

    const getTrackingList = async () => {
        setLoadingGetTrackList(true);
        let requestURI = `${NODE_ROUTE_URI}/commercialInvoice/getCrossBorderTrackingViewListByShippingOrderId`;
        try {
            const results = await axios({
                method: 'get',
                url: requestURI,
                headers: {
                    Authorization: `Bearer ${token}`
                },
                params: {
                    shippingOrderId: order_id
                }
            })
            const {data} = results;

            console.log('get tracking list', data);

            const allInvoiceStatus = data.every(element => element?.doc_status === 2);

            console.log('all invoice status', allInvoiceStatus);

            setInvoiceStatus(allInvoiceStatus);

            const updatedTrackingList = [];

            data.forEach(element => {
                if (element.doc_status !== 2) {
                    const randomId = generateRandomString(8);
                    let object = {
                        trackingId: randomId,
                        isChecked: true,
                        trackingNumber: element?.tracking_no,
                        tranId: element?.tran_id,
                        shippingIdNumber: element?.shipping_id_no,
                        description: element?.item_name,
                        unitPrice: element?.unit_price,
                        quantity: 1,
                        UOM: element?.uom,
                        weight: element?.weight,
                        hsCode: element?.hs_code,
                        originalCountry: element?.origi_country,
                        currency: element?.currency_code,
                        weightUnit: element?.weight_unit,
                        length: element?.length,
                        height: element?.height,
                        width: element?.width,
                        dimensionUnit: element?.dimension_unit
                    }
                    updatedTrackingList.push(object);
                }
            })

            console.log('update tracking list', updatedTrackingList);
            setTrackingList(updatedTrackingList);
            setLoadingGetTrackList(false);
            setErrorGetTrackList(false);
        } catch (e) {
            console.log(e);
            setLoadingGetTrackList(false);
            setErrorGetTrackList(true);
        }
    }

    const getItemList = async () => {
        setLoadingGetItemList(true);
        let requestURI = `${NODE_ROUTE_URI}/packingList/getPackingListAndPackingListDetailByShippingOrderIdAndUserId`;
        try {
            const results = await axios({
                method: 'get',
                url: requestURI,
                headers: {
                    Authorization: `Bearer ${token}`
                },
                params: {
                    shippingOrderId: order_id,
                    currency: 'CAD'
                }
            })

            const {data: {packingListDetails}} = results;
            const {data: {cross_border_shipment_type}} = results;
            console.log('get packing list', packingListDetails);

            const updatedPackingList = [];

            packingListDetails.forEach(element => {
                let object = {
                    packingId: element?.id,
                    isChecked: true,
                    description: element?.item_name,
                    unitPrice: element?.unit_price,
                    quantity: element?.quantity,
                    UOM: element?.uom,
                    weight: element?.weight,
                    hsCode: element?.hs_code,
                    originalCountry: element?.origi_country,
                    currency: element?.currency_code,
                    weightUnit: element?.weight_unit,
                }
                updatedPackingList.push(object);
            })
            setItemList(updatedPackingList);
            setShipmentType(cross_border_shipment_type);
            setLoadingGetItemList(false);
            setErrorGetItemList(false);
        } catch (e) {
            console.log(e);
            setLoadingGetItemList(false);
            setErrorGetItemList(true);
        }
    }

    useEffect(() => {
        getTrackingList();
        getItemList();
    }, []);

    console.log('item list', itemList);
    console.log('invoice list', invoiceList);

    return (
        <Box sx={{
            border: '1px solid #B2B2B2',
            borderRadius: '10px',
            padding: '20px'
        }}>
            <Grid container spacing={2}>
                <Grid item md={2} xs={12}>
                    <Box sx={{
                        display: 'flex',
                        height: '125px',
                        alignItems: 'center'
                    }}>
                        <CrossBorderLogo width="100%" height="100%"/>
                    </Box>
                </Grid>
                <Grid item md={7} xs={12}>
                    <Grid container spacing={1}>
                        <Grid item xs={1}>
                            <img src={'https://storage.googleapis.com/uucargo-resource/image/icon/HorizontalArrow.gif'}
                                 alt="horizontalArrow" width={40}/>
                        </Grid>
                        <Grid item xs={11}>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '10px'
                            }}>
                                <Typography sx={{fontSize: defaultText, fontWeight: '600'}}>
                                    Important Tips
                                </Typography>
                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}>
                                    <Box sx={{
                                        display: 'flex',
                                        gap: '5px'
                                    }}>
                                        <Typography style={{fontSize: '14px'}}>
                                            1.
                                        </Typography>
                                        <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                                            <span style={{fontWeight: '600'}}>Create or Upload Invoice:</span> Create an
                                            invoice using our system or upload your own commercial invoice in the
                                            "Package" Section.
                                        </Typography>
                                    </Box>
                                    <Box sx={{
                                        display: 'flex',
                                        gap: '5px'
                                    }}>
                                        <Typography style={{fontSize: '14px'}}>
                                            2.
                                        </Typography>
                                        <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                                            <span style={{fontWeight: '600'}}>Attach Invoice:</span> If you've created
                                            an invoice, click 'Upload Invoice' to attach it to the corresponding
                                            package.
                                        </Typography>
                                    </Box>
                                    <Box sx={{
                                        display: 'flex',
                                        gap: '5px'
                                    }}>
                                        <Typography style={{fontSize: '14px'}}>
                                            3.
                                        </Typography>
                                        <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                                            <span style={{fontWeight: '600'}}>Submit Invoice:</span> Ensure you've
                                            successfully submitted your invoice in the "Package" Section.
                                        </Typography>
                                    </Box>
                                    {
                                        form?.type !== 'N/A' &&
                                        <Box sx={{
                                            display: 'flex',
                                            gap: '5px'
                                        }}>
                                            <Typography style={{fontSize: '14px'}}>
                                                4.
                                            </Typography>
                                            {
                                                form?.purpose?.toUpperCase() === 'PERSONAL' ?
                                                    <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                                                        <span style={{fontWeight: '600'}}>Personal Items:</span> When
                                                        shipping personal items from Canada to the USA, you need to
                                                        attach the 3299 Form to your package. Additionally, include
                                                        packing list, passport, Homeland Security ID, and
                                                        visa. <span><Link
                                                        href={FROM_3299_PDF}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        sx={{
                                                            color: green,
                                                            textDecoration: 'none',
                                                            '&:hover': {
                                                                textDecoration: 'underline',
                                                                color: green
                                                            }
                                                        }}>Click the link</Link></span> to download the 3299 Form (last
                                                        updated on 05/07/2024). For further details and official
                                                        guidance, please visit the
                                                        <span> <Link href={FORM_3299}
                                                                     target="_blank"
                                                                     rel="noopener noreferrer"
                                                                     sx={{
                                                                         color: green,
                                                                         textDecoration: 'none',
                                                                         '&:hover': {
                                                                             textDecoration: 'underline',
                                                                             color: green
                                                                         }
                                                                     }}> official website.</Link></span>
                                                    </Typography>
                                                    :
                                                    <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                                                        <span style={{fontWeight: '600'}}>NAFTA:</span> If your shipment
                                                        falls under the provisions of the North American Free Trade
                                                        Agreement (NAFTA), be aware that it may qualify for certain
                                                        exemptions from duties or taxes. Ensure you include the
                                                        completed NAFTA form, a detailed packing list and certificate of
                                                        origin with your shipment. Make sure to review NAFTA guidelines
                                                        for eligibility and ensure compliance with both <span> <Link
                                                        href={FORM_B232}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        sx={{
                                                            color: green,
                                                            textDecoration: 'none',
                                                            '&:hover': {textDecoration: 'underline', color: green}
                                                        }}> Canada</Link></span> and <span> <Link href={FORM_434}
                                                                                                  target="_blank"
                                                                                                  rel="noopener noreferrer"
                                                                                                  sx={{
                                                                                                      color: green,
                                                                                                      textDecoration: 'none',
                                                                                                      '&:hover': {
                                                                                                          textDecoration: 'underline',
                                                                                                          color: green
                                                                                                      }
                                                                                                  }}> US.</Link></span>
                                                    </Typography>

                                            }
                                        </Box>
                                    }
                                    <Box sx={{
                                        display: 'flex',
                                        gap: '5px'
                                    }}>
                                        <Typography style={{fontSize: '14px'}}>
                                            {form?.type === 'N/A' ? '4.' : '5.'}
                                        </Typography>
                                        <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                                            <span style={{fontWeight: '600'}}>Prepare for Shipment:</span> After filling
                                            out the required forms, print it along with the commercial invoice, and
                                            sign the Shipper's name on both. Place one copy of each document in a
                                            transparent document bag, and attach it to the outside of the package. Each
                                            box should have its own set of documents attached.
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={3} xs={12}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'flex-end'
                    }}>
                        <Button
                            variant='contained'
                            sx={{
                                backgroundColor: '#1D8B45',
                                "&:hover": {
                                    backgroundColor: '#1D8B45',
                                    filter: 'brightness(0.9)'
                                }
                            }}
                            onClick={openCreateInvoice}
                            disabled={invoiceStatus}
                        >
                            <Typography style={{textTransform: 'none'}}>
                                + Create Invoice
                            </Typography>
                        </Button>
                    </Box>
                </Grid>
                <Grid item md={9} xs={12}>
                    <CrossBorderDashboardInvoiceList
                        itemList={itemList}
                        trackingList={trackingList}
                        invoiceStatus={invoiceStatus}
                        invoiceList={invoiceList}
                        isCreate={isCreate}
                        closeCreateInvoice={closeCreateInvoice}
                        addInvoiceList={addInvoiceList}
                        removeInvoiceList={removeInvoiceList}
                        shippingListData={shippingListData}
                        cbGetTrackingList={cbGetTrackingList}
                        clearInvoiceList={clearInvoiceList}
                        getTrackingList={getTrackingList}
                        loadingGetTrackList={loadingGetTrackList}
                        errorGetTrackList={errorGetTrackList}
                        shipmentType={shipmentType}
                        getDocumentList={getDocumentList}
                    />
                </Grid>
                <Grid item md={3} xs={12}>
                    <CrossBorderDashboardItemList
                        itemList={itemList}
                        loadingGetItemList={loadingGetItemList}
                        errorGetItemList={errorGetItemList}
                    />
                </Grid>
            </Grid>
        </Box>
    )
}