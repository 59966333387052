import Typography from "@mui/material/Typography";
import {Button, IconButton, InputAdornment, Link, Modal, Snackbar} from "@mui/material";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import {useHistory} from "react-router-dom";
import {useEffect, useState} from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {
    getMissingKeysForBecomeEndUser,
    isValidateEmail,
    isValidatePassword,
    isValidatePhone,
    provinces_states
} from "../../Utils/Helper";
import MuiAlert from "@mui/material/Alert";
import * as React from 'react';
import {HelpOutline} from "@material-ui/icons";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Autocomplete from "@material-ui/lab/Autocomplete";
import PartnerCoupons from "../Images/partner_coupons.png";
import {BACKEND_ME_API, NODE_ROUTE_URI, PARTNER_URI} from "../../Utils/apiUrl";
import axios from "axios";
import {useSelector} from "react-redux";
import {Redirect} from "react-router";
import {getAccessToken} from "../../Utils/doToken";
import Checkbox from "@mui/material/Checkbox";
import {MuiTelInput} from "mui-tel-input";
import intl from "react-intl-universal";
import Grid from "@mui/material/Grid";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import packageIcon from "../../images/homepageIcon/Package.svg";
import PublicIcon from "@mui/icons-material/Public";
import ltlIcon from "../../images/homepageIcon/Pallet.svg";
import brokerageIcon from "../../images/homepageIcon/CustomBrokerage.svg";
import FormHelperText from "@mui/material/FormHelperText";
import {makeStyles} from "@material-ui/core/styles";
import LoadingButton from "@mui/lab/LoadingButton";
import {REGISTER_USER_ALREADY_EXIST_PARTNER, REGISTER_USER_ALREADY_EXIST_USER} from "../../Utils/config";
import {green, greenButtonContained, mainHeading} from "../../Utils/styleConfig";

const businessTypeOption = [
    "E-Commerce",
    "Logistic Transportation",
    "Warehouse",
    "Solution Provider",
    "Other"
]

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const styles = {
    UserSignUpRoot: {
        // width: '500px',
        // maxWidth: '500px',
        width: '50%',
        margin: '0 auto 20px',
        '@media (max-width: 1024px)': {
            width: '100%', // for tablets and above
        },
        '@media (max-width: 480px)': {
            width: '100%', // for phone and above
        },
    },
    UserSignUpContainer: {
        marginTop: '50px',
        gap: '10px',
        padding: '30px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        '@media (max-width: 480px)': {
            padding: 0, // for phone and above
        },
    },
    UserSignUpIntro: {
        display: 'flex',
        flexDirection: 'column',
        gap: '10px'
    },
    UserSignUpHeading: {
        fontSize: mainHeading,
        fontWeight: '600',
        textAlign: 'left',
        WebkitTextStroke: '0.5px black', // For Webkit browsers
    },
    UserSignUpText: {
        textAlign: 'left',
        fontSize: '14px',
        color: '#000000'
    },
    UserSignUpContent: {
        width: '100%',
        margin: '0 auto',
        display: 'flex',
        flexDirection: 'column',
        gap: '30px',
        padding: '30px',
        backgroundColor: '#FFFFFF',
        borderRadius: '10px',
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)',
    },
    UserSignUpWarningText: {
        fontSize: '12px',
        color: '#FF0303'
    },
    UserSignUpResultTextSuccess: {
        fontSize: '14px',
        color: '#539165'
    },
    UserSignUpResultTextFail: {
        fontSize: '14px',
        color: '#FF0303'
    },
    UserSignUpCoupon: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        padding: 0
    },
    UserSignUpCouponImage: {
        height: '500px'
    },
    UserSignUpButtonBox: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center'
    },
    UserSignUpButton: {
        width: '100%',
        backgroundColor: 'green',
        '&:hover': {
            backgroundColor: 'green',
            opacity: '0.8'
        }
    },
    UserSignUpHaveAccount: {
        display: 'flex',
        gap: '5px',
        justifyContent: 'center'
    },
    UserSignUpPrivacyAndTerms: {
        display: 'flex',
        alignItems: 'flex-start',
        gap: '10px'
    },
    UserSignUpCheckbox: {
        padding: 0,
        color: 'green',
        '&.Mui-checked': {
            color: 'green'
        }
    },
    UserSignUpPrivacyAndTermsText: {
        textAlign: 'left',
        fontSize: '12px'
    },
    UserSignUpPrivacyAndTermsLink: {
        color: 'green',
        textDecoration: 'underline green'
    }
}

const useStyles = makeStyles({
    smallInput: {
        "& .MuiInputBase-input": {
            height: "4px", // Adjust the height as per your preference
            padding: "2px", // Adjust the padding as per your preference
            fontSize: "16px", // Adjust the font size as per your preference
        }
    },
    SignInButton: {
        color: 'white',
        backgroundColor: 'green',
        width: '100%',
        margin: '0 auto',
        '&:hover': {
            backgroundColor: 'green',
            opacity: '0.8'
        }
    },
});

export const LandingUserSignUp = () => {

    const classes = useStyles();

    const [isLoading, setIsLoading] = useState(false);
    const [sendEmail, setSendEmail] = useState();
    const [sendStatus, setSendStatus] = useState(false);
    const [loading, setLoading] = useState(false);

    // const [companyEmail, setCompanyEmail] = useState();
    // const [companyName, setCompanyName] = useState();
    // const [companyPhone, setCompanyPhone] = useState();
    // const [postalCode, setPostalCode] = useState();
    // const [province, setProvince] = useState();
    const [contactName, setContactName] = useState();

    const [fullName, setFullName] = useState();

    const [phone, setPhone] = useState();
    const [contactEmail, setContactEmail] = useState();
    const [password, setPassword] = useState();

    // const [companyType, setCompanyType] = useState();
    // const [coupon, setCoupon] = useState();
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');

    const [form, setForm] = useState({});

    const [missingKeys, setMissingKeys] = useState([]);

    const [validationContactEmail, setValidationContactEmail] = useState(true);
    const [validationCompanyEmail, setValidationCompanyEmail] = useState(true);
    const [validationPhone, setValidationPhone] = useState(true);
    const [validationPassword, setValidationPassword] = useState(true);

    const [openModal, setOpenModal] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [errorCode, setErrorCode] = useState(null);

    const [registerStatus, setRegisterStatus] = useState(false);

    const [check, setCheck] = useState(null);

    const isMobile = useMediaQuery('(max-width: 480px)');
    const history = useHistory();

    const [redirectToReferrer, setRedirectToReferrer] = useState(false);
    const [emailLoading, setEmailLoading] = useState(false);

    const token = getAccessToken("access_token") // or read token from cookie

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    }

    const handleCheck = () => {
        setCheck(!check);
    }

    // const handleModalOpen = () => {
    //     setOpenModal(true);
    // }
    //
    // const handleModalClose = () => {
    //     setOpenModal(false);
    // }
    //
    // const handleCompanyEmail = event => {
    //     const value = event.target.value;
    //     setCompanyEmail(value);
    //     setForm(prevState => ({
    //         ...prevState, companyEmail: value
    //     }))
    // }
    //
    // const handleCompanyName = event => {
    //     const value = event.target.value;
    //     setCompanyName(value);
    //     setForm(prevState => ({
    //         ...prevState, companyName: value
    //     }))
    // }
    //
    // const handleCompanyPhone = event => {
    //     const value = event.target.value;
    //     setCompanyPhone(value);
    //     setForm(prevState => ({
    //         ...prevState, companyPhone: value
    //     }))
    // }
    //
    // const handlePostalCode = event => {
    //     const value = event.target.value;
    //     setPostalCode(value);
    //     setForm(prevState => ({
    //         ...prevState, postalCode: value
    //     }))
    // }
    //
    // const handleProvince = (event, value) => {
    //     setProvince(value);
    //     setForm(prevState => ({
    //         ...prevState, province: value
    //     }))
    // }
    //
    // const handleContactName = event => {
    //     const value = event.target.value;
    //     setContactName(value);
    //     setForm(prevState => ({
    //         ...prevState, contactName: value
    //     }))
    // }

    const handleContactEmail = event => {
        const value = event.target.value.trim();
        setContactEmail(value);
        setForm(prevState => ({
            ...prevState, contactEmail: value
        }))
    }

    const handlePassword = event => {
        const value = event.target.value.trim();
        setPassword(value);
        setForm(prevState => ({
            ...prevState, password: value
        }))
    }

    const handleFullName = event => {
        const value = event.target.value;
        setFullName(value);
        setForm(prevState => ({
            ...prevState, fullName: value
        }))
    }

    const handlePhone = value => {
        setPhone(value);
        setForm(prevState => ({
            ...prevState, phone: value
        }))
    }

    const handleFirstName = event => {
        const value = event.target.value;
        setFirstName(value);
        setForm(prevState => ({
            ...prevState, firstName: value
        }))
    }

    const handleLastName = event => {
        const value = event.target.value;
        setLastName(value);
        setForm(prevState => ({
            ...prevState, lastName: value
        }))
    }

    // const handleBusinessType = (event, value) => {
    //     setCompanyType(value);
    //     setForm(prevState => ({
    //         ...prevState, companyType: value
    //     }))
    // }
    //
    // const handleCoupon = event => {
    //     const value = event.target.value;
    //     setCoupon(value);
    //     setForm(prevState => ({
    //         ...prevState, coupon: value
    //     }))
    // }

    const validateContactEmail = () => {
        const result = isValidateEmail(contactEmail);
        setValidationContactEmail(result);
        return result;
    }

    // const validateCompanyEmail = () => {
    //     const result = isValidateEmail(companyEmail);
    //     setValidationCompanyEmail(result);
    //     return result;
    // }

    // const validatePhone = () => {
    //     const result = isValidatePhone(companyPhone);
    //     setValidationPhone(result);
    //     return result;
    // }

    const validatePassword = () => {
        const result = isValidatePassword(password);
        setValidationPassword(result);
        return result;
    }

    const validateEmailAddress = async (email) => {
        let requestURL = `${NODE_ROUTE_URI}/validateEmail`;
        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                data: {
                    email: email,
                    validateRegex: true,
                    validateMx: true,
                    validateTypo: false,
                    validateDisposable: false,
                    validateSMTP: false
                }
            })
            console.log('[validateEmailAddress] result - ', result);
            return result?.data?.valid;
        } catch (e) {
            console.log(e.response)
        }
    }

    const registerEndUser = async () => {
        setIsLoading(true);
        let requestURL = `${NODE_ROUTE_URI}/login_middleware/signup`;

        const accessToken = getAccessToken("access_token");
        const headers = accessToken ? {Authorization: `Bearer ${accessToken}`} : {}

        const data = {
            firstname: firstName,
            lastname: lastName,
            email: contactEmail?.trim(),
            password: password?.trim(),
            phone: phone?.replace(/\D/g, '')
        }

        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                data: data,
                headers: headers
            })
            console.log(result.data);
            setRegisterStatus(true);
            setIsLoading(false);
            setErrorMessage('');
            setSuccessMessage(intl.get('LANDING_PAGE.SIGN_UP.SUCCESS_MESSAGE_USER'));
            handleSuccessMessage();
            setErrorCode('');
        } catch (e) {
            console.log(e.response);
            setRegisterStatus(false);
            const errorCode = e?.response?.data?.code;
            console.log(errorCode);
            handleErrorMessage();
            if (errorCode === REGISTER_USER_ALREADY_EXIST_PARTNER) {
                setErrorMessage(intl.get('LANDING_PAGE.SIGN_UP.ERROR_MESSAGE_USER_3'));
            } else if (errorCode === REGISTER_USER_ALREADY_EXIST_USER) {
                setErrorMessage(intl.get('LANDING_PAGE.SIGN_UP.ERROR_MESSAGE_USER_1'));
            } else {
                setErrorMessage(intl.get('LANDING_PAGE.SIGN_UP.ERROR_MESSAGE_USER_2'));
            }
            setIsLoading(false);
            setErrorCode(errorCode);
        }
    }

    const handleSubmit = async () => {
        console.log('[LandingUserSignUp] handleSubmit - from', form);
        const missingKeys = getMissingKeysForBecomeEndUser(form);
        setMissingKeys(missingKeys);

        const contactEmailCheck = await validateEmailAddress(contactEmail);
        setValidationContactEmail(contactEmailCheck);
        // const contactEmailCheck = validateContactEmail();
        check === null && setCheck(false);
        // const companyEmailCheck = validateCompanyEmail();
        const phoneCheck = isValidatePhone(phone);
        setValidationPhone(phoneCheck);
        const passwordCheck = validatePassword();
        if (contactEmailCheck && passwordCheck && missingKeys.length === 0 && check && phoneCheck) {
            registerEndUser();
        }
    }

    const handleSwitchAccount = async () => {
        setEmailLoading(true);
        let requestURL = `${NODE_ROUTE_URI}/login_middleware/requestToSwitchAccountTypeEmail`;
        let data = {
            email: contactEmail,
            ownerRole: 3
        }

        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                data: data
            });
            setErrorMessage('');
            setSuccessMessage('Successfully Send Email');
            handleSuccessMessage();
        } catch (e) {
            console.log(e.response);
            setErrorMessage('Fail to Send Email');
            handleErrorMessage();
        } finally {
            setEmailLoading(false);
        }
    }

    useEffect(() => {

        if (token) {
            // Make an API call to validate the token
            axios.get(BACKEND_ME_API, {
                headers: {Authorization: `Bearer ${token}`},
            })
                .then(() => {
                    // Token is valid, redirect the user to the home page
                    setRedirectToReferrer(true);
                })
                .catch(() => {
                    // Token is not valid, do nothing
                });
        }
    }, []);

    useEffect(() => {
        contactEmail ? validateContactEmail() : setValidationContactEmail(true);
    }, [contactEmail]);

    useEffect(() => {
        password ? validatePassword() : setValidationPassword(true);
    }, [password]);

    if (redirectToReferrer) {
        return <Redirect to="/home"/>;
    }

    console.log('[LandingUserSignUp] phone', phone);

    return (
        <Grid container spacing={2}>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Grid item xs={12}>
                <Typography sx={styles.UserSignUpHeading}>
                    {intl.get('LANDING_PAGE.SIGN_UP.CREATE')}
                </Typography>
            </Grid>
            {
                registerStatus ?
                    <>
                        <Grid item xs={12}>
                            <Typography sx={{fontSize: '14px', color: green}}>
                                Your account has been successfully created! An activation email has been sent to your
                                registered email address. Please check your inbox and follow the instructions to
                                activate your account.
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                variant="contained"
                                sx={styles.UserSignUpButton}
                                onClick={() => history.push('/sign-in')}
                                size='small'
                            >
                                <Typography sx={{textTransform: 'none', fontSize: '14px'}}>
                                    Sign In Account
                                </Typography>
                            </Button>
                        </Grid>
                    </>
                    :
                    <Grid item xs={12}>
                        <Box sx={styles.UserSignUpContent}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Grid container spacing={0}>
                                        <Grid item xs={12}>
                                            <Typography sx={{fontWeight: '600'}}>
                                                Is this account for
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Box sx={{display: 'flex', alignItems: 'center'}}>
                                                <Checkbox
                                                    icon={<RadioButtonUncheckedIcon/>}
                                                    checkedIcon={<CheckCircleIcon/>}
                                                    color="success"
                                                    checked
                                                />
                                                <Typography style={{color: '#545454', padding: '0 5px'}}>
                                                    Individual
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Box sx={{display: 'flex', alignItems: 'center'}}>
                                                <Checkbox
                                                    icon={<RadioButtonUncheckedIcon/>}
                                                    checkedIcon={<CheckCircleIcon/>}
                                                    color="success"
                                                    onClick={() => history.push('/business/sign-up')}
                                                />
                                                <Typography style={{color: '#545454', padding: '0 5px'}}>
                                                    Business
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        value={contactEmail}
                                        label={`${intl.get('LANDING_PAGE.SIGN_UP.EMAIL')}`}
                                        required
                                        fullWidth
                                        variant="outlined"
                                        onInput={handleContactEmail}
                                        inputProps={{style: {fontSize: 14}}}
                                        InputLabelProps={{style: {fontSize: 14}}}
                                        size='small'
                                        error={(missingKeys.includes('contactEmail') && !contactEmail) || !validationContactEmail}
                                        helperText={(missingKeys.includes('contactEmail') && !contactEmail) ?
                                            intl.get('LANDING_PAGE.SIGN_UP.REQUIRED') : !validationContactEmail &&
                                            intl.get('LANDING_PAGE.SIGN_UP.INVALID_EMAIL')}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        value={password}
                                        label={`${intl.get('LANDING_PAGE.SIGN_UP.PASSWORD')}`}
                                        type={showPassword ? 'text' : 'password'}
                                        required
                                        fullWidth
                                        variant="outlined"
                                        onInput={handlePassword}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                    >
                                                        {showPassword ? <Visibility/> : <VisibilityOff/>}
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                        inputProps={{style: {fontSize: 14}}}
                                        InputLabelProps={{style: {fontSize: 14}}}
                                        size='small'
                                        error={(missingKeys.includes('password') && !password) || !validationPassword}
                                        helperText={(missingKeys.includes('password') && !password) ?
                                            intl.get('LANDING_PAGE.SIGN_UP.REQUIRED') : !validationPassword &&
                                            intl.get('LANDING_PAGE.SIGN_UP.INVALID_PASSWORD')}
                                    />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <TextField
                                        value={firstName}
                                        label={`${intl.get('LANDING_PAGE.SIGN_UP.FIRST_NAME')}`}
                                        required
                                        fullWidth
                                        variant="outlined"
                                        onInput={handleFirstName}
                                        inputProps={{style: {fontSize: 14}}}
                                        InputLabelProps={{style: {fontSize: 14}}}
                                        size='small'
                                        error={(missingKeys.includes('firstName') && !firstName)}
                                        helperText={
                                            ((missingKeys.includes('firstName') && !firstName) && intl.get('CROSS_BORDER.REQUIRED'))
                                        }
                                    />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <TextField
                                        value={lastName}
                                        label={`${intl.get('LANDING_PAGE.SIGN_UP.LAST_NAME')}`}
                                        required
                                        fullWidth
                                        variant="outlined"
                                        onInput={handleLastName}
                                        inputProps={{style: {fontSize: 14}}}
                                        InputLabelProps={{style: {fontSize: 14}}}
                                        size='small'
                                        error={(missingKeys.includes('lastName') && !lastName)}
                                        helperText={
                                            ((missingKeys.includes('lastName') && !lastName) && intl.get('CROSS_BORDER.REQUIRED'))
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <MuiTelInput
                                        value={phone}
                                        label={`${intl.get('LANDING_PAGE.SIGN_UP.PHONE')}`}
                                        required
                                        fullWidth
                                        variant="outlined"
                                        defaultCountry="CA"
                                        // onChange={handleCompanyPhone}
                                        onChange={handlePhone}
                                        inputProps={{style: {fontSize: 14}}}
                                        InputLabelProps={{style: {fontSize: 14}}}
                                        size='small'
                                        placeholder={intl.get('LANDING_PAGE.SIGN_UP.RECOMMEND')}
                                        error={(missingKeys.includes('phone') && !phone) || !validationPhone}
                                        helperText={
                                            ((missingKeys.includes('phone') && !phone) ? intl.get('CROSS_BORDER.REQUIRED') : !validationPhone && 'Invalid Phone Number')
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Box sx={styles.UserSignUpPrivacyAndTerms}>
                                        <Checkbox
                                            checked={check}
                                            sx={styles.UserSignUpCheckbox}
                                            onChange={handleCheck}
                                        />
                                        <Typography style={styles.UserSignUpPrivacyAndTermsText}>
                                            {intl.get('LANDING_PAGE.SIGN_UP.AGREEMENT')}<Link
                                            sx={styles.UserSignUpPrivacyAndTermsLink}
                                            href={'https://uucargo.ca/terms-of-use/'}
                                            target="_blank" rel="noopener noreferrer"
                                        >{intl.get('LANDING_PAGE.SIGN_UP.TERMS')}
                                        </Link> {intl.get('LANDING_PAGE.SIGN_UP.AND')} <Link
                                            sx={styles.UserSignUpPrivacyAndTermsLink}
                                            href={'https://uucargo.ca/privacy-policy/'}
                                            target="_blank" rel="noopener noreferrer"
                                        >{intl.get('LANDING_PAGE.SIGN_UP.PRIVATE_POLICY')}</Link>.
                                        </Typography>
                                    </Box>
                                    <FormHelperText sx={{color: "error.main"}}>
                                        {check === null ? '' : check ? '' : intl.get('LANDING_PAGE.SIGN_UP.AGREEMENT_WARNING')}
                                    </FormHelperText>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box sx={styles.UserSignUpButtonBox}>
                                        <LoadingButton
                                            disabled={loading}
                                            loading={loading}
                                            variant="contained"
                                            onClick={handleSubmit}
                                            className={classes.SignInButton}
                                            sx={{
                                                backgroundColor: '#1D8B45',
                                                "&:hover": {
                                                    backgroundColor: '#1D8B45',
                                                    filter: 'brightness(0.9)'
                                                }
                                            }}
                                            size='small'
                                        >
                                            <Typography sx={{textTransform: 'none', fontSize: '14px'}}>
                                                {intl.get('LANDING_PAGE.SIGN_UP.CREATE_ACCOUNT')}
                                            </Typography>
                                        </LoadingButton>
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box sx={styles.UserSignUpHaveAccount}>
                                        <Typography
                                            style={{fontSize: '14px', color: '#000000'}}
                                        >
                                            {intl.get('LANDING_PAGE.SIGN_UP.HAVE_ACCOUNT')}
                                        </Typography>
                                        <Typography
                                            style={{
                                                fontSize: '14px',
                                                color: 'green',
                                                cursor: 'pointer',
                                                fontWeight: '600'
                                            }}
                                            onClick={() => {
                                                // handleOpenSignup();
                                                history.push('/sign-in')
                                                // dispatch(updateLandingClickJoinUs(true))
                                            }}
                                        >
                                            {intl.get('LANDING_PAGE.SIGN_UP.SIGN_IN')}
                                        </Typography>
                                    </Box>
                                </Grid>
                                {
                                    errorCode === REGISTER_USER_ALREADY_EXIST_PARTNER &&
                                    <>
                                        <Grid item xs={12}>
                                            <Typography sx={{color: "error.main"}}>
                                                {errorMessage}
                                            </Typography>
                                            <Typography sx={{color: "error.main"}}>
                                                If this is your account and you need to switch account types, please
                                                press the button below to contact UUCargo for assistance.
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box sx={{
                                                display: 'flex',
                                                justifyContent: 'flex-end'
                                            }}>
                                                <LoadingButton
                                                    variant='contained'
                                                    sx={greenButtonContained}
                                                    onClick={handleSwitchAccount}
                                                    loading={emailLoading}
                                                >
                                                    <Typography sx={{textTransform: 'none', fontSize: '14px'}}>
                                                        Switch Account
                                                    </Typography>
                                                </LoadingButton>
                                            </Box>
                                        </Grid>
                                    </>
                                }
                            </Grid>
                        </Box>
                    </Grid>
            }
        </Grid>
    )
}