import Grid from "@material-ui/core/Grid";
import {
    Button,
    Container,
    Dialog, DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormLabel,
    Link,
    Snackbar,
    useMediaQuery
} from "@mui/material";
import Typography from "@mui/material/Typography";
import {useHistory, useParams} from "react-router-dom";
import Box from "@mui/material/Box";
import {PARTNER_URI} from "../../Utils/apiUrl";
import {getAccessToken} from "../../Utils/doToken";
import axios from "axios";
import {useEffect, useRef, useState, Fragment} from "react";
import CircularProgress from "@mui/material/CircularProgress";
import {CSVLink} from "react-csv";
import {AdjustOrderDashboardEach} from "./AdjustOrderDashboardEach";
import {mapCarrierLogo} from "../../Utils/Helper";
import LoadingButton from "@mui/lab/LoadingButton";
import * as React from "react";
import MuiAlert from "@mui/material/Alert";
import {AdjustOrderDashboardCanpar} from "./AdjustOrderDashboardCanpar";
import {AdjustOrderDashboardInvoice} from "./AdjustOrderDashboardInvoice";
import {
    greenButtonContained,
    grey,
    greyButtonContained,
    greyButtonOutlined,
    sectionHeading
} from "../../Utils/styleConfig";

const TestData = [
    {
        orderId: '1613',
        amount: 100,
        invoiceNumber: '000000A514A4243',
        trackingNumber: '1ZA514A42092503631',
        adjustmentFee: 12,
        reason: 'Adjustments & Other Charges/Address Corrections'
    },

]

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const AdjustOrderDashboard = () => {

    const history = useHistory();

    const isMobile = useMediaQuery('(max-width: 480px)');

    const csvLink = useRef();

    const token = getAccessToken("access_token");
    const {orderId} = useParams();
    const [loading, setLoading] = useState(false);
    const [loadingClaim, setLoadingClaim] = useState(false);
    const [isClaimed, setIsClaimed] = useState(false);
    const [tracking, setTracking] = useState([]);
    const [adjustAmount, setAdjustMount] = useState(0);
    const [carrier, setCarrier] = useState('');
    const [invoiceData, setInvoiceData] = useState([]);
    const [invoiceLoading, setInvoiceLoading] = useState(false);
    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [trackingLength, setTrackingLength] = useState(0);
    const [orderDetails, setOrderDetails] = useState(null);
    const [dialogOpen, setDialogOpen] = useState(false);

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const handleDialogOpen = () => {
        setDialogOpen(true);
    }

    const handleDialogClose = () => {
        setDialogOpen(false);
    }

    const getAdjustOrderDetails = async () => {
        setLoading(true);
        let requestURL = `${PARTNER_URI}/invoices/getPartnerShippingAdjustOrderDetailsByShippingOrderId`;

        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${token}`
                },
                params: {
                    orderId: orderId,
                    // partnerId: 248
                }
            })
            console.log('result', result);
            setOrderDetails(result?.data);
            setAdjustMount(result?.data?.total_amount_include_tax);
            setCarrier(result?.data?.account_base);
            setTracking(result?.data?.trackings);
            setLoading(false);

        } catch (e) {
            console.log('error', e.response);
            setLoading(false);
        }
    }

    const getInvoiceData = async (tackingNumber, invoiceNumber) => {
        let requestURL = `${PARTNER_URI}/invoices/exportInvoiceDataForTracking`;
        let data = {
            trackingNumber: tackingNumber,
            invoiceNumber: invoiceNumber
        }
        console.log('data', data);
        const invoiceResult = [];
        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${token}`
                },
                data: data
            })
            console.log('result get invoice data', result);
            if (result?.data?.headData?.length === 0) {
                // setErrorMessage('There is no invoice data available for download.');
                // handleErrorMessage();
            } else {
                // setErrorMessage('');
                // setSuccessMessage('Invoice data downloaded successfully.');
                // handleSuccessMessage();
                setInvoiceData(prevState => {
                    if (prevState?.length === 0) {
                        return [result?.data?.headData, ...result?.data?.lineData]
                    } else {
                        return [...prevState, ...result?.data?.lineData]
                    }
                })
                // setInvoiceData([result?.data?.headData, ...result?.data?.lineData]);
                // csvLink.current.link.click();
            }
        } catch (e) {
            // setErrorMessage('There is an issue with downloading invoice data.');
            // handleErrorMessage();
            console.log('error', e.response);
        } finally {
            setTrackingLength(prevState => prevState + 1);
        }
    }

    const handleDownloadInvoice = () => {
        setInvoiceLoading(true);
        setTrackingLength(0);
        setInvoiceData([]);
        for (const adjustment of tracking) {
            getInvoiceData(adjustment?.tracking_no, adjustment?.invoice_no);
        }
    }

    const handleBack = () => {
        history.goBack();
    }

    const claimAdjustment = async () => {
        try {
            setLoadingClaim(true);

            let requestURL = `${PARTNER_URI}/invoices/claimAdjustment`;

            const trackingInfo = orderDetails?.trackings?.map(tracking => ({
                trackingNumber: tracking?.tracking_no,
                invoiceNumber: tracking?.invoice_no,
                adjustedOrder: tracking?.originalShippingOrderInfo?.order_id
            }))

            const data = {
                adjustmentOrder: orderDetails?.order_id,
                carrier: orderDetails?.account_base,
                adjustmentAmount: orderDetails?.total_amount_include_tax,
                trackingInfo: trackingInfo
            };

            const result = await axios({
                method: 'post',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${token}`
                },
                data: data
            })

            setErrorMessage('');
            setSuccessMessage('Successfully send claim adjustment.');
            handleSuccessMessage();

            return true;
        } catch (e) {
            setErrorMessage('Fail to send claim adjustment');
            handleErrorMessage();
            return false;
        } finally {
            setLoadingClaim(false);
        }
    }

    const handleClaimAdjustment = async () => {
        const claimResult = await claimAdjustment();
        claimResult && setIsClaimed(true);
        handleDialogClose();
    }

    useEffect(() => {
        let isMounted = true; // Add a flag to track if the component is mounted

        getAdjustOrderDetails();

        // Cleanup function to set isMounted to false when the component unmounts
        return () => {
            isMounted = false;
        };

    }, []);

    useEffect(() => {
        if (trackingLength > 0 && trackingLength === tracking?.length) {
            if (invoiceData?.length > 1) {
                // console.log('download csv');
                setErrorMessage('');
                setSuccessMessage('Invoice data downloaded successfully.');
                handleSuccessMessage();
                csvLink.current.link.click();
            } else {
                setErrorMessage('There is no invoice data available for download.');
                handleErrorMessage();
            }
            setInvoiceLoading(false);
        }
    }, [trackingLength])

    if (loading) {
        return (
            <Container maxwidth='xl'>
                <Box>
                    <CircularProgress/>
                </Box>
            </Container>
        )
    }

    console.log('[AdjustOrderDashboard] orderDetails', orderDetails);

    return (
        <Container maxWidth='xl'>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Dialog
                maxWidth="sm"
                fullWidth
                open={dialogOpen}
                onClose={handleDialogClose}
            >
                <DialogTitle>
                    <Typography sx={{fontSize: sectionHeading, fontWeight: '600'}}>
                        Claim Adjustment
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Typography>
                        You are about to claim the adjustment.
                    </Typography>
                    <Typography>
                        Would you like to proceed with the claim?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        gap: '10px'
                    }}>
                        <Button
                            variant='contained'
                            sx={greyButtonContained}
                            size='small'
                            onClick={handleDialogClose}
                        >
                            <Typography sx={{textTransform: 'none'}}>
                                No
                            </Typography>
                        </Button>
                        <LoadingButton
                            variant='contained'
                            sx={greenButtonContained}
                            size='small'
                            onClick={handleClaimAdjustment}
                            loading={loadingClaim}
                        >
                            <Typography sx={{textTransform: 'none'}}>
                                Yes
                            </Typography>
                        </LoadingButton>
                    </Box>
                </DialogActions>
            </Dialog>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '20px'
            }}>
                <Grid container spacing={2}>
                    <Grid item md={3} xs={12}>
                        <Box sx={{display: 'flex', gap: '20px', alignItems: 'center', height: '100%'}}>
                            <Typography sx={{fontWeight: '600'}}>
                                Adjustment Order #:
                            </Typography>
                            <Typography sx={{fontWeight: '600'}}>
                                {orderId}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item md={4} xs={12}>
                        <Box sx={{display: 'flex', gap: '20px', alignItems: 'center', height: '100%'}}>
                            <Typography sx={{fontWeight: '600'}}>
                                Total Adjustment Amount:
                            </Typography>
                            <Typography sx={{fontWeight: '600'}}>
                                $ {adjustAmount?.toFixed(2)}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item md={1} xs={12}>
                        <Box sx={{display: 'flex', gap: '20px', alignItems: 'center', height: '100%'}}>
                            <img src={mapCarrierLogo(carrier?.toUpperCase())} alt="logo" width='50px'/>
                        </Box>
                    </Grid>
                    {(carrier?.toUpperCase() === 'CANPAR' || carrier?.toUpperCase() === 'UPS') &&
                        <Grid item md={2} xs={12}>
                            <Box sx={{display: 'flex', gap: '20px', alignItems: 'center', height: '100%'}}>
                                <LoadingButton
                                    variant='outlined'
                                    sx={{
                                        border: '2px solid #1D8B45',
                                        "&:hover": {
                                            border: '2px solid #1D8B45',
                                            filter: 'brightness(0.9)'
                                        }
                                    }}
                                    onClick={handleDownloadInvoice}
                                    loading={invoiceLoading}
                                >
                                    <Typography style={{
                                        textTransform: 'none',
                                        fontSize: '12px',
                                        color: '#1D8B45',
                                    }}>
                                        Download CSV
                                    </Typography>
                                </LoadingButton>
                                <CSVLink
                                    data={invoiceData}
                                    filename={`Adjust-Invoice-${orderId}.csv`}
                                    className='hidden'
                                    ref={csvLink}
                                    target='_blank'
                                />
                            </Box>
                        </Grid>
                    }
                    <Grid item md={2} xs={12}>
                        <Box sx={{display: 'flex', gap: '20px', alignItems: 'center', height: '100%'}}>
                            <Button
                                variant='outlined'
                                sx={greyButtonOutlined}
                                onClick={handleDialogOpen}
                                disabled={isClaimed}
                            >
                                <Typography style={{
                                    textTransform: 'none',
                                    fontSize: '12px',
                                    color: grey,
                                }}>
                                    Claim Adjustment
                                </Typography>
                            </Button>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider/>
                    </Grid>
                </Grid>
                {
                    !isMobile &&
                    <Grid container spacing={2}>
                        <Grid item xs={3}>
                            <Typography style={{fontWeight: '600', fontSize: '14px'}}>
                                Tracking
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography style={{fontWeight: '600', fontSize: '14px'}}>
                                Adjust For Order
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography style={{fontWeight: '600', fontSize: '14px'}}>
                                Sender
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography style={{fontWeight: '600', fontSize: '14px'}}>
                                Recipient
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography style={{fontWeight: '600', fontSize: '14px'}}>
                                Adjustment
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider/>
                        </Grid>
                    </Grid>
                }
                {
                    tracking?.length > 0 && tracking?.map((adjustment, index) => {
                        return (
                            <Fragment key={index}>
                                <AdjustOrderDashboardEach adjustment={adjustment} carrier={carrier}/>
                            </Fragment>
                        )
                    })
                }
                {
                    carrier?.toUpperCase() === 'CANPAR' && <AdjustOrderDashboardCanpar/>
                }
                {
                    orderDetails?.invoiceInfo && <AdjustOrderDashboardInvoice orderDetails={orderDetails}
                                                                              getAdjustOrderDetails={getAdjustOrderDetails}/>
                }
                <Box sx={{display: 'flex', justifyContent: 'flex-start', marginBottom: '40px'}}>
                    <Button
                        variant='contained'
                        sx={{
                            backgroundColor: '#1D8B45',
                            "&:hover": {
                                backgroundColor: '#1D8B45',
                                filter: 'brightness(0.9)'
                            }
                        }}
                        onClick={handleBack}
                    >
                        <Typography sx={{textTransform: 'none'}}>
                            Back
                        </Typography>
                    </Button>
                </Box>
            </Box>
        </Container>

    )
}