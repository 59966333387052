import Grid from "@material-ui/core/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import dayjs from "dayjs";
import {grey, subHeading, textContent} from "../../../Utils/styleConfig";
import * as React from "react";
import {LABEL_DOWNLOAD_URL_PREFIX_ALTERNATE} from "../../../Utils/apiUrl";
import {Link} from "react-router-dom";

const style = {
    PackageOrderDashboardCostRoot: {
        border: '1px solid #B2B2B2',
        borderRadius: '10px',
        padding: '10px 0',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
    }
}

export const BusinessPackageOrderDashboardECommerceFulfillment = ({
                                                                      fulfillmentList,
                                                                      fulfillmentLoading
                                                                  }) => {

    console.log('[BusinessPackageOrderDashboardECommerceFulfillment] fulfillmentList', fulfillmentList);

    return (
        <Box sx={style.PackageOrderDashboardCostRoot}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        padding: '10px',
                        borderBottom: '1px solid #B2B2B2'
                    }}>
                        <Typography sx={{fontSize: '20px', fontWeight: '600'}}>
                            {/*{intl.get('ORDER_DASHBOARD.SHIPPING_DOCUMENTS.SHIPPING_DOCUMENTS')}*/}
                            ECommerce Order Fulfillment
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{
                        display: 'flex',
                        padding: '0 10px',
                        justifyContent: 'flex-start',
                        gap: '10px'
                    }}>
                        <Box>
                            <img
                                src={`${LABEL_DOWNLOAD_URL_PREFIX_ALTERNATE}${fulfillmentList?.[0]?.marketplace_icon_uri}`}
                                alt="logo"
                                width='30px'/>
                        </Box>
                        <Typography>
                            {fulfillmentList?.[0]?.store_name}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{
                        display: 'flex',
                        padding: '0 10px',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                        flexDirection: 'column'
                    }}>
                        <Typography sx={{fontSize: subHeading, fontWeight: '600'}}>
                            Order #
                            <Link
                                to={`/business/e-commerce/order/${fulfillmentList?.[0]?.marketplace_order_id}`}
                                style={{color: '#1D8B45'}}
                            >
                                <span> {fulfillmentList?.[0]?.order_name}</span>
                            </Link>
                        </Typography>
                        <Typography sx={{fontSize: textContent}}>
                            {dayjs(fulfillmentList?.[0]?.fulfilled_at)?.format('MMMM DD, YYYY')}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{
                        padding: '0 10px'
                    }}>
                        <Typography sx={{color: grey, fontWeight: '600'}}>
                            Item List
                        </Typography>
                        {
                            fulfillmentList?.[0]?.fulfillmentProducts?.map((item, index) => (
                                <Grid container spacing={1} key={index}>
                                    <Grid item xs={6}>
                                        <Typography
                                            sx={{
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                            }}>
                                            {item?.product_name}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                        {
                                            +item?.weight > 0 &&
                                            <Typography>
                                                {item?.weight?.toFixed(2)} {item?.weight_unit}
                                            </Typography>
                                        }
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Typography>
                                            {item?.product_quantity}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            ))
                        }
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}