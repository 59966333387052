import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {styles, subHeading} from "../../Utils/styleConfig";
import Box from "@mui/material/Box";
import {Divider} from "@mui/material";

export const MarketplaceOrderDashboardSender = ({orderData}) => {
    return (
        <Box sx={styles?.root2}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box sx={styles?.section}>
                        <Typography sx={{fontSize: subHeading}}>
                            Sender
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Divider/>
                </Grid>
                <Grid item xs={12}>
                    <Box sx={styles?.content}>
                        <Typography>
                            {orderData?.pickup_name}
                        </Typography>
                        <Typography>
                            {orderData?.pickup_address_line2 && `${orderData?.pickup_address_line2} - `}{orderData?.pickup_address}
                        </Typography>
                        <Typography>
                            {orderData?.pickup_city}, {orderData?.pickup_province}, {orderData?.pickup_country}, {orderData?.pickup_postal_code}
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}