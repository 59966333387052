import React, {Fragment, useEffect, useState} from "react";
import {NODE_ROUTE_URI} from "../../Utils/apiUrl";
import axios from "axios";
import {extractFullAddress} from "../../Utils/Helper";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import {makeStyles} from "@material-ui/core/styles";
import {Checkbox, FormControlLabel} from "@material-ui/core";
import intl from "react-intl-universal";

const useStyles = makeStyles({
    smallInput: {
        "& .MuiInputBase-input": {
            height: "0px", // Adjust the height as per your preference
            padding: "2px", // Adjust the padding as per your preference
            fontSize: "14px", // Adjust the font size as per your preference
        }
    }
});

export const house_type = [
    {propertyType: 'Residential', houseType: 'House'},
    {propertyType: 'Residential', houseType: 'Townhouse'},
    {propertyType: 'Residential', houseType: 'Apartment'},
    {propertyType: 'Residential', houseType: 'Curb Side'},
]

export const WordPressQuoteLocation = ({tab, validatedResult, cbHandleMovingFrom, cbHandleMovingTo, lng}) => {

    const classes = useStyles();

    const [movingFromAddressPredictions, setMovingFromAddressPredictions] = useState([]);
    const [movingToAddressPredictions, setMovingToAddressPredictions] = useState([]);
    const [movingFrom, setMovingFrom] = useState({
        streetAddress: null,
        apt: null,
        city: null,
        subCity: null,
        province: null,
        postalCode: null,
        country: null,
        propertyType: {
            houseType: "House",
            propertyType: "Residential"
        },
        floorLevel: '1',
        lift: 0,
        storey: '1',
        displayAddress: {
            description: null,
            placeId: null,
            text: null
        },
        displayPostalCode: {
            description: null,
            placeId: null,
            text: null
        },
        option: 'schedule',
        warehouse: null,
        distanceFromTruckToDoor: "0 - 50m",
        // staircase: "Do not include the basement of your residence",
        staircaseNumber: 0,
        isNarrowStreet: false,
        isSteepDriveway: false,
        drivewaySteepLevel: 0,
        latitude: null,
        longitude: null
    });

    const [movingTo, setMovingTo] = useState({
        streetAddress: null,
        apt: null,
        city: null,
        subCity: null,
        province: null,
        postalCode: null,
        country: null,
        propertyType: {
            houseType: "Apartment",
            propertyType: "Residential"
        },
        floorLevel: '1',
        lift: 1,
        storey: '1',
        displayAddress: {
            description: null,
            placeId: null,
            text: null
        },
        displayPostalCode: {
            description: null,
            placeId: null,
            text: null
        },
        option: 'schedule',
        warehouse: null,
        distanceFromTruckToDoor: "0 - 50m",
        // staircase: "Do not include the basement of your residence",
        staircaseNumber: 0,
        isNarrowStreet: false,
        isSteepDriveway: false,
        drivewaySteepLevel: 0,
        latitude: null,
        longitude: null
    });

    const googleMapsPlaceAutocomplete = async (inputValue, type) => {
        let requestURL = `${NODE_ROUTE_URI}/maps_apis/placeAutocomplete`;

        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    input: inputValue,
                    types: type
                }
            })
            const {data: {data: {predictions}}} = result;
            console.log('prediction', predictions);
            const updatedPredictions = predictions?.map(each => {
                return {
                    description: each?.description,
                    text: each?.structured_formatting?.main_text,
                    placeId: each?.place_id
                }
            })
            console.log('updated prediction', updatedPredictions);

            return updatedPredictions;

        } catch (e) {
            console.log(e.response)
        }
    }

    const googleMapsPlaceDetails = async (placeId) => {
        let requestURL = `${NODE_ROUTE_URI}/maps_apis/placeDetails`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    placeId: placeId
                }
            });
            const place = result?.data?.data?.result;
            return place;

        } catch (e) {
            console.log(e.response)
        }
    }

    const getPlace = async (place) => {
        console.log('[getPlaceId] place', place?.text?.length);
        if (place?.text?.length === 3) {

            const predictionsAddress = await googleMapsPlaceAutocomplete(place?.text, 'postal_code');
            console.log('[getPlaceId] prediction address', predictionsAddress);

            const filterPrediction = predictionsAddress?.filter(item => item?.text?.length > 3);
            console.log('[getPlaceId] filterPrediction', filterPrediction);

            if (filterPrediction?.length > 0) {
                return filterPrediction?.[0];
            } else {
                return place;
            }
        } else {
            return place;
        }
    }

    const handleInputChangeMovingFromPostalCode = async (event, value) => {
        // setMovingFrom(prevState => ({
        //     ...prevState,
        //     postalCode: value,
        //     displayPostalCode: {
        //         ...prevState?.displayPostalCode,
        //         description: value,
        //         placeId: '',
        //         text: value
        //     }
        // }));
        const predictionsAddress = await googleMapsPlaceAutocomplete(value, 'postal_code');
        setMovingFromAddressPredictions(predictionsAddress);
    }

    const handleChangeMovingFromPostalCode = async (event, value) => {

        console.log('[handleChangeMovingFromPostalCode] value', value);

        const placeValue = await getPlace(value);

        console.log('[handleChangeMovingFromPostalCode] place value', placeValue);

        const place = await googleMapsPlaceDetails(placeValue?.placeId);

        if (place) {
            const address = extractFullAddress(place);
            console.log('[handleChangeMovingFromPostalCode] address', address);
            console.log('[handleChangeMovingFromPostalCode] place', place);

            setMovingFrom(prevState => ({
                ...prevState,
                city: address?.city || address?.administrative_area_level_3 || place?.vicinity || address?.sublocality || address?.neighborhood,
                subCity: place?.vicinity || address?.sublocality || address?.neighborhood,
                province: address?.state?.code,
                country: address?.country?.code,
                postalCode: address?.zip,
                displayPostalCode: placeValue,
                latitude: place?.geometry?.location?.lat,
                longitude: place?.geometry?.location?.lng
            }))
        } else {
            setMovingFrom(prevState => ({
                ...prevState,
                postalCode: '',
                displayPostalCode: {
                    ...prevState?.displayPostalCode,
                    description: '',
                    placeId: '',
                    text: ''
                },
                latitude: '',
                longitude: ''
            }))
        }
    }

    const handleInputChangeMovingToPostalCode = async (event, value) => {
        // setMovingTo(prevState => ({
        //     ...prevState,
        //     postalCode: value,
        //     displayPostalCode: {
        //         ...prevState?.displayPostalCode,
        //         description: value,
        //         placeId: '',
        //         text: value
        //     }
        // }));
        const predictionsAddress = await googleMapsPlaceAutocomplete(value, 'postal_code');
        setMovingToAddressPredictions(predictionsAddress);
    }

    const handleChangeMovingToPostalCode = async (event, value) => {

        const placeValue = await getPlace(value);

        const place = await googleMapsPlaceDetails(placeValue?.placeId);

        if (place) {
            const address = extractFullAddress(place);
            setMovingTo(prevState => ({
                ...prevState,
                city: address?.city || address?.administrative_area_level_3 || place?.vicinity || address?.sublocality || address?.neighborhood,
                subCity: place?.vicinity || address?.sublocality || address?.neighborhood,
                province: address?.state?.code,
                country: address?.country?.code,
                postalCode: address?.zip,
                displayPostalCode: placeValue,
                latitude: place?.geometry?.location?.lat,
                longitude: place?.geometry?.location?.lng
            }))
        } else {
            setMovingTo(prevState => ({
                ...prevState,
                postalCode: '',
                displayPostalCode: {
                    ...prevState?.displayPostalCode,
                    description: '',
                    placeId: '',
                    text: ''
                },
                latitude: '',
                longitude: ''
            }))
        }
    }

    const handleIsSelfDropOff = (event) => {
        setMovingFrom(prevState => ({
            ...prevState,
            option: event.target.checked ? 'self' : 'schedule',
            warehouse: null
        }))
    }

    const handleIsSelfPickup = (event) => {
        setMovingTo(prevState => ({
            ...prevState,
            option: event.target.checked ? 'self' : 'schedule',
            warehouse: null
        }))
    }

    const handleMovingFromPropertyType = (event, value) => {
        if (value?.houseType === 'House' || value?.houseType === 'Townhouse') {
            setMovingFrom(prevState => ({
                ...prevState,
                propertyType: value,
                lift: 0
            }))
        } else if (value?.houseType === 'Curb Side') {
            setMovingFrom(prevState => ({
                ...prevState,
                propertyType: value,
                lift: 0,
                floorLevel: "1"
            }))
        } else {
            setMovingFrom(prevState => ({
                ...prevState,
                propertyType: value,
                lift: 1
            }))
        }
    }

    const handleMovingToPropertyType = (event, value) => {
        if (value?.houseType === 'House' || value?.houseType === 'Townhouse') {
            setMovingTo(prevState => ({
                ...prevState,
                propertyType: value,
                lift: 0
            }))
        } else if (value?.houseType === 'Curb Side') {
            setMovingTo(prevState => ({
                ...prevState,
                propertyType: value,
                lift: 0,
                floorLevel: "1"
            }))
        } else {
            setMovingTo(prevState => ({
                ...prevState,
                propertyType: value,
                lift: 1
            }))
        }
    }

    useEffect(() => {
        cbHandleMovingFrom(movingFrom);
    }, [movingFrom])

    useEffect(() => {
        cbHandleMovingTo(movingTo);
    }, [movingTo])

    return (
        <Fragment>
            <Grid item md={6} xs={12}>
                <Grid container spacing={4}>
                    {
                        tab?.toLowerCase() === 'moving' &&
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                < FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={movingFrom?.option === 'self'}
                                            onChange={handleIsSelfDropOff}
                                            style={{
                                                color: 'green',
                                                '&.Mui-checked': {
                                                    color: 'green',
                                                }
                                            }}
                                        />
                                    }
                                    label={
                                        <>
                                            {lng === 'cn' ? '自行将货物送至我司仓库' : "Drop off at our warehouse by yourself"}
                                        </>
                                    }
                                />
                            </FormControl>
                        </Grid>
                    }
                    <Grid item md={tab?.toLowerCase() === 'moving' ? 8 : 12} xs={12}>
                        <FormControl fullWidth>
                            <FormLabel required sx={{textAlign: 'left'}}>
                                {lng === 'cn' ? '始发地邮编' : "Departure Postal Code"}
                            </FormLabel>
                            <Autocomplete
                                // freeSolo
                                value={movingFrom?.displayPostalCode}
                                fullWidth
                                options={movingFromAddressPredictions}
                                filterOptions={(options, state) => options}
                                getOptionLabel={option => option?.description}
                                onInputChange={handleInputChangeMovingFromPostalCode}
                                onChange={handleChangeMovingFromPostalCode}
                                PaperComponent={props => <Paper {...props}
                                                                sx={{width: 400}}/>}
                                renderInput={params => <TextField
                                    {...params}
                                    className={classes.smallInput}
                                    variant="outlined"
                                    error={validatedResult !== null && !movingFrom?.postalCode}
                                    helperText={(validatedResult !== null && !movingFrom?.postalCode) && (lng === 'cn' ? "必填" : "Required")}
                                />}
                                renderOption={option => (
                                    <Typography style={{fontSize: '12px'}}>
                                        {option.description}
                                    </Typography>
                                )}
                            />
                        </FormControl>
                    </Grid>
                    {
                        tab?.toLowerCase() === 'moving' &&
                        <Grid item md={4} xs={12}>
                            <FormControl fullWidth>
                                <FormLabel required>
                                    {lng === 'cn' ? '房屋类型' : "Property Type"}
                                </FormLabel>
                                <Autocomplete
                                    value={movingFrom.propertyType}
                                    fullWidth
                                    groupBy={(option) => option.propertyType}
                                    options={house_type}
                                    getOptionLabel={(option) => option.houseType}
                                    onChange={handleMovingFromPropertyType}
                                    renderInput={(params) => <TextField
                                        variant="outlined"
                                        className={classes.smallInput}
                                        {...params}
                                        error={validatedResult !== null && !movingFrom?.propertyType}
                                        helperText={(validatedResult !== null && !movingFrom?.propertyType) && (lng === 'cn' ? "必填" : "Required")}
                                    />}
                                    renderOption={(option) => (
                                        <div>
                                            {option.houseType}
                                        </div>
                                    )}
                                />
                            </FormControl>
                        </Grid>
                    }
                </Grid>
            </Grid>
            <Grid item md={6} xs={12}>
                <Grid container spacing={4}>
                    {
                        tab?.toLowerCase() === 'moving' &&
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={movingTo?.option === 'self'}
                                            onChange={handleIsSelfPickup}
                                            style={{
                                                color: 'green',
                                                '&.Mui-checked': {
                                                    color: 'green',
                                                }
                                            }}
                                        />
                                    }
                                    label={
                                        <>
                                            {lng === 'cn' ? '自行来我司仓库提货' : "Self Pickup at our warehouse by yourself"}
                                        </>
                                    }
                                />
                            </FormControl>
                        </Grid>
                    }
                    <Grid item md={tab?.toLowerCase() === 'moving' ? 8 : 12} xs={12}>
                        <FormControl fullWidth>
                            <FormLabel required sx={{textAlign: 'left'}}>
                                {lng === 'cn' ? '目的地邮编' : "Destination Postal Code"}
                            </FormLabel>
                            <Autocomplete
                                // freeSolo
                                value={movingTo?.displayPostalCode}
                                fullWidth
                                options={movingToAddressPredictions}
                                filterOptions={(options, state) => options}
                                getOptionLabel={option => option?.description}
                                onInputChange={handleInputChangeMovingToPostalCode}
                                onChange={handleChangeMovingToPostalCode}
                                PaperComponent={props => <Paper {...props}
                                                                sx={{width: 400}}/>}
                                renderInput={params => <TextField
                                    {...params}
                                    className={classes.smallInput}
                                    variant="outlined"
                                    error={validatedResult !== null && !movingTo?.postalCode}
                                    helperText={(validatedResult !== null && !movingTo?.postalCode) && (lng === 'cn' ? "必填" : "Required")}
                                />}
                                renderOption={option => (
                                    <Typography style={{fontSize: '12px'}}>
                                        {option.description}
                                    </Typography>
                                )}
                            />
                        </FormControl>
                    </Grid>
                    {
                        tab?.toLowerCase() === 'moving' &&
                        <Grid item md={4} xs={12}>
                            <FormControl fullWidth>
                                <FormLabel required>
                                    {lng === 'cn' ? '房屋类型' : "Property Type"}
                                </FormLabel>
                                <Autocomplete
                                    value={movingTo.propertyType}
                                    fullWidth
                                    groupBy={(option) => option.propertyType}
                                    options={house_type}
                                    getOptionLabel={(option) => option.houseType}
                                    onChange={handleMovingToPropertyType}
                                    renderInput={(params) => <TextField
                                        variant="outlined"
                                        className={classes.smallInput}
                                        {...params}
                                        error={validatedResult !== null && !movingTo?.propertyType}
                                        helperText={(validatedResult !== null && !movingTo?.propertyType) && (lng === 'cn' ? "必填" : "Required")}
                                    />}
                                    renderOption={(option) => (
                                        <div>
                                            {option.houseType}
                                        </div>
                                    )}
                                />
                            </FormControl>
                        </Grid>
                    }
                </Grid>
            </Grid>
        </Fragment>

    )
}