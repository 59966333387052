import {Button, Container, Snackbar} from "@mui/material";
import {MovingServiceRoomPlaceItemList} from "./MovingServiceRoomPlaceItemList";
import {MovingServiceItem} from "./MovingServiceItem";
import MovingServiceProgressBar from "./MovingServiceProgressBar";
import {useState} from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import intl from "react-intl-universal";
import {useHistory, useParams} from "react-router-dom";
import LoadingButton from "@mui/lab/LoadingButton";
import {MovingServiceImage} from "./MovingServiceImage";
import {MovingServicePhotosAndDetails} from "./MovingServicePhotosAndDetails";
import {useSelector} from "react-redux";
import MuiAlert from "@mui/material/Alert";
import * as React from "react";
import MovingItemTitle from "../../images/icons/movingItemTitle.png";
import {MovingServiceCar} from "./MovingServiceCar";
import {MovingServiceItemListInput} from "./MovingServiceItemListInput";

const styles = {
    MovingServiceAddressRoot: {
        display: 'flex',
        flexDirection: 'column',
        gap: '30px',
        marginBottom: '40px',
    },
    MovingServiceSummaryButtonSection: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',

    },
}

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const requiredItemProperties = ["length", "width", "height", "quantity", "weight", "lengthUnit", "weightUnit", "type"];
const validateRequiredItemProperties = ["length", "width", "height", "weight", "quantity"];

export const MovingServiceList = ({validatedResult, AIAssisted}) => {

    const {tab} = useParams();

    return (
        <Box sx={styles.MovingServiceAddressRoot}>
            {
                tab === 'vehicle' ?
                    <MovingServiceCar validatedResult={validatedResult}/>
                    :
                    (tab === 'item' && AIAssisted) ?
                        <MovingServiceItemListInput validatedResult={validatedResult}/>
                        :
                        <>
                            <MovingServiceRoomPlaceItemList tab={tab} validatedResult={validatedResult}/>
                            <MovingServiceItem tab={tab} validatedResult={validatedResult}/>
                        </>
            }
        </Box>
    )
}